export const MOCK_GET_PALLETS_BY_STATUS: any = {
  success: true,
  pallets: [
    {
      'activated': true,
      'barcode': 'TP-0000-0287',
      'create_ts': '2024-05-02T12:43:46.432488',
      'date': '2024-05-02T12:43:46.330370',
      'dimensions': {},
      'executor': {
        'enabled': true,
        'id': '69e2c608-88c9-4a32-87a9-1401736228b0',
        'login': '123',
        'name': '123',
        'patronymic': '123',
        'position': 'GENERAL_DIRECTOR',
        'roles': [
          'e545d9ad-c079-4c11-9005-a775438549a2',
        ],
        'surname': '123',
      },
      'id': 'TP-0000-0287',
      'pallet_id': 'TP-0000-0287',
      'pallet_num': 1,
      'places': [
        'OP-TM-0000-0824', 'OP-TM-0000-0827', 'OP-TM-0000-0828'
      ],
      'status': 'NEW',
      'update_ts': '2024-05-09T10:19:00.137289',
      'uuid': 'ee9f4f9e-c3a2-4234-b188-d63026082c5d',
      'workspace': {
        'barcode': '-',
        'condition': 'GOOD',
        'id': 'd5642996-489c-4f31-8b2d-65d8c6fe337a',
        'printer_settings': [
          {
            'name': 'Microsoft Print to PDF (перенаправлено 1)',
            'type': 'A4',
          },
          {
            'name': 'ZDesigner 105SL 203DPI',
            'type': 'STICKER_10x15',
          },
          {
            'name': 'ZDesigner 105SL 203DPI',
            'type': 'STICKER_6x6',
          },
        ],
        'title': '-',
        'zone_id': '26826c40-ac61-4e9f-a5ea-1101ef5521f2',
      },
    },
    {
      'activated': true,
      'barcode': 'TP-TM-0000-0286',
      'create_ts': '2024-04-10T11:33:06.981485',
      'date': '2024-04-10T11:33:06.975034',
      'dimensions': {
        'depth': 1,
        'height': 1,
        'width': 1,
      },
      'executor': {
        'enabled': true,
        'id': '69e2c608-88c9-4a32-87a9-1401736228b0',
        'login': '123',
        'name': '123',
        'patronymic': '123',
        'position': 'GENERAL_DIRECTOR',
        'roles': [
          'e545d9ad-c079-4c11-9005-a775438549a2',
        ],
        'surname': '123',
      },
      'id': 'TP-TM-0000-0286',
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'pallet_id': 'TP-TM-0000-0286',
      'pallet_num': 2,
      'places': ['OP-TM-0000-0829'],
      'status': 'NEW',
      'update_ts': '2024-04-10T11:34:22.849982',
      'uuid': '7929b676-3c9c-4930-88df-50ddab18e8e2',
      'weight': 1,
      'workspace': {
        'barcode': 'XX-1',
        'condition': 'GOOD',
        'id': 'a94203b3-67d7-42b7-9642-ef510c3e9323',
        'printer_settings': [
          {
            'name': 'PRINTER_A4',
            'type': 'A4',
          },
          {
            'name': 'STICKER_6x6',
            'type': 'STICKER_6x6',
          },
          {
            'name': 'STICKER_10X15',
            'type': 'STICKER_10x15',
          },
        ],
        'title': 'XX-1',
        'zone_id': '26826c40-ac61-4e9f-a5ea-1101ef5521f2',
      },
    },
  ],
  places: [
    {
      'dimensions': {
        'depth': 180,
        'height': 100,
        'width': 250,
      },
      'id': 'OP-TM-0000-0824',
      'items': [
        {
          'fragile': true,
          'id': 'R72060',
          'quantity': 1,
          'serial_numbers': [
            {
              'code': 'chestniy_znak',
              'title': 'Честный знак',
              'value': '0104065302204113215%HefMSHYlegL9100BE92IlF/M3RjtFd4KI5xnWnri5ZHCNIlndYdSVKLoG2VvWg3BZ3dPM1EnijDoPSuNJmVI63clfwHgXLVy3vDCXKvnw==',
            },
          ],
        },
      ],
      'order_id': 'orderId_1',
      'packaging_id': '250*180*100',
      'sequence_num': 3,
      'weight': 1000,
    },
    {
      'dimensions': {
        'depth': 180,
        'height': 100,
        'width': 250,
      },
      'id': 'OP-TM-0000-0827',
      'items': [
        {
          'fragile': true,
          'id': 'R72060',
          'quantity': 1,
          'serial_numbers': [
            {
              'code': 'chestniy_znak',
              'title': 'Честный знак',
              'value': '0104065301205113215%HefMSHYlegL9100BE92IlF/M3RjtFd4KI5xnWnri5ZHCNIlndYdSVKLoG2VvWg3BZ3dPM1EnijDoPSuNJmVI63clfwHgXLVy3vDCXKvnw==',
            },
          ],
        },
      ],
      'order_id': 'orderId_2',
      'packaging_id': '250*180*100',
      'sequence_num': 6,
      'weight': 1000,
    },
    {
      'dimensions': {
        'depth': 180,
        'height': 100,
        'width': 250,
      },
      'id': 'OP-TM-0000-0828',
      'items': [
        {
          'fragile': true,
          'id': 'R72060',
          // 'id': 'R72265',
          'quantity': 1,
          'serial_numbers': [
            {
              'code': 'chestniy_znak',
              'title': 'Честный знак',
              'value': '0104065301305113215%HefMSHYlegL9100BE92IlF/M3RjtFd4KI5xnWnri5ZHCNIlndYdSVKLoG2VvWg3BZ3dPM1EnijDoPSuNJmVI63clfwHgXLVy3vDCXKvnw==',
            },
          ],
        },
      ],
      'order_id': 'orderId_5',
      'packaging_id': '250*180*100',
      'sequence_num': 7,
      'weight': 1000,
    },
    {
      'dimensions': {
        'depth': 180,
        'height': 100,
        'width': 250,
      },
      'id': 'OP-TM-0000-0829',
      'items': [
        {
          'fragile': true,
          'id': 'R72060',
          'quantity': 1,
          'serial_numbers': [
            {
              'code': 'chestniy_znak',
              'title': 'Честный знак',
              'value': '0104065301305123215%HefMSHYlegL9100BE92IlF/M3RjtFd4KI5xnWnri5ZHCNIlndYdSVKLoG2VvWg3BZ3dPM1EnijDoPSuNJmVI63clfwHgXLVy3vDCXKvnw==',
            },
          ],
        },
      ],
      'order_id': 'orderId_6',
      'packaging_id': '250*180*100',
      'sequence_num': 8,
      'weight': 1000,
    },
  ],
  orders: [
    {
          'id': 'orderId_1',
          'operationType': 'PACKAGING_B2B',
          'maxBoxesWithoutPallet': 100000,
          'relabelingType': 'BOXES_AND_PALLETS',
          'with_pallet_composition': false,
          'cancelReason': '',
          'date': '2023-08-27T21:06:29',
          'packing_state': 'awaiting_packing',
          'DisableStickerAndPackagingFBO': false,
          'global_shipping_guidelines': '',
          'ExtraSeat': false,
          'dlvId': 'c32e4e07-f508-448b-b470-c6eadcca2f0f',
          'order_shipping_comments': '',
          'operativeComment': '',
          'sorting_number': 1,
          'require_place_weight': true,
          'preferred_barcode_type': 'WILDBERRIES',
          'shippingOptions': {
            'packingType': 'MIX_PACKINGS',
          },
          'shipping_form': [],
          'merchant': {
            'id': '2',
            'title': 'test 2',
            'trade_title': 'test 2',
            'dlv_login': 'test 2',
          },
          'receiver': {
            'contractor_id': '',
            'phone': '70000000000',
            'patronymic': 'Wildberries',
            'name': 'На',
            'surname': 'Покупатель',
          },
          'delivery': {
            'logo': '',
            'title': 'WB',
            'contract_title': '',
            'contract': 'R7WB',
            'address': {
              'country': 'RU',
              'region': '',
              'region_fias': '',
              'area': 'Московская',
              'area_fias': '',
              'city': 'Подольск',
              'city_fias': '',
              'settlement': 'Коледино',
              'settlement_fias': '',
              'street': 'ул Троицкая',
              'house': '20',
              'block': '',
              'building': '',
              'floor': '',
              'flat': '',
              'zip': '',
              'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
            },
            'sorting_center_acceptance_date': '',
            'maximum_place_weight': '',
            'maximum_places_count': '',
            'maximum_pallet_weight': 1000000,
            'maximum_place_dimensions': {},
            'maximum_pallet_dimensions': {
              'width': 100000,
              'depth': 10000000,
              'height': 100000,
            },
          },
          'relabelingOptions': {
            'scanNewBarcodeByClient': 'scanWithUnique',
          },
          'items': [
            {
              'id': 'R72060',
              'title': 'Футболка оверсайз с кармашком для мальчика (140, 146, 158, 164), Цвет: морской Размер: 140',
              'article': '33405-140морской',
              'quantity': 1,
              'weight': 120,
              'image': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc.jpg',
              'image_preview': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc-100.jpg',
              'fragile': '',
              'price_data': {
                'price': 1201,
                'vat': 'NO_VAT',
                'currency': 'RUB',
              },
              'require_weight': false,
              'require_dimensions': false,
              'dimensions': {
                'width': 26,
                'height': 1,
                'depth': 15.5,
              },
              'barcodes': [
                {
                  'barcode': '2',
                  'type': 'COMMON',
                },
                {
                  'barcode': '2123123',
                  'type': 'WILDBERRIES',
                },
              ],
              'packagings': [],
              'scan_serial_numbers': [],
            },
          ],
          'places': [],
          'pallets': [],
          'ad_attachments': [],
        },
    {
          'id': 'orderId_2',
          'operationType': 'PACKAGING_B2B',
          'maxBoxesWithoutPallet': 100000,
          'relabelingType': 'BOXES_AND_PALLETS',
          'with_pallet_composition': false,
          'cancelReason': '',
          'date': '2023-08-27T21:06:29',
          'packing_state': 'awaiting_packing',
          'DisableStickerAndPackagingFBO': false,
          'global_shipping_guidelines': '',
          'ExtraSeat': false,
          'dlvId': 'c32e4e07-f508-448b-b470-c6eadcca2f0f',
          'order_shipping_comments': '',
          'operativeComment': '',
          'sorting_number': 1,
          'require_place_weight': true,
          'preferred_barcode_type': 'WILDBERRIES',
          'shippingOptions': {
            'packingType': 'MIX_PACKINGS',
          },
          'shipping_form': [],
          'merchant': {
            'id': '2',
            'title': 'test 2',
            'trade_title': 'test 2',
            'dlv_login': 'test 2',
          },
          'receiver': {
            'contractor_id': '',
            'phone': '70000000000',
            'patronymic': 'Wildberries',
            'name': 'На',
            'surname': 'Покупатель',
          },
          'delivery': {
            'logo': '',
            'title': 'WB',
            'contract_title': '',
            'contract': 'R7WB',
            'address': {
              'country': 'RU',
              'region': '',
              'region_fias': '',
              'area': 'Московская',
              'area_fias': '',
              'city': 'Подольск',
              'city_fias': '',
              'settlement': 'Коледино',
              'settlement_fias': '',
              'street': 'ул Троицкая',
              'house': '20',
              'block': '',
              'building': '',
              'floor': '',
              'flat': '',
              'zip': '',
              'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
            },
            'sorting_center_acceptance_date': '',
            'maximum_place_weight': '',
            'maximum_places_count': '',
            'maximum_pallet_weight': 1000000,
            'maximum_place_dimensions': {},
            'maximum_pallet_dimensions': {
              'width': 100000,
              'depth': 10000000,
              'height': 100000,
            },
          },
          'relabelingOptions': {
            'scanNewBarcodeByClient': 'scanWithUnique',
          },
          'items': [
            {
              'id': 'R72060',
              'title': 'Футболка оверсайз с кармашком для мальчика (140, 146, 158, 164), Цвет: морской Размер: 140',
              'article': '33405-140морской',
              'quantity': 1,
              'weight': 120,
              'image': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc.jpg',
              'image_preview': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc-100.jpg',
              'fragile': '',
              'price_data': {
                'price': 1201,
                'vat': 'NO_VAT',
                'currency': 'RUB',
              },
              'require_weight': false,
              'require_dimensions': false,
              'dimensions': {
                'width': 26,
                'height': 1,
                'depth': 15.5,
              },
              'barcodes': [
                {
                  'barcode': '2',
                  'type': 'COMMON',
                },
                {
                  'barcode': '2123123',
                  'type': 'WILDBERRIES',
                },
              ],
              'packagings': [],
              'scan_serial_numbers': [],
            },
          ],
          'places': [],
          'pallets': [],
          'ad_attachments': [],
      },
    {
        'id': 'orderId_3',
        'operationType': 'PACKAGING_B2B',
        'maxBoxesWithoutPallet': 100000,
        'relabelingType': 'BOXES_AND_PALLETS',
        'with_pallet_composition': false,
        'cancelReason': '',
        'date': '2023-08-27T20:02:24',
        'packing_state': 'awaiting_packing',
        'DisableStickerAndPackagingFBO': false,
        'global_shipping_guidelines': '',
        'ExtraSeat': false,
        'dlvId': 'a75243a5-7a09-4872-b6c8-d3153f193704',
        'order_shipping_comments': '',
        'operativeComment': '',
        'sorting_number': 2,
        'require_place_weight': true,
        'preferred_barcode_type': 'WILDBERRIES',
        'shippingOptions': {
          'packingType': 'MIX_PACKINGS',
        },
        'shipping_form': [],
        'merchant': {
          'id': '1',
          'title': 'test 1',
          'trade_title': 'test 1',
          'dlv_login': 'test 1',
        },
        'receiver': {
          'contractor_id': '',
          'phone': '70000000000',
          'patronymic': 'Wildberries',
          'name': 'На',
          'surname': 'Покупатель',
        },
        'delivery': {
          'logo': '',
          'title': 'WB',
          'contract_title': '',
          'contract': 'R7WB',
          'address': {
            'country': 'RU',
            'region': '',
            'region_fias': '',
            'area': 'Московская',
            'area_fias': '',
            'city': 'Подольск',
            'city_fias': '',
            'settlement': 'Коледино',
            'settlement_fias': '',
            'street': 'ул Троицкая',
            'house': '20',
            'block': '',
            'building': '',
            'floor': '',
            'flat': '',
            'zip': '',
            'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
          },
          'sorting_center_acceptance_date': '',
          'maximum_place_weight': '',
          'maximum_places_count': '',
          'maximum_pallet_weight': 1000000,
          'maximum_place_dimensions': {},
          'maximum_pallet_dimensions': {
            'width': 100000,
            'depth': 10000000,
            'height': 100000,
          },
        },
        'relabelingOptions': {
          'scanNewBarcodeByClient': 'scanWithUnique',
        },
        'items': [
          {
            'id': 'R72265',
            'title': 'Футболка для мальчика, Цвет: салатовый Размер: 146 (134-140)',
            'article': '42069-146салатовый',
            'quantity': 1,
            'weight': 110,
            'image': 'https://storage.yandexcloud.net/images-k2c/56c8de20-6a5f-4cf6-8dfc-0e9d3a96490f.jpg',
            'image_preview': 'https://storage.yandexcloud.net/images-k2c/56c8de20-6a5f-4cf6-8dfc-0e9d3a96490f-100.jpg',
            'fragile': '',
            'price_data': {
              'price': 1136,
              'vat': 'NO_VAT',
              'currency': 'RUB',
            },
            'require_weight': false,
            'require_dimensions': false,
            'dimensions': {
              'width': 31,
              'height': 1,
              'depth': 29,
            },
            'barcodes': [
              {
                'barcode': '1',
                'type': 'WILDBERRIES',
              },
            ],
            'packagings': [],
            'scan_serial_numbers': [],
          },
        ],
        'places': [],
        'pallets': [],
        'ad_attachments': [],
      },
    {
        'id': 'orderId_4',
        'operationType': 'PACKAGING_B2B',
        'maxBoxesWithoutPallet': 100000,
        'relabelingType': 'BOXES_AND_PALLETS',
        'with_pallet_composition': false,
        'cancelReason': '',
        'date': '2023-08-27T20:03:33',
        'packing_state': 'awaiting_packing',
        'DisableStickerAndPackagingFBO': false,
        'global_shipping_guidelines': '',
        'ExtraSeat': false,
        'dlvId': '28f79e1e-83f5-4f04-a63e-93e7b787c27e',
        'order_shipping_comments': '',
        'operativeComment': '',
        'sorting_number': 3,
        'require_place_weight': true,
        'preferred_barcode_type': 'WILDBERRIES',
        'shippingOptions': {
          'packingType': 'MIX_PACKINGS',
        },
        'shipping_form': [],
        'merchant': {
          'id': 'b0e8ba9a-3db2-11ed-f692-ac1f6bd14dbc',
          'title': 'Ершова ИП "ЛУРДИ СТАЙЛ" КОТЛЯКОВКА',
          'trade_title': 'Ершова ИП "ЛУРДИ СТАЙЛ" КОТЛЯКОВКА',
          'dlv_login': 'i.dir4415@yandex.ru',
        },
        'receiver': {
          'contractor_id': '',
          'phone': '70000000000',
          'patronymic': 'Wildberries',
          'name': 'На',
          'surname': 'Покупатель',
        },
        'delivery': {
          'logo': '',
          'title': 'WB',
          'contract_title': '',
          'contract': 'R7WB',
          'address': {
            'country': 'RU',
            'region': '',
            'region_fias': '',
            'area': 'Московская',
            'area_fias': '',
            'city': 'Подольск',
            'city_fias': '',
            'settlement': 'Коледино',
            'settlement_fias': '',
            'street': 'ул Троицкая',
            'house': '20',
            'block': '',
            'building': '',
            'floor': '',
            'flat': '',
            'zip': '',
            'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
          },
          'sorting_center_acceptance_date': '',
          'maximum_place_weight': '',
          'maximum_places_count': '',
          'maximum_pallet_weight': 1000000,
          'maximum_place_dimensions': {},
          'maximum_pallet_dimensions': {
            'width': 100000,
            'depth': 10000000,
            'height': 100000,
          },
        },
        'relabelingOptions': {
          'scanNewBarcodeByClient': 'scanWithUnique',
        },
        'items': [
          {
            'id': 'R72269',
            'title': 'Футболка для мальчика, Цвет: салатовый Размер: 176 (164-170)',
            'article': '42069-176салатовый',
            'quantity': 1,
            'weight': 125,
            'image': 'https://storage.yandexcloud.net/images-k2c/56c8de20-6a5f-4cf6-8dfc-0e9d3a96490f.jpg',
            'image_preview': 'https://storage.yandexcloud.net/images-k2c/56c8de20-6a5f-4cf6-8dfc-0e9d3a96490f-100.jpg',
            'fragile': '',
            'price_data': {
              'price': 1136,
              'vat': 'NO_VAT',
              'currency': 'RUB',
            },
            'require_weight': false,
            'require_dimensions': false,
            'dimensions': {
              'width': 32,
              'height': 1,
              'depth': 29,
            },
            'barcodes': [
              {
                'barcode': '1000000003603',
                'type': 'WILDBERRIES',
              },
            ],
            'packagings': [],
            'scan_serial_numbers': [],
          },
        ],
        'places': [],
        'pallets': [],
        'ad_attachments': [],
      },
    {
        'id': 'orderId_5',
        'operationType': 'PACKAGING_B2B',
        'maxBoxesWithoutPallet': 100000,
        'relabelingType': 'BOXES_AND_PALLETS',
        'with_pallet_composition': false,
        'cancelReason': '',
        'date': '2023-08-27T22:28:58',
        'packing_state': 'awaiting_packing',
        'DisableStickerAndPackagingFBO': false,
        'global_shipping_guidelines': '',
        'ExtraSeat': false,
        'dlvId': '249a588f-fbe7-41ce-b1a5-218a34541a93',
        'order_shipping_comments': '',
        'operativeComment': '',
        'sorting_number': 4,
        'require_place_weight': true,
        'preferred_barcode_type': 'WILDBERRIES',
        'shippingOptions': {
          'packingType': 'MIX_PACKINGS',
        },
        'shipping_form': [],
        'merchant': {
          'id': 'b0e8ba9a-3db2-11ed-f692-ac1f6bd14dbc',
          'title': 'Ершова ИП "ЛУРДИ СТАЙЛ" КОТЛЯКОВКА',
          'trade_title': 'Ершова ИП "ЛУРДИ СТАЙЛ" КОТЛЯКОВКА',
          'dlv_login': 'i.dir4415@yandex.ru',
        },
        'receiver': {
          'contractor_id': '',
          'phone': '70000000000',
          'patronymic': 'Wildberries',
          'name': 'На',
          'surname': 'Покупатель',
        },
        'delivery': {
          'logo': '',
          'title': 'WB',
          'contract_title': '',
          'contract': 'R7WB',
          'address': {
            'country': 'RU',
            'region': '',
            'region_fias': '',
            'area': 'Московская',
            'area_fias': '',
            'city': 'Подольск',
            'city_fias': '',
            'settlement': 'Коледино',
            'settlement_fias': '',
            'street': 'ул Троицкая',
            'house': '20',
            'block': '',
            'building': '',
            'floor': '',
            'flat': '',
            'zip': '',
            'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
          },
          'sorting_center_acceptance_date': '',
          'maximum_place_weight': '',
          'maximum_places_count': '',
          'maximum_pallet_weight': 1000000,
          'maximum_place_dimensions': {},
          'maximum_pallet_dimensions': {
            'width': 100000,
            'depth': 10000000,
            'height': 100000,
          },
        },
        'relabelingOptions': {
          'scanNewBarcodeByClient': 'scanWithUnique',
        },
        'items': [
          {
            'id': 'R71958',
            'title': 'Рубашка в клетку на молнии с капюшоном для мальчика, Цвет: песочный Размер: 134',
            'article': '52218-134песочный',
            'quantity': 1,
            'weight': 305,
            'image': 'https://storage.yandexcloud.net/images-k2c/a5f6a73b-d25b-499d-95d5-08fd00d010a7.jpg',
            'image_preview': 'https://storage.yandexcloud.net/images-k2c/a5f6a73b-d25b-499d-95d5-08fd00d010a7-100.jpg',
            'fragile': '',
            'price_data': {
              'price': 1418,
              'vat': 'NO_VAT',
              'currency': 'RUB',
            },
            'require_weight': false,
            'require_dimensions': false,
            'dimensions': {
              'width': 27,
              'height': 4,
              'depth': 26,
            },
            'barcodes': [
              {
                'barcode': '1000000003289',
                'type': 'WILDBERRIES',
              },
            ],
            'packagings': [],
            'scan_serial_numbers': [],
          },
        ],
        'places': [],
        'pallets': [],
        'ad_attachments': [],
      },
    {
        'id': 'orderId_6',
        'operationType': 'PACKAGING_B2B',
        'maxBoxesWithoutPallet': 100000,
        'relabelingType': 'BOXES_AND_PALLETS',
        'with_pallet_composition': false,
        'cancelReason': '',
        'date': '2023-08-28T05:51:28',
        'packing_state': 'awaiting_packing',
        'DisableStickerAndPackagingFBO': false,
        'global_shipping_guidelines': '',
        'ExtraSeat': false,
        'dlvId': '3fece691-967f-43c4-b995-2749e62f97d0',
        'order_shipping_comments': '',
        'operativeComment': '',
        'sorting_number': 5,
        'require_place_weight': true,
        'preferred_barcode_type': 'WILDBERRIES',
        'shippingOptions': {
          'packingType': 'MIX_PACKINGS',
        },
        'shipping_form': [],
        'merchant': {
          'id': 'b0e8ba9a-3db2-11ed-f692-ac1f6bd14dbc',
          'title': 'Ершова ИП "ЛУРДИ СТАЙЛ" КОТЛЯКОВКА',
          'trade_title': 'Ершова ИП "ЛУРДИ СТАЙЛ" КОТЛЯКОВКА',
          'dlv_login': 'i.dir4415@yandex.ru',
        },
        'receiver': {
          'contractor_id': '',
          'phone': '70000000000',
          'patronymic': 'Wildberries',
          'name': 'На',
          'surname': 'Покупатель',
        },
        'delivery': {
          'logo': '',
          'title': 'WB',
          'contract_title': '',
          'contract': 'R7WB',
          'address': {
            'country': 'RU',
            'region': '',
            'region_fias': '',
            'area': 'Московская',
            'area_fias': '',
            'city': 'Подольск',
            'city_fias': '',
            'settlement': 'Коледино',
            'settlement_fias': '',
            'street': 'ул Троицкая',
            'house': '20',
            'block': '',
            'building': '',
            'floor': '',
            'flat': '',
            'zip': '',
            'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
          },
          'sorting_center_acceptance_date': '',
          'maximum_place_weight': '',
          'maximum_places_count': '',
          'maximum_pallet_weight': 1000000,
          'maximum_place_dimensions': {},
          'maximum_pallet_dimensions': {
            'width': 100000,
            'depth': 10000000,
            'height': 100000,
          },
        },
        'relabelingOptions': {
          'scanNewBarcodeByClient': 'scanWithUnique',
        },
        'items': [
          {
            'id': 'R72118',
            'title': 'LUMINARC Столовый набор Зели 19 предметов',
            'article': 'L4123',
            'quantity': 1,
            'weight': 6375,
            'image': 'https://storage.yandexcloud.net/images-k2c/aac8acb5-b43f-4450-a606-6f7c05e95ecc.png',
            'image_preview': 'https://storage.yandexcloud.net/images-k2c/aac8acb5-b43f-4450-a606-6f7c05e95ecc-100.png',
            'fragile': true,
            'price_data': {
              'price': 3985,
              'vat': 'NO_VAT',
              'currency': 'RUB',
            },
            'require_weight': false,
            'require_dimensions': false,
            'dimensions': {
              'width': 28,
              'height': 27,
              'depth': 19,
            },
            'barcodes': [
              {
                'barcode': '0883314524710',
                'type': 'COMMON',
              },
              {
                'barcode': '1000000003449',
                'type': 'WILDBERRIES',
              },
            ],
            'packagings': [],
            'scan_serial_numbers': [],
          },
        ],
        'places': [],
        'pallets': [],
        'ad_attachments': [],
      },
  ],
}

import { useQuery } from 'react-query'
import api from 'src/api'

import { ApiReqResult, IShipmentTypeItem } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'

/*
 * Получение типов отгрузки
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Spravochniki-perechislenij/operation/getShipmentTypes
 */

const getShipmentTypes = async () => {
  const result = await api.get(`/enum/shipment-type`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

export interface IShipmentResultData {
  values: IShipmentTypeItem[]
}

interface IApiResult extends IShipmentResultData, ApiReqResult {}

const useGetShipmentTypes = (
  options?: UseQueryOptions<any>
) => {
  return useQuery<IApiResult>(
    [`getShipmentTypes`],
    getShipmentTypes,
    options
  )
}

export default useGetShipmentTypes

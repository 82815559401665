import { useMutation } from 'react-query'

import api from 'src/api'

export interface IItemPhoto {
  data: string
  title: string
}

const useLoadItemPhotos = () => {
  return useMutation(
    async (data: { itemPhotos: IItemPhoto[]; returnId: string; placeId: string; itemId: string}) => {
      const result = await api.post(`/return/${data.returnId}/place/${data.placeId}/item/${data.itemId}/file`, data.itemPhotos)
      return result.data
    },
  )
}

export default useLoadItemPhotos

export const MOCK_GET_ORDER_PACKING_SKU_MARKETPLACE_V2: any = {
  'order': {
    'activated': true,
    'ad_attachments': [],
    'common_options': {
      'auto_exit': true,
      'require_print_docs': false,
    },
    'cancel_requested': false,
    'crossdocking_options': {},
    'external_id': '9793845577178',
    'id': 'AED9793845577178',
    'join_code': '****',
    'packing_options': {
      'additional_sku_sticker_quantity': 0,
      'completion_packing_available': false,
      'instruction': {
        'required': false,
      },
      'packing_type': 'MIX_PACKINGS',
      'require_place_weight': true,
    },
    'packing_status': 'PACKING_ORDER_PLACES',
    'sku_condition': 'GOOD',
    'items': [
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '1',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '2',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '3',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '4',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '5',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '6',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '7',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '8',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '9',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '10',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '11',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 65,
        'marketplace_sku_id': '12',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 65,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '13',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '14',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '15',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '16',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '17',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '18',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '19',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '20',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '21',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '22',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '23',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '24',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '25',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '26',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '27',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '28',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '29',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '30',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '31',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '32',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '33',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '34',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '35',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '36',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '37',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '38',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '39',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178228',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '40',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '41',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '42',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '43',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '44',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '45',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '46',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '47',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '48',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '49',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '50',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '51',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '52',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '53',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '54',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '55',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '56',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '57',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '58',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '59',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '60',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '61',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '62',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '63',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '64',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '65',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '66',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '67',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '68',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '69',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '70',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '71',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '72',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '73',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '74',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '75',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
      {
        'sku_id': 'AED00-000000178155',
        'quantity': 1,
        'insurance': 66,
        'marketplace_sku_id': '76',
        'packing_options': {
          'dimensions_and_weight': {
            'dimensions_required': false,
            'weight_required': false,
          },
          'instruction': {
            'required': false,
          },
          'packaging': {
            'ask': false,
            'required': false,
          },
          'sku_batch': {
            'exp_date_required': false,
          },
          'price_data': {
            'currency': 'RUB',
            'price': 66,
            'vat': 'VAT_20',
          },
        },
      },
    ],
    'places': [
      {
        'activated': true,
        'relabeled': false,
        'date': '2024-04-14T23:10:34',
        'items': [
          {
            'barcode_used': '9000100980692',
            'id': '68282030-7ee6-4c66-b6ca-2b64b9b91467',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': 'aee28dc5-2512-4620-a4f0-a0d1df96ddf2',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': 'bba2c57a-1897-4e88-9de0-d5262eaf6977',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': '7f3cda92-b94f-4849-b52e-5793153c841f',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': '98b5e2e1-b4f4-4e3d-9cf8-fdd4147a69a0',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': '3ad7aff2-c7da-4a68-8522-3f0dd3915db9',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': '143e1573-a7d1-4606-b8b5-ea7fcb1b132c',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': 'f10f083d-8341-4533-971d-48eccf2bd502',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': 'b75360f0-5404-4068-ad0a-cae4a53b74eb',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': '1fb621dd-3ee8-4d1e-aeb5-5bc0c8545914',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': 'd90b0733-e187-48ff-a468-6ba6567de37b',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': '5b1ed8d8-a65e-410e-b56e-774378f54445',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': '90bd10e7-9f43-4e99-98dc-2a47e6d7e8de',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': 'af7009ea-2149-42b2-afae-78a5bb1b6077',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': '2c558df6-3c06-485e-8a1e-22640e7ca153',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': '91da10b4-58e6-40e6-accc-2f07ba2c9dac',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': 'd5d8e265-f913-40f3-87a0-aaeec3d6c4ea',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
          {
            'barcode_used': '9000100980692',
            'id': '9bf48609-90e4-4e6f-a261-2e2f92ab3cc1',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKING',
          },
        ],
        'merchant': {
          'cam_id': '75638',
          'contract_date': '01.11.2023 0:00:00',
          'contract_num': 'КО-ОП-17\/11\/2023-299',
          'dlv_login': 'sreztsov@samokat.ru',
          'enabled': true,
          'id': '1411d36c-942a-11ee-2396-ac1f6b90eae2',
          'inn': '9701048328',
          'kpp': '770101001',
          'login': 'sreztsov@samokat.ru',
          'ogrn': '1167746803180',
          'title': 'МАРКЕТПЛЕЙС "Мегамаркет"',
          'trade_title': 'Общество с ограниченной ответственностью "МАРКЕТПЛЕЙС"',
        },
        'status': 'PACKING',
        'workspace': {
          'barcode': 'DM-40',
          'title': 'DM-40',
        },
      },
      {
        'activated': true,
        'relabeled': false,
        'barcode': '9538900484473',
        'date': '2024-04-14T23:10:34',
        'id': 'abfa741c-e8b8-433c-9bcc-ff8913ccc20e',
        'external_id': 'abfa741c-e8b8-433c-9bcc-ff8913ccc20e',
        'index': 1,
        'items': [
          {
            'barcode_used': '9000100980692',
            'id': '402d576e-1de3-4070-a327-0b5aeb1c76d5',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': '029e46cc-d700-4329-bdc6-5790a2680eeb',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': 'e14e934f-f6a0-4b2d-a08c-23459f2e668d',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': '0516b99c-12ca-406b-97d1-b05d312de1fe',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': '7b2b0c02-490f-4343-8e93-2588551bf42a',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': '32c34450-9b90-441a-9911-a974207e3dbf',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': '4318825f-6a42-4d4f-970a-42688575a921',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': '0885d31e-0999-47df-9500-3650c4ebb050',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': '902554e2-00f0-4532-900e-5b5ffbf962ab',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': '148be562-a177-4b7f-8299-e9fc87455e8d',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': 'abb192ad-9fb2-4a82-bd1e-fd92f8d471de',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': '4378758a-4c33-4d7a-bef5-b7f63219e8bf',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '92080902-0a82-412d-9449-99ec346da372',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '720fce4e-f102-4721-82e3-9dc1bbd128a5',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '7ec34b52-3843-4b54-a357-1d6cacbde35d',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '4e6c829d-651f-4313-8ac3-0cd3da62def0',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '60007ed3-3e49-4cd6-96f4-05953f646c3a',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '7decf317-3263-476a-9a13-557bd837de40',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
        ],
        'merchant': {
          'cam_id': '75638',
          'contract_date': '01.11.2023 0:00:00',
          'contract_num': 'КО-ОП-17\/11\/2023-299',
          'dlv_login': 'sreztsov@samokat.ru',
          'enabled': true,
          'id': '1411d36c-942a-11ee-2396-ac1f6b90eae2',
          'inn': '9701048328',
          'kpp': '770101001',
          'login': 'sreztsov@samokat.ru',
          'ogrn': '1167746803180',
          'title': 'МАРКЕТПЛЕЙС "Мегамаркет"',
          'trade_title': 'Общество с ограниченной ответственностью "МАРКЕТПЛЕЙС"',
        },
        'status': 'PACKED',
        'workspace': {
          'barcode': 'DM-40',
          'title': 'DM-40',
        },
      },
      {
        'activated': true,
        'relabeled': false,
        'barcode': '9538900173414',
        'date': '2024-04-14T23:10:34',
        'id': 'f3136d6f-96c0-4306-aa39-e7f1f81ba689',
        'external_id': 'f3136d6f-96c0-4306-aa39-e7f1f81ba689',
        'index': 2,
        'items': [
          {
            'barcode_used': '9000100980814',
            'id': 'd0714d58-a975-47b7-8d2e-deea86f039fb',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '91b3dfe3-6f00-4667-ba3b-cc64ee9abd27',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '6ac05474-b238-46a2-8a81-3db87b3b6076',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'dcd1b7e2-a771-47b1-94b2-808b8aa8a5ad',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '5ae36f3c-e6ca-4525-ba62-346bc4c8bf82',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '3ef71c6b-758d-42d0-a8cc-093067d3e5fe',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'c1d381fb-26ce-4e4c-8049-77b492e3765e',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'a02efb77-6062-4993-af72-226a4e0b61a4',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'd18fa73d-b2ed-48e0-a5e6-88ff0aa9a293',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'b2693794-60fc-4cb2-abbf-de7c00ea831c',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'ff18eaab-7691-4ba3-9fcf-4e0ca65642da',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '49eb9148-03d5-48a4-8d3b-5c271ff76e03',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '1a1d6aed-e85e-426d-b1c4-7453b95683bf',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '0c457879-4e2c-4de2-8b42-4eebb12d3b4e',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '728ff510-40db-4d16-9109-791ea38865fb',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '01460419-2f60-462a-9f1b-67333c3b5e24',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'ac98b426-96c5-49c5-b762-75288eaba3ff',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'a11211b5-0467-4130-804c-279c972c4aac',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
        ],
        'merchant': {
          'cam_id': '75638',
          'contract_date': '01.11.2023 0:00:00',
          'contract_num': 'КО-ОП-17\/11\/2023-299',
          'dlv_login': 'sreztsov@samokat.ru',
          'enabled': true,
          'id': '1411d36c-942a-11ee-2396-ac1f6b90eae2',
          'inn': '9701048328',
          'kpp': '770101001',
          'login': 'sreztsov@samokat.ru',
          'ogrn': '1167746803180',
          'title': 'МАРКЕТПЛЕЙС "Мегамаркет"',
          'trade_title': 'Общество с ограниченной ответственностью "МАРКЕТПЛЕЙС"',
        },
        'status': 'PACKED',
        'workspace': {
          'barcode': 'DM-40',
          'title': 'DM-40',
        },
      },
      {
        'activated': true,
        'relabeled': false,
        'barcode': '9538900148467',
        'date': '2024-04-14T23:10:34',
        'id': '5e6cc474-c69c-4c02-acf7-599e1bec047c',
        'external_id': '5e6cc474-c69c-4c02-acf7-599e1bec047c',
        'index': 3,
        'items': [
          {
            'barcode_used': '9000100980692',
            'id': '65f7615a-398c-4798-b34f-6a7d8fb70d2f',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980692',
            'id': '2f5d8af2-4406-48e9-9554-ac8455b721ca',
            'quantity': 1,
            'sku_id': 'AED00-000000178155',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'dc1e075b-e09c-4aef-a26e-38ef258b9cba',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '7b012007-96c1-44ff-ac81-874b31a8c52e',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'e5ed3540-74ee-4d51-8d10-5547a1d78b04',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '69df59fb-d8dd-4bb2-8e56-9fb26e8f431c',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '39401499-4046-4ff1-bc2d-6a2cc0c5023b',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'c6bad284-0813-4003-b429-920a0098b82a',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '5ca040c6-58d0-4aa7-99dd-f777351d70f9',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '6ec1b7f0-4f29-4953-998f-fcd80ef2a136',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '1fba69a5-dabf-42c8-8326-fc2a6520c298',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'f3a40493-2432-420d-8b16-c44ef8e2089a',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '831b3645-dfe0-4912-ba0f-cdad8488b5cc',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '6998f47c-dbd8-45b7-9fdd-356f0a60e7f8',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '238b7901-41b7-44ff-97ab-1d2a20fee7b7',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': 'c83211c6-f6ab-44af-beef-8726d7fe0e8b',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '564c93cf-6692-4f9a-bd39-d5bde57ebaf2',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
          {
            'barcode_used': '9000100980814',
            'id': '3328c0a8-6bfd-48bd-8a44-074b98e1a8e9',
            'quantity': 1,
            'sku_id': 'AED00-000000178228',
            'status': 'PACKED',
          },
        ],
        'merchant': {
          'cam_id': '75638',
          'contract_date': '01.11.2023 0:00:00',
          'contract_num': 'КО-ОП-17\/11\/2023-299',
          'dlv_login': 'sreztsov@samokat.ru',
          'enabled': true,
          'id': '1411d36c-942a-11ee-2396-ac1f6b90eae2',
          'inn': '9701048328',
          'kpp': '770101001',
          'login': 'sreztsov@samokat.ru',
          'ogrn': '1167746803180',
          'title': 'МАРКЕТПЛЕЙС "Мегамаркет"',
          'trade_title': 'Общество с ограниченной ответственностью "МАРКЕТПЛЕЙС"',
        },
        'status': 'PACKED',
        'workspace': {
          'barcode': 'DM-40',
          'title': 'DM-40',
        },
      },
    ],
    'relabeling_options': {
      'relabeling_type': 'BOXES_AND_PALLETS',
    },
    'status': 'PACKING',
    'transport_places': [],
    'uuid': '0ea50f98-fa9b-11ee-6284-ac1f6b90eae2',
    'customer': {
      'name': 'Карташов Павел Юрьевич',
    },
    'merchant': {
      'cam_id': '75638',
      'contract_date': '01.11.2023 0:00:00',
      'contract_num': 'КО-ОП-17\/11\/2023-299',
      'dlv_login': 'sreztsov@samokat.ru',
      'enabled': true,
      'id': '1411d36c-942a-11ee-2396-ac1f6b90eae2',
      'inn': '9701048328',
      'kpp': '770101001',
      'login': 'sreztsov@samokat.ru',
      'ogrn': '1167746803180',
      'title': 'МАРКЕТПЛЕЙС "Мегамаркет"',
      'trade_title': 'Общество с ограниченной ответственностью "МАРКЕТПЛЕЙС"',
    },
    'date': '2024-04-14T23:10:34',
    'create_ts': '2024-04-14T23:10:34',
    'payment_type': 'COD_CASH',
    'delivery': {
      'delivery_contract': {
        'delivery_company': {
          'code': 'CDEKV2',
          'is_fbo': false,
          'is_fbs': false,
          'logo': '',
          'preferred_barcode_type': '',
          'title': 'CDEK V2',
        },
        'code': 'AEDCDEK137',
        'dlv_id': '56b53f26-3d7b-481c-9ce1-e660809479c0',
        'min_days_before_expiration_date': 0,
        'title': 'AED - CDEK 137',
        'delivery_company_product': {
          'code': '137',
          'title': '',
        },
      },
      'address': {
        'area': 'Татарстан',
        'city': 'Набережные Челны',
        'country': 'RU',
        'flat': '111',
        'full_address': 'Респ Татарстан, г Набережные Челны, ул Раскольникова, д 17, кв. 111',
        'house': '17',
        'street': 'ул Раскольникова',
      },
      'shipping_date_plan': '2024-04-19T00:00:00',
    },
  },
  'skus': [
    {
      'batch_accounting': true,
      'batch_expdate_mandatory': true,
      'dimensions_fact': {
        'depth': 6,
        'height': 20,
        'width': 6,
      },
      'expiration_months': 36,
      'fragile': false,
      'external_id': 'AED00-000000178155',
      'id': 'AED00-000000178155',
      'image': '\/hlr-system\/206\/997\/554\/812\/160\/44\/100001270405b0.jpg',
      'ImagePreview': '\/hlr-system\/206\/997\/554\/812\/160\/44\/100001270405b0-100.jpg',
      'require_dimensions': false,
      'require_weight': false,
      'title': 'Средство чистящее Пемолюкс Ослепительно белый 480 г',
      'type': 'SKU',
      'weight_fact': 524,
      'weight_plan': 524,
      'barcodes': [
        {
          'active': true,
          'barcode': '9000100980692',
          'type': 'COMMON',
          'used_in_last_supply': true,
        },
      ],
      'merchant': {
        'cam_id': '75638',
        'contract_date': '01.11.2023 0:00:00',
        'contract_num': 'КО-ОП-17\/11\/2023-299',
        'dlv_login': 'sreztsov@samokat.ru',
        'enabled': true,
        'id': '1411d36c-942a-11ee-2396-ac1f6b90eae2',
        'inn': '9701048328',
        'kpp': '770101001',
        'login': 'sreztsov@samokat.ru',
        'ogrn': '1167746803180',
        'title': 'МАРКЕТПЛЕЙС "Мегамаркет"',
        'trade_title': 'Общество с ограниченной ответственностью "МАРКЕТПЛЕЙС"',
      },
    },
    {
      'batch_accounting': true,
      'batch_expdate_mandatory': true,
      'dimensions_fact': {
        'depth': 7,
        'height': 20,
        'width': 7,
      },
      'expiration_months': 36,
      'fragile': false,
      'external_id': 'AED00-000000178228',
      'id': 'AED00-000000178228',
      'image': '\/hlr-system\/206\/932\/206\/812\/141\/849\/100001270397b0.jpg',
      'ImagePreview': '\/hlr-system\/206\/932\/206\/812\/141\/849\/100001270397b0-100.jpg',
      'require_dimensions': false,
      'require_weight': false,
      'title': 'Средство чистящее Пемолюкс Морской бриз 480 г',
      'type': 'SKU',
      'weight_fact': 528,
      'weight_plan': 528,
      'barcodes': [
        {
          'active': true,
          'barcode': '9000100980814',
          'type': 'COMMON',
          'used_in_last_supply': true,
        },
      ],
      'merchant': {
        'cam_id': '75638',
        'contract_date': '01.11.2023 0:00:00',
        'contract_num': 'КО-ОП-17\/11\/2023-299',
        'dlv_login': 'sreztsov@samokat.ru',
        'enabled': true,
        'id': '1411d36c-942a-11ee-2396-ac1f6b90eae2',
        'inn': '9701048328',
        'kpp': '770101001',
        'login': 'sreztsov@samokat.ru',
        'ogrn': '1167746803180',
        'title': 'МАРКЕТПЛЕЙС "Мегамаркет"',
        'trade_title': 'Общество с ограниченной ответственностью "МАРКЕТПЛЕЙС"',
      },
    },
  ],
  'success': true,
}

import React from 'react'
import styles from './webcam-modal.module.scss'
import { Modal, WebcamComponent } from 'src/components/index'
import { ModalProps } from 'src/interfaces'


interface IWebcamModal extends ModalProps {
  handleNewScreenshot: (imgBase64Src: string) => void
}


const WebcamModal = (props: IWebcamModal) => {
  const { isOpen, handleNewScreenshot, onClose } = props

  if (!isOpen) {
    return null
  }
  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      withClose
      onClose={onClose}
      size={'s'}
    >
      <div className={styles.webcamWrapper}>
        <WebcamComponent
          onScreenshot={handleNewScreenshot}
          screenshotFormat="image/jpeg"
        />
      </div>
    </Modal>
  )
}

export default WebcamModal

import React, { useEffect, useRef, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import days from 'dayjs'
import cx from 'classnames'
import isMobile from 'ismobilejs'

import styles from './registries-scan-page.module.scss'

import { Button } from '@consta/uikit/Button'
import { Badge, BadgePropStatus } from '@consta/uikit/Badge'
import { Loader } from '@consta/uikit/Loader'
import { IconExit } from '@consta/icons/IconExit'

import CheckRegistryModal from './CheckRegistryModal'

import { AdvanceModeLock, InputWrapper, UserBlock } from 'src/components'

import { useAppContext } from 'src/context'

import { useGetRegisterById, useGetRegistriesHistory } from 'src/hooks'
import { useSound } from 'src/sound'
import { useNotifications } from 'src/notification'

import { rusToLatin } from 'src/helpers'

import { MONTHS, PATHS } from 'src/config'

import { IRegister, IRegistryListItem } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'

const RegistriesScanPage = () => {
  const [registriesList, setRegistriesList] = useState<IRegistryListItem[]>([])
  const [tableLoading, setTableLoading] = useState<boolean>(false)
  const [showRegistryCheck, setShowRegistryCheck] = useState<boolean>(false)
  const [curRegister, setCurRegister] = useState<IRegister | null>(null)
  const barcodeInput = useRef<HTMLTextAreaElement | HTMLInputElement | null>(
    null,
  )

  const [symbols, setSymbols] = useState<string[]>([])

  const { setAuthorized, advanceMode, setGlobalLoading } = useAppContext()

  const history = useHistory()
  const sound = useSound()
  const notification = useNotifications()
  const getRegister = useGetRegisterById()

  const { data } = useGetRegistriesHistory(setRegistriesList)

  const RegistryItem = ({
    registry,
    handleOpen,
  }: {
    registry: IRegistryListItem
    handleOpen: () => void
  }) => {
    const getStatus = (
      status: string,
    ): {
      label: string
      status: BadgePropStatus
    } => {
      if (status === 'CheckOut') {
        return {
          label: 'проверено',
          status: 'success',
        }
      }
      return {
        label: 'на проверке',
        status: 'warning',
      }
    }

    return (
      <div className={cx(styles.registryItem)} onClick={handleOpen}>
        <div className={styles.left}>
          <div className={styles.date}>
            {registry.datereg ? (
              <>
                <span>
                  {days(registry.datereg).date()}{' '}
                  {MONTHS[days(registry.datereg).month()]}
                </span>
                <span>{days(registry.datereg).format('HH:mm:ss')}</span>
              </>
            ) : null}
          </div>
          <div className={styles.id}>{registry.number}</div>
        </div>
        <div className={styles.right}>
          <Badge
            status={getStatus(registry.status).status}
            label={getStatus(registry.status).label}
            size="s"
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    setGlobalLoading(false)
  }, [])

  useEffect(() => {
    if (symbols.length && symbols[symbols.length - 1] === 'Enter') {
      const formattedBarcode = symbols
        .map((s, i) =>
          s === 'Shift' && /^[a-z]]+$/.test(symbols[i + 1])
            ? s.toUpperCase()
            : s,
        )
        .filter((s) => s !== 'Enter' && s !== 'Unidentified' && s !== 'Shift')
        .join('')
      handleChangeBarcode(formattedBarcode)
      return
    }
  }, [symbols])

  const handleExit = () => {
    history?.push(generatePath(PATHS.OPERATION))
  }

  const blurOrFocusBarcodeInput = (type: 'focus' | 'blur') => {
    if (!barcodeInput?.current) return
    type === 'focus'
      ? barcodeInput.current.focus()
      : barcodeInput.current.blur()
  }

  const handleCloseWithFocusBarcode = (act: any) => {
    act(false)
    blurOrFocusBarcodeInput('focus')
  }

  const getRegisterById = async (id: string) => {
    await getRegister
      .fetch({
        registerId: id,
      })
      .then((result) => {
        if (result.success && result.objects) {
          setCurRegister(result.objects)
          sound?.play('PACKING_PLACE_POSTED')
          setShowRegistryCheck(true)
        }
      })
      .finally(() => {
        setSymbols([])
      })
  }

  const handleChangeBarcode = async (value: TextFieldPropValue) => {
    if (!value) {
      notification?.show('alert', 'Штрихкод не считан')
      return
    }
    const newVal = rusToLatin(value)

    await getRegisterById(newVal)
  }

  const handleConfirm = (register: IRegister) => {
    history.push({
      pathname: generatePath(PATHS.REGISTRY_FORM, {
        registerId: register.number,
      }),
      state: {
        register,
      },
    })
  }

  const handleOpen = (register: IRegistryListItem) => {
    history.push({
      pathname: generatePath(PATHS.REGISTRY_FORM, {
        registerId: register.number,
      }),
    })
  }

  const handleCancel = () => {
    handleCloseWithFocusBarcode(setShowRegistryCheck)
    setCurRegister(null)
  }

  const compareRegistries = (a: IRegistryListItem, b: IRegistryListItem) => {
    return a.status === 'OnСheck' ? -1 : b.status === 'OnСheck' ? 1 : 0
  }

  return (
    <>
      <div
        className={styles.registryMain}
        style={{ height: window.innerHeight }}
      >
        <div className={styles.header}>
          <UserBlock size="m" view="clear" />
          <div className={styles.btns}>
            {!isMobile().any ? <AdvanceModeLock /> : null}
            <Button
              label="Выйти"
              view="ghost"
              iconLeft={IconExit}
              size="s"
              onClick={handleExit}
            />
          </div>
        </div>
        <div className={styles.top}>
          <h3>Сверка реестра</h3>
          {/*<h5>Символы: {symbols.join(', ')}</h5>*/}

          <InputWrapper
            id={'barcode'}
            label={'Отсканируйте штрихкод'}
            handleChange={handleChangeBarcode}
            autoFocus={true}
            size="m"
            className={styles.barcode}
            inputRef={barcodeInput}
            withDelay
          />
        </div>
        {!tableLoading && registriesList?.length ? (
          <>
            <h4 className={styles.subtitle}>Список отсканированных реестров</h4>
            <div className={styles.registriesItems}>
              {registriesList
                .slice()
                .sort(compareRegistries)
                .map((r: IRegistryListItem) => (
                  <RegistryItem
                    key={r.number}
                    registry={r}
                    handleOpen={() => handleOpen(r)}
                  />
                ))}
            </div>
          </>
        ) : null}
        {tableLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : null}
      </div>
      {showRegistryCheck && curRegister ? (
        <CheckRegistryModal
          isOpen={true}
          onClose={handleCancel}
          onMount={() => blurOrFocusBarcodeInput('blur')}
          registry={curRegister}
          handleConfirm={handleConfirm}
        />
      ) : null}
    </>
  )
}

export default RegistriesScanPage

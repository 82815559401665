import { useQuery } from 'react-query'
import api from 'src/api'

import { MOCK_GET_BATCH, provideMock } from 'src/mocks'

import { ApiReqResult, IBatchOrder } from 'src/interfaces'

interface useGetBatchParamsType {
  orderId: string
}

const getBatch = async (params: useGetBatchParamsType) => {
  const path = `/packing/batch/${params.orderId}/start`

  const result = await api.post(path)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface GetBatchResult extends ApiReqResult {
  orders: IBatchOrder[]
}

const useGetBatch = (
  params: useGetBatchParamsType,
  callback?: (orders: IBatchOrder[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<GetBatchResult>(
    [`getBatch`, params],
    provideMock<useGetBatchParamsType, GetBatchResult>(getBatch, params, [
      {
        key: 'orderId',
        value: 'mock',
        result: MOCK_GET_BATCH,
      },
    ]),
    {
      enabled: !!params,
      keepPreviousData: true,
      onSuccess: ({ success, orders }) => {
        if (success) {
          if (callback) {
            callback(orders)
          }
        } else {
          if (error) {
            error()
          }
        }
      },
      onError: () => {
        if (error) {
          error()
        }
      },
      onSettled: () => {
        if (settled) {
          settled()
        }
      },
    },
  ) as { data: GetBatchResult, refetch: any }
}

export default useGetBatch

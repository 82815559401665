import { IUserData } from './user'
import { ValueOf } from '../helpers'

export const DocumentTypeEnum = {
  BILLING: 'BILLING',
  SKU_REPLACE: 'SKU_REPLACE',
  RELABEL: 'RELABEL',
  MOVING: 'MOVING',
  EXTENDED_MOVING: 'EXTENDED_MOVING',
  ORDER: 'ORDER',
  ORDER_BATCH: 'ORDER_BATCH',
  ORDER_PLACE: 'ORDER_PLACE',
  ORDER_CANCEL: 'ORDER_CANCEL',
  TRANSPORT_PLACE: 'TRANSPORT_PLACE',
  PICKING: 'PICKING',
  PLACING: 'PLACING',
  RECEIPT: 'RECEIPT',
  SERVICE: 'SERVICE',
  SUPPLY_PLAN: 'SUPPLY_PLAN',
  SUPPLY_SORTING: 'SUPPLY_SORTING',
  SHIPMENT: 'SHIPMENT',
  SHIPMENT_PLAN: 'SHIPMENT_PLAN',
  UNLOADING: 'UNLOADING',
  PHYSICAL_SET_ORDER_DOC: 'PHYSICAL_SET_ORDER_DOC',
  PHYSICAL_SET_ORDER_PLACE_DOC: 'PHYSICAL_SET_ORDER_PLACE_DOC',
  CHANGE_SKU_STOCK: 'CHANGE_SKU_STOCK',
  RETURN: 'RETURN',
  RETURN_PLACE: 'RETURN_PLACE',
  INVENTORY: 'INVENTORY',
  INVENTORY_CELL: 'INVENTORY_CELL',
  TRANSPORTATION: 'TRANSPORTATION',
  PHYSICAL_SET_ORDER: 'PHYSICAL_SET_ORDER',

  PHYSICAL_SET_ORDER_PLACE: 'PHYSICAL_SET_ORDER_PLACE',
  PHYSICAL_SET_ORDER_BATCH: 'PHYSICAL_SET_ORDER_BATCH',

  /* Придуманные, надо ищ изменить */
  REPLACE: "REPLACE",
  TASK_OF_COMPLETING: "TASK_OF_COMPLETING",
  WORKSPACES: "WORKSPACES",
  BILLING_SERVICES: "BILLING_SERVICES",
  TRANSPORTATION_TEMPLATES: "TRANSPORTATION_TEMPLATES",
  CARRYER_QUBY: "CARRYER_QUBY",
} as const
export type DocumentTypeEnum = ValueOf<typeof DocumentTypeEnum>

export enum EntityTypeEnum {
  ORDER = 'ORDER',
  ORDER_PLACE = 'ORDER_PLACE',
  SKU = 'SKU',
  TRANSPORT_PLACE = 'TRANSPORT_PLACE',
  WAREHOUSE_PLACE = 'WAREHOUSE_PLACE',
}

export interface ILogScanBarcodeData {
  /** Отсканированный ШК */
  barcode: string
  /** Комментарий */
  comment?: string
  /** Идентификатор документа, в рамках которого производилось сканирование */
  document_id?: string
  /** Тип документа, в рамках которого производилось сканирование */
  document_type?: DocumentTypeEnum
  /** Тип сущности */
  entity_type?: EntityTypeEnum
  /** Идентификатор продавца */
  merchant_id?: string
  /*Тип приёмки */
  acceptance_type?: string | number,
  /*Кратность короба/палеты*/
  multiplicity?: string | null
}

export interface IChangeItemChange {
  /** Новое значение */
  after: string
  /** Старое значение */
  before: string
  /** Параметр */
  field: string
}

const HistoryChangesActionType = {
  create: 'CREATE',
  change: 'CHANGE',
  delete: 'DELETE',
} as const
export const HistoryChangesDescrType = {
  [HistoryChangesActionType.create]: 'Создание',
  [HistoryChangesActionType.change]: 'Изменение',
  [HistoryChangesActionType.delete]: 'Удаление',
} as const
export type HistoryChangesActionType = ValueOf<typeof HistoryChangesActionType>

export interface IChangeItem {
  /** Действие */
  action: HistoryChangesActionType
  /** Изменения */
  changes: IChangeItemChange[]
  /** Сущность */
  entity: string
}

export interface IHistoryChanges {
  /** Время изменений */
  date: string
  /** Изменения */
  items: IChangeItem[]
  /** Исполнитель */
  user: IUserData
  /** Рабочее место, с которого выполнены изменения */
  workspace: string
}

import React, { FC } from 'react'
import cx from 'classnames'
import styles from './arm-layout-wrapper.module.scss'


interface ArmLayoutProps {
  pageWrapperClassName?: string
  bodyClassName?: string
  sidebarSlot?: React.ReactNode
  children?: React.ReactNode
}

const ArmLayoutWrapper: FC<ArmLayoutProps> = (props) => {
  const {
    pageWrapperClassName,
    sidebarSlot,
    children,
    bodyClassName
  } = props

  return (
    <div className={cx(styles.main, pageWrapperClassName)}>
      {sidebarSlot}
      <div className={cx(styles.bodyWrap, bodyClassName)}>
        {children}
      </div>
    </div>
  )
}

export default ArmLayoutWrapper

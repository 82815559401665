import { IReducerAction } from 'src/interfaces'
import { IReducer } from '../types/storeInterfaces'

import {
  SET_CONFIRM_MODAL,
  SET_ERROR_DATA_MODAL,
  SET_INPUT_REF,
  SET_JOIN_CODE_MODAL_VISIBLE,
  SET_REPRINT_STICKERS_FOR_PRINT,
  SET_STICK_STICKER_MODAL_VISIBLE,
} from './actions'

export const getInitialState = (): IReducer => ({
  inputRef: null,

  joinCodeModalVisible: false,
  confirmModal: null,
  errorDataModal: null,
  stickStickerModalVisible: false,

  reprintFiles: [],
})

export const reducer = (
  state: IReducer,
  action: IReducerAction,
): IReducer => {
  const { type, payload } = action

  switch (type) {

    case SET_JOIN_CODE_MODAL_VISIBLE:
      return {
        ...state,
        joinCodeModalVisible: payload,
      }

    case SET_CONFIRM_MODAL:
      return {
        ...state,
        confirmModal: payload,
      }

    case SET_INPUT_REF:
      return {
        ...state,
        inputRef: payload,
      }


    case SET_STICK_STICKER_MODAL_VISIBLE:
      return {
        ...state,
        stickStickerModalVisible: payload,
      }


    case SET_ERROR_DATA_MODAL:
      return {
        ...state,
        errorDataModal: payload,
      }


    case SET_REPRINT_STICKERS_FOR_PRINT:
      return {
        ...state,
        reprintFiles: payload,
      }
    default:
      return state
  }
}

import React, { createContext, useContext, useEffect, useReducer } from 'react'
import { v4 as uuidv4 } from 'uuid'

import Notifications, { INotification, NotificationOptions } from '../Notifications'

import { useSound } from 'src/sound'

interface INotificationContext {
  state: { [key: string]: INotification }
  show: (
    type: "success" | "system" | "alert" | "warning",
    content: string | React.ReactNode,
    options?: NotificationOptions,
  ) => void
  clear: (id: string) => void
  clearAll: () => void
  playSound: (type: string, options?: NotificationOptions) => void
}

export const NotificationContext = createContext<
  INotificationContext | undefined
>(undefined)

export const useNotifications = () => {
  const context = useContext(NotificationContext)

  return context
}

const reducer = (state: { [key: string]: INotification }, action: any) => {
  switch (action.type) {
    case 'addNotification':
      return { [action.newNotification.id]: action.newNotification, ...state }
    case 'removeNotification':
      return (() => {
        const newNotifications = { ...state }
        delete newNotifications[action.id]
        return newNotifications
      })()
    case 'clearAll':
      return {}
    default:
      return state
  }
}

export const NotificationProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const sound = useSound()

  const [state, dispatch] = useReducer(reducer, {})

  const show = (
    type: string,
    content: string | React.ReactNode,
    options?: NotificationOptions,
  ) => {
    const id = options?.id || uuidv4()
    dispatch({
      type: 'addNotification',
      newNotification: {
        id,
        type,
        content,
        options,
      },
    })
  }

  useEffect(() => {
    window.showNotification = show //TODO better
    window.clearNotification = clear
  }, [])

  const clear = (id: string) => {
    const newNotifications = { ...state }
    delete newNotifications[id]

    dispatch({ type: 'removeNotification', id })
  }

  const clearAll = () => dispatch({ type: 'clearAll' })

  const playSound = (type?: string, options?: NotificationOptions) => {
    if (options?.soundName) {
      sound?.play(options?.soundName)
    } else {
      if (type === 'alert' && !options?.allWidth) {
        sound?.play('OTHER_ERROR')
      }
    }
  }

  return (
    <NotificationContext.Provider
      value={{
        state,
        show,
        clear,
        clearAll,
        playSound,
      }}
    >
      {children}
      <Notifications />
    </NotificationContext.Provider>
  )
}

import { IOrder, IOrderPackagingOption, IOrderPackagingPlace } from './order'
import { ApiReqResult } from '../api'

export interface IBatchOrders {
  orders: IBatchOrder[]
}

export interface IBatchOrder extends IOrder {
  packagings: IOrderPackagingOption[]
}

export interface IOrderBatchPackagingPlace extends IOrderPackagingPlace {
  batchOrderId?: string
  batchOrderDate?: string
}

export interface IBatchItem {
  image: string
  title: string
  barcode: string
  orderId: string
  orderDate: string
  serial: string
  placeId: string
}

export interface IFBSOrderItem extends ApiReqResult {
  order: IOrder
  packagings: IOrderPackagingOption[]
}

export enum fbsBatchStatuses {
  prepared = 'prepared',
  in_selection = 'in_selection',
  selected = 'selected',
  in_sorting = 'in_sorting',
  sorted = 'sorted',
  in_packaging = 'in_packaging',
  packaged = 'packaged',
  in_shipment = 'in_shipment',
  shipped = 'shipped',
  in_preparation = 'in_preparation',
}

export interface IFBSOrderBatch {
  id: string
  uuid?: string
  orders: IFBSOrderItem[]
  status: fbsBatchStatuses
}

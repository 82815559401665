import { useAppContext } from 'src/context'

export const useUser = () => {
  const { currentUser } = useAppContext()


  /** Проверка на корректность рабочего места */
  const checkCurrentWorkspace = (workspaceBarcode?: string) => {
    return workspaceBarcode === currentUser.workTable
  }

  return {
    currentUser,
    checkCurrentWorkspace
  }
}

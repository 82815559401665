import React, { useRef } from 'react'
import styles from './table-columns-settings.module.scss'
import { Checkbox } from '@consta/uikit/Checkbox'
import { Button } from '@consta/uikit/Button'
import { IconSettings } from '@consta/icons/IconSettings'
import { ContextMenu } from '@consta/uikit/ContextMenu'
import { IconComponent } from '@consta/icons/Icon'
import { ButtonPropView } from '@consta/uikit/__internal__/src/components/Button/Button'
import { Direction } from '@consta/uikit/__internal__/src/components/Popover/Popover'
import {
  ContextMenuPropGetItemLabel,
  ContextMenuPropGetItemLeftSide,
} from '@consta/uikit/__internal__/src/components/ContextMenu/types'

interface Item<ITEM> {
  label: string
  key: string
  column: ITEM
}
interface IProps<ITEM> {
  menuVisible: boolean
  items: Item<ITEM>[]
  getLeftSideBar?: ContextMenuPropGetItemLeftSide<any>
  hiddenColumnsKeys?: string[]
  handleChangeCheckbox: (key: string, checked: boolean) => void
  getLabel?: ContextMenuPropGetItemLabel<any>
  btnView?: ButtonPropView
  btnLabel?: string
  btnOnClick: () => void
  btnIconRight?: IconComponent
  direction?: Direction
  handleClickOutside?: () => void
}

const renderLeftSide = <ITEM,>(
  item: Item<ITEM>,
  onChange: (key: string, checked: boolean) => void,
  hiddenColumnsKeys: string[] = []
) => {
  const checked = !hiddenColumnsKeys.includes(item.key)
  return (
    <Checkbox
      label={''}
      className={styles.checkbox}
      checked={checked}
      onChange={(e) => onChange(item.key, e.checked)}
    />
  )
}

const TableColumnsSettings = <ITEM,>(props: IProps<ITEM>) => {
  const {
    hiddenColumnsKeys = [],
    menuVisible,
    items,
    btnView = 'ghost',
    btnLabel = 'Настройки колонок',
    getLabel = (item) => item.label,
    getLeftSideBar = (item) => renderLeftSide(item, handleChangeCheckbox, hiddenColumnsKeys),
    btnOnClick,
    btnIconRight = IconSettings,
    direction = 'downRight',
    handleChangeCheckbox,
    handleClickOutside,
  } = props

  const menuRef = useRef(null)

  return (
    <div className={styles.wrapper}>
      <Button
        view={btnView}
        label={btnLabel}
        onClick={btnOnClick}
        iconRight={btnIconRight}
        ref={menuRef}
        size={'s'}
      />
      <ContextMenu
        isOpen={menuVisible}
        offset={10}
        direction={direction}
        onClickOutside={handleClickOutside}
        items={items}
        getItemLabel={getLabel}
        getItemLeftSide={getLeftSideBar}
        onClick={(e) => {
          // console.log(e.target)
        }}
        anchorRef={menuRef}
        className={styles.contextMenu}
      />
    </div>
  )
}
export default TableColumnsSettings

import { useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult, IAcceptanceSku, IGetSupplyReqObj, ISupply, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from 'src/helpers'

interface GetSuppliesResult extends ApiReqResult, PaginationResult {
  skus: IAcceptanceSku[]
  supplies: ISupply[]
}

const useGetSupplies = (
  params: IGetSupplyReqObj,
  handleSuccess?: (result: GetSuppliesResult) => void,
  handleSettled?: () => void,
  handleError?: () => void,
) => {
  return useQuery(
    [`getSupplies`, params],
    async () => {
      const searchParams = convertObjectToURLParams(params)
      const result = await api.get(`/receiving/supply${searchParams}`)
      return result.data
    },
    {
      keepPreviousData: true,
      onSuccess: (data) => {
        if (data?.success) {
          return handleSuccess?.(data)
        }
        handleError?.()
      },
      onSettled: handleSettled,
      onError: handleError,
    },
  )
}

export default useGetSupplies

import React, { useEffect, useMemo, useState } from 'react'
// @ts-ignore
import eruda from 'eruda'
import isMobile from 'ismobilejs'

import styles from './app.module.scss'

import Main from './Main'

import { useAppContext } from 'src/context'
import { SoundProvider, useSound } from 'src/sound'
import { NotificationProvider } from 'src/notification'

import { Loader } from '@consta/uikit/Loader'

import api from 'src/api'
import { appLocalStorage } from 'src/libs/localStorage'

const GlobalLoader = () => (
  <div className={styles.globalLoader}>
    <Loader />
  </div>
)

const App = () => {
  const {
    globalLoading,
    setGlobalLoading,
    authorized,
    setAuthorized,
    setCurrentUser,
  } = useAppContext()

  const sound = useSound()

  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    const { token, currentUser, currentBase } = appLocalStorage

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`
      setAuthorized(true)
    }

    if (currentUser) {
      setCurrentUser(currentUser)
    }

    if (currentBase) {
      api.defaults.baseURL = `https://${currentBase}`
    }
    setIsReady(true)
    setGlobalLoading(false)
    if (isMobile().any) {
      eruda.init()
    }
  }, [])

  useEffect(() => {
    /**
     * Быстрое временное решение
     * Скрывает проблему ResizeObserver - loop limit exceeded
     * https://stackoverflow.com/a/76216986
     */
    const errorListener = (e: ErrorEvent) => {
      const errMessages = [
        'ResizeObserver loop completed with undelivered notifications.',
        'Script error.',
        'ResizeObserver loop limit exceeded'
      ]
      if (errMessages.includes(e.message)) {
        const resizeObserverErrDiv = document.getElementById('webpack-dev-server-client-overlay-div')
        const resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay')
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    }
    window.addEventListener('error', errorListener)
    return () => {
      window.removeEventListener('error', errorListener)
    }
  }, [])

  return useMemo(
    () => (
      <>
        {isReady ? (
          <SoundProvider>
            <NotificationProvider>
              {globalLoading ? <GlobalLoader /> : null}
              <Main authorized={authorized} />
            </NotificationProvider>
          </SoundProvider>
        ) : null}
      </>
    ),
    [authorized, globalLoading, isReady],
  )
}

export default App

import React from 'react'

import styles from './ConfirmCodeModal.module.scss'

import { JoinCodeForm, Modal } from 'src/components'

import { ModalProps } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Loader } from '@consta/uikit/Loader'
import { ModalPropSize } from '../Modal/Modal'


/**
 * Модальное окно c вводом кода
 */

type ConfirmActionProps = {
  onSubmit: (value: TextFieldPropValue) => void
  title?: string
  subtitle?: string
  codeLength?: number
  confirmLoading?: boolean
  isInteger?: boolean
  size?: ModalPropSize
} & ModalProps

const ConfirmCodeModal = (props: ConfirmActionProps) => {
  const {
    isOpen,
    onClose,
    codeLength,
    onSubmit,
    title = "Введите код входа",
    subtitle,
    confirmLoading,
    isInteger,
    size='s',
  } = props

  return (
    <Modal
      withClose
      hasOverlay
      isOpen={isOpen}
      className={styles.modal}
      title={title}
      subtitle={subtitle}
      closeClass={styles.closeClass}
      size={size}
      onClose={onClose}
      onOverlayClick={onClose}
    >
      {confirmLoading ? <Loader className={styles.loading} /> : null}
      <JoinCodeForm
        isInteger={isInteger}
        onSubmit={onSubmit}
        codeLength={codeLength}
      />
    </Modal>
  )
}

export default ConfirmCodeModal

import React from 'react'

import styles from './ImageConfirmModal.module.scss'
import { Button } from '@consta/uikit/Button'

import { InputWrapper, Modal } from 'src/components'

import { ModalProps } from 'src/interfaces'
import cx from 'classnames'
import { Loader } from '@consta/uikit/Loader'

/**
 * Модальное окно с подтверждением по изображением
 * (пример подтверждение упаковки по изображению ШК упаковки)
 * */

interface ChoicePrintFormsProps extends ModalProps {
  onSubmit: (barcode: string) => void
  title?: string
  subtitle?: string
  confirmImg?: string
  confirmImgClassname?: string
  error?: string
  imgLoading?: boolean
}

const ImageConfirmModal = (props: ChoicePrintFormsProps) => {
  const {
    isOpen,
    error,
    onClose,
    onSubmit,
    title = 'Подтвердите по изображению',
    subtitle,
    confirmImg,
    confirmImgClassname,
    imgLoading,
  } = props

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      title={title}
      subtitle={subtitle}
      size='m'
    >
      <div className={styles.selectWrap}>
        {
          imgLoading
            ? <Loader className={styles.loader}/>
            : confirmImg
              ? <img src={confirmImg} className={cx(styles.image, confirmImgClassname)} />
              : <h3 style={{ color: 'darkred' }}>Нет изображения :(</h3>
        }
        <InputWrapper
          className={styles.input}
          withDelay
          placeholder={'Отсканируйте изображение, чтобы подтвердить'}
          id={'packaging_barcode'}
          error={error}
          handleChange={(barcode) => {
            onSubmit(String(barcode))
          }}
          autoFocus
          size='l'
        />
      </div>
      <div className={styles.btns}>
        <Button
          label='Отмена'
          view='ghost'
          className={styles.btn}
          onClick={onClose}
        />
      </div>
    </Modal>
  )
}

export default ImageConfirmModal

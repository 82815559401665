import { useQuery } from 'react-query'
import api from 'src/api'

const useGetRoles = (enabled: boolean = true) => {
  const urls = ['/warehouse/role', '/enum/privilege']
  const fetchRoles = async () => {
    return await Promise.all(
      urls.map(async (url) => {
        const result = await api.get(url, {})

        return result.data
      }),
    )
  }

  return useQuery(['getRoles'], fetchRoles, { enabled })
}

export default useGetRoles

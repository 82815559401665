import React from 'react'
import cx from 'classnames'

import styles from './open-close-badge.module.scss'
import { Badge, BadgePropForm, BadgePropSize } from '@consta/uikit/Badge'
import { BadgePropView } from '@consta/uikit/__internal__/src/components/Badge/Badge'

interface AdvanceModeLockProps {
  isOpen: boolean
  view?: BadgePropView
  form?: BadgePropForm
  size?: BadgePropSize
  className?: string
}

const OpenCloseBadge = (props: AdvanceModeLockProps) => {
  const {
    isOpen,
    className,
    view = 'stroked',
    form = 'round',
    size = 'm',
  } = props
  return (
    <Badge
      className={cx(styles.badge, className)}
      label={!isOpen ? 'Закрыто' : 'Открыто'}
      view={view}
      form={form}
      size={size}
      status={!isOpen ? 'success' : undefined}
    />
  )
}

export default OpenCloseBadge

import { useMutation } from 'react-query'
import api from 'src/api'

export interface IProps {
  enabled: boolean
  login: string
  name: string
  password?: string
  patronymic: string
  position?: string | null
  /** Идентификаторы ролей */
  roles: string[]
  surname: string
}

const useCreateUser = () => {
  const createUser = async (props: IProps) => {
    const result = await api.post('/warehouse/user', props)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(createUser)
}

export default useCreateUser

import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'

type FilterProps = {
  onChangeFilterState?: () => void
}


export const useFilterState = <T = Record<string, any>>(props?: FilterProps) => {
  const history = useHistory()

  const searchURI = history?.location?.search
  const searchStr = decodeURI(searchURI)?.substring(1)
  const defaultFilterState = searchStr ? JSON.parse(searchStr) : {}

  /** Стейт для поиска и отображения данных фильтров */
  const [filterState, setFilterState] = useState<T>(defaultFilterState)

  // сохраняем фильтры в url
  useEffect(() => {
    const filterStateStr = JSON.stringify(filterState)
    if (filterStateStr === searchStr) return
    history?.push({search: filterStateStr})
    props?.onChangeFilterState?.()
  }, [searchStr, filterState, history])

  return { filterState, setFilterState }
}
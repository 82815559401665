import {
  IAcceptanceSku,
  IBarcode,
  IBatch,
  ConditionEnum,
  ISupplyItem,
  ISupplyReceipt,
  ISupplyReceiptItem, TrustBox, TrustBoxItem,
} from '../interfaces'
import { cloneDeep } from './index'

interface ItemWithBatches extends ISupplyReceiptItem {
  batch: IBatch,
  condition: ConditionEnum,
}
export const combinePlanItemsWithBatches = (planItems: ISupplyItem[]): ISupplyItem[] => {
  return planItems.reduce((acc, item) => {
    let alreadyAddedItem = acc.find(itemAcc => (
      (itemAcc.sku_batch_id === item.sku_batch_id)
      && (itemAcc.sku_id === item.sku_id)
    ))
    if (!alreadyAddedItem) {
      return [...acc, item]
    }
    alreadyAddedItem.quantity += item.quantity
    return acc
  }, [])
}
const combineItemsWithBatches = (
  tares: ISupplyReceipt[],
  supplyItem: ISupplyItem,
  originalItem?: IAcceptanceSku,
) => {
  /** Комбинируем товары париям (+ годность товара) */
  const batches = originalItem?.batches
  return tares.reduce((tareItemsAccum, tare: ISupplyReceipt) => {
    const itemsInTare = tare.items.filter(tareItem => {
      return tareItem.sku_id === originalItem?.id
    })
    itemsInTare.forEach((tareItem) => {
      const correctBatch = batches.find(batch => batch.id === tareItem.sku_batch_id)
      if (!correctBatch) {
        return
      }
      let alreadyAddedItem = tareItemsAccum.find(tareItemAcc => (
        tareItemAcc.sku_batch_id === tareItem.sku_batch_id &&
        tareItemAcc.sku_id === tareItem.sku_id &&
        tareItemAcc.condition === tare.condition
      ))

      if (!alreadyAddedItem) {
        const newItem = {
          batch: correctBatch,
          ...tareItem,
          condition: tare.condition
        }
        tareItemsAccum.push(newItem)
        return
      }
      alreadyAddedItem.quantity += tareItem.quantity
    })

    return tareItemsAccum
  }, [] as ItemWithBatches[])
}

/* Подсчитываем принятые товары находящиеся в позиции коробка */
const calculateTrustBoxItems = (
  acc: number,
  supplyItem: ISupplyItem,
  trustBoxItems: TrustBoxItem[]
) => {
  return acc + trustBoxItems.reduce((trustBoxAcc, trustBoxItem) => {
    return (supplyItem.sku_id === trustBoxItem?.sku_id)
      ? trustBoxAcc + trustBoxItem.quantity
      : trustBoxAcc
  }, 0)
}

/* Подсчитываем принятые товары в таре */
const calculateTareItemCount = (
  tare: ISupplyReceipt,
  supplyItem: ISupplyItem,
  trustBoxes: TrustBox[]
) => {
  return tare?.items?.reduce(
    (acc: number, tareItem: ISupplyReceiptItem) => {
      const trustBoxItems = trustBoxes.find(box => box.barcode === tareItem.trust_acceptance_box_barcode)?.items
      // Подсчет коробок
      if (trustBoxItems) {
        const res = calculateTrustBoxItems(acc, supplyItem, trustBoxItems)
        return res
      }
      return (
        (tareItem.sku_id === supplyItem?.sku_id)
          ? acc + (tareItem.quantity * tareItem.multiplicity)
          : acc
      )
    },
    0,
  )
}

/* Подсчитываем принятые товары по кондиции */
const combineItemBySku = (
  accumItems: ISupplyItem[],
  newAccumItems: ISupplyReceiptItem[],
) => {
  /** формируем из добавленног товара, плановый с количеством 0, так как он не входил в планы */
  return newAccumItems.reduce(
    (accum: ISupplyItem[], accumItem: ISupplyReceiptItem): ISupplyItem[] => {
      const currentItem = accum.find(
        (accItem) => accItem.sku_id === accumItem.sku_id,
      )
      if (currentItem) {
        return accum
      }
      return [
        ...accum,
        {
          scan_serial_numbers: [],
          quantity: 0,
          sku_batch_id: accumItem.sku_batch_id || '',
          sku_id: accumItem.sku_id,
          id: accumItem.id,
        },
      ]
    },
    accumItems,
  )
}

const calculateGoodOrDefectiveItemsCount = (
  tares: ISupplyReceipt[],
  supplyItem: ISupplyItem,
  trustBoxes: TrustBox[]
) => {
  const goodConditions = [ConditionEnum.GOOD, ConditionEnum.HIDDEN_WRONG_SERIAL_NUMBER, ConditionEnum.HIDDEN_GOOD]
  const defectConditions = [ConditionEnum.DEFECT, ConditionEnum.HIDDEN_DEFECT]
  let conditionKey = 'good'
  return tares.reduce((acc, tare: ISupplyReceipt) => {
    const findItemCount = calculateTareItemCount(tare, supplyItem, trustBoxes)
    if (defectConditions.includes(tare.condition)) conditionKey = 'defect'
    if (goodConditions.includes(tare.condition)) conditionKey = 'good'
    return {
      ...acc,
      [conditionKey]: acc[conditionKey] + findItemCount,
    }
  }, { defect: 0, good: 0 })
}


const getNonePlannedSupplyItems = (tares: ISupplyReceipt[], planItems: ISupplyItem[]) => {
  return tares
    .reduce((accum: any[], tare): any[] =>
        combineItemBySku(accum, tare.items), [])
    .filter((tareItem) => !planItems.find(
      (planItem) => tareItem.sku_id === planItem.sku_id)
    )
}

export interface IDiscrepanciesItem {
  id: string,
  skuId: string,
  article: string,
  title: string,
  image: string,
  barcodes: IBarcode[],
  plan: number | string,
  fact: number | string,
  defect: number | string,
  shortages: number | string,
  surplus: number | string,
  originalSku: IAcceptanceSku | null,
  supplyItem: ISupplyItem | null,
  multiplicity?: number | string
  itemsWithBatch?: ItemWithBatches[]
}
/**
 * Функция для генерации отчета по приемке
 * (Переиспользуется в "Операции и документы")
 * */
export const generateDiscrepancies = (
  originalItems: IAcceptanceSku[],
  planItems: ISupplyItem[],
  tares: ISupplyReceipt[],
  trustBoxes: TrustBox[] = [],
): IDiscrepanciesItem[] => {
  const planItemsWithTrustBoxes = planItems
  const originalSkusCopy = cloneDeep(originalItems)
  const combinedPlanItemsCopy = combinePlanItemsWithBatches(cloneDeep(planItemsWithTrustBoxes))
  const taresCopy = cloneDeep(tares)
  /** Товары которые не вошли в планы */
  const notPlannedSupplyItems = getNonePlannedSupplyItems(taresCopy, combinedPlanItemsCopy)
  return (
    [...combinedPlanItemsCopy, ...notPlannedSupplyItems].map((item: ISupplyItem) => {
      const originItem = originalSkusCopy?.find(
        (curItem) => curItem?.id === item?.sku_id,
      )
      if (!originItem) {
        return {
          id: item.id,
          skuId: '',
          article: 'Товар не найден',
          title: 'Товар не найден',
          image: '',
          barcodes: [],
          plan: 0,
          fact: 0,
          defect: 0,
          shortages: 0,
          surplus: 0,
          originalSku: null,
          supplyItem: null,
          itemsWithBatch: undefined,
          multiplicity: 1,
        }
      }
      const {good: fact, defect: defective} = calculateGoodOrDefectiveItemsCount(
        taresCopy,
        item,
        trustBoxes
      )
      const itemsWithBatches = combineItemsWithBatches(
        taresCopy,
        item,
        originItem,
      )

      const planQuantity = item?.quantity || 0
      const multiplicity = item?.multiplicity || 1

      return {
        id: item.id,
        skuId: originItem.id,
        article: originItem.article,
        title: originItem.title,
        image: originItem.image,
        barcodes: originItem.barcodes,
        plan: planQuantity,
        multiplicity: multiplicity,
        fact: fact,
        defect: defective,
        shortages:
          planQuantity - fact - defective > 0
            ? planQuantity - fact - defective
            : 0,
        surplus:
          fact + defective - planQuantity > 0
            ? fact + defective - planQuantity
            : 0,
        originalSku: originItem,
        supplyItem: item,
        itemsWithBatch: itemsWithBatches
      }
    }) || []
  )
}

import { PrintErrorCodes } from 'src/hooks'
import { ErrorCodes, IError } from '../interfaces'

export const parseError = (err: any) => {
  console.error('ERROR:')
  console.error(err)
}


/** Обработка ошибок */
export const errorHelper = (e: unknown): IError => {
  const isStringError = typeof e === 'string'
  const isInstanceofError = e instanceof Error
  let error = e as IError
  if (!error || isStringError || isInstanceofError) {
    let message = 'Ошибка с фронта, нужно проверить логи'
    if (isStringError) {
      message = e
    }
    if (isInstanceofError) {
      message = e.message
    }
    console.error(e, error)
    return { code: ErrorCodes.FRONT_UNKNOWN_ERROR, message: message }
  }
  return error
}
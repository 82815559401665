import React, { useState } from 'react'

import { Button } from '@consta/uikit/Button'
import { DimensionsInput, EnterHandler, Modal } from 'src/components'
import { IDimensions, IOrderPackagingOption, ModalProps } from 'src/interfaces'

import styles from './choice-oversized-package-modal.module.scss'
import { convertDimensionsStr } from '../../../../helpers'
import { useRefsSwitcher } from '../../../../hooks'


interface ChoiceOversizedPackageModalProps extends ModalProps {
  packagingOptions?: IOrderPackagingOption[]
  selectedPackId?: string
  onBack: () => void
  onSubmit: (packageId: string, options: { dimensions?: IDimensions }) => void
}

const ChoiceOversizedPackageModal = (props: ChoiceOversizedPackageModalProps) => {
  const { isOpen, onBack, onSubmit, selectedPackId, packagingOptions } = props

  const {
    refsObj,
    next,
    setFocusIndex
  } = useRefsSwitcher({ refsKeys: ['width', 'height', 'depth'] })

  const [value, setValue] = useState<IDimensions>({
    width: null,
    height: null,
    depth: null,
  })

  const isInValid = Object.entries(value).some(([key, value]) => !Number(value))

  const handleSubmit = () => {
    if (isInValid) {
      next();
      return
    }
    const otherPack = packagingOptions?.find((pack) =>
      pack.barcodes.find((barcode) => barcode === 'other_pack'),
    )
    onSubmit(selectedPackId || otherPack?.id || 'other_pack', {
      dimensions: convertDimensionsStr('sm', 'mm', value),
    })
  }

  return (
    <EnterHandler onEnter={handleSubmit}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.wrapper}
        title='Габариты нестандартного места'
        subtitle='Введите габариты нестандартного места упаковки'
      >
        <div className={styles.dimensions}>
          <DimensionsInput
            value={value}
            onChange={setValue}
            floatNumbersInputs
            autoFocus
            widthRef={refsObj.width.ref}
            heightRef={refsObj.height.ref}
            depthRef={refsObj.depth.ref}
            onFocus={setFocusIndex}
          />
        </div>

        <div className={styles.btns}>
          <Button label='Назад' view='ghost' onClick={onBack} />
          <Button
            label='Подтвердить'
            disabled={isInValid}
            onClick={handleSubmit}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ChoiceOversizedPackageModal

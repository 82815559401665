import { useMutation } from 'react-query'
import api from 'src/api'

// http://wms-test.kak2c.ru/warehouse-api/warehouse/role/{role_id}
const useDeleteRole = () => {
  const deleteRole = async (roleId: string) => {
    const result = await api.delete(`/warehouse/role/${roleId}`)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(deleteRole)
}

export default useDeleteRole

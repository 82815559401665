import { IPrintOptions, usePrint } from './print'
import useGetTareStickers from '../../tare/useGetTareStickers'
import { usePreviewBase64Pdf } from '../usePreviewBase64Pdf'
import { useCallback } from 'react'


/** Печать стикера тары */

interface printResult {
  printTaresSticker: (barcodes: string[],  isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}

export const usePrintTareSticker = (): printResult => {
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const { printSticker } = usePrint()
  const { mutateAsync: getTareSticker } = useGetTareStickers()

  const printTaresSticker = useCallback(async (barcodes: string[], isPreview?: boolean, options: IPrintOptions = {}) => {
    return await getTareSticker(barcodes)
      .then((result) => {
        if (!result) { return }
        const { stickers, printer } = result
        stickers.forEach(sticker => {
          if (isPreview) {
            previewBase64Pdf(sticker.content, 'Стикер тары')
            return
          }
          printSticker(printer, sticker.content, {
            copies: 1,
            ...options
          })
        })
      })
      .catch(() => {

      })
  }, [getTareSticker, previewBase64Pdf, printSticker])

  return {
    printTaresSticker,
  }
}
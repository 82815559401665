import { useQuery } from 'react-query'

import api from 'src/api'

import {
  ApiReqResult,
  IPaginationBase,
  IPhysicalSetOrderDto,
  PaginationResult,
  PhysicalSetOrderStatusEnum,
} from 'src/interfaces'
import { convertObjectToURLParams } from 'src/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'


export interface IPhysicalSetOrderListParams extends Partial<IPaginationBase> {
  search?: string
  from?: string
  to?: string
  sku_ids?: string[]
  statuses?: PhysicalSetOrderStatusEnum[]
  in_batch?: boolean
}

interface IResult extends ApiReqResult, PaginationResult {
  orders: IPhysicalSetOrderDto[]
}

const getPhysicalSetOrderList = async (params: IPhysicalSetOrderListParams = {}): Promise<IResult> => {
  const searchParams = convertObjectToURLParams(params)
  const result = await api.get(`/physical-set-order${searchParams}`)
  if (!result?.data?.success) {
    throw new Error('not success')
  }
  return result.data
}

export const useFetchPhysicalSetOrderList = () => ({
  fetch: (params?: IPhysicalSetOrderListParams) => getPhysicalSetOrderList(params),
})


export const useGetPhysicalSetList = (
  params?: IPhysicalSetOrderListParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IResult>(
    [`getPhysicalSetOrderList`, params],
    async () => getPhysicalSetOrderList(params),
    {
      keepPreviousData: true,
      ...options,
    },
  )
}


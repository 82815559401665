import { useMutation } from 'react-query'
import api from 'src/api'

export interface IProps {
  data: string[]
}

// http://wms-test.kak2c.ru/warehouse-api/order/ship
const useCreateShipOrders = () => {
  const createShipOrders = async ({ data }: IProps) => {
    const result = await api.post(`/order/ship`, data)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(createShipOrders)
}

export default useCreateShipOrders

import { useMutation } from 'react-query'
import api from 'src/api'
import { ApiReqResult, IPlacing, ISku } from 'src/interfaces'


export interface GetPlacementResponseType extends ApiReqResult {
  placings: IPlacing[]
  placing: IPlacing
  skus: ISku[]
}

const useGetPlacement = () => {
  const fetchPlacement = async ({ id = '', isDoc = false }) => {
    let result

    if (isDoc) {
      result = await api.get(`/placing/${id}`)
    } else {
      result = await api.get(`/placing?tare_barcode=${id}`)
    }

    if (result?.status === 200) {
      return result.data as GetPlacementResponseType
    }
  }

  return useMutation('getPlacementList', fetchPlacement)
}

export default useGetPlacement

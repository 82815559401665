import { useMutation } from 'react-query'
import api from 'src/api'
import { ConditionEnum, IContainer, IPagination } from 'src/interfaces'

export interface TareGenerateArgs {
  // Кол-во тар
  count: number
  open?: boolean
  condition?: ConditionEnum
}

// response types
export interface TareGenerateResponseType {
  containers: IContainer[]
  errors: Error[]
  pagination: IPagination
  success: boolean
}

export interface Error {
  code: string
  message: string
}

const useGenerateTare = () => {
  const generateTare = async (args: TareGenerateArgs) => {
    const result = await api.post(`/warehouse/container/generate`, args)

    if (!result.data.success) {
      throw Error('Ошибка генерации тары')
    }
    return result.data as TareGenerateResponseType
  }

  return useMutation(generateTare)
}

export default useGenerateTare

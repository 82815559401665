import api from 'src/api'
import { IOrderPlace } from 'src/interfaces'

const getOrderPlace = async (barcode: string) => {
  const url = `/order-place/${barcode}`

  const result = await api.get(url, {})

  if (!result.data?.success) {
    throw result.data
  }

  return result.data?.place || {} as IOrderPlace
}

const useGetOrderPlace = () => ({
  fetch: (barcode: string) => getOrderPlace(barcode)
})

export default useGetOrderPlace

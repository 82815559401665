import styles from './NomenclatureCell.module.scss'
import React, { FC } from 'react'
import cx from 'classnames'
import { generatePath, Link } from 'react-router-dom'
import { PATHS } from 'src/config'
import { SECTIONS, tabs } from 'src/pages/DirectoriesPage/constants'

export const NomenclatureCell = ({
  className,
  article,
}: {
  article: string
  className?: string
}) => {
  return (
    <div className={cx(styles.nomenclature, className)}>
      {
        article !== 'undefined' &&
        <span className={styles.lightTxt}>{article ? article : ''}</span>
      }
    </div>
  )
}

interface NomenclatureCellProps {
  article: React.ReactNode
  itemId: string
  withNewTab?: boolean
}

export const NomenclatureLinkCell: FC<NomenclatureCellProps> = ({
  article,
  itemId,
  withNewTab,
}) => {
  const linkPath = generatePath(PATHS.DIRECTORIES, {
    page: tabs.guides.value,
    section: SECTIONS.nomenclature.value,
    type: 'item',
    id: itemId || '-',
  })
  return (
    <Link
      to={linkPath}
      onClick={(e) => {
        if (withNewTab) {
          e.preventDefault()
          window.open(linkPath, '_blank')?.focus()
        }
      }}
    >
      {article}
    </Link>
  )
}

export const MOCK_GET_LOG_CHANGES = {
  changes: [
    {
      date: '2019-08-24T14:15:22Z',
      items: [
        {
          action: 'CREATE',
          changes: [
            {
              after: 'string_after',
              before: 'string_before',
              field: 'string_field',
            },
          ],
          entity: 'entity_string',
        },
      ],
      user: {
        enabled: true,
        id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
        login: 'login_ string',
        name: 'name_ string',
        patronymic: 'patronymic_ string',
        position: 'GENERAL_DIRECTOR',
        roles: [
          '497f6eca-6276-4993-bfeb-53cbbbba6f08',
        ],
        surname: 'surname_string',
      },
      workspace: 'workspace_string',
    },
  ],
  pagination: {
    page: 0,
    size: 0,
    total_elements: 0,
    total_pages: 0,
  },
  success: true,
}

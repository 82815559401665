import { useQuery } from 'react-query'
import api from 'src/api'

import { ApiReqResult, StickerWithCopies } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'

interface IParams {
  itemId: string
}
const getSortingItemSticker = async (params: IParams): Promise<IResult> => {
  const result = await api.get(`/crossdocking-sorting/sorted-item/${params.itemId}/sticker`)
  if (!result?.data?.success) {
    throw result
  }
  return result.data
}

interface IResult extends ApiReqResult {
  files: StickerWithCopies[]
}


const useFetchSortingItemSticker = () => ({
  fetch: getSortingItemSticker
})

const useGetSortingItemSticker = (
  params: IParams,
  options?: UseQueryOptions<IResult>,
) => {
  return useQuery<IResult>(
    [`getPackagings`, params],
    () => getSortingItemSticker(params),
      options
  )
}

export { useGetSortingItemSticker, useFetchSortingItemSticker }

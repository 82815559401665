import { useMutation } from 'react-query'

import api from 'src/api'
import { IRefundEditing } from '../../interfaces/refund/refund'


const useUpdateReturn = () => {
  return useMutation(
    async (data: { refund: IRefundEditing; returnId?: string }) => {
      const result = await api.post(`/return${data.returnId ? '/' + data.returnId : ''}`, data.refund)
      if (!result?.data?.success) {
        throw result
      }
      return result.data
    },
  )
}

export default useUpdateReturn

import { useQuery } from 'react-query'

import api from 'src/api'
import { ApiReqResult, DocumentTypeEnum } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { convertObjectToURLParams } from 'src/helpers'
import { MOK_DOCUMENT_STATUSES, provideMock } from 'src/mocks'


/*
 * Статусы документа
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Spravochniki-perechislenij/operation/getDocumentStatuses
 */

interface IGetDocumentStatusesEnumResult extends ApiReqResult {
  /** Значения  */
  values: { title: string, value: string }[]
}

interface IGetDocumentStatusesEnumParams {
  /** Идентификатор сущности */
  type: DocumentTypeEnum
}

const useGetDocumentStatusesEnum = (paramsData: {
  params: IGetDocumentStatusesEnumParams,
  options?: UseQueryOptions<any>
}) => {
  const { params, options } = paramsData

  return useQuery<IGetDocumentStatusesEnumResult>(
    [`getLogChange`, params],
    provideMock<IGetDocumentStatusesEnumParams, IGetDocumentStatusesEnumResult>(
      async () => {
        const query = convertObjectToURLParams({type: params.type})
        const result = await api.get(`/enum/document-status${query}`)
        return result.data
      },
      params,
      [
        {
        key: 'type',
        value: 'mock',
        result: MOK_DOCUMENT_STATUSES,
      },
    ]),
    options
  )
}

export default useGetDocumentStatusesEnum

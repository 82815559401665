import { ApiReqResult, ICellPlace, IError } from 'src/interfaces'
import { useQuery } from 'react-query'
import api from 'src/api'
import { TextFieldPropValue } from '@consta/uikit/TextField'

interface IUseGetCellByBarcodeParams {
  barcode: TextFieldPropValue
}

export const getCellByBarcode = async (params?: IUseGetCellByBarcodeParams) => {
  if (!params?.barcode) {
    throw new Error('Отсутствует barcode ячейки')
  }
  const result = await api.get(
    `/warehouse/cell?barcode=${params.barcode}`,
  )
  if (!result?.data?.success) {
    throw result
  }
  return result.data
}

interface IGetCellByBarcodeResult extends ApiReqResult {
  cells: ICellPlace[]
  errors?: IError[]
}

export const useGetCellByBarcode = (
  params?: IUseGetCellByBarcodeParams,
  callback?: () => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IGetCellByBarcodeResult>(
    [`getCellStockByCellId`, params],
    () => getCellByBarcode(params),
    {
      onSuccess: (data) => {},
      onError: (err) => {
        console.log(err)
      },
      refetchOnWindowFocus: false,
      enabled: false,
    },
  ) as {
    refetch: any
    isLoading: boolean
    isError: boolean
    data: IGetCellByBarcodeResult
  }
}

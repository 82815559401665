import React, { CSSProperties } from 'react'
import styles from './tsd-table-item.module.scss'

import cx from 'classnames'

export interface ITSDTableItemItemColumn {
  columnStyles?: CSSProperties
  columnClassName?: string
  labelClassName?: string
  labelStyles?: CSSProperties
  valueClassName?: string
  valueStyles?: CSSProperties
  label?: React.ReactNode
  value: React.ReactNode
}
export interface ITSDTableItem {
  key: string
  columns: ITSDTableItemItemColumn[]
  className?: string
  status?: string
  onClickRow?: () => void
  rowStyles?: CSSProperties
  itemRef?: React.RefObject<HTMLDivElement>
}

export const TSDTableRow = (props: ITSDTableItem) => {
  const { className, columns, onClickRow, rowStyles, itemRef } = props
  return (
    <div
      ref={itemRef}
      style={rowStyles}
      className={cx(styles.itemWrapper, className)}
      onClick={onClickRow}
    >
      {
        columns.map((column, i) => (
          <div
            key={i}
            className={cx(styles.itemColumn, column.columnClassName)}
            style={column.columnStyles}
          >
            <div
              className={cx(styles.label, column.labelClassName)}
              style={column.labelStyles}
              children={column.label}
            />
            <div
              className={cx(styles.value, column.valueClassName)}
              style={column.valueStyles}
              children={column.value}
            />
          </div>
        ))
      }
    </div>
  )
}

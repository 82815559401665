import React, { useEffect } from 'react'

import { useAppContext } from 'src/context'
import isMobile from 'ismobilejs'

interface EnterHandlerProps extends React.PropsWithChildren<{}> {
  isActive?: boolean
  onEnter?: () => void
}

const EnterHandler = (props: EnterHandlerProps) => {
  const {
    isActive = true,
    children,
    onEnter
  } = props

  const { globalLoading, resetAfkInterval } = useAppContext()

  useEffect(() => {
    if (!onEnter || isMobile().any || !isActive) { return }
    const keyDownEventHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        resetAfkInterval()
        if (!globalLoading) {
          onEnter()
        }
      }
    }
    window.removeEventListener('keyup', keyDownEventHandler, false)
    window.addEventListener('keyup', keyDownEventHandler, false)

    return () => {
      window.removeEventListener('keyup', keyDownEventHandler, false)
    }
  }, [onEnter, globalLoading])

  return <>{children}</>
}

export default EnterHandler

import React, { FC } from 'react'

import noImage from 'src/assets/images/no-image.png'

interface NoImageProps {
  url: string
  size?: number
  altTxt?: string
}

const NoImage: FC<NoImageProps> = ({
  url,
  size = 40,
  altTxt = 'Нет фото',
}) => {
  return (
    <img
      alt={altTxt}
      width={size}
      src={url?.length ? url : noImage}
    />
  )
}

NoImage.displayName = 'NoImage'

export default NoImage

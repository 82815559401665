import api from 'src/api'

import { MOCK_GET_STICKER_BY_PLACE, provideMock } from 'src/mocks'

import { ApiReqResult, OperationTypes } from 'src/interfaces'

interface UseStickerByPlaceParamsType {
  orderId: string
  operationType?: OperationTypes | string
  placeId: string
}

interface GetStickerByPlaceResult extends ApiReqResult {
  stickerExists?: boolean
  sticker: {
    content: string
    printer: string
  }
}

const getStickerByPlace = async (params: UseStickerByPlaceParamsType) => {
  const result = await api.get(
    params.operationType
      ? `/packing/order/${params.orderId}/place/${params.placeId}/sticker?operationType=${params.operationType}`
      : `/packing/order/${params.orderId}/place/${params.placeId}/sticker`,
  )

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

const useGetStickerByPlace = () => ({
  fetch: (params: UseStickerByPlaceParamsType) =>
    provideMock<UseStickerByPlaceParamsType, GetStickerByPlaceResult>(
      getStickerByPlace,
      params,
      {
        key: 'orderId',
        value: 'mock',
        result: MOCK_GET_STICKER_BY_PLACE,
        logData: 'Получение стикера места'
      },
    )(),
})

export default useGetStickerByPlace

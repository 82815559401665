import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import styles from './informer.module.scss'

import { Timer } from '@consta/uikit/Timer'
import { Button } from '@consta/uikit/Button'
import { IInformer } from 'src/interfaces'

interface IInformerProps extends IInformer {

}

const Informer = ({ status, onClose, text, className }: IInformerProps) => {
  const [timeCount, setTimeCount] = useState<number>(5)
  const [timer, setTimer] = useState<any>(null)

  const countDown = () => {
    setTimeCount((state) => state - 1)
  }

  useEffect(() => {
    setTimer(setInterval(countDown, 1000))
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (timeCount <= 0) {
      onClose()
    }
  }, [timeCount])

  const isError = status === 'error'
  const isSuccess = status === 'success'
  const isInfo = status === 'info'
  return (
    <div
      className={`
    ${cx(styles.informer, className)} 
    ${isError ? styles.informerError : ''}
    ${isSuccess ? styles.informerSuccess : ''}
    ${isInfo ? styles.informerInfo : ''}
    `}
    >
      <div className={styles.left}>
        <Timer
          className={styles.timer}
          size="m"
          seconds={timeCount}
          progress={timeCount * 20}
          placeholder=''
        />
        <div className={styles.text}>{text}</div>
      </div>
      <Button
        className={styles.btn}
        size="s"
        onClick={onClose}
        label="Закрыть"
        view="ghost"
      />
    </div>
  )
}

export default Informer

import React from 'react'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IBatch, IError } from '.'
import { IAddress } from './order'

export interface ModalProps {
  isOpen: boolean
  onClose: () => void
  onMount?: () => void
}

export interface IContractorDto {
  external_id: string
  id?: string
  inn: string
  kpp?: string
  legal_address: IAddress
  ogrn?: string
  title: string
}

export interface ISelectListOption {
  id?: string
  label: string | React.ReactNode
  value?: string
  rightSide?: React.ReactNode
}

export interface IMultipleSelectListOption {
  label: React.ReactNode
  value: string
}

export interface IDatabaseSelectOption extends ISelectListOption {
  isTest?: boolean
  label: string
}

export interface ISelectOption {
  label: string
  value: string | number
}

export interface IScanOption extends ISelectOption {
  privilege?: string
}

export interface IDropdownItem {
  name: string
  action: (e: React.SyntheticEvent) => void
  hidden?: boolean
}

export interface IQzOptions {
  printer?: string
  scaleContent?: boolean
  margins?: {
    top: number
    right: number
    bottom: number
    left: number
  }
  size?: {
    width: number
    height: number
  }
  units?: string
  copies?: number
  format?: string
  flavor?: string
}


export interface IObjKeyString {
  [key: string]: any
}

export interface IDimensions extends IObjKeyString {
  width: TextFieldPropValue
  height: TextFieldPropValue
  depth: TextFieldPropValue
}

export interface IDimensionsObj extends IObjKeyString {
  width: number
  height: number
  depth: number
}

export interface ISerialNumber {
  code: string
  template: string
  title: string
  required?: boolean
}

export interface IActionButton {
  id: string
  //level: number
}

export interface IErrorData {
  data: { errors: IError[] }
}

export interface ResError {
  code: number
  message: string
  errorsticker?: {
    content: string
    printer: string
  }
}

export interface IBarcode {
  active?: boolean
  used_in_last_supply?: boolean
  type: string
  barcode: string
}

export interface IEnumValue {
  title: string
  value: string
}

export type IShipmentTypeItem = IEnumValue
export type IDeliveryItem = IEnumValue
export type ICountryItem = IEnumValue

export interface CommonSerialNumberFilled {
  code: string
  value: string
  title?: string
}

export interface IFile {
  /** Идентификатор */
  id: string
  /** Название */
  title: string
  /** ссылка */
  url: string
  /** дата */
  date: string
}

export interface IImage {
  url: string
  preview_url: string
  position: number
}

export interface ISticker {
  content: string
  printer: string
}

export interface IInformer {
  view?: 'filled' | 'bordered'
  status: 'success' | 'error' | 'info'
  onClose: () => void
  text: string
  className?: string
}

export interface IReducerAction {
  type: string
  payload: any
}

export type ITab = {
  label: string
  value: string
}

export type IPrivilegeTab = {
  label: string
  value: string
  privilege: string
}

export enum PrintTypeEnum {
  preview = 'preview',
  print = 'print',
}

export interface ICategory {
  code: string
  title: string
}
export interface IDeliveryCompanyProduct extends ICategory {
  delivery_company: string
}

export interface IDetail {
  description: string
  quantity: number
}

export type StickerFormatType = {
  format?: 'pdf' | 'png'
}

export interface ISelectOrderOption extends ISelectOption {
  type: string
  subtype: string
}


export interface ItemPreviewType {
  image: string
  title: string
  merchant?: string
  barcode?: string
  article?: string
  batch?: IBatch
}

export interface ConfirmType {
  title: string
  subtitle?: string
  confirmWithInput?: boolean
  type?: 'warning' | 'danger' | 'info'
  cancelBtnLabel?: string
  submitBtnLabel?: string
  actionSubmit: (arg?: TextFieldPropValue) => Promise<void> | void
  actionClose?: (arg?: TextFieldPropValue) => Promise<void> | void
  validateCallback?: (arg: TextFieldPropValue) => string | void
}
import api from 'src/api'

import { IContainer, PaginationResult } from 'src/interfaces'
import { useQuery } from 'react-query'
import { convertObjectToURLParams } from '../../helpers'
import { UseQueryOptions } from 'react-query/types/react/types'
import { ApiReqResult } from 'src/interfaces'

interface IResult extends ApiReqResult, PaginationResult {
  containers: IContainer[]
}
interface IParams {
  search: string
}


const useGetTaresList = (
  params: IParams,
  options?: UseQueryOptions<(params: IParams) => Promise<IResult>, any, IResult, [string, IParams]>
) => (
  useQuery(
    [`getTaresList`, params],
    async () => {
      const searchParams = convertObjectToURLParams(params)
      const result = await api.get(`/warehouse/container${searchParams}`)
      if (!result?.data?.success) {
        throw result
      }
      return result.data
    },
    options
  )
)

export default useGetTaresList

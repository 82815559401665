import { useQuery } from 'react-query'

import api from 'src/api'

import {
  ApiReqResult,
  IPagination,
  IPaginationFilter,
  ISku,
  SkuSerialNumberCodesEnum,
  SkuSerialNumberDto,
  SkuSerialNumberStatusEnum,
} from 'src/interfaces'
import { convertObjectToURLParams } from '../../helpers'
import { UseQueryOptions } from 'react-query/types/react/types'


interface IResult extends ApiReqResult {
  serial_numbers: SkuSerialNumberDto[]
  skus: ISku[]
  pagination: IPagination
}

interface IParams extends IPaginationFilter {
  factory?: boolean
  merchant_id?: string
  sku_id?: string
  serial_number_type?: SkuSerialNumberCodesEnum
  statuses?: SkuSerialNumberStatusEnum[]
}

const getSerialNumbers = async (params: IParams) => {
  const urlParams = convertObjectToURLParams(params)
  const result = await api.get(`/items/serial-number${urlParams}`)
  if (!result?.data?.success) {
    throw result
  }
  return result.data
}

export const useGetSerialNumbers = (
  params: IParams,
  options?: UseQueryOptions<IParams,any,IResult,any>
) => {
  return useQuery(
    [`getSerialNumbers`, params],
    () => getSerialNumbers(params),
    options,
  )
}

// import { FieldType } from '../../../components/FilterBlock/types'

import { DocumentTypeEnum } from 'src/interfaces'

export interface TemplateResult {
  children_available_to_create: DocumentTypeEnum[]
  data: TemplateData
  fields: TemplateField[]
  file_list_data: TemplateFileListData[]
  file_lists: TemplateFileList[]
  service_data: TemplateServiceData
  table_data: TemplateTableData[]
  tables: TemplateTable[]
  actions: IAction[]
}

export enum TemplateFieldType {
  BOOLEAN = 'BOOLEAN', // toggle

  // Combobox
  SELECT = 'SELECT',
  SKU_BATCH = 'SKU_BATCH', // спец комбобокс для выбора партии

  // Серч апи Combobox
  DOCUMENT = 'DOCUMENT',

  CONTRACTOR = 'CONTRACTOR',
  CONTRACTORS = 'CONTRACTORS',

  SKU = 'SKU',
  SKUS = 'SKUS',
  MERCHANT = 'MERCHANT',
  MERCHANTS = 'MERCHANTS',
  CELL = 'CELL',
  CELLS = 'CELLS',
  CONTAINER = 'CONTAINER',
  PLACE = 'WAREHOUSE_PLACE',


  // Календарь с датой / время
  DATE = 'DATE',
  DATETIME = 'DATETIME',
  YEARMONTH = 'YEARMONTH',
  TIME = 'TIME',

  // Сложные типы с модалкой
  GROUP = 'GROUP', // Есть вложенные поля, для разворота - заказ группа с адресом Заказ на отгрузку
  JSON = 'JSON', // Редактирование анкеты, анкета отгрузки. В поле meta есть id/value, из них сделать анкету, возврат id/value
  TABLE = 'TABLE', // additional-data, открывать в модалке http://localhost:3000/new-form/RECEIPT/RT-TM-0000-0162 serialNumbers


  // Ккомпоенент импута
  TEXT = 'TEXT',
  TEXT_AREA = 'TEXT_AREA',
  NUMBER = 'NUMBER',
  URL = 'URL',


  // доп поля, не учавствуют в редактирвоании
  IMAGE = 'IMAGE',
  ARTICLE = 'ARTICLE',

  // todo првоерить, его добавил Дима ХЗ зачем
  MULTISELECT = 'MULTISELECT',
  // MERCHANT = 'MERCHANT', // старый тип, в генерации не участвует
}
export enum TempalteAnalyticsType {
  ORDERS = 'ORDERS',
  STORAGE = 'STORAGE',
  SUPPLIES = 'SUPPLIES',
}
export const ANALYTICS_TITLE: Record<TempalteAnalyticsType | string, string> = {
  [TempalteAnalyticsType.ORDERS]: 'Заказы',
  [TempalteAnalyticsType.STORAGE]: 'Склад',
  [TempalteAnalyticsType.SUPPLIES]: 'Поставки',
}

export interface TemplateData {
  uuid: string
  create_ts: string
  deletion_available: boolean
  field_values: TemplateDataFieldValue[]
  activated: boolean
  recovery_available: boolean
  id: string
}

export interface TemplateDataFieldValue {
  id: number
  meta?: any
  value: any
}
export interface IActionsParams {
  id: number
  title: string
  type: TemplateFieldType
  values?: IActionsParamsValues[]
}
export interface IActionsParamsValues {
  title: string
  value: string
}
export interface IAction {
  require_selected_documents?: boolean
  dangerous: boolean
  id: number
  title: string
  params: IActionsParams[]
  table_params?: { id/* tableId */: number, required: boolean }[]
}

export interface TemplateField {
  document_type?: DocumentTypeEnum
  fields: TemplateField[]
  id: number
  read_only: boolean
  related_filed_id?: number
  title: string
  type: TemplateFieldType
  values: TemplateFieldValue[]
  required: boolean
}

export interface TemplateFieldValue {
  title: string
  value: string
}

export interface TemplateFileListData {
  id: number
  items: TemplateFileItem[]
}

export interface TemplateFileItem {
  id: string
  title: string
}

export interface TemplateFileList {
  deletion_available: boolean
  id: number
  title: string
  upload_available: boolean
}

export interface TemplateServiceData {
  items: TemplateServiceDataItem[]
  read_only: boolean
  visible: boolean
}

export interface TemplateServiceDataItem {
  quantity_fact: number
  quantity_plan: number
  service_id: string
}

export interface TemplateTableData {
  id: number
  rows: TemplateTableRow[]
}

export interface TemplateTableRow {
  id?: string
  cells: TemplateTableCell[]
  rows?: TemplateTableRow[]
}

export interface TemplateTableCell {
  additional_data?: TemplateCellAdditionalData
  id: number
  meta?: {
    dto?: any,
    [key: string]: any
  }
  value: string
}

export interface TemplateColumnAdditionalData {
  columns: TemplateTableColumn[]
}
export interface TemplateCellAdditionalData {
  rows: TemplateTableRow[]
}

export interface TemplateTable {
  columns: TemplateTableColumn[]
  creation_available?: boolean
  deletion_available?: boolean
  id: number
  title?: string
}

export interface TemplateTableColumn {
  id: number
  read_only: boolean
  title: string
  related_column_id?: number
  document_type?: DocumentTypeEnum
  type: TemplateFieldType
  required?: boolean
  additional_data?: TemplateColumnAdditionalData
  values?: {
    title: string
    value: string
  }[]
}

export type OnChangeFieldType = (
  columnId: number,
  fieldId: string,
  fieldType: TemplateFieldType,
  value: any, // тут может быть любой тип в зависимости от типа поля
) =>  void


export type CellInfoType = {
  cell: TemplateTableCell,
  column: Omit<TemplateTableColumn, 'values'> & {
    values: { id: string, label: string }[]
    inverse_related_column_id?: number // фронтовый флаг обратной зависимости столбца
  }
}
import { IDimensions, IDimensionsObj } from '../other'
import { ICommonInfoPlaceItem, IOrderPackedPallet, IPlacePacked } from '../order'
import { ApiReqResult } from '../api'
import { IMerchant } from '../acceptance'

export enum palletStatuses {
  new = 'NEW',
  packing = 'PACKING',
  packed = 'PACKED',
}
export interface IPallet {
  id: string
  num?: string
  pallet_num: number
  barcode?: string
  status: palletStatuses
  places: string[]
  weight: number
  dimensions: IDimensionsObj
  packaging_id?: string
}

export interface IPalletPlace {
  id: string
  order_id: string
  packaging_id: string
  sequence_num: number
  weight: number
  items: ICommonInfoPlaceItem[]
  dimensions?: IDimensions
}

export interface IPalletOrder {
  id: string
  merchant?: IMerchant

  /** Номер корзины */
  sorting_number?: string
  /** Селлер */
  seller?: string
  /** ID Селлера */
  seller_id?: string
}
export interface IUseGetPalletResult extends ApiReqResult {
  pallet: IOrderPackedPallet
  places: IPlacePacked[]
  orders: IPalletOrder[]
}

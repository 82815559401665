import { useQuery } from 'react-query'
import api from 'src/api'

const useGetWarehouseSettings = (enabled: boolean = true) => {
  const urls = ['/warehouse', '/warehouse/user', '/enum/country']
  const fetchWarehouseSettings = async () => {
    return await Promise.all(
      urls.map(async (url) => {
        const result = await api.get(url, {})

        if (result?.status === 200) {
          return result.data
        }
      }),
    )
  }

  return useQuery('getWarehouseSettings', fetchWarehouseSettings, { enabled })
}

export default useGetWarehouseSettings

import React from 'react'
import cx from 'classnames'

const IconPrinter = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cx('filled', props.className)}
  >
    <path d="M7 5H17V8H7V5Z" fill="#00395C" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 15V19H17V15H19V10C19 9.44772 18.5523 9 18 9H6C5.44772 9 5 9.44772 5 10V15H7ZM15 14H9V17H15V14ZM16 10.5C16 10.2239 16.2239 10 16.5 10H17.5C17.7761 10 18 10.2239 18 10.5C18 10.7761 17.7761 11 17.5 11H16.5C16.2239 11 16 10.7761 16 10.5Z"
      fill="#00395C"
    />
  </svg>
)
const WhiteIconPrinter = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cx('filled', props.className)}
  >
    <path d="M7 5H17V8H7V5Z" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 15V19H17V15H19V10C19 9.44772 18.5523 9 18 9H6C5.44772 9 5 9.44772 5 10V15H7ZM15 14H9V17H15V14ZM16 10.5C16 10.2239 16.2239 10 16.5 10H17.5C17.7761 10 18 10.2239 18 10.5C18 10.7761 17.7761 11 17.5 11H16.5C16.2239 11 16 10.7761 16 10.5Z"
      fill="#fff"
    />
  </svg>
)
const BlueIconPrinter = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    className={cx('filled', props.className)}
  >
    <path d="M7 5H17V8H7V5Z" fill="rgb(0, 120, 210)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 15V19H17V15H19V10C19 9.44772 18.5523 9 18 9H6C5.44772 9 5 9.44772 5 10V15H7ZM15 14H9V17H15V14ZM16 10.5C16 10.2239 16.2239 10 16.5 10H17.5C17.7761 10 18 10.2239 18 10.5C18 10.7761 17.7761 11 17.5 11H16.5C16.2239 11 16 10.7761 16 10.5Z"
      fill="rgb(0, 120, 210)"
    />
  </svg>
)

export { IconPrinter, WhiteIconPrinter, BlueIconPrinter }

import { useMutation } from 'react-query'
import api from 'src/api'

import { ApiReqResult, IPutPhysicalSetOrderPlaceInnerDto, ISticker } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { MOCK_STICKER_6x6, provideMock } from '../../mocks'

interface IResult extends ApiReqResult {
  sticker: ISticker
  place_id: string
}

interface IParams {
  orderId: string,
  place: IPutPhysicalSetOrderPlaceInnerDto
}


const useCreateEditPhysicalSetPlace = (
  options?: UseMutationOptions<any, any, any>,
) => {
  return useMutation(
    async (params: IParams) => {
      try {
        const result = await provideMock<IParams, { data: IResult }>(
          async () => {
            const path = `/physical-set-order/${params.orderId}/place${params.place.id ? `/${params.place.id}` : ''}`
            return await api.post(path, { place: params.place })
          },
          params,
          [
            {
              key: 'orderId',
              value: 'mock',
              result: {
                data: {
                  success: true,
                  sticker: {
                    content: MOCK_STICKER_6x6,
                    printer: 'sticker_6x6',
                  },
                  place_id: 'test_place_id',
                },
              },
              logData: 'Создаем/редактируем комплект',
            },
          ],
        )()

        if (!result?.data?.success) {
          throw new Error('Ошибка редактирования места')
        }
        return result.data
      } catch (e) {
        throw e
      }
    },
    options,
  )
}

export default useCreateEditPhysicalSetPlace

import { IOrder } from './order'
import { ValueOf } from '../helpers'
import { ICommonDocument } from './documents'


export const PlacingStatusEnum: Record<string, string> = {
  new: 'NEW',
  processing: 'PROCESSING',
  done: 'DONE',
} as const
export type PlacingStatusEnum = ValueOf<typeof PlacingStatusEnum>

export interface IPlacingItem {
  /** ШК ячейки */
  id: string
  /**Идентификатор позиции документа*/
  quantity: number
  /**Кол-во товара*/
  sku_batch_id: string
  /**Идентификатор партии товара*/
  sku_id: string
  /** Идентификатор товара*/
  cell_barcode: string
}

export interface IPlacing extends ICommonDocument{
  /** Позиции документа*/
  items: IPlacingItem[],
  /** Заказы */
  orders: IOrder
  /** Статус */
  status: string
  /** ШК тары */
  tare_barcode: string
}
import React from 'react'
import cx from 'classnames'

import styles from './select-list.module.scss'

import { IMultipleSelectListOption } from 'src/interfaces'
import { Checkbox } from '@consta/uikit/Checkbox'


interface MultipleSelectListProps {
  options: IMultipleSelectListOption[]
  value: string[]
  onChange: (selectedValues: string[]) => void
  className?: string
  height?: number
  onLabelClick?: boolean
}

const SelectMultipleList = (props: MultipleSelectListProps) => {
  const {
    value,
    options,
    onChange,
    className,
    height = 310,
    onLabelClick = false,
  } = props

  const handleClickItem = (id: string) => {
    let res = value.includes(id) ? value.filter(item => item !== id) : [...value, id]
    onChange(res)
  }

  return (
    <div className={cx(styles.wrap, className)}>
      <ul
        className={styles.main}
        style={{ height: `${height}px` }}
      >
        {options.length ? (
          <>
            {options.map((o, i) => {
              const isActive = value.includes(o.value)
              return (
                <li
                  className={cx(styles.li, {[styles.active]: isActive})}
                  key={`${o.value}-${i}`}
                  onClick={!onLabelClick ? () => handleClickItem(o.value) : undefined}
                >
                  <Checkbox checked={isActive} />
                  <span>{o.label}</span>
                </li>
              )
            })}
          </>
        ) : null}
      </ul>
    </div>
  )
}

export default SelectMultipleList

import React from 'react'
import styles from './JoinCodeInfo.module.scss'

interface IVerifiedInfoProps {
  value?: string
}
const JoinCodeInfo = (props: IVerifiedInfoProps) => {
  const { value } = props
  if (!value) return null
  return (
    <p className={styles.joinCode}>Код входа: <b>{value}</b></p>
  )
}
export default JoinCodeInfo

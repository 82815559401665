import styles from './TSDNomenclatureCountingTable.module.scss'
import { TSDNomenclatureCountingTableRow, TSDNomenclatureCountingTableRowProps, TSDTableWrapper } from 'src/components'
import React, { memo } from 'react'
import cx from 'classnames'
import { useVirtualScroll } from '@consta/uikit/useVirtualScrollCanary'
import { useForkRef } from '@consta/uikit/useForkRef'

export type TableProps = {
  items: TSDNomenclatureCountingTableRowProps[]
  maxTableHeight: number
  className?: string
  loading?: boolean
} & TableTotalAmount & EditAmount

type EditAmount = {
  withEditQuantity?: boolean
  withDeleteItem?: boolean
  onClickEdit?: (sku_id: string, sku_batch_id?: string) => void
  onClickDelete?: (itemId: string, sku_id: string, sku_batch_id?: string) => void
}

type TableTotalAmount = {
  withTotalQuantity?: boolean
  addedQuantity?: number
  totalQuantity?: number
}

export const TSDNomenclatureCountingTable = memo((props: TableProps) => {
  const {
    loading,
    items=[],
    maxTableHeight,
    className,
    withTotalQuantity,
    addedQuantity = 0,
    totalQuantity = 0,
    withEditQuantity,
    withDeleteItem,
    onClickEdit,
    onClickDelete
  } = props

  const { listRefs, scrollElementRef, slice, spaceTop } = useVirtualScroll({
    length: items?.length,
    isActive: items?.length > 100,
  });

  return (
    <div className={cx(styles.tableContent, className)}>
      {
        withTotalQuantity ? (
          <b className={styles.total}>{addedQuantity} из {totalQuantity}</b>
        ) : null
      }
      <TSDTableWrapper
        loading={loading}
        header={<></>}
        tableClassName={styles.tableWrapper}
        maxTableHeight={maxTableHeight}
        tableRef={scrollElementRef}
      >
        <div className={styles.th}>
          <div>
            <p>Номенклатура</p>
          </div>
          <div>
            <p>Кол-во</p>
          </div>
        </div>
        <div style={{ marginTop: spaceTop }}>
          {
            items?.slice(...slice).map((stock, i) => (
              <TSDNomenclatureCountingTableRow
                key={`${stock.sku_id}${stock.batchExpDate||''}${i}`}
                id={`${stock.sku_id}${stock.batchExpDate||''}${i}`}
                itemId={stock.id}
                withEditQuantity={withEditQuantity}
                withDeleteItem={withDeleteItem}
                onClickEdit={onClickEdit}
                onClickDelete={onClickDelete}
                itemRef={listRefs[slice[0] + i]}
                {...stock}
              />
            ))
          }
        </div>
      </TSDTableWrapper>
    </div>
  )
})
import { ShippingOptionsType } from 'src/interfaces'

export const MOCK_GET_BATCH = {
  success: true,
  orders: [
    {
      id: 'mock',
      shippingOptions: {
        packingType: ShippingOptionsType.MIX_PACKINGS,
      },
      date: '2021-12-20T14:21:30',
      packing_state: 'packed',
      global_shipping_guidelines: '',
      order_shipping_comments: '',
      require_place_weight: true,
      shipping_form: [],
      delivery: {
        logo: 'https://storage.yandexcloud.net/images-k2c/f6299c22-4d07-4cb2-bcbc-6733612024bf.jpg',
        title: 'Почта России API',
        contract_title: 'POSTAL_PARCEL',
        sorting_center_acceptance_date: '2021-12-21',
        maximum_place_weight: 0,
        maximum_places_count: 1,
      },
      items: [
        {
          id: 'KK1043',
          title: 'стакан',
          article: 'sta',
          quantity: 1,
          image: '',
          image_preview: '',
          require_weight: false,
          require_dimensions: false,
          barcodes: [
            {
              barcode: '70100401010202',
              type: 'COMMON',
            },
            {
              barcode: '2',
              type: 'COMMON',
            },
          ],
          scan_serial_numbers: [],
        },
      ],
      places: [],
      ad_attachments: [],
      packagings: [
        {
          id: 'some-id',
          title: 'Коробка 50х30х20',
          barcodes: ['BX-503020', 'BX*503020'],
        },
        {
          id: 'some-id-2',
          title: 'Коробка 50х30х10',
          barcodes: ['BX-503010'],
        },
        {
          id: 'some-id-3',
          title: 'Коробка 50х30х30',
          barcodes: ['BX-503030'],
        },
      ],
    },
    {
      id: 'mock2',
      shippingOptions: {
        packingType: ShippingOptionsType.MIX_PACKINGS,
      },
      date: '2021-12-24T14:22:00',
      packing_state: 'packed',
      global_shipping_guidelines: '',
      order_shipping_comments: '',
      require_place_weight: true,
      shipping_form: [],
      delivery: {
        logo: 'https://storage.yandexcloud.net/images-k2c/f6299c22-4d07-4cb2-bcbc-6733612024bf.jpg',
        title: 'Почта России API',
        contract_title: 'POSTAL_PARCEL',
        sorting_center_acceptance_date: '2021-12-21',
        maximum_place_weight: 0,
        maximum_places_count: 1,
      },
      items: [
        {
          id: 'KK1042',
          title: 'Ложка',
          article: 'loz',
          quantity: 1,
          image:
            'https://storage.yandexcloud.net/images-k2c/f6299c22-4d07-4cb2-bcbc-6733612024bf.jpg',
          image_preview:
            'https://storage.yandexcloud.net/images-k2c/f6299c22-4d07-4cb2-bcbc-6733612024bf-100.jpg',
          require_weight: false,
          require_dimensions: false,
          barcodes: [
            {
              barcode: '1',
              type: '',
            },
            {
              barcode: '435345435435',
              type: '',
            },
            {
              barcode: '70100401010204',
              type: '',
            },
            {
              barcode: '70100401010204111',
              type: 'COMMON',
            },
            {
              barcode: '701004010102043',
              type: 'COMMON',
            },
          ],
          scan_serial_numbers: [
            {
              code: 'imei',
              title: 'IMEI код',
              template: '',
            },
            {
              code: 'chestniy_znak',
              title: 'Честный знак',
              template: '^098[A-Za-z0-9_]{5}7676[A-Za-z0-9_]{6}',
              //template: '',
            },
          ],
        },
      ],
      places: [],
      ad_attachments: [],
      packagings: [
        {
          id: 'some-id',
          title: 'Коробка 50х30х20',
          barcodes: ['BX-503020', 'BX*503020'],
        },
      ],
    },
    {
      id: 'mock3',
      shippingOptions: {
        packingType: ShippingOptionsType.MIX_PACKINGS,
      },
      date: '2021-12-20T14:22:34',
      packing_state: 'awaiting_packing',
      global_shipping_guidelines: '',
      order_shipping_comments: '',
      require_place_weight: false,
      shipping_form: [],
      delivery: {
        logo: 'https://storage.yandexcloud.net/images-k2c/f6299c22-4d07-4cb2-bcbc-6733612024bf.jpg',
        title: 'Почта России API',
        contract_title: 'POSTAL_PARCEL2',
        sorting_center_acceptance_date: '2021-12-21',
        maximum_place_weight: 0,
        maximum_places_count: 1,
      },
      items: [
        {
          id: 'KK1043',
          title: 'стакан',
          article: 'sta',
          quantity: 1,
          image: '',
          image_preview: '',
          require_weight: false,
          require_dimensions: false,
          barcodes: [
            {
              barcode: '70100401010202',
              type: 'COMMON',
            },
            {
              barcode: '2',
              type: 'COMMON',
            },
          ],
          scan_serial_numbers: [
            {
              code: 'imei',
              title: 'IMEI код',
              template: '',
            },
          ],
        },
      ],
      places: [
        // {
        //   packaging_id: 'some-id-2',
        //   sequence_num: 1,
        //   is_final: true,
        //   weight: 42,
        //   items: [
        //     {
        //       id: 'KK1043',
        //       packed_at: '2021-12-22T18:41:28',
        //       barcode_used: '70100401010202',
        //       quantity: 1,
        //       serial_numbers: [{ code: 'imei', value: '12' }],
        //     },
        //   ],
        //   id: '49dff77b-f59d-4dbc-8783-1014f9d20169',
        // },
      ],
      ad_attachments: [],
      packagings: [
        {
          id: 'some-id',
          title: 'Коробка 50х30х20',
          barcodes: ['BX-503020', 'BX*503020'],
        },
        {
          id: 'some-id-2',
          title: 'Коробка 50х30х10',
          barcodes: ['BX-503010'],
        },
      ],
    },
  ],
}

import api from 'src/api'

import { ApiReqResult } from 'src/interfaces'
import { convertObjectToURLParams } from '../../helpers'
import { provideMock } from '../../mocks'

export interface CheckBarcodeResult extends ApiReqResult {
  packaging_id: string
}
export enum BarcodeDataType {
  stickerWB = 'StickerWB',
  package = 'Package',
}
interface useCheckBarcodeParams {
  barcode: string
  type: BarcodeDataType // StickerWB - Уникальный стикер из бабины, "Package" -Упаковка
  seller_id?: string
}

const getCheckBarcode = async (paramsObj: useCheckBarcodeParams) => {
  const params = convertObjectToURLParams(paramsObj)
  const result = await api.get(`/check/barcode${params}`)
  if (!result.data?.success) throw result.data
  return result.data
}

const useCheckBarcode = () => ({
  fetch: (params: useCheckBarcodeParams) =>
    provideMock<useCheckBarcodeParams, CheckBarcodeResult>(
      getCheckBarcode,
      params,
      {
        key: 'barcode',
        value: 'mock',
        result: {
          success: true,
          packaging_id: 'some id',
        },
      },
    )(),
})

export default useCheckBarcode

import { useMutation } from 'react-query'
import api from 'src/api'
import { IProps } from './useCreateUser'

export interface IPropsEdit extends IProps {
  id?: string
}
const useEditUser = () => {
  const editUser = async (props: IPropsEdit) => {
    const result = await api.post(`/warehouse/user/${props.id}`, props)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(editUser)
}

export default useEditUser

import { IMerchant } from './acceptance'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { DocumentTypeEnum } from 'src/interfaces'
import { IDetail, IFile } from './other'

export enum RelatedDocumentEnum {
  PROCESSING = "PROCESSING",
  DONE = "DONE",
}

export interface RelatedDocument {
  /** Идентификатор */
  id: string
  /** UUID */
  uuid: string
  /** Флаг активности (false для удаленных документов) */
  activated: boolean
  /** Дочерние документы */
  related_documents: RelatedDocument[]
  /** Время создания */
  create_ts: string
  /** Время документа */
  date: string
  /** Идентификатор из внешней системы */
  external_id?: string
  status: RelatedDocumentEnum
  num: number
  /** Тип документа */
  type: DocumentTypeEnum
  /** Время последнего обновления */
  update_ts: string
  /** ШК для некоторых типов документа */
  barcode: string
}

export interface ServiceDocument {
  id: string
  activated: boolean
  comment: string
  create_ts: string
  date: string
  executor: Executor
  external_id: string
  files?: IFile[]
  items: DocumentItem[]
  merchant: IMerchant
  status: string
  update_ts: string
  uuid: string
}

export interface NewServiceDocument extends Pick<ServiceDocument, 'id'> {}

export interface Executor {
  enabled: boolean
  id: string
  login: string
  name: string
  password: string
  patronymic: string
  position: string
  roles: string[]
  surname: string
}

export interface DocumentItem {
  id: string
  quantity: number
  service_id: string
  details: IDetail[]
}

export interface PreCalcItem {
  id?: string
  quantity: number
  service_id: string
}

export interface FormInputCalcItem {
  quantity: number | null
  service_id: string
}

export interface Service {
  id: string
  /** Активность услуги */
  active: boolean
  /** Код услуги */
  code: number
  /** Идентификатор группы услуг */
  group_id: string
  /** Позиция услуги в группе */
  position: number
  /** Определяет услуги, связанные с расходными материалами */
  is_material: boolean
  /** Параметры */
  properties: ServiceProperties
  /**Ед. измерения */
  measure: string
  /** Наименование */
  title: string
}

export interface ServiceWithValue extends Service {
  value: TextFieldPropValue
}

export interface ServiceProperties {
  compare_type: string
  dimensions_sum: number
  weight: number
}

export interface ServiceGroup {
  active: boolean
  id: string
  properties: GroupProperties
  title: string
  type: string
}

export interface GroupProperties {
  cell_types: string[]
  delivery_aboard: boolean
  delivery_codes: string[]
  full_billing: boolean
  include_shipments: boolean
  include_supplies: boolean
  marketplace: boolean
}

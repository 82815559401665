import { useMemo, useState } from 'react'


type ProcessItem = {
  condition: boolean // условие выполнения процесса (например открытия модалки)
  value: string // значение выполнения процесса (например код по которому поймем какая модалка)
  error?: string
}

/**
 *  Суть хука:
 *  Поочередный вывод модалок или других процессов
 */

export const useSequentialDataEntryProcess = (props: {initialProcessItems: ProcessItem[]}) => {
  /** Вычисляем изначальный список процессов */
  const correctInitialItems = useMemo(
    () => props.initialProcessItems.filter(item => item.condition),
    [props.initialProcessItems]
  );

  const [processItems, setProcessItems] = useState<ProcessItem[]>(correctInitialItems)

  const [currentProcessIndex, setCurrentProcessIndex] = useState<number>(0)
  const currentProcess = processItems[currentProcessIndex]
  const isComplete = currentProcessIndex > processItems.length-1


  /**
   * Обновляем текущий список без изменения позиции
   */
  const updateCurrentProcessItems = (items: ProcessItem[]) => {
    const correctProcessItems = items.filter(item => item.condition)
    setProcessItems(correctProcessItems)
  }

  /**
   *  Задаем новый список для каких-то нужд
   *  (например для обработки ошибок в каких-то модалках, можно задать только их,
   *  без потери данных стейта прошлого списка)
   */
  const setNewProcessItems = (items: ProcessItem[]) => {
    updateCurrentProcessItems(items)
    setCurrentProcessIndex(0)
  }

  const goNext = () => {
    setCurrentProcessIndex(prev => {
      const maxValue = processItems.length
      const value = prev + 1
      return value >= maxValue ? maxValue : value
    })
  }
  const goBack = () => {
    setCurrentProcessIndex(prev => {
      const value = prev - 1
      return value <= 0 ? 0 : value
    })
  }

  return {
    currentProcess,
    setNewProcessItems,
    updateCurrentProcessItems,
    goNext,
    goBack,
    isComplete
  }
}
import api from 'src/api'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'

import { convertObjectToURLParams } from '../../helpers'

import { ApiReqResult, IPaginationFilter, PaginationResult } from 'src/interfaces'
import { IReplaceDoc } from 'src/interfaces/replace'

interface IParams extends IPaginationFilter {}

export interface GetReplaceResult extends ApiReqResult, PaginationResult {
  documents: IReplaceDoc[]
}

export const useGetReplaceList = (
  params: IParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<GetReplaceResult>(
    ['getReplaceList'],
    async () => {
      const searchParams = convertObjectToURLParams(params)
      const result = await api.get(`/replace${searchParams}`)

      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    })
}

import { useQuery } from 'react-query'

import api from 'src/api'
import { ApiReqResult, DocumentTypeEnum, RelatedDocument } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { provideMock } from '../../mocks'
import { convertObjectToURLParams } from 'src/helpers'


/*
 * Получить связанные документы
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Obshie-metody-dlya-dokumentov/operation/getRelatedDocuments
 */

interface IGetDocumentRelatedResult extends ApiReqResult {
  /** Документы */
  document: RelatedDocument
}

interface IGetDocumentRelatedParams {
  /** Идентификатор сущности */
  doc_id: string
  doc_type?: DocumentTypeEnum
}

const useGetDocumentRelated = (paramsData: {
  params: IGetDocumentRelatedParams,
  options?: UseQueryOptions<any>
}) => {
  const { params, options } = paramsData

  return useQuery<IGetDocumentRelatedResult>(
    [`getDocumentRelated`, params],
    provideMock<IGetDocumentRelatedParams, IGetDocumentRelatedResult>(
      async () => {
        const paramsStr = convertObjectToURLParams({doc_id: params.doc_id, doc_type: params.doc_type})
        const result = await api.get(`/document/related${paramsStr}`)
        return result.data
      },
      params,
      [
        // {
        // key: 'entity_id',
        // value: 'mock',
        // result: MOCK_GET_LOG_CHANGES,
      // },
    ]),
    options
  )
}

export default useGetDocumentRelated

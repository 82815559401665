import React, { FC } from 'react'
import noImage from 'src/assets/images/no-image.png'
import { Img } from '../../../index'
import styles from './ImageCell.module.scss'

interface ImageCellProps {
  image?: string
  yandex?: boolean
}

export const ImageCell: FC<ImageCellProps> = ({ image, yandex = true }) => {
  return (
    <Img
      className={styles.img}
      src={typeof image === 'string' ? image : noImage}
      size={100}
      yandex={yandex}
      modal
    />
  )
}

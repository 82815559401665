import React, { useEffect, useRef } from 'react'
import cx from 'classnames'

import styles from './calendar-input.module.scss'
import { DatePicker, DatePickerProps, DatePickerPropType } from '@consta/uikit/DatePicker'

import { useAppContext } from 'src/context'


export interface CalendarInputProps extends Omit<DatePickerProps, 'type'> {
  id: string
  label?: string
  viewDescription?: string
  value: Date | null
  handleChange?: (value: CalendarPropValue) => void
  className?: any
  backspaceReset?: boolean
  isRequired?: boolean
  type?: DatePickerPropType
}

type CalendarPropValue = Date | null

const CalendarInput = ({
  className,
  id,
  label,
  handleChange = () => false,
  backspaceReset,
  isRequired,
  viewDescription,
  ...props
}: CalendarInputProps) => {
  const inputRef = useRef(null)

  const { resetAfkInterval } = useAppContext()

  useEffect(() => {
    /* Костыльное скрытие подсказок автозаполнения */
    const target: HTMLInputElement | null = inputRef?.current
    if (target) {
      (target as HTMLInputElement).setAttribute('autocomplete', 'off')
    }
  }, [inputRef?.current])

  const handleChangeCalendar = (value: any) => {
    resetAfkInterval()
    handleChange(value)
  }

  const handleBackspace = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (backspaceReset && (e.key === 'Backspace' || e.key === 'Delete')) {
      handleChangeCalendar(null)
    }
  }

  return (
    <div
      className={cx(styles.inputCalendar, className)}
      onKeyDown={e => handleBackspace(e)}
    >
      {label &&
        <label className={cx({ [styles.requiredLabel]: isRequired })} htmlFor={id}>
          {label}
        </label>
      }
      <DatePicker
        {...props}
        id={id}
        className={styles.calendar}
        dropdownClassName={styles.calendarDropdown}
        onChange={({ value }) => handleChangeCalendar(value)}
        inputRef={inputRef}
      />
    </div>
  )
}

export default CalendarInput

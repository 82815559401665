import { Modal, TSDTableNomenclatureCell, TSDTableWrapper } from '../../../index'
import styles from './TSDSelectSkuModal.module.scss'
import React from 'react'
import { ISku } from 'src/interfaces'

interface ISelectBatchModal {
  onSubmit: (sku: ISku) => void
  onClose: () => void
  skus: ISku[]
  isOpen: boolean
}

const TSDSelectSkuModal = ({
  onSubmit,
  skus,
  onClose,
  isOpen,
}: ISelectBatchModal) => {
  return (
    <Modal
      withClose
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      onClose={onClose}
      title={'Выбор номенклатуры'}
      subtitle={''}
      className={styles.modal}
    >
      <TSDTableWrapper
        header={<></>}
        tableClassName={styles.tableWrapper}
        maxTableHeight={480}
      >
        {skus.map((sku, i) => {
          return (
            <div
              key={sku?.id || i}
              className={styles.batchItem}
              onClick={() => onSubmit(sku)}
            >
              <TSDTableNomenclatureCell
                withoutCollapseName
                merchantName={sku.merchant?.title}
                name={sku.title}
                article={sku.article}
                barcodes={sku.barcodes}
              />
            </div>
          )
        })}
      </TSDTableWrapper>
    </Modal>
  )
}
export default TSDSelectSkuModal

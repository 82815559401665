import styles from './SortBtn.module.scss'
import { Button } from '@consta/uikit/Button'
import cx from 'classnames'
import React from 'react'
import { IconSortUp } from '@consta/icons/IconSortUp'
import { IconSortDown } from '@consta/icons/IconSortDown'
import { IconUnsort } from '@consta/icons/IconUnsort'
import { SortEnum } from '../../../../updatedDesignVersion/shared/types/sort'


type PropsType = {
  className?: string
  active?: boolean
  sort: SortEnum
  onClick: () => void
}
const SortBtn = (props: PropsType) => {
  const {
    active = false,
    sort,
    onClick,
    className,
  } = props

  if (!active) return null
  return (
    <div className={cx(styles.wrap, className)}>
      <Button
        className={cx(styles.btn)}
        size='s'
        iconLeft={
          !sort
            ? IconUnsort
            : sort === SortEnum.DESC
              ? IconSortUp
              : IconSortDown
        }
        view='clear'
        onlyIcon
        onClick={onClick}
      />
    </div>
  )
}

export default SortBtn
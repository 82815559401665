import { useMutation } from 'react-query'
import api from 'src/api'

export interface GenerateCellsProps {
  access_level: number
  bay_end: number
  bay_start: number
  condition: string
  row_end: number
  row_start: number
  tier_end: string
  tier_start: string
  type: string
  zone_id: string
}

export const useGenerateCells = () => {
  const generateCells = async (props: GenerateCellsProps) => {
    const result = await api.post('/warehouse/cell/generate', props)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(generateCells)
}

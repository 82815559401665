import React from 'react'
import { ITableRow } from 'src/interfaces'
import { CheckboxCell } from '..'
import { ITableColumn } from '../../interfaces'


export const generateTableColumns = (
  extraColumns: ITableColumn[],
  rows: ITableRow[],
  selections: string[],
  setSelections: React.Dispatch<React.SetStateAction<string[]>>,
) => {
  return [
    {
      key: 'actions',
      title: '',
      title_txt: '',
      isAction: true,
      withHeaderAction: true,
      gridCellWidth: '50px',
      renderCell: ({ row, isHeader, index }: any) => (
        <CheckboxCell
          isHeaderCheckbox={isHeader}
          fieldId={row.id}
          fieldsIds={rows.map(row => row.id)}
          checkedItemsIds={selections}
          setCheckedItemsIds={setSelections}
        />
      )
    },
    ...extraColumns
  ]
}

export const generateTableRows = (
  extraRows: ITableRow[]
): ITableRow[] => {
  return extraRows
}

import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ICombobox, SupplyStatusEnum } from 'src/interfaces'
import { generateStatusesOptions } from 'src/helpers'


export const SUPPLY_STATUSES: Record<SupplyStatusEnum | string, string> = {
  [SupplyStatusEnum.entered]: 'Новый',
  [SupplyStatusEnum.arrived]: 'Прибыл',
  [SupplyStatusEnum.processing]: 'В работе',
  [SupplyStatusEnum.avaitingPlacing]: 'Ждет размещения',
  [SupplyStatusEnum.placed]: 'Размещен',
  [SupplyStatusEnum.done]: 'Выполнен',
}

export const SUPPLY_STATUSES_STYLES: Record<SupplyStatusEnum | string, BadgePropStatus> = {
  [SupplyStatusEnum.entered]: 'warning',
  [SupplyStatusEnum.arrived]: 'success',
  [SupplyStatusEnum.avaitingPlacing]: 'normal',
  [SupplyStatusEnum.processing]: 'normal',
  [SupplyStatusEnum.placed]: 'success',
  [SupplyStatusEnum.done]: 'error',
}

export const supplySelectOptions: ICombobox[] = generateStatusesOptions(SUPPLY_STATUSES)

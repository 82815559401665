import api from 'src/api'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import { ApiReqResult, IPackageDto, PackagingPurposes } from '../../interfaces'
import { convertObjectToURLParams } from '../../helpers'

interface IResult extends ApiReqResult {
  packagings: IPackageDto[]
}

interface IParams {
  merchant_id?: string
  purpose?: PackagingPurposes
}

const getPackagings = async (params: IParams = {}): Promise<IResult> => {
  const paramsStr = convertObjectToURLParams(params)
  const result = await api.get(`/packing/packagins${paramsStr}`)

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

export const useGetPackagings = () => ({
  fetch: async (params?: IParams): Promise<IPackageDto[]> => {
    const data = await getPackagings(params)
    return data.packagings || []
  },
})

export const useQueryGetPackagings = (params: IParams, options: UseQueryOptions<any> = {}) => {
  return useQuery<IResult>(
    [`getPackagins`, params],
    async () => getPackagings(params),
    {
      keepPreviousData: true,
      ...options,
    },
  )
}

import { useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult, IContainer, IPhysicalSetOrderPlaceDto } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { provideMock } from '../../mocks'
import { MOCK_GET_PHYSICAL_SET_ORDER_PLACE } from '../../mocks/physicalSet'


interface IParams {
  placeId: string
}

interface IResult extends ApiReqResult {
  place: IPhysicalSetOrderPlaceDto
  containers: IContainer[]
}

const getPhysicalSetOrderPlace = async (params: IParams) => {
  const result = await api.get(`/physical-set-order-place/${params.placeId}`)
  if (!result?.data?.success) {
    throw new Error('Ошибка комплекта')
  }
  return result.data
}


export const useFetchPhysicalSetOrderPlace = () => ({
  fetch: async (params: IParams): Promise<IResult> => await getPhysicalSetOrderPlace(params)
})

export const useGetPhysicalSetOrderPlace = (
  params: IParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IResult>(
    [`getPhysicalSet`, params],
    provideMock<IParams, IResult>(
    async () => await getPhysicalSetOrderPlace(params),
       params,
      [
        {
        key: 'placeId',
        value: 'mock',
        result: MOCK_GET_PHYSICAL_SET_ORDER_PLACE,
        logData: 'Получение упакованного места',
      },
        ]),
    {
      keepPreviousData: true,
      ...options,
    },
  )
}

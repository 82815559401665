import { ConditionEnum, ISku } from 'src/interfaces'

export enum CellTypesEnum {
  cell = 'CELL',
  pallet = 'PALLET',
}
export const CellDescriptionEnum = {
  [CellTypesEnum.cell]: 'Ячейка',
  [CellTypesEnum.pallet]: 'Паллет',
}

export interface IWarehouseCell {
  id: string
  zone_id: string
  title: string
  condition: ConditionEnum
  barcode: string
}

export interface ICellPlace {
  __special_id?: string
  id: string
  /** Уровень доступности ячейки */
  access_level: number
  is_blocked: boolean
  zone_id: string
  title: string
  /** Состояние товара */
  condition: ConditionEnum
  /** ШК ячейки */
  barcode: string
  /** Адрес ячейки */
  topology?: {
    bay?: number
    row?: number
    section?: string
    line?: number
    tier?: number | string
    position?: string
  }
  type?: CellTypesEnum | string
}

export interface ICellSkuPart {
  sku_id?: string
  human_id?: string
  quantity: number
  optional: boolean
}

export interface ICellSkuStock {
  defect_type_id?: string
  sku_id: string
  sku_batch_id?: string
  place_id: string
  quantity: number
  reserved: number
  condition?: ConditionEnum
}

export interface ICellSku extends ISku {}

import React, { useEffect, useState } from 'react'
import ComboboxWrapper from '../ComboboxWrapper/ComboboxWrapper'
import { DefaultItem } from '@consta/uikit/Combobox'
import { PropSize } from '@consta/uikit/__internal__/src/components/SelectComponents/types'

interface IConvertData {
  key: string
  labelValue: string
  idValue: string
}
export interface IComboboxWrapperWithApi {
  withApiSearch?: boolean
  label?: string
  placeholder?: string

  value?: DefaultItem | /* DefaultItem[] */ any | null
  valueId?: string // для ID значения

  className?: string
  wrapperClassName?: string
  isMultiple?: boolean
  onChange?: (value: DefaultItem | /* DefaultItem[] */ any | null) => void
  useGetHookSearch: any
  // useGetHookSearch -передаем хук запроса,из которого получаем список для селекта
  convertHookParams?: (params: { search: string }) => any
  // convertHookParams-функция в которую передаем search и другие параметры
  //  и конвертируем в объект который нужен будет для фильтров к запросу
  auto?: boolean
  //   Есть два способа как конвертировать полученую Data  в DefaultItem[]

  // 1 Способ
  // Если включен режим auto,то мы передаем в параметр avtoConvertData,
  // объект { key: string, labelValue: string, idValue: string} ,который в UseEffect,
  // преобразует полученую Data в DefaultItem[]
  //   Пример:
  // auto
  // autoConvertData={{
  // 	key: 'orders',
  // 	labelValue: 'id',
  // 	idValue: 'id',
  //   }}
  autoConvertData?: IConvertData
  //   Объект {key: 'orders',labelValue: 'id',idValue: 'id',} который передаем пропсом для конвертация
  // полученой Data

  //   2 Способ
  // Если выключен режим auto,мы в верхнем компоненте передаем пропс convertHookData
  // функцию,в котрой конвертируем полученную Data в DefaultItem[]
  convertHookData?: (data: any) => any
  //   Функция котороя конвертирует полученную Data в DefaultItem[]
  //   Пример:
  //   convertHookData={(data) => {
  //     return data.orders.map((el) => ({ label: el.id, id: el.id }))
  //   }}
  link?: string
  readOnly?: boolean
  isRequired?: boolean

  size?: PropSize
}

const ComboboxWrapperWithApi = (props: IComboboxWrapperWithApi) => {
  const {
    withApiSearch = true,
    link,
    label = '',
    placeholder = '',
    value = null,
    valueId,
    className,
    useGetHookSearch,
    convertHookParams = (params) => ({ params: { search: params.search }, size: 50 }),
    autoConvertData,
    convertHookData,
    isMultiple = false,
    auto = false,
    onChange = () => false,
    readOnly = false,
    isRequired = false,
    size,
    wrapperClassName
  } = props
  const [search, setSearch] = useState<string>('')
  const { data, isFetching } = useGetHookSearch(convertHookParams({ search }))
  const [list, setList] = useState<DefaultItem[]>([])

  useEffect(() => {
    if (!data) return
    if (!auto) {
      setList(convertHookData(data))
    } else {
      const { key, labelValue, idValue } = autoConvertData
      setList(
        data[key].map((el) => ({ label: el[labelValue], id: el[idValue] })),
      )
    }
  }, [data, search])

  let correctValue = valueId ? list.find(item => item.id === valueId) : value
  return (
    <ComboboxWrapper
      link={link}
      disabled={readOnly}
      withApiSearch={withApiSearch}
      handleApiSearch={(value: string | null) => setSearch(value || '')}
      searchFunction={withApiSearch ? () => true : undefined}
      className={className}
      wrapperClassName={wrapperClassName}
      items={list}
      value={correctValue}
      label={label}
      isMultiple={isMultiple}
      placeholder={placeholder}
      isRequired={isRequired}
      size={size}
      loading={isFetching}
      onChange={({ value }) => {
        onChange(value)
        setSearch('')
      }}
    />
  )
}
export default ComboboxWrapperWithApi

import { useMutation, useQuery } from 'react-query'
import api from 'src/api'

import { ApiReqResult, PaginationResult } from 'src/interfaces'
import { IShipment, IShipmentOrder, IShipmentOrderPlace, ShipmentStatusesEnum } from 'src/interfaces/shipmnet'
import { copyNoUndefined } from '../../helpers'

/*
 * Получение списка отгрузок
 * wiki:
 * https://wiki.kak2c.ru/link/785#bkmrk-%D0%9F%D0%BE%D0%BB%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5-%D1%81%D0%BF%D0%B8%D1%81%D0%BA%D0%B0-%D0%BE%D1%82%D0%B3
 */

export interface IShipmentListParams {
  search?: string
  from?: string
  to?: string
  delivery_companies?: string[] | string
  statuses?: ShipmentStatusesEnum[] | string
  shipment_types?: string
  gates?: string
  carrier_fio?: string
  plate_number?: string
  page?: number
  size?: number
}

const getShipmentsList = async (params: IShipmentListParams) => {
  const clearParams = copyNoUndefined(params)
  const urlParams = new URLSearchParams(Object(clearParams))
  const urlParamsStr = urlParams.toString()

  const result = await api.get(
    `/shipment${urlParamsStr ? `?${urlParamsStr}` : ''}`,
  )

  if (!result.data?.success) {
    throw new Error(result?.data?.message)
  }

  return result.data
}

interface IResultData {
  shipments: IShipment[]
  places: IShipmentOrderPlace[]
  orders: IShipmentOrder[]
}

interface IApiResult extends ApiReqResult, IResultData, PaginationResult {}

const useGetShipmentsList = (
  params: IShipmentListParams,
  callback?: (object: IResultData) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IApiResult>(
    [`getShipmentsList`, params],
    () => getShipmentsList(params),
    {
      keepPreviousData: true,
      onSuccess: ({ success, ...rest }) => {
        if (success) {
          callback?.(rest)
        } else {
          error?.()
        }
      },
      onError: () => error?.(),
      onSettled: () => settled?.(),
    },
  )
}

export default useGetShipmentsList

export const useGetShipmentsListM = () => useMutation(
  'getShipmentList',
  getShipmentsList
)

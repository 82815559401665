import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock } from 'src/mocks'

import { ApiReqResult } from 'src/interfaces'

/*
 * Создание/редактирование документа
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Okazannye-uslugi/operation/putServiceDoc
 */

export interface IUpdateServiceDocResult extends ApiReqResult {
  id: string
}

export interface IUpdateServiceDocData {
  items: { quantity: number, service_id: string }[],
  date?: string,
  merchant_id?: string,
  parent_id?: string,
  doc_id?: string
}

const useUpdateServiceDoc = () => {
  return useMutation(async (data: IUpdateServiceDocData) => {
    const docIdStr = data.doc_id ? `/${data.doc_id}` : ''
    const result = await provideMock<{},
      { data: IUpdateServiceDocResult }>(
      async () =>
        await api.post(`/billing/service-doc${docIdStr}`, data),
      {
        key: 'doc_id',
        value: 'mock',
        result: {
          data: {
            success: true,
          },
        },
      },
    )()

    if (!result?.data?.success) {
      throw result
    }
    return result?.data
  })
}

export default useUpdateServiceDoc

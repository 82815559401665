import api from 'src/api'

import { ApiReqResult, IOrder, IOrderPackagingOption } from 'src/interfaces'

interface UsePackedOrderByIdParamsType {
  orderId: string
}

const getPackedOrder = async (params: UsePackedOrderByIdParamsType) => {
  const result = await api.get(`/packing/order/${params.orderId}`)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface GetPackedOrdersByIdResult extends ApiReqResult {
  order: IOrder,
  packagings: IOrderPackagingOption[]
}

const useGetPackedOrderById = () => ({
  refetch: async (params: UsePackedOrderByIdParamsType) => {
    try {
      const data = await getPackedOrder(params)
      return data?.order
    } catch (err) {
      console.log('error', err)
    }
  }
})

export default useGetPackedOrderById

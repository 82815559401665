import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import dayjs from 'dayjs'

import { appLocalStorage } from 'src/libs'

import AdvanceModeModal from './AdvanceModeModal'

import { useGetDatabases, useRemoveAdvancedMode } from 'src/hooks'

import packageInfo from 'src/../package.json'

import { ICurrentUser, IDatabaseSelectOption, IInformer } from 'src/interfaces'
import { Informer } from '../../components'
import {
  generateMenuList,
  IMenuList,
  MENU_LIST,
  DocumentsType,
} from '../../updatedDesignVersion/shared/ui/NavigationMenu/routes'
import { useGetReportsTypeList } from '../../hooks/report/useGetReportsTypeList'
import { useGetDocumentTypeList } from '../../hooks/document/useGetDocumentTypes'


export const AppContext = createContext({
  documentsTypeList: [] as DocumentsType[],
  mainMenuList: [] as IMenuList[],
  globalLoading: true,
  removeAdvanceModeIsLoading: false,
  setGlobalLoading: (globalLoading: boolean) => void globalLoading as void,
  authorized: false,
  setAuthorized: (authorized: boolean) => void authorized as void,
  currentUser: {} as ICurrentUser,
  setCurrentUser: (currentUser: ICurrentUser) => void currentUser as void,
  advanceMode: false,
  setAdvanceMode: (value: boolean) => void value as void,
  removeAdvanceMode: () => void false as void,
  requestAdvanceMode: () => void false as void,
  showAdvanceModeModal: false as boolean,
  performWithAdvance: (action: () => () => void) => void action as void,
  appVersion: packageInfo.version,
  setAppVersion: (value: string) => void value as void,
  resetAfkInterval: () => void false as void,

  databases: [] as IDatabaseSelectOption[],
  setDatabases: (databases: IDatabaseSelectOption[]) => void databases as void,

  logout: () => void false as void,

  informer: null as IInformer | null,
  setInformer: (informer: IInformer | null) => void informer as void
})


export const AppContextProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  const removeAdvanceModeMutation = useRemoveAdvancedMode()

  const [databases, setDatabases] = useState<IDatabaseSelectOption[] | null>(null)
  const [authorized, setAuthorized] = useState(false)
  const [currentUser, setCurrentUser] = useState<ICurrentUser>({})
  const [globalLoading, setGlobalLoading] = useState(true)

  const [informer, setInformer] = useState<IInformer | null>(null)

  const [advanceMode, setAdvanceMode] = useState<boolean>(false)
  const [showAdvanceModeModal, setShowAdvanceModeModal] =
    useState<boolean>(false)
  const [deferredAction, setDeferredAction] = useState<(() => void) | null>(
    null,
  )

  const [appVersion, setAppVersion] = useState(packageInfo.version)

  const [afkTimer, setAfkTimer] = useState(dayjs())


  const [mainMenuList, setMainMenuList] = useState<IMenuList[]>([])
  const [reportsTypeList, setReportsTypeList] = useState<DocumentsType[]>([])
  const [documentsTypeList, setDocumentsTypeList] = useState<DocumentsType[]>([])

  useEffect(() => {
    if (!authorized) return
    const menuValue = generateMenuList({ reportsTypeList, documentsTypeList })
    setMainMenuList(menuValue.filter(menu => menu.items.length))
  }, [mainMenuList?.length, reportsTypeList?.length, documentsTypeList?.length, authorized])

  useGetReportsTypeList({
    enabled: !reportsTypeList?.length && authorized,
    onSuccess: (data) => setReportsTypeList(data?.values)
  })
  useGetDocumentTypeList({
    enabled: !documentsTypeList?.length && authorized,
    onSuccess: (data) => setDocumentsTypeList(data?.values)
  })


  const logout = () => {
    appLocalStorage.clear()
    setAuthorized(false)
  }

  const getDatabases = useGetDatabases()

  useEffect(() => {
    if (databases) {
      // базы уже добавлены
      return
    }
    getDatabases
      .fetch()
      .then((data) => {
        setDatabases(data)
      })
  }, [databases])

  const performWithAdvance = useCallback((action: () => () => void) => {
    if (advanceMode) {
      action()()
    } else {
      setDeferredAction(action)
      setShowAdvanceModeModal(true)
    }
  }, [advanceMode])

  const removeAdvanceMode = async () => {
    await removeAdvanceModeMutation.mutateAsync().then(({ data }) => {
      if (data?.success === true) {
        setAdvanceMode(false)
      }
    })
  }

  return (
    <AppContext.Provider
      value={{
        mainMenuList,
        documentsTypeList,

        databases: databases || [],
        setDatabases,
        globalLoading,
        setGlobalLoading,
        authorized,
        setAuthorized,
        currentUser,
        setCurrentUser: (user: ICurrentUser) => {
          appLocalStorage.currentUser = user
          setCurrentUser(user)
        },
        advanceMode,
        setAdvanceMode,
        removeAdvanceMode,
        removeAdvanceModeIsLoading: removeAdvanceModeMutation.isLoading,
        performWithAdvance,
        showAdvanceModeModal,
        requestAdvanceMode: () => setShowAdvanceModeModal(true),
        appVersion,
        setAppVersion,
        resetAfkInterval: () => setAfkTimer(dayjs()),

        logout,

        informer,
        setInformer
      }}
    >
      {children}

      {showAdvanceModeModal ? (
        <AdvanceModeModal
          isOpen={true}
          deferredAction={deferredAction}
          setAdvanceMode={setAdvanceMode}
          onClose={() => {
            setDeferredAction(null)
            setShowAdvanceModeModal(false)
          }}
        />
      ) : null}
      {
        informer ?
          <Informer {...informer} />
        : null
      }
    </AppContext.Provider>
  )
}

export const useAppContext = () => {
  const context = useContext(AppContext)
  return context
}

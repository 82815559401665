import React from 'react'
import cx from 'classnames'

import styles from './scan-wrapper.module.scss'

import ScanHeader from '../ScanHeader'
import { CurrentBase } from 'src/components/widgets'

declare const ScanWrapperPropSize: readonly ['s', 'm', 'l']
declare type ScanWrapperPropSize = typeof ScanWrapperPropSize[number]

interface ScanWrapperProps {
  className?: string
  children?: React.ReactNode
  title?: string
  subtitle?: string
  size?: ScanWrapperPropSize
  withBase?: boolean
  withoutGoToMainMenu?: boolean
}

const ScanWrapper = (props: ScanWrapperProps) => {
  const {
    title,
    subtitle,
    size,
    withoutGoToMainMenu,
    className,
    withBase = true
  } = props

  return (
    <div className={styles.wrap}>
      <div
        className={cx(styles.main, className, size ? styles[size] : styles.m)}
      >
        <div className={styles.head}>
          <ScanHeader
            title={title}
            subtitle={subtitle}
            withoutGoToMainMenu={withoutGoToMainMenu}
            className={cx({ [styles.mainHeader]: withBase })}
          />
          {withBase ? (
            <CurrentBase />
          ) : null}
        </div>
        {props.children}
      </div>
    </div>
  )
}

export default ScanWrapper

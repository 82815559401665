import { ZoneType } from '../../pages/DirectoriesPage/pages/ZonesSection/types'
import { ICellPlace } from './cell'

export enum ZoneTypesEnum {
  STORAGE ='STORAGE',
  RECEIVING ='RECEIVING',
  PACKAGING ='PACKAGING',
  CORRECTION ='CORRECTION',
  OUT_OF_STOCK ='OUT_OF_STOCK',
  SHIPMENT ='SHIPMENT',
  SORTING ='SORTING',
}
export interface IZone {
  id: string
  title: string
  type: ZoneTypesEnum
}

export interface ITreeLvlItem {
  index: string
  items: ITreeLvlItem[] | ICellPlace[]
  level_title: string
  title: string
  is_blocked: boolean
  access_level: string | number
  __special_id?: string
}

export interface IZoneTreeNode {
  id: string
  /** Всего ячеек в зоне */
  cells_quantity: number
  items: ITreeLvlItem[]
  level_title: string
  title: string
  type: ZoneType
}

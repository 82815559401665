import { useMutation } from 'react-query'

import api from 'src/api'

import { ApiReqResult, ICarrierCrateData } from 'src/interfaces'

/*
 * Создание/редактирование перевозчика
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Perevozchiki/operation/putCarrier
 */

interface Result extends ApiReqResult {
  id: string
}
interface CreateUpdateCarrierParams {
  carrierId?: string
}

const useCreateUpdateCarrier = (params: CreateUpdateCarrierParams) => {
  return useMutation(async (data: ICarrierCrateData) => {
    const carrierId = params.carrierId || data.id || ''
    const path = `/carrier${carrierId ? `/${carrierId}` : ''}`
    const result = await api.post(path, data)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}

export default useCreateUpdateCarrier

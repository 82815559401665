import React from 'react'
import cx from 'classnames'

import styles from './advance-mode-lock.module.scss'

import { IconUnlock } from '@consta/icons/IconUnlock'
import { IconLock } from '@consta/icons/IconLock'

import { useAppContext } from 'src/context'
import { Button } from '@consta/uikit/Button'

interface AdvanceModeLockProps {
  className?: string
}

const AdvanceModeLock = (props: AdvanceModeLockProps) => {
  const { className } = props

  const { advanceMode, requestAdvanceMode, removeAdvanceModeIsLoading, removeAdvanceMode } = useAppContext()

  const handleAdvanceMode = () => {
    if (!advanceMode) {
      requestAdvanceMode()
    } else {
      removeAdvanceMode()
    }
  }

  const AdvanceModeIcon = advanceMode ? IconUnlock : IconLock

  return (
    <div className={cx(styles.wrapper, className)}>
      <Button
        onlyIcon
        iconLeft={AdvanceModeIcon}
        view="clear"
        size="s"
        iconSize="m"
        onClick={handleAdvanceMode}
        loading={removeAdvanceModeIsLoading}
      />
    </div>
  )
}

export default AdvanceModeLock

import { useMutation } from 'react-query'
import api from 'src/api'

export interface IProps {
  title: string
  id: string
}

// http://wms-test.kak2c.ru/warehouse-api/warehouse/gate/{gate_id}
const useEditGate = () => {
  const editGate = async (props: IProps) => {
    const result = await api.post(`/warehouse/gate/${props.id}`, {
      title: props.title,
    })

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(editGate)
}

export default useEditGate

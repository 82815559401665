import React from 'react'
import cx from 'classnames'
import styles from './TreeSelect.module.scss'
import DropdownTreeSelect, { DropdownTreeSelectProps, NodeAction, TreeNode } from 'react-dropdown-tree-select'
import 'react-dropdown-tree-select/dist/styles.css'


export type TreeOptionType = {
  label: string
  value: string
  children?: TreeOptionType[]
  [key: string]: any
}

export type TreeSelectProps = {
  id?: string
  label?: string
  options?: TreeOptionType[]
  wrapperClassName?: string
  onChange: (currentNode: TreeNode, selectedNodes: TreeNode[]) => void
} & Partial<DropdownTreeSelectProps>


export const TreeSelect = (props: TreeSelectProps) => {
  const {
    id,
    label,
    options = [],
    onChange,
    wrapperClassName,
    ...otherProps
  } = props

  const onChangeTree = (currentNode: TreeNode, selectedNodes: TreeNode[]) => {
    console.log('onChange::', currentNode, selectedNodes)
    onChange(currentNode, selectedNodes)
  }
  const onAction = (node: TreeNode, action: NodeAction) => {
    // console.log('onAction::', action, node)
  }
  const onNodeToggle = (currentNode: TreeNode) => {
    // console.log('onNodeToggle::', currentNode)
  }

  return (
    <div className={cx(styles.wrapper, wrapperClassName)}>
      {label ? <label>{label}</label> : null}
      <DropdownTreeSelect
        mode={'multiSelect'}
        texts={{ placeholder: " " }}
        data={options}
        onChange={onChangeTree}
        onAction={onAction}
        onNodeToggle={onNodeToggle}
        className={cx(styles.treeSelect)}

        {...otherProps}
      />
    </div>
  )
}


import { useQuery } from 'react-query'
import api from 'src/api'
import { convertObjectToURLParams } from '../../helpers'
import { IPaginationFilter } from '../../interfaces'


interface IParams {
  usersParams?: {search?: string} & IPaginationFilter,
  rolesParams?: {},
  positionsParams?: {},
}

const useGetWHUsers = (args: { reqParams?: IParams, enabled?: boolean}) => {
  const {
    reqParams,
    enabled = true
  } = args
  const urls = [
    { url: '/warehouse/user', params: reqParams?.usersParams },
    { url: '/warehouse/role', params: reqParams?.rolesParams },
    { url: '/enum/warehouse-user-position', params: reqParams?.positionsParams },
  ]
  const fetchUsers = async () => {
    return await Promise.all(
      urls.map(async ({ url, params }) => {
        const paramsStr = convertObjectToURLParams(params)
        const result = await api.get(`${url}${paramsStr}`)

        return result.data
      }),
    )
  }

  return useQuery(['getUsers', reqParams?.usersParams], fetchUsers, { enabled: enabled })
}

export default useGetWHUsers

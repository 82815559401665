import { useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { IRefund, IRefundSku } from '../../interfaces/refund/refund'


export interface IGetReturnByIdResult extends ApiReqResult {
  return?: IRefund
  skus?: IRefundSku[]
}

const useGetReturnById = (
  returnId: string,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IGetReturnByIdResult>(
    [`getReturnById`, returnId],
    async () => {
      const result = await api.get(`/return/${returnId}`)
      if (!result.data.success) {
        throw new Error(result.data.message)
      }
      return result.data
    },
    options
  )
}

export default useGetReturnById

import { usePrint } from './print'
import { useAppContext } from '../../../context'
import { useGetPickings } from 'src/hooks'
import useGetPickingList from '../../picking/useGetPickingList'
import usePickingFullComplete from '../../picking/usePickingFullComplete'
import { usePreviewBase64Pdf } from '../usePreviewBase64Pdf'


/**
 * Печатает / открывает просмотр листов подбора
 */

interface printPickingListsResult {
  printPickingLists: (
    batchIds: string[],
    isFullComplete: boolean,
    isPreview?: boolean
  ) => Promise<void>
}
export const usePrintPickingLists = ():printPickingListsResult => {
  const { setGlobalLoading } = useAppContext()
  const { printSticker } = usePrint({})
  const { fetch: getPickings } = useGetPickings()
  const { fetch: getPickingList } = useGetPickingList()
  const pickingFullComplete = usePickingFullComplete()
  const { previewBase64Pdf } = usePreviewBase64Pdf()

  const printPickingLists = async (
    batchIds: string[],
    isFullComplete: boolean,
    isPreview?: boolean
  ) => {
    setGlobalLoading(true)
    try {
      for (const batchId of batchIds) {
        const pickingIds: { id: string }[] = await getPickings({
          parent_id: batchId,
        })
        for (const { id: pickingId } of pickingIds) {
          const { content, printer } = await getPickingList({
            doc_id: pickingId,
          })
          if (content) {
            if (isPreview) {
              previewBase64Pdf(content, `Лист подбора ${pickingId}`)
            } else {
              await printSticker(printer, content)
            }
          }
          if (isFullComplete) {
            await pickingFullComplete.mutateAsync({ doc_id: pickingId })
          }
        }
      }
    } finally {
      setGlobalLoading(false)
    }
  }
  return {
    printPickingLists
  }
}

import api from 'src/api'
import { IRefundPlace } from '../../interfaces/refund/refund'
import { ApiReqResult, IAcceptanceSku, IPaginationFilter, PaginationResult, ReturnStatusEnum } from '../../interfaces'
import { useQuery } from 'react-query'
import { convertObjectToURLParams } from '../../helpers'
import { UseQueryOptions } from 'react-query/types/react/types'

interface GetReturnResult extends ApiReqResult, PaginationResult {
  skus: IAcceptanceSku[]
  returns: IRefundPlace[]
}
interface IParams extends IPaginationFilter {
  external_id?: string
  date_from?: string
  date_to?: string
  statuses?: ReturnStatusEnum
  delivery_companies?: string[]
}

export const useGetReturnList = () => ({
  fetch: async () => {
    const result = await api.get('/return', {})
    if (!result.data?.success) {
      throw result.data
    }
    return result.data?.returns || ([] as IRefundPlace[])
  },
})

export const useQueryReturnList = (
  params: IParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<GetReturnResult>(
    [`getReturnList`, params],
    async () => {
      const searchParams = convertObjectToURLParams(params)
      const result = await api.get(`/return${searchParams}`)

      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}

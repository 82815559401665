import React, { memo } from 'react'
import styles from './TSDTableCountingCell.module.scss'

import cx from 'classnames'
import { IconEdit } from '@consta/icons/IconEdit'
import { Button } from 'src/components'
import { IconTrash } from '@consta/icons/IconTrash'

export interface TSDTableQuantityCellProps {
  quantity: string | number
  maxQuantity: string | number
  className?: string
  withEditQuantity?: boolean
  onClickEdit?: () => void
  withDeleteItem?: boolean
  onClickDelete?: () => void
}

export const TSDTableCountingCell = memo((props: TSDTableQuantityCellProps) => {
  const {
    quantity,
    maxQuantity,
    className,
    withEditQuantity,
    onClickEdit,
    withDeleteItem,
    onClickDelete,
  } = props
  return (
    <div className={cx(styles.quantity, className)}>
      <p>{quantity}/{maxQuantity}</p>
      {
        withEditQuantity ? (
          <Button
            onlyIcon
            iconLeft={IconEdit}
            onClick={onClickEdit}
            view={'ghost'}
            size={'xs'}
          />
        ) : null
      }
      {
        withDeleteItem ? (
          <Button
            onlyIcon
            iconLeft={IconTrash}
            theme={'danger'}
            onClick={onClickDelete}
            view={'ghost'}
            size={'xs'}
          />
        ) : null
      }
    </div>
  )
})

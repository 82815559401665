import { IField, IFieldValue, IInnerFieldValue } from './fields'
import { TemplateFieldType } from '../types/tableGenerator/generatorTypes'
import days from 'dayjs'

export const generateFieldsValue = (fields: IInnerFieldValue[]) =>
  fields.map((filter) => ({
    label: filter.title,
    id: filter.value,
    value: filter.value,
  }))

/* Получаем значение поля по типу */
export const getFieldValueByType = (field: IField, fieldValue: any) => {
  switch (field.type) {
    case TemplateFieldType.TIME:
    case TemplateFieldType.YEARMONTH:
    case TemplateFieldType.DATETIME:
    case TemplateFieldType.DATE: {
      if (!fieldValue?.value) return null
      return days(fieldValue?.value)
    }

    case TemplateFieldType.URL:
    case TemplateFieldType.NUMBER:
    case TemplateFieldType.TEXT:
    case TemplateFieldType.TEXT_AREA:
    case TemplateFieldType.BOOLEAN:
    case TemplateFieldType.GROUP:
    case TemplateFieldType.JSON:
    case TemplateFieldType.TABLE:
      return fieldValue?.value

    case TemplateFieldType.CONTAINER:
    case TemplateFieldType.DOCUMENT:
    case TemplateFieldType.SKU_BATCH:
    case TemplateFieldType.CELL:
    case TemplateFieldType.PLACE:
    case TemplateFieldType.SKU:
    case TemplateFieldType.MERCHANT:
    case TemplateFieldType.CELLS:
    case TemplateFieldType.SKUS:
    case TemplateFieldType.MERCHANTS:
    case TemplateFieldType.CONTRACTOR:
    case TemplateFieldType.CONTRACTORS:
      if (!fieldValue?.value) return null
      return {
        id: field.id,
        value: fieldValue?.value,
        label: fieldValue?.meta?.title || fieldValue?.meta?.dto?.title || fieldValue?.meta?.dto?.id,
      }

    case TemplateFieldType.SELECT:
      const valueFromOptions = field.values?.find(field => `${field.value}` === `${fieldValue?.value}`)
      if (!fieldValue?.value) return null
      return {
        id: field.id,
        value: valueFromOptions?.value,
        label: valueFromOptions?.title,
      }
  }
}

/* Генерируем объект [id]: {значение} на основе типа поля */
export const generateFieldsValuesState = (fields: IField[], fieldValues: IFieldValue[]) => {
  return fields.reduce((acc, item) => {
    const fieldValue = fieldValues.find(innerItem => `${innerItem?.id}` === `${item.id}`)
    acc[item.id] = getFieldValueByType(item, fieldValue)
    return acc
  }, {})
}

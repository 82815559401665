import React from 'react'
import cx from 'classnames'

import styles from './table.module.scss'

const Row = <RowData,>(
  props: React.PropsWithChildren<{ id?: string; data?: RowData }>,
) => {
  const { children } = props
  return <>{children}</>
}

const Column = (
  props: React.PropsWithChildren<{
    title?: string | React.ReactNode
    className?: string
    align?: align
    colSpan?: number
  }>,
) => {
  const { children } = props
  return <>{children}</>
}

interface TableProps {
  striped?: boolean
  withCollapse?: boolean
  stickyHeader?: boolean
  withoutHead?: boolean
  theme?: 'primary' | 'blue' | 'red'
  className?: string
  emptyRow?: string
  children:
    | React.ReactElement<React.ComponentProps<typeof Row>>
    | React.ReactElement<React.ComponentProps<typeof Row>>[]
}

type align = 'right' | 'left' | 'center' | 'justify' | 'char'

const Table = <RowData,>(props: React.PropsWithChildren<TableProps>) => {
  const {
    children,
    withCollapse,
    theme = 'simple',
    className,
    stickyHeader,
    emptyRow,
    withoutHead = false,
    striped = false,
  } = props

  const defaultRow = (
    children
      ? Array.isArray(children)
        ? children[0]
        : children
      : [{ props: { children: [] } }]
  ) as { props: any } //TODO type

  const columns = defaultRow
    ? defaultRow.props.children.map(
        (c: { props: { title: string; align?: align } }) => ({
          title: c.props.title,
          align: c.props?.align || 'left',
        }),
      )
    : []

  return (
    <div
      className={cx(
        styles.wrapper,
        styles[theme],
        className,
        stickyHeader ? styles.sticky : null,
        striped ? styles.striped : null,
      )}
    >
      {columns.length ? (
        <table cellPadding={0} cellSpacing={0}>
          {!withoutHead ? (
            <thead>
              <tr>
                {columns.map(
                  (c: { title: string; align?: align }, i: number) => (
                    <th align={c.align || 'left'} key={i}>
                      {c.title}
                    </th>
                  ),
                )}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {React.Children.map(
              children,
              (
                c: any, //TODO type
                i: number,
              ) => (
                <tr key={i}>
                  {!emptyRow ? (
                    <>
                      {c.props.children.map((cc: any, j: number) => (
                        <td
                          key={`${i}-${j}`}
                          className={cc.props.className}
                          align={cc.props.align}
                          colSpan={cc.props.colSpan ? cc.props.colSpan : null}
                        >
                          {cc.props.children}
                        </td>
                      ))}
                    </>
                  ) : (
                    <td
                      key={1010101010}
                      align="center"
                      colSpan={columns.length}
                      className={styles.light}
                    >
                      {emptyRow}
                    </td>
                  )}
                </tr>
              ),
            )}
          </tbody>
        </table>
      ) : null}
    </div>
  )
}

Table.Row = Row
Table.Column = Column

export default Table


export const MOCK_GET_INSTRUCTION = {
  success: true,
  instruction: {
    id: "0000000006",
    title: "Не действ. DLV - CDEK",
    value: "<h4>ЗАГОЛОВОК</h4><h4>текст</h4><p>3</p>",
    soundURL: "https://storage.yandexcloud.net/tempoline-files/4fadff5b-ebd9-4e20-aded-fcfa189cf332.mp3"
  },
}

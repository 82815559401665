import api from 'src/api'

import { ApiReqResult, IContainer } from 'src/interfaces'
import { convertObjectToURLParams } from 'src/helpers'
import { IRefund, IRefundSku } from '../../interfaces/refund/refund'

export interface IGetReturnsParams {
  external_id?: string
  date_from?: string
  date_to?: string
  statuses?: string[]
  delivery_companies?: string[]
}

export interface IGetReturnsResult extends ApiReqResult {
  containers?: IContainer[]
  returns?: IRefund[]
  skus?: IRefundSku[]
}

const useGetReturns = () => ({
  fetch: async (paramsObj: IGetReturnsParams) => {
    const searchParams = convertObjectToURLParams(paramsObj)
    const result = await api.get(`/return${searchParams}`)
    if (!result.data.success) {
      throw new Error(result.data.message)
    }
    return result.data as IGetReturnsResult
  },
})

export default useGetReturns

import React, { useEffect, useState } from 'react'

import styles from './item-sticker-print-modal.module.scss'

import img from './assets/sticker.svg'

import { Timer } from '@consta/uikit/Timer'
import { Button } from '@consta/uikit/Button'

import { Modal } from 'src/components'

import { ModalProps } from 'src/interfaces'

interface IItemStickerPrintModal extends ModalProps {
  backTo?: string
  title?: string
  subtitle?: string
}

const ItemStickerPrintModal = (props: IItemStickerPrintModal) => {
  const {
    isOpen,
    onClose,
    title = 'Печать стикера',
    subtitle = `Приклейте стикер`,
    backTo = 'упаковке',
  } = props

  const [timeCount, setTimeCount] = useState<number>(5)

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeCount((state) => state - 1)
    }, 1000)

    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (timeCount === 0) {
      onClose()
    }
  }, [timeCount])

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay={false}
      onOverlayClick={(): boolean => false}
      className={styles.sticker}
      title={title}
      img={img}
      subtitle={subtitle}
    >
      <Timer size="m" seconds={timeCount} progress={timeCount * 10} placeholder='' />

      <Button
        label={`Вернуться к ${backTo}`}
        view="clear"
        className={styles.btnClear}
        onClick={() => onClose()}
      />
    </Modal>
  )
}

export default ItemStickerPrintModal

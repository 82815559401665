import api from 'src/api'
import { IDefectType } from 'src/interfaces/refund/refund'


const useGetDefectType = () => ({
  fetch: async () => {
    const result = await api.get(`/defect_type?size=1000`)
    if (!result.data.success) {
      throw new Error(result.data.message)
    }
    return result.data.defects as IDefectType[]
  }
})




export default useGetDefectType

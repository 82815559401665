export const MOCK_FULL_BY_PLACES = {
  places: [
    {
      barcode: 'K010101',
      condition: 'GOOD',
      id: '2c544b5c-3c86-4627-a6c9-d08a3f6fec2b',
      title: 'K-01-01-01',
      topology: {
        line: 1,
        position: '1',
        section: 'K',
        tier: 1,
      },
      type: 'CELL',
    },
  ],
  skus: [
    {
      article: 'Артикул товара 1010',
      barcodes: [
        {
          type: 'COMMON',
          value: '0000000001',
        },
      ],
      batch_accounting: false,
      batch_expdate_mandatory: false,
      batches: [],
      dimensions_fact: {},
      dimensions_plan: {
        depth: 10,
        height: 10,
        width: 10,
      },
      human_id: '1010',
      id: '3255753c-6ab1-48f6-8930-68749877fe84',
      images: [
        {
          position: 0,
          preview_url: '',
          url: '',
        },
      ],
      parts: [],
      require_dimensions: true,
      require_weight: true,
      scan_serial_numbers: [
        {
          code: 'CHESTNIY_ZNAK',
          template: '*',
          title: 'Chestniy znak',
        },
      ],
      title: 'Название товара 1010',
      title_en: 'Title of SKU 1010',
    },
    {
      article: 'Артикул товара 1011',
      barcodes: [
        {
          type: 'COMMON',
          value: '202',
        },
        {
          type: 'COMMON',
          value: '203',
        },
        {
          type: 'COMMON',
          value: '204',
        },
      ],
      batch_accounting: false,
      batch_expdate_mandatory: false,
      batches: [],
      dimensions_fact: {},
      dimensions_plan: {
        depth: 10,
        height: 10,
        width: 10,
      },
      human_id: '1011',
      id: '3255753c-6ab1-48f6-8930-68749877fe05',
      images: [
        {
          position: 0,
          preview_url: '',
          url: '',
        },
      ],
      parts: [],
      require_dimensions: true,
      require_weight: true,
      scan_serial_numbers: [
        {
          code: 'CHESTNIY_ZNAK',
          template: '*',
          title: 'Chestniy znak',
        },
      ],
      title: 'Тест 1',
      title_en: 'Title of SKU 1011',
    },
    {
      article: 'Артикул товара 1012',
      barcodes: [
        {
          type: 'COMMON',
          value: '302',
        },
        {
          type: 'COMMON',
          value: '303',
        },
        {
          type: 'COMMON',
          value: '304',
        },
      ],
      batch_accounting: false,
      batch_expdate_mandatory: false,
      batches: [],
      dimensions_fact: {},
      dimensions_plan: {
        depth: 10,
        height: 10,
        width: 10,
      },
      human_id: '1012',
      id: '3255753c-6ab1-48f6-8930-68749877fe06',
      images: [
        {
          position: 0,
          preview_url: '',
          url: '',
        },
      ],
      parts: [],
      require_dimensions: true,
      require_weight: true,
      scan_serial_numbers: [
        {
          code: 'CHESTNIY_ZNAK',
          template: '*',
          title: 'Chestniy znak',
        },
      ],
      title: 'Тест 2',
      title_en: 'Title of SKU 1012',
    },
    {
      article: 'Артикул товара 1013',
      barcodes: [
        {
          type: 'COMMON',
          value: '402',
        },
        {
          type: 'COMMON',
          value: '403',
        },
        {
          type: 'COMMON',
          value: '404',
        },
      ],
      batch_accounting: false,
      batch_expdate_mandatory: false,
      batches: [],
      dimensions_fact: {},
      dimensions_plan: {
        depth: 10,
        height: 10,
        width: 10,
      },
      human_id: '1013',
      id: '3255753c-6ab1-48f6-8930-68749877fe07',
      images: [
        {
          position: 0,
          preview_url: '',
          url: '',
        },
      ],
      parts: [],
      require_dimensions: true,
      require_weight: true,
      scan_serial_numbers: [
        {
          code: 'CHESTNIY_ZNAK',
          template: '*',
          title: 'Chestniy znak',
        },
      ],
      title: 'Тест 3',
      title_en: 'Title of SKU 1013',
    },
    {
      article: 'Артикул товара 1014',
      barcodes: [
        {
          type: 'COMMON',
          value: '502',
        },
        {
          type: 'COMMON',
          value: '503',
        },
        {
          type: 'COMMON',
          value: '504',
        },
      ],
      batch_accounting: false,
      batch_expdate_mandatory: false,
      batches: [],
      dimensions_fact: {},
      dimensions_plan: {
        depth: 10,
        height: 10,
        width: 10,
      },
      human_id: '1014',
      id: '3255753c-6ab1-48f6-8930-68749877fe08',
      images: [
        {
          position: 0,
          preview_url: '',
          url: '',
        },
      ],
      parts: [],
      require_dimensions: true,
      require_weight: true,
      scan_serial_numbers: [
        {
          code: 'CHESTNIY_ZNAK',
          template: '*',
          title: 'Chestniy znak',
        },
      ],
      title: 'Тест 4',
      title_en: 'Title of SKU 1014',
    },
  ],
  stock: [
    {
      place_id: '2c544b5c-3c86-4627-a6c9-d08a3f6fec2b',
      quantity: 97,
      reserved: 80,
      sku_id: '3255753c-6ab1-48f6-8930-68749877fe84',
    },
    {
      place_id: '2c544b5c-3c86-4627-a6c9-d08a3f6fec2b',
      quantity: 3,
      reserved: 1,
      sku_id: '3255753c-6ab1-48f6-8930-68749877fe05',
    },
    {
      place_id: '2c544b5c-3c86-4627-a6c9-d08a3f6fec2b',
      quantity: 2,
      reserved: 0,
      sku_id: '3255753c-6ab1-48f6-8930-68749877fe06',
    },
    {
      place_id: '2c544b5c-3c86-4627-a6c9-d08a3f6fec2b',
      quantity: 1,
      reserved: 0,
      sku_id: '3255753c-6ab1-48f6-8930-68749877fe07',
    },
    {
      place_id: '2c544b5c-3c86-4627-a6c9-d08a3f6fec2b',
      quantity: 5,
      reserved: 0,
      sku_id: '3255753c-6ab1-48f6-8930-68749877fe08',
    },
  ],
  success: true,
  errors: [],
}

import React from 'react'

import styles from './item-preview.module.scss'

import { Img } from 'src/components'

import { convertWeight, getBatchStr, hideSymbols } from 'src/helpers'
import noImg from 'src/assets/images/no-image.png'
import { ItemPreviewType } from 'src/interfaces'
import cx from 'classnames'
import { useToggle } from '../../hooks'

interface ItemPreviewProps extends ItemPreviewType {
  direction?: 'horizontal' | 'vertical'
  weight?: string | number
  withOpenBarcode?: boolean
  imgClassName?: string
}

const ItemPreview = (props: ItemPreviewProps) => {
  const {
    image,
    title,
    direction = 'horizontal',
    article,
    barcode,
    batch,
    weight,
    merchant,
    withOpenBarcode,
    imgClassName,
  } = props

  const {
    value: fullNameVisible,
    toggle: toggleName,
  } = useToggle()

  const MAX_SYMBOLS = 50
  const shortName = title.slice(0, MAX_SYMBOLS)
  return (
    <div className={cx(styles.preview, styles[direction])}>
      <Img className={cx(styles.img, imgClassName)} src={image || noImg} yandex />
      <div className={styles.nameBlock}>
        {batch?.num || batch?.exp_date ? (
          <div>
            <h5>Партия</h5>
            <h2>{getBatchStr(batch)}</h2>
          </div>
        ) : null}

        <div onClick={toggleName}>
          <h5>Наименование</h5>
          <h2>
            {
              fullNameVisible
                ? title
                : shortName + (title.length > MAX_SYMBOLS && !fullNameVisible ? '...' : '')
            }
          </h2>
        </div>
        {
          merchant ? (
            <div>
              <h5>Организация</h5>
              <h3>{merchant}</h3>
            </div>
          ) : null
        }
        {barcode ? (
          <div>
            <h5>Штрихкод</h5>
            <h3>
              {
                withOpenBarcode
                  ? barcode
                  : hideSymbols(6, barcode)
              }
            </h3>
          </div>
        ) : null}

        {article ? (
          <div>
            <h5>Артикул</h5>
            <h3>{article}</h3>
          </div>
        ) : null}

        {weight ? (
          <div>
            <h5>Текущий вес</h5>
            <h2>{convertWeight('g', 'kg', +weight)} кг.</h2>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export default ItemPreview

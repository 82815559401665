import { useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult } from 'src/interfaces'
import { PreCalcItem, Service, ServiceDocument } from '../../interfaces/billing'

/*
 * Получение списка документов и услуг для анкеты
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Okazannye-uslugi/operation/getServiceDocsByParent
 */


interface IServiceDocByParentResult extends ApiReqResult {
  /** Документы */
  documents: ServiceDocument[]
  /** Предварительный расчет позиций документа */
  pre_calc_items: PreCalcItem[]
  /** Услуги */
  services: Service[]
}

interface IServiceDocByParentParams {
  /** Идентификатор родительского документа */
  parent_id: string
}

const useGetServiceDocByParent = (paramsData: {
  params: IServiceDocByParentParams,
  handleSuccess?: (data: IServiceDocByParentResult) => void,
  handleSettled?: () => void,
  handleError?: () => void,
}) => {
  const {
    params,
    handleSuccess,
    handleSettled,
    handleError,
  } = paramsData

  return useQuery(
    [`getServiceDocByParent`],
    async () => {
      const result = await api.get(`/billing/service-doc/by-parent?parent_id=${params.parent_id}`)
      return result.data
    },
    {
      onSuccess: (data: IServiceDocByParentResult) => {
        if (data?.success) {
          return handleSuccess?.(data)
        }
        handleError?.()
      },
      onSettled: handleSettled,
      onError: handleError,
    },
  )
}

export default useGetServiceDocByParent


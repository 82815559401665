interface IMock<P, R> {
  key: keyof P
  value: any
  result: R
  logData?: any
}

const log = (logName?: any) => {
  if (logName) {
    console.log('req: ', logName)
  }
}

export const provideMock = <P, R>(
  hook: (props: P) => Promise<R>,
  params: P,
  mock?: IMock<P, R> | IMock<P, R>[],
) => {
  if (process.env.NODE_ENV !== 'production' && mock) {
    if (!Array.isArray(mock)) {
      if (params[mock.key] === mock.value) {
        return async () => {
          log(mock.logData)
          return await Promise.resolve(mock.result)
        }
      }
    } else {
      const findMock = mock.find((m: IMock<P, R>) => params[m.key] === m.value)
      if (findMock) {
        return async () => {
          log(findMock.logData)
          return await Promise.resolve(findMock.result)
        }
      }
    }
  }

  return async () => await hook(params)
}

export * from './cell'
export * from './printer'
export * from './packing'
export * from './receiving'
export * from './items'
export * from './registries'
export * from './log'
export * from './documents'
export * from './picking'
export * from './relabeling'
export * from './big_acceptance_mock'
export * from './crossdockingSorting'

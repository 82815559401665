import { useMutation } from 'react-query'
import api from 'src/api'

import { ApiReqResult, IGate, ShipmentStatusesEnum } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'

/*
  Создание/редактирование отгрузки
  wiki:
  https://wiki.kak2c.ru/link/785#bkmrk-%D0%A1%D0%BE%D0%B7%D0%B4%D0%B0%D0%BD%D0%B8%D0%B5%2F%D1%80%D0%B5%D0%B4%D0%B0%D0%BA%D1%82%D0%B8%D1%80%D0%BE%D0%B2%D0%B0
*/
interface IApiResult extends ApiReqResult {
  id: string
}
let IGateId: Pick<IGate, 'id'>
export interface IRequestShipmentData {
  id?: string
  date?: string
  delivery_service?: string
  carrier_id?: string
  vehicle_id?: string
  gate?: IGate | typeof IGateId
  comment?: string
  status?: ShipmentStatusesEnum | string
  shipment_plans?: string[]
}

const useUpdateShipment = (options?: UseQueryOptions<any>) => {
  // todo поправить (скрыл ошибку при обновлении консты)
  //@ts-ignore
  return useMutation(
    async (data: IRequestShipmentData) =>
      api.post(`/shipment${data.id ? `/${data.id}` : ''}`, data),
    options,
  )
}

export default useUpdateShipment

import React from 'react'
import styles from './QuantityCell.module.scss'
import { Button } from '@consta/uikit/Button'
import InputWrapper from 'src/components/InputWrapper'

interface IQuantityCell {
  id: string
  errorTxt?: string
  skuQuantity: number
  quantityValue?: number
  handleSetPrintQuantity?: (itemId: string, value?: number) => void
}
const QuantityCell = (props: IQuantityCell) => {
  const { id, errorTxt, skuQuantity, quantityValue, handleSetPrintQuantity } = props

  const updateValue = (newValue: string | null) => {
    handleSetPrintQuantity?.(id, newValue ? Number(newValue) : undefined)
  }
  return (
    <div className={styles.printInputWrapper}>
      <InputWrapper
        isInteger
        id={'quantity'}
        handleChange={updateValue}
        value={quantityValue?.toString()}
        className={styles.countInput}
        error={errorTxt}
      />
      <Button
        label={'Вставить кол-во'}
        view={'ghost'}
        onClick={() => updateValue(String(skuQuantity || 0))}
        size={'m'}
      />
    </div>
  )
}
export default QuantityCell

import api from 'src/api'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { ApiReqResult } from '../../interfaces'

interface usePickingFullCompleteParams {
  doc_id: string
  data?: {
    warehouse_place_barcode?: string
    with_errors?: boolean
  }
}

const pickingFullComplete = async ({
  doc_id,
  data = {}
}: usePickingFullCompleteParams) => {
  if (!doc_id) {
    return
  }
  const result = await api.post(`/picking/${doc_id}/full-complete`, data)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

const usePickingFullComplete = (options?: UseMutationOptions<any,ApiReqResult,any>) => {
  return useMutation(pickingFullComplete, options)
}

export default usePickingFullComplete

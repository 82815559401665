import { useQuery } from 'react-query'
import api from 'src/api'

import { ApiReqResult, IOrdersLastItem } from 'src/interfaces'

const getPackedOrders = async () => {
  const result = await api.get(`/packing/orders/last?limit=10`)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface GetPackedOrdersResult extends ApiReqResult {
  orders: IOrdersLastItem[]
}

const useGetPackedOrders = (
  callback?: (orders: IOrdersLastItem[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<GetPackedOrdersResult>(
    [`getPackedOrders`],
    () => getPackedOrders(),
    {
      keepPreviousData: true,
      onSuccess: ({ success, orders }) => {
        if (success) {
          if (callback) {
            callback(orders)
          }
        } else {
          if (error) {
            error()
          }
        }
      },
      onError: () => {
        if (error) {
          error()
        }
      },
      onSettled: () => {
        if (settled) {
          settled()
        }
      },
    },
  ) as { data: GetPackedOrdersResult }
}

export default useGetPackedOrders

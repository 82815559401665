import React, { useState } from 'react'
import { ComboboxWrapper, SelectBatchModal } from 'src/components'
import { DefaultItem } from '@consta/uikit/Combobox'
import { IBatch, ISku } from '../../../../../interfaces'
import { useNotifications } from 'src/notification'
import { CommonFieldProps } from '../../types/props'

type Item = DefaultItem & {dto?: IBatch}
interface IComboboxSkuBatch extends Omit<CommonFieldProps, 'onChange' | 'value'> {
  sku: ISku
  withCreateItem?: boolean
  disabled?: boolean
  items: Item[]
  value: Item | null
  onChange?: (value: Item | null) => void
  updateSkuBatches?: (value: Item) => void
}

const ComboboxSkuBatch = (props: IComboboxSkuBatch) => {
  const {
    sku,
    updateSkuBatches,
    onChange,
    label = 'партия',
    placeholder = 'Выберите партию',
    items = [],
    withCreateItem = true,
    ...restProps
  } = props

  const notif = useNotifications()


  const [createBatchModalVisible, setCreateBatchModalVisible] = useState<boolean>(false)

  return (
    <>
      <ComboboxWrapper
        withCreateItem={withCreateItem}
        items={items}
        label={label}
        placeholder={placeholder}
        onCreate={() => {
          if (!sku) {
            notif.show('alert', 'Для создания партии требуется выбрать номенклатуру')
            return
          }
          setCreateBatchModalVisible(true)
        }}
        onChange={(data) => {
          onChange(data.value)
        }}
        {...restProps}
      />

      {
        createBatchModalVisible && sku ? (
          <SelectBatchModal
            isOpen={true}
            onlyCreate={true}
            expdate={sku.batch_expdate_mandatory}
            item={sku}
            onSubmit={(batch, isNewBatch) => {
              const newVal = {id: batch.id, label: `${batch.exp_date} ${batch.num}`, dto: batch}
              updateSkuBatches(newVal) // обновляем список партий для sku
              onChange(newVal) // обновляем выбранную партию
              setCreateBatchModalVisible(false)
            }}
            onClose={() => setCreateBatchModalVisible(false)}
          />
        ) : null
      }
    </>
  )
}
export default ComboboxSkuBatch

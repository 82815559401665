import React, { useEffect, useMemo, useState } from 'react'

import styles from './ChoiceTableModal.module.scss'

import { Button } from '@consta/uikit/Button'

import { Modal, TableV2 } from 'src/components'
import { IconComponent } from '@consta/icons/Icon'
import { ITableColumn, ITableRow, ModalProps } from 'src/interfaces'

import { generateTableColumns } from './utils'

/**
 * Модальное окно с таблицей выбора (чекбоксами)
 * */

interface ChoiceTableModalProps extends ModalProps {
  onSubmit: (selections: ITableRow[]) => void
  /** Остальные строки и столбцы (все кроме чекбокса) */
  rows: ITableRow[]
  columns: ITableColumn[]

  tableFullHeight?: boolean
  title?: string
  subtitle?: string
  submitBtnLabel: string
  cancelBtnIcon?: IconComponent
  submitBtnIcon?: IconComponent
  extraSlotFooter?: React.ReactNode
}

const ChoiceTableModal = (props: ChoiceTableModalProps) => {
  const {
    isOpen,
    rows,
    columns,
    onClose,
    onSubmit,
    tableFullHeight,
    title,
    subtitle,
    submitBtnLabel,
    cancelBtnIcon,
    submitBtnIcon,
    extraSlotFooter
  } = props

  const [selections, setSelections] = useState<string[]>([])

  useEffect(() => {
    if (selections.length) return
    const defaultCheckedRows = rows.filter(row => row.defaultChecked).map(row => row.id)
    setSelections(defaultCheckedRows)
  }, [rows])


  const generatedRows = useMemo(() => rows, [rows])
  const generatedColumns = useMemo(
    () => generateTableColumns(columns, generatedRows, selections, setSelections),
    [selections, columns, generatedRows]
  )


  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      title={title}
      subtitle={subtitle}
      size='m'
    >
      <div className={styles.selectWrap}>
        <TableV2
          fullHeight={tableFullHeight}
          theme={'gray'}
          rows={generatedRows}
          columns={generatedColumns}
          className={styles.tableWrap}
          maxHeightBody={640}
        />
      </div>
      {extraSlotFooter}
      <div className={styles.btns}>
        <Button
          iconLeft={cancelBtnIcon}
          label='Отмена'
          view='ghost'
          className={styles.btn}
          onClick={onClose}
        />
        <Button
          iconLeft={submitBtnIcon}
          label={submitBtnLabel}
          disabled={!selections.length}
          onClick={() => {
            const result = selections.reduce((acc, selection) => {
              const selectedRow = rows.find(row => row.id === selection)
              if (selectedRow) {
                acc.push(selectedRow)
              }
              return acc
            }, [] as ITableRow[])
            onSubmit(result)
          }}
          className={styles.btn}
        />
      </div>
    </Modal>
  )
}

export default ChoiceTableModal

import React, { useState } from 'react'
import { IOrderPackagingOption } from 'src/interfaces'
import { ImageConfirmModal } from 'src/components'

interface IAdditionalPackConfirmProps {
  imgLoading?: boolean
  selected: IOrderPackagingOption | null
  onClose: () => void
  onSubmit: (packagingId: string) => void
}

const AdditionalPackConfirm = (props: IAdditionalPackConfirmProps) => {
  const {
    imgLoading,
    selected,
    onSubmit,
    onClose
  } = props

  const [error, setError] = useState<string>('')

  return (
    <ImageConfirmModal
      isOpen={true}
      imgLoading={imgLoading}
      title={'Подтвердите упаковку по изображению'}
      onClose={onClose}
      error={error}
      onSubmit={(value) => {
        const confirmed = selected?.barcodes?.includes(value)
        if (!confirmed) {
          setError(`ШК упаковки ${value} не подтвердилось`)
        }
        if (selected && confirmed) {
          onSubmit(selected.id)
          onClose()
        }
      }}
      confirmImg={selected?.img}
    />
  )
}

export default AdditionalPackConfirm

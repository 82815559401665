import { IContainer, IPhysicalSetOrderDto, PhysicalSetOrderStatusEnum } from '../../interfaces'
import { MOCK_GENERATE_TARE } from '../tare'

type MockType = { order: IPhysicalSetOrderDto, containers: IContainer[], success: boolean }

/* получение задания */
export const MOCK_GET_PHYSICAL_SET: MockType = {
  'containers': [],
  'order': {
    'activated': true,
    'create_ts': '2023-09-04T10:48:53.341705',
    'date': '2023-09-04T10:48:53.238000',
    'external_id': '1007',
    'id': 'P-TM-1007',
    'items': [
      {
        'quantity': 2,
        'sku': {
          'article': 'pen',
          'barcodes': [
            {
              'active': true,
              'barcode': '1',
              'type': 'COMMON',
              'used_in_last_supply': false,
            },
          ],
          'batch_accounting': false,
          'batch_expdate_mandatory': false,
          'batches': [],
          'dimensions_fact': {
            'depth': 1,
            'height': 1,
            'width': 1,
          },
          'dimensions_plan': {} as any,
          'external_id': '1041',
          'fragile': false,
          'id': 'TM-1041',
          'image': 'https://storage.yandexcloud.net/images-k2c/f5bdca2e-3aff-46e2-afda-8de561d1732a.png',
          'image_preview': 'https://storage.yandexcloud.net/images-k2c/f5bdca2e-3aff-46e2-afda-8de561d1732a-100.png',
          'images': [
            {
              'position': 0,
              'preview_url': 'https://storage.yandexcloud.net/images-k2c/f5bdca2e-3aff-46e2-afda-8de561d1732a-100.png',
              'url': 'https://storage.yandexcloud.net/images-k2c/f5bdca2e-3aff-46e2-afda-8de561d1732a.png',
            },
          ],
          'merchant': {
            'cam_id': '101',
            'contract_date': '2022-09-25',
            'contract_num': '1001',
            'enabled': true,
            'id': '2b78266b-0405-48f6-a263-3dba1869209f',
            'login': 'test@new-wms.ru',
            'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
            'title': 'Test new WMS merchant',
            'trade_title': 'Test new WMS merchant',
          },
          'parts': [],
          'require_dimensions': false,
          'require_weight': false,
          'scan_serial_numbers': [
            {
              code: "chestniy_znak",
              required: false,
              template: "{УИТ}",
              title: "Честный знак",
            }
          ],
          'title': 'Ручка',
          'type': 'SKU',
          'weight_fact': 33,
        },
      },
      {
        'quantity': 4,
        'sku': {
          'article': 'KTS',
          'barcodes': [
            {
              'active': true,
              'barcode': '2',
              'type': 'OZON',
              'used_in_last_supply': false,
            },
            {
              'active': true,
              'barcode': '100020003000',
              'type': 'COMMON',
              'used_in_last_supply': true,
            },
            {
              'active': true,
              'barcode': '110020003000',
              'type': 'WILDBERRIES',
              'used_in_last_supply': false,
            },
          ],
          'batch_accounting': false,
          'batch_expdate_mandatory': false,
          'batches': [],
          'dimensions_fact': {
            'depth': 10,
            'height': 30,
            'width': 20,
          },
          'dimensions_plan': {
            'depth': 100,
            'height': 100,
            'width': 100,
          },
          'external_id': '1040',
          'fragile': false,
          'id': 'TM-1040',
          'image': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b.png',
          'image_preview': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b-100.png',
          'images': [
            {
              'position': 0,
              'preview_url': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b-100.png',
              'url': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b.png',
            },
          ],
          'merchant': {
            'cam_id': '101',
            'contract_date': '2022-09-25',
            'contract_num': '1001',
            'enabled': true,
            'id': '2b78266b-0405-48f6-a263-3dba1869209f',
            'login': 'test@new-wms.ru',
            'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
            'title': 'Test new WMS merchant',
            'trade_title': 'Test new WMS merchant',
          },
          'parts': [],
          'require_dimensions': false,
          'require_weight': false,
          'scan_serial_numbers': [],
          'title': 'Кактус',
          'type': 'SKU',
          'weight_fact': 500,
          'weight_plan': 1000,
        },
      },
    ],
    'merchant': {
      'cam_id': '101',
      'contract_date': '2022-09-25',
      'contract_num': '1001',
      'enabled': true,
      'id': '2b78266b-0405-48f6-a263-3dba1869209f',
      'login': 'test@new-wms.ru',
      'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
      'title': 'Test new WMS merchant',
      'trade_title': 'Test new WMS merchant',
    },
    'operation': 'ASSEMBLING',
    'places': [],
    'quantity': 2,
    'sku': {
      'article': 'set',
      'barcodes': [
        {
          'active': true,
          'barcode': '2',
          'type': 'COMMON',
          'used_in_last_supply': false,
        },
      ],
      'batch_accounting': false,
      'batch_expdate_mandatory': false,
      'batches': [],
      // "dimensions_fact": {} ,
      'dimensions_plan': {
        'depth': 100,
        'height': 100,
        'width': 100,
      },
      'external_id': '1048',
      'fragile': false,
      'id': 'TM-1048',
      'images': [],
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'parts': [
        {
          'optional': false,
          'quantity': 1,
          'sku_id': 'TM-1041',
        },
        {
          'optional': false,
          'quantity': 2,
          'sku_id': 'TM-1040',
        },
      ],
      'require_dimensions': true,
      'require_weight': true,
      'scan_serial_numbers': [],
      'title': 'Комплект',
      'type': 'PHYSICAL_SET',
      'weight_plan': 1000,
    } as any,
    'sku_condition': 'GOOD',
    'status': PhysicalSetOrderStatusEnum.PICKED,
    'update_ts': '2023-09-04T10:48:53.352136',
    'uuid': 'cb837c7d-28f1-4758-94ac-8a5b657c72e9',
  } as any,
  'success': true,
}

/* старт упаковки */
export const MOCK_START_PHYSICAL_SET: MockType = {
  'order': {
    ...MOCK_GET_PHYSICAL_SET.order,
    status: PhysicalSetOrderStatusEnum.PACKING,
  },
  'containers': [],
  'success': true,
}

/* Задаем место */
export const MOCK_CREATE_EDIT_PHYSICAL_SET: MockType = {
  'order': {
    ...MOCK_START_PHYSICAL_SET.order,
    places: [
      {
        'activated': true,
        'comment': 'string',
        'create_ts': '2019-08-24T14:15:22Z',
        'date': '2019-08-24T14:15:22Z',
        'dimensions': {
          'depth': 0,
          'height': 0,
          'width': 0,
        },
        'executor': {
          'enabled': true,
          'id': '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          'login': '123',
          'name': 'string',
          'password': 'string',
          'patronymic': 'string',
          'position': 'GENERAL_DIRECTOR' as any,
          'roles': [],
          'surname': 'string',
        },
        'external_id': 'string',
        'id': 'string',
        'items': [
          {
            'barcode_used': '1',
            'id': 'TM-1041',
            'packed_at': '2019-08-24T14:15:22Z',
            'quantity': 2,
            'serial_numbers': [],
          },
          {
            'barcode_used': '2',
            'id': 'TM-1040',
            'packed_at': '2019-08-24T14:15:22Z',
            'quantity': 1,
            'serial_numbers': [],
          },
        ],
        // "merchant": {} as ,
        'packaging_id': 'string',
        // "pallet_id": "string",
        'status': 'PACKING' as any,
        'tare_barcode': 'TARA0000240',
        'update_ts': '2019-08-24T14:15:22Z',
        'uuid': '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
        'warehouse_comment': 'string',
        'weight': 0,
        'workspace': {
          'barcode': 'XX-1',
          'condition': 'GOOD' as any,
          'id': '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          'title': 'string',
          'zone_id': '4495020a-236f-46e0-9453-e3f9cc6476f4',
        },
        "sku_id": 'test_sku_id'
      },
    ],
  },
  'containers': MOCK_GENERATE_TARE.containers,
  'success': true,
}

export const MOCK_DISASSEMBLING_CREATE_EDIT_PHYSICAL_SET: MockType = {
  'order': {
    ...MOCK_START_PHYSICAL_SET.order,
    operation: 'DISASSEMBLING' as any,
    places: [
      {
        'activated': true,
        'comment': 'string',
        'create_ts': '2019-08-24T14:15:22Z',
        'date': '2019-08-24T14:15:22Z',
        'dimensions': {
          'depth': 0,
          'height': 0,
          'width': 0,
        },
        'executor': {
          'enabled': true,
          'id': '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          'login': '123',
          'name': 'string',
          'password': 'string',
          'patronymic': 'string',
          'position': 'GENERAL_DIRECTOR' as any,
          'roles': [],
          'surname': 'string',
        },
        'external_id': 'string',
        'id': 'string',
        'items': [
          {
            'barcode_used': '1',
            'id': 'TM-1041',
            'packed_at': '2019-08-24T14:15:22Z',
            'quantity': 2,
            'serial_numbers': [],
            'tare_barcode': 'TARA0000241',
          },
          {
            'barcode_used': '2',
            'id': 'TM-1040',
            'packed_at': '2019-08-24T14:15:22Z',
            'quantity': 1,
            'serial_numbers': [],
            'tare_barcode': 'TARA0000241',
          },
        ],
        // "merchant": {} as ,
        'packaging_id': 'string',
        // "pallet_id": "string",
        'status': 'PACKING' as any,
        'update_ts': '2019-08-24T14:15:22Z',
        'uuid': '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
        'warehouse_comment': 'string',
        'weight': 0,
        'workspace': {
          'barcode': 'XX-1',
          'condition': 'GOOD' as any,
          'id': '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          'title': 'string',
          'zone_id': '4495020a-236f-46e0-9453-e3f9cc6476f4',
        },
        "sku_id": 'test_sku_id'
      },
      {
        'activated': true,
        'comment': 'string',
        'create_ts': '2019-08-24T14:15:22Z',
        'date': '2019-08-24T14:15:22Z',
        'dimensions': {
          'depth': 0,
          'height': 0,
          'width': 0,
        },
        'executor': {
          'enabled': true,
          'id': '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          'login': '123',
          'name': 'string',
          'password': 'string',
          'patronymic': 'string',
          'position': 'GENERAL_DIRECTOR' as any,
          'roles': [],
          'surname': 'string',
        },
        'external_id': 'string',
        'id': 'string2',
        'items': [
          {
            'barcode_used': '1',
            'id': 'TM-1041',
            'packed_at': '2019-08-24T14:15:22Z',
            'quantity': 1,
            'serial_numbers': [],
            'tare_barcode': 'TARA0000241',
          },
          {
            'barcode_used': '2',
            'id': 'TM-1040',
            'packed_at': '2019-08-24T14:15:22Z',
            'quantity': 1,
            'serial_numbers': [],
            'tare_barcode': 'TARA0000240',
          },
        ],
        // "merchant": {} as ,
        'packaging_id': 'string',
        // "pallet_id": "string",
        'status': 'PACKING' as any,
        'update_ts': '2019-08-24T14:15:22Z',
        'uuid': '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
        'warehouse_comment': 'string',
        'weight': 0,
        'workspace': {
          'barcode': 'XX-1',
          'condition': 'GOOD' as any,
          'id': '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          'title': 'string',
          'zone_id': '4495020a-236f-46e0-9453-e3f9cc6476f4',
        },
        "sku_id": 'test_sku_id'
      },
    ],
  },
  'containers': MOCK_GENERATE_TARE.containers,
  'success': true,
}


export const MOCK_GET_PHYSICAL_SET_ORDER_PLACE = {
  "place": {
    'activated': true,
    'comment': 'string',
    'create_ts': '2019-08-24T14:15:22Z',
    'date': '2019-08-24T14:15:22Z',
    'dimensions': {
      'depth': 0,
      'height': 0,
      'width': 0,
    },
    'executor': {
      'enabled': true,
      'id': '497f6eca-6276-4993-bfeb-53cbbbba6f08',
      'login': '123',
      'name': 'string',
      'password': 'string',
      'patronymic': 'string',
      'position': 'GENERAL_DIRECTOR' as any,
      'roles': [],
      'surname': 'string',
    },
    'external_id': 'string',
    'id': 'string2',
    'items': [
      {
        'barcode_used': '1',
        'id': 'TM-1041',
        'packed_at': '2019-08-24T14:15:22Z',
        'quantity': 1,
        'serial_numbers': [],
        'tare_barcode': 'TARA0000241',
      },
      {
        'barcode_used': '2',
        'id': 'TM-1040',
        'packed_at': '2019-08-24T14:15:22Z',
        'quantity': 1,
        'serial_numbers': [],
        'tare_barcode': 'TARA0000240',
      },
    ],
    'packaging_id': 'string',
    'status': 'PACKING' as any,
    'update_ts': '2019-08-24T14:15:22Z',
    'uuid': '095be615-a8ad-4c33-8e9c-c7612fbf6c9f',
    'warehouse_comment': 'string',
    'weight': 0,
    'workspace': {
      'barcode': 'XX-1',
      'condition': 'GOOD' as any,
      'id': '497f6eca-6276-4993-bfeb-53cbbbba6f08',
      'title': 'string',
      'zone_id': '4495020a-236f-46e0-9453-e3f9cc6476f4',
    },
    "sku_id": 'test_sku_id'
  },
  'containers': MOCK_GENERATE_TARE.containers,
  'success': true,
}
import {
  ApiReqResult,
  IBarcode,
  IBatch,
  ICategory,
  IDimensions,
  IDimensionsObj,
  IImage,
  IMerchant,
  ISerialNumber,
} from 'src/interfaces'

export interface useCreateBatchResult extends ApiReqResult {
  id: string
}

export interface ISkuPart {
  /** true - если товар не обязателен для набора */
  optional: boolean
  /** Кол-во товара */
  quantity: number
  /** Идентификатор товара */
  sku_id: string
}

export enum SkuSerialNumberCodesEnum {
  chestniy_znak = "chestniy_znak",
  imei = "imei",
  unique_number = "unique_number",
}
export enum SkuSerialNumberStatusEnum {
  IN_POOL = "IN_POOL",
  PRINTED = "PRINTED",
  IN_STOCK = "IN_STOCK",
  OUT_OF_STOCK = "OUT_OF_STOCK",
  ERROR = "ERROR",
}
export interface SkuSerialNumberDto {
  code: SkuSerialNumberCodesEnum
  factory: boolean
  sku_id: string
  status: SkuSerialNumberStatusEnum
  status_date: string
  value: string
}

export interface ISku {
  article: string
  barcodes: IBarcode[]
  batch_accounting: boolean
  batch_expdate_mandatory: boolean
  batches: IBatch[]
  category?: ICategory
  country_of_origin: string
  dimensions_fact: IDimensionsObj | IDimensions
  dimensions_plan: IDimensionsObj | IDimensions
  external_id: string
  fragile: boolean
  id: string
  image: string
  image_preview: string
  images: IImage[]
  merchant: IMerchant
  parts: ISkuPart[]
  require_dimensions: boolean
  require_weight: boolean
  scan_serial_numbers: ISerialNumber[]
  title: string
  title_en: string
  tnved_code: string
  type: string
  weight_fact: number
  weight_plan: number
  manufacturer: string
  composition: string
  expiration_months?: number
}

export type SkuWithBarcode = ISku & { barcode_used?: string }
import React, { useEffect } from 'react'

import styles from './instruction.module.scss'
import ReactHtmlParser from 'react-html-parser'
import { ModalProps } from '../../interfaces'
import { Button, EnterHandler, Modal } from '../index'
import useGetPackingInstruction from '../../hooks/packing/useGetPackingInstruction'
import { Loader } from '@consta/uikit/Loader'
import { IconCheck } from '@consta/icons/IconCheck'
import { IconVolumeUp } from '@consta/icons/IconVolumeUp'
import cx from 'classnames'
import { useDynamicSoundsHook } from '../../sound/SoundProvider'

interface InstructionContentProps {
  withoutTitle?: boolean
  title?: string
  value: string
  wrapperClassName?: string
}

export const InstructionContent = (props: InstructionContentProps) => {
  const {
    withoutTitle = false,
    title = 'Руководство по упаковке',
    value,
    wrapperClassName
  } = props
  
  return (
    <div className={cx(styles.content, wrapperClassName)}>
      {!withoutTitle ? <h3>{title}</h3> : null}
      <div className={styles.instruction}>
        {ReactHtmlParser(value ?? '')}
      </div>
    </div>
  )
}

interface InstructionModal extends Omit<InstructionContentProps, 'value'>, ModalProps {
  instructionId: string
  headerTitle?: string
  onAccept: () => void,
}

export const InstructionModal = (props: InstructionModal) => {
  const {
    isOpen,
    onClose,
    onAccept,
    onMount,
    headerTitle,
    instructionId,
    ...contentRestProps
  } = props

  const { playAudio } = useDynamicSoundsHook()

  const { data: instructionData, isFetching } = useGetPackingInstruction({ instructionId })

  const instruction = instructionData?.instruction

  function playInstructionSound() {
    const soundURL = instruction?.soundURL
    if (soundURL) {
      playAudio(soundURL)
    }
  }

  useEffect(() => {
    if (instructionData && !isFetching) {
      playInstructionSound()
    }
  }, [instructionData, isFetching])


  return (
    <EnterHandler
      onEnter={onAccept}
    >
      <Modal
        isOpen={isOpen}
        hasOverlay
        title={headerTitle}
        onOverlayClick={() => {
        }}
        className={styles.modal}
        onClose={onClose}
        withClose
      >
        {
          isFetching ? (
            <div className={styles.loaderWrapper}>
              <Loader className={styles.loader} />
            </div>
          ) : (
            <InstructionContent
              {...contentRestProps}
              value={instruction?.value || ''}
              wrapperClassName={styles.wrapperClassName}
            />
          )
        }

        <div className={styles.footer}>
          {instruction?.soundURL ? (
            <Button
              label="Повторить звук"
              iconRight={IconVolumeUp}
              onClick={playInstructionSound}
              className={styles.btnPrint}
              loading={isFetching}
              disabled={isFetching}
            />
          ) : <div />}

          <Button
            label='Ок'
            view='primary'
            loading={isFetching}
            className={styles.primary}
            onClick={onAccept}
            iconLeft={IconCheck}
            disabled={isFetching}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}



import api from 'src/api'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import { IPaginationFilter, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from 'src/helpers'


interface IParam extends IPaginationFilter {

}
interface IResult extends PaginationResult {

}

const getUsers = async (params?: IParam) => {
  const paramsStr = convertObjectToURLParams(params)
  const result = await api.get(`/warehouse/user${paramsStr}`)

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

export const useGetUsers = () => ({
  fetch: getUsers,
})

export const useGetQueryUsers = (
  params?: IParam,
  options?: UseQueryOptions<IParam,unknown,IResult, any>,
) =>
  useQuery(
    ['getUsers', params],
    async () => await getUsers(params),
    options
  )

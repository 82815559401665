import { useMutation } from 'react-query'
import api from 'src/api'

import { ApiReqResult, IError } from 'src/interfaces'
import { IBatchGenerateData } from './useBatchGenerate'
import { UseMutationOptions } from 'react-query/types/react/types'

export type IBatchPreviewGroupParam = { title: string, value: string }
export type IBatchPreviewGroup = {
  id: number,
  orders_count: number,
  params: IBatchPreviewGroupParam[]
}

export interface IBatchResult {
  success: boolean
  errors?: IError[]
  groups: IBatchPreviewGroup[],
}

interface IApiResult extends ApiReqResult {
  results: IBatchResult
}


const useBatchGeneratePreviewGroups = (
  options?: UseMutationOptions<any, IApiResult, any>,
) => {
  return useMutation(
    async (data: IBatchGenerateData) => {
      const result = await api.post(`/order-batch/preview-groups`, data)
      if (!result?.data?.success) {
        throw result
      }
      return result.data as IBatchResult
    },
    {
      ...options,
    },
  )
}

export default useBatchGeneratePreviewGroups

import cx from 'classnames'

const IconFragile = (props: any) => (
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}
       className={cx('filled', props.className)}>
    <path
      d='M4.78125 1.00001L4 4.57143L5.81818 4.21429L5.45455 6L8 2.2987L6.12987 2.94805L6.70484 1L9 1L9 4.8C9 6.3856 7.91886 7.70185 6.5 7.95576V10H9V11H3V10H5.5V7.95576C4.08114 7.70185 3 6.3856 3 4.8V1.00001L4.78125 1.00001Z'
      fill='white' />
  </svg>
)

export default IconFragile
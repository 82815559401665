import React, { useRef } from 'react'
import styles from './scan-item-condition-modal.module.scss'
import { InputWrapper, Modal } from 'src/components'
import { useNotifications } from 'src/notification'
import { ConditionEnum, ModalProps } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Button } from '@consta/uikit/Button'
import { DEFECT_CONDITION_BARCODE, GOOD_CONDITION_BARCODE } from 'src/config'
import { ResponsesImageEmptyBox } from '@consta/uikit/ResponsesImageEmptyBox'

interface ScanItemStateModalProps extends ModalProps {
  withClose?: boolean
  handleSelect: (condition: ConditionEnum) => void
}

const ScanItemConditionModal = (props: ScanItemStateModalProps) => {
  const {
    withClose = true,
    isOpen,
    onClose,
    handleSelect
  } = props

  const notification = useNotifications()

  const input = useRef<HTMLTextAreaElement | HTMLInputElement | null>(null)

  const focusBarcodeInput = () => {
    if (!input?.current) return
    input.current.focus()
  }

  const handleChange = (scanVal: TextFieldPropValue) => {
    if (!scanVal) {
      return
    }
    const val = scanVal.trim()
    let res: ConditionEnum
    if (val === GOOD_CONDITION_BARCODE) {
      res = ConditionEnum.GOOD
    } else if (val === DEFECT_CONDITION_BARCODE) {
      res = ConditionEnum.DEFECT
    } else {
      notification?.show('alert', 'Данный ШК статуса не используется')
      focusBarcodeInput();
      return
    }
    handleSelect(res);
  }


  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.sticker}
      title=""
      subtitle=""
      size="s"
      imgClass={styles.img}
    >
      <div className={styles.body}>
        <div className={styles.imageWrap}>
          <ResponsesImageEmptyBox />
        </div>
        <h2>Определите состояние товара</h2>
        <h4>Отсканируйте ШК состояния товара</h4>
        <InputWrapper
          id={'setSticker'}
          handleChange={handleChange}
          autoFocus
          className={styles.itemInput}
          placeholder="ШК состояния товара"
          size="l"
          inputRef={input}
          withDelay
        />
        {
          withClose ? (
            <div className={styles.btns}>
              <Button
                label="Отмена"
                view="ghost"
                onClick={onClose}
                size="l"
              />
            </div>
          ) : null
        }
      </div>
    </Modal>
  )
}

export default ScanItemConditionModal

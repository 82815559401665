import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import styles from './NavigationMenu.module.scss'
import { IconDocFilled } from '@consta/icons/IconDocFilled'
import { IconSelect } from '@consta/icons/IconSelect'
import { HeaderTypeEnum, IMenuList } from './routes'
import { Link, useParams } from 'react-router-dom'
import { IconCalculator } from '@consta/icons/IconCalculator'
import { useClickOutside } from '@consta/uikit/useClickOutside'
import { IconCards } from '@consta/icons/IconCards'
import cx from 'classnames'

const MenuIcon = {
  [HeaderTypeEnum.operations]: <IconCards className={styles.icon} />,
  [HeaderTypeEnum.documents]: <IconDocFilled className={styles.icon} />,
  [HeaderTypeEnum.reports]: <IconCalculator className={styles.icon} />
}

interface Props {
  mainMenuList: IMenuList[]
}
export const NavigationMenu = (props: Props) => {
  const { docType, page } = useParams<{ docType: string, page: string }>()

  const {
    mainMenuList
  } = props

  const [activeIndexMenu, setActiveIndexMenu] = useState<number | null>(null)
  const sectionRef = useRef<HTMLElement>()
  const menuElementsRef = useRef(mainMenuList.map(() => createRef<HTMLLIElement>()))

  useEffect(() => {
    if (!menuElementsRef) return
    menuElementsRef.current = mainMenuList.map(() => createRef<HTMLLIElement>())
  }, [menuElementsRef, mainMenuList])

  /* Определяем к какому блоку меню относится текущая стр. */
  const findActiveMenuItemIndex = mainMenuList.findIndex(item =>
    item.pages.find(itemPage => [docType, page].includes(itemPage))
  )

  /* Вычисляем позицию меню */
  const currentElementPos = useMemo(() => {
    const currentElement = menuElementsRef.current[activeIndexMenu]?.current || null
    const menuItemPos = currentElement?.getBoundingClientRect() || { top: 0, left: 0, height: 0 }
    const menuPos = sectionRef?.current?.getBoundingClientRect() || { left: 0, height: 0 }

    const menuElData = mainMenuList[activeIndexMenu]
    const itemHeaderTypes = menuElData?.items?.reduce((acc, item) => {
      acc.add(item.headerType)
      return acc
    }, new Set())
    const itemHasThreeBlocks = itemHeaderTypes?.size > 2
    return {
      ...menuItemPos,
      top: menuPos.height + 20,
      left: itemHasThreeBlocks || !currentElement ? 0 : menuItemPos.left - menuPos.left,
    }
  }, [activeIndexMenu, menuElementsRef, mainMenuList, sectionRef])

  const handleClose = () => {
    setActiveIndexMenu(null)
  }

  useClickOutside({
    isActive: activeIndexMenu !== null,
    ignoreClicksInsideRefs: [sectionRef],
    handler: handleClose,
  })

  const renderSubMenu = (
    item,
    definedHeaderType: HeaderTypeEnum,
  ) => {
    if (
      item.items.filter((subItem) => subItem.headerType === definedHeaderType)
        .length === 0
    ) {
      return null
    }

    return (
      <div className={styles.column}>
        {definedHeaderType !== HeaderTypeEnum.noname && (
          <h3 className={styles.title}>
            {MenuIcon[definedHeaderType]}
            <span>{definedHeaderType}</span>
          </h3>
        )}
        <nav className={styles.navList}>
          {item.items
            .filter((subItem) => subItem.headerType === definedHeaderType)
            .map((subItem) => {
              return (
                <Link
                  key={subItem.name}
                  to={subItem.route}
                  onClick={handleClose}
                >
                  {subItem.name}
                </Link>
              )
            })}
        </nav>
      </div>
    )
  }

  const handleActiveMenu = (index: number) => {
    if (index !== activeIndexMenu) {
      setActiveIndexMenu(index)
    } else {
      setActiveIndexMenu(null)
    }
  }

  return (
    <section ref={sectionRef} className={styles.outerSection}>
      <nav>
        <ul className={styles.list}>
          {mainMenuList.map((item, index) => {
            const isActive = activeIndexMenu !== null
              ? activeIndexMenu === index
              : findActiveMenuItemIndex === index
            return (
              <li
                ref={menuElementsRef.current[index]}
                className={cx(styles.listButton, {[styles.itemActive]: isActive})}
                key={index}
                onClick={() => handleActiveMenu(index)}
              >
                <span>{item.label}</span>
                <IconSelect className={styles.icon} size="s" />
              </li>
            )
          })}
        </ul>
      </nav>
      {mainMenuList.map((item, index) => {
        return (
          activeIndexMenu === index && (
            <section
              className={styles.innerSection}
              key={index}
              style={{
                left: currentElementPos.left,
                top: currentElementPos.top,
              }}
            >
              {renderSubMenu(item, HeaderTypeEnum.operations)}
              {renderSubMenu(item, HeaderTypeEnum.documents)}
              {renderSubMenu(item, HeaderTypeEnum.reports)}
              {renderSubMenu(item, HeaderTypeEnum.noname)}
            </section>
          )
        )
      })}
    </section>
  )
}

import axios, { AxiosResponse } from 'axios'
import { appLocalStorage } from 'src/libs'

import { apiErrorLog, apiResErrorLog } from './logger'

import { API_BASE_URL } from 'src/config'

declare module 'axios' {
  export interface AxiosRequestConfig {
    source?: string
    errorCallback?: (error: string) => string
    notShowErrors?:boolean
  }
}

interface ResError {
  code: number
  message: string
  errorsticker?: {
    content: string
    printer: string
  }
}

const token = appLocalStorage.token

const api = axios.create({
  baseURL: `https://${API_BASE_URL}`,
  headers: {
    Authorization: token && `Bearer ${token}`,
  },
})

api.interceptors.response.use(
  (res: AxiosResponse) => {
    if (res.data.success === false && res.data.errors) {
      apiResErrorLog(res)
      /** На старой WMS ошибка с сообщением приходит через 200 ОК */
      showErrors(res.data.results?.map(err => err.errors).flat() || [])
      showErrors(res.data.errors, res.config.errorCallback)
    }
    // запрос за базами предусматривает ответ в виде массива,
    // если убрать проверку, падает фронт
    const isArrayResponse = Array.isArray(res.data)
    if (
      !res.data.success
      && !res.data.errors
      && !isArrayResponse
    ) {
      apiResErrorLog(res)
      res.data
        ? window.showNotification('alert', res.data)
        : window.showNotification(
            'alert',
            'Непредвиденная ошибка работы с сервером. Обратитесь к администратору.',
          )
    }
    return res
  },
  async (error) => {
    console.log('error',error)
    if (error.response?.status === 401) {
      window.showNotification('alert', '401 Unauthorized')
      if (
        error.response.config.url !== `v1/auth` &&
        error.response.config.url !== `v1/auth/refresh`
      ) {
        console.error(error)
        appLocalStorage.clear()
        window.location.reload()
      }
    } else {
      const data = error?.response?.data
      const config = error?.response?.config
      /** На новой WMS ошибка с сообщением приходит НЕ через 200 ОК, а обычно */
      if (data?.errors?.length) {
        if (!config?.notShowErrors) {
          showErrors(data.errors, config?.errorCallback)
        }
        return data.errors?.[0]
      }
      apiErrorLog(error)

      if (!config?.notShowErrors) {
        window.showNotification(
          'alert',
          'Непредвиденная ошибка работы с сервером. Обратитесь к администратору.',
        )
        return 'Непредвиденная ошибка работы с сервером. Обратитесь к администратору.'
      }
    }
  },
)

function showErrors(
  errors: ResError[],
  errorCallback?: (error: string) => string,
) {
  errors.forEach((e: ResError) => {
    if (e?.errorsticker?.content) {
      const { content, printer } = e?.errorsticker
      window.printSticker(content, { qzOptions: { printer } })
    }

    window.showNotification(
      'alert',
      errorCallback ? errorCallback(e.message) : e.message,
    )
  })
}

export default api

import React from 'react'
import { Sidebar as ConstaSidebar } from '@consta/uikit/Sidebar'
import { SidebarPropSize } from '@consta/uikit/__internal__/src/components/Sidebar/Sidebar'
import { Button } from '@consta/uikit/Button'
import { IconClose } from '@consta/icons/IconClose'
import styles from './Drawer.module.scss'

interface Props {
  isOpen?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  hasOverlay?: boolean;
  onClickOutside?: (event: MouseEvent) => void;
  onEsc?: (event: KeyboardEvent) => void;
  position?: 'right' | 'bottom' | 'left' | 'top';
  size?: SidebarPropSize;
  rootClassName?: string;
  container?: HTMLDivElement | undefined;
  afterClose?: () => void;
  withClose?: boolean
  children?: React.ReactNode
}

const Drawer: React.FC<Props> = (props) => {
  const {
    children,
    withClose = true
  } = props
  return (
    <ConstaSidebar
      {...props}
    >
      {
        withClose ? (
          <Button
            onlyIcon
            size={'m'}
            view={'clear'}
            iconLeft={IconClose}
            onClick={props.onClose}
            className={styles.closeIcon}
          />
        ) : null
      }
      {children}
    </ConstaSidebar>
  )
}

export default Drawer

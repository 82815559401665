import React from 'react'

import styles from './filter-view.module.scss'
import { Tag } from '@consta/uikit/Tag'
import { Button } from '@consta/uikit/Button'
import cx from 'classnames'

export interface IFilterView {
  key: string
  label: string
  value: string
}

interface IFilterProps {
  filtersView: IFilterView[]
  clearFilter?: (key: string) => void
  clearAllFilters?: () => void
  wrapperClassName?: string
  /** Кнопка сброса всех фильтров */
  isClearAllFilter?: boolean
  isClearFilter?: boolean
}

const FilterView = (props: IFilterProps) => {
  const {
    filtersView,
    clearFilter,
    clearAllFilters,
    wrapperClassName,
    isClearAllFilter = true,
    isClearFilter = true,
  } = props

  return (
    <div className={cx(styles.filtersView, wrapperClassName)}>
      {isClearAllFilter && filtersView?.length ? (
        <Button
          label={'Сбросить все'}
          view={'clear'}
          size={'s'}
          onClick={clearAllFilters}
          className={styles.clearFilter}
        />
      ) : null}
      {filtersView.map((item) =>
        isClearFilter ? (
          <Tag
            key={item.key}
            label={`${item.label}: ${item.value}`}
            mode={'cancel'}
            size={'l'}
            onCancel={() => clearFilter && clearFilter(item.key)}
          />
        ) : (
          <Tag
            key={item.key}
            label={`${item.label}: ${item.value}`}
            mode={'info'}
            size={'l'}
          />
        ),
      )}
    </div>
  )
}

export default FilterView

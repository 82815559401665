import React, { useState } from 'react'
import cx from 'classnames'

import styles from './defective-switch-wrapper.module.scss'

import { Switch } from '@consta/uikit/Switch'

import { ConditionEnum, ISupplyReceipt } from 'src/interfaces'

/*
 * !!! Важно
 * Компонент используется в приемке
 * Todo Надо отрефакторить компонент,
 *  чтобы можно было использовать не только в приемке
 * */

interface IDefectiveSwitchWrapper {
  tare: ISupplyReceipt
  handleConfirmGoods: (type: string) => void
  className?: string
  blurOrFocus: (type: 'focus' | 'blur') => void
}

const DefectiveSwitchWrapper = (props: IDefectiveSwitchWrapper) => {
  const { tare, handleConfirmGoods, className, blurOrFocus } = props

  const [showOnlyGoodWarning, setShowOnlyGoodWarning] = useState<boolean>(false)
  const [showOnlyDefectiveWarning, setShowOnlyDefectiveWarning] =
    useState<boolean>(false)

  const handleChangeDefective = (checked: boolean) => {
    if (checked) {
      setShowOnlyDefectiveWarning(true)
    } else {
      setShowOnlyGoodWarning(true)
    }
  }

  const handleConfirm = (type: string) => {
    handleConfirmGoods(type)

    if (type === 'defective') {
      setShowOnlyDefectiveWarning(false)
    } else {
      setShowOnlyGoodWarning(false)
    }
    blurOrFocus('focus')
  }

  const handleCloseWithFocus = (act: React.Dispatch<boolean>) => {
    act(false)
    blurOrFocus('focus')
  }
  const isDefective = tare.condition === ConditionEnum.DEFECT
  return (
    <>
      <div
        className={cx(styles.wrap, className)}
        onClick={() => handleChangeDefective(!isDefective)}
      >
        <span>Тара брака</span>
        <Switch checked={isDefective} />
      </div>
    </>
  )
}

export default DefectiveSwitchWrapper

export const MOCK_GET_REGISTER_BY_ID = {
  success: true,
  objects: {
    number: 'mock',
    cell_number: '012',
    courier_id: 'Wildberries',
    comment: '',
    NameOrganization: 'Чечевицина ИП',
    status: 'OnСheck',
    ship_date: '2021-11-04T00:00:00',
    items: [
      {
        order_id: 'CZ154328483',
        barcode: '1',
        barcodeadd: '*R3uklQD',
        order_date: '2021-12-19T18:40:46',
        scanned: false,
      },
      {
        order_id: 'CZ154396401',
        barcode: '2',
        barcodeadd: '*R3yB1BJ',
        order_date: '2021-12-19T19:40:09',
        scanned: false,
      },
      {
        order_id: 'CZ154578412',
        barcode: '3',
        barcodeadd: '*R8EYNyQ',
        order_date: '2021-12-19T22:05:22',
        scanned: false,
      },
      // {
      //   order_id: 'CZ154639915',
      //   barcode: '4815559461',
      //   barcodeadd: '*R8HmyVm',
      //   order_date: '2021-12-19T22:49:16',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ154856239',
      //   barcode: '4851903314',
      //   barcodeadd: '*SEyK1Jk',
      //   order_date: '2021-12-20T07:57:45',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ154856639',
      //   barcode: '4851910134',
      //   barcodeadd: '*SEyRfZj',
      //   order_date: '2021-12-20T07:57:53',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ154903073',
      //   barcode: '4852104927',
      //   barcodeadd: '*SE1Pt+X',
      //   order_date: '2021-12-20T09:14:56',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ154992711',
      //   barcode: '4852544023',
      //   barcodeadd: '*SE78hd5',
      //   order_date: '2021-12-20T10:59:20',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ154996610',
      //   barcode: '4852562009',
      //   barcodeadd: '*SE8OFl4',
      //   order_date: '2021-12-20T11:05:47',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ155011505',
      //   barcode: '4852610412',
      //   barcodeadd: '*SE89WwW',
      //   order_date: '2021-12-20T11:22:45',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ155033633',
      //   barcode: '4852677954',
      //   barcodeadd: '*SE9/UIS',
      //   order_date: '2021-12-20T11:41:49',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ155209178',
      //   barcode: '4853258926',
      //   barcodeadd: '*SFG2q6F',
      //   order_date: '2021-12-20T14:18:12',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ155219208',
      //   barcode: '4853293349',
      //   barcodeadd: '*SFHYSWB',
      //   order_date: '2021-12-20T14:32:35',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ155307592',
      //   barcode: '4853542508',
      //   barcodeadd: '*SFLLmyS',
      //   order_date: '2021-12-20T15:51:05',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ155324051',
      //   barcode: '4853878835',
      //   barcodeadd: '*SFQUDNV',
      //   order_date: '2021-12-20T16:09:20',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ155326845',
      //   barcode: '4854246671',
      //   barcodeadd: '*SFV7Q9F',
      //   order_date: '2021-12-20T16:09:27',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ155363108',
      //   barcode: '4856292633',
      //   barcodeadd: '*SF1JRk0',
      //   order_date: '2021-12-20T16:41:36',
      //   scanned: false,
      // },
      // {
      //   order_id: 'CZ155426253',
      //   barcode: '4858723848',
      //   barcodeadd: '*SGaPggF',
      //   order_date: '2021-12-20T17:41:27',
      //   scanned: false,
      // },
    ],
  },
}

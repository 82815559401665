import { useMutation } from 'react-query'
import api from 'src/api'

import { ApiReqResult, ISku, OrderPackingV2, StickerWithCopies } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


interface Params {
  placeId: string
}
const deleteTransportPlaceItem = async (params: Params) => {
  const result = await api.delete(`/packing-v2/transport-place/item/${params.placeId}`)
  if (!result.data?.success) {
    throw result
  }
  return result.data
}

interface Result extends ApiReqResult {
  order: OrderPackingV2
  skus: ISku[]
  files_for_print?: StickerWithCopies[]
}

const useDeleteTransportPlaceItem = (
  options?: UseMutationOptions<Result, any, any>,
) => {
  return useMutation(deleteTransportPlaceItem, options)
}

export default useDeleteTransportPlaceItem

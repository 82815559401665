import { useQuery } from 'react-query'

import api from 'src/api'
import { ApiReqResult, DocumentTypeEnum, IDocumentLight, IPaginationFilter } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { convertObjectToURLParams } from 'src/helpers'


interface IResult extends ApiReqResult {
  /** Документы */
  documents: IDocumentLight[]
}

interface IParams extends IPaginationFilter {
  document_type: DocumentTypeEnum

  merchant_ids?: string[]

  search?: string
}

const useGetDocument = (params: IParams, options?: UseQueryOptions<any>) => {
  return useQuery<IResult>(
    [`getDocument`, params],
    async () => {
      const paramsStr = convertObjectToURLParams(params)
      const result = await api.get(`/document${paramsStr}`)
      return result.data
    },
    { ...options },
  )
}

export default useGetDocument

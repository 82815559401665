import { useMutation } from 'react-query'
import api from 'src/api'

export interface IProps {
  title: string
  id: string
}

// http://wms-test.kak2c.ru/warehouse-api/warehouse/gate/{gate_id}
const useCreateGate = () => {
  const createGate = async (props: IProps) => {
    const result = await api.post(`/warehouse/gate`, props)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(createGate)
}

export default useCreateGate

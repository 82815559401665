import { ICombobox, OrderStatusEnum } from 'src/interfaces'
import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { generateStatusesOptions } from '../../helpers'

export const ORDER_STATUSES_DESCRIPTION: Record<
  OrderStatusEnum | string,
  string
> = {
  [OrderStatusEnum.new]: 'Новый',
  [OrderStatusEnum.picking]: 'На подборе',
  [OrderStatusEnum.picked]: 'Подобран',
  [OrderStatusEnum.packing]: 'На упаковке',
  [OrderStatusEnum.packing_error]: 'Ошибка упаковки',
  [OrderStatusEnum.packedAwaitingDocs]: 'Ожидает документы',
  [OrderStatusEnum.packedAwaitingRelabel]: 'Ожидает перемаркировки',
  [OrderStatusEnum.packed]: 'Упакован',
  [OrderStatusEnum.shipped]: 'Отгружен',
  [OrderStatusEnum.canceled]: 'Отменен',
  [OrderStatusEnum.return_partially]: 'Частичный возврат',
  [OrderStatusEnum.return]: 'Возвращен',
  [OrderStatusEnum.done]: 'Завершен',
}

export const ORDER_STATUS_BADGE_TYPE: Record<
  OrderStatusEnum | string,
  BadgePropStatus
> = {
  [OrderStatusEnum.new]: 'system',
  [OrderStatusEnum.picking]: 'normal',
  [OrderStatusEnum.picked]: 'normal',
  [OrderStatusEnum.packing]: 'normal',
  [OrderStatusEnum.packedAwaitingDocs]: 'normal',
  [OrderStatusEnum.packedAwaitingRelabel]: 'normal',
  [OrderStatusEnum.packed]: 'success',
  [OrderStatusEnum.shipped]: 'warning',
  [OrderStatusEnum.canceled]: 'warning',
  [OrderStatusEnum.return_partially]: 'warning',
  [OrderStatusEnum.return]: 'warning',
  [OrderStatusEnum.done]: 'warning',
}

export const orderStatusSelectOptions: ICombobox[] = generateStatusesOptions(
  ORDER_STATUSES_DESCRIPTION,
)

export const ORDER_STATUSES: {
  value: OrderStatusEnum
  label: string
  style: BadgePropStatus
}[] = Object.keys(ORDER_STATUSES_DESCRIPTION).map((key) => ({
  value: key as OrderStatusEnum,
  label: ORDER_STATUSES_DESCRIPTION[key as keyof object],
  style: ORDER_STATUS_BADGE_TYPE[key as keyof object],
}))

import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useDebounce as useDebounceConsta } from '@consta/uikit/useDebounce'

export const useDebounce = <S>(
  initialState: S | (() => S),
  delay: number = 300
): [S | null, S | null, Dispatch<SetStateAction<S | null>>] => {
  /** Значение без задержки */
  const [value, setValue] = useState<S | null>(initialState || null)

  /** Значение с задержкой*/
  const [valueDebounced, setValueDebounced] = useState<S | null>(null)

  const debounceSetValue = useDebounceConsta(setValueDebounced, delay)
  useEffect(() => {
    debounceSetValue(value)
  }, [value])

  return [valueDebounced, value, setValue]
}

import { useMutation } from 'react-query'
import api from 'src/api'

import { ApiReqResult } from 'src/interfaces'

/*
  Старт погрузки
  wiki:
  https://wiki.kak2c.ru/link/785#bkmrk-%D0%9F%D0%BE%D0%B3%D1%80%D1%83%D0%B7%D0%BA%D0%B0
*/

interface IParams {
  shipmentId: string
}

const useStartLoadingShipment = (
  params: IParams,
  callback?: () => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useMutation(
    async () => api.post(`/shipment/${params.shipmentId}/start-loading`),
    {
      onSuccess: ({ data: { success } }: { data: ApiReqResult }) => {
        if (success) {
          callback?.()
        } else {
          error?.()
        }
      },
      onError: error,
      onSettled: settled,
    },
  )
}

export default useStartLoadingShipment

import { IPrintOptions, usePrint } from './print'
import { useGetStickerByPallet, usePreviewBase64Pdf } from 'src/hooks'
import { OperationTypes } from '../../../interfaces'


/** Печать стикера транспортного места */

interface printResult {
  printTransportPlaceSticker: (barcode: string, operationType?: OperationTypes | string, isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}

export const usePrintTransportPlaceSticker = (): printResult => {
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const { printSticker } = usePrint()
  const { fetch: getTransportPlaceSticker } = useGetStickerByPallet()

  const printTransportPlaceSticker = async (barcode: string, operationType?: OperationTypes | string, isPreview?: boolean, options: IPrintOptions = {}) => {
    return await getTransportPlaceSticker({ pallet_id: barcode, operationType })
      .then((result) => {
        const { sticker } = result
        if (!result || !sticker) { return }

        if (isPreview) {
          previewBase64Pdf(sticker.content, 'Стикер транспортного места')
          return
        }

        printSticker(sticker.printer, sticker.content, {
          copies: 1,
          ...options
        })
      })
      .catch(() => {

      })
  }

  return {
    printTransportPlaceSticker,
  }
}
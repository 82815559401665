import React, { useState } from 'react'

import styles from './item-count-modal.module.scss'

import { Button } from '@consta/uikit/Button'

import { EnterHandler, InputWrapper, ItemPreview, Modal } from 'src/components/index'

import { IBatch, ModalProps } from 'src/interfaces'

import { TextFieldPropValue } from '@consta/uikit/TextField'


interface IItemInfo {
  image: string
  title: string
  batch?: IBatch
  barcode?: string
  acceptance_type?: string
}

interface ItemCountProps extends ModalProps {
  withClose?: boolean
  title?: string
  leftBtnLabel?: string
  rightBtnLabel?: string
  item?: IItemInfo
  count?: string
  setCount?: (count: string) => void
  onItemAdding: (count: number) => void
}

const ItemCountModal = (props: ItemCountProps) => {
  const {
    count,
    item,
    isOpen,
    title,
    withClose = true,
    leftBtnLabel = 'Закрыть',
    rightBtnLabel = 'Добавить',
    setCount,
    onItemAdding,
    onClose,
  } = props

  const [localCount, setLocalCount] = useState<TextFieldPropValue | null>(count || null)

  const defineText = (type: string) => {
    if (type === "PIECE") {
      return "Введите количество товара"
    }
    if (type === "BOX") {
      return "Введите количество коробов"
    }
    if (type === "PALLET") {
      return "Введите количество палет"
    }
  }


  const handleAddCount = () => {
    if (Number(localCount) < 1) {
      return
    }
    setCount?.(String(localCount))
    onItemAdding(Number(localCount))
  }

  return (
    <EnterHandler onEnter={handleAddCount}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        withClose={withClose}
        onClose={onClose}
        onOverlayClick={(): boolean => false}
        className={styles.itemCount}
        title={title}
        size='s'
      >
        {item ? (
          <ItemPreview
            image={item.image}
            title={item.title}
            batch={item.batch}
            barcode={item.barcode}
            imgClassName={styles.imgPreview}
          />
        ) : null}

        <InputWrapper
          label={item?.acceptance_type ? defineText(item?.acceptance_type) : 'Введите количество'}
          value={localCount}
          id={'count'}
          handleChange={(value: TextFieldPropValue) => setLocalCount(String(value))}
          autoFocus
          className={styles.itemInput}
          placeholder={''}
          size='l'
          isInteger
        />
        <div className={styles.btns}>
          <Button
            label={leftBtnLabel}
            view='ghost'
            className={styles.btn}
            onClick={onClose}
          />
          <Button
            label={rightBtnLabel}
            className={styles.btn}
            disabled={!localCount || Number(localCount) < 1}
            onClick={handleAddCount}
            // loading={loading}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ItemCountModal

import React from 'react'
import cx from 'classnames'
import styles from './warning-modal.module.scss'

import warningImg from './assets/warning.svg'
import errorImg from './assets/error.svg'

import { Button, ButtonPropSize, ButtonPropView } from '@consta/uikit/Button'

import { Modal } from 'src/components'

import { ModalProps } from 'src/interfaces'
import { ModalPropSize } from '../Modal/Modal'
import { useArrowSwitch } from '../../hooks'

export interface IWarningModal extends ModalProps {
  title: string
  subtitle: string
  modalType?: 'warning' | 'error'
  handleConfirm?: () => void
  className?: string
  leftBtnTxt?: string
  size?: ModalPropSize
  leftBtnView?: ButtonPropView
  leftBtnSize?: ButtonPropSize
  leftBtnOnClick?: () => void
  leftBtnLoading?: boolean
  rightBtnTxt?: string
  rightBtnView?: ButtonPropView
  rightBtnSize?: ButtonPropSize
  rightBtnOnClick?: () => void
  rightBtnLoading?: boolean
  body?: React.ReactNode
  customFooter?: React.ReactNode
  warningImg?: string

  onOverlayClick?: () => void

  withClose?: boolean
  withBtnsSwitchFocus?: boolean
}

const modalTypeData = {
  warning: { img: warningImg },
  error: { img: errorImg },
}

const WarningModal = (props: IWarningModal) => {
  const {
    isOpen,
    onClose,
    title,
    subtitle,
    size,
    handleConfirm = () => {},
    className = '',
    modalType = 'warning',
    warningImg = modalTypeData[modalType]?.img,

    body,
    customFooter,

    leftBtnTxt = 'Подтвердить',
    leftBtnView = 'primary',
    leftBtnSize = 'l',
    leftBtnLoading,
    leftBtnOnClick,

    rightBtnTxt = 'Отменить',
    rightBtnView = 'ghost',
    rightBtnSize = 'l',
    rightBtnLoading,
    rightBtnOnClick,

    onOverlayClick,
    withClose,
    withBtnsSwitchFocus = true
  } = props

  const {
    refsObj,
  } = useArrowSwitch({
    refsKeys: ['submit', 'close'],
    withEnterClickBtn: true,
    isActive: withBtnsSwitchFocus && isOpen
  })

  return (
    <Modal
      isOpen={isOpen}
      withClose={withClose}
      onClose={onClose}
      hasOverlay
      onOverlayClick={onOverlayClick}
      className={cx(styles.warning, className)}
      title={title}
      subtitle={subtitle}
      img={warningImg}
      imgClass={styles.img}
      size={size}
    >
      {body}

      {customFooter ? (
        customFooter
      ) : (
        <div className={styles.btns}>
          <Button
            label={leftBtnTxt}
            view={leftBtnView}
            className={styles.primary}
            onClick={() => {
              leftBtnOnClick?.()
              handleConfirm?.() // старый метод, который остался как рудимент
            }}
            size={leftBtnSize}
            loading={leftBtnLoading}
            ref={refsObj?.submit?.ref}
            autoFocus={!withBtnsSwitchFocus}
          />
          <Button
            label={rightBtnTxt}
            view={rightBtnView}
            className={styles.primary}
            onClick={() => {
              rightBtnOnClick?.()
              onClose?.() // старый метод, который остался как рудимент
            }}
            size={rightBtnSize}
            loading={rightBtnLoading}
            ref={refsObj?.close?.ref}
          />
        </div>
      )}
    </Modal>
  )
}

export default WarningModal

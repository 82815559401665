import { usePrint } from './print'
import { usePreviewBase64Pdf } from '../usePreviewBase64Pdf'
import useGetSerialNumbersSticker from '../../items/useGetSerialNumbersSticker'
import { DocumentTypeEnum } from '../../../interfaces'
import { useState } from 'react'


interface Result {
  loading: boolean
  printSerialNumber: (
    sku_id: string,
    serial_number_type: "chestniy_znak" | "imei" | "unique_number",
    document_id: string,
    document_type: DocumentTypeEnum,
    disable_billing?: boolean,
    reprint?: boolean,
    withPreview?: boolean,
    copies?: number
  ) => Promise<void>
}

export const usePrintSerialNumber = (): Result => {
  const { printSticker } = usePrint({})
  const getSerialNumbersSticker = useGetSerialNumbersSticker()
  const { previewBase64Pdf } = usePreviewBase64Pdf()

  const [loading, setLoading] = useState(false)

  const printSerialNumber = async (
    sku_id,
    serial_number_type,
    document_id,
    document_type,
    disable_billing,
    reprint,
    withPreview,
    copies
  ) => {
    setLoading(true)
    // throw 'test error'
    await getSerialNumbersSticker
      .fetch({
        sku_id,
        serial_number_type,
        document_id,
        document_type,
        disable_billing,
        reprint,
      })
      .then(async (data) => {
        if (withPreview) {
          previewBase64Pdf(data.sticker.content)
        } else {
          await printSticker(data.sticker.printer, data.sticker.content, {copies, stickerExists: data.stickerExists})
        }
      })
      .catch((e) => {
        throw e
      })
      .finally(() => setLoading(false))
  }
  return {
    loading,
    printSerialNumber,
  }
}

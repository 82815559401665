import { IAddress, ICountryItem } from '../interfaces'

export const getFullAddress = (
  addr: IAddress,
  countryList: ICountryItem[],
): string => {
  let result = ''
  if (addr.zip) result += `${addr.zip}, `
  if (addr.country) {
    const country = countryList.find((c) => c.value === addr.country)?.title
    result += country ? `${country}, ` : ''
  }
  if (addr.region) result += `${addr.region}, `
  if (addr.area) result += `${addr.area}, `
  if (addr.city) result += `${addr.city}, `
  if (addr.settlement) result += `${addr.settlement}, `
  if (addr.street) result += `${addr.street}, `
  if (addr.house) result += `д.${addr.house} `
  if (addr.block) result += `к.${addr.block} `
  if (addr.building) result += `стр.${addr.building} `
  if (addr.flat) result += `кв.${addr.flat} `
  if (addr.floor) result += `эт.${addr.floor} `
  return result || addr.full_address || ''
}

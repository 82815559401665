import React, { useEffect, useState } from 'react'

import styles from './additional-packaging-modal.module.scss'

import { Button } from '@consta/uikit/Button'
import { IconCheck } from '@consta/icons/IconCheck'
import { IconCancel } from '@consta/icons/IconCancel'

import { ChoiceManualPackageModal, ItemPreview, Modal } from 'src/components'

import { ICachedPackage, IOrderPackagingOption, IPackageDto, ModalProps } from 'src/interfaces'
import { base64ToImgStr, cloneDeep } from 'src/helpers'
import { useItemGetSticker } from '../../../hooks'
import AdditionalPackConfirm from './AdditionalPackConfirm'

interface PackagingItem {
  id: string
  image: string
  title: string
  barcode?: string
}

interface AdditionalPackagingModalProps extends ModalProps {
  item?: PackagingItem | null
  onAdditionalPackagingAdding: (packageId: string) => void
  packages: IPackageDto[]
  setCachedPackages?: (cachedPackages: ICachedPackage) => void
  cachedPackages?: Record<string,string>
  cacheEmptyPackage?: boolean // указывает надо ли кешировать пустую упаковку
  hardUpdateAdditionalPackaging?: boolean // каждый раз позволять выбрать упаковку
  withOverlay?: boolean
  withConfirm?: boolean
  packingRequired?: boolean
}

const AdditionalPackagingModal = (props: AdditionalPackagingModalProps) => {
  const {
    isOpen,
    item,
    onClose,
    cacheEmptyPackage = true,
    onAdditionalPackagingAdding,
    hardUpdateAdditionalPackaging,
    withOverlay = false,
    packages,
    setCachedPackages,
    cachedPackages,
    withConfirm,
    packingRequired
  } = props

  const [selected, setSelected] = useState<IOrderPackagingOption | null>(null)
  const [showChoiceManualPackage, setShowChoiceManualPackage] = useState<boolean>(false)
  const [showConfirmPackage, setShowConfirmPackage] = useState<boolean>(false)
  const [packingImgLoading, setPackingImgLoading] = useState<boolean>(false)

  const getSkuItemSticker = useItemGetSticker()

  useEffect(() => {
    /** Если упаковка обязательна, то сразу переходим к ее выбору */
    if (!packingRequired) {
      return
    }
    handleChoicePackage()
  }, [packingRequired])

  const updateCachedPackage = (packageId: string | null) => {
    if (item?.id && cachedPackages) {
      let correctCachedPackages = cloneDeep(cachedPackages)
      if (packageId) {
        correctCachedPackages = {
          ...correctCachedPackages,
          [item.id]: packageId,
        }
      } else {
        delete correctCachedPackages[item.id]
      }
      setCachedPackages?.(correctCachedPackages)
    }
  }

  const handleChoicePackage = () => {
    // если у текущего товара уже была выбрана доп. упаковка, выбор упаковки пропускается
    // если нет жесткого указания на ввод
    if (item?.id && cachedPackages?.[item.id] && !hardUpdateAdditionalPackaging) {
      const cachedPackage = cachedPackages[item.id]
      onAdditionalPackagingAdding(cachedPackage)
      return
    }
    setShowChoiceManualPackage(true)
  }

  const handleAdditionalPackagingAdding = (packageId: string) => {
    updateCachedPackage(packageId)
    onAdditionalPackagingAdding(packageId)
  }

  const handleCloseAddPackage = () => {
    const emptyPackage = packages.find(p => p.barcodes.includes('no_packaging'))
    const emptyPackageId = emptyPackage?.id || 'no_packaging'

    updateCachedPackage(cacheEmptyPackage ? emptyPackageId : null)
    /** параметр нужен для обновления упаковки */
    onAdditionalPackagingAdding(emptyPackageId)
  }

  return (
    <>
      {showChoiceManualPackage ? (
        <>
          <ChoiceManualPackageModal
            withoutSelectList={withConfirm}
            isOpen={isOpen}
            packagingOptions={packages}
            selected={selected}
            onSelect={(option: IOrderPackagingOption | null, usedPackagingBarcode?: string) => {
              if (option && withConfirm) {
                setPackingImgLoading(true)
                getSkuItemSticker
                  .fetch({
                    sku_id: option.id,
                    barcode: usedPackagingBarcode,
                    format: 'PNG',
                    advanced: true,
                    disable_billing: true
                  })
                  .then(async ({ sticker}) => {
                    if (sticker?.content) {
                      setSelected({ ...option, img: base64ToImgStr(sticker.content) })
                      return
                    }
                    setSelected(option)
                  })
                  .catch((err) => {
                    console.log('Произошла ошибка стикера упаковки', err?.message || '')
                  })
                  .finally(() => {
                    setPackingImgLoading(false)
                  })
                return
              }
              setSelected(option)
            }}
            onBack={() => {
              setShowChoiceManualPackage(false)
            }}
            onClose={() => {}}
            onSubmit={handleAdditionalPackagingAdding}
            showConfirm={setShowConfirmPackage}
          />
          {
            showConfirmPackage ? (
              <AdditionalPackConfirm
                imgLoading={packingImgLoading}
                selected={selected}
                onClose={() => {
                  setShowConfirmPackage(false)
                }}
                onSubmit={handleAdditionalPackagingAdding}
              />
            ) : null
          }
        </>
      ) : (
        <Modal
          isOpen={isOpen}
          onOverlayClick={(): boolean => false}
          className={styles.additionalPack}
          size="s"
          hasOverlay={withOverlay}
          withClose
          onClose={onClose}
        >
          {item ? (
            <ItemPreview
              image={item.image}
              title={item.title}
              barcode={item.barcode}
            />
          ) : null}

          <h3 className={styles.title}>
            Требуется ли дополнительная упаковка?
          </h3>

          <div className={styles.btns}>
            <Button
              label="Упаковка не нужна"
              view="ghost"
              className={styles.btn}
              onClick={handleCloseAddPackage}
              iconLeft={IconCancel}
              size="l"
            />
            <Button
              label="Упаковка нужна"
              className={styles.btn}
              iconLeft={IconCheck}
              onClick={handleChoicePackage}
              size="l"
            />
          </div>
        </Modal>
      )}
    </>
  )
}

export default AdditionalPackagingModal

import React, { FC, MouseEventHandler } from 'react'
import styles from './LinkCell.module.scss'
import { Link } from 'react-router-dom'

interface LinkCellProps {
  to: string
  title: string
  target?: string
  onClick?: MouseEventHandler | undefined;
}
export const LinkCell: FC<LinkCellProps> = (props) => {
  const { to, title, target, onClick } = props
  return (
    <Link className={styles.link} to={to} onClick={onClick} target={target}>
      { title }
    </Link>
  )
}

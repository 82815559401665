import { PickingStatusEnum } from '../picking'

import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'


export const PICKING_STATUSES_DESCRIPTION: Record<PickingStatusEnum | string, string> = {
  [PickingStatusEnum.readyForProcessing]: 'Готов к работе',
  [PickingStatusEnum.requireMoving]: 'Необходимо перемещение',
  [PickingStatusEnum.error]: 'Ошибка',
  [PickingStatusEnum.processing]: 'В работе',
  [PickingStatusEnum.done]: 'Выполнен',
}

export const PICKING_STATUS_BADGE_TYPE: Record<PickingStatusEnum | string, BadgePropStatus> = {
  [PickingStatusEnum.readyForProcessing]: 'system',
  [PickingStatusEnum.requireMoving]: 'error',
  [PickingStatusEnum.error]: 'error',
  [PickingStatusEnum.processing]: 'normal',
  [PickingStatusEnum.done]: 'success',
}

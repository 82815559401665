import { useQuery, UseQueryOptions } from 'react-query'

import api from 'src/api'

import { ApiReqResult, IMerchant, IPaginationFilter, PaginationResult } from 'src/interfaces'


interface Result extends ApiReqResult, PaginationResult {
  merchant: IMerchant
}
interface Params extends IPaginationFilter {
  merchant_id: string
}

const useGetMerchant = (
  params: Params,
  options?: UseQueryOptions<Params, unknown, Result>
) => {
  return  useQuery<Params, unknown, Result>(
    [`getMerchant`, params],
    async () => {
      const result = await api.get(`/merchant/${params.merchant_id}`)
      return result.data
    },
    {
      ...options,
    },
  )
}

export default useGetMerchant

import { IPrintOptions, usePrint } from './print'
import { useFetchWaybillForPrint, usePreviewBase64Pdf } from 'src/hooks'


/** Печать транспортной накладной */

interface printResult {
  printWaybillSticker: (shipmentId: string, isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}

export const usePrintWaybillSticker = (): printResult => {
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const { printSticker } = usePrint()
  const { fetch: getWaybillSticker } = useFetchWaybillForPrint()

  const printWaybillSticker = async (shipmentId: string, isPreview?: boolean, options: IPrintOptions = {}) => {
    return await getWaybillSticker({ shipmentId })
      .then((result) => {
        if (!result) { return }
        const { content, printer } = result

        if (isPreview) {
          previewBase64Pdf(content, 'Транспортная накладная')
          return
        }

        printSticker(printer, content, {
          copies: 1,
          ...options
        })
      })
      .catch(() => {

      })
  }

  return {
    printWaybillSticker,
  }
}
import React from 'react'
import cx from 'classnames'
import styles from './TrashCell.module.scss'
import { ButtonPropSize, ButtonPropView } from '@consta/uikit/__internal__/src/components/Button/Button'
import { IconTrash } from '@consta/icons/IconTrash'
import { Button } from 'src/components'

interface CollapseColumnProps {
  className?: string
  loading?: boolean
  onClick?: () => void
  view?: ButtonPropView
  size?: ButtonPropSize
  theme?: 'default' | 'white' | 'success' | 'warning' | 'danger'
  readonly?: boolean
}

export const TrashCell = (props: CollapseColumnProps) => {
  const {
    view = 'clear',
    size = 's',
    className,
    onClick,
    loading,
    theme,
    readonly = false,
  } = props

  return (
    <Button
      theme={theme}
      onlyIcon
      className={cx(styles.button, className)}
      iconLeft={IconTrash}
      view={view}
      size={size}
      iconSize={'s'}
      onClick={onClick}
      loading={loading}
      disabled={readonly}
    />
  )
}

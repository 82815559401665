import React, { createRef, useCallback, useEffect, useMemo, useState } from 'react'

/**
 * Хук который используется для обработки "умных" фокусов полей
 * Идея в том, чтобы можно было переключаться между полями по индексу
 * Важно!
 * Индекс полей учитывается по передаваемогу списку ключей
 */

type Props = {
  initialIndex?: number
  withLoopIndex?: boolean
  refsKeys: readonly string[] // ключи по которому можно понять какие где рефы
}
type RefObjectType = { [key: string]: { key: string, ref: React.RefObject<HTMLInputElement> } }
export const useRefsSwitcher = (props: Props) => {
  const {
    withLoopIndex,
    initialIndex = 0,
    refsKeys
  } = props

  const [currentIndex, setCurrentIndex] = useState(initialIndex)

  const refsObj = useMemo(() => refsKeys.reduce((acc, item) => {
    return { ...acc, [item]: { key: item, ref: createRef<HTMLInputElement>() } }
  }, {} as RefObjectType), [refsKeys])
  const refs = useMemo(() => refsKeys.map(key => refsObj[key]), [refsObj, refsKeys])

  useEffect(() => {
    refs[currentIndex]?.ref?.current?.focus()
  }, [currentIndex, refs])


  const next = useCallback(() => {
    setCurrentIndex(prev => {
      const newVal = prev + 1
      const maxVal = refs.length-1
      if (withLoopIndex) return newVal > maxVal ? 0 : newVal
      return newVal > maxVal ? refs.length : newVal
    })
  }, [refs.length, withLoopIndex])

  const prev = useCallback(() => {
    setCurrentIndex(prev => {
      const newVal = prev - 1
      const minVal = 0
      if (withLoopIndex) return newVal < minVal ? refs.length-1 : newVal
      return newVal <= minVal ? minVal : newVal
    })
  }, [refs.length, withLoopIndex])

  const setFocusIndex = useCallback((key: typeof refsKeys[number]) => {
    const foundIndex = refs.findIndex(refItem => refItem.key === key)
    if (foundIndex < 0) return
    setCurrentIndex(foundIndex)
  }, [refs])

  return {
    currentIndex,
    currentRef: refs[currentIndex]?.ref,
    currentRefKey: refsKeys[currentIndex],
    refsObj,
    next,
    prev,
    setFocusIndex,
    isDone: refsKeys.length-1 < currentIndex
  }
}

export enum RelabelingStatusEnum {
  readyForProcessing = 'READY_FOR_PROCESSING',
  processing = 'PROCESSING',
  done = 'DONE'
}

export interface RelabelItem {
  new_sku_batch_id: string;
  new_sku_id: string;
  quantity: number;
  sku_batch_id: string;
  sku_id: string
  tare_barcode: string;
}

export interface UnprocessedRelabelItem {
  new_sku_batch_id?: string;
  new_sku_id: string;
  quantity: number;
  sku_batch_id?: string;
  sku_id: string
}

export interface IRelabel {
  id: string;
  items: RelabelItem[]
  status: "READY_FOR_PROCESSING" | "PROCESSING" | "DONE" | "ERROR"
  parent_id?: string
}

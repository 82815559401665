import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock } from 'src/mocks'

import { ApiReqResult, ISupplyReceiptData } from 'src/interfaces'
import { v4 } from 'uuid'

interface IParams {
  supplyId: string
}

interface openEditSupplyPartData extends ISupplyReceiptData {
  receipt_Id?: string
}

interface result extends ApiReqResult {
  id: string
}

const useOpenEditSupplyPart = (params: IParams) => {
  return useMutation(
    async ({ receipt_Id, ...restData }: openEditSupplyPartData) => {
      let path = `/receiving/supply/${params.supplyId}/part`
      if (receipt_Id) {
        path += `/${receipt_Id}`
      }
      try {
        const result = await provideMock<IParams, { data: result }>(
          async () => await api.post(path, restData),
          params,
          {
            key: 'supplyId',
            value: 'mock',
            result: {
              data: { id: v4(), success: true },
            },
          },
        )()

        if (!result?.data?.success) {
          throw result
        }
        return result?.data
      } catch (e) {
        throw e
      }
    },
  )
}

export default useOpenEditSupplyPart

import React from 'react'
import cx from 'classnames'
import styles from './CollapseCell.module.scss'
import { Button } from '@consta/uikit/Button'
import { IconRemove } from '@consta/icons/IconRemove'
import { IconAdd } from '@consta/icons/IconAdd'
import { ButtonPropSize, ButtonPropView } from '@consta/uikit/__internal__/src/components/Button/Button'

interface CollapseColumnProps {
  isExpanded?: boolean,
  className?: string,
  onClick?: () => void
  view?: ButtonPropView
  size?: ButtonPropSize
}

export const CollapseCell = (props: CollapseColumnProps) => {
  const {
    view = 'clear',
    size = 's',
    isExpanded,
    className,
    onClick
  } = props

  return (
    <Button
      onlyIcon
      className={cx(styles.openCloseButton, className)}
      iconLeft={isExpanded ? IconRemove : IconAdd}
      view={view}
      size={size}
      iconSize={'s'}
      onClick={onClick}
    />
  )
}

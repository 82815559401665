import { useMutation } from 'react-query'

import api from 'src/api'

import { provideMock } from 'src/mocks'

import { ApiReqResult, CommonSerialNumberFilled } from 'src/interfaces'
import { v4 } from 'uuid'

interface iParams {
  supplyId: string
}

interface IRequestItemData {
  quantity: number
  sku_id: string
  serial_numbers: CommonSerialNumberFilled[]
  defect_type_id?: string
  comment?: string
  sku_batch_id?: string | null
}
interface IResult extends ApiReqResult {
  results: { n: number; id: string; success: boolean }[]
}

const useAddSupplyPartItemToTare = (params: iParams) => {
  return useMutation(
    async (args: { items: IRequestItemData[], receiptId: string }) => {
      const { receiptId, items } = args
      try {
        const result = await provideMock<iParams, { data: IResult }>(
          async () =>
            await api.post(
              `/receiving/supply/${params.supplyId}/part/${receiptId}/item`,
              items,
            ),
          params,
          {
            key: 'supplyId',
            value: 'mock',
            result: {
              data: {
                success: true,
                results: [{ success: true, id: v4(), n: 1 }],
              },
            },
          },
        )()

        if (!result?.data?.success) {
          throw result
        }
        return result.data
      } catch (e) {
        throw e
      }
    },
  )
}

export default useAddSupplyPartItemToTare

import api from '../../api'
import { orderItemsEnum } from '../../pages/Reports/components/DefineParamsModal/DefineParamsModal'
import { useQuery } from 'react-query'
import { convertObjectToURLParams } from '../../helpers'
import { ApiReqResult, IPaginationFilter, PaginationResult } from '../../interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'

interface IPropsItems {
  type: string,
  subtype: string
  data: string[]
}

const getItemsByFilters = async (props: IPropsItems) => {
  const { type, subtype, data } = props
  const definePath = (type: string | undefined) => {
    if (type === orderItemsEnum.nomenclature) {
      return `/items/list`
    } else if (type === orderItemsEnum.cell) {
      return `/warehouse/cell/list`
    }
  }

  // @ts-ignore type не может быть undefined
  const result = await api.post(definePath(type), {
    filter: {
      [subtype]: data
    }
  })

  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data || []
}

export const useFetchItemsByFilters = () => ({
  fetch: (items: IPropsItems) => getItemsByFilters(items)
})

import { v4 } from 'uuid'
import { CHZ_CODE } from '../../helpers'

export const MOCK_GET_SUPPLY_BY_ID: any = {
  'errors': [],
  'skus': [
    {
      'article': 'demo1024',
      'barcodes': [
        {
          'active': true,
          'barcode': '1',
          'type': 'COMMON',
          'used_in_last_supply': true,
        },
      ],
      'batch_accounting': true,
      'batch_expdate_mandatory': true,
      'batches': Array(5).fill(1).map((el, i) => (  {
          id: `some-batch-id${i+10}`,
          num: `some-batch-id${i+10}`,
          exp_date: `2022-10-0${i+10}`,
        })),
      'dimensions_fact': {
        'depth': 10,
        'height': 10,
        'width': 10,
      },
      'dimensions_plan': {
        'depth': 300,
        'height': 600,
        'width': 1000,
      },
      'external_id': '1024',
      'fragile': false,
      'id': 'TM-1024',
      'image': 'https://storage.yandexcloud.net/images-k2c/57bf535f-89da-4806-90c4-43158fa44bea.jpg',
      'image_preview': 'https://storage.yandexcloud.net/images-k2c/57bf535f-89da-4806-90c4-43158fa44bea-100.jpg',
      'images': [
        {
          'position': 0,
          'preview_url': 'https://storage.yandexcloud.net/images-k2c/57bf535f-89da-4806-90c4-43158fa44bea-100.jpg',
          'url': 'https://storage.yandexcloud.net/images-k2c/57bf535f-89da-4806-90c4-43158fa44bea.jpg',
        },
      ],
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'parts': [],
      'require_dimensions': false,
      'require_weight': false,
      'scan_serial_numbers': [
        {
          required: true,
          code: CHZ_CODE,
          title: 'Честный знак',
          template: '*',
        },
        {
          code: 'imei2',
          title: 'IMEI код2',
          template: '*',
        },

      ],
      'title': 'Компьютер (демо товар)',
      'type': 'SKU',
      'weight_fact': 1000,
      'weight_plan': 10000,
    },
    {
      'article': 'demo1025',
      'barcodes': [
        {
          'active': true,
          'barcode': '2',
          'type': 'COMMON',
          'used_in_last_supply': true,
        },
      ],
      'batch_accounting': true,
      'batch_expdate_mandatory': true,
      "expiration_months": 24,
      'batches': Array(5).fill(1).map((el, i) => (  {
          id: `some-batch-id${i}`,
          num: `some-batch-id${i}`,
          exp_date: `2022-10-0${i+1}`,
        })),
      'dimensions_fact': {
        'depth': 123,
        'height': 210,
        'width': 120,
      },
      'dimensions_plan': {
        'depth': 300,
        'height': 200,
        'width': 200,
      },
      'external_id': '1025',
      'fragile': false,
      'id': 'TM-1025',
      'image': 'https://storage.yandexcloud.net/images-k2c/9a4b3378-0cc7-4880-aea5-4714bc25cfcc.jpg',
      'image_preview': 'https://storage.yandexcloud.net/images-k2c/9a4b3378-0cc7-4880-aea5-4714bc25cfcc-100.jpg',
      'images': [
        {
          'position': 0,
          'preview_url': 'https://storage.yandexcloud.net/images-k2c/9a4b3378-0cc7-4880-aea5-4714bc25cfcc-100.jpg',
          'url': 'https://storage.yandexcloud.net/images-k2c/9a4b3378-0cc7-4880-aea5-4714bc25cfcc.jpg',
        },
      ],
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'parts': [],
      'require_dimensions': false,
      'require_weight': false,
      'scan_serial_numbers': [
        {
          required: true,
          code: CHZ_CODE,
          title: 'Честный знак',
          template: '*',
        },
      ],
      'title': 'Фотоаппарат (демо товар)',
      'type': 'SKU',
      'weight_fact': 12000,
      'weight_plan': 1000,
    },
    {
      'article': 'demo1026',
      'barcodes': [
        {
          'active': true,
          'barcode': '63547534',
          'type': 'OZON',
          'used_in_last_supply': false,
        },
        {
          'active': true,
          'barcode': '1234563524124253',
          'type': 'COMMON',
          'used_in_last_supply': false,
        },
      ],
      'batch_accounting': true,
      'batch_expdate_mandatory': true,
      "expiration_months": 12,
      'batches': Array(5).fill(1).map((el, i) => (  {
          id: `some-batch-id${i}`,
          num: `some-batch-id${i}`,
          exp_date: `2022-10-0${i+1}`,
        })),
      'dimensions_fact': {
        'depth': 210,
        'height': 120,
        'width': 210,
      },
      'dimensions_plan': {
        'depth': 100,
        'height': 100,
        'width': 100,
      },
      'external_id': '1026',
      'fragile': false,
      'id': 'TM-1026',
      'image': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d.jpg',
      'image_preview': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d-100.jpg',
      'images': [
        {
          'position': 0,
          'preview_url': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d-100.jpg',
          'url': 'https://storage.yandexcloud.net/images-k2c/8aa184e8-9bc8-428e-8edb-365f9c35397d.jpg',
        },
      ],
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'parts': [],
      'require_dimensions': false,
      'require_weight': false,
      'scan_serial_numbers': [],
      'title': 'Наушники (демо товар)',
      'type': 'SKU',
      'weight_fact': 2000,
      'weight_plan': 1000,
    },
  ],
  'success': true,
  'supply': {
    'activated': true,
    'create_ts': '2023-12-14T11:02:28.130722',
    'date': '2023-12-14T11:02:27.883000',
    'supply_form': [
      {
        'title': 'test title',
        'value': 'test value',
        'comment': 'test comment formItem.comment formItem.comment formItem.commentformItem.comment formItem.comment formItem.commenttest comment',
      },
      {
        'title': 'test title 2',
        'value': 'test value 2',
        'comment': 'test comment 2 formItem.comment formItem.comment formItem.commentformItem.comment formItem.comment formItem.commenttest comment 2',
      }
    ],
    'executor': {
      'enabled': true,
      'id': 'd5e1534b-6af3-44bb-82a6-b71ac5d02f90',
      'login': '12354321',
      'name': 'Юлия',
      'patronymic': '',
      'position': 'DEVELOPER',
      'roles': [
        'e8191d72-0574-470e-a5bf-43fe5818ac19',
        '6d2726ed-914d-413c-8224-cbca5ce244b5',
        'e0e29932-c19d-431e-92ac-702f939e0cda',
        '2494d4ff-f0f8-4b1e-b2ec-2b330a449fae',
        'edfa715d-f607-47cb-9ad4-ca4c93409dd9',
        '1a30e375-dbf6-4de2-8976-a07a5a5847ef',
        '15da10de-5e40-4c46-9373-8cfe35aa7659',
        'ac018110-47bd-4d3d-b070-0aa4b0915a09',
        '70b16569-38eb-4014-a1fe-6ac0054c431a',
      ],
      'surname': 'П',
    },
    'external_id': '1037',
    'files': [],
    'has_diffs': false,
    'id': 'S-TM-1037',
    'items': [
      // {
      //   'id': 'dc560023-6059-478b-bb3e-1491ed8f80fa',
      //   'price_data': {
      //     'currency': 'RUB',
      //     'price': 2,
      //     'vat': 'NO_VAT',
      //   },
      //   'quantity': 6,
      //   'scan_serial_numbers': [CHZ_CODE],
      //   'sku_id': 'TM-1025',
      // },
      {
        'id': '40b710b4-c4e4-42d2-996f-09cd7b66420a',
        'price_data': {
          'currency': 'RUB',
          'price': 3,
          'vat': 'NO_VAT',
        },
        'quantity': 2,
        'scan_serial_numbers': [],
        'sku_id': 'TM-1026',
      },
      // {
      //   'id': '40b710b4-c4e4-42d2-996f-09cd7b66122',
      //   'price_data': {
      //     'currency': 'RUB',
      //     'price': 3,
      //     'vat': 'NO_VAT',
      //   },
      //   'quantity': 7,
      //   'scan_serial_numbers': ['imei','imei2'],
      //   'sku_id': 'TM-1026',
      //   'sku_batch_id': 'some-batch-id3',
      // },
      //
      // {
      //   'id': v4(),
      //   'price_data': {
      //     'currency': 'RUB',
      //     'price': 1,
      //     'vat': 'NO_VAT',
      //   },
      //   'quantity': 14,
      //   'sku_id': 'TM-1024',
      //   // 'sku_batch_id': 'some-batch-id12',
      // },
    ],
    'merchant': {
      'cam_id': '101',
      'contract_date': '2022-09-25',
      'contract_num': '1001',
      'enabled': true,
      'id': '2b78266b-0405-48f6-a263-3dba1869209f',
      'login': 'test@new-wms.ru',
      'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
      'title': 'Test new WMS merchant',
      'trade_title': 'Test new WMS merchant',
    },
    'receipt_started_at': '2023-12-14T12:16:53.000000',
    "supply_type": "FROM_SUPPLIER",
    'receipts': [
      {
        'activated': true,
        // 'condition': 'DEFECT',
        'condition': 'GOOD',
        'create_ts': '2023-12-15T11:36:16.891960',
        'date': '2023-12-15T11:36:16.881045',
        'executor': {
          'enabled': true,
          'id': '69e2c608-88c9-4a32-87a9-1401736228b0',
          'login': '123',
          'name': '123',
          'patronymic': '123',
          'position': 'GENERAL_DIRECTOR',
          'roles': [
            'e545d9ad-c079-4c11-9005-a775438549a2',
          ],
          'surname': '123',
        },
        'id': 'RT-TM-0000-0142',
        'items': [
          {
            'acceptance_type': 'PIECE',
            'barcode_used': '63547534',
            'id': 'de280bd7-3690-4d29-bbbb-a0fe5e4056e1',
            'multiplicity': 1,
            'quantity': 2,
            'serial_numbers': [],
            'sku_id': 'TM-1026',
          }
        ],
        'merchant': {
          'cam_id': '101',
          'contract_date': '2022-09-25',
          'contract_num': '1001',
          'enabled': true,
          'id': '2b78266b-0405-48f6-a263-3dba1869209f',
          'login': 'test@new-wms.ru',
          'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
          'title': 'Test new WMS merchant',
          'trade_title': 'Test new WMS merchant',
        },
        'status': 'CLOSED',
        'tare_barcode': 'TARA0000393',
        'update_ts': '2023-12-22T22:47:18.048818',
        'uuid': '596ffadd-3cc4-4dbb-8358-332c4d96e934',
        'workspace': {
          'barcode': 'XX-1',
          'condition': 'GOOD',
          'id': 'a94203b3-67d7-42b7-9642-ef510c3e9323',
          'title': 'XX-1',
          'zone_id': '26826c40-ac61-4e9f-a5ea-1101ef5521f2',
        },
      },
    ],
    'status': 'PROCESSING',
    'supplier': {
      'id': 'e8771d15-0809-478c-8c31-99a687c5ebd7',
      'inn': '5404084407',
      'kpp': '540401001',
      'ogrn': '1195476004789',
      'title': 'ООО "ПОСТАВЩИК 1"',
    },
    'update_ts': '2023-12-14T12:16:53.989066',
    'uuid': '6e51c3ae-4492-4c15-bfa0-3f3424518a6f',
    'workspace': {
      'barcode': 'DM-22',
      'condition': 'GOOD',
      'id': '7a9cb4f2-19fc-4c55-a757-d216f0b8a861',
      'title': 'DM-22',
      'zone_id': '26826c40-ac61-4e9f-a5ea-1101ef5521f2',
    },
  },
}

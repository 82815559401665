import { blurOrFocusInput } from '../../../helpers'
import React from 'react'

type Props = {
  inputBarcodeRef: React.RefObject<HTMLInputElement>
}

export const useInputFocus = (props: Props) => {
  const {
    inputBarcodeRef
  } = props
  const blurOrFocus = (type: 'focus' | 'blur') => blurOrFocusInput(inputBarcodeRef, type)

  const focus = () => blurOrFocus('focus')
  const blur = () => blurOrFocus('blur')

  return {
    focus,
    blur,
    blurOrFocus
  }
}
import { useMutation } from 'react-query'

import api from 'src/api'

import { MOCK_DELETE_SUPPLY_PART, provideMock } from 'src/mocks'

import { ApiReqResult, CommonSerialNumberFilled } from 'src/interfaces'

interface iParams {
  supplyId: string
  method?: 'delete' | 'post'
}
type useMutationDataParam = {
  receiptId: string
  quantity?: number
  /**
   * uuid Идентификатор позиции для удаления по нему
   */
  itemId?: string

  /**
   * Для удаления по выбранным критериям
   * (поиск позиции по id товара (sku), серийномы номерам, поставкам)
   * */
  skuId?: string
  serial_numbers?: CommonSerialNumberFilled[]
  sku_batch_id?: string
}
type dataParam = { supplyId: string } & useMutationDataParam
const getDeleteSupplyPartItemMethod = async (
  data: dataParam,
  method: 'delete' | 'post',
) => {
  if (method === 'delete') {
    return await api.delete(
      `/receiving/supply/${data.supplyId}/part/${data.receiptId}/item/${data.itemId}`,
    )
  } else {
    return await api.post(
      `/receiving/supply/${data.supplyId}/part/${data.receiptId}/item/delete`,
      {
        quantity: data.quantity,
        sku_id: data.skuId,
        item_id: data.itemId,
        sku_batch_id: data.sku_batch_id,
        serial_numbers: data.serial_numbers,
      },
    )
  }
}
const useDeleteSupplyPartItem = (params: iParams) => {
  return useMutation(async (data: useMutationDataParam) => {
    try {
      const result = await provideMock<iParams, { data: ApiReqResult }>(
        async () =>
          await getDeleteSupplyPartItemMethod(
            {
              quantity: data.quantity,
              receiptId: data.receiptId,
              supplyId: params.supplyId,
              itemId: data.itemId,
              skuId: data.skuId,
              serial_numbers: data.serial_numbers,
              sku_batch_id: data.sku_batch_id,
            },
            params.method || 'delete',
          ),
        params,
        {
          key: 'supplyId',
          value: 'mock',
          result: MOCK_DELETE_SUPPLY_PART,
        },
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useDeleteSupplyPartItem

import { useQuery } from 'react-query'
import api from 'src/api'

import { ApiReqResult, StickerWithCopies } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { convertObjectToURLParams } from 'src/helpers'

interface IParams {
  orderId: string,
  data: {
    barcode: string
    reprint?: boolean
  }
}
const getRelabelingStickers = async (params: IParams): Promise<IResult> => {
  const paramsStr = convertObjectToURLParams(params.data)
  const result = await api.get(`/packing-v2/order/${params.orderId}/sticker${paramsStr}`)
  if (!result?.data?.success) {
    throw result
  }
  return result.data
}

interface IResult extends ApiReqResult {
  files_for_print: StickerWithCopies[]
  barcode_required: boolean
}


const useFetchRelabelingStickersV2 = () => ({
  fetch: getRelabelingStickers
})

const useGetRelabelingStickersV2 = (
  params: IParams,
  options?: UseQueryOptions<IResult>,
) => {
  return useQuery<IResult>(
    [`getPackagings`, params],
    () => getRelabelingStickers(params),
      options
  )
}

export { useFetchRelabelingStickersV2, useGetRelabelingStickersV2 }

import api from 'src/api'
import { useQuery } from 'react-query'
import { Service, ServiceDocument } from 'src/interfaces'

export interface IGetBillingResponse {
  document: ServiceDocument
  services: Service[]
}
interface IParams {
  docId: string
}
const getBilling = async (params: IParams) => {
  const result = await api.get(`/billing/result/${params.docId}`)

  if (!result.data?.success) {
    throw result.data
  }

  return {
    document: result.data?.document,
    services: result.data?.services || [],
  } as IGetBillingResponse
}
export const useGetQueryBilling = (params: IParams) => {
  return useQuery<IGetBillingResponse>([`getQueryBilling`, params], () =>
    getBilling(params),
  )
}

const useGetBilling = () => ({
  fetch: (params: IParams) => getBilling(params),
})

export default useGetBilling

import React, { useState } from 'react'

import styles from './place-dimensions-modal.module.scss'

import { Button } from '@consta/uikit/Button'

import { DimensionsInput, EnterHandler, Modal } from 'src/components/index'

import { IDimensions, ModalProps } from 'src/interfaces'
import { convertDimensionsStr } from '../../helpers'
import { useRefsSwitcher } from '../../hooks'

interface ChoiceOversizedPackageModalProps extends ModalProps {
  onBack?: () => void
  onSubmit: (dimensions: IDimensions) => void
  title?: string
  subtitle?: string
  customFooter?: React.ReactNode
}

const PlaceDimensionsModal = (props: ChoiceOversizedPackageModalProps) => {
  const {
    isOpen,
    onBack,
    onSubmit,
    title = 'Габариты места',
    subtitle = 'Введите габариты места упаковки',
    customFooter,
  } = props

  const {
    refsObj,
    next,
    setFocusIndex
  } = useRefsSwitcher({ refsKeys: ['width', 'height', 'depth'] })

  const [dimensions, setDimensions] = useState<IDimensions>({
    width: null,
    height: null,
    depth: null,
  })

  const isInValid = Object.entries(dimensions).some(
    ([key, value]) => !Number(value),
  )

  const handleSubmit = () => {
    if (isInValid) {
      next()
      return
    }
    onSubmit(convertDimensionsStr('sm', 'mm', dimensions))
  }

  return (
    <EnterHandler onEnter={handleSubmit}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.wrapper}
        title={title}
        subtitle={subtitle}
      >
        <DimensionsInput
          value={dimensions}
          onChange={setDimensions}
          floatNumbersInputs
          autoFocus
          widthRef={refsObj.width.ref}
          heightRef={refsObj.height.ref}
          depthRef={refsObj.depth.ref}
          onFocus={setFocusIndex}
        />

        {customFooter ? (
          <>{customFooter}</>
        ) : (
          <div className={styles.btns}>
            {onBack ? (
              <Button label="Назад" view="ghost" onClick={onBack} />
            ) : (
              <span />
            )}
            <Button
              label="Подтвердить"
              disabled={isInValid}
              onClick={handleSubmit}
            />
          </div>
        )}
      </Modal>
    </EnterHandler>
  )
}

export default PlaceDimensionsModal

import React, { FC } from 'react'
import styles from './Layout.module.scss'
import { Header } from '../Header/Header'

interface ILayout {
  children?: React.ReactNode
}

export const Layout: FC<ILayout> = ({children}) => {
  return (
    <>
      <Header />
      <div className={styles.layout}>
        {children}
      </div>
    </>
  )
}

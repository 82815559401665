import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ICombobox, TaskTypeEnum } from 'src/interfaces'
import { generateStatusesOptions } from 'src/helpers'

export const TASK_TYPE: Record<TaskTypeEnum | string, string> = {
  [TaskTypeEnum.assembling]: 'Сборка',
  [TaskTypeEnum.disassembly]: 'Разборка',
}

export const TASK_TYPE_BADGE_TYPE: Record<
  TaskTypeEnum | string,
  BadgePropStatus
> = {
  [TaskTypeEnum.assembling]: 'success',
  [TaskTypeEnum.disassembly]: 'error',
}

export const taskSelectOptions: ICombobox[] = generateStatusesOptions(TASK_TYPE)

import { useMutation } from 'react-query'
import api from 'src/api'

import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


interface IApiResult extends ApiReqResult {
  id: string
}

export interface IBatchGenerateData {
  docId?: string
  orders?: string[]
}


const useCreateEditPhysicalSetOrderBatch = (
  options?: UseMutationOptions<any, IApiResult, any>,
) => {
  return useMutation(
    async (params: IBatchGenerateData) => {
      const path = `/physical-set-order-batch${params.docId ? `/${params.docId}`:''}`
      const res = await api.post(path, { orders: params.orders })
      if (!res.data?.success) {
        throw new Error('Ошибка генерации батча')
      }
      return res.data
    },
    options,
  )
}

export default useCreateEditPhysicalSetOrderBatch

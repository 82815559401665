import React from 'react'

import styles from './dimensions-input.module.scss'

import { InputWrapper } from 'src/components'

import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IDimensions } from 'src/interfaces'
import cx from 'classnames'
import { TextFieldPropSize } from '@consta/uikit/__internal__/src/components/TextField/TextField'

interface DimensionsInputProps {
  direction?: 'row' | 'column'
  value: IDimensions
  onChange: (d: IDimensions, field: string) => void
  onFocus?: (fieldName: string) => void
  errors?: { [key: string]: string }
  floatNumbersInputs?: boolean
  autoFocus?: boolean
  withLabelTopMargin?: boolean
  size?: TextFieldPropSize
  widthRef?: React.Ref<HTMLInputElement>
  heightRef?: React.Ref<HTMLInputElement>
  depthRef?: React.Ref<HTMLInputElement>
}

const DimensionsInput = (props: DimensionsInputProps) => {
  const {
    withLabelTopMargin = true,
    direction = 'row',
    value,
    onChange,
    errors,
    size = 'l',
    autoFocus = false,
    widthRef,
    heightRef,
    depthRef,
    onFocus
  } = props

  return (
    <div className={styles.wrapper}>
      <h4 className={cx(styles.colLabel, { [styles.labelTopMargin]: withLabelTopMargin })}>Введите габариты, см</h4>
      <div className={cx(styles.col3, styles[direction])}>
        <InputWrapper
          value={value.width}
          id={'dimensions.width'}
          handleChange={(width: TextFieldPropValue) =>
            onChange({ ...value, width }, 'width')
          }
          className={styles.itemInput}
          size={size}
          rightSide="ширина"
          error={errors?.width ?? ''}
          inputRef={widthRef}
          isDimensions
          onFocus={() => onFocus?.('width')}
          autoFocus={autoFocus}
        />
        <InputWrapper
          value={value.height}
          id={'dimensions.height'}
          handleChange={(height: TextFieldPropValue) =>
            onChange({ ...value, height  }, 'height')
          }
          className={styles.itemInput}
          size={size}
          rightSide="высота"
          error={errors?.height ?? ''}
          inputRef={heightRef}
          isDimensions
          onFocus={() => onFocus?.('height')}
        />
        <InputWrapper
          value={value.depth}
          id={'dimensions.depth'}
          handleChange={(depth: TextFieldPropValue) =>
            onChange({ ...value, depth  }, 'depth')
          }
          className={styles.itemInput}
          size={size}
          rightSide="длина"
          error={errors?.depth ?? ''}
          inputRef={depthRef}
          isDimensions
          onFocus={() => onFocus?.('depth')}
        />
      </div>
    </div>
  )
}

export default DimensionsInput

export const saveBase64File = (content:string, title: string = '') => {
  let byteCharacters = atob(content);
  let byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  let byteArray = new Uint8Array(byteNumbers);
  let blob = new Blob([byteArray], {type: "application/xls"});

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.setAttribute('download', `${title}`);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
}

import { InputWrapperProps } from '../InputWrapper'
import { CalendarInputProps } from '../CalendarInput'
import { ComboboxWrapperProps } from '../ComboboxWrapper'
import { ValueOf } from 'src/helpers'
import { IComboboxWrapperWithApi } from '../ComboboxWrapperWithApi/ComboboxWrapperWithApi'
import { SwitchWrapperProps } from '../SwitchWrapper/SwitchWrapper'
import { IBatch, ISku } from 'src/interfaces'
import { DefaultItem } from '@consta/uikit/Combobox'

export interface FilterRow {
  /**
   *  Скрывает строку из фильтров
   *  (обычно для расширения отображения фильтров типа поиска)
   */
  hidden?: boolean
  /** Вспомогательный флаг для распределения полей */
  flexSpaceBetween?: boolean
  /** Вспомогательный класс оборачивающий строки */
  extraClassName?: string
  /** Список полей (или слитых полей) */
  fields: FilterFieldType[]
}

type ExtraPropsFieldsType = {
  /** Id поля используется как ключ для стейта */
  id: string
  /** Описание для отображения */
  viewDescription?: string
}

export interface MainField {
  /** Вспомогательный класс оборачивающий поле */
  fieldWrapperClassName?: string
}

export const FieldType = {
  URL: 'URL',
  input: 'input',
  calendar: 'calendar',
  combobox: 'combobox',
  select: 'select',
  BOOLEAN: 'boolean',
  SKU_BATCH: 'sku_batch',

  SKU: 'sku',
  SKUS: 'skus',
  CELL: 'cell',
  CELLS: 'cells',
  MERCHANT: 'merchant',
  MERCHANTS: 'merchants',
  CONTRACTOR: 'contractor',
  CONTRACTORS: 'contractors',
  PLACE: 'WAREHOUSE_PLACE',
  CONTAINER: 'container',

  DOCUMENT: 'DOCUMENT',

  // todo добавить типы
  TABLE: 'table',
  GROUP: 'GROUP',
  JSON: 'JSON',
} as const
export type FieldType = ValueOf<typeof FieldType>

interface NeverField {
  jointFields?: never
}
/**
 * Интерфейсы полей с разными пропсами под каждый тип поля
 * (по задумке должно быть либо { jointFields }, либо { type, fieldProps })
 */
export interface IJointFieldsProps extends MainField {
  type?: never
  fieldProps?: never
  /** Список слитых вместе полей (поля, которые стоят вместе без пропусков, например поля даты "C" "По") */
  jointFields?: FilterFieldType[]
}

type FieldPropsType<T> = ExtraPropsFieldsType &
  Omit<T, 'value' | 'onChange'> & { onChange?: (...args: any[]) => void }

export interface IFieldBool extends MainField, NeverField {
  type?: typeof FieldType.BOOLEAN
  fieldProps?: FieldPropsType<SwitchWrapperProps>
}
export interface IFieldUrl extends MainField, NeverField {
  type?: typeof FieldType.URL
  fieldProps?: FieldPropsType<InputWrapperProps>
}
export interface IFieldInput extends MainField, NeverField {
  type?: typeof FieldType.input
  fieldProps?: FieldPropsType<InputWrapperProps>
}
export interface IFiledCalendar extends MainField, NeverField {
  type?: typeof FieldType.calendar
  fieldProps?: FieldPropsType<CalendarInputProps>
}
export interface IFieldCombobox extends MainField, NeverField {
  type?: typeof FieldType.combobox
  fieldProps?: FieldPropsType<ComboboxWrapperProps>
}
export interface IFieldSkuBatchCombobox extends MainField, NeverField {
  type?: typeof FieldType.SKU_BATCH
  fieldProps?:
    FieldPropsType<ComboboxWrapperProps>
    & {
      sku: ISku,
      updateSkuBatches?: (value: DefaultItem & {dto?: IBatch}) => void
    }
}
export interface IFieldComboboxWithsApi extends MainField, NeverField {
  type?:
    | typeof FieldType.CONTRACTOR
    | typeof FieldType.CONTRACTORS
    | typeof FieldType.MERCHANT
    | typeof FieldType.SKU
    | typeof FieldType.CELL
    | typeof FieldType.MERCHANTS
    | typeof FieldType.SKUS
    | typeof FieldType.CELLS
    | typeof FieldType.DOCUMENT
  fieldProps?: FieldPropsType<Omit<IComboboxWrapperWithApi, "useGetHookSearch">>
}
export interface IFieldTable extends MainField, NeverField {
  type?: typeof FieldType.TABLE
  fieldProps?: any //TODO: ПОПРАВИТЬ
}
export interface IFieldGroup extends MainField, NeverField {
  type?: typeof FieldType.GROUP
  fieldProps?: any //TODO: ПОПРАВИТЬ
}

export interface IFieldJson extends MainField, NeverField {
  type?: typeof FieldType.JSON
  fieldProps?: any //TODO: ПОПРАВИТЬ
}

const checkType = (type: string, fieldType: FieldType) =>
  type?.toLowerCase() === fieldType.toLowerCase()
export const isUrl = (field: FilterFieldType): field is IFieldUrl =>
  checkType(field?.type, FieldType.URL)
export const isInput = (field: FilterFieldType): field is IFieldInput =>
  checkType(field?.type, FieldType.input)
export const isCalendar = (field: FilterFieldType): field is IFiledCalendar =>
  checkType(field?.type, FieldType.calendar)
export const isCombobox = (field: FilterFieldType): field is IFieldCombobox =>
  checkType(field?.type, FieldType.combobox)
export const isSkuBatch = (field: FilterFieldType): field is IFieldSkuBatchCombobox =>
  checkType(field?.type, FieldType.SKU_BATCH)
export const isDocument = (field: FilterFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.DOCUMENT)
export const isBool = (field: FilterFieldType): field is IFieldBool =>
  checkType(field?.type, FieldType.BOOLEAN)
export const isTable = (field: FilterFieldType): field is IFieldTable =>
  checkType(field?.type, FieldType.TABLE)
export const isGroup = (field: FilterFieldType): field is IFieldGroup =>
  checkType(field?.type, FieldType.GROUP)
export const isJson = (field: FilterFieldType): field is IFieldGroup =>
  checkType(field?.type, FieldType.JSON)


export const isContractors = (field: FilterFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.CONTRACTOR)
  || checkType(field?.type, FieldType.CONTRACTORS)

export const isMerchants = (field: FilterFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.MERCHANT)
  || checkType(field?.type, FieldType.MERCHANTS)
export const isSku = (field: FilterFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.SKU)
  || checkType(field?.type, FieldType.SKUS)
export const isCell = (field: FilterFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.CELL)
  || checkType(field?.type, FieldType.CELLS)


export const isWarehousePlace = (field: FilterFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.PLACE)


export const isWarehouseContainer = (field: FilterFieldType): field is IFieldComboboxWithsApi =>
  checkType(field?.type, FieldType.CONTAINER)


export type FilterFieldType =
  | IFieldInput
  | IFieldUrl
  | IFiledCalendar
  | IFieldCombobox
  | IJointFieldsProps
  | IFieldSkuBatchCombobox
  | IFieldComboboxWithsApi
  | IFieldBool
  | IFieldTable
  | IFieldGroup
  | IFieldJson

import React from 'react'
import cx from 'classnames'

import styles from './switch-wrapper.module.scss'

import { Switch } from '@consta/uikit/Switch'

export interface SwitchWrapperProps {
  label: string
  value: boolean
  onChange: (checked: boolean) => void
  onClick?: (e: React.MouseEvent) => void
  disabled?: boolean
  className?: string
  switchClassName?: string
  readOnly?: boolean
  isRequired?: boolean
  align?: 'center' | 'top'
  size?: "m" | "s" | "xs" | "l"
}

const SwitchWrapper = (props: SwitchWrapperProps) => {
  const {
    isRequired,
    align = 'top',
    label,
    value,
    disabled,
    onClick,
    onChange,
    size,
    className,
    switchClassName
  } = props
  const isTop = align === 'top'
  return (
    <div className={cx(
      styles.wrap,
      { [styles.requiredLabel]: isRequired },
      className
    )}>
      {label && isTop ? <label className={styles.outLabel}>{label}</label> : null}
      <Switch
        align={align}
        label={!isTop && label}
        size={size}
        checked={value ? JSON.parse(`${value}`) : false}
        onClick={onClick}
        disabled={disabled}
        className={cx(styles.switch, {[styles.alignTop]: isTop},  switchClassName)}
        onChange={({ e, checked }) => onChange(checked)}
      />
    </div>
  )
}

export default SwitchWrapper

import React, { Ref } from 'react'
import cx from 'classnames'
import styles from './Button.module.scss'
import { Button as ConstaButton, Props } from '@consta/uikit/Button'

export interface ButtonProps extends Props {
  theme?: 'default' | 'white' | 'success' | 'warning' | 'danger'
  className?: string
}

export const Button = React.forwardRef((props: ButtonProps, ref:Ref<any>) => {
  const {
    className,
    theme = 'default',
    ...restProps
  } = props
  return (
    <ConstaButton
      {...restProps}
      className={cx(styles.Button, styles[`theme-${theme}`], className)}
      ref={ref}
    />
  )
})


import { DependencyList, useEffect } from 'react'


type Props = {
  isActive?: boolean
  handler: () => void
}
export const useKeyDownEscape = ({ isActive=true, handler }: Props, deps: DependencyList) => {
  const keyDownEventHandler = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      handler()
    }
  }
  useEffect(() => {
    if (!isActive) {
      return () => {
        document.removeEventListener('keydown',keyDownEventHandler)
      }
    }
    document.addEventListener('keydown',keyDownEventHandler)
    return () => {
      document.removeEventListener('keydown',keyDownEventHandler)
    }
  }, [isActive, ...deps])
}
import React from 'react'
import cx from 'classnames'
import days from 'dayjs'

import styles from './packed-items-table.module.scss'

import { Badge } from '@consta/uikit/Badge'
import { Button } from '@consta/uikit/Button'

import { Img } from 'src/components'

import { usePrinter } from 'src/printer'
import { useNotifications } from 'src/notification'
import { useGetStickerByPlace, usePrint } from 'src/hooks'

import { useAppContext } from 'src/context'

import { STATUSES_PACKED_ORDERS } from 'src/config'

import { convertWeight, getPackageTitle, hideSymbols } from 'src/helpers'

import { IOrderItem, IOrderPackagingOption, IOrderPackagingPlace, IOrderPackagingPlaceItem } from 'src/interfaces'

interface PackedItemsTableProps {
  orderId?: string
  className?: string
  places: IOrderPackagingPlace[] | null
  packed?: boolean
  closed?: boolean
  items?: IOrderItem[] | undefined
  packagingOptions: IOrderPackagingOption[]
}

const PackedItemsTable = ({
  orderId,
  places,
  items,
  className,
  packagingOptions,
  packed = false,
  closed = false,
}: PackedItemsTableProps) => {
  const printer = usePrinter()
  const notification = useNotifications()
  const { printSticker } = usePrint()

  const { performWithAdvance, advanceMode } = useAppContext()

  const statuses: STATUSES_PACKED_ORDERS = {
    closed: ['закрыто', 'success'],
    packing: ['В упаковке', 'normal'],
  }

  const getSticker = useGetStickerByPlace()

  const printPlaceSticker = async (id: string) => {
    if (!orderId) return

    await getSticker.fetch({ orderId, placeId: id }).then((result) => {
      if (result.success) {
        const { sticker, stickerExists } = result
        printSticker(sticker.printer, sticker.content, { stickerExists })
      }
    })
  }

  const handleGetPlaceSticker = async (id: string | undefined) => {
    if (!id) return

    if (advanceMode) {
      printPlaceSticker(id)
    } else {
      performWithAdvance(() => () => {
        printPlaceSticker(id)
      })
    }
  }

  const compareItems = (a: any, b: any) => {
    return days(a.packed_at).isBefore(b.packed_at) ? 1 : -1
  }

  if (!places) return null

  const formattedPlaces = places
    .slice()
    .reverse()
    .map((place: IOrderPackagingPlace) => ({
      ...place,
      items: place.items.reduce(
        (
          a: IOrderPackagingPlaceItem[],
          c: IOrderPackagingPlaceItem,
          i: number,
          arr: IOrderPackagingPlaceItem[],
        ) => {
          const findCurrentPlaceItem = a.find(
            (ci: IOrderPackagingPlaceItem) => ci.id === c.id,
          )
          if (findCurrentPlaceItem) {
            findCurrentPlaceItem.quantity =
              findCurrentPlaceItem.quantity + c.quantity
          } else {
            a.push({ ...c })
          }

          return a
        },
        [] as IOrderPackagingPlaceItem[],
      ),
    }))

  return (
    <>
      <div className={cx(className, styles.tableWrap)}>
        <div className={styles.tableHead}>
          <div className={styles.imgHead}>Фото</div>
          <div className={styles.imgName}>
            Номенклатура
            <span>Штрих-код товара</span>
          </div>
          <div className={styles.weightHead}>Вес 1 шт., кг</div>
          <div className={styles.quantityHead}>Кол-во</div>
        </div>
        <div className={styles.tableBody}>
          {formattedPlaces?.length
            ? formattedPlaces.map((place) => (
                <>
                  <div className={styles.place}>
                    <div className={styles.placeBadge}>
                      {place.id ? (
                        <Badge
                          status="success"
                          label="Закрыто"
                          className={styles.badge}
                          form="round"
                        />
                      ) : (
                        <Badge
                          status="normal"
                          label="Открыто"
                          className={styles.badge}
                          form="round"
                        />
                      )}
                    </div>
                    <div className={styles.placeInfo}>
                      <div>Место {place.sequence_num}</div>
                      <div className={styles.package}>
                        {getPackageTitle(place, packagingOptions)}
                      </div>
                      {place?.id ? <span>{convertWeight('g', 'kg', place.weight || 0)} кг.</span> : null}
                    </div>
                    <div className={styles.print}>
                      {place?.id ? (
                        <Button
                          label="Напечатать стикер"
                          view="secondary"
                          className={styles.btn}
                          size="s"
                          onClick={(e) => handleGetPlaceSticker(place.id)}
                        />
                      ) : null}
                    </div>
                    <div
                      className={styles.placeQuantity}
                    >{`${place.items.reduce(
                      (a, c) => a + c.quantity,
                      0,
                    )} шт.`}</div>
                  </div>
                  {place.items?.sort(compareItems).map((item) => (
                    <div className={styles.tableTr}>
                      <div className={styles.imgTd}>
                        <Img
                          className={styles.img}
                          src={
                            items?.find((o: IOrderItem) => o.id === item.id)
                              ?.image
                          }
                          size={100}
                          yandex
                        />
                      </div>
                      <div className={styles.nameTd}>
                        {
                          items?.find((o: IOrderItem) => o.id === item.id)
                            ?.title
                        }
                        <span className={styles.light}>
                          {hideSymbols(6, item.barcode_used)}
                        </span>
                      </div>
                      {item.weight ? (
                        <div className={styles.weight}>{convertWeight('g', 'kg',item.weight || 0)} кг.</div>
                      ) : null}
                      <div className={styles.quantityTd}>
                        {item.quantity} шт.
                      </div>
                    </div>
                  ))}
                </>
              ))
            : null}
        </div>
      </div>
    </>
  )
}

export default PackedItemsTable

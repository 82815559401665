import api from 'src/api'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { ApiReqResult } from '../../interfaces'


interface IParams {
  doc_id: string
}

const pickingStart = async ({ doc_id }: IParams) => {
  const result = await api.post(`/picking/${doc_id}/start`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

const usePickingStart = (options?: UseMutationOptions<any,ApiReqResult,any>) => {
  return useMutation(pickingStart, options)
}

export default usePickingStart

import { appLocalStorage } from '../../../libs'
import { useAppContext } from '../../../context'
import { IDatabaseSelectOption } from 'src/interfaces'
import { useMemo } from 'react'

type DatabaseObjectType = {
  prod: IDatabaseSelectOption[],
  test: IDatabaseSelectOption[]
}
export const useDatabases = () => {
  const {
    databases
  } = useAppContext()

  const isProd = process.env.REACT_APP_BUILD_MODE === 'prod';

  const {
    prod: prodDbOptions,
    test: testDbOptions
  } = useMemo(() => databases.reduce((acc, db) => {
    const key: keyof DatabaseObjectType = db.isTest ? 'test' : 'prod'
    return { ...acc, [key]: [ ...acc[key], db ] }
  }, { prod: [], test: [] } as DatabaseObjectType), [databases])

  const advancedDatabase = localStorage.getItem('withTestDB')
  /* Тестовые и продовые базы для выбранной конфигурации dbMode */
  const databasesWithMode = [...testDbOptions, ...prodDbOptions]
  /* Скрытые для продового режима базы или все, если добавлен параметр в локал сторедже (для отладки) */
  const dataBaseOptions = isProd && !advancedDatabase ? prodDbOptions : databasesWithMode;

  const getDBOptionByStorage = () => {
    if (!appLocalStorage.currentBase) {
      return null
    }
    return databasesWithMode.find(storageDBOption => storageDBOption.value === appLocalStorage.currentBase)
  }

  return {
    dataBaseOptions: dataBaseOptions || [],
    getDBOptionByStorage
  }
}
export const BIG_ACCEPTANCE: any = {
  "errors": [],
  "skus": [
    null,
    {
      "article": "220773",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196506475",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 120,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 120,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "220773",
      "fragile": false,
      "id": "0Q-220773",
      "images": [],
      "manufacturer": "КОМПАНИЯ ТОР ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Жидкость для снятия лака `PINK UP` `DAILY` без ацетона (Черника) 200 мл",
      "type": "SKU",
      "weight_fact": 222,
      "weight_plan": 222
    },
    {
      "article": "209008",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809647391500",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 170,
        "width": 110
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 170,
        "width": 110
      },
      "expiration_months": 36,
      "external_id": "209008",
      "fragile": false,
      "id": "0Q-209008",
      "images": [],
      "manufacturer": "ANCORS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `SOME BY MI` REAL с экстрактом чайного дерева (успокаивающая) 20 г",
      "type": "SKU",
      "weight_fact": 26,
      "weight_plan": 26
    },
    {
      "article": "210216",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159421897",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 230,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 230,
        "width": 100
      },
      "expiration_months": 0,
      "external_id": "210216",
      "fragile": false,
      "id": "0Q-210216",
      "images": [],
      "manufacturer": "NINGBO KAILI HOLDING GROUP CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Станок для бритья одноразовый `VOX` FOR WOMEN 3 лезвия 4 шт",
      "type": "SKU",
      "weight_fact": 42,
      "weight_plan": 42
    },
    {
      "article": "224424",
      "barcodes": [
        {
          "active": true,
          "barcode": "8697926006036",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 280,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 280,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "224424",
      "fragile": false,
      "id": "0Q-224424",
      "images": [],
      "manufacturer": "REDIST KOZMETIK SANAYI IC VE DIS TICARET ANONIM SIRKETI",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для волос `REDIST` FULL FORCE Cупер сильной фиксации 400 мл",
      "type": "SKU",
      "weight_fact": 480,
      "weight_plan": 480
    },
    {
      "article": "241005",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523601",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 150,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "241005",
      "fragile": false,
      "id": "0Q-241005",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш для губ `ISCREAM` FLOWER FLOW тон 04",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "43003",
      "barcodes": [
        {
          "active": true,
          "barcode": "5900095000327",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 330,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 330,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "43003",
      "fragile": false,
      "id": "0Q-43003",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Диски ватные `CLEANIC` PURE EFFECT гигиенические 120 шт",
      "type": "SKU",
      "weight_fact": 48,
      "weight_plan": 48
    },
    {
      "article": "203428",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809643517201",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 150,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "203428",
      "fragile": false,
      "id": "0Q-203428",
      "images": [],
      "manufacturer": "COSMAX INC.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `A`PIEU` LACTOBACILLUS с лактобактериями (увлажняющий) 50 мл",
      "type": "SKU",
      "weight_plan": 71
    },
    {
      "article": "70351",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607051792715",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 140,
        "height": 20,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "70351",
      "fragile": false,
      "id": "0Q-70351",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Хна для волос `ФИТОКОСМЕТИК` ИНДИЙСКАЯ Натуральная 25 г",
      "type": "SKU",
      "weight_plan": 28
    },
    {
      "article": "146602",
      "barcodes": [
        {
          "active": true,
          "barcode": "4897045272976",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 220,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 220,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "146602",
      "fragile": false,
      "id": "0Q-146602",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `KENSUKO` SPA Энергия восстановления 500 г",
      "type": "SKU",
      "weight_fact": 561,
      "weight_plan": 561
    },
    {
      "article": "227141",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196512209",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 160,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "227141",
      "fragile": false,
      "id": "0Q-227141",
      "images": [],
      "manufacturer": "ГРАНД А. В. ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Бумага туалетная влажная `LELEA` детская с D-пантенолом 60 шт",
      "type": "SKU",
      "weight_fact": 250,
      "weight_plan": 250
    },
    {
      "article": "177583",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809486361597",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 140,
        "width": 170
      },
      "expiration_months": 36,
      "external_id": "177583",
      "fragile": false,
      "id": "0Q-177583",
      "images": [],
      "manufacturer": "C&TECH CORP",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BANOBAGI` VITA GENIC с экстрактом центеллы азиатской и комплексом витаминов (восстанавливающая) 30 г",
      "type": "SKU",
      "weight_plan": 37
    },
    {
      "article": "84361",
      "barcodes": [
        {
          "active": true,
          "barcode": "6926939642088",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104116726",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 290,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "84361",
      "fragile": false,
      "id": "0Q-84361",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `KENSUKO` восстанавливающий для поврежденных волос 760 г",
      "type": "SKU",
      "weight_plan": 870
    },
    {
      "article": "129329",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809386880402",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 150,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "129329",
      "fragile": false,
      "id": "0Q-129329",
      "images": [],
      "manufacturer": "INTERKOS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для рук `ESFOLIO` с экстрактом секрета улитки 100 мл",
      "type": "SKU",
      "weight_plan": 130
    },
    {
      "article": "213381",
      "barcodes": [
        {
          "active": true,
          "barcode": "4810438026550",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 120,
        "width": 20
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 20
      },
      "expiration_months": 30,
      "external_id": "213381",
      "fragile": false,
      "id": "0Q-213381",
      "images": [],
      "manufacturer": "ООО РЕЛУИ БЕЛ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Помада для губ `RELOUIS` NUDE MATTE тон 23 жидкая матовая",
      "type": "SKU",
      "weight_fact": 22,
      "weight_plan": 22
    },
    {
      "article": "196441",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196500220",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 170,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 170,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "196441",
      "fragile": false,
      "id": "0Q-196441",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Молочко-шиммер `SUN LOOK` золотое сияние 150 мл",
      "type": "SKU",
      "weight_fact": 164,
      "weight_plan": 164
    },
    {
      "article": "169248",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104742178",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4630160535690",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 70,
        "width": 110
      },
      "expiration_months": 0,
      "external_id": "169248",
      "fragile": false,
      "id": "0Q-169248",
      "images": [],
      "manufacturer": "GUANGZHOU DONGZHIMENG COSMETIC CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки для лица `DECO.` матирующие с экстрактом зеленого чая 100 шт",
      "type": "SKU",
      "weight_plan": 19
    },
    {
      "article": "206286",
      "barcodes": [
        {
          "active": true,
          "barcode": "8429871991514",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 210,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 210,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "206286",
      "fragile": false,
      "id": "0Q-206286",
      "images": [],
      "manufacturer": "NUVARIA GLOBAL, S.L.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-гель для душа `DICORA URBAN FIT` Йогурт и овес 400 мл",
      "type": "SKU",
      "weight_fact": 508,
      "weight_plan": 508
    },
    {
      "article": "106193",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809663576011",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104295346",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "8809323737646",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 130,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 130,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "106193",
      "fragile": false,
      "id": "0Q-106193",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сыворотка для лица `IT`S SKIN` COLLAGEN 40 мл",
      "type": "SKU",
      "weight_fact": 151,
      "weight_plan": 151
    },
    {
      "article": "212901",
      "barcodes": [
        {
          "active": true,
          "barcode": "8807779098335",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 230,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 230,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "212901",
      "fragile": false,
      "id": "0Q-212901",
      "images": [],
      "manufacturer": "DOORI COSMETICS CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для волос `DAENG GI MEO RI` HONEY c пчелиным маточным молочком 500 мл",
      "type": "SKU",
      "weight_fact": 596,
      "weight_plan": 596
    },
    {
      "article": "235832",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196518751",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "235832",
      "fragile": false,
      "id": "0Q-235832",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Масло после депиляции `VOX` 150 мл",
      "type": "SKU",
      "weight_fact": 300,
      "weight_plan": 300
    },
    {
      "article": "220694",
      "barcodes": [
        {
          "active": true,
          "barcode": "5908233660204",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 110,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "220694",
      "fragile": false,
      "id": "0Q-220694",
      "images": [],
      "manufacturer": "BE BIO ACTIVECOSMETIQS -MIND NETWORK INSPIRE SP. Z O.O. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для области вокруг глаз `BEBIO` питательно-разглаживающий (для молодой кожи) 15 мл",
      "type": "SKU",
      "weight_plan": 35
    },
    {
      "article": "229189",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809486364192",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 140,
        "width": 160
      },
      "expiration_months": 1,
      "external_id": "229189",
      "fragile": false,
      "id": "0Q-229189",
      "images": [],
      "manufacturer": "C&TECH CORP",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BANOBAGI` с коллагеном и ретинолом (anti-age) 30 г",
      "type": "SKU",
      "weight_plan": 35
    },
    {
      "article": "228357",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329039420",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 140,
        "width": 20
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 140,
        "width": 20
      },
      "expiration_months": 1,
      "external_id": "228357",
      "fragile": false,
      "id": "0Q-228357",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тушь для ресниц `LUXVISAGE` ELASTICA THERMO TUBING объемная тон черный",
      "type": "SKU",
      "weight_fact": 27,
      "weight_plan": 27
    },
    {
      "article": "192033",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159424294",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 240,
        "width": 60
      },
      "expiration_months": 0,
      "external_id": "192033",
      "fragile": false,
      "id": "0Q-192033",
      "images": [],
      "manufacturer": "YADE COSMETIC (DONGGUAN) CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кисть для пудры и румян `DECO.` REBEL luxe finish",
      "type": "SKU",
      "weight_plan": 42
    },
    {
      "article": "196439",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196500206",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 80,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 80,
        "width": 70
      },
      "expiration_months": 0,
      "external_id": "196439",
      "fragile": false,
      "id": "0Q-196439",
      "images": [],
      "manufacturer": "NINGBO KAILI HOLDING GROUP CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кассеты для станка `VOX` 3 лезвия (Алоэ вера) 2 шт",
      "type": "SKU",
      "weight_fact": 18,
      "weight_plan": 18
    },
    {
      "article": "232016",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196515903",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 130,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 130,
        "width": 70
      },
      "expiration_months": 24,
      "external_id": "232016",
      "fragile": false,
      "id": "0Q-232016",
      "images": [],
      "manufacturer": "ЗЕТТЕКНОЛОДЖИ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-мыло детское `LP CARE` с пантенолом 90 г",
      "type": "SKU",
      "weight_fact": 91,
      "weight_plan": 91
    },
    {
      "article": "191902",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159422405",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 110,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "191902",
      "fragile": false,
      "id": "0Q-191902",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Парфюмированный мист для тела `BESTIES` BODY MIST kissing mizzle 30 мл",
      "type": "SKU",
      "weight_plan": 43
    },
    {
      "article": "227043",
      "barcodes": [
        {
          "active": true,
          "barcode": "4602984022229",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 310,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 310,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "227043",
      "fragile": false,
      "id": "0Q-227043",
      "images": [],
      "manufacturer": "СТУПИНСКИЙ ХИМИЧЕСКИЙ ЗАВОД АО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `BIS` весенняя прохлада (концентрированный) 900 мл",
      "type": "SKU",
      "weight_fact": 970,
      "weight_plan": 970
    },
    {
      "article": "227070",
      "barcodes": [
        {
          "active": true,
          "barcode": "5060434299017",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 210,
        "width": 230
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 210,
        "width": 230
      },
      "expiration_months": 1,
      "external_id": "227070",
      "fragile": false,
      "id": "0Q-227070",
      "images": [],
      "manufacturer": "LOVULAR LIMITED",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Бальзам-ополаскиватель для стирки `LOVULAR` SWEET KISS 1000 мл",
      "type": "SKU",
      "weight_fact": 1000,
      "weight_plan": 1000
    },
    {
      "article": "111460",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901018014414",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104329911",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 220,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "111460",
      "fragile": false,
      "id": "0Q-111460",
      "images": [],
      "manufacturer": "LABORATORIUM KOSMETYCZNE JOANNA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `JOANNA` KERATIN с кератином 500 мл",
      "type": "SKU",
      "weight_plan": 567
    },
    {
      "article": "222991",
      "barcodes": [
        {
          "active": true,
          "barcode": "4604049012831",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 190,
        "width": 140
      },
      "expiration_months": 24,
      "external_id": "222991",
      "fragile": false,
      "id": "0Q-222991",
      "images": [],
      "manufacturer": "НЕФИС- КОСМЕТИКC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Порошок стиральный `BIMAX` 100 пятен 400 гр",
      "type": "SKU",
      "weight_plan": 435
    },
    {
      "article": "227017",
      "barcodes": [
        {
          "active": true,
          "barcode": "4815038044430",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 150,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "227017",
      "fragile": false,
      "id": "0Q-227017",
      "images": [],
      "manufacturer": "ЛЮБО ДЕЛО ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пятновыводитель для белья `ЛЮБО ДЕЛО` для женского белья и кружева (3 саше)",
      "type": "SKU",
      "weight_plan": 111
    },
    {
      "article": "196375",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159429558",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 60,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "196375",
      "fragile": false,
      "id": "0Q-196375",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `MINI GEL` тон 41 5 мл",
      "type": "SKU",
      "weight_plan": 31
    },
    {
      "article": "213840",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809414192224",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 180,
        "width": 130
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 180,
        "width": 130
      },
      "expiration_months": 36,
      "external_id": "213840",
      "fragile": false,
      "id": "0Q-213840",
      "images": [],
      "manufacturer": "LK COSMETICS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BERGAMO` HELP! с экстрактом нони (укрепляющая) 25 мл",
      "type": "SKU",
      "weight_fact": 30,
      "weight_plan": 30
    },
    {
      "article": "245841",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329045544",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 110,
        "width": 20
      },
      "expiration_months": 31,
      "external_id": "245841",
      "fragile": false,
      "id": "0Q-245841",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тени для век `LUXVISAGE` SATIN NUDE Super stay жидкие тон 204 Sunny beige",
      "type": "SKU",
      "weight_plan": 21
    },
    {
      "article": "205371",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901018020088",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 200,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "205371",
      "fragile": false,
      "id": "0Q-205371",
      "images": [],
      "manufacturer": "LABORATORIUM KOSMETYCZNE JOANNA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для душа и шампунь `JOANNA` `POWER MEN` ACTIVE 4 в 1 c экстрактом конопли (мужской) 300 мл",
      "type": "SKU",
      "weight_plan": 346
    },
    {
      "article": "158598",
      "barcodes": [
        {
          "active": true,
          "barcode": "8714100914878",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "158598",
      "fragile": false,
      "id": "0Q-158598",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для волос `TRESEMME` Beauty-full volume Экстрафиксация 250 мл",
      "type": "SKU",
      "weight_plan": 253
    },
    {
      "article": "182312",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104850644",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4630160530275",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "182312",
      "fragile": false,
      "id": "0Q-182312",
      "images": [],
      "manufacturer": "DONGGUAN TAIMENG ACCESSORIES CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для губ `LP CARE` ночная Good night 1 шт",
      "type": "SKU",
      "weight_plan": 15
    },
    {
      "article": "209761",
      "barcodes": [
        {
          "active": true,
          "barcode": "8480029434956",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "209761",
      "fragile": false,
      "id": "0Q-209761",
      "images": [],
      "manufacturer": "NUVARIA GLOBAL, S.L.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь-кондиционер для волос `DICORA URBAN FIT` 2 в 1 мужской с цинком 400 мл",
      "type": "SKU",
      "weight_plan": 459
    },
    {
      "article": "227066",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600697010267",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 130,
        "height": 300,
        "width": 270
      },
      "dimensions_plan": {
        "depth": 130,
        "height": 300,
        "width": 270
      },
      "expiration_months": 1,
      "external_id": "227066",
      "fragile": false,
      "id": "0Q-227066",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Порошок стиральный `SARMA` Горная свежесть 2,4 кг",
      "type": "SKU",
      "weight_fact": 2505,
      "weight_plan": 2505
    },
    {
      "article": "147373",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016846782",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 210,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "147373",
      "fragile": false,
      "id": "0Q-147373",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `SEA OF SPA` `BIOSPA` с кератином и маслом макадамии 400 мл",
      "type": "SKU",
      "weight_fact": 490,
      "weight_plan": 490
    },
    {
      "article": "141324",
      "barcodes": [
        {
          "active": true,
          "barcode": "3574661331157",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "3574660607567",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "3574660362794",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 140,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "141324",
      "fragile": false,
      "id": "0Q-141324",
      "images": [],
      "manufacturer": "JOHNSON & JOHNSON",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тампоны `O.B.` PRO COMFORT Super plus 16 шт",
      "type": "SKU",
      "weight_plan": 80
    },
    {
      "article": "138866",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806185782616",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "138866",
      "fragile": false,
      "id": "0Q-138866",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пилинг-гель для лица `A`PIEU` NAKED Скатка с PHA-кислотами 100 мл",
      "type": "SKU",
      "weight_plan": 124
    },
    {
      "article": "240999",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523540",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 130,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "240999",
      "fragile": false,
      "id": "0Q-240999",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Подводка для глаз `ISCREAM` FLOWER FLOW тон 02 pink с глиттером",
      "type": "SKU",
      "weight_plan": 11
    },
    {
      "article": "228309",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290018306307",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 80,
        "height": 220,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 80,
        "height": 220,
        "width": 80
      },
      "expiration_months": 1,
      "external_id": "228309",
      "fragile": false,
      "id": "0Q-228309",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для душа `SEA OF SPA` CANNABIO 780 мл",
      "type": "SKU",
      "weight_fact": 858,
      "weight_plan": 858
    },
    {
      "article": "225736",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196510175",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 170,
        "width": 80
      },
      "expiration_months": 1,
      "external_id": "225736",
      "fragile": false,
      "id": "0Q-225736",
      "images": [],
      "manufacturer": "COSMEWAX, S.A.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-автозагар для тела `SUN LOOK` 150 мл",
      "type": "SKU",
      "weight_plan": 178
    },
    {
      "article": "141296",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016846089",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 190,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "141296",
      "fragile": false,
      "id": "0Q-141296",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для ног `SEA OF SPA` `BIOSPA` с маслом чайного дерева (против трещин) 180 мл",
      "type": "SKU",
      "weight_fact": 278,
      "weight_plan": 278
    },
    {
      "article": "241002",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523571",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 150,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "241002",
      "fragile": false,
      "id": "0Q-241002",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш для губ `ISCREAM` FLOWER FLOW тон 01",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "204584",
      "barcodes": [
        {
          "active": true,
          "barcode": "5903416023340",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 110,
        "width": 20
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 110,
        "width": 20
      },
      "expiration_months": 36,
      "external_id": "204584",
      "fragile": false,
      "id": "0Q-204584",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Корректор для лица `EVELINE` BOTANIC EXPERT антибактериальный точечный тон 010",
      "type": "SKU",
      "weight_fact": 12,
      "weight_plan": 12
    },
    {
      "article": "169249",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160536024",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104742185",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 60,
        "width": 90
      },
      "expiration_months": 0,
      "external_id": "169249",
      "fragile": false,
      "id": "0Q-169249",
      "images": [],
      "manufacturer": "3M JAPAN LIMITED",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки-пленка для лица `DECO.` матирующие 50 шт",
      "type": "SKU",
      "weight_plan": 12
    },
    {
      "article": "155187",
      "barcodes": [
        {
          "active": true,
          "barcode": "4897045273096",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 220,
        "width": 140
      },
      "expiration_months": 36,
      "external_id": "155187",
      "fragile": false,
      "id": "0Q-155187",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `KHARISMA VOLTAGE` ARGAN OIL восстанавливающая с маслом арганы 500 мл",
      "type": "SKU",
      "weight_plan": 497
    },
    {
      "article": "12730",
      "barcodes": [
        {
          "active": true,
          "barcode": "9000100234825",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400000163367",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "9000101409468",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "12730",
      "fragile": false,
      "id": "0Q-12730",
      "images": [],
      "manufacturer": "HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для стирки жидкое `ЛАСКА` Сияние цвета 1 л",
      "type": "SKU",
      "weight_plan": 1000
    },
    {
      "article": "226961",
      "barcodes": [
        {
          "active": true,
          "barcode": "4623722258311",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 80
      },
      "expiration_months": 1,
      "external_id": "226961",
      "fragile": false,
      "id": "0Q-226961",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для мытья посуды `SYNERGETIC` с ароматом апельсина 500 мл",
      "type": "SKU",
      "weight_plan": 520
    },
    {
      "article": "164362",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809443252500",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "164362",
      "fragile": false,
      "id": "0Q-164362",
      "images": [],
      "manufacturer": "MLS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка для умывания `SHINETREE` с экстрактом авокадо 80 мл",
      "type": "SKU",
      "weight_plan": 94
    },
    {
      "article": "125475",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104445765",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5901761914344",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 170,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 170,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "125475",
      "fragile": false,
      "id": "0Q-125475",
      "images": [],
      "manufacturer": "Eveline Cosmetics S.A. sp.k.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Эмульсия для тела `SUN LOOK` солнцезащитная водостойкая SPF-50 150 мл",
      "type": "SKU",
      "weight_fact": 178,
      "weight_plan": 178
    },
    {
      "article": "160221",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160533948",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400104680999",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 150,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 150,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "160221",
      "fragile": false,
      "id": "0Q-160221",
      "images": [],
      "manufacturer": "COSMEWAX, S.A.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Набор для депиляции `VOX` (теплый воск 120 мл, полоски для депиляции 20 шт)",
      "type": "SKU",
      "weight_fact": 240,
      "weight_plan": 240
    },
    {
      "article": "231822",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809854130176",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 40
      },
      "expiration_months": 1,
      "external_id": "231822",
      "fragile": false,
      "id": "0Q-231822",
      "images": [],
      "manufacturer": "FROM B LAB CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сыворотка для лица `DEARBOO` ночная с ретинолом и гиалуроновой кислотой (anti-age) 60 мл",
      "type": "SKU",
      "weight_plan": 93
    },
    {
      "article": "222598",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196507007",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 90,
        "width": 20
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 90,
        "width": 20
      },
      "expiration_months": 36,
      "external_id": "222598",
      "fragile": false,
      "id": "0Q-222598",
      "images": [],
      "manufacturer": "DONGGUAN TAIMENG ACCESSORIES CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Помада-бальзам для губ `ISCREAM` CHOICE с эффектом ph тон 02 (cherry)",
      "type": "SKU",
      "weight_fact": 25,
      "weight_plan": 25
    },
    {
      "article": "93946",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600697113876",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4600697105680",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 100,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 100,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "93946",
      "fragile": false,
      "id": "0Q-93946",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло хозяйственное `УШАСТЫЙ НЯНЬ` Против пятен (для детского белья) 180 г",
      "type": "SKU",
      "weight_fact": 179,
      "weight_plan": 179
    },
    {
      "article": "123904",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104434790",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "8806325616764",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 170,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "123904",
      "fragile": false,
      "id": "0Q-123904",
      "images": [],
      "manufacturer": "LION CORPORATION",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Паста зубная `LION` SYSTEMA Защита от образования зубного камня 120 г",
      "type": "SKU",
      "weight_plan": 145
    },
    {
      "article": "108506",
      "barcodes": [
        {
          "active": true,
          "barcode": "4897045271207",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104307612",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 130,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "108506",
      "fragile": false,
      "id": "0Q-108506",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь `KENSUKO` Восстанавливающий (для поврежденных волос) 110 мл",
      "type": "SKU",
      "weight_plan": 126
    },
    {
      "article": "227140",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196512193",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 100,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 100,
        "width": 50
      },
      "expiration_months": 42,
      "external_id": "227140",
      "fragile": false,
      "id": "0Q-227140",
      "images": [],
      "manufacturer": "ЗЕТТЕКНОЛОДЖИ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки влажные `LELEA` для белой обуви 8 шт",
      "type": "SKU",
      "weight_fact": 34,
      "weight_plan": 34
    },
    {
      "article": "223180",
      "barcodes": [
        {
          "active": true,
          "barcode": "4056800640065",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 220,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "223180",
      "fragile": false,
      "id": "0Q-223180",
      "images": [],
      "manufacturer": "INTERSPRAY",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для волос `WELLA` `WELLAFLEX` блеск и фиксация 250 мл",
      "type": "SKU",
      "weight_plan": 245
    },
    {
      "article": "12407",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400000159964",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "3574660234329",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "3574661331164",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "3574660239881",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 60,
        "width": 130
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 60,
        "width": 130
      },
      "expiration_months": 1,
      "external_id": "12407",
      "fragile": false,
      "id": "0Q-12407",
      "images": [],
      "manufacturer": "JOHNSON & JOHNSON",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тампоны `O.B.` PRO COMFORT Super 16 шт",
      "type": "SKU",
      "weight_fact": 61,
      "weight_plan": 61
    },
    {
      "article": "233413",
      "barcodes": [
        {
          "active": true,
          "barcode": "4001499162824",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 270,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "233413",
      "fragile": false,
      "id": "0Q-233413",
      "images": [],
      "manufacturer": "FROSCH",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Молочко чистящее `FROSCH` Лаванда 500 мл",
      "type": "SKU",
      "weight_plan": 725
    },
    {
      "article": "133194",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104508767",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4630160531104",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 150,
        "width": 90
      },
      "expiration_months": 24,
      "external_id": "133194",
      "fragile": false,
      "id": "0Q-133194",
      "images": [],
      "manufacturer": "ГРАНД А. В. ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки влажные `LP CARE` для снятия макияжа с мицеллярной водой 15 шт",
      "type": "SKU",
      "weight_plan": 56
    },
    {
      "article": "204700",
      "barcodes": [
        {
          "active": true,
          "barcode": "4623722258205",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 280,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 280,
        "width": 90
      },
      "expiration_months": 24,
      "external_id": "204700",
      "fragile": false,
      "id": "0Q-204700",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `SYNERGETIC` Лавандовое поле 1000 мл",
      "type": "SKU",
      "weight_fact": 1097,
      "weight_plan": 1097
    },
    {
      "article": "242856",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196525902",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "242856",
      "fragile": false,
      "id": "0Q-242856",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `LIMITED` FLOWER BLOOM тон 10 10 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "192318",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010711228",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4640159425079",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 90,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "192318",
      "fragile": false,
      "id": "0Q-192318",
      "images": [],
      "manufacturer": "ERTE KOZMETIK SANAYI VE TICARET A.S",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Парфюмерная вода `BESTIES` NATURE SOUND midnight blossom (жен.) 20 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_plan": 98
    },
    {
      "article": "226776",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196511745",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 210,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 210,
        "width": 90
      },
      "expiration_months": 12,
      "external_id": "226776",
      "fragile": false,
      "id": "0Q-226776",
      "images": [],
      "manufacturer": "COSMEWAX, S.A.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Полоски восковые для лица `VOX` GREEN 12 шт + 2 салфетки",
      "type": "SKU",
      "weight_fact": 56,
      "weight_plan": 56
    },
    {
      "article": "85825",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809581330580",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400104140929",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "8809285880220",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 110
      },
      "expiration_months": 36,
      "external_id": "85825",
      "fragile": false,
      "id": "0Q-85825",
      "images": [],
      "manufacturer": "BLISSPACK CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Патчи для глаз гидрогелевые `LASSIE`EL` WHITE CRYSTAL против темных кругов 16 шт",
      "type": "SKU",
      "weight_plan": 49
    },
    {
      "article": "223520",
      "barcodes": [
        {
          "active": true,
          "barcode": "5903416047483",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 140,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 140,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "223520",
      "fragile": false,
      "id": "0Q-223520",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `EVELINE` FACE THERAPY PROFESSIONAL с витамином С 8 мл",
      "type": "SKU",
      "weight_fact": 10,
      "weight_plan": 10
    },
    {
      "article": "240996",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523250",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 70,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "240996",
      "fragile": false,
      "id": "0Q-240996",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Палетка хайлайтеров для лица `ISCREAM` FLOWER FLOW тон 01-03",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "146712",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809530054925",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 120,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 120,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "146712",
      "fragile": false,
      "id": "0Q-146712",
      "images": [],
      "manufacturer": "COSMECCA KOREA CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Бустер для лица `A`PIEU` GLYCOLIC c AHA и BHA-кислотами и гликолевой кислотой 120 мл",
      "type": "SKU",
      "weight_fact": 294,
      "weight_plan": 294
    },
    {
      "article": "191924",
      "barcodes": [
        {
          "active": true,
          "barcode": "4897045274062",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 90,
        "width": 20
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 90,
        "width": 20
      },
      "expiration_months": 36,
      "external_id": "191924",
      "fragile": false,
      "id": "0Q-191924",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Филлер для волос `KENSUKO` 13 мл",
      "type": "SKU",
      "weight_fact": 25,
      "weight_plan": 25
    },
    {
      "article": "245839",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329045520",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 110,
        "width": 20
      },
      "expiration_months": 31,
      "external_id": "245839",
      "fragile": false,
      "id": "0Q-245839",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тени для век `LUXVISAGE` SATIN NUDE Super stay жидкие тон 202 Pastel peach",
      "type": "SKU",
      "weight_plan": 21
    },
    {
      "article": "123897",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806325603566",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104434721",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 250,
        "width": 120
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 250,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "123897",
      "fragile": false,
      "id": "0Q-123897",
      "images": [],
      "manufacturer": "LION CORPORATION",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей-пятновыводитель `LION` BEAT Кислородный (концентрированный) 500 мл",
      "type": "SKU",
      "weight_fact": 607,
      "weight_plan": 607
    },
    {
      "article": "228308",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290018306291",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 70,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 70,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "228308",
      "fragile": false,
      "id": "0Q-228308",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `SEA OF SPA` CANNABIO ночной 60 мл",
      "type": "SKU",
      "weight_fact": 174,
      "weight_plan": 174
    },
    {
      "article": "240995",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523243",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 70,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "240995",
      "fragile": false,
      "id": "0Q-240995",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Палетка теней для век `ISCREAM` FLOWER FLOW тон 01-06",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "125250",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104444331",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "7290018306956",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4640010717602",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 100,
        "height": 70,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 100,
        "height": 70,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "125250",
      "fragile": false,
      "id": "0Q-125250",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Скраб для тела `SEA OF SPA` `BIOSPA` Жасмин 350 мл",
      "type": "SKU",
      "weight_fact": 512,
      "weight_plan": 512
    },
    {
      "article": "204542",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809698220286",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "204542",
      "fragile": false,
      "id": "0Q-204542",
      "images": [],
      "manufacturer": "MEDB CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `MED B` 1 DAY с гиалуроновой кислотой (увлажняющая) 27 мл",
      "type": "SKU",
      "weight_plan": 30
    },
    {
      "article": "234988",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290018306093",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 130,
        "width": 70
      },
      "expiration_months": 60,
      "external_id": "234988",
      "fragile": false,
      "id": "0Q-234988",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сыворотка для лица `SEA OF SPA` `BIO MARINE` с натуральным коллагеном 40 мл",
      "type": "SKU",
      "weight_plan": 181
    },
    {
      "article": "227963",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809628884632",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 150,
        "width": 40
      },
      "expiration_months": 1,
      "external_id": "227963",
      "fragile": false,
      "id": "0Q-227963",
      "images": [],
      "manufacturer": "B&B KOREA CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `TENZERO` SIGNATURE с глутатионом (для сияния кожи) 50 мл",
      "type": "SKU",
      "weight_plan": 67
    },
    {
      "article": "171695",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290017409665",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "171695",
      "fragile": false,
      "id": "0Q-171695",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель `SEA OF SPA` `BIOSPA` с экстрактом алоэ вера 100 мл",
      "type": "SKU",
      "weight_fact": 105,
      "weight_plan": 105
    },
    {
      "article": "220845",
      "barcodes": [
        {
          "active": true,
          "barcode": "4810438027304",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 90,
        "width": 40
      },
      "expiration_months": 30,
      "external_id": "220845",
      "fragile": false,
      "id": "0Q-220845",
      "images": [],
      "manufacturer": "ООО РЕЛУИ БЕЛ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем тональный для лица `RELOUIS` `RELOUIS PRO` FACE&BODY FOUNDATION 24H SPF30 тон 2N средний нейтральный",
      "type": "SKU",
      "weight_plan": 42
    },
    {
      "article": "162908",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010713628",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104702707",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "162908",
      "fragile": false,
      "id": "0Q-162908",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей-термозащита для укладки волос `KENSUKO` с морской солью 50 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "226963",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971451754",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 260,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "226963",
      "fragile": false,
      "id": "0Q-226963",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `SYNERGETIC` универсальное 500 мл",
      "type": "SKU",
      "weight_plan": 485
    },
    {
      "article": "195961",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159426526",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 150,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 150,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "195961",
      "fragile": false,
      "id": "0Q-195961",
      "images": [],
      "manufacturer": "VIOKOX, S.A.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для депиляции интимных зон `VOX` 75 мл",
      "type": "SKU",
      "weight_fact": 110,
      "weight_plan": 110
    },
    {
      "article": "230110",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196514562",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 120,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 30
      },
      "expiration_months": 1,
      "external_id": "230110",
      "fragile": false,
      "id": "0Q-230110",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Щетка зубная `LP CARE` DENTAL складная голубая",
      "type": "SKU",
      "weight_fact": 14,
      "weight_plan": 14
    },
    {
      "article": "65094",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400000257516",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "7332531019330",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4015000937092",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 130,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "65094",
      "fragile": false,
      "id": "0Q-65094",
      "images": [],
      "manufacturer": "ЛАБ-ИНДАСТРИЗ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Стайлинг-пудра `GOT2B` пороШОК (для объема) 10 г",
      "type": "SKU",
      "weight_plan": 37
    },
    {
      "article": "159328",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809486360866",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "8809486362297",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 140,
        "width": 170
      },
      "expiration_months": 36,
      "external_id": "159328",
      "fragile": false,
      "id": "0Q-159328",
      "images": [],
      "manufacturer": "C&TECH CORP",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BANOBAGI` VITA GENIC Отбеливающая 30 мл",
      "type": "SKU",
      "weight_plan": 36
    },
    {
      "article": "174388",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160535874",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104780040",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 140,
        "width": 100
      },
      "expiration_months": 24,
      "external_id": "174388",
      "fragile": false,
      "id": "0Q-174388",
      "images": [],
      "manufacturer": "ГРАНД А. В. ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки влажные `LP CARE` DEO дезодорирующие 15 шт",
      "type": "SKU",
      "weight_plan": 64
    },
    {
      "article": "185861",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010710436",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400104887138",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 150,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "185861",
      "fragile": false,
      "id": "0Q-185861",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Парфюмерная вода `BESTIES` PERFUME SHIMMER sheer beauty (жен.) 50 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_plan": 213
    },
    {
      "article": "133295",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901018014346",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5901018013325",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 170,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 170,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "133295",
      "fragile": false,
      "id": "0Q-133295",
      "images": [],
      "manufacturer": "LABORATORIUM KOSMETYCZNE JOANNA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Краска для волос `JOANNA` MULTI CREAM 3D Пламенный рыжий (тон 43)",
      "type": "SKU",
      "weight_fact": 178,
      "weight_plan": 178
    },
    {
      "article": "213312",
      "barcodes": [
        {
          "active": true,
          "barcode": "6221155115232",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 50,
        "width": 80
      },
      "expiration_months": 1,
      "external_id": "213312",
      "fragile": false,
      "id": "0Q-213312",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло твердое `CAMAY` Японская сакура 85 гр",
      "type": "SKU",
      "weight_plan": 85
    },
    {
      "article": "227022",
      "barcodes": [
        {
          "active": true,
          "barcode": "4815038045130",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 150,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "227022",
      "fragile": false,
      "id": "0Q-227022",
      "images": [],
      "manufacturer": "ЛЮБО ДЕЛО ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `ЛЮБО ДЕЛО` для стиральной машины (2 стика)",
      "type": "SKU",
      "weight_plan": 240
    },
    {
      "article": "242337",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196524882",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 100,
        "width": 60
      },
      "expiration_months": 0,
      "external_id": "242337",
      "fragile": false,
      "id": "0Q-242337",
      "images": [],
      "manufacturer": "КОРОЛЕВА НАЙЛЯ РАИСОВНА ИП",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Наклейки для ногтей `PINK UP` `DECOR` GRUNGE COLLECTION 2D переводные тон 189",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "231673",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196515804",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 130,
        "height": 130,
        "width": 130
      },
      "dimensions_plan": {
        "depth": 130,
        "height": 130,
        "width": 130
      },
      "expiration_months": 1,
      "external_id": "231673",
      "fragile": false,
      "id": "0Q-231673",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мочалка-шар для тела `DECO.` (Pink cloud)",
      "type": "SKU",
      "weight_fact": 44,
      "weight_plan": 44
    },
    {
      "article": "138825",
      "barcodes": [
        {
          "active": true,
          "barcode": "8807779089463",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 110,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 110,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "138825",
      "fragile": false,
      "id": "0Q-138825",
      "images": [],
      "manufacturer": "DOORI COSMETICS CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `EGG PLANET` с кератином 30 мл",
      "type": "SKU",
      "weight_fact": 39,
      "weight_plan": 39
    },
    {
      "article": "245850",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329046800",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 150,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "245850",
      "fragile": false,
      "id": "0Q-245850",
      "images": [],
      "manufacturer": "NINGBO EYECOS COSMETIC CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш для бровей `LUXVISAGE` BROWISSIMO Ultra slim super stay 24H механический тон 311 Taupe",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "201031",
      "barcodes": [
        {
          "active": true,
          "barcode": "6970908432576",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 70,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 70,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "201031",
      "fragile": false,
      "id": "0Q-201031",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Прокладки ежедневные `OBB` дышащие 30 шт",
      "type": "SKU",
      "weight_fact": 56,
      "weight_plan": 56
    },
    {
      "article": "173301",
      "barcodes": [
        {
          "active": true,
          "barcode": "8410262902080",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "173301",
      "fragile": false,
      "id": "0Q-173301",
      "images": [],
      "manufacturer": "NUVARIA GLOBAL, S.L.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для душа `DICORA URBAN FIT` Молоко и дыня 400 мл",
      "type": "SKU",
      "weight_plan": 457
    },
    {
      "article": "230121",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196514678",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 260,
        "width": 190
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 260,
        "width": 190
      },
      "expiration_months": 1,
      "external_id": "230121",
      "fragile": false,
      "id": "0Q-230121",
      "images": [],
      "manufacturer": "GUANGZHOU LISUN INTERNATIONAL COMMERCE DEVELOPMENT CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Порошок стиральный `LELEA` Тропический микс 1 кг",
      "type": "SKU",
      "weight_fact": 1050,
      "weight_plan": 1050
    },
    {
      "article": "242333",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196524844",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 100,
        "width": 60
      },
      "expiration_months": 0,
      "external_id": "242333",
      "fragile": false,
      "id": "0Q-242333",
      "images": [],
      "manufacturer": "КОРОЛЕВА НАЙЛЯ РАИСОВНА ИП",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Наклейки для ногтей `PINK UP` `DECOR` GRUNGE COLLECTION 2D переводные тон 185",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "206440",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809589393280",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 40
      },
      "expiration_months": 1,
      "external_id": "206440",
      "fragile": false,
      "id": "0Q-206440",
      "images": [],
      "manufacturer": "COSTEM CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `ESFOLIO` с пептидами (восстанавливающий) 50 мл",
      "type": "SKU",
      "weight_plan": 66
    },
    {
      "article": "138845",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806185733380",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 120,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "138845",
      "fragile": false,
      "id": "0Q-138845",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ночная маска для лица `A`PIEU` FRESH MATE MASK успокаивающая с маслом чайного дерева 50 мл",
      "type": "SKU",
      "weight_fact": 64,
      "weight_plan": 64
    },
    {
      "article": "241001",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523564",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 100,
        "height": 100,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 130,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "241001",
      "fragile": false,
      "id": "0Q-241001",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Подводка для глаз `ISCREAM` FLOWER FLOW тон 04 red с глиттером",
      "type": "SKU",
      "weight_fact": 100,
      "weight_plan": 11
    },
    {
      "article": "243718",
      "barcodes": [
        {
          "active": true,
          "barcode": "6291109034865",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4610044201644",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 130,
        "width": 80
      },
      "expiration_months": 72,
      "external_id": "243718",
      "fragile": false,
      "id": "0Q-243718",
      "images": [],
      "manufacturer": "ПОНТИ ПАРФЮМ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Парфюмерная вода `EQUIVALENT` F028 (жен.) 50 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_plan": 216
    },
    {
      "article": "150476",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016167962",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 140,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 140,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "150476",
      "fragile": false,
      "id": "0Q-150476",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сыворотка для лица и кожи вокруг глаз `SEA OF SPA` GOLD BENEFITS с экстрактом зеленого чая и гиалуроновой кислотой 30 мл",
      "type": "SKU",
      "weight_fact": 130,
      "weight_plan": 130
    },
    {
      "article": "225609",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809301768105",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "225609",
      "fragile": false,
      "id": "0Q-225609",
      "images": [],
      "manufacturer": "ANCORS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гидрофильное масло для лица `ARIUL` APPLE CIDER с яблочным уксусом 200 мл",
      "type": "SKU",
      "weight_plan": 239
    },
    {
      "article": "211872",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640018992322",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 70,
        "width": 130
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 70,
        "width": 130
      },
      "expiration_months": 30,
      "external_id": "211872",
      "fragile": false,
      "id": "0Q-211872",
      "images": [],
      "manufacturer": "RECKITT BENCKISER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тест для определения беременности `EVITEST` №1",
      "type": "SKU",
      "weight_fact": 10,
      "weight_plan": 10
    },
    {
      "article": "204547",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809698220330",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "204547",
      "fragile": false,
      "id": "0Q-204547",
      "images": [],
      "manufacturer": "MEDB CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `MED B` 1 DAY с экстрактом чайного дерева (успокаивающая) 27 мл",
      "type": "SKU",
      "weight_plan": 30
    },
    {
      "article": "226959",
      "barcodes": [
        {
          "active": true,
          "barcode": "4623721671449",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 230,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 80
      },
      "expiration_months": 1,
      "external_id": "226959",
      "fragile": false,
      "id": "0Q-226959",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для мытья посуды `SYNERGETIC` с ароматом яблока 500 мл",
      "type": "SKU",
      "weight_fact": 520,
      "weight_plan": 520
    },
    {
      "article": "203420",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809581460294",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "203420",
      "fragile": false,
      "id": "0Q-203420",
      "images": [],
      "manufacturer": "COSMAX INC.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мист для волос `A`PIEU` RASPBERRY с малиновым уксусом 100 мл",
      "type": "SKU",
      "weight_fact": 134,
      "weight_plan": 134
    },
    {
      "article": "53514",
      "barcodes": [
        {
          "active": true,
          "barcode": "4820190620019",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4607051790742",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 90,
        "width": 90
      },
      "expiration_months": 24,
      "external_id": "53514",
      "fragile": false,
      "id": "0Q-53514",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Порошок зубной `ФИТОКОСМЕТИК` Мятный 75 г",
      "type": "SKU",
      "weight_plan": 113
    },
    {
      "article": "138846",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806185733397",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "138846",
      "fragile": false,
      "id": "0Q-138846",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ночная маска для лица `A`PIEU` FRESH MATE MASK увлажняющая с экстрактом базилика и баобаба 50 мл",
      "type": "SKU",
      "weight_plan": 65
    },
    {
      "article": "242848",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196525810",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "242848",
      "fragile": false,
      "id": "0Q-242848",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `LIMITED` FLOWER BLOOM тон 02 10 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "214342",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329037402",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 110,
        "width": 50
      },
      "expiration_months": 31,
      "external_id": "214342",
      "fragile": false,
      "id": "0Q-214342",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем тональный для лица `LUXVISAGE` CC CREAM active complex SPF 10 тон 10 fair",
      "type": "SKU",
      "weight_plan": 44
    },
    {
      "article": "227025",
      "barcodes": [
        {
          "active": true,
          "barcode": "4602984003402",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 290,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 290,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "227025",
      "fragile": false,
      "id": "0Q-227025",
      "images": [],
      "manufacturer": "СТУПИНСКИЙ ХИМИЧЕСКИЙ ЗАВОД АО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `SANFOR` для труб 750 мл",
      "type": "SKU",
      "weight_fact": 809,
      "weight_plan": 809
    },
    {
      "article": "227041",
      "barcodes": [
        {
          "active": true,
          "barcode": "4602984013937",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 250,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 250,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "227041",
      "fragile": false,
      "id": "0Q-227041",
      "images": [],
      "manufacturer": "СТУПИНСКИЙ ХИМИЧЕСКИЙ ЗАВОД АО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `SANITA` универсальное с отбеливающим эффектом 600 мл",
      "type": "SKU",
      "weight_fact": 649,
      "weight_plan": 649
    },
    {
      "article": "232849",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196516665",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 170,
        "width": 150
      },
      "expiration_months": 1,
      "external_id": "232849",
      "fragile": false,
      "id": "0Q-232849",
      "images": [],
      "manufacturer": "КОРОЛЕВА НАЙЛЯ РАИСОВНА ИП",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кристаллы для лица и тела `DECO.` CRYSTAL MYSTERY by Miami Tattoos (Show drops)",
      "type": "SKU",
      "weight_plan": 10
    },
    {
      "article": "228614",
      "barcodes": [
        {
          "active": true,
          "barcode": "5900095012856",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 350,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "228614",
      "fragile": false,
      "id": "0Q-228614",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Диски ватные `CLEANIC` SOFT&COMFORT гигиенические +33% 133 шт",
      "type": "SKU",
      "weight_plan": 67
    },
    {
      "article": "138838",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806185725774",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 160,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "138838",
      "fragile": false,
      "id": "0Q-138838",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка для умывания `A`PIEU` `DEEP CLEAN` 130 мл",
      "type": "SKU",
      "weight_fact": 160,
      "weight_plan": 160
    },
    {
      "article": "238017",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630234042925",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 210,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 70
      },
      "expiration_months": 24,
      "external_id": "238017",
      "fragile": false,
      "id": "0Q-238017",
      "images": [],
      "manufacturer": "ГЛОБАЛ БИО КОСМЕТИК ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мягкий гель для интимной гигиены `INTIMLY` EUPHORIA с натуральными афродизиаками (персик и ваниль) 220 мл",
      "type": "SKU",
      "weight_fact": 291,
      "weight_plan": 291
    },
    {
      "article": "153263",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901761961867",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "153263",
      "fragile": false,
      "id": "0Q-153263",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем тональный для лица `EVELINE` LIQUID CONTROL тон 15 жидкий",
      "type": "SKU",
      "weight_plan": 121
    },
    {
      "article": "72343",
      "barcodes": [
        {
          "active": true,
          "barcode": "5907609369840",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5907609332486",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 100,
        "height": 50,
        "width": 110
      },
      "expiration_months": 36,
      "external_id": "72343",
      "fragile": false,
      "id": "0Q-72343",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для тела `EVELINE` ФИТО ЛИНИЯ Оливки + протеины шелка 210 мл",
      "type": "SKU",
      "weight_plan": 254
    },
    {
      "article": "232577",
      "barcodes": [
        {
          "active": true,
          "barcode": "5904181932257",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5904181932370",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 190,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "232577",
      "fragile": false,
      "id": "0Q-232577",
      "images": [],
      "manufacturer": "4MYORGANIC SP.Z.O.O. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для интимной гигиены гипоаллергенный `4ORGANIC` Hyaluronic Acid 350 мл",
      "type": "SKU",
      "weight_plan": 398
    },
    {
      "article": "231663",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809581450653",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 130,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "231663",
      "fragile": false,
      "id": "0Q-231663",
      "images": [],
      "manufacturer": "COSMECCA KOREA CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `A`PIEU` PURE BLOCK солнцезащитный SPF-50+ (матирующий) 50 мл",
      "type": "SKU",
      "weight_plan": 70
    },
    {
      "article": "189709",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160530220",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 100,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 100,
        "width": 100
      },
      "expiration_months": 0,
      "external_id": "189709",
      "fragile": false,
      "id": "0Q-189709",
      "images": [],
      "manufacturer": "NINGBO KAILI HOLDING GROUP CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кассеты для станка `VOX` 5 лезвий 3 шт",
      "type": "SKU",
      "weight_fact": 33,
      "weight_plan": 33
    },
    {
      "article": "234974",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809747954285",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "234974",
      "fragile": false,
      "id": "0Q-234974",
      "images": [],
      "manufacturer": "COSMECCA KOREA CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тонер для лица `A`PIEU` TEA TREE успокаивающий 210 мл",
      "type": "SKU",
      "weight_plan": 235
    },
    {
      "article": "206223",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329034784",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 120,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "206223",
      "fragile": false,
      "id": "0Q-206223",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Основа под макияж `LUXVISAGE` PRIME EXPERT PORE FILLER выравнивающая (прозрачная)",
      "type": "SKU",
      "weight_fact": 44,
      "weight_plan": 44
    },
    {
      "article": "133290",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901018014254",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5901018013233",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 170,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "133290",
      "fragile": false,
      "id": "0Q-133290",
      "images": [],
      "manufacturer": "LABORATORIUM KOSMETYCZNE JOANNA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Краска для волос `JOANNA` MULTI CREAM 3D Красная вишня (тон 35)",
      "type": "SKU",
      "weight_plan": 180
    },
    {
      "article": "226293",
      "barcodes": [
        {
          "active": true,
          "barcode": "9000100653503",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 50
      },
      "expiration_months": 10,
      "external_id": "226293",
      "fragile": false,
      "id": "0Q-226293",
      "images": [],
      "manufacturer": "ЛАБ-ИНДАСТРИЗ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для волос `ТАФТ` POWER Fullness for fine hair (5) 250 мл",
      "type": "SKU",
      "weight_plan": 242
    },
    {
      "article": "222797",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196507267",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 80,
        "height": 120,
        "width": 190
      },
      "expiration_months": 1,
      "external_id": "222797",
      "fragile": false,
      "id": "0Q-222797",
      "images": [],
      "manufacturer": "GUANGZHOU LISUN INTERNATIONAL COMMERCE DEVELOPMENT CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Капсулы для стирки `LELEA` Лимонная свежесть 52 шт",
      "type": "SKU",
      "weight_plan": 494
    },
    {
      "article": "228307",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290018306284",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 70,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 70,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "228307",
      "fragile": false,
      "id": "0Q-228307",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `SEA OF SPA` CANNABIO дневной 60 мл",
      "type": "SKU",
      "weight_fact": 174,
      "weight_plan": 174
    },
    {
      "article": "206849",
      "barcodes": [
        {
          "active": true,
          "barcode": "4810438024068",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 110,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "206849",
      "fragile": false,
      "id": "0Q-206849",
      "images": [],
      "manufacturer": "ООО РЕЛУИ БЕЛ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для бровей `RELOUIS` `RELOUIS PRO` WATERPROOF COLOR BROW GEL водостойкий тон 02 tаupe",
      "type": "SKU",
      "weight_plan": 12
    },
    {
      "article": "125042",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901018977467",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5901018016074",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5901018015640",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400104442498",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "125042",
      "fragile": false,
      "id": "0Q-125042",
      "images": [],
      "manufacturer": "LABORATORIUM KOSMETYCZNE JOANNA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло жидкое для интимной гигиены `JOANNA` с пребиотиками 200 мл",
      "type": "SKU",
      "weight_fact": 235,
      "weight_plan": 235
    },
    {
      "article": "211853",
      "barcodes": [
        {
          "active": true,
          "barcode": "5052197036107",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 130,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 130,
        "width": 70
      },
      "expiration_months": 60,
      "external_id": "211853",
      "fragile": false,
      "id": "0Q-211853",
      "images": [],
      "manufacturer": "RECKITT BENCKISER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Презервативы `CONTEX` Extra Sensation (с крупными точками и ребрами) 12 шт",
      "type": "SKU",
      "weight_fact": 37,
      "weight_plan": 37
    },
    {
      "article": "147374",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016846799",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 210,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "147374",
      "fragile": false,
      "id": "0Q-147374",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для волос `SEA OF SPA` `BIOSPA` с кератином и маслом макадамии 400 мл",
      "type": "SKU",
      "weight_fact": 488,
      "weight_plan": 488
    },
    {
      "article": "206936",
      "barcodes": [
        {
          "active": true,
          "barcode": "4810438021135",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 40,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "206936",
      "fragile": false,
      "id": "0Q-206936",
      "images": [],
      "manufacturer": "ООО РЕЛУИ БЕЛ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пудра рассыпчатая для лица `RELOUIS` `RELOUIS PRO` HD POWDER фиксирующая прозрачная",
      "type": "SKU",
      "weight_plan": 52
    },
    {
      "article": "196016",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159426793",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 200,
        "width": 70
      },
      "expiration_months": 0,
      "external_id": "196016",
      "fragile": false,
      "id": "0Q-196016",
      "images": [],
      "manufacturer": "YANGJIANG CITY YANGDONG DISTRICT JUNHE INDUSTRY & TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Инструмент для чистки лица `MORITZ` WAVE двусторонний",
      "type": "SKU",
      "weight_plan": 27
    },
    {
      "article": "179674",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010713673",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104823716",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "179674",
      "fragile": false,
      "id": "0Q-179674",
      "images": [],
      "manufacturer": "AEROFA AEROSOL DOLUM SAN. TIC. A.S.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `KENSUKO` DETOXIFY (сухой) 200 мл",
      "type": "SKU",
      "weight_plan": 156
    },
    {
      "article": "228050",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809606850475",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 150,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 150,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "228050",
      "fragile": false,
      "id": "0Q-228050",
      "images": [],
      "manufacturer": "NFC CORPORATION",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тонер для лица `CELIMAX` кремовый с комплексом керамидов (укрепляющий и питательный) 150 мл",
      "type": "SKU",
      "weight_fact": 220,
      "weight_plan": 220
    },
    {
      "article": "226968",
      "barcodes": [
        {
          "active": true,
          "barcode": "4613720439041",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 270,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "226968",
      "fragile": false,
      "id": "0Q-226968",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `SYNERGETIC` от засоров труб 1000 мл",
      "type": "SKU",
      "weight_plan": 1220
    },
    {
      "article": "215016",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806325620686",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 90,
        "height": 260,
        "width": 160
      },
      "expiration_months": 36,
      "external_id": "215016",
      "fragile": false,
      "id": "0Q-215016",
      "images": [],
      "manufacturer": "LION CORPORATION (KOREA)",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для стирки `LION` жидкое концентрированное 1,45 л",
      "type": "SKU",
      "weight_plan": 1650
    },
    {
      "article": "13650",
      "barcodes": [
        {
          "active": true,
          "barcode": "4056800414079",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4056800114078",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 220,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 220,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "13650",
      "fragile": false,
      "id": "0Q-13650",
      "images": [],
      "manufacturer": "INTERSPRAY",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для волос `WELLA` `WELLAFLEX` объем для тонких волос 250 мл",
      "type": "SKU",
      "weight_fact": 244,
      "weight_plan": 244
    },
    {
      "article": "225734",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196510151",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 100,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 100,
        "width": 30
      },
      "expiration_months": 1,
      "external_id": "225734",
      "fragile": false,
      "id": "0Q-225734",
      "images": [],
      "manufacturer": "COSMEWAX, S.A.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Капли для лица `SUN LOOK` бронзирующие 15 мл",
      "type": "SKU",
      "weight_fact": 56,
      "weight_plan": 56
    },
    {
      "article": "196512",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196500725",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 200,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "196512",
      "fragile": false,
      "id": "0Q-196512",
      "images": [],
      "manufacturer": "AEROFA AEROSOL DOLUM SAN. TIC. A.S.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для бритья `VOX` ALOE VERA 200 мл",
      "type": "SKU",
      "weight_plan": 200
    },
    {
      "article": "166529",
      "barcodes": [
        {
          "active": true,
          "barcode": "5060315052960",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4640010710344",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 160,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "166529",
      "fragile": false,
      "id": "0Q-166529",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Парфюмерная вода `BESTIES` PERFUME SPRAY spring woods (жен.) 30 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_plan": 66
    },
    {
      "article": "226960",
      "barcodes": [
        {
          "active": true,
          "barcode": "4623722258281",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 230,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 80
      },
      "expiration_months": 1,
      "external_id": "226960",
      "fragile": false,
      "id": "0Q-226960",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для мытья посуды `SYNERGETIC` с ароматом арбуза 500 мл",
      "type": "SKU",
      "weight_fact": 520,
      "weight_plan": 520
    },
    {
      "article": "242850",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196525834",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "242850",
      "fragile": false,
      "id": "0Q-242850",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `LIMITED` FLOWER BLOOM тон 04 10 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "211741",
      "barcodes": [
        {
          "active": true,
          "barcode": "5903416027164",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 120,
        "width": 20
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 20
      },
      "expiration_months": 31,
      "external_id": "211741",
      "fragile": false,
      "id": "0Q-211741",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Консилер для лица `EVELINE` WONDER MATCH кремовый тон 015 natural",
      "type": "SKU",
      "weight_fact": 35,
      "weight_plan": 35
    },
    {
      "article": "221407",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809517476436",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 120,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "221407",
      "fragile": false,
      "id": "0Q-221407",
      "images": [],
      "manufacturer": "KOLMAR Korea Co., Ltd.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `DEWYTREE` URBAN SHADE солнцезащитный SPF50+ (увлажняющий) 50 мл",
      "type": "SKU",
      "weight_plan": 63
    },
    {
      "article": "147375",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016846805",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 110,
        "height": 80,
        "width": 110
      },
      "expiration_months": 36,
      "external_id": "147375",
      "fragile": false,
      "id": "0Q-147375",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `SEA OF SPA` `BIOSPA` с кератином и маслом макадамии 500 мл",
      "type": "SKU",
      "weight_plan": 446
    },
    {
      "article": "179605",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104822955",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4630160534952",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "179605",
      "fragile": false,
      "id": "0Q-179605",
      "images": [],
      "manufacturer": "GREENCOS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель-спрей для лица и тела `SUN LOOK` увлажняющий с арбузом 118 г",
      "type": "SKU",
      "weight_fact": 148,
      "weight_plan": 148
    },
    {
      "article": "245849",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329046794",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 150,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "245849",
      "fragile": false,
      "id": "0Q-245849",
      "images": [],
      "manufacturer": "NINGBO EYECOS COSMETIC CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш для бровей `LUXVISAGE` BROWISSIMO Ultra slim super stay 24H механический тон 310 Soft brown",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "228306",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290018306277",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 60,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 60,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "228306",
      "fragile": false,
      "id": "0Q-228306",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Масло для тела `SEA OF SPA` CANNABIO 250 мл",
      "type": "SKU",
      "weight_fact": 317,
      "weight_plan": 317
    },
    {
      "article": "206439",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809589393297",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 40
      },
      "expiration_months": 1,
      "external_id": "206439",
      "fragile": false,
      "id": "0Q-206439",
      "images": [],
      "manufacturer": "COSTEM CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `ESFOLIO` с экстрактом центеллы азиатской (успокаивающий) 50 мл",
      "type": "SKU",
      "weight_plan": 66
    },
    {
      "article": "232744",
      "barcodes": [
        {
          "active": true,
          "barcode": "4810438027908",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 120,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 120,
        "width": 30
      },
      "expiration_months": 1,
      "external_id": "232744",
      "fragile": false,
      "id": "0Q-232744",
      "images": [],
      "manufacturer": "ООО РЕЛУИ БЕЛ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для бровей `RELOUIS` ALL DAY FIXING фиксирующий тон прозрачный",
      "type": "SKU",
      "weight_fact": 21,
      "weight_plan": 21
    },
    {
      "article": "190459",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159420944",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 90,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "190459",
      "fragile": false,
      "id": "0Q-190459",
      "images": [],
      "manufacturer": "ЗЕТТЕКНОЛОДЖИ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки влажные детские `LP CARE` Creme-Brulee 8 шт",
      "type": "SKU",
      "weight_plan": 32
    },
    {
      "article": "201489",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809632521820",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "8809540519421",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "201489",
      "fragile": false,
      "id": "0Q-201489",
      "images": [],
      "manufacturer": "EZEKIEL COSMETICS CO.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `PACK AGE` Oasis (супер увлажняющая) 20 г",
      "type": "SKU",
      "weight_fact": 30,
      "weight_plan": 30
    },
    {
      "article": "125478",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901761914283",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104445796",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 100,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 100,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "125478",
      "fragile": false,
      "id": "0Q-125478",
      "images": [],
      "manufacturer": "Eveline Cosmetics S.A. sp.k.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Эмульсия для тела `SUN LOOK` солнцезащитная водостойкая SPF-30 mini 30 мл",
      "type": "SKU",
      "weight_fact": 40,
      "weight_plan": 40
    },
    {
      "article": "229199",
      "barcodes": [
        {
          "active": true,
          "barcode": "4009175953667",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 280,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "229199",
      "fragile": false,
      "id": "0Q-229199",
      "images": [],
      "manufacturer": "FROSCH",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `FROSCH` для ванны и душа, виноград 500 мл",
      "type": "SKU",
      "weight_plan": 589
    },
    {
      "article": "242853",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196525865",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "242853",
      "fragile": false,
      "id": "0Q-242853",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `LIMITED` FLOWER BLOOM тон 07 10 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "242852",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196525858",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "242852",
      "fragile": false,
      "id": "0Q-242852",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `LIMITED` FLOWER BLOOM тон 06 10 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "222579",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196506758",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 140,
        "width": 10
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 140,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "222579",
      "fragile": false,
      "id": "0Q-222579",
      "images": [],
      "manufacturer": "NANJING MISS BEAUTY COSMETICS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маркер для веснушек `ISCREAM` BASE (тон 02)",
      "type": "SKU",
      "weight_fact": 3,
      "weight_plan": 3
    },
    {
      "article": "212301",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809663574826",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 90,
        "height": 70,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "212301",
      "fragile": false,
      "id": "0Q-212301",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `IT`S SKIN` RETINOIDIN с ретинолом и пептидами (anti-age) 100 мл",
      "type": "SKU",
      "weight_plan": 208
    },
    {
      "article": "171693",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290017409672",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "171693",
      "fragile": false,
      "id": "0Q-171693",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем `SEA OF SPA` `BIOSPA` с экстрактом граната 100 мл",
      "type": "SKU",
      "weight_fact": 106,
      "weight_plan": 106
    },
    {
      "article": "154453",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809386885803",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "154453",
      "fragile": false,
      "id": "0Q-154453",
      "images": [],
      "manufacturer": "COSTEM CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мист для лица `ESFOLIO` NUTRI SNAIL с экстрактом муцина улитки 150 мл",
      "type": "SKU",
      "weight_plan": 214
    },
    {
      "article": "196420",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196500077",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 80,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "196420",
      "fragile": false,
      "id": "0Q-196420",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло листовое `LP CARE` с ароматом черники 20 шт",
      "type": "SKU",
      "weight_plan": 8
    },
    {
      "article": "191896",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159422344",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "191896",
      "fragile": false,
      "id": "0Q-191896",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Парфюмированный мист для тела `BESTIES` SHIMMER MIST velvet sugar 88 мл",
      "type": "SKU",
      "weight_plan": 112
    },
    {
      "article": "243780",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196526596",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 80,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "243780",
      "fragile": false,
      "id": "0Q-243780",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Энзимная пудра для умывания `LP CARE` 50 г",
      "type": "SKU",
      "weight_plan": 80
    },
    {
      "article": "210418",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809443255037",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 100,
        "width": 140
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 100,
        "width": 140
      },
      "expiration_months": 36,
      "external_id": "210418",
      "fragile": false,
      "id": "0Q-210418",
      "images": [],
      "manufacturer": "MLS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `SHINETREE` с экстрактом лимона и ниацинамидом (для сияния кожи) 15 шт",
      "type": "SKU",
      "weight_fact": 239,
      "weight_plan": 239
    },
    {
      "article": "196364",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159429442",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 60,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 60,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "196364",
      "fragile": false,
      "id": "0Q-196364",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `MINI GEL` тон 30 5 мл",
      "type": "SKU",
      "weight_fact": 31,
      "weight_plan": 31
    },
    {
      "article": "242246",
      "barcodes": [
        {
          "active": true,
          "barcode": "3574661684291",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 180,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "242246",
      "fragile": false,
      "id": "0Q-242246",
      "images": [],
      "manufacturer": "JOHNSON & JOHNSON",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ополаскиватель для полости рта `LISTERINE` FLAVOURS Mild & Minty 250 мл",
      "type": "SKU",
      "weight_plan": 291
    },
    {
      "article": "206216",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329035583",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 130,
        "width": 10
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 130,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "206216",
      "fragile": false,
      "id": "0Q-206216",
      "images": [],
      "manufacturer": "SCHWAN COSMETICS GERMANY GMBH & CO. KG",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш для губ `LUXVISAGE` `PIN UP` ULTRA MATT матовый тон 211 muse",
      "type": "SKU",
      "weight_fact": 3,
      "weight_plan": 3
    },
    {
      "article": "188403",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010715219",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 110,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 110,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "188403",
      "fragile": false,
      "id": "0Q-188403",
      "images": [],
      "manufacturer": "CHUANGJIA (FUJIAN) HYGIENE PRODUCTS TECHNOLOGY CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Прокладки классические `LP CARE` ультратонкие с ромашкой 8 шт",
      "type": "SKU",
      "weight_fact": 61,
      "weight_plan": 61
    },
    {
      "article": "191904",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159422429",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 110,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 110,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "191904",
      "fragile": false,
      "id": "0Q-191904",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Парфюмированный мист для тела `BESTIES` BODY MIST rocking fantasy 30 мл",
      "type": "SKU",
      "weight_fact": 43,
      "weight_plan": 43
    },
    {
      "article": "170270",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160530534",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104752429",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 150,
        "width": 110
      },
      "expiration_months": 0,
      "external_id": "170270",
      "fragile": false,
      "id": "0Q-170270",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Палочки ватные `LP CARE` для коррекции макияжа и маникюра 100 шт",
      "type": "SKU",
      "weight_plan": 59
    },
    {
      "article": "139578",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196515194",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4897045272914",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4640010716476",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 210,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 210,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "139578",
      "fragile": false,
      "id": "0Q-139578",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей для укладки волос `KENSUKO` Супер объем 200 мл",
      "type": "SKU",
      "weight_fact": 234,
      "weight_plan": 234
    },
    {
      "article": "204870",
      "barcodes": [
        {
          "active": true,
          "barcode": "5903416029441",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 80,
        "height": 80,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "204870",
      "fragile": false,
      "id": "0Q-204870",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `EVELINE` RICH COCONUT интенсивно увлажняющий 50 мл",
      "type": "SKU",
      "weight_plan": 193
    },
    {
      "article": "245847",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329045476",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 70
      },
      "expiration_months": 31,
      "external_id": "245847",
      "fragile": false,
      "id": "0Q-245847",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пудра для лица `LUXVISAGE` HD BLUR Soft Focus рассыпчатая светоотражающая тон Универсальный",
      "type": "SKU",
      "weight_plan": 43
    },
    {
      "article": "201909",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329023924",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4811329047012",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 80,
        "height": 20,
        "width": 80
      },
      "expiration_months": 24,
      "external_id": "201909",
      "fragile": false,
      "id": "0Q-201909",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пудра компактная для лица `LUXVISAGE` EXPERT MATT фиксирующая тон бесцветный",
      "type": "SKU",
      "weight_plan": 40
    },
    {
      "article": "210417",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809443255013",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 100,
        "width": 140
      },
      "expiration_months": 36,
      "external_id": "210417",
      "fragile": false,
      "id": "0Q-210417",
      "images": [],
      "manufacturer": "MLS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `SHINETREE` с экстрактом центеллы азиатской и хауттюйнии (успокаивающая) 15 шт",
      "type": "SKU",
      "weight_plan": 239
    },
    {
      "article": "136394",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806325611493",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 170,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "136394",
      "fragile": false,
      "id": "0Q-136394",
      "images": [],
      "manufacturer": "LION CORPORATION",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Паста зубная детская `LION` Виноград (от 3 до 12 лет) 90 г",
      "type": "SKU",
      "weight_plan": 112
    },
    {
      "article": "226965",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971450207",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 250,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "226965",
      "fragile": false,
      "id": "0Q-226965",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для мытья пола и поверхностей `SYNERGETIC` Аромамагия 750 мл",
      "type": "SKU",
      "weight_plan": 760
    },
    {
      "article": "226381",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196510922",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 160,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 160,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "226381",
      "fragile": false,
      "id": "0Q-226381",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сменная насадка для электрической зубной щетки `LP CARE` DENTAL standard clean",
      "type": "SKU",
      "weight_fact": 15,
      "weight_plan": 15
    },
    {
      "article": "206345",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600999039454",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 240,
        "width": 140
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 240,
        "width": 140
      },
      "expiration_months": 37,
      "external_id": "206345",
      "fragile": false,
      "id": "0Q-206345",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-мыло `Я САМАЯ` ECO BALANCE с экстрактом льна (дой-пак) 500 мл",
      "type": "SKU",
      "weight_fact": 436,
      "weight_plan": 436
    },
    {
      "article": "237877",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196520471",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 100,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "237877",
      "fragile": false,
      "id": "0Q-237877",
      "images": [],
      "manufacturer": "ERTE KOZMETIK SANAYI VE TICARET A.S",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Парфюмерная вода `BESTIES` KISS4YOU love enough (жен.) 25 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_plan": 107
    },
    {
      "article": "220578",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196506246",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 40,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 40,
        "width": 60
      },
      "expiration_months": 0,
      "external_id": "220578",
      "fragile": false,
      "id": "0Q-220578",
      "images": [],
      "manufacturer": "DALIAN WSS ARTS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки для снятия лака и гель-лака `PINK UP` `DAILY` персик 30 шт",
      "type": "SKU",
      "weight_fact": 37,
      "weight_plan": 37
    },
    {
      "article": "211768",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971451310",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 200,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "211768",
      "fragile": false,
      "id": "0Q-211768",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло жидкое `SYNERGETIC` Пачули и ароматный бергамот (биоразлагаемое) 380 мл",
      "type": "SKU",
      "weight_plan": 429
    },
    {
      "article": "118454",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104388482",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "8807779080668",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 190,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "118454",
      "fragile": false,
      "id": "0Q-118454",
      "images": [],
      "manufacturer": "DOORI COSMETICS CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство против выпадения волос `DAENG GI MEO RI` восстанавливающее (интенсивного действия) 145 мл",
      "type": "SKU",
      "weight_plan": 176
    },
    {
      "article": "245845",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329044769",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 130,
        "width": 20
      },
      "expiration_months": 24,
      "external_id": "245845",
      "fragile": false,
      "id": "0Q-245845",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Блеск-бальзам для губ `LUXVISAGE` JELLY Mint Охлаждающий эффект",
      "type": "SKU",
      "weight_plan": 26
    },
    {
      "article": "221428",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971453604",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 220,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 220,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "221428",
      "fragile": false,
      "id": "0Q-221428",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель-бальзам для мытья посуды `SYNERGETIC` Розмарин и листья смородины 500 мл",
      "type": "SKU",
      "weight_fact": 560,
      "weight_plan": 560
    },
    {
      "article": "210430",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809589393471",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 40
      },
      "expiration_months": 1,
      "external_id": "210430",
      "fragile": false,
      "id": "0Q-210430",
      "images": [],
      "manufacturer": "COSTEM CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сыворотка для лица `ESFOLIO` с ретинолом (разглаживающая) 30 мл",
      "type": "SKU",
      "weight_plan": 94
    },
    {
      "article": "84360",
      "barcodes": [
        {
          "active": true,
          "barcode": "6926939642149",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104116733",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 290,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "84360",
      "fragile": false,
      "id": "0Q-84360",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для волос `KENSUKO` ультра восстанавливающий 760 г",
      "type": "SKU",
      "weight_plan": 870
    },
    {
      "article": "226970",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971455158",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 80,
        "height": 270,
        "width": 160
      },
      "expiration_months": 1,
      "external_id": "226970",
      "fragile": false,
      "id": "0Q-226970",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер-ополаскиватель для белья `SYNERGETIC` Магическая орхидея 1,5 л",
      "type": "SKU",
      "weight_plan": 1497
    },
    {
      "article": "206459",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809589393006",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 80,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 80,
        "width": 30
      },
      "expiration_months": 1,
      "external_id": "206459",
      "fragile": false,
      "id": "0Q-206459",
      "images": [],
      "manufacturer": "COSTEM CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Масло для лица `ESFOLIO` 3HA c AHA,BHA и PHA - кислотами 10 мл",
      "type": "SKU",
      "weight_fact": 44,
      "weight_plan": 44
    },
    {
      "article": "225777",
      "barcodes": [
        {
          "active": true,
          "barcode": "8410262902073",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 260,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "225777",
      "fragile": false,
      "id": "0Q-225777",
      "images": [],
      "manufacturer": "NUVARIA GLOBAL, S.L.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для душа `DICORA URBAN FIT` ветивер и женьшень 750 мл",
      "type": "SKU",
      "weight_plan": 831
    },
    {
      "article": "191919",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159422573",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 80,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 80,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "191919",
      "fragile": false,
      "id": "0Q-191919",
      "images": [],
      "manufacturer": "YIWU YUN PACKING CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Блестки для лица, тела и волос `DECO.` by Miami tattoos (Pop)",
      "type": "SKU",
      "weight_fact": 20,
      "weight_plan": 20
    },
    {
      "article": "227075",
      "barcodes": [
        {
          "active": true,
          "barcode": "5060434292148",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 190,
        "height": 420,
        "width": 190
      },
      "dimensions_plan": {
        "depth": 190,
        "height": 420,
        "width": 190
      },
      "expiration_months": 1,
      "external_id": "227075",
      "fragile": false,
      "id": "0Q-227075",
      "images": [],
      "manufacturer": "LOVULAR LIMITED",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Подгузники-трусики `LOVULAR` СОЛНЕЧНАЯ СЕРИЯ L 9-14 кг 44 шт",
      "type": "SKU",
      "weight_fact": 1250,
      "weight_plan": 1250
    },
    {
      "article": "205364",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809099646067",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 160,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "205364",
      "fragile": false,
      "id": "0Q-205364",
      "images": [],
      "manufacturer": "CLIO CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Паста зубная с помпой `BLOOM` с фруктовым вкусом 300 г",
      "type": "SKU",
      "weight_fact": 376,
      "weight_plan": 376
    },
    {
      "article": "66002",
      "barcodes": [
        {
          "active": true,
          "barcode": "5907609329721",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5907609366405",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 140,
        "height": 30,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 140,
        "height": 30,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "66002",
      "fragile": false,
      "id": "0Q-66002",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Восстанавливающий комплекс для ногтей `EVELINE` бриллиантовый блеск 12 мл",
      "type": "SKU",
      "weight_fact": 59,
      "weight_plan": 59
    },
    {
      "article": "219471",
      "barcodes": [
        {
          "active": true,
          "barcode": "4660205470310",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 80
      },
      "expiration_months": 24,
      "external_id": "219471",
      "fragile": false,
      "id": "0Q-219471",
      "images": [],
      "manufacturer": "НАТУРАКОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-краска для волос `ONLY BIO COLOR` Тон 5.6 Красное дерево 115 мл",
      "type": "SKU",
      "weight_plan": 146
    },
    {
      "article": "04213",
      "barcodes": [
        {
          "active": true,
          "barcode": "4056800114047",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 220,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 220,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "04213",
      "fragile": false,
      "id": "0Q-04213",
      "images": [],
      "manufacturer": "INTERSPRAY",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для волос `WELLA` `WELLAFLEX` подвижная фиксация 250 мл",
      "type": "SKU",
      "weight_fact": 247,
      "weight_plan": 247
    },
    {
      "article": "74105",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809184250117",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 120,
        "height": 80,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 120,
        "height": 80,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "74105",
      "fragile": false,
      "id": "0Q-74105",
      "images": [],
      "manufacturer": "G.T PAPER CO., LTD PUTIAN FUJIAN",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Прокладки классические `HUMMINGS` herbal 10 шт",
      "type": "SKU",
      "weight_fact": 104,
      "weight_plan": 104
    },
    {
      "article": "237937",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610113161435",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 210,
        "width": 150
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 210,
        "width": 150
      },
      "expiration_months": 36,
      "external_id": "237937",
      "fragile": false,
      "id": "0Q-237937",
      "images": [],
      "manufacturer": "ООО БИОТЕК ФАРМ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Соль для ванн `BIOTEQ` магниевая Ромашка и липа (против простуды) 500 г",
      "type": "SKU",
      "weight_fact": 511,
      "weight_plan": 511
    },
    {
      "article": "231591",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196515460",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 220,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "231591",
      "fragile": false,
      "id": "0Q-231591",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей термозащитный `KHARISMA VOLTAGE` CASHMERE с маслом ши 200 мл",
      "type": "SKU",
      "weight_plan": 239
    },
    {
      "article": "232578",
      "barcodes": [
        {
          "active": true,
          "barcode": "5904181932394",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5904181932264",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 190,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "232578",
      "fragile": false,
      "id": "0Q-232578",
      "images": [],
      "manufacturer": "4MYORGANIC SP.Z.O.O. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для интимной гигиены гипоаллергенный `4ORGANIC` Cranberry 350 мл",
      "type": "SKU",
      "weight_plan": 398
    },
    {
      "article": "202935",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290017990439",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 80,
        "height": 90,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 80,
        "height": 90,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "202935",
      "fragile": false,
      "id": "0Q-202935",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица дневной `SEA OF SPA` `BIOSPA` увлажняющий с коллагеном и оливковым маслом 50 мл",
      "type": "SKU",
      "weight_fact": 224,
      "weight_plan": 224
    },
    {
      "article": "190460",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159420951",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 230,
        "width": 70
      },
      "expiration_months": 0,
      "external_id": "190460",
      "fragile": false,
      "id": "0Q-190460",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мешки `LELEA` для стирки белья 3 шт",
      "type": "SKU",
      "weight_plan": 54
    },
    {
      "article": "233227",
      "barcodes": [
        {
          "active": true,
          "barcode": "4059729404145",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 70,
        "width": 30
      },
      "expiration_months": 1,
      "external_id": "233227",
      "fragile": false,
      "id": "0Q-233227",
      "images": [],
      "manufacturer": "COSNOVA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `CATRICE` CLOSSING GLOW тон 010 you glow girl 10,5 мл",
      "type": "SKU",
      "weight_plan": 56
    },
    {
      "article": "223167",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971453741",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 100,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 100,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "223167",
      "fragile": false,
      "id": "0Q-223167",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло твердое `SYNERGETIC` эвкалипт и шалфей (натуральное) 90 гр",
      "type": "SKU",
      "weight_fact": 95,
      "weight_plan": 95
    },
    {
      "article": "228305",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290018306260",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 60,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 60,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "228305",
      "fragile": false,
      "id": "0Q-228305",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Скраб для тела `SEA OF SPA` CANNABIO 250 мл",
      "type": "SKU",
      "weight_fact": 310,
      "weight_plan": 310
    },
    {
      "article": "68478",
      "barcodes": [
        {
          "active": true,
          "barcode": "6926939672689",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "6926939674539",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 230,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "68478",
      "fragile": false,
      "id": "0Q-68478",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `KHARISMA VOLTAGE` абсолютное восстановление 500 мл",
      "type": "SKU",
      "weight_plan": 555
    },
    {
      "article": "227071",
      "barcodes": [
        {
          "active": true,
          "barcode": "5060434297037",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 200,
        "height": 170,
        "width": 110
      },
      "dimensions_plan": {
        "depth": 200,
        "height": 170,
        "width": 110
      },
      "expiration_months": 1,
      "external_id": "227071",
      "fragile": false,
      "id": "0Q-227071",
      "images": [],
      "manufacturer": "LOVULAR LIMITED",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Подгузники `LOVULAR` GIRAFFE NB 0-4 кг 26 шт",
      "type": "SKU",
      "weight_fact": 480,
      "weight_plan": 480
    },
    {
      "article": "141131",
      "barcodes": [
        {
          "active": true,
          "barcode": "8807779088879",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 110,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "141131",
      "fragile": false,
      "id": "0Q-141131",
      "images": [],
      "manufacturer": "DOORI COSMETICS CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для рук `EGG PLANET` OH MY! HAND CREAM Клубника 30 мл",
      "type": "SKU",
      "weight_plan": 36
    },
    {
      "article": "156587",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809530077429",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 160,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "156587",
      "fragile": false,
      "id": "0Q-156587",
      "images": [],
      "manufacturer": "DIST ABLE C &C CO, LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тоник для лица `A`PIEU` 18 слабокислотный (для всех типов кожи) 180 мл",
      "type": "SKU",
      "weight_fact": 231,
      "weight_plan": 231
    },
    {
      "article": "245838",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329045513",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 110,
        "width": 20
      },
      "expiration_months": 31,
      "external_id": "245838",
      "fragile": false,
      "id": "0Q-245838",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тени для век `LUXVISAGE` SATIN NUDE Super stay жидкие тон 201 Ivory",
      "type": "SKU",
      "weight_plan": 21
    },
    {
      "article": "213850",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809414192330",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 180,
        "width": 130
      },
      "expiration_months": 36,
      "external_id": "213850",
      "fragile": false,
      "id": "0Q-213850",
      "images": [],
      "manufacturer": "LK COSMETICS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BERGAMO` HELP! с гиалуроновой кислотой (ультра-увлажняющая) 25 мл",
      "type": "SKU",
      "weight_plan": 30
    },
    {
      "article": "243522",
      "barcodes": [
        {
          "active": true,
          "barcode": "4605922005919",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 170,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 170,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "243522",
      "fragile": false,
      "id": "0Q-243522",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Паста зубная `ЛЕСНОЙ БАЛЬЗАМ` TOTAL КОМПЛЕКС Отбеливание с минералами 113 г",
      "type": "SKU",
      "weight_fact": 124,
      "weight_plan": 124
    },
    {
      "article": "173306",
      "barcodes": [
        {
          "active": true,
          "barcode": "8410262902400",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "173306",
      "fragile": false,
      "id": "0Q-173306",
      "images": [],
      "manufacturer": "NUVARIA GLOBAL, S.L.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Молочко для тела `DICORA URBAN FIT` Цитрусы и персик 400 мл",
      "type": "SKU",
      "weight_plan": 457
    },
    {
      "article": "161198",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104687639",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4640010713611",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 150,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 150,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "161198",
      "fragile": false,
      "id": "0Q-161198",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей-термозащита для укладки волос `KENSUKO` с морской солью 100 мл",
      "type": "SKU",
      "weight_fact": 147,
      "weight_plan": 147
    },
    {
      "article": "146598",
      "barcodes": [
        {
          "active": true,
          "barcode": "4897045272969",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 90,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 90,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "146598",
      "fragile": false,
      "id": "0Q-146598",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для волос `KENSUKO` SILIKON-FREE 50 мл",
      "type": "SKU",
      "weight_fact": 65,
      "weight_plan": 65
    },
    {
      "article": "241922",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809794736285",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 210,
        "width": 150
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 150
      },
      "expiration_months": 36,
      "external_id": "241922",
      "fragile": false,
      "id": "0Q-241922",
      "images": [],
      "manufacturer": "JOR R & D CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `JMSOLUTION` POKEMON с витамином С 30 мл",
      "type": "SKU",
      "weight_fact": 430,
      "weight_plan": 430
    },
    {
      "article": "226296",
      "barcodes": [
        {
          "active": true,
          "barcode": "9000100950589",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 290,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 290,
        "width": 90
      },
      "expiration_months": 24,
      "external_id": "226296",
      "fragile": false,
      "id": "0Q-226296",
      "images": [],
      "manufacturer": "HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `E` Восточный сон 1000 мл",
      "type": "SKU",
      "weight_fact": 1046,
      "weight_plan": 1046
    },
    {
      "article": "245844",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329044745",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 130,
        "width": 20
      },
      "expiration_months": 24,
      "external_id": "245844",
      "fragile": false,
      "id": "0Q-245844",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Блеск-бальзам для губ `LUXVISAGE` JELLY Citrus Питание",
      "type": "SKU",
      "weight_plan": 26
    },
    {
      "article": "206082",
      "barcodes": [
        {
          "active": true,
          "barcode": "305210232518",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "8801619049884",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "0305210232518",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 100,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 100,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "206082",
      "fragile": false,
      "id": "0Q-206082",
      "images": [],
      "manufacturer": "LCC CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Бальзам для губ `VASELINE` LIP THERAPY с маслом какао (в баночке) 7 г",
      "type": "SKU",
      "weight_fact": 15,
      "weight_plan": 15
    },
    {
      "article": "227142",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196512216",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "227142",
      "fragile": false,
      "id": "0Q-227142",
      "images": [],
      "manufacturer": "ГРАНД А. В. ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Бумага туалетная влажная `LELEA` детская с D-пантенолом 30 шт",
      "type": "SKU",
      "weight_plan": 131
    },
    {
      "article": "228188",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196512520",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 200,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 200,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "228188",
      "fragile": false,
      "id": "0Q-228188",
      "images": [],
      "manufacturer": "AEROFA AEROSOL DOLUM SAN. TIC. A.S.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для бритья `VOX` FOR MEN Алоэ вера и ментол 200 мл",
      "type": "SKU",
      "weight_fact": 255,
      "weight_plan": 255
    },
    {
      "article": "228321",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809647390121",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "228321",
      "fragile": false,
      "id": "0Q-228321",
      "images": [],
      "manufacturer": "GREENCOS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тонер для лица `SOME BY MI` с витамином С и галактомисисом (для сияния кожи) 200 мл",
      "type": "SKU",
      "weight_fact": 256,
      "weight_plan": 256
    },
    {
      "article": "185834",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104886810",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4630160531005",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 150,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "185834",
      "fragile": false,
      "id": "0Q-185834",
      "images": [],
      "manufacturer": "COSMEWAX, S.A.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Полоски восковые для лица `VOX` ECO с экстрактом алоэ вера и аргановым маслом 8 шт",
      "type": "SKU",
      "weight_plan": 18
    },
    {
      "article": "77693",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600702091748",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 150,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 150,
        "width": 60
      },
      "expiration_months": 31,
      "external_id": "77693",
      "fragile": false,
      "id": "0Q-77693",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для рук `БАРХАТНЫЕ РУЧКИ` РОСКОШЬ МАКАДАМИИ (с маслом ореха) 80 мл",
      "type": "SKU",
      "weight_fact": 91,
      "weight_plan": 91
    },
    {
      "article": "93943",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600697060583",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4602573020568",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 220,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 220,
        "width": 80
      },
      "expiration_months": 42,
      "external_id": "93943",
      "fragile": false,
      "id": "0Q-93943",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для мытья посуды `УШАСТЫЙ НЯНЬ` Ромашка и алоэ (детский) 500 мл",
      "type": "SKU",
      "weight_fact": 500,
      "weight_plan": 500
    },
    {
      "article": "233388",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196517112",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 100,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 100,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "233388",
      "fragile": false,
      "id": "0Q-233388",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ополаскиватель для полости рта `LP CARE` DENTAL Арбуз-Мята 10 мл",
      "type": "SKU",
      "weight_fact": 15,
      "weight_plan": 15
    },
    {
      "article": "215265",
      "barcodes": [
        {
          "active": true,
          "barcode": "6903071014324",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 140,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "215265",
      "fragile": false,
      "id": "0Q-215265",
      "images": [],
      "manufacturer": "HANZHOU ZHONGDIND IMP. EXP. COMPANY LIMITED GUANGDONG SILORAN CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тушь для ресниц `BOB` SILK MASCARA RED черная",
      "type": "SKU",
      "weight_plan": 42
    },
    {
      "article": "213825",
      "barcodes": [
        {
          "active": true,
          "barcode": "5907731958271",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 180,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "213825",
      "fragile": false,
      "id": "0Q-213825",
      "images": [],
      "manufacturer": "BE BIO ACTIVECOSMETIQS -MIND NETWORK INSPIRE SP. Z O.O. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для волос `BEBIO` натуральный (для сухих волос) 200 мл",
      "type": "SKU",
      "weight_fact": 222,
      "weight_plan": 222
    },
    {
      "article": "164237",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010717169",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104715202",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 180,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "164237",
      "fragile": false,
      "id": "0Q-164237",
      "images": [],
      "manufacturer": "COSMEWAX, S.A.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для депиляции `VOX` в душе (с ароматом дыни) 100 мл",
      "type": "SKU",
      "weight_plan": 134
    },
    {
      "article": "196509",
      "barcodes": [
        {
          "active": true,
          "barcode": "4897045274536",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 100,
        "height": 180,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 100,
        "height": 180,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "196509",
      "fragile": false,
      "id": "0Q-196509",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `KENSUKO` COLLAGEN для лишенных объема и тонких волос 400 мл",
      "type": "SKU",
      "weight_fact": 586,
      "weight_plan": 586
    },
    {
      "article": "159322",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809581460454",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "159322",
      "fragile": false,
      "id": "0Q-159322",
      "images": [],
      "manufacturer": "DIST ABLE C &C CO, LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка для умывания `A`PIEU` MADECASSOSIDE с мадекассосидом 130 мл",
      "type": "SKU",
      "weight_plan": 152
    },
    {
      "article": "196064",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159427158",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 140,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 140,
        "width": 60
      },
      "expiration_months": 24,
      "external_id": "196064",
      "fragile": false,
      "id": "0Q-196064",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-краска для бровей `KENSUKO` Тон Коричневый",
      "type": "SKU",
      "weight_fact": 17,
      "weight_plan": 17
    },
    {
      "article": "201704",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329014045",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 70,
        "width": 20
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 70,
        "width": 20
      },
      "expiration_months": 36,
      "external_id": "201704",
      "fragile": false,
      "id": "0Q-201704",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Помада для губ `LUXVISAGE` перламутровая тон 5",
      "type": "SKU",
      "weight_fact": 15,
      "weight_plan": 15
    },
    {
      "article": "00526",
      "barcodes": [
        {
          "active": true,
          "barcode": "5900095000037",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5900516401443",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5900516400040",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 110,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 110,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "00526",
      "fragile": false,
      "id": "0Q-00526",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Палочки ватные `CLEANIC` гигиенические 200 шт",
      "type": "SKU",
      "weight_fact": 65,
      "weight_plan": 65
    },
    {
      "article": "223271",
      "barcodes": [
        {
          "active": true,
          "barcode": "4064666045139",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4056800674169",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "223271",
      "fragile": false,
      "id": "0Q-223271",
      "images": [],
      "manufacturer": "INTERSPRAY",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мусс для волос `WELLA` `WELLAFLEX` объем до 2-х дней (экстрасильная фиксация) 200 мл",
      "type": "SKU",
      "weight_plan": 235
    },
    {
      "article": "191901",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159422399",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 110,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "191901",
      "fragile": false,
      "id": "0Q-191901",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Парфюмированный мист для тела `BESTIES` BODY MIST malibu blossom 30 мл",
      "type": "SKU",
      "weight_plan": 43
    },
    {
      "article": "219429",
      "barcodes": [
        {
          "active": true,
          "barcode": "4623722258199",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 270,
        "width": 90
      },
      "expiration_months": 24,
      "external_id": "219429",
      "fragile": false,
      "id": "0Q-219429",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `SYNERGETIC` Райский сад 1000 мл",
      "type": "SKU",
      "weight_plan": 1051
    },
    {
      "article": "237947",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196520594",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 90,
        "height": 170,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "237947",
      "fragile": false,
      "id": "0Q-237947",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ароматизатор воздуха `LELEA` Peach 400 мл",
      "type": "SKU",
      "weight_plan": 467
    },
    {
      "article": "125253",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290012934155",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104444362",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 180,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "125253",
      "fragile": false,
      "id": "0Q-125253",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для тела `SEA OF SPA` `BIOSPA` с экстрактом алоэ вера и маслом Ши 180 мл",
      "type": "SKU",
      "weight_fact": 202,
      "weight_plan": 202
    },
    {
      "article": "00437",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400000059790",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4600697101903",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4600697101378",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 100,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 100,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "00437",
      "fragile": false,
      "id": "0Q-00437",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло `НЕВСКАЯ КОСМЕТИКА` Банное 140 г",
      "type": "SKU",
      "weight_fact": 145,
      "weight_plan": 145
    },
    {
      "article": "134600",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809203139201",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "8809386882406",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 150,
        "width": 110
      },
      "expiration_months": 1,
      "external_id": "134600",
      "fragile": false,
      "id": "0Q-134600",
      "images": [],
      "manufacturer": "C & TECH CORPORATION",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки для снятия макияжа `DEWYTREE` 3 EFFECT 20 шт",
      "type": "SKU",
      "weight_plan": 133
    },
    {
      "article": "210955",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809589393525",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 40
      },
      "expiration_months": 1,
      "external_id": "210955",
      "fragile": false,
      "id": "0Q-210955",
      "images": [],
      "manufacturer": "COSTEM CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сыворотка для лица `ESFOLIO` с гиалуроновой кислотой и экстрактом хауттюйнии (увлажняющая и успокаивающая) 50 мл",
      "type": "SKU",
      "weight_plan": 115
    },
    {
      "article": "170637",
      "barcodes": [
        {
          "active": true,
          "barcode": "5900516868154",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5900516842376",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5900516868055",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 100,
        "width": 110
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 100,
        "width": 110
      },
      "expiration_months": 61,
      "external_id": "170637",
      "fragile": false,
      "id": "0Q-170637",
      "images": [],
      "manufacturer": "BELLA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Набор пластырей `MATOPAT` UNIVERSAL 20 шт",
      "type": "SKU",
      "weight_fact": 13,
      "weight_plan": 13
    },
    {
      "article": "190989",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159421293",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 240,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "190989",
      "fragile": false,
      "id": "0Q-190989",
      "images": [],
      "manufacturer": "AEROFA AEROSOL DOLUM SAN. TIC. A.S.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для волос `GIS` BUBBLE супер объем и невидимая фиксация 225 мл",
      "type": "SKU",
      "weight_plan": 264
    },
    {
      "article": "121934",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160531081",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104416819",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 170,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 170,
        "width": 90
      },
      "expiration_months": 24,
      "external_id": "121934",
      "fragile": false,
      "id": "0Q-121934",
      "images": [],
      "manufacturer": "ГРАНД А. В. ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки влажные `LP CARE` с антибактериальным эффектом 60 шт",
      "type": "SKU",
      "weight_fact": 231,
      "weight_plan": 231
    },
    {
      "article": "242338",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196524899",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 100,
        "width": 60
      },
      "expiration_months": 0,
      "external_id": "242338",
      "fragile": false,
      "id": "0Q-242338",
      "images": [],
      "manufacturer": "КОРОЛЕВА НАЙЛЯ РАИСОВНА ИП",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Наклейки для ногтей `PINK UP` `DECOR` GRUNGE COLLECTION 2D переводные тон 190",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "135766",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016846010",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "135766",
      "fragile": false,
      "id": "0Q-135766",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Део-ролл муж. `SEA OF SPA` `BIOSPA` гипоаллергенный 100 мл",
      "type": "SKU",
      "weight_plan": 146
    },
    {
      "article": "237754",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196520013",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 230,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "237754",
      "fragile": false,
      "id": "0Q-237754",
      "images": [],
      "manufacturer": "HUNCA KOZMETIK SANAYI A.S.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мусс для волос `KHARISMA VOLTAGE` МГНОВЕННЫЙ ОБЪЕМ сверхсильная фиксация (с аргинином) 200 мл",
      "type": "SKU",
      "weight_fact": 219,
      "weight_plan": 219
    },
    {
      "article": "206442",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809589393303",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 40
      },
      "expiration_months": 1,
      "external_id": "206442",
      "fragile": false,
      "id": "0Q-206442",
      "images": [],
      "manufacturer": "COSTEM CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `ESFOLIO` с гиалуроновой кислотой (увлажняющий) 50 мл",
      "type": "SKU",
      "weight_fact": 66,
      "weight_plan": 66
    },
    {
      "article": "245848",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329045483",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 70
      },
      "expiration_months": 31,
      "external_id": "245848",
      "fragile": false,
      "id": "0Q-245848",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пудра для лица `LUXVISAGE` FOREVER MATT! super FIX тон Бесцветный",
      "type": "SKU",
      "weight_plan": 43
    },
    {
      "article": "242849",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196525827",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "242849",
      "fragile": false,
      "id": "0Q-242849",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `LIMITED` FLOWER BLOOM тон 03 10 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "217684",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196502965",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 130,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "217684",
      "fragile": false,
      "id": "0Q-217684",
      "images": [],
      "manufacturer": "HANGZHOU ZHONGDING IMP.EXP.COMPANY LIMITED",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Подводка для глаз `NOVO` STAR MOTION тон 04 розовая",
      "type": "SKU",
      "weight_plan": 16
    },
    {
      "article": "210217",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159421903",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 210,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 210,
        "width": 90
      },
      "expiration_months": 0,
      "external_id": "210217",
      "fragile": false,
      "id": "0Q-210217",
      "images": [],
      "manufacturer": "NINGBO KAILI HOLDING GROUP CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Станок для бритья одноразовый `VOX` FOR WOMEN 2 лезвия 4 шт",
      "type": "SKU",
      "weight_fact": 29,
      "weight_plan": 29
    },
    {
      "article": "223133",
      "barcodes": [
        {
          "active": true,
          "barcode": "8410262902257",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 150,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "223133",
      "fragile": false,
      "id": "0Q-223133",
      "images": [],
      "manufacturer": "NUVARIA GLOBAL, S.L.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло жидкое `DICORA URBAN FIT` Молоко и дыня 500 мл",
      "type": "SKU",
      "weight_plan": 557
    },
    {
      "article": "152661",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104643857",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4640010715943",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 110,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 110,
        "width": 90
      },
      "expiration_months": 0,
      "external_id": "152661",
      "fragile": false,
      "id": "0Q-152661",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Палочки ватные `LP CARE` PURE COTTON в пакетике 160 шт",
      "type": "SKU",
      "weight_fact": 36,
      "weight_plan": 36
    },
    {
      "article": "177581",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809486361610",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 140,
        "width": 170
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 140,
        "width": 170
      },
      "expiration_months": 36,
      "external_id": "177581",
      "fragile": false,
      "id": "0Q-177581",
      "images": [],
      "manufacturer": "C&TECH CORP",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BANOBAGI` VITA GENIC с коллагеном и комплексом витаминов (обновляющая) 30 г",
      "type": "SKU",
      "weight_fact": 37,
      "weight_plan": 37
    },
    {
      "article": "192039",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159424355",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 200,
        "width": 40
      },
      "expiration_months": 0,
      "external_id": "192039",
      "fragile": false,
      "id": "0Q-192039",
      "images": [],
      "manufacturer": "YADE COSMETIC (DONGGUAN) CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кисть для хайлайтера `DECO.` REBEL accent set",
      "type": "SKU",
      "weight_plan": 20
    },
    {
      "article": "170638",
      "barcodes": [
        {
          "active": true,
          "barcode": "5900516868062",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5900516842383",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 100,
        "width": 110
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 100,
        "width": 110
      },
      "expiration_months": 61,
      "external_id": "170638",
      "fragile": false,
      "id": "0Q-170638",
      "images": [],
      "manufacturer": "BELLA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Набор пластырей `MATOPAT` TRANSPARENT 20 шт",
      "type": "SKU",
      "weight_fact": 13,
      "weight_plan": 13
    },
    {
      "article": "200534",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809517474951",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 190,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "200534",
      "fragile": false,
      "id": "0Q-200534",
      "images": [],
      "manufacturer": "MLS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `DEWYTREE` SKIN-DERMA c экстрактом жемчуга (для сияния кожи) 23 г",
      "type": "SKU",
      "weight_plan": 28
    },
    {
      "article": "213029",
      "barcodes": [
        {
          "active": true,
          "barcode": "8720182990679",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "213029",
      "fragile": false,
      "id": "0Q-213029",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка-мусс для умывания `ЧЕРНЫЙ ЖЕМЧУГ` ОЧИЩЕНИЕ + УХОД 2 в 1 150 мл",
      "type": "SKU",
      "weight_fact": 216,
      "weight_plan": 216
    },
    {
      "article": "202994",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809581455580",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 190,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "202994",
      "fragile": false,
      "id": "0Q-202994",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для снятия макияжа с глаз и губ `A`PIEU` `MINERAL` SWEET ROSE двухфазное 250 мл",
      "type": "SKU",
      "weight_fact": 285,
      "weight_plan": 285
    },
    {
      "article": "230115",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196514616",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 260,
        "width": 190
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 260,
        "width": 190
      },
      "expiration_months": 1,
      "external_id": "230115",
      "fragile": false,
      "id": "0Q-230115",
      "images": [],
      "manufacturer": "GUANGZHOU LISUN INTERNATIONAL COMMERCE DEVELOPMENT CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Стиральный порошок `LELEA` отбеливающий 1 кг",
      "type": "SKU",
      "weight_fact": 1050,
      "weight_plan": 1050
    },
    {
      "article": "147369",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016846874",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 70,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 70,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "147369",
      "fragile": false,
      "id": "0Q-147369",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-масло для тела `SEA OF SPA` `BIOSPA` Инжир и оливковое масло (смягчающее) 250 мл",
      "type": "SKU",
      "weight_fact": 268,
      "weight_plan": 268
    },
    {
      "article": "139077",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010715981",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104548312",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4630160530947",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 160,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 160,
        "width": 60
      },
      "expiration_months": 0,
      "external_id": "139077",
      "fragile": false,
      "id": "0Q-139077",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Диски ватные `LP CARE` двусторонние из хлопка 50 шт",
      "type": "SKU",
      "weight_fact": 29,
      "weight_plan": 29
    },
    {
      "article": "125242",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290010673025",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104444256",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 230,
        "width": 140
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 230,
        "width": 140
      },
      "expiration_months": 36,
      "external_id": "125242",
      "fragile": false,
      "id": "0Q-125242",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Соль для ванны `SEA OF SPA` минеральная Мертвого моря 500 г",
      "type": "SKU",
      "weight_fact": 514,
      "weight_plan": 514
    },
    {
      "article": "208958",
      "barcodes": [
        {
          "active": true,
          "barcode": "8480029434987",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 210,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "208958",
      "fragile": false,
      "id": "0Q-208958",
      "images": [],
      "manufacturer": "NUVARIA GLOBAL, S.L.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для волос `DICORA URBAN FIT` с маслом макадамии (экстра восстановление и питание) 400 мл",
      "type": "SKU",
      "weight_fact": 447,
      "weight_plan": 447
    },
    {
      "article": "56151",
      "barcodes": [
        {
          "active": true,
          "barcode": "4005808561490",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "56151",
      "fragile": false,
      "id": "0Q-56151",
      "images": [],
      "manufacturer": "BEIERSDORF",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для интимной гигиены `NIVEA` INTIMATE Sensitive 250 мл",
      "type": "SKU",
      "weight_plan": 284
    },
    {
      "article": "242199",
      "barcodes": [
        {
          "active": true,
          "barcode": "5904181931274",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 140,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 140,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "242199",
      "fragile": false,
      "id": "0Q-242199",
      "images": [],
      "manufacturer": "4MYORGANIC SP.Z.O.O. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Точечный гель для лица `UNI.Q` ACNE STOP с эктоином и лактобионовой кислотой (против несовершенств кожи) 20 мл",
      "type": "SKU",
      "weight_fact": 35,
      "weight_plan": 35
    },
    {
      "article": "229191",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809486364215",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 140,
        "width": 160
      },
      "expiration_months": 1,
      "external_id": "229191",
      "fragile": false,
      "id": "0Q-229191",
      "images": [],
      "manufacturer": "C&TECH CORP",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BANOBAGI` с коллагеном и 9 видами пептидов (восстанавливающая) 30 г",
      "type": "SKU",
      "weight_plan": 35
    },
    {
      "article": "215125",
      "barcodes": [
        {
          "active": true,
          "barcode": "5903416029311",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 60,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 60,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "215125",
      "fragile": false,
      "id": "0Q-215125",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Румяна для лица `EVELINE` FEEL THE BLUSH тон 05 taupe",
      "type": "SKU",
      "weight_fact": 32,
      "weight_plan": 32
    },
    {
      "article": "227042",
      "barcodes": [
        {
          "active": true,
          "barcode": "4602984016822",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 80,
        "height": 270,
        "width": 160
      },
      "dimensions_plan": {
        "depth": 80,
        "height": 270,
        "width": 160
      },
      "expiration_months": 1,
      "external_id": "227042",
      "fragile": false,
      "id": "0Q-227042",
      "images": [],
      "manufacturer": "СТУПИНСКИЙ ХИМИЧЕСКИЙ ЗАВОД АО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для стирки жидкое `VILOR` детское 1000 мл",
      "type": "SKU",
      "weight_fact": 1018,
      "weight_plan": 1018
    },
    {
      "article": "150473",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016846980",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 80,
        "height": 90,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 80,
        "height": 90,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "150473",
      "fragile": false,
      "id": "0Q-150473",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица ночной `SEA OF SPA` GOLD BENEFITS с гиалуроновой кислотой 50 мл",
      "type": "SKU",
      "weight_fact": 207,
      "weight_plan": 207
    },
    {
      "article": "243029",
      "barcodes": [
        {
          "active": true,
          "barcode": "4627183580714",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4610196526343",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 90,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 90,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "243029",
      "fragile": false,
      "id": "0Q-243029",
      "images": [],
      "manufacturer": "АРОМА ПРОМ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей-санайзер для ухода за кожей рук `LP CARE` FOREST STORY Jasmine 20 мл",
      "type": "SKU",
      "weight_fact": 35,
      "weight_plan": 35
    },
    {
      "article": "170639",
      "barcodes": [
        {
          "active": true,
          "barcode": "5900516842390",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5900516868079",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 100,
        "width": 110
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 100,
        "width": 110
      },
      "expiration_months": 61,
      "external_id": "170639",
      "fragile": false,
      "id": "0Q-170639",
      "images": [],
      "manufacturer": "BELLA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Набор пластырей `MATOPAT` HAPPY 12 шт",
      "type": "SKU",
      "weight_fact": 13,
      "weight_plan": 13
    },
    {
      "article": "213310",
      "barcodes": [
        {
          "active": true,
          "barcode": "6221155023605",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 80,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "213310",
      "fragile": false,
      "id": "0Q-213310",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло твердое `CAMAY` Романтик 85 г",
      "type": "SKU",
      "weight_plan": 85
    },
    {
      "article": "242847",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196525803",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "242847",
      "fragile": false,
      "id": "0Q-242847",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `LIMITED` FLOWER BLOOM тон 01 10 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "245842",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329045551",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 110,
        "width": 20
      },
      "expiration_months": 31,
      "external_id": "245842",
      "fragile": false,
      "id": "0Q-245842",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тени для век `LUXVISAGE` SATIN NUDE Super stay жидкие тон 205 Taupe",
      "type": "SKU",
      "weight_plan": 21
    },
    {
      "article": "166470",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809581460287",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 230,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "166470",
      "fragile": false,
      "id": "0Q-166470",
      "images": [],
      "manufacturer": "COSMAX INC.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `A`PIEU` RASPBERRY с малиновым уксусом 500 мл",
      "type": "SKU",
      "weight_plan": 584
    },
    {
      "article": "210902",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809446655230",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "210902",
      "fragile": false,
      "id": "0Q-210902",
      "images": [],
      "manufacturer": "SINDO P & G CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `GRACE DAY` MULTI-VITAMIN с экстрактом манго (питательная) 27 мл",
      "type": "SKU",
      "weight_fact": 32,
      "weight_plan": 32
    },
    {
      "article": "164239",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160532323",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104715226",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 140,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "164239",
      "fragile": false,
      "id": "0Q-164239",
      "images": [],
      "manufacturer": "AEROFA AEROSOL DOLUM SAN. TIC. A.S.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пена для бритья `VOX` FOR WOMEN Ваниль 50 мл",
      "type": "SKU",
      "weight_plan": 68
    },
    {
      "article": "147680",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809530049747",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 60,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 60,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "147680",
      "fragile": false,
      "id": "0Q-147680",
      "images": [],
      "manufacturer": "BESTONETECH CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пилинг-диски для лица `A`PIEU` с АНА и ВНА-кислотами и 6 витаминами 35 шт",
      "type": "SKU",
      "weight_fact": 110,
      "weight_plan": 110
    },
    {
      "article": "191903",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159422412",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 110,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 110,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "191903",
      "fragile": false,
      "id": "0Q-191903",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Парфюмированный мист для тела `BESTIES` BODY MIST always yours 30 мл",
      "type": "SKU",
      "weight_fact": 43,
      "weight_plan": 43
    },
    {
      "article": "215839",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104989245",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 210,
        "width": 90
      },
      "expiration_months": 0,
      "external_id": "215839",
      "fragile": false,
      "id": "0Q-215839",
      "images": [],
      "manufacturer": "SHENZHEN N-DEER FASHION CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Расческа для сушки волос `LADY PINK` `FAVS` мятная",
      "type": "SKU",
      "weight_plan": 46
    },
    {
      "article": "241003",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523588",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 150,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "241003",
      "fragile": false,
      "id": "0Q-241003",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш для губ `ISCREAM` FLOWER FLOW тон 02",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "167082",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104726208",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4610196511301",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 80,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 80,
        "width": 50
      },
      "expiration_months": 0,
      "external_id": "167082",
      "fragile": false,
      "id": "0Q-167082",
      "images": [],
      "manufacturer": "JIN CORPORATION",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спонжи для макияжа `DECO.` BASE квадратные (латекс) 2 шт",
      "type": "SKU",
      "weight_fact": 9,
      "weight_plan": 9
    },
    {
      "article": "228311",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290013761057",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 100,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 100,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "228311",
      "fragile": false,
      "id": "0Q-228311",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица и тела `SEA OF SPA` `BIOSPA` с папайей 250 мл",
      "type": "SKU",
      "weight_fact": 332,
      "weight_plan": 332
    },
    {
      "article": "226347",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809589393884",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "226347",
      "fragile": false,
      "id": "0Q-226347",
      "images": [],
      "manufacturer": "COSTEM CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка для умывания `ESFOLIO` с экстрактом алоэ вера 75 г",
      "type": "SKU",
      "weight_fact": 90,
      "weight_plan": 90
    },
    {
      "article": "227132",
      "barcodes": [
        {
          "active": true,
          "barcode": "4651111951673",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 120,
        "height": 80,
        "width": 200
      },
      "dimensions_plan": {
        "depth": 120,
        "height": 80,
        "width": 200
      },
      "expiration_months": 1,
      "external_id": "227132",
      "fragile": false,
      "id": "0Q-227132",
      "images": [],
      "manufacturer": "ПАПИРЮГ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки бумажные `PLUSHE` Ассорти в коробке 2-х слойные 180 шт",
      "type": "SKU",
      "weight_fact": 240,
      "weight_plan": 240
    },
    {
      "article": "208954",
      "barcodes": [
        {
          "active": true,
          "barcode": "8480029434925",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 210,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "208954",
      "fragile": false,
      "id": "0Q-208954",
      "images": [],
      "manufacturer": "NUVARIA GLOBAL, S.L.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `DICORA URBAN FIT` с маслом макадамии (экстра восстановление и питание) 400 мл",
      "type": "SKU",
      "weight_fact": 447,
      "weight_plan": 447
    },
    {
      "article": "159330",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809486362273",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "8809486360859",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 140,
        "width": 170
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 140,
        "width": 170
      },
      "expiration_months": 36,
      "external_id": "159330",
      "fragile": false,
      "id": "0Q-159330",
      "images": [],
      "manufacturer": "C&TECH CORP",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BANOBAGI` VITA GENIC Лифтинг 30 мл",
      "type": "SKU",
      "weight_fact": 36,
      "weight_plan": 36
    },
    {
      "article": "12413",
      "barcodes": [
        {
          "active": true,
          "barcode": "3574660192063",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400000160021",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4001683011426",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "3574661360850",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 100,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "12413",
      "fragile": false,
      "id": "0Q-12413",
      "images": [],
      "manufacturer": "JOHNSON & JOHNSON",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тампоны `O.B.` PRO COMFORT Mini 16 шт",
      "type": "SKU",
      "weight_plan": 34
    },
    {
      "article": "242248",
      "barcodes": [
        {
          "active": true,
          "barcode": "3574661684321",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 280,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 280,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "242248",
      "fragile": false,
      "id": "0Q-242248",
      "images": [],
      "manufacturer": "JOHNSON & JOHNSON",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ополаскиватель для полости рта `LISTERINE` FLAVOURS Mild & Minty 500 мл",
      "type": "SKU",
      "weight_fact": 563,
      "weight_plan": 563
    },
    {
      "article": "154669",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809177591814",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "8809177590312",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 180,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "154669",
      "fragile": false,
      "id": "0Q-154669",
      "images": [],
      "manufacturer": "HANIL PHARM CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Паста зубная `HANIL` WHAIRANG Защита от зубного камня и налета 150 г",
      "type": "SKU",
      "weight_plan": 175
    },
    {
      "article": "93944",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600697101996",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4600697105079",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 90,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 90,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "93944",
      "fragile": false,
      "id": "0Q-93944",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-мыло детское `УШАСТЫЙ НЯНЬ` с алоэ вера и подорожником 90 г",
      "type": "SKU",
      "weight_fact": 92,
      "weight_plan": 92
    },
    {
      "article": "221405",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809615058381",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "8809615057674",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 150,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "221405",
      "fragile": false,
      "id": "0Q-221405",
      "images": [],
      "manufacturer": "LS COSMETIC CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для губ `MEDIHEAL` ночная (увлажняющая) 10 мл",
      "type": "SKU",
      "weight_plan": 24
    },
    {
      "article": "141285",
      "barcodes": [
        {
          "active": true,
          "barcode": "4670017926981",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 100,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 100,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "141285",
      "fragile": false,
      "id": "0Q-141285",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Краска для ресниц и бровей `ФИТОКОСМЕТИК` `КОКЕТКА` тон Шоколад",
      "type": "SKU",
      "weight_fact": 12,
      "weight_plan": 12
    },
    {
      "article": "202999",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809505543096",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 200,
        "width": 140
      },
      "expiration_months": 36,
      "external_id": "202999",
      "fragile": false,
      "id": "0Q-202999",
      "images": [],
      "manufacturer": "JOR R & D CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `JMSOLUTION` BLACK с экстрактом кокона тутового шелкопряда  (выравнивающая тон кожи) 35 мл",
      "type": "SKU",
      "weight_plan": 42
    },
    {
      "article": "223181",
      "barcodes": [
        {
          "active": true,
          "barcode": "8699568539910",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 220,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 220,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "223181",
      "fragile": false,
      "id": "0Q-223181",
      "images": [],
      "manufacturer": "INTERSPRAY",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для волос `WELLA` `WELLAFLEX` мгновенный объем 250 мл",
      "type": "SKU",
      "weight_fact": 252,
      "weight_plan": 252
    },
    {
      "article": "245259",
      "barcodes": [
        {
          "active": true,
          "barcode": "4627097721630",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 190,
        "width": 40
      },
      "expiration_months": 120,
      "external_id": "245259",
      "fragile": false,
      "id": "0Q-245259",
      "images": [],
      "manufacturer": "ХОУМ ЭКСПЕРТ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пакеты для запекания `HOMEX` с клипсами 5 шт (30х40см) Очень удобные",
      "type": "SKU",
      "weight_plan": 30
    },
    {
      "article": "231654",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196515514",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 160,
        "width": 160
      },
      "expiration_months": 1,
      "external_id": "231654",
      "fragile": false,
      "id": "0Q-231654",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Органайзер для женской гигиены `LP CARE` cats шт",
      "type": "SKU",
      "weight_plan": 28
    },
    {
      "article": "242239",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196524028",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 140,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "242239",
      "fragile": false,
      "id": "0Q-242239",
      "images": [],
      "manufacturer": "DONGGUAN TAIMENG ACCESSORIES CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Бальзам для губ `LP CARE` BUNNY Маршмеллоу 3 г",
      "type": "SKU",
      "weight_plan": 50
    },
    {
      "article": "211740",
      "barcodes": [
        {
          "active": true,
          "barcode": "5903416027157",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 20
      },
      "expiration_months": 31,
      "external_id": "211740",
      "fragile": false,
      "id": "0Q-211740",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Консилер для лица `EVELINE` WONDER MATCH кремовый тон 005 light porcelain",
      "type": "SKU",
      "weight_plan": 35
    },
    {
      "article": "228045",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809032673174",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 170,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 170,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "228045",
      "fragile": false,
      "id": "0Q-228045",
      "images": [],
      "manufacturer": "IPEERES COSMETICS CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тонер для лица `SKINFOOD` CARROT CAROTENE с экстрактом и маслом моркови (успокаивающий) 300 мл",
      "type": "SKU",
      "weight_fact": 378,
      "weight_plan": 378
    },
    {
      "article": "225611",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809301768969",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "225611",
      "fragile": false,
      "id": "0Q-225611",
      "images": [],
      "manufacturer": "ANCORS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Скраб-гоммаж для лица  `ARIUL` APPLE CIDER с яблочным уксусом (отшелушивающий) 100 г",
      "type": "SKU",
      "weight_plan": 134
    },
    {
      "article": "152834",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809530068953",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 50,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 50,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "152834",
      "fragile": false,
      "id": "0Q-152834",
      "images": [],
      "manufacturer": "COSMECCA KOREA CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `A`PIEU` 10 OIL SOAK 50 мл",
      "type": "SKU",
      "weight_fact": 159,
      "weight_plan": 159
    },
    {
      "article": "138852",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806185759571",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 200,
        "height": 10,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "138852",
      "fragile": false,
      "id": "0Q-138852",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ватная палочка для пилинга `A`PIEU` AQUA PEELING c 8% AHA и BHA-кислотами и экстрактом алоэ вера (интенсивного действия) 3 мл",
      "type": "SKU",
      "weight_plan": 8
    },
    {
      "article": "227045",
      "barcodes": [
        {
          "active": true,
          "barcode": "4602984022250",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 310,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "227045",
      "fragile": false,
      "id": "0Q-227045",
      "images": [],
      "manufacturer": "СТУПИНСКИЙ ХИМИЧЕСКИЙ ЗАВОД АО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `BIS` пион и белые цветы (концентрированный) 900 мл",
      "type": "SKU",
      "weight_plan": 970
    },
    {
      "article": "170375",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901761993806",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 130,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 130,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "170375",
      "fragile": false,
      "id": "0Q-170375",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем тональный для лица `EVELINE` ART PROFESSIONAL MAKE-UP 3 в 1 ультрастойкий тон бежевый",
      "type": "SKU",
      "weight_fact": 41,
      "weight_plan": 41
    },
    {
      "article": "220805",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971450702",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 190,
        "width": 190
      },
      "expiration_months": 24,
      "external_id": "220805",
      "fragile": false,
      "id": "0Q-220805",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Порошок стиральный `SYNERGETIC` 20 стиков",
      "type": "SKU",
      "weight_plan": 624
    },
    {
      "article": "123881",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104434578",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4897045272662",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 220,
        "width": 140
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 220,
        "width": 140
      },
      "expiration_months": 36,
      "external_id": "123881",
      "fragile": false,
      "id": "0Q-123881",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `KHARISMA VOLTAGE` GOLD GINGER Восстановление и обновление 500 мл",
      "type": "SKU",
      "weight_fact": 497,
      "weight_plan": 497
    },
    {
      "article": "125227",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290012934568",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104444102",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 90,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 90,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "125227",
      "fragile": false,
      "id": "0Q-125227",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ночной крем для лица `SEA OF SPA` `BIOSPA` питательный 50 мл",
      "type": "SKU",
      "weight_fact": 230,
      "weight_plan": 230
    },
    {
      "article": "38924",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400000257776",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4600936310325",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 100,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 100,
        "width": 60
      },
      "expiration_months": 120,
      "external_id": "38924",
      "fragile": false,
      "id": "0Q-38924",
      "images": [],
      "manufacturer": "СВОБОДА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло детское `ТИК-ТАК` 150 г",
      "type": "SKU",
      "weight_fact": 150,
      "weight_plan": 150
    },
    {
      "article": "227050",
      "barcodes": [
        {
          "active": true,
          "barcode": "9000101417173",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 130
      },
      "expiration_months": 1,
      "external_id": "227050",
      "fragile": false,
      "id": "0Q-227050",
      "images": [],
      "manufacturer": "HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Подвеска для унитаза `BREF` DELUXE Чарующий лунный цветок 2х50 г",
      "type": "SKU",
      "weight_plan": 130
    },
    {
      "article": "209452",
      "barcodes": [
        {
          "active": true,
          "barcode": "4015100450767",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4015100805727",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 40,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "209452",
      "fragile": false,
      "id": "0Q-209452",
      "images": [],
      "manufacturer": "SCHWARZKOPF & HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Воск для волос `ТАФТ` POWER мегафиксация (5) 75 мл",
      "type": "SKU",
      "weight_plan": 93
    },
    {
      "article": "231827",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809221272911",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 70,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 70,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "231827",
      "fragile": false,
      "id": "0Q-231827",
      "images": [],
      "manufacturer": "IPEERES COSMETICS CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `SKINFOOD` YUJA C с экстрактом юдзу (выравнивающий тон кожи) 61 мл",
      "type": "SKU",
      "weight_fact": 208,
      "weight_plan": 208
    },
    {
      "article": "167080",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104726185",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4640010717244",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 90,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 90,
        "width": 60
      },
      "expiration_months": 0,
      "external_id": "167080",
      "fragile": false,
      "id": "0Q-167080",
      "images": [],
      "manufacturer": "JIN CORPORATION",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спонжи для макияжа `DECO.` BASE клиновидные (латекс) 4 шт",
      "type": "SKU",
      "weight_fact": 20,
      "weight_plan": 20
    },
    {
      "article": "150468",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010716001",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400104621558",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4630160532415",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 290,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 290,
        "width": 60
      },
      "expiration_months": 37,
      "external_id": "150468",
      "fragile": false,
      "id": "0Q-150468",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Диски ватные `LP CARE` PURE COTTON с добавлением ионов серебра 120 шт",
      "type": "SKU",
      "weight_fact": 60,
      "weight_plan": 60
    },
    {
      "article": "209749",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806325629979",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 170,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 170,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "209749",
      "fragile": false,
      "id": "0Q-209749",
      "images": [],
      "manufacturer": "LION CORPORATION (KOREA)",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для волос `RICE DAY` Увлажнение и объем (для сухих волос) 200 мл",
      "type": "SKU",
      "weight_fact": 272,
      "weight_plan": 272
    },
    {
      "article": "233412",
      "barcodes": [
        {
          "active": true,
          "barcode": "4001499957567",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 90,
        "height": 270,
        "width": 130
      },
      "expiration_months": 1,
      "external_id": "233412",
      "fragile": false,
      "id": "0Q-233412",
      "images": [],
      "manufacturer": "FROSCH",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `FROSCH` Цветы хлопка 1 л",
      "type": "SKU",
      "weight_plan": 1047
    },
    {
      "article": "227048",
      "barcodes": [
        {
          "active": true,
          "barcode": "9000101417142",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 130
      },
      "expiration_months": 1,
      "external_id": "227048",
      "fragile": false,
      "id": "0Q-227048",
      "images": [],
      "manufacturer": "HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Подвеска для унитаза `BREF` DELUXE Нежная магнолия 2х50 г",
      "type": "SKU",
      "weight_plan": 130
    },
    {
      "article": "234987",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290018306017",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 100,
        "height": 100,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 100,
        "height": 100,
        "width": 100
      },
      "expiration_months": 60,
      "external_id": "234987",
      "fragile": false,
      "id": "0Q-234987",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `SEA OF SPA` `BIO MARINE` дневной с натуральным коллагеном (для жирной и комбинированной кожи) 50 мл",
      "type": "SKU",
      "weight_fact": 209,
      "weight_plan": 209
    },
    {
      "article": "241931",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809852548553",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 180,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 180,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "241931",
      "fragile": false,
      "id": "0Q-241931",
      "images": [],
      "manufacturer": "JOR R & D CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лосьон для тела `JMSOLUTION` DISNEY с экстрактом медовой гардении 500 мл",
      "type": "SKU",
      "weight_fact": 560,
      "weight_plan": 560
    },
    {
      "article": "111463",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104329942",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5901018014407",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5901018912642",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 220,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "111463",
      "fragile": false,
      "id": "0Q-111463",
      "images": [],
      "manufacturer": "LABORATORIUM KOSMETYCZNE JOANNA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `JOANNA` ARGAN OIL с аргановым маслом 500 мл",
      "type": "SKU",
      "weight_plan": 564
    },
    {
      "article": "231460",
      "barcodes": [
        {
          "active": true,
          "barcode": "8720633011168",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 80,
        "height": 240,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "231460",
      "fragile": false,
      "id": "0Q-231460",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `DOVE` ГЛУБОКОЕ ПИТАНИЕ И ВОССТАНОВЛЕНИЕ с маслом миндаля и манго (для сухих волос) 380 мл",
      "type": "SKU",
      "weight_plan": 427
    },
    {
      "article": "241000",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523557",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 130,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "241000",
      "fragile": false,
      "id": "0Q-241000",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Подводка для глаз `ISCREAM` FLOWER FLOW тон 03 green с глиттером",
      "type": "SKU",
      "weight_plan": 11
    },
    {
      "article": "243027",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196526329",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4627183580691",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 90,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 90,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "243027",
      "fragile": false,
      "id": "0Q-243027",
      "images": [],
      "manufacturer": "АРОМА ПРОМ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей-санайзер для ухода за кожей рук `LP CARE` FOREST STORY Lavanda 20 мл",
      "type": "SKU",
      "weight_fact": 35,
      "weight_plan": 35
    },
    {
      "article": "205358",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809517475286",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 80,
        "height": 70,
        "width": 80
      },
      "expiration_months": 1,
      "external_id": "205358",
      "fragile": false,
      "id": "0Q-205358",
      "images": [],
      "manufacturer": "PICOSTECH CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `DEWYTREE` SUPER CERAMIDE с керамидами и экстрактом нони (для сияния и укрепления кожи) 50 мл",
      "type": "SKU",
      "weight_plan": 206
    },
    {
      "article": "204544",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809698220293",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "204544",
      "fragile": false,
      "id": "0Q-204544",
      "images": [],
      "manufacturer": "MEDB CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `MED B` 1 DAY с экстрактом секрета улитки (укрепляющая) 27 мл",
      "type": "SKU",
      "weight_plan": 30
    },
    {
      "article": "203669",
      "barcodes": [
        {
          "active": true,
          "barcode": "8714100863572",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "203669",
      "fragile": false,
      "id": "0Q-203669",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка-мусс для умывания `ЧЕРНЫЙ ЖЕМЧУГ` КОЛЛАГЕН питательная 150 мл",
      "type": "SKU",
      "weight_fact": 205,
      "weight_plan": 205
    },
    {
      "article": "191894",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159422320",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "191894",
      "fragile": false,
      "id": "0Q-191894",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Парфюмированный мист для тела `BESTIES` SHIMMER MIST coconut kiss 88 мл",
      "type": "SKU",
      "weight_plan": 112
    },
    {
      "article": "159332",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809581450714",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "159332",
      "fragile": false,
      "id": "0Q-159332",
      "images": [],
      "manufacturer": "DIST ABLE C &C CO, LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка для умывания `A`PIEU` `DEEP CLEAN` с молочным протеином 130 мл",
      "type": "SKU",
      "weight_plan": 159
    },
    {
      "article": "28175",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607051790827",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 90,
        "width": 90
      },
      "expiration_months": 24,
      "external_id": "28175",
      "fragile": false,
      "id": "0Q-28175",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Порошок зубной `ФИТОКОСМЕТИК` Отбеливающий 75 г",
      "type": "SKU",
      "weight_plan": 113
    },
    {
      "article": "136041",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104522909",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4630160531166",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 180,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 180,
        "width": 80
      },
      "expiration_months": 0,
      "external_id": "136041",
      "fragile": false,
      "id": "0Q-136041",
      "images": [],
      "manufacturer": "NINGBO RAFFINI IMPORT AND EXPORT CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Массажер для лица `DECO.`",
      "type": "SKU",
      "weight_fact": 42,
      "weight_plan": 42
    },
    {
      "article": "166995",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290010673537",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "166995",
      "fragile": false,
      "id": "0Q-166995",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для рук `SEA OF SPA` `BIOSPA` с маслом авокадо и алоэ вера 100 мл",
      "type": "SKU",
      "weight_fact": 111,
      "weight_plan": 111
    },
    {
      "article": "12406",
      "barcodes": [
        {
          "active": true,
          "barcode": "3574660240009",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "3574661329840",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "3574660234428",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "3574660234282",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 110,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 110,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "12406",
      "fragile": false,
      "id": "0Q-12406",
      "images": [],
      "manufacturer": "JOHNSON & JOHNSON",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тампоны `O.B.` PRO COMFORT Normal 16 шт",
      "type": "SKU",
      "weight_fact": 47,
      "weight_plan": 47
    },
    {
      "article": "146031",
      "barcodes": [
        {
          "active": true,
          "barcode": "8807779087902",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "expiration_months": 1,
      "external_id": "146031",
      "fragile": false,
      "id": "0Q-146031",
      "images": [],
      "manufacturer": "DOORI COSMETICS CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `DAENG GI MEO RI` YELLOW BLOSSOM против выпадения 200 мл",
      "type": "SKU",
      "weight_fact": 222,
      "weight_plan": 222
    },
    {
      "article": "149325",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160534860",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104612402",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 120,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 120,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "149325",
      "fragile": false,
      "id": "0Q-149325",
      "images": [],
      "manufacturer": "MLS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Стик для лица и губ `SUN LOOK` солнцезащитный SPF-50 15 г",
      "type": "SKU",
      "weight_fact": 46,
      "weight_plan": 46
    },
    {
      "article": "234977",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809747954230",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 170,
        "width": 110
      },
      "expiration_months": 36,
      "external_id": "234977",
      "fragile": false,
      "id": "0Q-234977",
      "images": [],
      "manufacturer": "CNF CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `A`PIEU` TEA TREE успокаивающая 23 г",
      "type": "SKU",
      "weight_plan": 26
    },
    {
      "article": "196058",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159427097",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 80,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 80,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "196058",
      "fragile": false,
      "id": "0Q-196058",
      "images": [],
      "manufacturer": "ЗЕТТЕКНОЛОДЖИ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки влажные `LP CARE` для интимной гигиены 8 шт",
      "type": "SKU",
      "weight_fact": 36,
      "weight_plan": 36
    },
    {
      "article": "228310",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290010673469",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 210,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 210,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "228310",
      "fragile": false,
      "id": "0Q-228310",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Вода мертвого моря `SEA OF SPA` `BIO MARINE` 500 мл",
      "type": "SKU",
      "weight_fact": 537,
      "weight_plan": 537
    },
    {
      "article": "245853",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329046831",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 150,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "245853",
      "fragile": false,
      "id": "0Q-245853",
      "images": [],
      "manufacturer": "NINGBO EYECOS COSMETIC CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш для бровей `LUXVISAGE` BROWISSIMO Ultra slim super stay 24H механический тон 314 Deep brown",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "245852",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329046824",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 150,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "245852",
      "fragile": false,
      "id": "0Q-245852",
      "images": [],
      "manufacturer": "NINGBO EYECOS COSMETIC CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш для бровей `LUXVISAGE` BROWISSIMO Ultra slim super stay 24H механический тон 313 Ash brown",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "219440",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971454359",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 230,
        "width": 80
      },
      "expiration_months": 24,
      "external_id": "219440",
      "fragile": false,
      "id": "0Q-219440",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Освежитель-спрей для помещений `SYNERGETIC` Миндаль и яблоневый цвет (гипоаллергенный биоразлагаемый) 380 мл",
      "type": "SKU",
      "weight_plan": 430
    },
    {
      "article": "200216",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901018017927",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 140,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "200216",
      "fragile": false,
      "id": "0Q-200216",
      "images": [],
      "manufacturer": "LABORATORIUM KOSMETYCZNE JOANNA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Оттеночный шампунь для волос `JOANNA` ULTRA COLOR SYSTEM тон платиновый (против желтизны) 100 мл",
      "type": "SKU",
      "weight_plan": 124
    },
    {
      "article": "149358",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104612518",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4630160530053",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "149358",
      "fragile": false,
      "id": "0Q-149358",
      "images": [],
      "manufacturer": "AEROFA AEROSOL DOLUM SAN. TIC. A.S.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пантенол-спрей для тела `SUN LOOK` 150 мл",
      "type": "SKU",
      "weight_plan": 163
    },
    {
      "article": "204703",
      "barcodes": [
        {
          "active": true,
          "barcode": "4613720439027",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 260,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "204703",
      "fragile": false,
      "id": "0Q-204703",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `SYNERGETIC` для мытья стекол, окон и зеркал 500 мл",
      "type": "SKU",
      "weight_plan": 485
    },
    {
      "article": "125477",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104445789",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5901761914535",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 170,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "125477",
      "fragile": false,
      "id": "0Q-125477",
      "images": [],
      "manufacturer": "Eveline Cosmetics S.A. sp.k.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Эмульсия для тела `SUN LOOK` солнцезащитная водостойкая SPF-30 150 мл",
      "type": "SKU",
      "weight_plan": 182
    },
    {
      "article": "215105",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809663574536",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 170,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "215105",
      "fragile": false,
      "id": "0Q-215105",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Роликовый корректор для контура глаз и носогубных складок `CHASIN` RABBITS` с матчей (увлажняющий) 15 мл",
      "type": "SKU",
      "weight_plan": 68
    },
    {
      "article": "209598",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809517475873",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 60,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "209598",
      "fragile": false,
      "id": "0Q-209598",
      "images": [],
      "manufacturer": "PICOSTECH CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `DEWYTREE` с коллагеном (увлажняющий, питательный и укрепляющий) 50 мл",
      "type": "SKU",
      "weight_plan": 120
    },
    {
      "article": "227723",
      "barcodes": [
        {
          "active": true,
          "barcode": "5000204113211",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "227723",
      "fragile": false,
      "id": "0Q-227723",
      "images": [],
      "manufacturer": "SC JOHNSON",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Освежитель воздуха `GLADE` Лазурная волна и магнолия 300 мл",
      "type": "SKU",
      "weight_plan": 340
    },
    {
      "article": "242855",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196525896",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "242855",
      "fragile": false,
      "id": "0Q-242855",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `LIMITED` FLOWER BLOOM тон 09 10 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "241930",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809852548546",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 90,
        "height": 180,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "241930",
      "fragile": false,
      "id": "0Q-241930",
      "images": [],
      "manufacturer": "JOR R & D CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для душа `JMSOLUTION` DISNEY с экстрактом медовой гардении 500 мл",
      "type": "SKU",
      "weight_plan": 560
    },
    {
      "article": "228318",
      "barcodes": [
        {
          "active": true,
          "barcode": "9000100728201",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4015100437249",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "228318",
      "fragile": false,
      "id": "0Q-228318",
      "images": [],
      "manufacturer": "ЛАБ-ИНДАСТРИЗ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для волос `ТАФТ` ОБЪЕМ для истонченных волос (сверхсильная фиксация) 250 мл",
      "type": "SKU",
      "weight_plan": 242
    },
    {
      "article": "226966",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971451020",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 270,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "226966",
      "fragile": false,
      "id": "0Q-226966",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `SYNERGETIC` для чистки сантехники «Чайное дерево и эвкалипт» 700 мл",
      "type": "SKU",
      "weight_plan": 723
    },
    {
      "article": "133905",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806185733137",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "133905",
      "fragile": false,
      "id": "0Q-133905",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка для умывания `A`PIEU` `DEEP CLEAN` 200 мл",
      "type": "SKU",
      "weight_plan": 267
    },
    {
      "article": "242247",
      "barcodes": [
        {
          "active": true,
          "barcode": "3574661684307",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 280,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "242247",
      "fragile": false,
      "id": "0Q-242247",
      "images": [],
      "manufacturer": "JOHNSON & JOHNSON",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ополаскиватель для полости рта `LISTERINE` FLAVOURS Lime & Mint 500 мл",
      "type": "SKU",
      "weight_plan": 563
    },
    {
      "article": "134563",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290012934322",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 100,
        "height": 80,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 100,
        "height": 80,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "134563",
      "fragile": false,
      "id": "0Q-134563",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Масло для тела `SEA OF SPA` Цветение жасмина и зеленого чая 350 мл",
      "type": "SKU",
      "weight_fact": 451,
      "weight_plan": 451
    },
    {
      "article": "103996",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104282506",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4897045271665",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 220,
        "width": 130
      },
      "expiration_months": 36,
      "external_id": "103996",
      "fragile": false,
      "id": "0Q-103996",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `KHARISMA VOLTAGE` с кератином и коллагеном 500 мл",
      "type": "SKU",
      "weight_plan": 520
    },
    {
      "article": "245846",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329044752",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 130,
        "width": 20
      },
      "expiration_months": 24,
      "external_id": "245846",
      "fragile": false,
      "id": "0Q-245846",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Блеск-бальзам для губ `LUXVISAGE` JELLY Rose Увлажнение",
      "type": "SKU",
      "weight_plan": 26
    },
    {
      "article": "213309",
      "barcodes": [
        {
          "active": true,
          "barcode": "6221155023612",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 80,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "213309",
      "fragile": false,
      "id": "0Q-213309",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло твердое `CAMAY` Динамик 85 г",
      "type": "SKU",
      "weight_plan": 85
    },
    {
      "article": "150466",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104621534",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4640010713680",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 210,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "150466",
      "fragile": false,
      "id": "0Q-150466",
      "images": [],
      "manufacturer": "AEROFA AEROSOL DOLUM SAN. TIC. A.S.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `KENSUKO` Volume (сухой) 200 мл",
      "type": "SKU",
      "weight_fact": 156,
      "weight_plan": 156
    },
    {
      "article": "121441",
      "barcodes": [
        {
          "active": true,
          "barcode": "5900516311926",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5900516312022",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 170,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 170,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "121441",
      "fragile": false,
      "id": "0Q-121441",
      "images": [],
      "manufacturer": "BELLA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Прокладки ежедневные `BELLA` PANTY Soft 20 шт",
      "type": "SKU",
      "weight_fact": 53,
      "weight_plan": 53
    },
    {
      "article": "159329",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809486360842",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "8809486362266",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 140,
        "width": 170
      },
      "expiration_months": 36,
      "external_id": "159329",
      "fragile": false,
      "id": "0Q-159329",
      "images": [],
      "manufacturer": "C&TECH CORP",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BANOBAGI` VITA GENIC Увлажняющая 30 мл",
      "type": "SKU",
      "weight_plan": 36
    },
    {
      "article": "234973",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809747954278",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "234973",
      "fragile": false,
      "id": "0Q-234973",
      "images": [],
      "manufacturer": "COSMECCA KOREA CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка для умывания `A`PIEU` TEA TREE очищающая 200 мл",
      "type": "SKU",
      "weight_plan": 241
    },
    {
      "article": "196686",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196504464",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "196686",
      "fragile": false,
      "id": "0Q-196686",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Краска для волос `KENSUKO` Тон 5.0 (Темно-русый) 50 мл",
      "type": "SKU",
      "weight_fact": 140,
      "weight_plan": 140
    },
    {
      "article": "219427",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971450535",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 130,
        "width": 180
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 130,
        "width": 180
      },
      "expiration_months": 48,
      "external_id": "219427",
      "fragile": false,
      "id": "0Q-219427",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Таблетки для посудомоечных машин `SYNERGETIC` Биоразлагаемые бесфосфатные 25 шт",
      "type": "SKU",
      "weight_fact": 567,
      "weight_plan": 567
    },
    {
      "article": "246388",
      "barcodes": [
        {
          "active": true,
          "barcode": "4015100805802",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 150,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "246388",
      "fragile": false,
      "id": "0Q-246388",
      "images": [],
      "manufacturer": "SCHWARZKOPF & HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для укладки волос `ТАФТ` ULTRA экспресс-укладка 150 мл",
      "type": "SKU",
      "weight_plan": 171
    },
    {
      "article": "219118",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196504839",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 190,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 190,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "219118",
      "fragile": false,
      "id": "0Q-219118",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS (HONG KONG) LIMITED",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Бустер `GIS` для кудрявых и вьющихся волос 200 мл",
      "type": "SKU",
      "weight_fact": 255,
      "weight_plan": 255
    },
    {
      "article": "191885",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159422238",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 140,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 140,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "191885",
      "fragile": false,
      "id": "0Q-191885",
      "images": [],
      "manufacturer": "HUIZHOU JIAJIALI COSMETIC CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для укладки волос `KENSUKO` разглаживающий 100 мл",
      "type": "SKU",
      "weight_fact": 123,
      "weight_plan": 123
    },
    {
      "article": "173572",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104775879",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4630160531289",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 160,
        "width": 130
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 160,
        "width": 130
      },
      "expiration_months": 0,
      "external_id": "173572",
      "fragile": false,
      "id": "0Q-173572",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спонж для очищения лица `DECO.` CLEAN (круглый) 9,5 см",
      "type": "SKU",
      "weight_fact": 25,
      "weight_plan": 25
    },
    {
      "article": "242170",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523915",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 170,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "242170",
      "fragile": false,
      "id": "0Q-242170",
      "images": [],
      "manufacturer": "АРОМА ПРОМ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка-мусс для умывания `LP CARE` 150 мл",
      "type": "SKU",
      "weight_fact": 300,
      "weight_plan": 204
    },
    {
      "article": "210421",
      "barcodes": [
        {
          "active": true,
          "barcode": "8807779097703",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 200,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 200,
        "width": 80
      },
      "expiration_months": 1,
      "external_id": "210421",
      "fragile": false,
      "id": "0Q-210421",
      "images": [],
      "manufacturer": "DOORI COSMETICS CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `EGG PLANET` питательная 200 мл",
      "type": "SKU",
      "weight_fact": 222,
      "weight_plan": 222
    },
    {
      "article": "12793",
      "barcodes": [
        {
          "active": true,
          "barcode": "4601726007609",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "8717163094952",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "8717163286975",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 300,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 300,
        "width": 90
      },
      "expiration_months": 18,
      "external_id": "12793",
      "fragile": false,
      "id": "0Q-12793",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `DOMESTOS` Свежесть атлантики (жидкое) 1 л",
      "type": "SKU",
      "weight_fact": 1000,
      "weight_plan": 1000
    },
    {
      "article": "208956",
      "barcodes": [
        {
          "active": true,
          "barcode": "8480029434963",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 210,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "208956",
      "fragile": false,
      "id": "0Q-208956",
      "images": [],
      "manufacturer": "NUVARIA GLOBAL, S.L.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь-кондиционер для волос `DICORA URBAN FIT` 2 в 1 мужской 400 мл",
      "type": "SKU",
      "weight_plan": 447
    },
    {
      "article": "29803",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600697030463",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "3600697030463",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4602573070082",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 210,
        "width": 120
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 210,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "29803",
      "fragile": false,
      "id": "0Q-29803",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для детского белья `УШАСТЫЙ НЯНЬ` 750 мл",
      "type": "SKU",
      "weight_fact": 750,
      "weight_plan": 750
    },
    {
      "article": "124408",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104438019",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400105146753",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4640010716568",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 80,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 80,
        "width": 100
      },
      "expiration_months": 24,
      "external_id": "124408",
      "fragile": false,
      "id": "0Q-124408",
      "images": [],
      "manufacturer": "GLISH Co., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Клей для ресниц и пучков `DECO.` EYELASHES ADHESIVE гипоаллергенный (на латексной основе) 5 мл",
      "type": "SKU",
      "weight_fact": 21,
      "weight_plan": 21
    },
    {
      "article": "206154",
      "barcodes": [
        {
          "active": true,
          "barcode": "4752171009417",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 240,
        "width": 140
      },
      "expiration_months": 37,
      "external_id": "206154",
      "fragile": false,
      "id": "0Q-206154",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло жидкое `OLEA` ECO BOTANIC Ветивер и бергамот (дой-пак) 450 мл",
      "type": "SKU",
      "weight_plan": 479
    },
    {
      "article": "238978",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196522260",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 180,
        "width": 20
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 180,
        "width": 20
      },
      "expiration_months": 0,
      "external_id": "238978",
      "fragile": false,
      "id": "0Q-238978",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кисть для нанесения теней `ISCREAM` HEART BEAT",
      "type": "SKU",
      "weight_fact": 17,
      "weight_plan": 17
    },
    {
      "article": "204699",
      "barcodes": [
        {
          "active": true,
          "barcode": "4623721671432",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 280,
        "width": 90
      },
      "expiration_months": 24,
      "external_id": "204699",
      "fragile": false,
      "id": "0Q-204699",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `SYNERGETIC` Миндальное молочко 1000 мл",
      "type": "SKU",
      "weight_plan": 1097
    },
    {
      "article": "246390",
      "barcodes": [
        {
          "active": true,
          "barcode": "4015100805901",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 200,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "246390",
      "fragile": false,
      "id": "0Q-246390",
      "images": [],
      "manufacturer": "SCHWARZKOPF & HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пена для укладки волос `ТАФТ` ULTRA сверхсильная фиксация (4) 150 мл",
      "type": "SKU",
      "weight_plan": 178
    },
    {
      "article": "242244",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196524073",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 140,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "242244",
      "fragile": false,
      "id": "0Q-242244",
      "images": [],
      "manufacturer": "DONGGUAN TAIMENG ACCESSORIES CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Блеск-бальзам для губ `LP CARE` BUBBLE Бабл гам 3 мл",
      "type": "SKU",
      "weight_plan": 50
    },
    {
      "article": "231681",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809901210035",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 200,
        "width": 120
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 200,
        "width": 120
      },
      "expiration_months": 1,
      "external_id": "231681",
      "fragile": false,
      "id": "0Q-231681",
      "images": [],
      "manufacturer": "BEAUTY INSIDE CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `ESFOLIO` 3HA с AHA, BHA и PHA – кислотами (смягчающая) 25 мл",
      "type": "SKU",
      "weight_fact": 34,
      "weight_plan": 34
    },
    {
      "article": "201419",
      "barcodes": [
        {
          "active": true,
          "barcode": "5903416003779",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 120,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "201419",
      "fragile": false,
      "id": "0Q-201419",
      "images": [],
      "manufacturer": "EVELINE COSMETICS S.A. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей-фиксатор макияжа `EVELINE` MATTIFYING FIXER MIST FULL HD матирующий 50 мл",
      "type": "SKU",
      "weight_fact": 62,
      "weight_plan": 62
    },
    {
      "article": "173592",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160535737",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400104776005",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 80,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "173592",
      "fragile": false,
      "id": "0Q-173592",
      "images": [],
      "manufacturer": "YIWU YUN PACKING CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Блестки для лица, тела и волос `DECO.` by Miami tattoos (Mermaid)",
      "type": "SKU",
      "weight_plan": 20
    },
    {
      "article": "69312",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600702084566",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4600702088441",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 220,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 220,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "69312",
      "fragile": false,
      "id": "0Q-69312",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ополаскиватель для полости рта `ЛЕСНОЙ БАЛЬЗАМ` с экстрактом коры дуба и пихты на отваре трав 400 мл",
      "type": "SKU",
      "weight_fact": 454,
      "weight_plan": 454
    },
    {
      "article": "189932",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159420791",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 220,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 220,
        "width": 90
      },
      "expiration_months": 0,
      "external_id": "189932",
      "fragile": false,
      "id": "0Q-189932",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для сна и путешествий `DECO.` с чехлом (brulee)",
      "type": "SKU",
      "weight_fact": 53,
      "weight_plan": 53
    },
    {
      "article": "229949",
      "barcodes": [
        {
          "active": true,
          "barcode": "8720633009912",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 190,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 50
      },
      "expiration_months": 30,
      "external_id": "229949",
      "fragile": false,
      "id": "0Q-229949",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Део-спрей жен. `REXONA` УЛЬТРАНЕВИДИМАЯ 72ч (антиперспирант) 150 мл",
      "type": "SKU",
      "weight_fact": 125,
      "weight_plan": 125
    },
    {
      "article": "100967",
      "barcodes": [
        {
          "active": true,
          "barcode": "4605922011859",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 170,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 170,
        "width": 50
      },
      "expiration_months": 30,
      "external_id": "100967",
      "fragile": false,
      "id": "0Q-100967",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Део-спрей муж. `REXONA` `MEN` НЕВИДИМЫЙ антиперспирант 150 мл",
      "type": "SKU",
      "weight_fact": 131,
      "weight_plan": 131
    },
    {
      "article": "219415",
      "barcodes": [
        {
          "active": true,
          "barcode": "9000100950404",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 290,
        "width": 100
      },
      "expiration_months": 24,
      "external_id": "219415",
      "fragile": false,
      "id": "0Q-219415",
      "images": [],
      "manufacturer": "HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `E` Fresh 1 л",
      "type": "SKU",
      "weight_plan": 1058
    },
    {
      "article": "240994",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523236",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 20,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "240994",
      "fragile": false,
      "id": "0Q-240994",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Румяна для лица `ISCREAM` FLOWER FLOW тон 03",
      "type": "SKU",
      "weight_plan": 28
    },
    {
      "article": "216050",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196502156",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 60,
        "width": 110
      },
      "expiration_months": 36,
      "external_id": "216050",
      "fragile": false,
      "id": "0Q-216050",
      "images": [],
      "manufacturer": "ONUGE PERSONAL CARE (GUANGZHOU) CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Полоски для зубов отбеливающие `LP CARE` DENTAL Mint 1 пара",
      "type": "SKU",
      "weight_plan": 3
    },
    {
      "article": "226777",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196511752",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 210,
        "width": 90
      },
      "expiration_months": 12,
      "external_id": "226777",
      "fragile": false,
      "id": "0Q-226777",
      "images": [],
      "manufacturer": "COSMEWAX, S.A.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Полоски восковые для бикини `VOX` GREEN 12 шт + 2 салфетки",
      "type": "SKU",
      "weight_plan": 56
    },
    {
      "article": "167086",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104726246",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4640010714564",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 180,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 180,
        "width": 60
      },
      "expiration_months": 0,
      "external_id": "167086",
      "fragile": false,
      "id": "0Q-167086",
      "images": [],
      "manufacturer": "YADE COSMETIC (DONGGUAN) CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кисть `DECO.` для пудры выкручивающаяся № 113",
      "type": "SKU",
      "weight_fact": 76,
      "weight_plan": 76
    },
    {
      "article": "210414",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809643547345",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "8809643547949",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 170,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "210414",
      "fragile": false,
      "id": "0Q-210414",
      "images": [],
      "manufacturer": "Y & K HEALTHCARE CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Точечные пластыри для лица `A`PIEU` FUSIDIUM против несовершенств кожи 60 шт",
      "type": "SKU",
      "weight_plan": 9
    },
    {
      "article": "240993",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523229",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 20,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "240993",
      "fragile": false,
      "id": "0Q-240993",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Румяна для лица `ISCREAM` FLOWER FLOW тон 02",
      "type": "SKU",
      "weight_plan": 28
    },
    {
      "article": "242851",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196525841",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "242851",
      "fragile": false,
      "id": "0Q-242851",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `LIMITED` FLOWER BLOOM тон 05 10 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "211873",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640018992346",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 70,
        "width": 130
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 70,
        "width": 130
      },
      "expiration_months": 30,
      "external_id": "211873",
      "fragile": false,
      "id": "0Q-211873",
      "images": [],
      "manufacturer": "RECKITT BENCKISER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тест для определения беременности `EVITEST` №2",
      "type": "SKU",
      "weight_fact": 10,
      "weight_plan": 10
    },
    {
      "article": "210901",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809446655193",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "210901",
      "fragile": false,
      "id": "0Q-210901",
      "images": [],
      "manufacturer": "SINDO P & G CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `GRACE DAY` MULTI-VITAMIN с экстрактом клубники (увлажняющая) 27 мл",
      "type": "SKU",
      "weight_fact": 32,
      "weight_plan": 32
    },
    {
      "article": "111111",
      "barcodes": [
        {
          "active": true,
          "barcode": "46149886",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 120,
        "width": 60
      },
      "expiration_months": 30,
      "external_id": "111111",
      "fragile": false,
      "id": "0Q-111111",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Део-стик жен. `REXONA` СУХОСТЬ ПУДРЫ 40 мл",
      "type": "SKU",
      "weight_plan": 80
    },
    {
      "article": "204823",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809589392665",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 80,
        "height": 70,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 80,
        "height": 70,
        "width": 80
      },
      "expiration_months": 1,
      "external_id": "204823",
      "fragile": false,
      "id": "0Q-204823",
      "images": [],
      "manufacturer": "COSTEM CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `ESFOLIO` кислородная глиняная (для очищения и сужения пор) 100 г",
      "type": "SKU",
      "weight_fact": 185,
      "weight_plan": 185
    },
    {
      "article": "227128",
      "barcodes": [
        {
          "active": true,
          "barcode": "7942361015756",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 150,
        "height": 240,
        "width": 150
      },
      "expiration_months": 1,
      "external_id": "227128",
      "fragile": false,
      "id": "0Q-227128",
      "images": [],
      "manufacturer": "ПАПИРЮГ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Полотенца бумажные `PLUSHE` Maxi 2-х слойные 1 шт",
      "type": "SKU",
      "weight_plan": 340
    },
    {
      "article": "34426",
      "barcodes": [
        {
          "active": true,
          "barcode": "8411061636268",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 120,
        "width": 80
      },
      "expiration_months": 30,
      "external_id": "34426",
      "fragile": false,
      "id": "0Q-34426",
      "images": [],
      "manufacturer": "ANTONIO PUIG",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Туалетная вода `ANTONIO BANDERAS` BLUE SEDUCTION (муж.) 100 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_plan": 328
    },
    {
      "article": "196440",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196500213",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 200,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 200,
        "width": 100
      },
      "expiration_months": 0,
      "external_id": "196440",
      "fragile": false,
      "id": "0Q-196440",
      "images": [],
      "manufacturer": "NINGBO KAILI HOLDING GROUP CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Станок для бритья `VOX` 3 лезвия с 1 сменной кассетой (Алоэ вера)",
      "type": "SKU",
      "weight_fact": 54,
      "weight_plan": 54
    },
    {
      "article": "245258",
      "barcodes": [
        {
          "active": true,
          "barcode": "4627097721821",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 310,
        "width": 50
      },
      "expiration_months": 120,
      "external_id": "245258",
      "fragile": false,
      "id": "0Q-245258",
      "images": [],
      "manufacturer": "ХОУМ ЭКСПЕРТ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Рукав для запекания `HOMEX` с завязками (3м х 29см) Очень длинные",
      "type": "SKU",
      "weight_plan": 25
    },
    {
      "article": "217211",
      "barcodes": [
        {
          "active": true,
          "barcode": "4813409000658",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 140,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "217211",
      "fragile": false,
      "id": "0Q-217211",
      "images": [],
      "manufacturer": "ФЭМИЛИ ФОРЭВА ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гидрофильное конопляное масло `FAMILY FOREVER FACTORY` 100 мл",
      "type": "SKU",
      "weight_plan": 200
    },
    {
      "article": "242270",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196524448",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 130,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "242270",
      "fragile": false,
      "id": "0Q-242270",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка для умывания `LP CARE` 50 мл",
      "type": "SKU",
      "weight_plan": 150
    },
    {
      "article": "242854",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196525872",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 70,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "242854",
      "fragile": false,
      "id": "0Q-242854",
      "images": [],
      "manufacturer": "VIPERA SP. Z O.O. SP. K",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для ногтей `PINK UP` `LIMITED` FLOWER BLOOM тон 08 10 мл",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "166520",
      "barcodes": [
        {
          "active": true,
          "barcode": "5060315052816",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4640010710283",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 150,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "166520",
      "fragile": false,
      "id": "0Q-166520",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Парфюмерная вода`BESTIES` PERFUME SPRAY black velvet (унисекс) 30 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_plan": 66
    },
    {
      "article": "200064",
      "barcodes": [
        {
          "active": true,
          "barcode": "8681922113676",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4640010710597",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "8681982211367",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 140,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 140,
        "width": 60
      },
      "expiration_months": 108,
      "external_id": "200064",
      "fragile": false,
      "id": "0Q-200064",
      "images": [],
      "manufacturer": "BERLIN KOZMETIK SAN. TIC. LTD. STI",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Туалетная вода `CLIVE & KEIRA` SKANDAL (жен.) 30 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_fact": 192,
      "weight_plan": 192
    },
    {
      "article": "240997",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523427",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 70,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "240997",
      "fragile": false,
      "id": "0Q-240997",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Палетка для скульптурирования `ISCREAM` FLOWER FLOW тон 01-03",
      "type": "SKU",
      "weight_plan": 55
    },
    {
      "article": "245843",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329045568",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 110,
        "width": 20
      },
      "expiration_months": 31,
      "external_id": "245843",
      "fragile": false,
      "id": "0Q-245843",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тени для век `LUXVISAGE` SATIN NUDE Super stay жидкие тон 206 Nude brown",
      "type": "SKU",
      "weight_plan": 21
    },
    {
      "article": "93947",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600697122090",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "93947",
      "fragile": false,
      "id": "0Q-93947",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-мыло жидкое `УШАСТЫЙ НЯНЬ` с оливковым маслом и экстрактом алоэ вера 300 мл",
      "type": "SKU",
      "weight_plan": 338
    },
    {
      "article": "227720",
      "barcodes": [
        {
          "active": true,
          "barcode": "4620000432951",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 230,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "227720",
      "fragile": false,
      "id": "0Q-227720",
      "images": [],
      "manufacturer": "SC JOHNSON",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Освежитель воздуха `GLADE` Индонезийский сандал 300 мл",
      "type": "SKU",
      "weight_fact": 340,
      "weight_plan": 340
    },
    {
      "article": "140136",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809530037928",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 60,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 60,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "140136",
      "fragile": false,
      "id": "0Q-140136",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `A`PIEU` GOOD NIGHT ночная 105 мл",
      "type": "SKU",
      "weight_fact": 207,
      "weight_plan": 207
    },
    {
      "article": "238358",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630234042901",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 80
      },
      "expiration_months": 24,
      "external_id": "238358",
      "fragile": false,
      "id": "0Q-238358",
      "images": [],
      "manufacturer": "ГЛОБАЛ БИО КОСМЕТИК ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для интимной гигиены `INTIMLY` с пребиотиками 220 мл",
      "type": "SKU",
      "weight_plan": 297
    },
    {
      "article": "231661",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809581450646",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 130,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "231661",
      "fragile": false,
      "id": "0Q-231661",
      "images": [],
      "manufacturer": "COSMECCA KOREA CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица `A`PIEU` PURE BLOCK солнцезащитный SPF-50+ (увлажняющий) 50 мл",
      "type": "SKU",
      "weight_plan": 65
    },
    {
      "article": "210316",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600999011412",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 210,
        "width": 150
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 210,
        "width": 150
      },
      "expiration_months": 37,
      "external_id": "210316",
      "fragile": false,
      "id": "0Q-210316",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-мыло жидкое `Я САМАЯ` BIO Персик и миндальное молочко 500 мл",
      "type": "SKU",
      "weight_fact": 520,
      "weight_plan": 520
    },
    {
      "article": "223737",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400105030854",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 20,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 20,
        "width": 30
      },
      "expiration_months": 1,
      "external_id": "223737",
      "fragile": false,
      "id": "0Q-223737",
      "images": [],
      "manufacturer": "SHENZHEN N-DEER FASHION CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Косметичка `LADY PINK`",
      "type": "SKU",
      "weight_fact": 160,
      "weight_plan": 160
    },
    {
      "article": "213822",
      "barcodes": [
        {
          "active": true,
          "barcode": "5907731958196",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 200,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 200,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "213822",
      "fragile": false,
      "id": "0Q-213822",
      "images": [],
      "manufacturer": "BE BIO ACTIVECOSMETIQS -MIND NETWORK INSPIRE SP. Z O.O. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `BEBIO` натуральный (для жирных волос) 300 мл",
      "type": "SKU",
      "weight_fact": 354,
      "weight_plan": 354
    },
    {
      "article": "210329",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610113160773",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 200,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 200,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "210329",
      "fragile": false,
      "id": "0Q-210329",
      "images": [],
      "manufacturer": "ООО БИОТЕК ФАРМ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло жидкое `BIOHELPY` натуральное (Черная смородина и мята) 400 мл",
      "type": "SKU",
      "weight_fact": 442,
      "weight_plan": 442
    },
    {
      "article": "227065",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600697030470",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 90,
        "height": 270,
        "width": 130
      },
      "expiration_months": 1,
      "external_id": "227065",
      "fragile": false,
      "id": "0Q-227065",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `УШАСТЫЙ НЯНЬ` с алоэ вера 1200 мл",
      "type": "SKU",
      "weight_plan": 1338
    },
    {
      "article": "234151",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196517389",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "234151",
      "fragile": false,
      "id": "0Q-234151",
      "images": [],
      "manufacturer": "ОЛБО ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Масло для ногтей и кутикулы `PINK UP` `BEAUTY` Vitamin oil (с витаминами) 15 мл",
      "type": "SKU",
      "weight_plan": 23
    },
    {
      "article": "227283",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400105048484",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 250,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "227283",
      "fragile": false,
      "id": "0Q-227283",
      "images": [],
      "manufacturer": "SHENZHEN N-DEER FASHION CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Расческа для сушки волос `LADY PINK` `BASIC` черная",
      "type": "SKU",
      "weight_plan": 52
    },
    {
      "article": "08730",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400000145059",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "9000101409321",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "9000100234801",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "08730",
      "fragile": false,
      "id": "0Q-08730",
      "images": [],
      "manufacturer": "HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для стирки жидкое `ЛАСКА` Сияние черного 1 л",
      "type": "SKU",
      "weight_fact": 1000,
      "weight_plan": 1000
    },
    {
      "article": "211855",
      "barcodes": [
        {
          "active": true,
          "barcode": "4602228001829",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 170,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 170,
        "width": 70
      },
      "expiration_months": 24,
      "external_id": "211855",
      "fragile": false,
      "id": "0Q-211855",
      "images": [],
      "manufacturer": "RECKITT BENCKISER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель-смазка интимная `DUREX` PLAY для массажа 2 in 1 Sensual (с Иланг-Илангом) 200 мл",
      "type": "SKU",
      "weight_fact": 242,
      "weight_plan": 242
    },
    {
      "article": "229202",
      "barcodes": [
        {
          "active": true,
          "barcode": "4001499947537",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 270,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "229202",
      "fragile": false,
      "id": "0Q-229202",
      "images": [],
      "manufacturer": "FROSCH",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `FROSCH` универсальное, малина 750 мл",
      "type": "SKU",
      "weight_plan": 840
    },
    {
      "article": "73714",
      "barcodes": [
        {
          "active": true,
          "barcode": "8711700714990",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "expiration_months": 30,
      "external_id": "73714",
      "fragile": false,
      "id": "0Q-73714",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Део-спрей жен. `DOVE` БЕРЕЖНАЯ ЗАБОТА (для чувствительной кожи) 150 мл",
      "type": "SKU",
      "weight_plan": 124
    },
    {
      "article": "229190",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809486364222",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 140,
        "width": 160
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 140,
        "width": 160
      },
      "expiration_months": 1,
      "external_id": "229190",
      "fragile": false,
      "id": "0Q-229190",
      "images": [],
      "manufacturer": "C&TECH CORP",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BANOBAGI` с коллагеном и экстрактом хауттюйнии сердцевидной (успокаивающая) 30 г",
      "type": "SKU",
      "weight_fact": 34,
      "weight_plan": 34
    },
    {
      "article": "134442",
      "barcodes": [
        {
          "active": true,
          "barcode": "8801173801751",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 100,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "134442",
      "fragile": false,
      "id": "0Q-134442",
      "images": [],
      "manufacturer": "MUKUNGHWA CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло `MUKUNGHWA` с молоком 90 г",
      "type": "SKU",
      "weight_plan": 108
    },
    {
      "article": "245851",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329046817",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 150,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "245851",
      "fragile": false,
      "id": "0Q-245851",
      "images": [],
      "manufacturer": "NINGBO EYECOS COSMETIC CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш для бровей `LUXVISAGE` BROWISSIMO Ultra slim super stay 24H механический тон 312 Smoky",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "129331",
      "barcodes": [
        {
          "active": true,
          "barcode": "8803348017677",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 70
      },
      "expiration_months": 1,
      "external_id": "129331",
      "fragile": false,
      "id": "0Q-129331",
      "images": [],
      "manufacturer": "INTERKOS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка для умывания `ESFOLIO` Молочная 150 г",
      "type": "SKU",
      "weight_plan": 197
    },
    {
      "article": "213183",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971451716",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 190,
        "width": 120
      },
      "expiration_months": 60,
      "external_id": "213183",
      "fragile": false,
      "id": "0Q-213183",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пятновыводитель и отбеливатель для белья `SYNERGETIC` 10 шт",
      "type": "SKU",
      "weight_plan": 296
    },
    {
      "article": "135765",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016846003",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 160,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "135765",
      "fragile": false,
      "id": "0Q-135765",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Део-ролл жен. `SEA OF SPA` `BIOSPA` гипоаллергенный 100 мл",
      "type": "SKU",
      "weight_plan": 146
    },
    {
      "article": "210317",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600999011436",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 210,
        "width": 150
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 210,
        "width": 150
      },
      "expiration_months": 37,
      "external_id": "210317",
      "fragile": false,
      "id": "0Q-210317",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мыло жидкое `Я САМАЯ` BIO Масло арганы и орхидея 500 мл",
      "type": "SKU",
      "weight_fact": 519,
      "weight_plan": 519
    },
    {
      "article": "124609",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104439931",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4897045272600",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 120,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 120,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "124609",
      "fragile": false,
      "id": "0Q-124609",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сыворотка для волос `KENSUKO` разглаживающая 30 мл",
      "type": "SKU",
      "weight_fact": 103,
      "weight_plan": 103
    },
    {
      "article": "00640",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600702014297",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4600702080704",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "8720182994363",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 150,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "00640",
      "fragile": false,
      "id": "0Q-00640",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица дневной `ЧИСТАЯ ЛИНИЯ` ФИТОТЕРАПИЯ легкий увлажняющий (для нормальной и комбинированной кожи) 42 мл",
      "type": "SKU",
      "weight_plan": 56
    },
    {
      "article": "241961",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809628885684",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 180,
        "width": 130
      },
      "expiration_months": 36,
      "external_id": "241961",
      "fragile": false,
      "id": "0Q-241961",
      "images": [],
      "manufacturer": "JEONGIN CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `TENZERO` GLOW AMPOULE с коллагеном 25 мл",
      "type": "SKU",
      "weight_plan": 31
    },
    {
      "article": "219441",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971454373",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 230,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 230,
        "width": 80
      },
      "expiration_months": 24,
      "external_id": "219441",
      "fragile": false,
      "id": "0Q-219441",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Освежитель-спрей для помещений `SYNERGETIC` Пачули и нероли (гипоаллергенный биоразлагаемый) 380 мл",
      "type": "SKU",
      "weight_fact": 420,
      "weight_plan": 420
    },
    {
      "article": "212882",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607051797543",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 220,
        "width": 130
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 220,
        "width": 130
      },
      "expiration_months": 24,
      "external_id": "212882",
      "fragile": false,
      "id": "0Q-212882",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Соль для ванн `ФИТОКОСМЕТИК` Бишофитная (для снижения веса) 530 г",
      "type": "SKU",
      "weight_fact": 535,
      "weight_plan": 535
    },
    {
      "article": "229201",
      "barcodes": [
        {
          "active": true,
          "barcode": "4001499140648",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 270,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 270,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "229201",
      "fragile": false,
      "id": "0Q-229201",
      "images": [],
      "manufacturer": "FROSCH",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `FROSCH` универсальное, апельсин 750 мл",
      "type": "SKU",
      "weight_fact": 840,
      "weight_plan": 840
    },
    {
      "article": "234403",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610214380919",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 170,
        "width": 170
      },
      "expiration_months": 120,
      "external_id": "234403",
      "fragile": false,
      "id": "0Q-234403",
      "images": [],
      "manufacturer": "ЛИЛИЯ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки бумажные `PERO` PRESTIGE трехслойные Баклажан 20 шт",
      "type": "SKU",
      "weight_plan": 110
    },
    {
      "article": "231679",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809901210059",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 90,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 90,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "231679",
      "fragile": false,
      "id": "0Q-231679",
      "images": [],
      "manufacturer": "BEAUTY INSIDE CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пудра для лица `ESFOLIO` 3HA с AHA,BHA и PHA - кислотами (матирующая) 5 г",
      "type": "SKU",
      "weight_fact": 38,
      "weight_plan": 38
    },
    {
      "article": "232579",
      "barcodes": [
        {
          "active": true,
          "barcode": "5904181932271",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5904181932387",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 190,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "232579",
      "fragile": false,
      "id": "0Q-232579",
      "images": [],
      "manufacturer": "4MYORGANIC SP.Z.O.O. SP.K.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для интимной гигиены гипоаллергенный `4ORGANIC` Sensitive 350 мл",
      "type": "SKU",
      "weight_plan": 407
    },
    {
      "article": "221429",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971453154",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 260,
        "width": 100
      },
      "expiration_months": 24,
      "external_id": "221429",
      "fragile": false,
      "id": "0Q-221429",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `SYNERGETIC` универсальное с ароматом мандарина и лемонграсса 500 мл",
      "type": "SKU",
      "weight_plan": 594
    },
    {
      "article": "245854",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329046909",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 130,
        "width": 10
      },
      "expiration_months": 24,
      "external_id": "245854",
      "fragile": false,
      "id": "0Q-245854",
      "images": [],
      "manufacturer": "NINGBO EYECOS COSMETIC CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш-каял для глаз `LUXVISAGE` SOFT KAJAL Super stay механический тон Black",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "143094",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809386881294",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 50,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 50,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "143094",
      "fragile": false,
      "id": "0Q-143094",
      "images": [],
      "manufacturer": "INTERKOS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Патчи для глаз `ESFOLIO` BLACK CAVIAR гидрогелевые с экстрактом черной икры 60 шт",
      "type": "SKU",
      "weight_fact": 198,
      "weight_plan": 198
    },
    {
      "article": "227033",
      "barcodes": [
        {
          "active": true,
          "barcode": "4602984020423",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 300,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 300,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "227033",
      "fragile": false,
      "id": "0Q-227033",
      "images": [],
      "manufacturer": "СТУПИНСКИЙ ХИМИЧЕСКИЙ ЗАВОД АО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство чистящее `GREEN LOVE` для ванны и душа 500 мл",
      "type": "SKU",
      "weight_fact": 575,
      "weight_plan": 575
    },
    {
      "article": "147368",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016846904",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 60,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 60,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "147368",
      "fragile": false,
      "id": "0Q-147368",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-масло для тела `SEA OF SPA` `BIOSPA` Финик, банан и кокос (питательное) 250 мл",
      "type": "SKU",
      "weight_fact": 247,
      "weight_plan": 247
    },
    {
      "article": "223004",
      "barcodes": [
        {
          "active": true,
          "barcode": "4897045274734",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 100,
        "height": 180,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 100,
        "height": 180,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "223004",
      "fragile": false,
      "id": "0Q-223004",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `KENSUKO` SCALP-RELIEF балансирующий 400 мл",
      "type": "SKU",
      "weight_fact": 586,
      "weight_plan": 586
    },
    {
      "article": "181708",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160530565",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104844476",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 140,
        "width": 180
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 140,
        "width": 180
      },
      "expiration_months": 0,
      "external_id": "181708",
      "fragile": false,
      "id": "0Q-181708",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Набор дорожных флаконов `DECO.` в косметичке 4 предмета (dream big)",
      "type": "SKU",
      "weight_fact": 69,
      "weight_plan": 69
    },
    {
      "article": "213843",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809414192231",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 180,
        "width": 130
      },
      "expiration_months": 36,
      "external_id": "213843",
      "fragile": false,
      "id": "0Q-213843",
      "images": [],
      "manufacturer": "LK COSMETICS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `BERGAMO` HELP! с экстрактом зеленого чая (успокаивающая и питательная) 25 мл",
      "type": "SKU",
      "weight_plan": 30
    },
    {
      "article": "154970",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600999020728",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 190,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 80
      },
      "expiration_months": 37,
      "external_id": "154970",
      "fragile": false,
      "id": "0Q-154970",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-гель для интимной гигиены `Я САМАЯ` INTIMATE с молочной кислотой и экстрактом Алоэ Вера 265 мл",
      "type": "SKU",
      "weight_fact": 319,
      "weight_plan": 319
    },
    {
      "article": "57905",
      "barcodes": [
        {
          "active": true,
          "barcode": "5900516311407",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 160,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 160,
        "width": 60
      },
      "expiration_months": 61,
      "external_id": "57905",
      "fragile": false,
      "id": "0Q-57905",
      "images": [],
      "manufacturer": "BELLA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Прокладки ежедневные `BELLA` PANTY SENSITIVE 20 шт",
      "type": "SKU",
      "weight_fact": 40,
      "weight_plan": 40
    },
    {
      "article": "227044",
      "barcodes": [
        {
          "active": true,
          "barcode": "4602984022243",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 310,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 310,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "227044",
      "fragile": false,
      "id": "0Q-227044",
      "images": [],
      "manufacturer": "СТУПИНСКИЙ ХИМИЧЕСКИЙ ЗАВОД АО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `BIS` магия карите (концентрированный) 900 мл",
      "type": "SKU",
      "weight_fact": 970,
      "weight_plan": 970
    },
    {
      "article": "237870",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196520402",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 100,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "237870",
      "fragile": false,
      "id": "0Q-237870",
      "images": [],
      "manufacturer": "ERTE KOZMETIK SANAYI VE TICARET A.S",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Парфюмерная вода `BESTIES` MEMORIES COLLECTION people talk 50 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_plan": 221
    },
    {
      "article": "221427",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971453598",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 220,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 220,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "221427",
      "fragile": false,
      "id": "0Q-221427",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель-бальзам для мытья посуды `SYNERGETIC` Розовый грейпфрут и специи 500 мл",
      "type": "SKU",
      "weight_fact": 560,
      "weight_plan": 560
    },
    {
      "article": "240992",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523212",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 20,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "240992",
      "fragile": false,
      "id": "0Q-240992",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Румяна для лица `ISCREAM` FLOWER FLOW тон 01",
      "type": "SKU",
      "weight_plan": 28
    },
    {
      "article": "243704",
      "barcodes": [
        {
          "active": true,
          "barcode": "4680043732418",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "expiration_months": 24,
      "external_id": "243704",
      "fragile": false,
      "id": "0Q-243704",
      "images": [],
      "manufacturer": "ДИНА+ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка-мусс для умывания `ИНДЕКС НАТУРАЛЬНОСТИ` с экстрактом яблока, AHA кислотами и гидролатом ромашки 150 мл",
      "type": "SKU",
      "weight_fact": 203,
      "weight_plan": 203
    },
    {
      "article": "233411",
      "barcodes": [
        {
          "active": true,
          "barcode": "4001499957536",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 90,
        "height": 270,
        "width": 140
      },
      "expiration_months": 1,
      "external_id": "233411",
      "fragile": false,
      "id": "0Q-233411",
      "images": [],
      "manufacturer": "FROSCH",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для белья `FROSCH` Миндальное молочко 1 л",
      "type": "SKU",
      "weight_plan": 1031
    },
    {
      "article": "49935",
      "barcodes": [
        {
          "active": true,
          "barcode": "4005808369904",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4005900996077",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 120,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 80
      },
      "expiration_months": 12,
      "external_id": "49935",
      "fragile": false,
      "id": "0Q-49935",
      "images": [],
      "manufacturer": "BEIERSDORF",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Бальзам для губ `NIVEA` Клубничное сияние 4,8 г",
      "type": "SKU",
      "weight_fact": 19,
      "weight_plan": 19
    },
    {
      "article": "120533",
      "barcodes": [
        {
          "active": true,
          "barcode": "4650001689122",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 190,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 80
      },
      "expiration_months": 37,
      "external_id": "120533",
      "fragile": false,
      "id": "0Q-120533",
      "images": [],
      "manufacturer": "КОТТОН КЛАБ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для интимной гигиены `Я САМАЯ` INTIMATE с молочной кислотой 265 мл",
      "type": "SKU",
      "weight_fact": 321,
      "weight_plan": 321
    },
    {
      "article": "226964",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971450184",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 250,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "226964",
      "fragile": false,
      "id": "0Q-226964",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для мытья пола и поверхностей `SYNERGETIC` Нежная чистота 750 мл",
      "type": "SKU",
      "weight_plan": 760
    },
    {
      "article": "177915",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160534136",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400104808836",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 60,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 60,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "177915",
      "fragile": false,
      "id": "0Q-177915",
      "images": [],
      "manufacturer": "GUANGZHOU KAGA COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Верхнее покрытие для ногтей UV/LED `PINK UP` `PRO` no-cleanse top coat без липкого слоя 10 мл",
      "type": "SKU",
      "weight_fact": 46,
      "weight_plan": 46
    },
    {
      "article": "202998",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809505547612",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 200,
        "width": 140
      },
      "expiration_months": 36,
      "external_id": "202998",
      "fragile": false,
      "id": "0Q-202998",
      "images": [],
      "manufacturer": "JOR R & D CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `JMSOLUTION` PRIME с экстрактом медузы (восстанавливающая) 33 мл",
      "type": "SKU",
      "weight_plan": 42
    },
    {
      "article": "72292",
      "barcodes": [
        {
          "active": true,
          "barcode": "6926939674652",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 90,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 90,
        "width": 100
      },
      "expiration_months": 36,
      "external_id": "72292",
      "fragile": false,
      "id": "0Q-72292",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `KHARISMA VOLTAGE` интенсивная увлажняющая 500 мл",
      "type": "SKU",
      "weight_fact": 500,
      "weight_plan": 500
    },
    {
      "article": "239216",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290019935001",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "7290010673117",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 140,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 140,
        "width": 40
      },
      "expiration_months": 60,
      "external_id": "239216",
      "fragile": false,
      "id": "0Q-239216",
      "images": [],
      "manufacturer": "SEA OF SPA DEAD SEA LABORATORIES LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Масло для тела `SEA OF SPA` `BIOSPA` массажное Ваниль и пачули 100 мл",
      "type": "SKU",
      "weight_fact": 114,
      "weight_plan": 114
    },
    {
      "article": "133898",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806185722094",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 170,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 170,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "133898",
      "fragile": false,
      "id": "0Q-133898",
      "images": [],
      "manufacturer": "HANBUL COSMETICS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гидрофильное масло для лица `A`PIEU` Календула 150 мл",
      "type": "SKU",
      "weight_fact": 220,
      "weight_plan": 220
    },
    {
      "article": "170282",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290017409986",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 210,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 210,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "170282",
      "fragile": false,
      "id": "0Q-170282",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для волос `SEA OF SPA` CANNABIO с конопляным и аргановым маслом (для всех типов волос) 400 мл",
      "type": "SKU",
      "weight_fact": 447,
      "weight_plan": 447
    },
    {
      "article": "226645",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196511684",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 90,
        "width": 40
      },
      "expiration_months": 42,
      "external_id": "226645",
      "fragile": false,
      "id": "0Q-226645",
      "images": [],
      "manufacturer": "ЗЕТТЕКНОЛОДЖИ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки влажные `LP CARE` ANIME универсальные бабл гам 8 шт",
      "type": "SKU",
      "weight_plan": 34
    },
    {
      "article": "219439",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971454366",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 230,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 230,
        "width": 80
      },
      "expiration_months": 24,
      "external_id": "219439",
      "fragile": false,
      "id": "0Q-219439",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Освежитель-спрей для помещений `SYNERGETIC` Ирис и розовый перец (гипоаллергенный биоразлагаемый) 380 мл",
      "type": "SKU",
      "weight_fact": 420,
      "weight_plan": 420
    },
    {
      "article": "227072",
      "barcodes": [
        {
          "active": true,
          "barcode": "5060434291936",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 300,
        "height": 330,
        "width": 120
      },
      "dimensions_plan": {
        "depth": 300,
        "height": 330,
        "width": 120
      },
      "expiration_months": 1,
      "external_id": "227072",
      "fragile": false,
      "id": "0Q-227072",
      "images": [],
      "manufacturer": "LOVULAR LIMITED",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Подгузники `LOVULAR` GIRAFFE S 3-7 кг 72 шт",
      "type": "SKU",
      "weight_fact": 1500,
      "weight_plan": 1500
    },
    {
      "article": "191905",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159422436",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 110,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "191905",
      "fragile": false,
      "id": "0Q-191905",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Парфюмированный мист для тела `BESTIES` BODY MIST fly away 30 мл",
      "type": "SKU",
      "weight_plan": 43
    },
    {
      "article": "223239",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196508134",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 160,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "223239",
      "fragile": false,
      "id": "0Q-223239",
      "images": [],
      "manufacturer": "КОРОЛЕВА НАЙЛЯ РАИСОВНА ИП",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Переводные тату-веснушки `DECO.` by Miami tattoos (Gretta)",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "150472",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290016846973",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 90,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 90,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "150472",
      "fragile": false,
      "id": "0Q-150472",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для лица дневной `SEA OF SPA` GOLD BENEFITS с гиалуроновой кислотой 50 мл",
      "type": "SKU",
      "weight_fact": 202,
      "weight_plan": 202
    },
    {
      "article": "188127",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010713741",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 110,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "188127",
      "fragile": false,
      "id": "0Q-188127",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Парфюмированный спрей для волос `BESTIES` HAIR PERFUME sinfonia vanilla 100 мл",
      "type": "SKU",
      "weight_plan": 116
    },
    {
      "article": "93945",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600697101972",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "4600697104850",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 90,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 90,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "93945",
      "fragile": false,
      "id": "0Q-93945",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-мыло детское `УШАСТЫЙ НЯНЬ` с оливковым маслом и ромашкой 90 г",
      "type": "SKU",
      "weight_fact": 94,
      "weight_plan": 94
    },
    {
      "article": "229163",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809517476825",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 120,
        "width": 40
      },
      "expiration_months": 1,
      "external_id": "229163",
      "fragile": false,
      "id": "0Q-229163",
      "images": [],
      "manufacturer": "NOWCOS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сыворотка для лица `DEWYTREE` MIRACLE с гиалуроновой кислотой и морской водой (увлажняющая) 40 мл",
      "type": "SKU",
      "weight_plan": 82
    },
    {
      "article": "27830",
      "barcodes": [
        {
          "active": true,
          "barcode": "40152240",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "000040152240",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "0000040152240",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4015000192989",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4015100806069",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 70,
        "height": 40,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "27830",
      "fragile": false,
      "id": "0Q-27830",
      "images": [],
      "manufacturer": "SCHWARZKOPF & HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель-воск для укладки волос `ТАФТ` с блеском (2) 75 мл",
      "type": "SKU",
      "weight_plan": 99
    },
    {
      "article": "84216",
      "barcodes": [
        {
          "active": true,
          "barcode": "6926939674614",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104109124",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "84216",
      "fragile": false,
      "id": "0Q-84216",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей для волос `KHARISMA VOLTAGE` увлажняющий 200 мл",
      "type": "SKU",
      "weight_plan": 211
    },
    {
      "article": "223794",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400105031424",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 110,
        "height": 160,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 110,
        "height": 160,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "223794",
      "fragile": false,
      "id": "0Q-223794",
      "images": [],
      "manufacturer": "SHENZHEN N-DEER FASHION CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сумка для покупок `LADY PINK` `FAVS`",
      "type": "SKU",
      "weight_fact": 26,
      "weight_plan": 26
    },
    {
      "article": "243037",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630234048996",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 40,
        "height": 100,
        "width": 40
      },
      "expiration_months": 24,
      "external_id": "243037",
      "fragile": false,
      "id": "0Q-243037",
      "images": [],
      "manufacturer": "ГЛОБАЛ БИО КОСМЕТИК ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей для волос `MISS ORGANIC` 7в1 несмываемый 40 мл",
      "type": "SKU",
      "weight_plan": 52
    },
    {
      "article": "54747",
      "barcodes": [
        {
          "active": true,
          "barcode": "4015000544191",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5012583201885",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 230,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 230,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "54747",
      "fragile": false,
      "id": "0Q-54747",
      "images": [],
      "manufacturer": "ЛАБ-ИНДАСТРИЗ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Жидкость для укладки волос `GOT2B` АНГЕЛ-ХРАНИТЕЛЬ 200 мл",
      "type": "SKU",
      "weight_fact": 237,
      "weight_plan": 237
    },
    {
      "article": "109334",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104315679",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "4630160534808",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 170,
        "height": 50,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "109334",
      "fragile": false,
      "id": "0Q-109334",
      "images": [],
      "manufacturer": "GREENCOS CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Гель для лица и тела `SUN LOOK` успокаивающий с алоэ после загара 150 г",
      "type": "SKU",
      "weight_plan": 174
    },
    {
      "article": "210056",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809698220613",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "dimensions_plan": {
        "depth": 10,
        "height": 160,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "210056",
      "fragile": false,
      "id": "0Q-210056",
      "images": [],
      "manufacturer": "MEDB CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `MED B` 1 DAY с экстрактом алоэ вера (увлажняющая) 27 мл",
      "type": "SKU",
      "weight_fact": 31,
      "weight_plan": 31
    },
    {
      "article": "230081",
      "barcodes": [
        {
          "active": true,
          "barcode": "3574660389128",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "3574660666175",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 230,
        "width": 90
      },
      "expiration_months": 1,
      "external_id": "230081",
      "fragile": false,
      "id": "0Q-230081",
      "images": [],
      "manufacturer": "JOHNSON & JOHNSON",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Ополаскиватель для полости рта `LISTERINE` COOL MINT 500 мл",
      "type": "SKU",
      "weight_plan": 566
    },
    {
      "article": "213385",
      "barcodes": [
        {
          "active": true,
          "barcode": "4810438026598",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 120,
        "width": 20
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 20
      },
      "expiration_months": 30,
      "external_id": "213385",
      "fragile": false,
      "id": "0Q-213385",
      "images": [],
      "manufacturer": "ООО РЕЛУИ БЕЛ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Помада для губ `RELOUIS` NUDE MATTE тон 27 жидкая матовая",
      "type": "SKU",
      "weight_fact": 22,
      "weight_plan": 22
    },
    {
      "article": "242241",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196524042",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 100,
        "height": 100,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 140,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "242241",
      "fragile": false,
      "id": "0Q-242241",
      "images": [],
      "manufacturer": "DONGGUAN TAIMENG ACCESSORIES CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Бальзам для губ `LP CARE` BUNNY Кола 3 г",
      "type": "SKU",
      "weight_fact": 100,
      "weight_plan": 50
    },
    {
      "article": "138251",
      "barcodes": [
        {
          "active": true,
          "barcode": "4015100190342",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 180,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "138251",
      "fragile": false,
      "id": "0Q-138251",
      "images": [],
      "manufacturer": "ЛАБ-ИНДАСТРИЗ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей для укладки волос `ТАФТ` Экспресс-укладка (мегафиксация) 150 мл",
      "type": "SKU",
      "weight_plan": 175
    },
    {
      "article": "125248",
      "barcodes": [
        {
          "active": true,
          "barcode": "2400104444317",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "7290013761347",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 100,
        "height": 70,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 100,
        "height": 70,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "125248",
      "fragile": false,
      "id": "0Q-125248",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Скраб для тела `SEA OF SPA` `BIOSPA` Ваниль 350 мл",
      "type": "SKU",
      "weight_fact": 514,
      "weight_plan": 514
    },
    {
      "article": "166994",
      "barcodes": [
        {
          "active": true,
          "barcode": "7290010673544",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 160,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "166994",
      "fragile": false,
      "id": "0Q-166994",
      "images": [],
      "manufacturer": "SEA OF SPA LABS LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для ног `SEA OF SPA` `BIOSPA` с маслом авокадо и алоэ вера 100 мл",
      "type": "SKU",
      "weight_fact": 111,
      "weight_plan": 111
    },
    {
      "article": "33489",
      "barcodes": [
        {
          "active": true,
          "barcode": "4015000520430",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5012583105091",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 240,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 240,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "33489",
      "fragile": false,
      "id": "0Q-33489",
      "images": [],
      "manufacturer": "ЛАБ-ИНДАСТРИЗ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Лак для волос `GOT2B` Cтальная хватка 300 мл",
      "type": "SKU",
      "weight_fact": 289,
      "weight_plan": 289
    },
    {
      "article": "185422",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010710375",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400104882416",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 150,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 150,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "185422",
      "fragile": false,
      "id": "0Q-185422",
      "images": [],
      "manufacturer": "ZHEJIANG MEIMI TECHNOLOGY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Парфюмерная вода `BESTIES` PERFUME SPRAY gin fizz (жен.) 30 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_fact": 66,
      "weight_plan": 66
    },
    {
      "article": "225587",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809446656886",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 120,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 120,
        "width": 50
      },
      "expiration_months": 1,
      "external_id": "225587",
      "fragile": false,
      "id": "0Q-225587",
      "images": [],
      "manufacturer": "SINDO P & G CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Сыворотка для лица `GRACE DAY` MIRACLE с бакучиолом, ниацинамидом и аденозином (подтягивающая) 50 мл",
      "type": "SKU",
      "weight_fact": 130,
      "weight_plan": 130
    },
    {
      "article": "26713",
      "barcodes": [
        {
          "active": true,
          "barcode": "8001090016959",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5413149395237",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "expiration_months": 24,
      "external_id": "26713",
      "fragile": false,
      "id": "0Q-26713",
      "images": [],
      "manufacturer": "P & G",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для мытья посуды `FAIRY` НЕЖНЫЕ РУКИ Чайное дерево и мята 450 мл",
      "type": "SKU",
      "weight_fact": 1000,
      "weight_plan": 1000
    },
    {
      "article": "211040",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901018020347",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 210,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 210,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "211040",
      "fragile": false,
      "id": "0Q-211040",
      "images": [],
      "manufacturer": "LABORATORIUM KOSMETYCZNE JOANNA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `JOANNA` CANNABIS SEED с экстрактом семян конопли (регулирующий жирность волос) 400 мл",
      "type": "SKU",
      "weight_fact": 451,
      "weight_plan": 451
    },
    {
      "article": "55953",
      "barcodes": [
        {
          "active": true,
          "barcode": "8718114671024",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 50
      },
      "expiration_months": 30,
      "external_id": "55953",
      "fragile": false,
      "id": "0Q-55953",
      "images": [],
      "manufacturer": "UNILEVER",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Део-спрей жен. `REXONA` СВЕЖЕСТЬ ДУША 48 ч (антиперспирант) 150 мл",
      "type": "SKU",
      "weight_plan": 124
    },
    {
      "article": "228014",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809280061464",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 150,
        "width": 30
      },
      "expiration_months": 1,
      "external_id": "228014",
      "fragile": false,
      "id": "0Q-228014",
      "images": [],
      "manufacturer": "C&T DREAM CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем для области вокруг глаз `ENOUGH` W COLLAGEN с коллагеном (выравнивающий) 30 мл",
      "type": "SKU",
      "weight_plan": 46
    },
    {
      "article": "159466",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809386886206",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 60
      },
      "expiration_months": 1,
      "external_id": "159466",
      "fragile": false,
      "id": "0Q-159466",
      "images": [],
      "manufacturer": "COSTEM CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пенка для умывания `ESFOLIO` с древесным углем 150 г",
      "type": "SKU",
      "weight_plan": 191
    },
    {
      "article": "238352",
      "barcodes": [
        {
          "active": true,
          "barcode": "9000100980739",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 200,
        "width": 70
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 200,
        "width": 70
      },
      "expiration_months": 36,
      "external_id": "238352",
      "fragile": false,
      "id": "0Q-238352",
      "images": [],
      "manufacturer": "HENKEL",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Порошок чистящий `ПЕМОЛЮКС` Лимон 480 г",
      "type": "SKU",
      "weight_fact": 521,
      "weight_plan": 521
    },
    {
      "article": "166807",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600697125374",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 200,
        "width": 130
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 200,
        "width": 130
      },
      "expiration_months": 36,
      "external_id": "166807",
      "fragile": false,
      "id": "0Q-166807",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-мыло жидкое для детей `УШАСТЫЙ НЯНЬ` с оливковым маслом и экстрактом алоэ вера 500 мл",
      "type": "SKU",
      "weight_fact": 502,
      "weight_plan": 502
    },
    {
      "article": "196098",
      "barcodes": [
        {
          "active": true,
          "barcode": "4897045274482",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 100,
        "width": 30
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 100,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "196098",
      "fragile": false,
      "id": "0Q-196098",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Масло для волос `KENSUKO` KERATIN 40 мл",
      "type": "SKU",
      "weight_fact": 347,
      "weight_plan": 347
    },
    {
      "article": "243696",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610117625841",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 180,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 180,
        "width": 60
      },
      "expiration_months": 24,
      "external_id": "243696",
      "fragile": false,
      "id": "0Q-243696",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `ORGANIC OIL` `PROFESSIONAL` Восстановление и блеск с аргановым маслом 240 мл",
      "type": "SKU",
      "weight_fact": 282,
      "weight_plan": 282
    },
    {
      "article": "227035",
      "barcodes": [
        {
          "active": true,
          "barcode": "4602984000678",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 220,
        "width": 100
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 220,
        "width": 100
      },
      "expiration_months": 1,
      "external_id": "227035",
      "fragile": false,
      "id": "0Q-227035",
      "images": [],
      "manufacturer": "СТУПИНСКИЙ ХИМИЧЕСКИЙ ЗАВОД АО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для мытья посуды `SANITA` грейпфрут и мята 450 мл",
      "type": "SKU",
      "weight_fact": 508,
      "weight_plan": 508
    },
    {
      "article": "160556",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901018018184",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 140,
        "width": 80
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 140,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "160556",
      "fragile": false,
      "id": "0Q-160556",
      "images": [],
      "manufacturer": "LABORATORIUM KOSMETYCZNE JOANNA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Оттеночный бальзам для волос `JOANNA` ULTRA COLOR тон медный 100 г",
      "type": "SKU",
      "weight_fact": 132,
      "weight_plan": 132
    },
    {
      "article": "241004",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523595",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 150,
        "width": 10
      },
      "expiration_months": 36,
      "external_id": "241004",
      "fragile": false,
      "id": "0Q-241004",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Карандаш для губ `ISCREAM` FLOWER FLOW тон 03",
      "type": "SKU",
      "weight_plan": 5
    },
    {
      "article": "226973",
      "barcodes": [
        {
          "active": true,
          "barcode": "4607971455592",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 80,
        "height": 150,
        "width": 210
      },
      "dimensions_plan": {
        "depth": 80,
        "height": 150,
        "width": 210
      },
      "expiration_months": 1,
      "external_id": "226973",
      "fragile": false,
      "id": "0Q-226973",
      "images": [],
      "manufacturer": "SYNERGETIC",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Таблетки для посудомоечных машин `SYNERGETIC` ULTRA POWER 55 шт",
      "type": "SKU",
      "weight_fact": 1100,
      "weight_plan": 1100
    },
    {
      "article": "187306",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010712034",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 120,
        "height": 110,
        "width": 120
      },
      "dimensions_plan": {
        "depth": 120,
        "height": 110,
        "width": 120
      },
      "expiration_months": 0,
      "external_id": "187306",
      "fragile": false,
      "id": "0Q-187306",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мочалка-шар для тела `DECO.` синтетическая (white)",
      "type": "SKU",
      "weight_fact": 41,
      "weight_plan": 41
    },
    {
      "article": "191920",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159422580",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 50,
        "height": 80,
        "width": 50
      },
      "dimensions_plan": {
        "depth": 50,
        "height": 80,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "191920",
      "fragile": false,
      "id": "0Q-191920",
      "images": [],
      "manufacturer": "YIWU YUN PACKING CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Блестки для лица, тела и волос `DECO.` by Miami tattoos (Millennium)",
      "type": "SKU",
      "weight_fact": 20,
      "weight_plan": 20
    },
    {
      "article": "196065",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159427165",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 140,
        "width": 60
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 140,
        "width": 60
      },
      "expiration_months": 24,
      "external_id": "196065",
      "fragile": false,
      "id": "0Q-196065",
      "images": [],
      "manufacturer": "ФИТОКОСМЕТИК",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Крем-краска для бровей `KENSUKO` Тон Графит",
      "type": "SKU",
      "weight_fact": 18,
      "weight_plan": 18
    },
    {
      "article": "134916",
      "barcodes": [
        {
          "active": true,
          "barcode": "4897045272747",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 130,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "134916",
      "fragile": false,
      "id": "0Q-134916",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `KHARISMA VOLTAGE` с водорослями для сухих и ломких волос 20 мл",
      "type": "SKU",
      "weight_plan": 24
    },
    {
      "article": "241949",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523656",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "241949",
      "fragile": false,
      "id": "0Q-241949",
      "images": [],
      "manufacturer": "HUIZHOU JIAJIALI COSMETIC CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `KENSUKO` BUBBLE GUM для объема 300 мл",
      "type": "SKU",
      "weight_plan": 500
    },
    {
      "article": "137070",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160535805",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104531611",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 150,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 150,
        "width": 90
      },
      "expiration_months": 24,
      "external_id": "137070",
      "fragile": false,
      "id": "0Q-137070",
      "images": [],
      "manufacturer": "ГРАНД А. В. ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки влажные `LP CARE` с антибактериальным эффектом 15 шт",
      "type": "SKU",
      "weight_fact": 60,
      "weight_plan": 60
    },
    {
      "article": "241950",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196523663",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 200,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "241950",
      "fragile": false,
      "id": "0Q-241950",
      "images": [],
      "manufacturer": "HUIZHOU JIAJIALI COSMETIC CO.,LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кондиционер для волос `KENSUKO` BUBBLE GUM для объема 300 мл",
      "type": "SKU",
      "weight_plan": 500
    },
    {
      "article": "216053",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196502187",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 110,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 110,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "216053",
      "fragile": false,
      "id": "0Q-216053",
      "images": [],
      "manufacturer": "CHINA-BASE NINGBO FOREIGN TRADE CO., LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Нить зубная `LP CARE` DENTAL Bubble GUM 15 м",
      "type": "SKU",
      "weight_fact": 16,
      "weight_plan": 16
    },
    {
      "article": "226643",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196511660",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 30,
        "height": 90,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 30,
        "height": 90,
        "width": 40
      },
      "expiration_months": 42,
      "external_id": "226643",
      "fragile": false,
      "id": "0Q-226643",
      "images": [],
      "manufacturer": "ЗЕТТЕКНОЛОДЖИ ООО",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Салфетки влажные `LP CARE` ANIME универсальные кокос 8 шт",
      "type": "SKU",
      "weight_fact": 34,
      "weight_plan": 34
    },
    {
      "article": "245840",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329045537",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 110,
        "width": 20
      },
      "expiration_months": 31,
      "external_id": "245840",
      "fragile": false,
      "id": "0Q-245840",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Тени для век `LUXVISAGE` SATIN NUDE Super stay жидкие тон 203 Powder rose",
      "type": "SKU",
      "weight_plan": 21
    },
    {
      "article": "88592",
      "barcodes": [
        {
          "active": true,
          "barcode": "4897045270828",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "2400104161412",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 90,
        "height": 250,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 90,
        "height": 250,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "88592",
      "fragile": false,
      "id": "0Q-88592",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `KHARISMA VOLTAGE` ARGAN OIL восстанавливающий с маслом арганы 800 мл",
      "type": "SKU",
      "weight_fact": 914,
      "weight_plan": 914
    },
    {
      "article": "188401",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010715196",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 200,
        "width": 190
      },
      "expiration_months": 36,
      "external_id": "188401",
      "fragile": false,
      "id": "0Q-188401",
      "images": [],
      "manufacturer": "QUANZHOUSHI SHENGHONGDA HYGIENE PRODUCTS CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Трусики `LP CARE` менструальные 1 шт",
      "type": "SKU",
      "weight_plan": 38
    },
    {
      "article": "213549",
      "barcodes": [
        {
          "active": true,
          "barcode": "5709954024371",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 40,
        "height": 150,
        "width": 40
      },
      "dimensions_plan": {
        "depth": 40,
        "height": 150,
        "width": 40
      },
      "expiration_months": 36,
      "external_id": "213549",
      "fragile": false,
      "id": "0Q-213549",
      "images": [],
      "manufacturer": "DERMAPHARM A/S",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Масло детское `DERMA` BABY 150 мл",
      "type": "SKU",
      "weight_fact": 162,
      "weight_plan": 162
    },
    {
      "article": "160434",
      "barcodes": [
        {
          "active": true,
          "barcode": "8809184257093",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 80,
        "height": 100,
        "width": 110
      },
      "expiration_months": 36,
      "external_id": "160434",
      "fragile": false,
      "id": "0Q-160434",
      "images": [],
      "manufacturer": "G.T PAPER CO., LTD PUTIAN FUJIAN",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Прокладки классические `HUMMINGS` normal 16 шт",
      "type": "SKU",
      "weight_plan": 142
    },
    {
      "article": "121443",
      "barcodes": [
        {
          "active": true,
          "barcode": "5900516312084",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5900516312008",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 70,
        "height": 170,
        "width": 160
      },
      "dimensions_plan": {
        "depth": 70,
        "height": 170,
        "width": 160
      },
      "expiration_months": 36,
      "external_id": "121443",
      "fragile": false,
      "id": "0Q-121443",
      "images": [],
      "manufacturer": "BELLA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Прокладки ежедневные `BELLA` PANTY Soft 60 шт",
      "type": "SKU",
      "weight_fact": 130,
      "weight_plan": 130
    },
    {
      "article": "143399",
      "barcodes": [
        {
          "active": true,
          "barcode": "8806325620297",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 20,
        "height": 180,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "143399",
      "fragile": false,
      "id": "0Q-143399",
      "images": [],
      "manufacturer": "LION CORPORATION",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Пятновыводитель для белья `LION` BEAT O2 Clean Plus концентрированный 150 г",
      "type": "SKU",
      "weight_plan": 150
    },
    {
      "article": "237684",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196519826",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 20,
        "height": 120,
        "width": 120
      },
      "dimensions_plan": {
        "depth": 20,
        "height": 120,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "237684",
      "fragile": false,
      "id": "0Q-237684",
      "images": [],
      "manufacturer": "NINGBO ESPERY COMMODITY CO., LTD",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Кассеты для станка `VOX` FOR MEN 3 лезвия 2 шт",
      "type": "SKU",
      "weight_fact": 35,
      "weight_plan": 35
    },
    {
      "article": "187325",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640010712201",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 80,
        "height": 210,
        "width": 80
      },
      "expiration_months": 36,
      "external_id": "187325",
      "fragile": false,
      "id": "0Q-187325",
      "images": [],
      "manufacturer": "ESSCALATE COSMETICS",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Шампунь для волос `KHARISMA VOLTAGE` TEA TREE&HEMP OIL 500 мл",
      "type": "SKU",
      "weight_plan": 570
    },
    {
      "article": "237872",
      "barcodes": [
        {
          "active": true,
          "barcode": "4610196520426",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 60,
        "height": 100,
        "width": 60
      },
      "expiration_months": 36,
      "external_id": "237872",
      "fragile": false,
      "id": "0Q-237872",
      "images": [],
      "manufacturer": "ERTE KOZMETIK SANAYI VE TICARET A.S",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "{УИТ}",
          "title": "Честный знак"
        }
      ],
      "title": "Парфюмерная вода `BESTIES` MEMORIES COLLECTION mad racing 50 мл",
      "tnved_code": "330300",
      "type": "SKU",
      "weight_plan": 221
    },
    {
      "article": "196076",
      "barcodes": [
        {
          "active": true,
          "barcode": "4640159427257",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 50,
        "height": 190,
        "width": 50
      },
      "expiration_months": 36,
      "external_id": "196076",
      "fragile": false,
      "id": "0Q-196076",
      "images": [],
      "manufacturer": "AEROFA AEROSOL DOLUM SAN. TIC. A.S.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Мусс для душа `VOX` с тропическим ароматом 200 мл",
      "type": "SKU",
      "weight_plan": 236
    },
    {
      "article": "189711",
      "barcodes": [
        {
          "active": true,
          "barcode": "4630160530237",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 10,
        "height": 170,
        "width": 120
      },
      "expiration_months": 36,
      "external_id": "189711",
      "fragile": false,
      "id": "0Q-189711",
      "images": [],
      "manufacturer": "GOSHEN KOREA CO. LTD.",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для лица `SUN LOOK` с экстрактом кактуса (успокаивающая) 25 г",
      "type": "SKU",
      "weight_plan": 32
    },
    {
      "article": "121450",
      "barcodes": [
        {
          "active": true,
          "barcode": "4600697064505",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 60,
        "height": 130,
        "width": 140
      },
      "dimensions_plan": {
        "depth": 60,
        "height": 130,
        "width": 140
      },
      "expiration_months": 1,
      "external_id": "121450",
      "fragile": false,
      "id": "0Q-121450",
      "images": [],
      "manufacturer": "НЕВСКАЯ КОСМЕТИКА",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Средство для посудомоечных машин `УШАСТЫЙ НЯНЬ` в таблетках 20 шт",
      "type": "SKU",
      "weight_fact": 433,
      "weight_plan": 433
    },
    {
      "article": "111462",
      "barcodes": [
        {
          "active": true,
          "barcode": "5901018012823",
          "type": "COMMON",
          "used_in_last_supply": true
        },
        {
          "active": true,
          "barcode": "5901018012342",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "2400104329935",
          "type": "COMMON",
          "used_in_last_supply": false
        },
        {
          "active": true,
          "barcode": "5901018014483",
          "type": "COMMON",
          "used_in_last_supply": false
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {
        "depth": 100,
        "height": 100,
        "width": 90
      },
      "dimensions_plan": {
        "depth": 100,
        "height": 100,
        "width": 90
      },
      "expiration_months": 36,
      "external_id": "111462",
      "fragile": false,
      "id": "0Q-111462",
      "images": [],
      "manufacturer": "LABORATORIUM KOSMETYCZNE JOANNA",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Маска для волос `JOANNA` KERATIN с кератином 500 г",
      "type": "SKU",
      "weight_fact": 557,
      "weight_plan": 557
    },
    {
      "article": "201922",
      "barcodes": [
        {
          "active": true,
          "barcode": "4811329030038",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": false,
      "batch_expdate_mandatory": false,
      "batches": [],
      "dimensions_fact": {},
      "dimensions_plan": {
        "depth": 30,
        "height": 120,
        "width": 30
      },
      "expiration_months": 36,
      "external_id": "201922",
      "fragile": false,
      "id": "0Q-201922",
      "images": [],
      "manufacturer": "ООО ЛЮКС-ВИЗАЖ",
      "merchant": {
        "cam_id": "71156",
        "contract_date": "2023-10-16",
        "contract_num": "КО-ОП- 16/10/2023-293",
        "enabled": true,
        "id": "75d776a1-1038-4837-b62f-8de5b76de458",
        "inn": "7709505477",
        "kpp": "774950001",
        "login": "e.generalova@taber.ru",
        "ogrn": "1037739861851",
        "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
        "title": "Табер Трейд \"Подружка\"",
        "trade_title": "Подружка"
      },
      "parts": [],
      "require_dimensions": false,
      "require_weight": false,
      "scan_serial_numbers": [],
      "title": "Спрей для фиксации макияжа `LUXVISAGE` PRIME & FIX",
      "type": "SKU",
      "weight_plan": 61
    }
  ],
  "success": true,
  "supply": {
    "accompanying_doc_num": "911SB329206",
    "activated": true,
    "comment": ".",
    "create_ts": "2024-06-28T19:44:16.180218",
    "date": "2024-06-28T19:44:03.242000",
    "executor": {
      "enabled": true,
      "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
      "login": "5200",
      "name": "А",
      "position": "OPERATOR",
      "roles": [
        "459a9682-cc26-4ba5-a278-db938c15be71"
      ],
      "surname": "Казанцева"
    },
    "external_id": "IW937NP401260",
    "files": [],
    "has_diffs": false,
    "id": "S-0Q-IW937NP401260",
    "items": [
      {
        "id": "da7ac6ca-89d3-48b9-998c-ec3ca12d0144",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 46,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210417"
      },
      {
        "id": "0259815c-27ab-429d-af8c-6c0232667fdd",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-238978"
      },
      {
        "id": "1debae0a-3c0b-42e6-b666-3204d93ce05a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 17,
        "scan_serial_numbers": [],
        "sku_id": "0Q-234988"
      },
      {
        "id": "11f363fd-4e4d-4316-a173-044f3a551836",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-177915"
      },
      {
        "id": "9448f234-2d96-4e6e-87c3-34e608ee0244",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191924"
      },
      {
        "id": "96e883a3-a24d-4ee6-8453-d5b29e52565d",
        "price_data": {
          "currency": "RUB",
          "price": 235,
          "vat": "VAT_10"
        },
        "quantity": 52,
        "scan_serial_numbers": [],
        "sku_id": "0Q-211873"
      },
      {
        "id": "2147e3ab-9319-4edf-aad7-48fe4c263534",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 56,
        "scan_serial_numbers": [],
        "sku_id": "0Q-111462"
      },
      {
        "id": "e318a004-5923-4bd2-a6e3-68f93806e7e4",
        "price_data": {
          "currency": "RUB",
          "price": 32.25,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242333"
      },
      {
        "id": "ee461ff1-84c7-467f-a037-5d4d968fa0ef",
        "price_data": {
          "currency": "RUB",
          "price": 109.5,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-232849"
      },
      {
        "id": "fe075951-0101-4054-8d2c-320bbe8307bb",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-185422"
      },
      {
        "id": "3b7cda41-5f09-4226-b376-7296b5fe3c10",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-231673"
      },
      {
        "id": "f7615243-8ebc-45f3-a5cc-34d1476b503b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-181708"
      },
      {
        "id": "cf44d0c4-5095-4a96-b018-30cf7e106764",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-108506"
      },
      {
        "id": "1c1cfb43-5985-4439-aebd-7703d0c6ebba",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-160556"
      },
      {
        "id": "b9ccb70a-bee8-42b6-8b31-f0205f5d92f0",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 7,
        "scan_serial_numbers": [],
        "sku_id": "0Q-147374"
      },
      {
        "id": "0c8253c9-8a96-4384-8ad3-65cf2a10af73",
        "price_data": {
          "currency": "RUB",
          "price": 84.63,
          "vat": "VAT_20"
        },
        "quantity": 45,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196441"
      },
      {
        "id": "a41bd202-6447-49d2-8f25-641c87548fcb",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 25,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210216"
      },
      {
        "id": "df8b4db4-a4a7-404f-b89c-4b5727be9a8f",
        "price_data": {
          "currency": "RUB",
          "price": 199,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-243704"
      },
      {
        "id": "e97da40d-7d97-4edb-9298-c4137bd06b57",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 49,
        "scan_serial_numbers": [],
        "sku_id": "0Q-205358"
      },
      {
        "id": "e37723ce-1df2-478f-b389-ce5699ccb2e6",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-133905"
      },
      {
        "id": "80b8d19d-d3f5-4e9e-9702-2c24f453b708",
        "price_data": {
          "currency": "RUB",
          "price": 30.52,
          "vat": "VAT_20"
        },
        "quantity": 40,
        "scan_serial_numbers": [],
        "sku_id": "0Q-70351"
      },
      {
        "id": "507dab11-5446-4c38-aee4-0cb213ab4ada",
        "price_data": {
          "currency": "RUB",
          "price": 259,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-219441"
      },
      {
        "id": "1c401a56-f241-4c85-820e-b715172d62d3",
        "price_data": {
          "currency": "RUB",
          "price": 91.67,
          "vat": "VAT_20"
        },
        "quantity": 9,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210316"
      },
      {
        "id": "7f3fe0ab-bad7-489d-bc05-fff367d40dc4",
        "price_data": {
          "currency": "RUB",
          "price": 11.46,
          "vat": "VAT_20"
        },
        "quantity": 42,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227140"
      },
      {
        "id": "3a9e4036-d4ce-4f22-b042-63e2c7598985",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 17,
        "scan_serial_numbers": [],
        "sku_id": "0Q-134600"
      },
      {
        "id": "93c099eb-5358-467f-8b93-3c08d812c4bd",
        "price_data": {
          "currency": "RUB",
          "price": 169.1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213385"
      },
      {
        "id": "4d6b0dee-d072-475b-90a4-23a294f04c63",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210217"
      },
      {
        "id": "18fb089f-f7f3-4f0f-afb9-139cb11a319b",
        "price_data": {
          "currency": "RUB",
          "price": 1.3,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245849"
      },
      {
        "id": "d38e7b06-a6b8-460d-9f3e-1d4999246f52",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-167080"
      },
      {
        "id": "20e6b93f-941e-46e1-b62e-32bbb673a1fc",
        "price_data": {
          "currency": "RUB",
          "price": 43,
          "vat": "VAT_20"
        },
        "quantity": 60,
        "scan_serial_numbers": [],
        "sku_id": "0Q-93944"
      },
      {
        "id": "199e8ff8-d90a-411c-9a30-ee9dc6be1c5d",
        "price_data": {
          "currency": "RUB",
          "price": 1.31,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245841"
      },
      {
        "id": "454df7df-28dc-47c4-b0f5-6298d1913249",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210056"
      },
      {
        "id": "10be4edd-d50d-4005-a50d-d4ca3abaf057",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196439"
      },
      {
        "id": "1f39112f-829d-4d5a-a601-518d28962a8d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-229191"
      },
      {
        "id": "b899b6e4-b061-4c64-a96f-bdb74d37dfd0",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-229163"
      },
      {
        "id": "cdeb3c9b-5a34-43ab-8733-43de2622c268",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 7,
        "scan_serial_numbers": [],
        "sku_id": "0Q-147369"
      },
      {
        "id": "cebb8156-a46a-47c7-886f-0758ef5bf866",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213840"
      },
      {
        "id": "b39b6074-9f7f-40fd-89fd-4355c2ca9c40",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-134442"
      },
      {
        "id": "0a864971-8623-4e4e-9892-232db48414f1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-212901"
      },
      {
        "id": "9919fd1f-4d9b-4e8f-a9bd-5e5cd7152fb0",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242855"
      },
      {
        "id": "f8b50839-df33-4315-abc8-9405ef07f437",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213549"
      },
      {
        "id": "f5685670-1022-40b9-830e-95b969a004cf",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-215839"
      },
      {
        "id": "eef8adcf-3d2d-4261-91dd-47f4a46c1a62",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-189711"
      },
      {
        "id": "80c401f3-881c-438d-af73-e111f76cfff4",
        "price_data": {
          "currency": "RUB",
          "price": 9.52,
          "vat": "VAT_20"
        },
        "quantity": 42,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226643"
      },
      {
        "id": "cee7a4b8-ebc0-437d-98cd-669b5b8d0764",
        "price_data": {
          "currency": "RUB",
          "price": 99.79,
          "vat": "VAT_20"
        },
        "quantity": 5,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206345"
      },
      {
        "id": "aa626741-37a0-44f4-8371-10a6bd7224b3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 100,
        "scan_serial_numbers": [],
        "sku_id": "0Q-188401"
      },
      {
        "id": "c3a56ef3-dee8-4bbe-a2d0-fa1b8c8b6422",
        "price_data": {
          "currency": "RUB",
          "price": 379,
          "vat": "VAT_20"
        },
        "quantity": 50,
        "scan_serial_numbers": [],
        "sku_id": "0Q-233412"
      },
      {
        "id": "34880ef3-0527-4ddd-85d8-faae3cfb0d68",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-136394"
      },
      {
        "id": "50da3dec-dcd7-44d7-a46a-8a6bda8511d4",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241931"
      },
      {
        "id": "f3532313-74bf-43c5-8f95-bd9d8704465e",
        "price_data": {
          "currency": "RUB",
          "price": 399,
          "vat": "VAT_20"
        },
        "quantity": 46,
        "scan_serial_numbers": [],
        "sku_id": "0Q-233411"
      },
      {
        "id": "af40ee04-b201-458f-8092-4ffbc7130ed9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-164362"
      },
      {
        "id": "8a8ea716-ca7e-4332-ad38-443582f0ef43",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 11,
        "scan_serial_numbers": [],
        "sku_id": "0Q-170282"
      },
      {
        "id": "37979b05-519c-4052-aa35-c001d8168f14",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 52,
        "scan_serial_numbers": [],
        "sku_id": "0Q-188127"
      },
      {
        "id": "87996826-0ac6-4673-baf6-170d2abc2026",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 22,
        "scan_serial_numbers": [],
        "sku_id": "0Q-234973"
      },
      {
        "id": "190a6c73-014f-4273-863f-c0033a2aea38",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-209749"
      },
      {
        "id": "7ff34a93-a3ef-4663-ac64-e4a89496e4da",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-201489"
      },
      {
        "id": "4a4a7e31-5749-4965-8371-5de0ecb11019",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-240999"
      },
      {
        "id": "b706b9ee-c7b2-4325-a4f9-e14d9cafcb14",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 9,
        "scan_serial_numbers": [],
        "sku_id": "0Q-222797"
      },
      {
        "id": "9119d857-2ecb-46c3-9a17-730d20494c61",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 9,
        "scan_serial_numbers": [],
        "sku_id": "0Q-225611"
      },
      {
        "id": "e5c632d7-fc3d-40c7-96bd-4983cc6a58c9",
        "price_data": {
          "currency": "RUB",
          "price": 1.31,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245839"
      },
      {
        "id": "c84be565-2452-4202-a045-54501f229c3f",
        "price_data": {
          "currency": "RUB",
          "price": 40,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242270"
      },
      {
        "id": "f868403a-1deb-4d5c-bbbb-318c92f45c51",
        "price_data": {
          "currency": "RUB",
          "price": 229,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-221428"
      },
      {
        "id": "b852c714-b64e-4409-b14f-cc1f2f01af28",
        "price_data": {
          "currency": "RUB",
          "price": 299,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-73714"
      },
      {
        "id": "fb624635-421b-4b84-920a-ae2c3389471f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-211740"
      },
      {
        "id": "785f654d-bfe5-4fc1-9039-1dcd8c5858e1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-204547"
      },
      {
        "id": "d077eff5-0367-4008-ae3c-c5470b077cea",
        "price_data": {
          "currency": "RUB",
          "price": 549,
          "vat": "VAT_20"
        },
        "quantity": 7,
        "scan_serial_numbers": [],
        "sku_id": "0Q-230081"
      },
      {
        "id": "98dd58e5-898b-4d6c-8bee-bf64fa937abd",
        "price_data": {
          "currency": "RUB",
          "price": 1.3,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245853"
      },
      {
        "id": "4630b025-d18e-4cf2-8f1b-f6a2123fe893",
        "price_data": {
          "currency": "RUB",
          "price": 229,
          "vat": "VAT_20"
        },
        "quantity": 21,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226968"
      },
      {
        "id": "9b9b1f13-10b3-4604-8f44-19bdfb295f93",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206440"
      },
      {
        "id": "a6e37810-f6ef-4369-b672-f2406f71384b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-84361"
      },
      {
        "id": "cf08ddb2-0943-47b1-b5fc-db10850d63d7",
        "price_data": {
          "currency": "RUB",
          "price": 399,
          "vat": "VAT_20"
        },
        "quantity": 183,
        "scan_serial_numbers": [],
        "sku_id": "0Q-141324"
      },
      {
        "id": "c8d97bd5-8880-4aff-bf4e-7905689df3db",
        "price_data": {
          "currency": "RUB",
          "price": 529,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-56151"
      },
      {
        "id": "175d623f-9aee-4010-986f-83230fa64709",
        "price_data": {
          "currency": "RUB",
          "price": 59,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-00437"
      },
      {
        "id": "92299af4-b17f-42d8-abe7-80f67e59beb2",
        "price_data": {
          "currency": "RUB",
          "price": 499,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-223181"
      },
      {
        "id": "381770cd-68d7-49c3-9549-76ac2f1849b1",
        "price_data": {
          "currency": "RUB",
          "price": 339,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-100967"
      },
      {
        "id": "8cb8760f-681a-401c-9f6c-b409d7b39ae2",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-185861"
      },
      {
        "id": "be3f0e94-af8d-41de-a230-d42a46b836df",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-223004"
      },
      {
        "id": "b87af6db-12cd-4f47-b969-6ce69ee83cdc",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242848"
      },
      {
        "id": "fd55f714-0f24-4779-8801-2675e58a7d70",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-68478"
      },
      {
        "id": "0da33504-73f6-49a9-a8c5-af4a1f244ec6",
        "price_data": {
          "currency": "RUB",
          "price": 279,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-12793"
      },
      {
        "id": "7758925a-8a0e-4387-ade1-eb3c57fb813a",
        "price_data": {
          "currency": "RUB",
          "price": 269,
          "vat": "VAT_20"
        },
        "quantity": 76,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242246"
      },
      {
        "id": "82a19faa-beef-41b6-bfbe-3d9d92edd605",
        "price_data": {
          "currency": "RUB",
          "price": 1.3,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245850"
      },
      {
        "id": "8d48b391-02eb-4194-8bef-88bb4d199f72",
        "price_data": {
          "currency": "RUB",
          "price": 51.9,
          "vat": "VAT_20"
        },
        "quantity": 13,
        "scan_serial_numbers": [],
        "sku_id": "0Q-243027"
      },
      {
        "id": "086f9410-cd1e-439e-a61b-d72406fd21a6",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-208958"
      },
      {
        "id": "27d3d176-13f0-4e7f-a6f0-5d358925b951",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-225736"
      },
      {
        "id": "04143b96-7b51-46ee-9f81-b31febfdfdc3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-215016"
      },
      {
        "id": "fed8095a-3da8-4fa7-b676-ef26a998fddb",
        "price_data": {
          "currency": "RUB",
          "price": 100.11,
          "vat": "VAT_20"
        },
        "quantity": 16,
        "scan_serial_numbers": [],
        "sku_id": "0Q-220773"
      },
      {
        "id": "18e61c44-db20-4f6f-85bd-2203b66701ab",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 9,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191904"
      },
      {
        "id": "008b2bae-c7b9-4e27-b2b6-5313e1c5e279",
        "price_data": {
          "currency": "RUB",
          "price": 108.19,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-232744"
      },
      {
        "id": "d96c566f-68d3-4de5-9753-75ed2189a5a1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 17,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206082"
      },
      {
        "id": "88cbaf48-ab81-4325-a861-6e92dfac66dc",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 120,
        "scan_serial_numbers": [],
        "sku_id": "0Q-209598"
      },
      {
        "id": "fa30747c-d79e-43ec-9b43-1df24d595171",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210901"
      },
      {
        "id": "5a80ff43-1082-42c6-987b-ffc012263ab2",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 13,
        "scan_serial_numbers": [],
        "sku_id": "0Q-167082"
      },
      {
        "id": "7a318d20-3028-4ae9-b21b-d38e8653e6bc",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213850"
      },
      {
        "id": "dcfc4b04-f7e9-4872-bcb1-3aa1c5682fb0",
        "price_data": {
          "currency": "RUB",
          "price": 159,
          "vat": "VAT_20"
        },
        "quantity": 47,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245259"
      },
      {
        "id": "384ff9bf-21db-49bc-a805-fb63d537136b",
        "price_data": {
          "currency": "RUB",
          "price": 0.98,
          "vat": "VAT_20"
        },
        "quantity": 63,
        "scan_serial_numbers": [],
        "sku_id": "0Q-201922"
      },
      {
        "id": "2828b40f-58aa-40dc-89a3-90ed82b91709",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-84216"
      },
      {
        "id": "3120e1ca-71b8-4199-875c-0ec65ff7c614",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213822"
      },
      {
        "id": "ca34566b-898b-46f9-9fb3-007c5624aa74",
        "price_data": {
          "currency": "RUB",
          "price": 339,
          "vat": "VAT_20"
        },
        "quantity": 30,
        "scan_serial_numbers": [],
        "sku_id": "0Q-203669"
      },
      {
        "id": "599e2bf8-fd94-489f-9aae-b1b615a45963",
        "price_data": {
          "currency": "RUB",
          "price": 211.83,
          "vat": "VAT_20"
        },
        "quantity": 80,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206936"
      },
      {
        "id": "54884eb2-7e66-47f3-81e9-71dc063d2d10",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 60,
        "scan_serial_numbers": [],
        "sku_id": "0Q-159322"
      },
      {
        "id": "2ef71b39-7c4e-4bf1-83e0-82c5a5846e6b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 60,
        "scan_serial_numbers": [],
        "sku_id": "0Q-202994"
      },
      {
        "id": "2b654e27-5b5a-4dd5-9114-8659d78a47b4",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-171695"
      },
      {
        "id": "f93798da-3ccc-42e1-a568-930f96c0abd8",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 135,
        "scan_serial_numbers": [],
        "sku_id": "0Q-169249"
      },
      {
        "id": "848ac83b-cbbc-4955-98dc-4de8e131c69f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-204544"
      },
      {
        "id": "17eae8b4-d0cc-465a-8f0f-d7a40500e4a0",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-225777"
      },
      {
        "id": "f08004fd-1213-4168-853e-8596ca0b156c",
        "price_data": {
          "currency": "RUB",
          "price": 549,
          "vat": "VAT_20"
        },
        "quantity": 47,
        "scan_serial_numbers": [],
        "sku_id": "0Q-138251"
      },
      {
        "id": "cdd57c7e-a2cf-4eb5-b292-576593794b95",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-149325"
      },
      {
        "id": "e0eee0e3-3738-4ff8-9798-19f0dc1d5273",
        "price_data": {
          "currency": "RUB",
          "price": 269,
          "vat": "VAT_20"
        },
        "quantity": 26,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227065"
      },
      {
        "id": "d86435e1-50d5-4a78-9d52-c17112c08507",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242847"
      },
      {
        "id": "28b59010-01e0-4af9-ae78-8ebdaf52a8e7",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-225587"
      },
      {
        "id": "80b6186f-895c-4aed-9e51-73e15197ef82",
        "price_data": {
          "currency": "RUB",
          "price": 299,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226963"
      },
      {
        "id": "c25817c0-834b-423d-9438-1892f14b25c1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-103996"
      },
      {
        "id": "b1f2b22a-57eb-4c02-81d9-8f2b149a8688",
        "price_data": {
          "currency": "RUB",
          "price": 155,
          "vat": "VAT_20"
        },
        "quantity": 45,
        "scan_serial_numbers": [],
        "sku_id": "0Q-222991"
      },
      {
        "id": "afa013f7-e165-420d-bd8f-066cf9262d2b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241961"
      },
      {
        "id": "5d7f86b1-679d-4080-b53d-ceb59080a709",
        "price_data": {
          "currency": "RUB",
          "price": 1.85,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206216"
      },
      {
        "id": "8a183511-bff5-41c1-aa28-f623eedfd73d",
        "price_data": {
          "currency": "RUB",
          "price": 949,
          "vat": "VAT_20"
        },
        "quantity": 46,
        "scan_serial_numbers": [],
        "sku_id": "0Q-211855"
      },
      {
        "id": "e9c9d4c1-243b-4e13-bc2a-d19398732a3c",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 85,
        "scan_serial_numbers": [],
        "sku_id": "0Q-223133"
      },
      {
        "id": "676d2405-aa2d-46d9-8438-69135c6a4e0b",
        "price_data": {
          "currency": "RUB",
          "price": 189,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-166807"
      },
      {
        "id": "22518f6b-721e-454b-be2e-e83dbcd9807d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-231591"
      },
      {
        "id": "2e19a7e5-bba7-43a1-9138-327bda14ec74",
        "price_data": {
          "currency": "RUB",
          "price": 97,
          "vat": "VAT_20"
        },
        "quantity": 32,
        "scan_serial_numbers": [],
        "sku_id": "0Q-238352"
      },
      {
        "id": "c566ab9f-46da-4202-ba1f-5db037dfe28a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 60,
        "scan_serial_numbers": [],
        "sku_id": "0Q-156587"
      },
      {
        "id": "f17cd9d9-c388-44e5-9d39-87825670afdc",
        "price_data": {
          "currency": "RUB",
          "price": 769,
          "vat": "VAT_20"
        },
        "quantity": 45,
        "scan_serial_numbers": [],
        "sku_id": "0Q-54747"
      },
      {
        "id": "503707eb-7bf9-45f5-8926-00e2c13895c9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191894"
      },
      {
        "id": "45f5268a-8361-4699-9f13-106b3aa0d3b8",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-209008"
      },
      {
        "id": "f637152f-720d-4b7e-ba83-93e2cf0e50e3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242856"
      },
      {
        "id": "42fe3180-ee1d-4a49-9350-1ecb49a0b3f8",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196512"
      },
      {
        "id": "96219a3b-67b1-4f21-a0af-01e0221f8b36",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 27,
        "scan_serial_numbers": [],
        "sku_id": "0Q-166994"
      },
      {
        "id": "4389ce47-e0bb-4d96-9b95-17ed90f35484",
        "price_data": {
          "currency": "RUB",
          "price": 179,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-29803"
      },
      {
        "id": "0b1ccd71-1a2c-47b0-91cd-1a2aa2e4f21f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-232578"
      },
      {
        "id": "99e0b3f0-e520-494c-bfb6-dece902883c3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 31,
        "scan_serial_numbers": [],
        "sku_id": "0Q-224424"
      },
      {
        "id": "8fc35998-e8bc-4215-b0fb-c1785a3adfbb",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-231654"
      },
      {
        "id": "11867e4a-b71a-4b69-b355-3cf1cf5a7bb3",
        "price_data": {
          "currency": "RUB",
          "price": 299,
          "vat": "VAT_20"
        },
        "quantity": 55,
        "scan_serial_numbers": [],
        "sku_id": "0Q-233413"
      },
      {
        "id": "311f3142-83fc-406d-81c3-c28ca7de6bae",
        "price_data": {
          "currency": "RUB",
          "price": 1.18,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245844"
      },
      {
        "id": "d574144b-bcff-4c43-b5b0-e15e703639ad",
        "price_data": {
          "currency": "RUB",
          "price": 1.18,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245846"
      },
      {
        "id": "3e2adec4-370a-42fd-87f4-9e07b561f57d",
        "price_data": {
          "currency": "RUB",
          "price": 159,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227025"
      },
      {
        "id": "137fd5f0-d548-4ec6-a4a0-88574dce50c1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-159329"
      },
      {
        "id": "33f91944-8436-4637-bf80-2b1f213a0de2",
        "price_data": {
          "currency": "RUB",
          "price": 30,
          "vat": "VAT_20"
        },
        "quantity": 16,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196065"
      },
      {
        "id": "81de12dc-45e3-46b7-aa68-ad6756c57d60",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-215125"
      },
      {
        "id": "2065d5e6-fffd-43a3-b915-e267b7369165",
        "price_data": {
          "currency": "RUB",
          "price": 399,
          "vat": "VAT_20"
        },
        "quantity": 11,
        "scan_serial_numbers": [],
        "sku_id": "0Q-246388"
      },
      {
        "id": "9d0aae5c-85ca-4b62-bae2-2261ea5a2fda",
        "price_data": {
          "currency": "RUB",
          "price": 25.67,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-139077"
      },
      {
        "id": "395afe1f-ef20-49db-89d0-53af71565397",
        "price_data": {
          "currency": "RUB",
          "price": 599,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-219427"
      },
      {
        "id": "533d2b82-1d54-4b3c-892e-9f289a7877ab",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226776"
      },
      {
        "id": "c4819bea-9c1c-42e9-92ec-2b31b56f282e",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 40,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210418"
      },
      {
        "id": "f6a8ff43-dcfb-40f8-8102-1755aa14d0af",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-129329"
      },
      {
        "id": "97283139-438f-4dde-b36e-cf7ba9b5b4e4",
        "price_data": {
          "currency": "RUB",
          "price": 69,
          "vat": "VAT_20"
        },
        "quantity": 51,
        "scan_serial_numbers": [],
        "sku_id": "0Q-121441"
      },
      {
        "id": "3f98db31-ce6a-4788-b5b7-ca3cd0e63f23",
        "price_data": {
          "currency": "RUB",
          "price": 239,
          "vat": "VAT_20"
        },
        "quantity": 28,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226965"
      },
      {
        "id": "ff984271-8712-4d4f-9fe3-8262d3db54eb",
        "price_data": {
          "currency": "RUB",
          "price": 167,
          "vat": "VAT_20"
        },
        "quantity": 98,
        "scan_serial_numbers": [],
        "sku_id": "0Q-121443"
      },
      {
        "id": "3cf38a34-ad72-4131-900e-275b392a71fe",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 30,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196076"
      },
      {
        "id": "0ea8daba-526e-4b18-abb6-14a56b3b4844",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 9,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242239"
      },
      {
        "id": "bbba973c-db17-44ae-9fe2-66180ef1a53c",
        "price_data": {
          "currency": "RUB",
          "price": 549,
          "vat": "VAT_20"
        },
        "quantity": 22,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227066"
      },
      {
        "id": "4330553e-a8ab-4b33-870c-0eafb221a31e",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 7,
        "scan_serial_numbers": [],
        "sku_id": "0Q-177583"
      },
      {
        "id": "d9cef926-2e35-4895-a20f-d38c99651fa5",
        "price_data": {
          "currency": "RUB",
          "price": 1.76,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-201909"
      },
      {
        "id": "b2e3dee9-5dc3-48b4-a6cf-8d14e7d7b07a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 40,
        "scan_serial_numbers": [],
        "sku_id": "0Q-159466"
      },
      {
        "id": "61d32770-3028-489c-9186-143174a1e60f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241005"
      },
      {
        "id": "2a5ed76f-29ac-405c-b8a7-7a149f595039",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-138825"
      },
      {
        "id": "7bc9dee8-ce3d-4a5c-932c-8998b479be9e",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-195961"
      },
      {
        "id": "5dd50b3a-12bf-4b7a-b304-d5503aea1a80",
        "price_data": {
          "currency": "RUB",
          "price": 549,
          "vat": "VAT_20"
        },
        "quantity": 43,
        "scan_serial_numbers": [],
        "sku_id": "0Q-121450"
      },
      {
        "id": "b214d4fe-2689-47ae-ac29-d716efd42e3e",
        "price_data": {
          "currency": "RUB",
          "price": 429,
          "vat": "VAT_20"
        },
        "quantity": 52,
        "scan_serial_numbers": [],
        "sku_id": "0Q-209452"
      },
      {
        "id": "94d76eaf-dd00-49fc-ac6e-1e4de8ebc551",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-220694"
      },
      {
        "id": "1cab5030-f5d1-48b6-a128-5dfe0cfe80ca",
        "price_data": {
          "currency": "RUB",
          "price": 239,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227043"
      },
      {
        "id": "2797c142-c632-451b-9c64-656c9663bb88",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 7,
        "scan_serial_numbers": [],
        "sku_id": "0Q-202935"
      },
      {
        "id": "c425bf1e-8c19-469f-a0f0-91527697cba9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-74105"
      },
      {
        "id": "68329323-9032-4e1c-943d-95a422c20746",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 40,
        "scan_serial_numbers": [],
        "sku_id": "0Q-125475"
      },
      {
        "id": "a2cbc6eb-bd02-4bf5-9ca5-02910efb973c",
        "price_data": {
          "currency": "RUB",
          "price": 109.25,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-120533"
      },
      {
        "id": "6cf9f05a-b970-443a-80a9-1ca5d5cd610d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-179674"
      },
      {
        "id": "0806d2e2-9365-41cd-abd9-2811b50d87a0",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-230121"
      },
      {
        "id": "3ff558f5-9878-4fda-8ae6-edd08ff0394f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-125253"
      },
      {
        "id": "b4194239-d14e-421c-9317-ec9b8f622cb0",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 96,
        "scan_serial_numbers": [],
        "sku_id": "0Q-155187"
      },
      {
        "id": "da0b4408-1fc9-4ae7-9d4c-c4d5bedd9558",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 72,
        "scan_serial_numbers": [],
        "sku_id": "0Q-140136"
      },
      {
        "id": "475d729d-e4fc-4d04-b79f-4bc2fc472113",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-240994"
      },
      {
        "id": "76670c9d-ddab-44aa-b3e8-84f778fe5928",
        "price_data": {
          "currency": "RUB",
          "price": 972.83,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-200064"
      },
      {
        "id": "175746db-ebd3-47a7-aa29-c8ab993b2b76",
        "price_data": {
          "currency": "RUB",
          "price": 9.52,
          "vat": "VAT_20"
        },
        "quantity": 42,
        "scan_serial_numbers": [],
        "sku_id": "0Q-190459"
      },
      {
        "id": "307af933-32b8-4f62-8359-2264f6e8ee7c",
        "price_data": {
          "currency": "RUB",
          "price": 179,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227723"
      },
      {
        "id": "f346bae1-10e3-438e-bfc3-74fd4a58fa92",
        "price_data": {
          "currency": "RUB",
          "price": 226.88,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-243696"
      },
      {
        "id": "aa963123-6711-45f1-9f53-f5ca32d48c5c",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-166529"
      },
      {
        "id": "4edece53-f932-444b-93fe-240eb1f92ebb",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 54,
        "scan_serial_numbers": [],
        "sku_id": "0Q-166470"
      },
      {
        "id": "e536651b-3cd5-4b5d-92de-3dbbf580ac06",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-147373"
      },
      {
        "id": "5170a4ac-9fc5-45d3-b825-f50dd3093cac",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 70,
        "scan_serial_numbers": [],
        "sku_id": "0Q-225734"
      },
      {
        "id": "0c84e484-8df2-4493-82ee-c0d93ae120fd",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-204870"
      },
      {
        "id": "48f3b7f3-e871-4b0b-a0c1-6049e8e76d59",
        "price_data": {
          "currency": "RUB",
          "price": 169,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226960"
      },
      {
        "id": "8ebbe18f-8bc0-41f9-a795-7113efa2bd64",
        "price_data": {
          "currency": "RUB",
          "price": 2690,
          "vat": "VAT_10"
        },
        "quantity": 7,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227075"
      },
      {
        "id": "c1e7a32f-aa49-4c11-abdc-b0a471a5d702",
        "price_data": {
          "currency": "RUB",
          "price": 409,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-231460"
      },
      {
        "id": "6e2800c9-fa3e-4af8-ba88-443fbf7c3aa3",
        "price_data": {
          "currency": "RUB",
          "price": 619,
          "vat": "VAT_20"
        },
        "quantity": 65,
        "scan_serial_numbers": [],
        "sku_id": "0Q-220805"
      },
      {
        "id": "a514ea23-2304-424b-b1ed-e2bd60d3a8ab",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-125250"
      },
      {
        "id": "ff10e0bc-9dd8-459a-9a8d-b2bbb49a6e11",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 60,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206442"
      },
      {
        "id": "13125640-9f50-4acb-96e2-23d006df1a72",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-146598"
      },
      {
        "id": "f5f29a5e-8f14-4c2b-9dbf-901511dec5b1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-164239"
      },
      {
        "id": "a7fb2445-2291-4020-9529-50fe5b955a1b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-231827"
      },
      {
        "id": "c7bcd424-e8bc-4528-8438-4794dee35a14",
        "price_data": {
          "currency": "RUB",
          "price": 399,
          "vat": "VAT_20"
        },
        "quantity": 55,
        "scan_serial_numbers": [],
        "sku_id": "0Q-12406"
      },
      {
        "id": "45b58ed9-9fc2-4684-8803-1b76563fe239",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-208956"
      },
      {
        "id": "55189fab-79d5-45ca-9875-0441622581e7",
        "price_data": {
          "currency": "RUB",
          "price": 195,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-237937"
      },
      {
        "id": "125c2f3e-0ab5-4498-bf1c-9f9bb53c2ba9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 54,
        "scan_serial_numbers": [],
        "sku_id": "0Q-192033"
      },
      {
        "id": "ab5f74e6-8644-46d5-bf67-86d2526c49af",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-159328"
      },
      {
        "id": "4703b19e-ff55-4cdc-b876-27d12054d7b8",
        "price_data": {
          "currency": "RUB",
          "price": 33.75,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-232016"
      },
      {
        "id": "dba5410d-fcec-4782-9b23-fde9ba9820f4",
        "price_data": {
          "currency": "RUB",
          "price": 94.29,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-235832"
      },
      {
        "id": "19dc36b1-da3a-4e79-94d6-3a2a788b0439",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-204584"
      },
      {
        "id": "018ac8f7-dbdb-4b2a-b202-d608abd32334",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-229190"
      },
      {
        "id": "0c2b601c-32d2-41d0-934a-f6ea915c86ab",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 100,
        "scan_serial_numbers": [],
        "sku_id": "0Q-237947"
      },
      {
        "id": "624626ad-a88d-4c68-af3d-6ed41ff49777",
        "price_data": {
          "currency": "RUB",
          "price": 139,
          "vat": "VAT_20"
        },
        "quantity": 60,
        "scan_serial_numbers": [],
        "sku_id": "0Q-93947"
      },
      {
        "id": "6518e2eb-6d78-4306-ada5-3eadddac264d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-133295"
      },
      {
        "id": "4ddf33e2-9969-4501-bf67-43e29e966123",
        "price_data": {
          "currency": "RUB",
          "price": 445,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-158598"
      },
      {
        "id": "60ab9070-f9e8-4235-ab09-590cd7f07b95",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 5,
        "scan_serial_numbers": [],
        "sku_id": "0Q-146031"
      },
      {
        "id": "5bc6f569-514f-4ff2-9d54-2d1fbc7b5544",
        "price_data": {
          "currency": "RUB",
          "price": 172,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-238358"
      },
      {
        "id": "c28d03c7-2e51-4053-84cc-900d29b08350",
        "price_data": {
          "currency": "RUB",
          "price": 18.6,
          "vat": "VAT_20"
        },
        "quantity": 75,
        "scan_serial_numbers": [],
        "sku_id": "0Q-174388"
      },
      {
        "id": "8f9c80af-239f-4162-9582-5046d32a1a4b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242850"
      },
      {
        "id": "bb0e11d6-7c1c-45eb-8c29-fdd6926a45d7",
        "price_data": {
          "currency": "RUB",
          "price": 229,
          "vat": "VAT_20"
        },
        "quantity": 16,
        "scan_serial_numbers": [],
        "sku_id": "0Q-69312"
      },
      {
        "id": "88ba45e5-1e38-4308-ac7f-cd4b8c4a4c7e",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 21,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228014"
      },
      {
        "id": "0edbe327-0a2f-4cab-8706-eda7968d2a3b",
        "price_data": {
          "currency": "RUB",
          "price": 249,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-204699"
      },
      {
        "id": "0b503c8c-5104-4d4b-8aa7-746692077a25",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-134916"
      },
      {
        "id": "74261726-1c3d-4ae3-83b1-db961a6feee7",
        "price_data": {
          "currency": "RUB",
          "price": 689,
          "vat": "VAT_20"
        },
        "quantity": 49,
        "scan_serial_numbers": [],
        "sku_id": "0Q-33489"
      },
      {
        "id": "c0fcf2e4-58ea-41ce-b1b4-29befc40ce01",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 160,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210430"
      },
      {
        "id": "2ccb02ad-58a9-478e-b8bb-13b5439d68af",
        "price_data": {
          "currency": "RUB",
          "price": 259,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-219439"
      },
      {
        "id": "4d5656c4-7f14-405d-b150-2f3a29ed2e22",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-123881"
      },
      {
        "id": "d4ab178b-d7cd-433b-b76a-ff100ccdaae5",
        "price_data": {
          "currency": "RUB",
          "price": 549,
          "vat": "VAT_20"
        },
        "quantity": 52,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242247"
      },
      {
        "id": "a7ebff49-a76d-4bec-9b3c-93fc17f13296",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-204542"
      },
      {
        "id": "21598a2b-e88d-4ad4-9ba3-43376a19e622",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-211741"
      },
      {
        "id": "b67e11c9-9f78-4bfb-a674-3f8b7848e706",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-219118"
      },
      {
        "id": "85b96de7-e743-4e0d-82ca-15109abd01f2",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 35,
        "scan_serial_numbers": [],
        "sku_id": "0Q-231822"
      },
      {
        "id": "d0487917-eaa8-429c-ac97-9301398592ff",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210955"
      },
      {
        "id": "1dc17f4e-d152-49db-9380-dc17b109e911",
        "price_data": {
          "currency": "RUB",
          "price": 113.2,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191920"
      },
      {
        "id": "62aab9f1-3580-47ff-a745-b4e455d3a6dc",
        "price_data": {
          "currency": "RUB",
          "price": 1480,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226973"
      },
      {
        "id": "e097a22d-a54d-41d8-9056-e1e680a99ebd",
        "price_data": {
          "currency": "RUB",
          "price": 1.31,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245840"
      },
      {
        "id": "ef9e52f6-8724-44e7-9b35-6e673b323140",
        "price_data": {
          "currency": "RUB",
          "price": 87.4,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242170"
      },
      {
        "id": "96274986-bdac-4811-b9ee-17c9f89b792d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 9,
        "scan_serial_numbers": [],
        "sku_id": "0Q-220578"
      },
      {
        "id": "9e606cb3-bd77-4339-b861-ee040c0ef778",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-205371"
      },
      {
        "id": "b2282048-b390-4b4b-a853-4fe35b62ebe6",
        "price_data": {
          "currency": "RUB",
          "price": 125,
          "vat": "VAT_10"
        },
        "quantity": 55,
        "scan_serial_numbers": [],
        "sku_id": "0Q-211872"
      },
      {
        "id": "b645e16e-08e3-457e-80dc-3f6ec7cda758",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-225609"
      },
      {
        "id": "0cb707c5-cbc0-484e-ae8d-592327c6b107",
        "price_data": {
          "currency": "RUB",
          "price": 113.2,
          "vat": "VAT_20"
        },
        "quantity": 22,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191919"
      },
      {
        "id": "390cc9cb-816d-45aa-86ea-4ae81182ec15",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191896"
      },
      {
        "id": "dbc966bd-7567-4488-83e0-afaba52ff86a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 5,
        "scan_serial_numbers": [],
        "sku_id": "0Q-223794"
      },
      {
        "id": "11b562f7-1ba9-4fb4-b627-8e6ee29a441a",
        "price_data": {
          "currency": "RUB",
          "price": 51.9,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-243029"
      },
      {
        "id": "327f1a04-7e28-4b88-b503-f3217df46b9a",
        "price_data": {
          "currency": "RUB",
          "price": 115,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-57905"
      },
      {
        "id": "e7347296-533b-450a-ae75-7ac18e5e68e1",
        "price_data": {
          "currency": "RUB",
          "price": 2.46,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245848"
      },
      {
        "id": "e9da1ead-33d3-4bb7-af89-fae63474f392",
        "price_data": {
          "currency": "RUB",
          "price": 650,
          "vat": "VAT_20"
        },
        "quantity": 9,
        "scan_serial_numbers": [],
        "sku_id": "0Q-243718"
      },
      {
        "id": "14867114-68ec-4b04-8c7a-c108114eefdb",
        "price_data": {
          "currency": "RUB",
          "price": 32.25,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242337"
      },
      {
        "id": "535272e6-c163-48ac-9aae-973caf886fff",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 22,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241922"
      },
      {
        "id": "2b70cfea-7b02-4013-b714-962ba97b626f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206459"
      },
      {
        "id": "e925619f-b72e-4664-8da6-0c54589e513d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241004"
      },
      {
        "id": "7570c9e0-4b67-40d2-854a-85ef9f8d75ae",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-111463"
      },
      {
        "id": "6561e40d-4b32-4ac5-bcd4-83a42f4f5102",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 50,
        "scan_serial_numbers": [],
        "sku_id": "0Q-118454"
      },
      {
        "id": "d8241f1a-8146-4b38-9e4c-ce87c4f3ccdf",
        "price_data": {
          "currency": "RUB",
          "price": 195,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-204703"
      },
      {
        "id": "1cf59e42-488e-47b0-9b77-c472ef346011",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-190989"
      },
      {
        "id": "60a6e556-a513-49fa-b953-bd806a7b421b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 40,
        "scan_serial_numbers": [],
        "sku_id": "0Q-141296"
      },
      {
        "id": "7add5855-a2af-40bf-9f97-9dcf0a8e61e4",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 9,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242241"
      },
      {
        "id": "976053b4-ffb5-40bb-aa61-9d9a9a3523b9",
        "price_data": {
          "currency": "RUB",
          "price": 2.46,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245847"
      },
      {
        "id": "f5cee473-0fdd-404e-8e71-f8afdf22467c",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 19,
        "scan_serial_numbers": [],
        "sku_id": "0Q-171693"
      },
      {
        "id": "beb133fb-4595-468e-a71d-7cde12253a18",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 60,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206439"
      },
      {
        "id": "15b7f25f-bffa-440d-8db0-a8d8eaaab26f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-147375"
      },
      {
        "id": "1d7c9163-d201-40e8-a43e-ac1300675f0e",
        "price_data": {
          "currency": "RUB",
          "price": 12.75,
          "vat": "VAT_20"
        },
        "quantity": 42,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196058"
      },
      {
        "id": "f3cd055f-e3fc-44e4-a161-57c4d8e4ee78",
        "price_data": {
          "currency": "RUB",
          "price": 499,
          "vat": "VAT_20"
        },
        "quantity": 37,
        "scan_serial_numbers": [],
        "sku_id": "0Q-13650"
      },
      {
        "id": "0c406e6e-14d5-40ef-bd49-cc5881347b86",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241950"
      },
      {
        "id": "1efcf05b-6a55-4244-ad67-649c4ab7a042",
        "price_data": {
          "currency": "RUB",
          "price": 89,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213309"
      },
      {
        "id": "bbd74121-23e5-4d10-af2b-30828fff65ed",
        "price_data": {
          "currency": "RUB",
          "price": 895,
          "vat": "VAT_10"
        },
        "quantity": 21,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227071"
      },
      {
        "id": "5fefcfdc-c9bc-4181-b77b-2356aada3f40",
        "price_data": {
          "currency": "RUB",
          "price": 165,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228614"
      },
      {
        "id": "0d32f2b0-f8c8-4d40-ad86-04b1766cb8e8",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 72,
        "scan_serial_numbers": [],
        "sku_id": "0Q-149358"
      },
      {
        "id": "0b785bb8-3e40-4b31-8883-30196aacda3a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-164237"
      },
      {
        "id": "cc1d268d-caf3-46cb-ae71-7a6f0694199d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-125248"
      },
      {
        "id": "a6833757-ebb4-43d0-ae8d-94869a63e867",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228309"
      },
      {
        "id": "ec5e8ea7-834b-48f2-884a-c20c5c7cfebb",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-146712"
      },
      {
        "id": "a05ef5f3-2ff7-47f2-b9b2-a9095acb0a1a",
        "price_data": {
          "currency": "RUB",
          "price": 99.38,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-243037"
      },
      {
        "id": "78742f05-d6c9-489b-807c-3098be1b9e1f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-234977"
      },
      {
        "id": "d3f28ada-f0f3-42b4-be8a-5feb6d939655",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 60,
        "scan_serial_numbers": [],
        "sku_id": "0Q-216050"
      },
      {
        "id": "57452a53-1f7b-422c-9da8-db86dedacd7c",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 30,
        "scan_serial_numbers": [],
        "sku_id": "0Q-143399"
      },
      {
        "id": "95b72688-6f37-497f-b1af-84ae42ddcf62",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-215265"
      },
      {
        "id": "1d761f60-2200-4b45-b98f-32e5a7e73a14",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 30,
        "scan_serial_numbers": [],
        "sku_id": "0Q-234974"
      },
      {
        "id": "b78575c5-cde1-4add-bd98-3f60f7c0e988",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 50,
        "scan_serial_numbers": [],
        "sku_id": "0Q-106193"
      },
      {
        "id": "625501f2-e4ba-4d6b-9d4c-4aca4a12da30",
        "price_data": {
          "currency": "RUB",
          "price": 113.2,
          "vat": "VAT_20"
        },
        "quantity": 37,
        "scan_serial_numbers": [],
        "sku_id": "0Q-173592"
      },
      {
        "id": "4da794a1-9bd6-4e69-bc4a-efb3e6e5a871",
        "price_data": {
          "currency": "RUB",
          "price": 349,
          "vat": "VAT_20"
        },
        "quantity": 47,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227050"
      },
      {
        "id": "8686cb94-8367-4c4e-940e-9e18f12eb4db",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-138846"
      },
      {
        "id": "3a6c73a8-029a-464f-ba30-8aec8b39df2d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-138852"
      },
      {
        "id": "162dda91-3754-4cc0-b8e6-d07d8adf48b7",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-133898"
      },
      {
        "id": "b300433a-d1a3-4d74-9724-72776c0e2c6e",
        "price_data": {
          "currency": "RUB",
          "price": 219,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-49935"
      },
      {
        "id": "fcf390fc-f668-451e-8849-ab4b430dfaa8",
        "price_data": {
          "currency": "RUB",
          "price": 129,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-170638"
      },
      {
        "id": "8dac56e7-1878-41e1-b15e-cfb280d0290e",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 33,
        "scan_serial_numbers": [],
        "sku_id": "0Q-237870"
      },
      {
        "id": "790778c6-797c-4824-a1f7-c31ce287e516",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-139578"
      },
      {
        "id": "4082477b-9d45-4270-a78f-ddc459501e32",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-203420"
      },
      {
        "id": "defc79bb-e909-4005-ad09-f59e5ff454ce",
        "price_data": {
          "currency": "RUB",
          "price": 499,
          "vat": "VAT_20"
        },
        "quantity": 123,
        "scan_serial_numbers": [],
        "sku_id": "0Q-223180"
      },
      {
        "id": "8094c2cd-cdb3-4981-94ce-419d360cd4d3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-192039"
      },
      {
        "id": "b657bd87-1167-450f-aeb2-079efc353b51",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227283"
      },
      {
        "id": "187b028d-f6d0-48d5-8fdb-78ec34c586f0",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-231681"
      },
      {
        "id": "2290161e-ae42-4704-a1d5-e972f2f2ce6e",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-237877"
      },
      {
        "id": "75d85bb2-23fa-423a-98e5-e0793c0a06c8",
        "price_data": {
          "currency": "RUB",
          "price": 149,
          "vat": "VAT_20"
        },
        "quantity": 58,
        "scan_serial_numbers": [],
        "sku_id": "0Q-219415"
      },
      {
        "id": "d6c9fba6-9019-4694-a623-85a93da9dc35",
        "price_data": {
          "currency": "RUB",
          "price": 415,
          "vat": "VAT_20"
        },
        "quantity": 51,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228318"
      },
      {
        "id": "4a9e96de-1b98-4fef-924a-e36a69e26503",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 11,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228308"
      },
      {
        "id": "8103e8a0-2047-402c-8e90-3d08e0d10f79",
        "price_data": {
          "currency": "RUB",
          "price": 179,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227017"
      },
      {
        "id": "a87bc7a5-64a3-4b1d-80bb-2bca5fb29959",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226347"
      },
      {
        "id": "fe6a3721-96b9-42b1-bb35-2e2a7401fda1",
        "price_data": {
          "currency": "RUB",
          "price": 199,
          "vat": "VAT_20"
        },
        "quantity": 25,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227022"
      },
      {
        "id": "3e15c9f9-478d-4092-935b-1c1fc325f16c",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-124408"
      },
      {
        "id": "2d91d715-ae89-47f0-998c-cb4e4a21e11a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 5,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228321"
      },
      {
        "id": "9cd958e7-936f-4503-872d-f79a313471da",
        "price_data": {
          "currency": "RUB",
          "price": 185,
          "vat": "VAT_20"
        },
        "quantity": 35,
        "scan_serial_numbers": [],
        "sku_id": "0Q-43003"
      },
      {
        "id": "cda75150-d6ef-43f2-bccc-de90f6e05f5b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-223520"
      },
      {
        "id": "9c5f3304-d865-43bf-916e-0fe06b16f714",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241930"
      },
      {
        "id": "7c21db14-1576-4424-b4f8-53aea153ea72",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-159332"
      },
      {
        "id": "85cc6962-4019-40ff-b1a2-4b358bc64b08",
        "price_data": {
          "currency": "RUB",
          "price": 499,
          "vat": "VAT_20"
        },
        "quantity": 22,
        "scan_serial_numbers": [],
        "sku_id": "0Q-04213"
      },
      {
        "id": "26ed4ed2-51cf-4afb-81bb-3bb28478bf6e",
        "price_data": {
          "currency": "RUB",
          "price": 1.3,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245851"
      },
      {
        "id": "038382de-1a5f-4571-9e4e-c4c7756c938e",
        "price_data": {
          "currency": "RUB",
          "price": 1490,
          "vat": "VAT_10"
        },
        "quantity": 30,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227072"
      },
      {
        "id": "7ed5bf97-701e-4c61-a604-3e6d6121ea6e",
        "price_data": {
          "currency": "RUB",
          "price": 16.18,
          "vat": "VAT_20"
        },
        "quantity": 50,
        "scan_serial_numbers": [],
        "sku_id": "0Q-137070"
      },
      {
        "id": "93c82fb9-55b9-4b9d-a032-65c6895684eb",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228307"
      },
      {
        "id": "f256f658-5c9f-4cd1-97e7-6e94487ba356",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-182312"
      },
      {
        "id": "20a66d94-7626-4ecd-9165-ca3751087d9c",
        "price_data": {
          "currency": "RUB",
          "price": 129,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-170639"
      },
      {
        "id": "592b1b1e-624c-4d9f-be99-4f8da0940646",
        "price_data": {
          "currency": "RUB",
          "price": 259,
          "vat": "VAT_20"
        },
        "quantity": 78,
        "scan_serial_numbers": [],
        "sku_id": "0Q-219440"
      },
      {
        "id": "5b62aa5b-c84b-40c8-8d7a-017123ee2c7e",
        "price_data": {
          "currency": "RUB",
          "price": 70.13,
          "vat": "VAT_20"
        },
        "quantity": 5,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227132"
      },
      {
        "id": "642447d4-62a6-48da-9fcd-c414a029985d",
        "price_data": {
          "currency": "RUB",
          "price": 99,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-243522"
      },
      {
        "id": "b4290e83-c90b-4c0e-b89e-a7454c256673",
        "price_data": {
          "currency": "RUB",
          "price": 315,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-111111"
      },
      {
        "id": "fed6eaa8-2a45-4f8d-9529-2d71d13ac6f0",
        "price_data": {
          "currency": "RUB",
          "price": 89,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213312"
      },
      {
        "id": "bae8b3f1-cd14-4668-8467-b3a689c2e642",
        "price_data": {
          "currency": "RUB",
          "price": 81,
          "vat": "VAT_20"
        },
        "quantity": 21,
        "scan_serial_numbers": [],
        "sku_id": "0Q-219471"
      },
      {
        "id": "04e328d2-7961-4feb-9a6d-322a07934f4b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 100,
        "scan_serial_numbers": [],
        "sku_id": "0Q-201031"
      },
      {
        "id": "9e394685-c580-40c7-b3dc-dfcb699c1257",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-216053"
      },
      {
        "id": "d647eb2d-72fd-4373-bfa4-adc85424e882",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-88592"
      },
      {
        "id": "aa7c842c-08fc-4eb0-ae9a-9eca0e8b64e9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 7,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196016"
      },
      {
        "id": "f32c7ff0-2893-4907-ad7e-ed8465a23e20",
        "price_data": {
          "currency": "RUB",
          "price": 91.67,
          "vat": "VAT_20"
        },
        "quantity": 35,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210317"
      },
      {
        "id": "f0b7dc81-a489-445c-a3b8-6d7327630b7b",
        "price_data": {
          "currency": "RUB",
          "price": 86.15,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227128"
      },
      {
        "id": "65c6708f-c0ae-4699-940b-4774e23c900d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 38,
        "scan_serial_numbers": [],
        "sku_id": "0Q-141131"
      },
      {
        "id": "8cc46505-8f72-4d84-9279-7c25f0c31b99",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-146602"
      },
      {
        "id": "0e546531-7b17-4f3b-982b-b2b9184a5ba4",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-125227"
      },
      {
        "id": "2704b0fe-627f-47b6-a642-c25c7ac812dd",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-66002"
      },
      {
        "id": "c27faa54-9455-4d2c-bdf3-657f585e1fbb",
        "price_data": {
          "currency": "RUB",
          "price": 130.92,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206849"
      },
      {
        "id": "3826c6f9-4b7d-4897-8949-6a5153553b43",
        "price_data": {
          "currency": "RUB",
          "price": 1.18,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-201704"
      },
      {
        "id": "90ad5704-d4ef-463d-9725-f144bb2a060b",
        "price_data": {
          "currency": "RUB",
          "price": 80,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-223239"
      },
      {
        "id": "aa987923-5529-4d98-b523-a0f190cd8c2b",
        "price_data": {
          "currency": "RUB",
          "price": 75,
          "vat": "VAT_20"
        },
        "quantity": 432,
        "scan_serial_numbers": [],
        "sku_id": "0Q-38924"
      },
      {
        "id": "7ff0984a-aadf-4f58-99bf-40941602bb2b",
        "price_data": {
          "currency": "RUB",
          "price": 62.3,
          "vat": "VAT_20"
        },
        "quantity": 28,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227141"
      },
      {
        "id": "d49c3b67-0471-4ec8-a132-f38acb91f654",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-124609"
      },
      {
        "id": "bf5396ec-e4ec-4994-ba50-aece974b9153",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 17,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242244"
      },
      {
        "id": "4c7feb0d-4486-4f03-86b0-090379179c12",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-189932"
      },
      {
        "id": "8386e759-01db-4848-94fc-2f3318b32872",
        "price_data": {
          "currency": "RUB",
          "price": 30,
          "vat": "VAT_20"
        },
        "quantity": 16,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196064"
      },
      {
        "id": "43f5a72e-07cd-4264-8a00-164b5bc914b3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-240992"
      },
      {
        "id": "a50180c9-2f1e-4213-b297-e05140c46480",
        "price_data": {
          "currency": "RUB",
          "price": 169.1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213381"
      },
      {
        "id": "631f7efa-02c7-4c61-8031-975db0b51ed3",
        "price_data": {
          "currency": "RUB",
          "price": 349,
          "vat": "VAT_20"
        },
        "quantity": 47,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227048"
      },
      {
        "id": "aaf84fdf-2638-497f-a007-4627ec4e325f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-202999"
      },
      {
        "id": "44db4905-fc4c-4a59-9d9d-4da2c835f192",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196098"
      },
      {
        "id": "8a558e54-2e68-4947-9aa8-10bc0863ef0a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 38,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228045"
      },
      {
        "id": "51f0acf3-d7ab-4bdc-9b92-3371a3e4fc27",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242851"
      },
      {
        "id": "5c5425a1-cbdd-4922-99e9-1b264f07ac99",
        "price_data": {
          "currency": "RUB",
          "price": 299,
          "vat": "VAT_20"
        },
        "quantity": 44,
        "scan_serial_numbers": [],
        "sku_id": "0Q-229201"
      },
      {
        "id": "8b84ee73-2b21-4b7d-a2fc-6ba7bdaf3303",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241949"
      },
      {
        "id": "979b0db2-857b-48db-83bc-f59046d30116",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 80,
        "scan_serial_numbers": [],
        "sku_id": "0Q-125477"
      },
      {
        "id": "38ae9a4d-3914-44ca-8cd5-3c3a13d94668",
        "price_data": {
          "currency": "RUB",
          "price": 229,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-221427"
      },
      {
        "id": "99a500fa-d82e-44c2-bd48-f613faa7a22b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-150466"
      },
      {
        "id": "6f7ee3a9-b907-401f-84b0-4e3832dc9fff",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 28,
        "scan_serial_numbers": [],
        "sku_id": "0Q-200216"
      },
      {
        "id": "91defc70-fb83-4d7d-abd0-56d7b09fc0ad",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-173306"
      },
      {
        "id": "2563f664-4cc6-482e-aa14-520966942f03",
        "price_data": {
          "currency": "RUB",
          "price": 26.43,
          "vat": "VAT_20"
        },
        "quantity": 34,
        "scan_serial_numbers": [],
        "sku_id": "0Q-53514"
      },
      {
        "id": "d7fa7caa-88b4-4c71-8c3b-ef16b01a82c7",
        "price_data": {
          "currency": "RUB",
          "price": 289,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-229949"
      },
      {
        "id": "732a1720-ab97-49b5-a84e-ded1474e759c",
        "price_data": {
          "currency": "RUB",
          "price": 1.18,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245845"
      },
      {
        "id": "5f08d0d9-1eed-490f-a575-5e1507aaea9e",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-240997"
      },
      {
        "id": "e64e825f-6ad4-4be5-b7b3-4621e9e4573f",
        "price_data": {
          "currency": "RUB",
          "price": 499,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-223271"
      },
      {
        "id": "1dbea9a1-053e-4dcb-a597-cedce4b7dd7f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-84360"
      },
      {
        "id": "7e991170-ef51-451a-b9e1-bef7432c97f3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 150,
        "scan_serial_numbers": [],
        "sku_id": "0Q-138838"
      },
      {
        "id": "c3c9cf33-66b7-4157-9d61-371e974e8040",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 27,
        "scan_serial_numbers": [],
        "sku_id": "0Q-135765"
      },
      {
        "id": "c8de68c3-0962-4329-adbf-58f466a5e7d3",
        "price_data": {
          "currency": "RUB",
          "price": 719,
          "vat": "VAT_10"
        },
        "quantity": 41,
        "scan_serial_numbers": [],
        "sku_id": "0Q-211853"
      },
      {
        "id": "0d8cf01d-d907-4f4e-adf9-92547717950f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213825"
      },
      {
        "id": "f6be8027-60fe-4305-a470-7c171e619d0b",
        "price_data": {
          "currency": "RUB",
          "price": 399,
          "vat": "VAT_20"
        },
        "quantity": 42,
        "scan_serial_numbers": [],
        "sku_id": "0Q-246390"
      },
      {
        "id": "c6c62903-a24a-4cb7-ab6e-4903087df95b",
        "price_data": {
          "currency": "RUB",
          "price": 9.52,
          "vat": "VAT_20"
        },
        "quantity": 42,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226645"
      },
      {
        "id": "350e31a7-12d5-40e4-8e54-c28ecf7f7f4e",
        "price_data": {
          "currency": "RUB",
          "price": 87.24,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-234151"
      },
      {
        "id": "34f35e35-d931-4433-a8bd-7353cce426ba",
        "price_data": {
          "currency": "RUB",
          "price": 1.31,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245843"
      },
      {
        "id": "b71131ac-df67-41ab-9061-cd14a0d127a0",
        "price_data": {
          "currency": "RUB",
          "price": 95,
          "vat": "VAT_20"
        },
        "quantity": 16,
        "scan_serial_numbers": [],
        "sku_id": "0Q-00640"
      },
      {
        "id": "d989e685-9496-44d7-9bd8-ddc940859fac",
        "price_data": {
          "currency": "RUB",
          "price": 247,
          "vat": "VAT_20"
        },
        "quantity": 90,
        "scan_serial_numbers": [],
        "sku_id": "0Q-55953"
      },
      {
        "id": "a2fa8a33-be24-4a32-89b1-649ad8035f82",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-222579"
      },
      {
        "id": "4e27526e-d47f-4e57-b591-74691f5816b9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191902"
      },
      {
        "id": "825b706c-52c8-4e53-b2c3-2221b8c0acf2",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 53,
        "scan_serial_numbers": [],
        "sku_id": "0Q-231663"
      },
      {
        "id": "343c5ebe-3554-4339-b76a-26edf5db02ce",
        "price_data": {
          "currency": "RUB",
          "price": 99,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-234403"
      },
      {
        "id": "3d0a4cbc-9e86-4e99-9dc0-8cc751b2f79d",
        "price_data": {
          "currency": "RUB",
          "price": 299,
          "vat": "VAT_20"
        },
        "quantity": 64,
        "scan_serial_numbers": [],
        "sku_id": "0Q-221429"
      },
      {
        "id": "030bb995-11b8-40c1-8cb2-0c0bf0db7107",
        "price_data": {
          "currency": "RUB",
          "price": 1.31,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245842"
      },
      {
        "id": "305e4cb8-2fa8-441c-8969-0c2292d0364b",
        "price_data": {
          "currency": "RUB",
          "price": 349,
          "vat": "VAT_20"
        },
        "quantity": 64,
        "scan_serial_numbers": [],
        "sku_id": "0Q-229199"
      },
      {
        "id": "f19fe016-59d8-45df-b0e5-13dc8995a1a1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-240993"
      },
      {
        "id": "5ce6c27a-4b80-4a5b-9b7d-df087581a036",
        "price_data": {
          "currency": "RUB",
          "price": 155,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-26713"
      },
      {
        "id": "f38408ec-28ed-4337-a014-aa4c22bf42d3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 26,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196364"
      },
      {
        "id": "3291b4a3-3d63-4c32-889c-5d520ddca9f9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 30,
        "scan_serial_numbers": [],
        "sku_id": "0Q-221407"
      },
      {
        "id": "ee164f5b-2eb5-4e41-85be-7380cfbc5ab2",
        "price_data": {
          "currency": "RUB",
          "price": 239,
          "vat": "VAT_20"
        },
        "quantity": 30,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226964"
      },
      {
        "id": "29a1dbe1-6cc1-4fc3-b0d7-3eeed2932761",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-230115"
      },
      {
        "id": "da9b558c-f1ed-4297-94b3-e6c5d8b36825",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-177581"
      },
      {
        "id": "52c8d3d0-3e3c-4ef6-813f-765e58039420",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 37,
        "scan_serial_numbers": [],
        "sku_id": "0Q-209761"
      },
      {
        "id": "fe583ce7-d0f8-4a7a-9aac-8b9a00a9c333",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-138845"
      },
      {
        "id": "8734e441-2207-44e8-a168-3a2316b232f2",
        "price_data": {
          "currency": "RUB",
          "price": 175,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210329"
      },
      {
        "id": "46186e27-8b40-4328-a1d6-47849f6757f6",
        "price_data": {
          "currency": "RUB",
          "price": 18.55,
          "vat": "VAT_20"
        },
        "quantity": 25,
        "scan_serial_numbers": [],
        "sku_id": "0Q-133194"
      },
      {
        "id": "4c796916-aae1-4275-a4da-ba04bea59717",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-160221"
      },
      {
        "id": "f87c240a-6bc4-43e7-a061-b76b0d4f8c8d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-72292"
      },
      {
        "id": "8b2a70b2-c687-4022-9485-347b00403b1d",
        "price_data": {
          "currency": "RUB",
          "price": 239,
          "vat": "VAT_20"
        },
        "quantity": 19,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227045"
      },
      {
        "id": "23de2c11-13e6-47ef-9068-c6b3a449bbf5",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 28,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191905"
      },
      {
        "id": "967f1033-ca44-4e1b-b8d4-124907d171fe",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 22,
        "scan_serial_numbers": [],
        "sku_id": "0Q-154453"
      },
      {
        "id": "f3aed518-2095-4792-b5eb-70b8c3b221f3",
        "price_data": {
          "currency": "RUB",
          "price": 1.18,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245854"
      },
      {
        "id": "9a20f5e8-f0fc-415c-8ee0-26712b9d7bc0",
        "price_data": {
          "currency": "RUB",
          "price": 1.31,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245838"
      },
      {
        "id": "0e0dc4ef-c8f1-40e6-a8eb-f5dfadec048a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-185834"
      },
      {
        "id": "2b14c448-aee7-4ec6-ae00-a62c130afaf0",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-201419"
      },
      {
        "id": "12483739-17ba-4c62-957d-7b6fa7488941",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-211040"
      },
      {
        "id": "c18daefc-102a-4a8b-abce-7144fb5d7eb3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 7,
        "scan_serial_numbers": [],
        "sku_id": "0Q-160434"
      },
      {
        "id": "c20962a7-2310-435e-b0ec-5bc5f122e85f",
        "price_data": {
          "currency": "RUB",
          "price": 328,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-217211"
      },
      {
        "id": "c39d4639-9cd7-427b-958f-53b664f76371",
        "price_data": {
          "currency": "RUB",
          "price": 149,
          "vat": "VAT_20"
        },
        "quantity": 21,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227042"
      },
      {
        "id": "33b23adf-161a-4c47-912f-9f77c0960b44",
        "price_data": {
          "currency": "RUB",
          "price": 149,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-223167"
      },
      {
        "id": "a7101295-fe0f-4d60-b13f-85689a320a43",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 80,
        "scan_serial_numbers": [],
        "sku_id": "0Q-109334"
      },
      {
        "id": "62eb6f1f-5ead-4813-8885-c9ab02057aaf",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-230110"
      },
      {
        "id": "b92ca034-d9d9-4ece-8e1b-4320988c6976",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-167086"
      },
      {
        "id": "3e087770-6684-46da-92b1-5fc569eaf377",
        "price_data": {
          "currency": "RUB",
          "price": 119,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-77693"
      },
      {
        "id": "b795031e-013a-4e7f-9b70-d7fb8e5b4bd1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-111460"
      },
      {
        "id": "495274a7-2bb8-44d2-aa1b-ae17b50a1d4f",
        "price_data": {
          "currency": "RUB",
          "price": 129,
          "vat": "VAT_20"
        },
        "quantity": 47,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245258"
      },
      {
        "id": "78ec9b6e-a804-4fb1-a06a-e5137a064992",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-232579"
      },
      {
        "id": "19c7eef2-f83f-49c4-bbbf-f66f8bc31207",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242853"
      },
      {
        "id": "c7f1dff9-6888-4cd0-885e-1be8f6ef83d2",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-153263"
      },
      {
        "id": "f6efcefb-7e15-4daf-b3c6-955ce63221da",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 9,
        "scan_serial_numbers": [],
        "sku_id": "0Q-234987"
      },
      {
        "id": "cf0e5a2b-c711-449f-bc43-353185cc7cc9",
        "price_data": {
          "currency": "RUB",
          "price": 32.59,
          "vat": "VAT_20"
        },
        "quantity": 84,
        "scan_serial_numbers": [],
        "sku_id": "0Q-152661"
      },
      {
        "id": "0d2c6688-5b6b-4c70-be93-1a8afccf5ab4",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 30,
        "scan_serial_numbers": [],
        "sku_id": "0Q-125478"
      },
      {
        "id": "21e71a75-4ffd-40f0-9e2d-7bcc17f70c56",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 54,
        "scan_serial_numbers": [],
        "sku_id": "0Q-223737"
      },
      {
        "id": "e0ebfdaa-b981-4418-a858-8854c32ca91b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 43,
        "scan_serial_numbers": [],
        "sku_id": "0Q-200534"
      },
      {
        "id": "edd66793-6afc-4552-8bd5-a8209654dea0",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242849"
      },
      {
        "id": "b3669b5f-c1b7-4d67-b8fe-01e4e525b226",
        "price_data": {
          "currency": "RUB",
          "price": 549,
          "vat": "VAT_20"
        },
        "quantity": 17,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242248"
      },
      {
        "id": "0defaed6-33fc-46ce-94c3-9bcccfbd7049",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 22,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228311"
      },
      {
        "id": "850c5243-e369-45f1-bedb-1608caa0f556",
        "price_data": {
          "currency": "RUB",
          "price": 120,
          "vat": "VAT_20"
        },
        "quantity": 11,
        "scan_serial_numbers": [],
        "sku_id": "0Q-243780"
      },
      {
        "id": "c2580da6-6a47-42e1-ac0d-0695d4891bbc",
        "price_data": {
          "currency": "RUB",
          "price": 1844.7,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-34426"
      },
      {
        "id": "1516559b-0c61-40a9-a516-5fa8df3cfb3f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226777"
      },
      {
        "id": "8af645a9-986e-423a-bd2a-d98fdf0935a7",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-232577"
      },
      {
        "id": "a3c84a8b-23c9-4e5a-b9b3-aafc1ec6ee59",
        "price_data": {
          "currency": "RUB",
          "price": 27.72,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227142"
      },
      {
        "id": "e0765399-8987-429a-ae87-cfec0bcb2d53",
        "price_data": {
          "currency": "RUB",
          "price": 235.9,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-220845"
      },
      {
        "id": "0dbfd214-b381-4cb0-a3b3-220354701a58",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 21,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191901"
      },
      {
        "id": "b206940a-3ebf-4d76-9bed-a19b364982f8",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-229189"
      },
      {
        "id": "1c4ebf40-ecfc-4d39-8dc6-5ba1157efed7",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241001"
      },
      {
        "id": "7a816df2-ea88-4b48-ae06-89e56c3e6e68",
        "price_data": {
          "currency": "RUB",
          "price": 399,
          "vat": "VAT_20"
        },
        "quantity": 138,
        "scan_serial_numbers": [],
        "sku_id": "0Q-12407"
      },
      {
        "id": "4c5a42b1-0ef2-4937-9f33-6b289df9a7c5",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 13,
        "scan_serial_numbers": [],
        "sku_id": "0Q-170270"
      },
      {
        "id": "59318c27-4043-45b2-a975-f72146d99f7a",
        "price_data": {
          "currency": "RUB",
          "price": 149,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226296"
      },
      {
        "id": "2b1fc06f-f00a-485d-bfd5-ebf830c640dd",
        "price_data": {
          "currency": "RUB",
          "price": 249,
          "vat": "VAT_20"
        },
        "quantity": 56,
        "scan_serial_numbers": [],
        "sku_id": "0Q-219429"
      },
      {
        "id": "5183bfd8-1b6e-459a-8bfc-3933caf8738d",
        "price_data": {
          "currency": "RUB",
          "price": 249,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-204700"
      },
      {
        "id": "c51e1056-15ba-4566-ac93-9348aad39389",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-237684"
      },
      {
        "id": "710657fe-23f0-4f15-9734-87ea677622a3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-187306"
      },
      {
        "id": "352905ce-d825-469b-8e52-d5ef1bf3e90c",
        "price_data": {
          "currency": "RUB",
          "price": 172,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-238017"
      },
      {
        "id": "b766cd77-ccee-4371-b529-23f1f79053da",
        "price_data": {
          "currency": "RUB",
          "price": 139,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227041"
      },
      {
        "id": "aa364d79-35c4-403e-8618-cc8da2d0f5ec",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-85825"
      },
      {
        "id": "8501ef67-c8fd-4549-8ee7-0308f5515470",
        "price_data": {
          "currency": "RUB",
          "price": 299,
          "vat": "VAT_20"
        },
        "quantity": 16,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213183"
      },
      {
        "id": "b02cc35a-a88a-4e3f-9dd8-0edb1fc0321c",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-240996"
      },
      {
        "id": "4504fd0d-2ad5-4714-9f8b-26d56f9a5412",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-170375"
      },
      {
        "id": "583466ea-4a0e-47f0-a173-ff1815643b09",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196420"
      },
      {
        "id": "618020f2-94c2-4f0f-bba2-03352fe64ae2",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 11,
        "scan_serial_numbers": [],
        "sku_id": "0Q-134563"
      },
      {
        "id": "9b1d5499-a61f-4ab4-ba43-2070f46ff65c",
        "price_data": {
          "currency": "RUB",
          "price": 399,
          "vat": "VAT_20"
        },
        "quantity": 71,
        "scan_serial_numbers": [],
        "sku_id": "0Q-12413"
      },
      {
        "id": "b5883893-bd47-4fe2-8c87-8f84888ae707",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196440"
      },
      {
        "id": "056e8cf3-97b7-42e2-acf4-c97dad949fe7",
        "price_data": {
          "currency": "RUB",
          "price": 219,
          "vat": "VAT_20"
        },
        "quantity": 19,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226966"
      },
      {
        "id": "b6fbd428-2c00-4430-b6f0-e2fac68e8f53",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196509"
      },
      {
        "id": "6cc973d8-c114-46c3-a855-1071a51afecc",
        "price_data": {
          "currency": "RUB",
          "price": 1.6,
          "vat": "VAT_20"
        },
        "quantity": 30,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228357"
      },
      {
        "id": "d0876bd9-06dc-4f35-9efa-890b1cefbe9d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 28,
        "scan_serial_numbers": [],
        "sku_id": "0Q-166995"
      },
      {
        "id": "846afe29-df57-4e37-bb25-2b8f23055bb9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242199"
      },
      {
        "id": "130f813d-19c9-4583-b1c6-1a3534b3d60e",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-133290"
      },
      {
        "id": "2efb1e47-7039-4f3a-91c0-fddef4b94021",
        "price_data": {
          "currency": "RUB",
          "price": 347,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-233227"
      },
      {
        "id": "766b26e9-07f3-4f4b-8c99-a7ab78b9d08e",
        "price_data": {
          "currency": "RUB",
          "price": 179,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227720"
      },
      {
        "id": "4f51b10e-ea65-4d10-b75a-d3a86b9a1d00",
        "price_data": {
          "currency": "RUB",
          "price": 32.25,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242338"
      },
      {
        "id": "5317aa48-92bd-40a2-9a9f-d45ad359959f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241000"
      },
      {
        "id": "1dd4c0fd-6fc5-4e13-915d-c2174eeb3a1d",
        "price_data": {
          "currency": "RUB",
          "price": 545,
          "vat": "VAT_20"
        },
        "quantity": 49,
        "scan_serial_numbers": [],
        "sku_id": "0Q-65094"
      },
      {
        "id": "9477083e-1f08-4c2b-bd2b-3f8e83f0197b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-135766"
      },
      {
        "id": "510682e1-2250-4d05-b5e4-0df6adca022e",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-187325"
      },
      {
        "id": "1651aab1-a3ab-4369-af09-f4c6bb0463af",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 18,
        "scan_serial_numbers": [],
        "sku_id": "0Q-208954"
      },
      {
        "id": "f9fb6ec9-5096-42fe-b8bd-b0a5b1822b8a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-202998"
      },
      {
        "id": "a4f57018-9a3c-4f73-9090-baf1dee18268",
        "price_data": {
          "currency": "RUB",
          "price": 169,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226959"
      },
      {
        "id": "38cc18d7-7b58-43f6-ad4e-4ac477dc7ba9",
        "price_data": {
          "currency": "RUB",
          "price": 85,
          "vat": "VAT_20"
        },
        "quantity": 44,
        "scan_serial_numbers": [],
        "sku_id": "0Q-93946"
      },
      {
        "id": "83e707f5-2df1-4349-8ab6-197c9287a4ac",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-222598"
      },
      {
        "id": "6dc84d81-34f1-49e6-acd2-b0c861b317b8",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-147368"
      },
      {
        "id": "1aedf4dd-0640-4503-9f59-b412f4d07db9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-161198"
      },
      {
        "id": "4dd2ebac-8346-4d1b-96bc-30dc48ef75f8",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-143094"
      },
      {
        "id": "5ac25369-430e-43c4-9a3e-b76da6261940",
        "price_data": {
          "currency": "RUB",
          "price": 58.22,
          "vat": "VAT_20"
        },
        "quantity": 22,
        "scan_serial_numbers": [],
        "sku_id": "0Q-121934"
      },
      {
        "id": "05128b9b-98e4-43e2-b80c-dcd32429622c",
        "price_data": {
          "currency": "RUB",
          "price": 415,
          "vat": "VAT_20"
        },
        "quantity": 100,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226293"
      },
      {
        "id": "114ed68e-7833-4343-a0d7-697c9b8789c9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 16,
        "scan_serial_numbers": [],
        "sku_id": "0Q-150476"
      },
      {
        "id": "2fb686e0-09c4-4040-93da-b47da5fc39d0",
        "price_data": {
          "currency": "RUB",
          "price": 159,
          "vat": "VAT_20"
        },
        "quantity": 19,
        "scan_serial_numbers": [],
        "sku_id": "0Q-00526"
      },
      {
        "id": "53eeb4f3-6ea8-46c7-b88d-b985609e01be",
        "price_data": {
          "currency": "RUB",
          "price": 439,
          "vat": "VAT_20"
        },
        "quantity": 47,
        "scan_serial_numbers": [],
        "sku_id": "0Q-27830"
      },
      {
        "id": "4d4299d5-8c38-4e03-bee8-15748d681703",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196375"
      },
      {
        "id": "8e0c0513-c67f-4f0f-b172-c430769432e2",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-152834"
      },
      {
        "id": "66cf5a19-e709-49d1-bcab-fa05cf383cc6",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241003"
      },
      {
        "id": "db55b207-3247-4ff8-978a-80ccf36d7b99",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228305"
      },
      {
        "id": "a2f0d14b-1f81-4f98-83bb-3fa80cecdc3d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 60,
        "scan_serial_numbers": [],
        "sku_id": "0Q-231679"
      },
      {
        "id": "9d2c70dc-7d7e-4eec-9edc-6561dfe72d5d",
        "price_data": {
          "currency": "RUB",
          "price": 26.43,
          "vat": "VAT_20"
        },
        "quantity": 5,
        "scan_serial_numbers": [],
        "sku_id": "0Q-28175"
      },
      {
        "id": "7f713423-cf70-4338-8409-cc91e5d46d33",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 13,
        "scan_serial_numbers": [],
        "sku_id": "0Q-239216"
      },
      {
        "id": "3af6b1e5-cb47-493b-bc36-4600094c3d7d",
        "price_data": {
          "currency": "RUB",
          "price": 169,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226961"
      },
      {
        "id": "c66c8955-8fa7-4ec6-8c76-9122a2547634",
        "price_data": {
          "currency": "RUB",
          "price": 130.38,
          "vat": "VAT_20"
        },
        "quantity": 49,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206154"
      },
      {
        "id": "7b053cd0-1d9e-4239-b3a5-16cfc969771c",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-173572"
      },
      {
        "id": "968b86dd-ef27-46d3-ae42-6b3d9862ebcf",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 40,
        "scan_serial_numbers": [],
        "sku_id": "0Q-129331"
      },
      {
        "id": "22f82c19-517c-4df6-9731-0ed4a33493ce",
        "price_data": {
          "currency": "RUB",
          "price": 1.69,
          "vat": "VAT_20"
        },
        "quantity": 96,
        "scan_serial_numbers": [],
        "sku_id": "0Q-214342"
      },
      {
        "id": "19d81ae8-cce3-46aa-8fa7-02d1d449273c",
        "price_data": {
          "currency": "RUB",
          "price": 89,
          "vat": "VAT_20"
        },
        "quantity": 145,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213310"
      },
      {
        "id": "1345739a-8093-4596-a88a-2ee726df0a12",
        "price_data": {
          "currency": "RUB",
          "price": 30.38,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-141285"
      },
      {
        "id": "e3e24e7e-02ac-46e9-99c7-601380942fe7",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 29,
        "scan_serial_numbers": [],
        "sku_id": "0Q-205364"
      },
      {
        "id": "85f440cc-cd82-49ec-a26e-36508604e413",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 36,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191903"
      },
      {
        "id": "b0d9f921-24d6-4551-b69b-02909b07200c",
        "price_data": {
          "currency": "RUB",
          "price": 149,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-93943"
      },
      {
        "id": "3e45a12a-1693-4264-8855-fda28ee72b0b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-204823"
      },
      {
        "id": "b1535e12-055f-4340-b2d5-4da6cf4256eb",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-215105"
      },
      {
        "id": "7cd4e21e-2a33-4ad0-9985-29b568c7d159",
        "price_data": {
          "currency": "RUB",
          "price": 57.9,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-196686"
      },
      {
        "id": "cf4ba9e2-1fbf-48b7-9710-9cbc6bac0a29",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 13,
        "scan_serial_numbers": [],
        "sku_id": "0Q-233388"
      },
      {
        "id": "f5741d76-4ad9-4e7a-b6de-e47c22509bd8",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-190460"
      },
      {
        "id": "0ed4acd4-71e9-4876-a306-ab20b5af759a",
        "price_data": {
          "currency": "RUB",
          "price": 43,
          "vat": "VAT_20"
        },
        "quantity": 156,
        "scan_serial_numbers": [],
        "sku_id": "0Q-93945"
      },
      {
        "id": "68c88432-e094-41b4-98d0-ab3e722b2392",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 15,
        "scan_serial_numbers": [],
        "sku_id": "0Q-188403"
      },
      {
        "id": "9a14c1ee-8317-4f76-8a15-b1271e723d49",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-203428"
      },
      {
        "id": "f401cb35-d654-4f43-b514-d2496e6f2482",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-173301"
      },
      {
        "id": "93533ff2-9016-4dc3-bc5d-c0eb969334c4",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-136041"
      },
      {
        "id": "20d03a95-b3e5-485d-8969-bbc70e9f8659",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 14,
        "scan_serial_numbers": [],
        "sku_id": "0Q-150473"
      },
      {
        "id": "3d00c0bc-c620-4b54-90e4-03985f5a6779",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-159330"
      },
      {
        "id": "93524883-988d-40ca-80e9-d905c669ab8b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 54,
        "scan_serial_numbers": [],
        "sku_id": "0Q-147680"
      },
      {
        "id": "5784670f-cc68-403e-97aa-b219680faef9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-192318"
      },
      {
        "id": "f9c05d60-0f55-4af8-b469-c71cbfa14a40",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 7,
        "scan_serial_numbers": [],
        "sku_id": "0Q-162908"
      },
      {
        "id": "85e4df7e-fa70-4f50-ba68-3a1caaca969d",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 40,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210421"
      },
      {
        "id": "c4eff324-e744-4005-b81d-c34b778ccfc9",
        "price_data": {
          "currency": "RUB",
          "price": 72.89,
          "vat": "VAT_20"
        },
        "quantity": 10,
        "scan_serial_numbers": [],
        "sku_id": "0Q-212882"
      },
      {
        "id": "243eded9-9304-4b07-90a1-4a872518ae41",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228310"
      },
      {
        "id": "5095b58d-35a5-4ac0-b70f-5c13257b8785",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 8,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227963"
      },
      {
        "id": "308a8b5e-4820-43ca-8dd1-6e5acb92b384",
        "price_data": {
          "currency": "RUB",
          "price": 1.52,
          "vat": "VAT_20"
        },
        "quantity": 16,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206223"
      },
      {
        "id": "ad8bd115-9d01-4e8e-9b37-b3115f831b10",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 16,
        "scan_serial_numbers": [],
        "sku_id": "0Q-166520"
      },
      {
        "id": "f73ce30a-0252-4bc5-9b13-6f4cf4825ed9",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-125042"
      },
      {
        "id": "efabf8a6-5f38-4611-bb61-717a7995520c",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-241002"
      },
      {
        "id": "5145b886-1b8f-46e0-a321-21d3d9e81c11",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-179605"
      },
      {
        "id": "921b3474-0ef2-4ded-bb6f-16780fe23c2c",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-237754"
      },
      {
        "id": "26dec025-f1c2-4ddd-a768-8e9669ee395e",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242852"
      },
      {
        "id": "157ff915-d108-4e06-b063-99ae72edc3e9",
        "price_data": {
          "currency": "RUB",
          "price": 399,
          "vat": "VAT_20"
        },
        "quantity": 9,
        "scan_serial_numbers": [],
        "sku_id": "0Q-08730"
      },
      {
        "id": "ab6dc36c-9b90-4750-809b-e85dac06b7ac",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 32,
        "scan_serial_numbers": [],
        "sku_id": "0Q-212301"
      },
      {
        "id": "20ba3b35-63bb-405a-ac82-249781eb2620",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 80,
        "scan_serial_numbers": [],
        "sku_id": "0Q-123904"
      },
      {
        "id": "0b985970-3bb1-49ab-b38e-7823b25fcab2",
        "price_data": {
          "currency": "RUB",
          "price": 229,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227033"
      },
      {
        "id": "4654167d-bc79-4054-a88f-fde2dc1a3ec1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 16,
        "scan_serial_numbers": [],
        "sku_id": "0Q-191885"
      },
      {
        "id": "286c199b-9b44-4283-b353-2943cec76f53",
        "price_data": {
          "currency": "RUB",
          "price": 349,
          "vat": "VAT_20"
        },
        "quantity": 20,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226970"
      },
      {
        "id": "8b8b141a-c1d9-47e4-b41c-7ae8cf9dc37f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-123897"
      },
      {
        "id": "995fb8a1-ecba-49c6-a073-151c0607c82a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 19,
        "scan_serial_numbers": [],
        "sku_id": "0Q-169248"
      },
      {
        "id": "d7aaa7df-a5d8-4363-8476-51cf9a8e0a3b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-242854"
      },
      {
        "id": "d7fe19ae-91d1-4006-b68c-d7eb62b06b93",
        "price_data": {
          "currency": "RUB",
          "price": 1.3,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-245852"
      },
      {
        "id": "34d25384-6d9b-42a3-93f8-7433b53da15b",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 13,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228306"
      },
      {
        "id": "5559a5dd-e511-4bf1-9a06-bd9330ec7ade",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 102,
        "scan_serial_numbers": [],
        "sku_id": "0Q-206286"
      },
      {
        "id": "f5e774d0-bf94-4cb8-81b8-f5fef755b6cb",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 13,
        "scan_serial_numbers": [],
        "sku_id": "0Q-154669"
      },
      {
        "id": "13b6146b-2a40-4971-a1be-c2487dc60119",
        "price_data": {
          "currency": "RUB",
          "price": 119,
          "vat": "VAT_20"
        },
        "quantity": 25,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227035"
      },
      {
        "id": "31507e26-8b80-450f-9e97-41e9dc439102",
        "price_data": {
          "currency": "RUB",
          "price": 120.23,
          "vat": "VAT_20"
        },
        "quantity": 17,
        "scan_serial_numbers": [],
        "sku_id": "0Q-154970"
      },
      {
        "id": "ae0a8d61-42ad-442d-9c97-ce7f351e0dd1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 2,
        "scan_serial_numbers": [],
        "sku_id": "0Q-226381"
      },
      {
        "id": "9cadfcb7-b172-4259-8001-1bc50f4b906f",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228050"
      },
      {
        "id": "6926dd05-c987-4dcf-a87f-fc1052d6e101",
        "price_data": {
          "currency": "RUB",
          "price": 129,
          "vat": "VAT_20"
        },
        "quantity": 84,
        "scan_serial_numbers": [],
        "sku_id": "0Q-170637"
      },
      {
        "id": "2e79ac4b-710a-47d4-9a35-fbfd5d03e7f2",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 5,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213843"
      },
      {
        "id": "2f873a74-81f2-49bf-a7aa-e07b1f2a7693",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 33,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210414"
      },
      {
        "id": "ab06bbf2-1fc1-4d48-a056-66bf5e7b2136",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-72343"
      },
      {
        "id": "c564a092-0770-484c-8713-4de0b29ca97a",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 48,
        "scan_serial_numbers": [],
        "sku_id": "0Q-138866"
      },
      {
        "id": "b5b7e158-a439-4359-ae4b-fa52525e2228",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-237872"
      },
      {
        "id": "beb2f166-b6ac-4cab-9360-0e586c53ff58",
        "price_data": {
          "currency": "RUB",
          "price": 465,
          "vat": "VAT_20"
        },
        "quantity": 13,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227070"
      },
      {
        "id": "11a6a088-8cb3-4fa0-bdd7-1a899918cb9d",
        "price_data": {
          "currency": "RUB",
          "price": 279,
          "vat": "VAT_20"
        },
        "quantity": 52,
        "scan_serial_numbers": [],
        "sku_id": "0Q-229202"
      },
      {
        "id": "ee76c937-4320-45d5-ab4e-a10bca17be01",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 12,
        "scan_serial_numbers": [],
        "sku_id": "0Q-217684"
      },
      {
        "id": "a2517fd9-a9e0-43d2-879d-db8e16f0cab3",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 46,
        "scan_serial_numbers": [],
        "sku_id": "0Q-221405"
      },
      {
        "id": "8af6a6a3-85ab-4bfb-8ba4-db8e800485c0",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-189709"
      },
      {
        "id": "2285daa4-43d9-400b-bdf3-d49cec042173",
        "price_data": {
          "currency": "RUB",
          "price": 399,
          "vat": "VAT_20"
        },
        "quantity": 58,
        "scan_serial_numbers": [],
        "sku_id": "0Q-12730"
      },
      {
        "id": "7db12416-6bd2-4319-ba20-bdf3f19280c1",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 47,
        "scan_serial_numbers": [],
        "sku_id": "0Q-231661"
      },
      {
        "id": "3e1c1c29-b6c7-452f-a374-4a4ef185ca8f",
        "price_data": {
          "currency": "RUB",
          "price": 259,
          "vat": "VAT_20"
        },
        "quantity": 6,
        "scan_serial_numbers": [],
        "sku_id": "0Q-211768"
      },
      {
        "id": "cc4fd7d5-1329-4798-aa42-daac5d0bd13e",
        "price_data": {
          "currency": "RUB",
          "price": 51.72,
          "vat": "VAT_20"
        },
        "quantity": 35,
        "scan_serial_numbers": [],
        "sku_id": "0Q-150468"
      },
      {
        "id": "a5de520e-e032-4d52-a299-bf4ca5fdc96b",
        "price_data": {
          "currency": "RUB",
          "price": 329,
          "vat": "VAT_20"
        },
        "quantity": 3,
        "scan_serial_numbers": [],
        "sku_id": "0Q-213029"
      },
      {
        "id": "cd300b1d-ff5a-4786-abc6-bac72a66ce65",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 4,
        "scan_serial_numbers": [],
        "sku_id": "0Q-150472"
      },
      {
        "id": "3791bd54-b052-4e6d-adfd-00ff346d3263",
        "price_data": {
          "currency": "RUB",
          "price": 239,
          "vat": "VAT_20"
        },
        "quantity": 17,
        "scan_serial_numbers": [],
        "sku_id": "0Q-227044"
      },
      {
        "id": "ee068a34-70d0-449c-92e4-2d8324ee5400",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-240995"
      },
      {
        "id": "3a1fbbec-bf4a-46e1-b8f4-830fd98d35d4",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 24,
        "scan_serial_numbers": [],
        "sku_id": "0Q-228188"
      },
      {
        "id": "23a67a17-8882-4432-8b42-7d63cf7e17a4",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 1,
        "scan_serial_numbers": [],
        "sku_id": "0Q-210902"
      },
      {
        "id": "c2a3558d-7d32-45ac-bc55-1ef567483da5",
        "price_data": {
          "currency": "RUB",
          "price": 1,
          "vat": "VAT_20"
        },
        "quantity": 23,
        "scan_serial_numbers": [],
        "sku_id": "0Q-125242"
      }
    ],
    "merchant": {
      "cam_id": "71156",
      "contract_date": "2023-10-16",
      "contract_num": "КО-ОП- 16/10/2023-293",
      "enabled": true,
      "id": "75d776a1-1038-4837-b62f-8de5b76de458",
      "inn": "7709505477",
      "kpp": "774950001",
      "login": "e.generalova@taber.ru",
      "ogrn": "1037739861851",
      "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
      "title": "Табер Трейд \"Подружка\"",
      "trade_title": "Подружка"
    },
    "receipt_started_at": "2024-06-28T19:58:35.000000",
    "receipts": [
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:25:43.662712",
        "date": "2024-06-29T15:25:43.099648",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2271",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "27802b26-aa26-4436-bb69-a1ffbb617f09",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382120"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003083",
        "update_ts": "2024-06-30T10:46:10.148674",
        "uuid": "b2f0dc99-cd0b-434d-a690-b8ed82c9d6fe",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:01:01.439403",
        "date": "2024-06-29T17:01:00.819436",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2396",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "723479a5-3439-46b9-aed6-7bc8be638cce",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367665"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003245",
        "update_ts": "2024-06-30T10:04:35.672823",
        "uuid": "941595b4-6ca5-4b84-a25e-31faf02ea775",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:41:59.897956",
        "date": "2024-06-29T18:41:59.250173",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2507",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1a203a78-11fd-4510-9cb0-f1634f1f4d4a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380393"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003540",
        "update_ts": "2024-07-01T10:58:24.619225",
        "uuid": "018d70dd-4b6a-4ddc-81da-fe8752d8713c",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:42:22.040230",
        "date": "2024-06-29T18:42:21.401630",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2509",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c337d54c-9b2b-43dd-9f3d-d2a3bff94c51",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382487"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003214",
        "update_ts": "2024-06-30T10:04:35.722877",
        "uuid": "fb32a2c3-f9d7-4c4a-a2cb-d49c82dabcf9",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:47:43.132608",
        "date": "2024-06-29T18:47:42.523350",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2521",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ad0a7094-536e-41a6-acea-ece5f034a13b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382148"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003376",
        "update_ts": "2024-06-30T10:46:10.185642",
        "uuid": "54441f98-c8b9-4b42-a31f-452bc8e15708",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:49:04.227365",
        "date": "2024-06-29T18:49:03.567508",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2524",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d250ba37-c7bc-4a52-b61d-a47139385ee2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380457"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003536",
        "update_ts": "2024-07-01T10:58:24.621670",
        "uuid": "c719dbf1-537f-40cc-b9c1-2a18a5c2db34",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:50:24.048841",
        "date": "2024-06-29T18:50:23.389731",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2525",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8316127a-8bcb-4a67-be4c-7cebbd84d645",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382139"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003374",
        "update_ts": "2024-06-30T10:46:10.186436",
        "uuid": "d1bcc0d6-4c7b-4a68-9c19-da6a3c73c15b",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:54:48.621224",
        "date": "2024-06-29T18:54:47.801804",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2533",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "a018838e-dcaa-4e1d-bd45-3cf14f1db4cf",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137376092"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003533",
        "update_ts": "2024-07-01T10:58:24.623620",
        "uuid": "8cc4c472-3bdc-4d64-98e6-094a80ca4adc",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:57:24.611080",
        "date": "2024-06-29T18:57:23.932630",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2538",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c0d93f64-94f5-421c-b4c3-b625b684dec8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380445"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003370",
        "update_ts": "2024-06-30T10:46:10.188020",
        "uuid": "2d13ede9-7b7a-48d1-b296-686bddb73567",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:03:43.322900",
        "date": "2024-06-29T17:03:42.664983",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2402",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0c501dde-226e-4331-94a4-3162cc7eef16",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356542"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003237",
        "update_ts": "2024-06-30T10:04:35.675404",
        "uuid": "000c8a46-1f14-4b12-b65f-3299c81c80f7",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:50:08.071940",
        "date": "2024-06-29T17:50:07.493819",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2439",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3a04983a-b191-42fb-b17d-73c3158600eb",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380454"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003386",
        "update_ts": "2024-06-30T10:46:10.181671",
        "uuid": "ee01524e-c6fd-43b0-b7cd-9e95373bb096",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:51:17.617083",
        "date": "2024-06-29T17:51:16.968095",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2443",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d206796c-3d78-4604-9fa0-89054742fc66",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380477"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003385",
        "update_ts": "2024-06-30T10:46:10.182014",
        "uuid": "3989d75d-0ebe-4843-b352-b9e25b0efc79",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:52:24.476768",
        "date": "2024-06-29T17:52:23.766414",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2446",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "a0ddc3a6-9e06-4c42-a86a-4cf3f20a8bab",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380396"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003384",
        "update_ts": "2024-06-30T10:46:10.182498",
        "uuid": "8b7b3b2d-338c-46f7-a485-7d54ef5602ed",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:43:31.514630",
        "date": "2024-06-29T18:43:30.813363",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2510",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "84db72b0-74c5-4b79-b5ea-65d190d8a144",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137376090"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003539",
        "update_ts": "2024-07-01T10:58:24.619592",
        "uuid": "b4870d3b-6149-4072-a68e-0c3861139b93",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:43:34.044233",
        "date": "2024-06-29T18:43:33.348191",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2511",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4a4b9454-c385-4186-bb15-99ce46ecfae8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380446"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003379",
        "update_ts": "2024-06-30T10:46:10.184474",
        "uuid": "0e106e18-9bee-4528-830e-98376d6cedf7",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:28:18.845146",
        "date": "2024-06-29T15:28:18.338700",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2275",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ed385ae2-3ba1-4bef-92af-74ead1cccce6",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383060"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003081",
        "update_ts": "2024-06-30T10:46:10.149409",
        "uuid": "c1039709-00aa-4463-a6f4-1276c881393c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:35:52.843138",
        "date": "2024-06-29T15:35:52.334701",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2285",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1dd880bb-4b1e-49ad-abd0-2638144b0e87",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380486"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002869",
        "update_ts": "2024-06-30T10:04:35.624644",
        "uuid": "58b9f5c4-b636-4596-9cda-e217d653aa5a",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:02:44.641311",
        "date": "2024-06-29T17:02:44.073993",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2399",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1972a5a4-fb30-4212-8ad7-961dea5edd8c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356544"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003246",
        "update_ts": "2024-06-30T10:04:35.674229",
        "uuid": "745477d7-9d96-4a23-ae27-ca8f761cb96a",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:36:38.353635",
        "date": "2024-06-29T17:36:37.722746",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2417",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b0422a4b-3a47-45c1-9ff9-0225b4f5d5bd",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367659"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003393",
        "update_ts": "2024-06-30T10:46:10.178709",
        "uuid": "da92786f-253c-41e1-8c78-a4423fd281bf",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:45:24.053173",
        "date": "2024-06-29T17:45:23.367940",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2433",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3ba4079f-fe62-425e-9dc2-fdefa9a21234",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382143"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003175",
        "update_ts": "2024-06-30T10:04:35.690069",
        "uuid": "7772574e-de2b-40b5-9fec-5e493c37cf31",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:53:03.898482",
        "date": "2024-06-29T17:53:03.267981",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2447",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c7300341-6460-463a-8fc9-2f143c679563",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380492"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003186",
        "update_ts": "2024-06-30T10:04:35.699039",
        "uuid": "ba6f36f8-91e5-4623-9434-c87c8eeeac03",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:53:32.582903",
        "date": "2024-06-29T17:53:32.027345",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2448",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d9736a60-cba0-416f-8141-0b9643a7d903",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380493"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003383",
        "update_ts": "2024-06-30T10:46:10.182953",
        "uuid": "4cc6e44f-5ff3-46c3-ad14-a816d7a90065",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:07:39.616903",
        "date": "2024-06-29T14:07:39.156222",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2203",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e4045c8d-a297-41c5-a5be-04bda7d86cc1",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379735"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002819",
        "update_ts": "2024-06-30T10:46:10.135467",
        "uuid": "da5411c0-b9c2-4b0b-b604-b8ab1dd7bba3",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:30:11.561954",
        "date": "2024-06-29T15:30:11.009123",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2277",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "a96336d8-bc78-408a-a6d5-cdfbf673d501",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382457"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002873",
        "update_ts": "2024-06-30T10:04:35.620805",
        "uuid": "1c7429fa-6a2d-471e-a081-705d20475b99",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:32:33.995788",
        "date": "2024-06-29T15:32:33.490294",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2280",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b2e797ea-9b4b-4efe-8d0d-54b04bf98b51",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382118"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003079",
        "update_ts": "2024-06-30T10:46:10.150150",
        "uuid": "4c622c6e-46b7-4ad9-812d-84d1c960a3ce",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:07:02.478516",
        "date": "2024-06-29T17:07:01.907911",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2409",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "65d157b1-ce16-4334-be9b-cb5e99e89f43",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356535"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003221",
        "update_ts": "2024-06-30T10:04:35.680026",
        "uuid": "6a081fb4-aea1-44e5-900d-c04d786dc2fb",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:35:37.999442",
        "date": "2024-06-29T17:35:37.255339",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2414",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e323dae0-8f47-4772-8593-c4a1348829e8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367678"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003219",
        "update_ts": "2024-06-30T10:04:35.682280",
        "uuid": "c83ec576-d2ec-4b6f-8824-3f9a30014dcd",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:38:06.308983",
        "date": "2024-06-29T17:38:05.707695",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2421",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ec89b45d-0234-4b77-9df9-cebd31ffe8d6",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367660"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003223",
        "update_ts": "2024-06-30T10:04:35.685864",
        "uuid": "2049f4dd-c87f-4308-a6d6-7433a1397bc1",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:43:34.095419",
        "date": "2024-06-29T17:43:33.067113",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2428",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "634c9955-b71f-4a80-94c8-7c5bdb64ca89",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383077"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003177",
        "update_ts": "2024-06-30T10:04:35.687908",
        "uuid": "f31dade2-0ec1-4e34-9af2-08bed667b7e0",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:44:28.128446",
        "date": "2024-06-29T18:44:27.463852",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2513",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0e893032-aaac-4385-a6f5-7ce5444ed84e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367672"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003213",
        "update_ts": "2024-06-30T10:04:35.723320",
        "uuid": "3da1330e-d79a-4b96-9faf-126966ec7dca",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:45:21.230606",
        "date": "2024-06-29T18:45:20.602565",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2515",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "eecc485f-5335-4409-bf0b-ea5ba48c71ec",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352674"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003538",
        "update_ts": "2024-07-01T10:58:24.620174",
        "uuid": "0bf92921-fd3e-4eb9-a55f-61475a45db38",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:09:11.843893",
        "date": "2024-06-29T14:09:11.323224",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2205",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "47fee660-f2c5-4ea2-8f93-31c69d93397a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379728"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002816",
        "update_ts": "2024-06-30T10:46:10.135852",
        "uuid": "b798f940-56d7-401c-9ed4-d17c3d87105a",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:31:42.761425",
        "date": "2024-06-29T15:31:42.241881",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2279",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "884d8ab4-ab57-4109-bb4a-95735ed7db3a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382121"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002872",
        "update_ts": "2024-06-30T10:04:35.621746",
        "uuid": "a88f7e71-d592-4523-ae18-7124ab7232ec",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:34:28.411148",
        "date": "2024-06-29T15:34:27.569548",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2283",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "28b8861e-a4e3-43ac-b1f1-e2556ce6667b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382461"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002871",
        "update_ts": "2024-06-30T10:04:35.622952",
        "uuid": "0b7c8863-ac2f-4e7a-bcd5-9d848c5ef8c5",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:02:56.076207",
        "date": "2024-06-29T17:02:55.513608",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2401",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5555e7cc-e820-4a62-81ab-e29abf9c377f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356541"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003399",
        "update_ts": "2024-06-30T10:46:10.175846",
        "uuid": "b4d40de6-eacc-4644-92e0-20308f61b0be",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:04:04.286093",
        "date": "2024-06-29T17:04:03.738909",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2403",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "054d80bf-aaff-41bc-bb78-938650f2b9ee",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367650"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003398",
        "update_ts": "2024-06-30T10:46:10.176519",
        "uuid": "e4ee77ae-7c20-40e6-add7-f7e296f29084",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:04:26.054861",
        "date": "2024-06-29T17:04:25.539360",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2404",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6e1752d8-b7e7-42da-95ec-03b483814ff0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367654"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003236",
        "update_ts": "2024-06-30T10:04:35.676661",
        "uuid": "8ef60e25-92c1-418a-8297-0cc9412e8763",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:45:36.971889",
        "date": "2024-06-29T18:45:36.302196",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2517",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5b109236-a3ed-43e6-a07e-6e6e672bb552",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367671"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003215",
        "update_ts": "2024-06-30T10:04:35.723718",
        "uuid": "9aae6ade-3465-477d-bce2-5c001f49d658",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T20:14:14.640496",
        "date": "2024-06-29T20:14:14.023641",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2655",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5b3527bf-fc64-4b38-9e7b-5f006528e042",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352684"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003313",
        "update_ts": "2024-06-30T10:46:10.205491",
        "uuid": "5680a095-a9fb-463d-9a9b-28259ef088b3",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:36:47.921581",
        "date": "2024-06-29T15:36:47.246719",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2286",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6c54dd43-e528-457b-9a44-7fe03feb4da0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380488"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002870",
        "update_ts": "2024-06-30T10:04:35.625801",
        "uuid": "fc70f698-348d-4cd1-b33d-564e2315ddf8",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:05:00.924847",
        "date": "2024-06-29T17:05:00.410784",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2405",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c928f099-4edb-42dd-8ca0-a724dfffcb4d",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356537"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003397",
        "update_ts": "2024-06-30T10:46:10.176906",
        "uuid": "e6233158-18a2-447b-b039-c682a54c51f7",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:52:40.249105",
        "date": "2024-06-29T18:52:39.562799",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2529",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4d20ade1-c3af-416a-a262-e24ed727f445",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380469"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003534",
        "update_ts": "2024-07-01T10:58:24.623029",
        "uuid": "460ece90-32ed-480c-abee-52fcae29da6d",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:53:31.372972",
        "date": "2024-06-29T18:53:30.770125",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2531",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7f0aebbd-b5d8-4ab5-a9d1-6aaf0039dc8c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379132"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003373",
        "update_ts": "2024-06-30T10:46:10.186813",
        "uuid": "ce9a1907-c4e2-44f5-bc76-ff414a801aee",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T20:13:08.759717",
        "date": "2024-06-29T20:13:08.093319",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2654",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "12d486b7-2ea2-48d6-8d6c-1b5b1a3a0adc",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356310"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003314",
        "update_ts": "2024-06-30T10:46:10.205025",
        "uuid": "b35db346-18c4-4120-b0d5-9653b9148ad4",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T12:58:56.757279",
        "date": "2024-06-29T12:58:56.281152",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2099",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "a6c43659-5a46-488b-8bf5-d3d5b2a88347",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379130"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002836",
        "update_ts": "2024-06-30T10:46:10.107899",
        "uuid": "b17fdcd6-feda-4065-8fcc-7b72825f9c05",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:04:20.218659",
        "date": "2024-06-29T13:04:19.754435",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2102",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d0fafc5d-2f6f-4c2a-8838-9c5044c4b116",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373867"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002837",
        "update_ts": "2024-06-30T10:46:10.112555",
        "uuid": "80ec772b-e05b-4894-9368-63cbc6cb88b1",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:07:30.812532",
        "date": "2024-06-29T13:07:30.285491",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2105",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "dbf4ecef-f5b5-441b-a846-3bcd4ea7978a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379770"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002909",
        "update_ts": "2024-06-30T10:46:10.116144",
        "uuid": "8a2d29b4-3f88-4250-bbee-12ffb8ed0ed4",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:10:12.981057",
        "date": "2024-06-29T13:10:12.541474",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2108",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f59c7a61-7fcc-4c89-ba51-f4eaf30069ba",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379718"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002910",
        "update_ts": "2024-06-30T10:46:10.117214",
        "uuid": "6e330567-9cb3-48c3-85c9-58481f7fc38e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:42:28.906866",
        "date": "2024-06-29T15:42:27.301231",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2296",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "505e4e6b-9098-4c18-a56a-4ed7060ee95f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356543"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002864",
        "update_ts": "2024-06-30T10:04:35.631422",
        "uuid": "d53917ac-78d2-4139-bb4a-b76eb3e944db",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:05:12.436893",
        "date": "2024-06-29T17:05:11.879241",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2406",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f39f378f-42d7-4158-aea9-eb6b4ad31606",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367661"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003235",
        "update_ts": "2024-06-30T10:04:35.678187",
        "uuid": "0353dfd8-369f-4d57-84b7-4dcd97306405",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:07:25.397906",
        "date": "2024-06-29T17:07:24.150373",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2410",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "467fb00d-77db-4de4-8296-9a1f4187f5b3",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367653"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003395",
        "update_ts": "2024-06-30T10:46:10.177833",
        "uuid": "85c32643-673a-43fa-921e-262ed7066d02",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:54:57.000003",
        "date": "2024-06-29T18:54:56.363151",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2534",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f8666aa5-9a2d-4fb4-b1a7-aa9b2a7ef829",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380471"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003372",
        "update_ts": "2024-06-30T10:46:10.187172",
        "uuid": "f66822fe-3d83-4389-9f8b-7a4bf16477aa",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T20:15:44.587770",
        "date": "2024-06-29T20:15:43.881416",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2657",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7fbdfd44-96e6-4331-a6ff-5ee6a4eb12e6",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381478"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003312",
        "update_ts": "2024-06-30T10:46:10.205948",
        "uuid": "a08dcdf0-637b-4246-9bc6-74f08432b4e6",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:33:35.539142",
        "date": "2024-06-29T14:33:34.953711",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2226",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "397d7da8-638b-49c5-9cd5-93e87efe0aac",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383064"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002817",
        "update_ts": "2024-06-30T10:46:10.137539",
        "uuid": "26cde3a8-73d5-42b3-890c-aa6eef353ee6",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:43:31.934623",
        "date": "2024-06-29T15:43:30.467577",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2297",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7ff1b812-612b-4f96-9586-5cf546a3d2d1",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352688"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002863",
        "update_ts": "2024-06-30T10:04:35.632850",
        "uuid": "5b4ee7ba-ebd9-47c9-9a98-7e442b6b781b",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:05:58.100128",
        "date": "2024-06-29T17:05:57.569612",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2407",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b3df2d5f-8cd2-42a4-bc77-a967b3d91969",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367658"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003396",
        "update_ts": "2024-06-30T10:46:10.177370",
        "uuid": "45c749a8-d9e1-4d87-9804-4146c1c5fb2c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:34:54.578424",
        "date": "2024-06-29T17:34:53.726670",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2411",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "931c61f7-cf36-40d2-887b-c68b3a71d83b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367651"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003220",
        "update_ts": "2024-06-30T10:04:35.681250",
        "uuid": "ba80527f-5d67-4ede-aa3b-db9479f47aea",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:39:22.689038",
        "date": "2024-06-29T17:39:22.089391",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2425",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "56978514-e239-40ed-8206-fcda5285a675",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367676"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003391",
        "update_ts": "2024-06-30T10:46:10.179554",
        "uuid": "775ff606-0607-4a32-a707-988464922b52",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:42:45.124958",
        "date": "2024-06-29T17:42:44.523556",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2426",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7737263c-d7e4-41a7-acfa-7ce4148b502b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382150"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003176",
        "update_ts": "2024-06-30T10:04:35.687012",
        "uuid": "ed30e96a-4f31-4dff-a7de-ee8dbc24ebdf",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:46:14.787480",
        "date": "2024-06-29T17:46:14.067742",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2434",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "57ea3ad1-7167-4ea1-9229-85bb5df7098c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367657"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003179",
        "update_ts": "2024-06-30T10:04:35.691473",
        "uuid": "0acd8f97-7259-44ce-beeb-7ec121f7ebf6",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:49:19.737443",
        "date": "2024-06-29T17:49:19.028301",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2438",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4455d20e-a2ce-47ce-bd09-e2d928e32bb3",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381506"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003181",
        "update_ts": "2024-06-30T10:04:35.693186",
        "uuid": "1cd27aef-83ff-460d-90d8-bb050f513d6c",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:51:35.647763",
        "date": "2024-06-29T17:51:35.069173",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2444",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "03b3854e-4da0-4aab-94b2-b5875e5b0cd4",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380453"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003184",
        "update_ts": "2024-06-30T10:04:35.696809",
        "uuid": "1333f957-3f7b-46d3-a114-5e8d1b886eb9",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:06:13.186966",
        "date": "2024-06-29T19:06:12.509038",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2553",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f143cfa6-db57-4b00-b29a-af587ab2de0b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383055"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003224",
        "update_ts": "2024-06-30T10:04:35.725920",
        "uuid": "63c80002-acc7-456e-8518-d77e7c4008d2",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:11:50.316829",
        "date": "2024-06-29T19:11:49.565137",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2565",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "fa9328da-a117-45cd-8444-ddebcf96f95a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356545"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003230",
        "update_ts": "2024-06-30T10:04:35.727946",
        "uuid": "fe6859d4-3e11-49d8-9ad4-b3f2f3d0761b",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T20:16:14.848523",
        "date": "2024-06-29T20:16:14.179420",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2658",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8b463f7a-d0a1-4aee-8f0d-e8e59c936c32",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373847"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003311",
        "update_ts": "2024-06-30T10:46:10.206335",
        "uuid": "5145cec3-6b26-4d85-9e6f-81a8b047af3c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:32:01.773421",
        "date": "2024-06-29T14:32:01.190996",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2225",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3db937fe-d947-4a77-81ea-2631ee1eb142",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381471"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002818",
        "update_ts": "2024-06-30T10:46:10.136497",
        "uuid": "31c83373-2ee6-48f1-bb80-ca6c95856e18",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:44:11.460227",
        "date": "2024-06-29T15:44:10.838279",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2298",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4e85c226-30ba-41bb-8190-d91ba5f0c5f3",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383071"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003073",
        "update_ts": "2024-06-30T10:46:10.152431",
        "uuid": "5926947a-a8c8-48d9-a5bc-b974268cd4dc",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:35:36.581951",
        "date": "2024-06-29T17:35:35.374661",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2413",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "36a7eebf-46e2-4818-8c74-73d2a96f1803",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367663"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003394",
        "update_ts": "2024-06-30T10:46:10.178218",
        "uuid": "44de4729-6992-4142-a003-c14468bae814",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:36:24.967729",
        "date": "2024-06-29T17:36:24.400258",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2416",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9b17061f-6aa3-4c8b-a6ab-d168437a3913",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367662"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003222",
        "update_ts": "2024-06-30T10:04:35.683356",
        "uuid": "e3925c31-a00b-4a0d-a5d7-cd3ad22330ca",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:37:35.147939",
        "date": "2024-06-29T17:37:34.559064",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2420",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f15391c1-f5d2-411e-a0e1-297eb84c2143",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367649"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003392",
        "update_ts": "2024-06-30T10:46:10.179083",
        "uuid": "c77b8bf7-7376-4f0c-ba58-e45e142742b1",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:43:03.431757",
        "date": "2024-06-29T17:43:02.856942",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2427",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "31f6c4e2-2749-4c3b-989b-8ba679d371f0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356525"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003390",
        "update_ts": "2024-06-30T10:46:10.180212",
        "uuid": "23c56a65-ea2c-43c4-9d29-b627c4a1eda4",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:46:23.900809",
        "date": "2024-06-29T17:46:23.232640",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2435",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c3ed9cb4-10fe-4b72-a69a-771ae4a33a3b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373842"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003387",
        "update_ts": "2024-06-30T10:46:10.181310",
        "uuid": "0b729cfd-a3e6-4046-b66b-ff65764cb507",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:50:29.158106",
        "date": "2024-06-29T17:50:28.611118",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2440",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f5739632-ae0b-4820-8819-c5e9f2f685cf",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356532"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003183",
        "update_ts": "2024-06-30T10:04:35.694495",
        "uuid": "b95eef24-47a6-45fd-b776-1b79e337cce6",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:06:13.041167",
        "date": "2024-06-29T19:06:12.339926",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2552",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3c85a3e0-fd1a-4070-8f99-8c3e65abf351",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352678"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003367",
        "update_ts": "2024-06-30T10:46:10.189080",
        "uuid": "1970f74e-af03-4f38-abef-9ea0f55c0c97",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:16:56.806181",
        "date": "2024-06-29T19:16:55.988637",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2573",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "49c3de72-c95f-46bf-811f-f41feb10e3c4",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356526"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003365",
        "update_ts": "2024-06-30T10:46:10.191888",
        "uuid": "147926da-dcb4-4f2b-b354-3adcb1612da5",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:18:03.082884",
        "date": "2024-06-29T19:18:02.242405",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2576",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "98dd4c8e-2142-4ced-bfe2-5fd5b942f030",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381518"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003232",
        "update_ts": "2024-06-30T10:04:35.728985",
        "uuid": "d940400e-eeb7-416f-b47e-a6c44c8b2818",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:20:08.845696",
        "date": "2024-06-29T19:20:08.238811",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2580",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8fe72656-303b-4387-906d-36a396e8c3a0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383084"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003357",
        "update_ts": "2024-06-30T10:46:10.193156",
        "uuid": "727e12eb-7136-4ddb-abfb-00adc3b52e93",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:23:01.034071",
        "date": "2024-06-29T19:23:00.382593",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2590",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "82ca5b3e-cab1-4f8b-9c9f-989529707a2b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381508"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003348",
        "update_ts": "2024-06-30T10:46:10.194779",
        "uuid": "13422639-4a51-4778-89f8-a6278cbca6ff",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:23:41.571282",
        "date": "2024-06-29T19:23:40.838140",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2591",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0ae6872d-0087-4bdb-a45a-5c253c0dcc55",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352663"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003174",
        "update_ts": "2024-06-30T10:04:35.730821",
        "uuid": "784e8fc9-a9e9-435a-9a3a-6d28b7d01c8c",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T20:16:46.952503",
        "date": "2024-06-29T20:16:46.301443",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2659",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6939d4e0-b0e5-4667-8160-15eb701cc2ec",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381475"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003320",
        "update_ts": "2024-06-30T10:46:10.206703",
        "uuid": "a4df5fa5-5766-4366-b074-e681b2818c24",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:28:36.503232",
        "date": "2024-06-29T13:28:35.901155",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2138",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7ba7b19d-ccc5-44a0-8ae0-238e15ef47d2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379750"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002938",
        "update_ts": "2024-06-30T10:04:35.585673",
        "uuid": "643e94f0-6699-4ae3-89f8-ed738f7e90bb",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:29:33.066566",
        "date": "2024-06-29T13:29:32.614284",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2139",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1581aa20-427c-445e-b97e-8acb1af8ff1f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367666"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002939",
        "update_ts": "2024-06-30T10:04:35.587199",
        "uuid": "7e3c5e84-424e-414e-862c-59c95cea74b6",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:45:19.175127",
        "date": "2024-06-29T15:45:18.671582",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2299",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "2ef48d7e-a214-4d9a-b60f-485e758e30e7",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352659"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002862",
        "update_ts": "2024-06-30T10:04:35.634602",
        "uuid": "20832e31-853e-4b36-9e32-8f6e03d2bbe6",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:37:12.713496",
        "date": "2024-06-29T17:37:12.123634",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2418",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "dc1aff24-b67c-4ca6-ab83-e187e8f8db97",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367652"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003225",
        "update_ts": "2024-06-30T10:04:35.684826",
        "uuid": "c02fd69f-48ed-461e-b1e8-72661ef45cd9",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:47:20.117888",
        "date": "2024-06-29T17:47:19.508972",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2436",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e01874ca-01f8-4dd3-bb82-79baee816896",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380452"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003180",
        "update_ts": "2024-06-30T10:04:35.692376",
        "uuid": "e7c078de-e7c0-4251-b999-cead1700070f",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:52:16.332617",
        "date": "2024-06-29T17:52:15.731368",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2445",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f910d0b6-aa87-4708-af1a-70fabae05060",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380456"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003185",
        "update_ts": "2024-06-30T10:04:35.697892",
        "uuid": "2ea1d064-ca43-4569-a696-75b170c5f958",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:07:08.382753",
        "date": "2024-06-29T19:07:07.598531",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2554",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0468985e-3917-4000-a168-4ee6043ec4dd",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383054"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003228",
        "update_ts": "2024-06-30T10:04:35.726352",
        "uuid": "f0b30884-3ff6-4980-bce3-0061aaef0ae8",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:08:11.809948",
        "date": "2024-06-29T19:08:11.171310",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2558",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "2c318568-565d-4fe0-afb6-7edfcb3395cb",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356546"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003366",
        "update_ts": "2024-06-30T10:46:10.189457",
        "uuid": "56cfc395-9bbf-4a06-a835-75c9233e5e5d",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:09:12.852961",
        "date": "2024-06-29T19:09:12.133170",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2561",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d690e0fe-64a2-4680-b282-9c9d92021e7d",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380496"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003358",
        "update_ts": "2024-06-30T10:46:10.189828",
        "uuid": "d68e32a6-a7df-489a-8b43-fce57b47fd64",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:10:31.365217",
        "date": "2024-06-29T19:10:30.701097",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2563",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e05d3648-add6-47df-b1d4-30ef621948a2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383067"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003359",
        "update_ts": "2024-06-30T10:46:10.190211",
        "uuid": "76266b65-83e8-4713-81a7-c60e7066389b",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:20:50.549906",
        "date": "2024-06-29T19:20:49.930715",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2582",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "94d8e11a-4978-49fe-bc38-dd7a13bf6c2c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383043"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003356",
        "update_ts": "2024-06-30T10:46:10.193540",
        "uuid": "620c4c6a-6632-4ac6-b4c7-3c4c5cd28949",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:21:16.358357",
        "date": "2024-06-29T19:21:15.659765",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2585",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "05fcc7ed-b6ae-46e2-a248-906611e3292c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381480"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003522",
        "update_ts": "2024-07-01T10:58:24.629979",
        "uuid": "a538ece8-6655-4d35-b7ec-86b963e1084b",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:27:08.768115",
        "date": "2024-06-29T19:27:08.140783",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2601",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e50836f2-062f-47a9-89ad-f771f7435a79",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380479"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003171",
        "update_ts": "2024-06-30T10:04:35.733112",
        "uuid": "ff9afbb3-5a29-4182-95dc-fba2a994abce",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:02:31.722562",
        "date": "2024-06-29T13:02:31.209698",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2101",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "2691178e-62e8-40d1-86e8-53578171845c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379764"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002838",
        "update_ts": "2024-06-30T10:46:10.111062",
        "uuid": "6d913b38-eae3-46bf-8e71-24331b178fd8",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:18:27.973803",
        "date": "2024-06-29T13:18:27.500417",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2120",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8e3a93c9-b8ae-4f9e-b2aa-c5fb1fc155d4",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352666"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002933",
        "update_ts": "2024-06-30T10:04:35.575379",
        "uuid": "4a266490-8d01-47c0-8ae6-b6d5c747ddae",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:35:03.806924",
        "date": "2024-06-29T14:35:03.108150",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2229",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c3e1cdbf-175a-44c6-9a5d-300b0e908ab2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383069"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003102",
        "update_ts": "2024-06-30T10:46:10.138166",
        "uuid": "ee5e3aca-69fc-42a4-891c-eaa9b14a0a0e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:46:25.370514",
        "date": "2024-06-29T15:46:24.538924",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2301",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "090d51b5-0f6f-44d6-81de-76704b80627d",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379759"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003072",
        "update_ts": "2024-06-30T10:46:10.152933",
        "uuid": "790c684e-0ce8-4f61-8ec8-a08791a7f49e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:49:36.674614",
        "date": "2024-06-29T15:49:36.109876",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2305",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "dde4b963-ab5e-4b1b-a65b-eba6ac89d922",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367656"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003070",
        "update_ts": "2024-06-30T10:46:10.154007",
        "uuid": "04301baf-d416-42ce-9d9a-7ac5aeff92b6",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:53:33.823588",
        "date": "2024-06-29T15:53:33.171146",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2309",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4fa7e966-2dda-4b5b-b720-ca0dc65d07bc",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380466"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002859",
        "update_ts": "2024-06-30T10:04:35.640937",
        "uuid": "4b28dbef-ea22-47ab-a3aa-9b51e4e2960a",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:59:01.256842",
        "date": "2024-06-29T15:59:00.718395",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2314",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e6a22598-4b39-4db0-9f79-28e094f79c96",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356312"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003066",
        "update_ts": "2024-06-30T10:46:10.155918",
        "uuid": "bc3614c4-a760-415a-9e99-06ba2906c133",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:00:06.869650",
        "date": "2024-06-29T16:00:06.311904",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2316",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d16549f5-85a5-4282-8417-e261c6ffcad9",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352686"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003065",
        "update_ts": "2024-06-30T10:46:10.156487",
        "uuid": "3bd79111-84a3-47c3-b5ca-5644502cc10f",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:44:03.161029",
        "date": "2024-06-29T17:44:02.027845",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2429",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1b6d7af8-7f1a-4048-a615-3bf0f8bbfda5",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383046"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003389",
        "update_ts": "2024-06-30T10:46:10.180610",
        "uuid": "d05888e5-6477-4ef9-8cb5-9c149c4a9967",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:07:33.541066",
        "date": "2024-06-29T19:07:32.853926",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2555",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6cd1a522-0402-4be4-88b2-9f17d63a6134",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382481"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003530",
        "update_ts": "2024-07-01T10:58:24.625195",
        "uuid": "1f6dd812-b242-4147-997f-d8a1d8ca9750",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:08:47.716243",
        "date": "2024-06-29T19:08:47.113500",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2559",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9c7e103e-30c7-4e2f-9cd8-702a3c0259e3",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383075"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003226",
        "update_ts": "2024-06-30T10:04:35.727114",
        "uuid": "8be69a24-a8ed-415f-8719-364b63dbe4e9",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:09:00.983018",
        "date": "2024-06-29T19:09:00.314963",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2560",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "019fa733-23ef-4a51-8532-ff9867de3719",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380397"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003529",
        "update_ts": "2024-07-01T10:58:24.626121",
        "uuid": "ef98ede9-366f-4419-b9c5-9728b04c7749",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:21:08.025441",
        "date": "2024-06-29T19:21:07.384167",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2583",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "629187cf-b7fc-4e40-8f17-e2b4cd2bbf1e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383080"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003233",
        "update_ts": "2024-06-30T10:04:35.729543",
        "uuid": "d8f46b5d-2b03-4d76-b733-1c31a4cd9ad8",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:21:33.509160",
        "date": "2024-06-29T19:21:32.830130",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2586",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e3860975-2c0b-4dbd-a2ac-ea25f701efc2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381511"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003355",
        "update_ts": "2024-06-30T10:46:10.193903",
        "uuid": "581cd352-cbcc-4fdd-95ea-3f6abb57638b",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:00:44.142357",
        "date": "2024-06-29T13:00:43.545401",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2100",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "98e9d73c-9d2f-476d-be58-31c75b2853ce",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373856"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002833",
        "update_ts": "2024-06-30T10:46:10.109473",
        "uuid": "c300b33f-81c6-41f2-b2e2-f88c05140a29",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:06:08.627454",
        "date": "2024-06-29T13:06:08.116698",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2104",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "2a832904-6b44-4959-8d1c-74532f47fa8e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379761"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002908",
        "update_ts": "2024-06-30T10:46:10.114189",
        "uuid": "c6592e5e-3972-4e96-984a-5f44c36abc11",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:37:47.136384",
        "date": "2024-06-29T14:37:46.645005",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2235",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "af083e64-f4ce-463f-9281-b4baf3d25488",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383062"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003099",
        "update_ts": "2024-06-30T10:46:10.139468",
        "uuid": "42e8b2fa-1492-4022-925d-98b4ee491568",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:46:31.550737",
        "date": "2024-06-29T15:46:30.754277",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2302",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "99836437-bc38-47c5-be59-37e0f22402b5",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137364899"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002861",
        "update_ts": "2024-06-30T10:04:35.636591",
        "uuid": "d2fa15f7-1621-49af-b829-ce3d37013efa",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:47:45.455514",
        "date": "2024-06-29T15:47:44.715921",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2303",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5a9e92d8-270e-45b7-b7ee-d455c06987e7",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379751"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003071",
        "update_ts": "2024-06-30T10:46:10.153401",
        "uuid": "c49a32f7-1ffa-4fb7-b710-259f2801ab70",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:52:10.055382",
        "date": "2024-06-29T15:52:09.448817",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2308",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "be870d9b-35f8-4396-abdf-8055e84ddcf0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379696"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002858",
        "update_ts": "2024-06-30T10:04:35.639629",
        "uuid": "41c53843-1d66-4acc-9476-d1246b449b47",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:54:45.291072",
        "date": "2024-06-29T15:54:44.747976",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2311",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "69f3764f-702d-4a3f-a77b-573447db16ba",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380463"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002857",
        "update_ts": "2024-06-30T10:04:35.642173",
        "uuid": "6228c39f-d1dd-45f1-b6e3-a9cb845fc930",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:11:06.991016",
        "date": "2024-06-29T16:11:06.348413",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2330",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "a2ab44ac-74cb-46e5-a43b-c5b180f188f7",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356317"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003060",
        "update_ts": "2024-06-30T10:46:10.159566",
        "uuid": "06eda944-eb64-427f-8531-6e57aa28560f",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:12:26.961668",
        "date": "2024-06-29T16:12:26.477854",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2331",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0f3abfdb-c8e7-4c41-805a-2bc2b7777f30",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356533"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003257",
        "update_ts": "2024-06-30T10:04:35.647760",
        "uuid": "4b117d56-89a2-489b-a393-6ef969253251",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:08:06.642309",
        "date": "2024-06-29T19:08:05.644688",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2557",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "02af230e-2b4a-42fe-aafe-4f0e7f41928c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380458"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003227",
        "update_ts": "2024-06-30T10:04:35.726753",
        "uuid": "9c8cefa1-1966-4bf0-8843-72d07e182a30",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:09:50.986794",
        "date": "2024-06-29T19:09:50.351175",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2562",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3fcd84b6-a9c0-4c17-a124-87c4ed226a25",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383079"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003229",
        "update_ts": "2024-06-30T10:04:35.727488",
        "uuid": "a2794790-0e49-4e8f-bddf-189882e4717c",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:14:55.836625",
        "date": "2024-06-29T13:14:55.393909",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2115",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3cfa3daf-c01b-4e9f-831e-2dd844489cc8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379768"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002914",
        "update_ts": "2024-06-30T10:46:10.118176",
        "uuid": "2b3f806d-d840-4342-b395-b8fef879f13a",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:16:41.416919",
        "date": "2024-06-29T13:16:40.960766",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2117",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e97e159b-27f5-41ca-8a10-efa90eeb75b0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373862"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002911",
        "update_ts": "2024-06-30T10:46:10.119294",
        "uuid": "84445ebf-590f-47e2-a7da-e7fddfb90415",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:19:13.555145",
        "date": "2024-06-29T13:19:13.065383",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2122",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "73cca4eb-7e77-433c-af48-a294bb2a08ed",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373839"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002912",
        "update_ts": "2024-06-30T10:46:10.120089",
        "uuid": "d199f5c1-7d48-4c8d-8961-376e3eab908e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:19:29.903871",
        "date": "2024-06-29T13:19:29.388841",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2123",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c42fa987-c36e-437e-bbae-ba93054ad105",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379755"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002934",
        "update_ts": "2024-06-30T10:04:35.579498",
        "uuid": "e2620e76-0e80-4a04-b6c0-8a0b68cf17f8",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:36:31.920790",
        "date": "2024-06-29T14:36:31.382219",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2232",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ed1a490c-a7c0-415f-ac24-4220970f072a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356527"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003101",
        "update_ts": "2024-06-30T10:46:10.138828",
        "uuid": "ec11265f-d474-4f98-add9-d126e37c6263",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:50:40.242360",
        "date": "2024-06-29T15:50:39.676614",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2306",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "fdfde20e-5a9b-4f1e-bf65-4f470a8f62b8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380494"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002860",
        "update_ts": "2024-06-30T10:04:35.638248",
        "uuid": "4a69906e-3da0-4ac9-b98c-5ad6d1714ee5",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:01:36.602442",
        "date": "2024-06-29T16:01:36.011476",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2319",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "59ac4806-b6dc-4fc0-b199-43e804881e16",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352685"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003064",
        "update_ts": "2024-06-30T10:46:10.157134",
        "uuid": "529e3b43-6f43-443a-8e30-aae0ada7a617",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:03:11.845856",
        "date": "2024-06-29T16:03:11.320802",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2320",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7eadbc20-d8fb-4deb-beee-e717e901991b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381514"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003063",
        "update_ts": "2024-06-30T10:46:10.157981",
        "uuid": "274144bc-d036-4e03-b5f4-5babb2b0df81",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:03:33.125892",
        "date": "2024-06-29T16:03:32.596606",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2321",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ee9f69ca-2305-4332-8c1a-626400ca8970",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356534"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003259",
        "update_ts": "2024-06-30T10:04:35.645432",
        "uuid": "fc327481-7033-40be-9b8e-d6ed03bb1172",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:13:27.706663",
        "date": "2024-06-29T16:13:27.136250",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2333",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "18fbd8f7-60eb-4c1f-a85c-ab6465eda54f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381516"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003256",
        "update_ts": "2024-06-30T10:04:35.648826",
        "uuid": "c10c5d74-9514-4cb4-9c54-49d77c53572b",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:44:36.535256",
        "date": "2024-06-29T17:44:34.585824",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2431",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3e9fa21b-b811-44ea-ac14-1fde7530508c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379765"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003178",
        "update_ts": "2024-06-30T10:04:35.688985",
        "uuid": "2178d197-e1c5-4b3c-8eb1-082b6ce9e0b8",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:10:50.924019",
        "date": "2024-06-29T19:10:50.257446",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2564",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "326ed5b8-44f4-4faf-bb19-cee401bf8e82",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383081"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003528",
        "update_ts": "2024-07-01T10:58:24.626991",
        "uuid": "b33c8588-b4a0-44a1-82e6-c40cd735c11d",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:16:58.747771",
        "date": "2024-06-29T19:16:57.896270",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2574",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c1821727-60c0-4326-bb3c-0ce2e22afc9e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356531"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003231",
        "update_ts": "2024-06-30T10:04:35.728414",
        "uuid": "097b3db2-1236-4ee8-90a6-41ba1c5539f4",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:18:06.077272",
        "date": "2024-06-29T19:18:05.381038",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2577",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e353fd46-31f0-496d-8587-82a519399023",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383059"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003524",
        "update_ts": "2024-07-01T10:58:24.629035",
        "uuid": "897ad637-a953-4785-8a2b-8177ece102c2",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:19:34.343787",
        "date": "2024-06-29T19:19:33.717780",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2579",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "585ed5f1-2263-4b9a-b110-9007ac8a3383",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381485"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003523",
        "update_ts": "2024-07-01T10:58:24.629414",
        "uuid": "09a07013-a105-46e6-ae45-2503cc5a7382",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:22:39.397393",
        "date": "2024-06-29T19:22:38.741038",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2588",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ff6d9e14-9103-475b-a399-5dab8bd0b34a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383066"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003234",
        "update_ts": "2024-06-30T10:04:35.730014",
        "uuid": "1d47458f-b342-419a-94b6-e858109712e0",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:26:07.990701",
        "date": "2024-06-29T19:26:07.355833",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2598",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8215a3d0-7110-492c-b5f3-c8bf4855d518",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381479"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003172",
        "update_ts": "2024-06-30T10:04:35.732465",
        "uuid": "4ad4125a-f03f-4068-ab20-2340a016d09c",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:51:16.078479",
        "date": "2024-06-29T15:51:15.533854",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2307",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "31c8ba4c-e94b-4832-a2f8-646e94c552c4",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380465"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003069",
        "update_ts": "2024-06-30T10:46:10.154582",
        "uuid": "615920f8-6e55-4083-b40c-15e06e2a3cb7",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:57:44.420038",
        "date": "2024-06-29T15:57:43.862505",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2313",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "dc2608e0-682d-4f22-adff-5b7228c828e3",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380487"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003067",
        "update_ts": "2024-06-30T10:46:10.155503",
        "uuid": "85f067a4-c36d-43ee-a16b-863810252a0e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:12:55.844596",
        "date": "2024-06-29T16:12:55.307782",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2332",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9591cc11-610e-4f7f-948c-bcf2b26bc3d0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356315"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003059",
        "update_ts": "2024-06-30T10:46:10.159989",
        "uuid": "faa63c92-1c86-4748-996c-809ec629799e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:14:43.124306",
        "date": "2024-06-29T16:14:42.559224",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2336",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "a358300b-53d0-4c77-874e-a402a1b54944",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356316"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003058",
        "update_ts": "2024-06-30T10:46:10.160389",
        "uuid": "ffda52f4-439b-4eae-9721-e1804138eb4f",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:45:08.709928",
        "date": "2024-06-29T17:45:07.877701",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2432",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "bfd32aef-0a35-4b83-96fd-92ca05fcf205",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382144"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003388",
        "update_ts": "2024-06-30T10:46:10.180981",
        "uuid": "25e38d82-fa91-414f-9ed1-649ad32d4e0a",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:12:26.159954",
        "date": "2024-06-29T19:12:25.247391",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2566",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "11048da0-2c6e-4f03-910b-406800386ca5",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352677"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003527",
        "update_ts": "2024-07-01T10:58:24.627368",
        "uuid": "2ec25537-db71-405f-a695-b55a960c0be3",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:20:37.258656",
        "date": "2024-06-29T13:20:36.682339",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2125",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "72d39327-2c75-40f7-b880-1fd4acc69378",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382124"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002913",
        "update_ts": "2024-06-30T10:46:10.121071",
        "uuid": "723910b7-535a-4220-be41-2b91a76eecf8",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:21:46.969431",
        "date": "2024-06-29T13:21:46.361971",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2127",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3f3d2529-4421-43e4-82ea-f5d9df0f727c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382465"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002915",
        "update_ts": "2024-06-30T10:46:10.122149",
        "uuid": "7b32ddfb-f2ce-47a8-8a5a-e2bf3b4b755e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:53:41.154975",
        "date": "2024-06-29T15:53:40.565613",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2310",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "be6e919d-9c0a-4de7-a00a-4e175224b1fe",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379135"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003068",
        "update_ts": "2024-06-30T10:46:10.155144",
        "uuid": "ec7382e6-ec85-43b0-8d8d-1d3198ca83e1",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:00:14.859758",
        "date": "2024-06-29T16:00:14.319142",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2317",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4a19dd68-a3bb-4299-9497-4ec281eaa5a7",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379692"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003260",
        "update_ts": "2024-06-30T10:04:35.643688",
        "uuid": "5dee57a4-b13c-42ef-b86f-f182f274f063",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:05:21.106195",
        "date": "2024-06-29T16:05:20.611573",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2323",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e8e3bb03-5619-4899-a4ea-20e38201854e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137364898"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003258",
        "update_ts": "2024-06-30T10:04:35.646524",
        "uuid": "e73fd0c9-77ae-4e52-bfce-0f9f281a5097",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:50:54.770007",
        "date": "2024-06-29T17:50:54.038216",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2441",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1d0a2d7f-a439-4e1e-82b9-94ae1a992c5e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380490"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003182",
        "update_ts": "2024-06-30T10:04:35.695665",
        "uuid": "22d2a854-71b6-4bf9-9f96-b97333dc4101",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:12:37.874857",
        "date": "2024-06-29T19:12:37.150954",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2567",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "09de5c24-2ba9-4d61-a8bf-68292a4d20c8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380448"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003360",
        "update_ts": "2024-06-30T10:46:10.190638",
        "uuid": "ffb4f9d6-5460-4605-96cd-69abd6054bb9",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:21:44.649451",
        "date": "2024-06-29T13:21:44.063623",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2126",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ba9d5a77-a1a2-44df-be15-2b3e18a9b7f6",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352665"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002935",
        "update_ts": "2024-06-30T10:04:35.581149",
        "uuid": "6730d26c-a713-415b-a9ca-ddce9efc4082",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:24:29.800707",
        "date": "2024-06-29T13:24:29.131681",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2131",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9ed57eba-a493-493b-88e3-ea2502ea1976",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379139"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002916",
        "update_ts": "2024-06-30T10:46:10.123399",
        "uuid": "81cfa6f2-87fe-4ea1-a9ba-0a63f394ce97",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:07:25.980937",
        "date": "2024-06-29T16:07:25.475819",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2325",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "2b4e6898-98bc-446d-bb56-34ee61cd2d2e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381515"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003062",
        "update_ts": "2024-06-30T10:46:10.158770",
        "uuid": "45d5b1c1-e9d0-4571-bf05-fef171814e25",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:08:50.312927",
        "date": "2024-06-29T16:08:49.646355",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2328",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "243b151b-be45-4244-b5a8-226b12bafb5b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379705"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003061",
        "update_ts": "2024-06-30T10:46:10.159160",
        "uuid": "0eca60e4-f9ca-4dcd-aab3-c5b87e01d366",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:14:39.342959",
        "date": "2024-06-29T16:14:38.783073",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2335",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "83e21dbe-7a88-47a0-8090-9da6c5b48366",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379746"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003255",
        "update_ts": "2024-06-30T10:04:35.650260",
        "uuid": "7089cd98-c34e-4d49-99f5-62094034d5cb",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:17:26.381452",
        "date": "2024-06-29T16:17:25.796420",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2339",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0425fb93-d48d-4405-8273-67ace6c87e33",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380468"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003057",
        "update_ts": "2024-06-30T10:46:10.160847",
        "uuid": "364632e9-698c-4020-9402-d5636c5223c1",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:21:36.862901",
        "date": "2024-06-29T16:21:36.273493",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2345",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d33abcff-c76d-4c92-a7e0-3668d1bc19c9",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382137"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003054",
        "update_ts": "2024-06-30T10:46:10.162504",
        "uuid": "ef4f805a-c594-4837-bf77-b335c6e4ad42",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:54:45.355467",
        "date": "2024-06-29T17:54:44.675369",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2449",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "edcdff19-a67a-436d-afba-e300bd6ce91f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383068"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003190",
        "update_ts": "2024-06-30T10:04:35.700199",
        "uuid": "9b8ccc3d-9bbb-4b4b-a6f5-9b040baf4ff8",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:55:25.175214",
        "date": "2024-06-29T17:55:24.406272",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2451",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e19e384f-4035-435e-8041-3f53f0973062",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380455"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003381",
        "update_ts": "2024-06-30T10:46:10.183756",
        "uuid": "f266d227-214b-4a60-aaaf-4fa3764eeaf1",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:13:01.443936",
        "date": "2024-06-29T18:13:00.066360",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2469",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "62b3c77d-6344-400f-9710-5ccc3552e04e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137374354"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003560",
        "update_ts": "2024-07-01T10:58:24.604484",
        "uuid": "bf2f7fc5-2487-4f66-99c8-92bc058854ee",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:13:42.184147",
        "date": "2024-06-29T19:13:41.478321",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2568",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d362bad3-b727-4495-8110-bb2b5384762a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356529"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003361",
        "update_ts": "2024-06-30T10:46:10.191010",
        "uuid": "bf838cd2-3acf-4af8-9eb5-7a0ed7ab7b6a",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:24:54.065223",
        "date": "2024-06-29T13:24:53.530604",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2132",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ba7c0440-30c7-4b75-a7e5-018182c513d8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367667"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002936",
        "update_ts": "2024-06-30T10:04:35.582918",
        "uuid": "38f7d76f-63ac-4889-818c-8a2fef7148a3",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:45:49.149082",
        "date": "2024-06-29T14:45:48.392893",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2244",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1f856b80-1276-41ef-b214-77b395bbe5df",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383044"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003095",
        "update_ts": "2024-06-30T10:46:10.140015",
        "uuid": "64d90e70-a2db-4eb4-b9d2-2ae873f47e5c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:54:49.200791",
        "date": "2024-06-29T17:54:48.590138",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2450",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "988f7d77-35b2-4d5d-aac6-909f5974289c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380395"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003382",
        "update_ts": "2024-06-30T10:46:10.183289",
        "uuid": "b0305cc8-5835-47cb-b6e2-67b24d85ecb7",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:01:41.207526",
        "date": "2024-06-29T18:01:40.591188",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2458",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c7585f03-fef1-41e3-9cc5-9cc133b5bbfe",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356320"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003198",
        "update_ts": "2024-06-30T10:04:35.705832",
        "uuid": "1e18364e-78e0-4d18-afa8-38f6834745c5",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:03:22.963736",
        "date": "2024-06-29T18:03:22.115806",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2461",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "db718d12-1b60-460c-8be0-e513d5102180",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356530"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003191",
        "update_ts": "2024-06-30T10:04:35.707759",
        "uuid": "cc16deec-e90b-4129-997f-9c2e453d0e61",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:09:51.255660",
        "date": "2024-06-29T18:09:50.698625",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2466",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e9d224fc-b071-46ee-8143-a6f32ecd1c3a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381465"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003197",
        "update_ts": "2024-06-30T10:04:35.711056",
        "uuid": "cc2fa400-48f8-47f6-aae3-820df281c95a",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:28:39.207369",
        "date": "2024-06-29T18:28:38.476011",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2481",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f85642bb-3cce-492c-9eff-318c0141196c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379138"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003196",
        "update_ts": "2024-06-30T10:04:35.713900",
        "uuid": "137fc800-d918-4e0d-b49b-8ec49adfbfcf",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:28:55.641097",
        "date": "2024-06-29T18:28:55.055360",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2482",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6942ee22-6cc9-47d9-81cc-56ab0683d04e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137364892"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003551",
        "update_ts": "2024-07-01T10:58:24.612491",
        "uuid": "d0c8c688-a737-478b-8477-93a1f351732d",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:33:18.087964",
        "date": "2024-06-29T18:33:17.522471",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2490",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8ec62689-0184-46bd-b175-120d6965d4a0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367674"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003207",
        "update_ts": "2024-06-30T10:04:35.717891",
        "uuid": "3eadc6a9-f754-4bcd-8124-238f1b1cf7b4",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:36:30.919400",
        "date": "2024-06-29T18:36:29.870582",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2497",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ce868026-83b2-44f3-8cd3-7f425cd97701",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137364887"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003544",
        "update_ts": "2024-07-01T10:58:24.617209",
        "uuid": "dc498837-7b06-4ee5-9a02-21f91ec10e0e",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:14:55.333977",
        "date": "2024-06-29T19:14:54.258478",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2569",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "27e17abf-5263-4c6b-9b10-5ce764abf866",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382479"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003526",
        "update_ts": "2024-07-01T10:58:24.627962",
        "uuid": "90b4ea60-6018-4169-9ca4-e43ddd4daaeb",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:25:43.578684",
        "date": "2024-06-29T13:25:43.023309",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2134",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7dfef598-7c70-44a8-8f0a-2013acd8336b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373860"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002917",
        "update_ts": "2024-06-30T10:46:10.124820",
        "uuid": "a8977cc6-73b9-4d53-9e20-cb0b13a67a29",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:16:05.871754",
        "date": "2024-06-29T16:16:05.306492",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2337",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4788dba7-0150-42f7-a465-c6ee5ab9a911",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356314"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003254",
        "update_ts": "2024-06-30T10:04:35.651705",
        "uuid": "f5852bc4-a279-4b56-885e-deea426fdb6a",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:17:03.507535",
        "date": "2024-06-29T16:17:02.902638",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2338",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "abb2577c-2464-442e-bd03-1eb5d9710455",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356313"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003253",
        "update_ts": "2024-06-30T10:04:35.653488",
        "uuid": "c141eff7-8b57-4fea-8b6d-b8788ef151c3",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:18:42.756038",
        "date": "2024-06-29T16:18:42.239942",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2341",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e0769b0c-4162-4512-86a2-69b91c27d314",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380464"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003056",
        "update_ts": "2024-06-30T10:46:10.161608",
        "uuid": "bef0b968-c00f-418a-a758-aedcc1db63f4",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:55:31.022163",
        "date": "2024-06-29T17:55:30.437078",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2452",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f88dadeb-425e-4cf1-85da-07d2dd9d1bb3",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380467"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003189",
        "update_ts": "2024-06-30T10:04:35.700737",
        "uuid": "ea57394f-521d-4586-8a54-7922553f5f88",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:17:03.387931",
        "date": "2024-06-29T18:17:02.705844",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2475",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5b72272e-abb4-4af9-b284-fe2a506a0c45",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352673"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003556",
        "update_ts": "2024-07-01T10:58:24.608789",
        "uuid": "657b899c-4852-4895-b6a1-334b3300a486",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:22:30.286350",
        "date": "2024-06-29T18:22:29.098039",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2477",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "770bbe97-8419-4a77-90c5-05f7d18e3f3f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379738"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003555",
        "update_ts": "2024-07-01T10:58:24.609379",
        "uuid": "23394a56-f2fa-4c6f-a0a1-a8355408f4ed",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:26:59.254643",
        "date": "2024-06-29T18:26:58.627948",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2480",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "109ae5d7-6abe-421e-820e-0f8eb8230ce0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381521"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003552",
        "update_ts": "2024-07-01T10:58:24.611848",
        "uuid": "87628da5-d2e7-4362-9d20-5079814fb859",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:29:53.781087",
        "date": "2024-06-29T18:29:53.156839",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2484",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "11adf832-8e51-49f8-b5eb-c77780526ef3",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380450"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003550",
        "update_ts": "2024-07-01T10:58:24.613536",
        "uuid": "f12d28a1-2dc9-4be6-911c-66dd6d5adb67",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:35:45.450842",
        "date": "2024-06-29T18:35:44.676793",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2495",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "26ba1bf8-0e86-44bc-a1b9-29c32f01cd68",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380451"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003545",
        "update_ts": "2024-07-01T10:58:24.616627",
        "uuid": "9a5d7854-7ef6-46ba-8053-c733fed65502",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:15:08.058249",
        "date": "2024-06-29T19:15:07.333050",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2570",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e35898f0-3a64-4e44-8054-6a4fb79219a8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379122"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003362",
        "update_ts": "2024-06-30T10:46:10.191380",
        "uuid": "83b29a8c-a6c1-4dad-a3cb-88a6738ae252",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:16:24.119382",
        "date": "2024-06-29T19:16:23.413074",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2572",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e6649036-2a5a-4668-851d-21e7d374f10f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379766"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003525",
        "update_ts": "2024-07-01T10:58:24.628308",
        "uuid": "1e4a54d0-5106-4caf-a78b-4e03c2ce108a",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:17:22.672470",
        "date": "2024-06-29T19:17:21.991028",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2575",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "cfc5227e-969c-4d13-9466-160252a3de68",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380478"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003364",
        "update_ts": "2024-06-30T10:46:10.192345",
        "uuid": "55b5cbee-42d7-44ba-9a5c-99eb2040f1dd",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:18:28.641689",
        "date": "2024-06-29T19:18:27.963334",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2578",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "01a7e4c0-713c-40eb-932e-0727fc099540",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380476"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003363",
        "update_ts": "2024-06-30T10:46:10.192735",
        "uuid": "83b963ff-ee66-4915-89d8-46efd898f87c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:22:15.248666",
        "date": "2024-06-29T19:22:14.566821",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2587",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ab0a2f62-40f2-4fbd-b059-bcec02d496a1",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381513"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003354",
        "update_ts": "2024-06-30T10:46:10.194417",
        "uuid": "ee7a91c1-f931-4788-9ad2-f5a8cc996edf",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:23:00.831165",
        "date": "2024-06-29T19:23:00.143264",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2589",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0d5765e4-9bfe-42d5-8114-a7a12d7e8715",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379756"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003521",
        "update_ts": "2024-07-01T10:58:24.630857",
        "uuid": "c5d12bf5-df0e-4756-995b-71fe7aaffd26",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:24:00.581242",
        "date": "2024-06-29T19:23:59.916783",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2592",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7d025c2e-fb28-4cab-befc-aecaf76862c6",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381509"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003347",
        "update_ts": "2024-06-30T10:46:10.195179",
        "uuid": "f3185808-1664-404d-b354-a5edc0374aa8",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:24:51.043262",
        "date": "2024-06-29T19:24:50.406984",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2594",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9dc0f846-c5cb-465c-9390-8fa3a0df3686",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383057"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003173",
        "update_ts": "2024-06-30T10:04:35.731758",
        "uuid": "ede024f6-6bf4-4476-92b1-eb5bc0605d76",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:24:54.541070",
        "date": "2024-06-29T19:24:53.855047",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2595",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "de8127e0-3786-4e97-82da-d67c474fc80c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381510"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003346",
        "update_ts": "2024-06-30T10:46:10.195717",
        "uuid": "501a75f8-c84a-47f9-b7cb-feeb8fb75f7e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:25:24.009681",
        "date": "2024-06-29T19:25:23.374354",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2597",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "573e6876-242f-44d5-8a79-5089c2d5dbe4",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383076"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003520",
        "update_ts": "2024-07-01T10:58:24.631457",
        "uuid": "481027b9-eac5-464b-b1e3-f710267394cd",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:27:33.546403",
        "date": "2024-06-29T13:27:32.997579",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2136",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e98b1c0e-673e-460c-afb9-3100e5a89b4c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352667"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002937",
        "update_ts": "2024-06-30T10:04:35.584452",
        "uuid": "ec0b2b95-8a8c-4551-8a20-40227f1bd863",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:30:02.607508",
        "date": "2024-06-29T13:30:02.105293",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2140",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9f2660aa-e23b-4899-a693-7542a025f908",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352683"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002940",
        "update_ts": "2024-06-30T10:04:35.588436",
        "uuid": "a14c9df9-2852-47f9-892c-3e506932e14a",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:30:04.385389",
        "date": "2024-06-29T13:30:03.824614",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2142",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9946cd53-340d-43e2-8acc-91f52fe905e9",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373861"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002919",
        "update_ts": "2024-06-30T10:46:10.126217",
        "uuid": "15185d93-4b1b-4126-850b-2cb41805b496",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:20:19.301923",
        "date": "2024-06-29T16:20:18.729079",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2342",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8a4d1fdf-7a75-4823-ae5a-aac6b40c733b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382146"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003055",
        "update_ts": "2024-06-30T10:46:10.162014",
        "uuid": "8c4f7d56-c444-4180-9e5e-2a5699af442b",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:21:58.036603",
        "date": "2024-06-29T16:21:57.412961",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2346",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "2168edab-a8cd-443f-854a-a59207984cea",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352668"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003252",
        "update_ts": "2024-06-30T10:04:35.655836",
        "uuid": "10c26562-531a-4730-99ca-1b1084ae0b73",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:56:28.308505",
        "date": "2024-06-29T17:56:27.681055",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2453",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "538e1736-4f50-4377-a465-b849c3633000",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383083"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003187",
        "update_ts": "2024-06-30T10:04:35.702129",
        "uuid": "23e911d2-b6d2-4a2c-951f-3c90347e1900",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:59:01.252469",
        "date": "2024-06-29T17:59:00.499568",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2455",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c052a432-c3a6-4d1d-8735-310223e6fda5",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383082"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003188",
        "update_ts": "2024-06-30T10:04:35.703582",
        "uuid": "c36f783c-f61c-4d2a-adb8-c32a1c83977d",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:00:11.761126",
        "date": "2024-06-29T18:00:11.204772",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2456",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0f8b557f-2c6f-4fad-a81b-53234fc671d9",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381474"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003201",
        "update_ts": "2024-06-30T10:04:35.704759",
        "uuid": "572cbf21-f48c-4eb0-85ab-5ab936716dbf",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:01:16.161005",
        "date": "2024-06-29T18:01:15.410381",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2457",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f4b836e4-6c0a-4177-a0c9-3ab0edb54d06",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383061"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003200",
        "update_ts": "2024-06-30T10:04:35.705290",
        "uuid": "5da007c2-5479-4550-b30c-542ae3ccb6c3",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:16:37.789022",
        "date": "2024-06-29T18:16:37.148374",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2474",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "952d70b6-a563-4187-9560-3228d09d7ddf",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383065"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003194",
        "update_ts": "2024-06-30T10:04:35.711954",
        "uuid": "7e9099ba-6d9c-43cb-8a3b-ecd2c41a555e",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:23:41.791685",
        "date": "2024-06-29T18:23:41.073397",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2478",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9e0ccc6c-f78f-49b3-a706-705fc3e2b1e6",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381524"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003554",
        "update_ts": "2024-07-01T10:58:24.610254",
        "uuid": "f6c2d3cd-2683-444f-8c98-4fc465b9de26",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:25:17.516881",
        "date": "2024-06-29T18:25:16.907810",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2479",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "96acc613-ff4c-42d5-a88f-bf33b6f08041",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379737"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003553",
        "update_ts": "2024-07-01T10:58:24.610876",
        "uuid": "2d4f6a8d-2ff1-4c5a-a55a-5c126f6dc81a",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:31:34.442144",
        "date": "2024-06-29T18:31:33.831950",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2487",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "bd789660-f806-470d-b6c5-4e0075ec6dac",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367655"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003212",
        "update_ts": "2024-06-30T10:04:35.716929",
        "uuid": "e56a13c9-2319-4697-a045-cdd67411b1bb",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:34:14.619114",
        "date": "2024-06-29T18:34:13.997982",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2492",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d30a1030-381b-4148-8449-d0321f5eb2ff",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380392"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003546",
        "update_ts": "2024-07-01T10:58:24.615999",
        "uuid": "27d6eee2-a9f4-4b1b-8873-3b4069514d00",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:36:23.722370",
        "date": "2024-06-29T18:36:22.685285",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2496",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "cfe5589a-cb2a-44f0-a6f8-82f335488484",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379141"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003208",
        "update_ts": "2024-06-30T10:04:35.719369",
        "uuid": "f4db46de-c656-4441-a52a-3225c1640994",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:37:16.185830",
        "date": "2024-06-29T18:37:15.566983",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2498",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "11035c92-6264-4733-946e-ba422333c578",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352676"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003543",
        "update_ts": "2024-07-01T10:58:24.617827",
        "uuid": "63b3a07f-549f-4b06-8faa-2956246c5273",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:37:32.203190",
        "date": "2024-06-29T18:37:31.627746",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2499",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6080b1f0-c7ca-46d2-80bc-3eec122ac928",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380474"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003218",
        "update_ts": "2024-06-30T10:04:35.720277",
        "uuid": "09fb43ac-ac80-4050-8ff0-4dc89a010d56",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:28:28.212014",
        "date": "2024-06-29T13:28:27.605794",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2137",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "40251af6-cd62-4080-8e6b-bdd43221d743",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379763"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002918",
        "update_ts": "2024-06-30T10:46:10.125459",
        "uuid": "0f503f1f-90c0-49f4-9e91-46dc932978ac",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:49:44.459073",
        "date": "2024-06-29T14:49:43.969846",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2247",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5303bd42-f331-4b3c-82d7-15370c8d3050",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383072"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003094",
        "update_ts": "2024-06-30T10:46:10.140598",
        "uuid": "f9b09606-89cf-47e7-92e7-7d30a71722b7",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:20:41.598554",
        "date": "2024-06-29T16:20:41.062036",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2343",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "390ca17b-97a6-4c8e-bf06-130cc9a10de2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382127"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003251",
        "update_ts": "2024-06-30T10:04:35.654576",
        "uuid": "12a4f165-8142-448a-9863-529edfb6ee73",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:02:29.901592",
        "date": "2024-06-29T18:02:29.328487",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2460",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "07843bf4-a6a1-4f97-8155-79dac7a2c62e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352680"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003199",
        "update_ts": "2024-06-30T10:04:35.706589",
        "uuid": "cfef90f4-b0bf-456a-baf3-6e6e2459ef0e",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:04:18.956138",
        "date": "2024-06-29T18:04:18.400002",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2462",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "53b59d16-4410-4071-a029-cf06c90a62e5",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356521"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003193",
        "update_ts": "2024-06-30T10:04:35.708707",
        "uuid": "16bbe481-9882-4574-baf0-70f6e8a73156",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:04:57.809988",
        "date": "2024-06-29T18:04:56.950899",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2463",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "a2649250-ddb5-49df-90c8-7f24ea7e5cc5",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381473"
          },
          {
            "acceptance_type": "BOX",
            "id": "c76dfe45-783d-4a4c-bc6f-3b0588d92c44",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356523"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003192",
        "update_ts": "2024-06-30T10:04:35.709839",
        "uuid": "db664113-f5c7-444a-96f2-e034e19b3b46",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:27:16.508885",
        "date": "2024-06-29T19:27:15.852628",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2602",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5eec8924-e2c4-43cb-8db3-06add9b15aeb",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381482"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003519",
        "update_ts": "2024-07-01T10:58:24.632259",
        "uuid": "a4557ca3-8cd6-49de-82bb-33fd012844af",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:29:48.713655",
        "date": "2024-06-29T19:29:47.913238",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2611",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7e5b659b-883b-4355-b9b4-216ffd276e9e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381481"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003167",
        "update_ts": "2024-06-30T10:04:35.735790",
        "uuid": "e66a5be6-c00b-42ce-8a25-77ea5a87b24d",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:30:07.760078",
        "date": "2024-06-29T19:30:07.143111",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2613",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9555ac18-703d-4067-8648-1adecc45bf55",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379704"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003338",
        "update_ts": "2024-06-30T10:46:10.197608",
        "uuid": "2cd72e83-716b-4ac9-908f-d688f45e4ea8",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:30:39.945532",
        "date": "2024-06-29T19:30:39.311736",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2614",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1f9f9962-a0e6-4386-a935-1ada5d6a23ff",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379128"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003337",
        "update_ts": "2024-06-30T10:46:10.197997",
        "uuid": "9c7bcb35-ed0e-4b16-b7d4-edde6d4f051e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:31:30.379070",
        "date": "2024-06-29T19:31:29.616861",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2617",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1400ba6d-8c22-4031-b59c-7d47ccf21695",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381472"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003516",
        "update_ts": "2024-07-01T10:58:24.634555",
        "uuid": "c231b26b-be46-475c-afec-04b7679d920f",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:37:24.203180",
        "date": "2024-06-29T19:37:23.528797",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2627",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b91d0b18-d027-4f10-b021-667c0361e0a1",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379125"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003162",
        "update_ts": "2024-06-30T10:46:10.200674",
        "uuid": "193ae8a8-c083-4acc-9c66-e0a754693d3d",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-28T19:58:36.775102",
        "date": "2024-06-28T19:58:36.243900",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-1977",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "a3be76d6-9571-4a08-9820-47cb87523370",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382122"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002614",
        "update_ts": "2024-06-30T10:46:10.102415",
        "uuid": "0a2436da-2757-4b92-99f6-ffb2cc9fdb2c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:30:52.545928",
        "date": "2024-06-29T13:30:52.016662",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2143",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "abf5d4d0-619b-40f1-ac79-0c733a5739c2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381527"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002941",
        "update_ts": "2024-06-30T10:04:35.590083",
        "uuid": "89f11ef0-8b6e-417f-9c3f-f7aad5fa0462",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:31:42.199928",
        "date": "2024-06-29T13:31:41.680259",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2147",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "beb5426a-ce24-47e4-b5c9-4c4cbaa13a31",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381523"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002942",
        "update_ts": "2024-06-30T10:04:35.591263",
        "uuid": "34fbeb60-202c-4b58-8e0e-50b0907afe4f",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:32:37.347158",
        "date": "2024-06-29T13:32:36.866571",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2150",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ebd0e2c7-79e6-4dc8-a03a-bf260878b739",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382486"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002921",
        "update_ts": "2024-06-30T10:46:10.127770",
        "uuid": "f451783c-5b1e-44c5-9a3b-52c28f6f7f3e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:37:07.649893",
        "date": "2024-06-29T13:37:07.196619",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2160",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b7190f82-a4ef-4879-ad5d-4d459ab8c2d5",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379762"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002924",
        "update_ts": "2024-06-30T10:46:10.129543",
        "uuid": "140ba3fe-eb2e-421d-9557-1b77ff0c625c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:38:22.455559",
        "date": "2024-06-29T13:38:21.972268",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2163",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "07657941-2969-401b-88ab-a9036196db2b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373857"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002925",
        "update_ts": "2024-06-30T10:46:10.130153",
        "uuid": "d09070e3-b638-4f28-931f-b57ba1293d13",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:22:55.994583",
        "date": "2024-06-29T16:22:55.380522",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2347",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "74c5171b-ff0a-4735-bf8a-c5965bd01f12",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379686"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003053",
        "update_ts": "2024-06-30T10:46:10.162881",
        "uuid": "8d9377d7-723c-4e79-90ae-3f5fa7269f13",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:16:09.055686",
        "date": "2024-06-29T18:16:08.377782",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2473",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ab4baf8a-abac-4e6e-adf8-6aa1ae7e7985",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381519"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003557",
        "update_ts": "2024-07-01T10:58:24.608153",
        "uuid": "e131013c-8d1e-484e-b681-3dbc6c18f6b8",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:27:27.515609",
        "date": "2024-06-29T19:27:26.811661",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2603",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ced4b2d4-3018-4508-a5ff-3fe2569c35ef",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381486"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003170",
        "update_ts": "2024-06-30T10:04:35.733750",
        "uuid": "c9af7373-f025-4717-905c-2e4099885946",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:31:31.063664",
        "date": "2024-06-29T19:31:30.376837",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2618",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "42e234f2-294a-48ae-841c-d04e9fcebc9c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381461"
          },
          {
            "acceptance_type": "BOX",
            "id": "ecc524ea-99cc-49f0-a72f-09888428e1f8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379153"
          },
          {
            "acceptance_type": "BOX",
            "id": "e2c3e848-f14f-4035-a86e-51607531a979",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379154"
          },
          {
            "acceptance_type": "BOX",
            "id": "6b9d58b8-0647-45b2-8592-fd1754af8802",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137377752"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003165",
        "update_ts": "2024-06-30T10:04:35.736641",
        "uuid": "75561002-7126-4876-851e-d6ca725710c9",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:34:20.454218",
        "date": "2024-06-29T19:34:19.792682",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2623",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "febdaffc-85f8-47cd-8c9e-4f6aff885a22",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356522"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003515",
        "update_ts": "2024-07-01T10:58:24.634934",
        "uuid": "a7ee2793-7f0e-4110-8fd4-d399eeb4f3b5",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:32:37.128815",
        "date": "2024-06-29T13:32:36.576438",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2149",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "46412574-98f3-4a35-b204-a5d4a2d5a2d8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367677"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002943",
        "update_ts": "2024-06-30T10:04:35.592716",
        "uuid": "37d2ec79-977f-4b5f-b27d-ac856ce7455a",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:33:34.501062",
        "date": "2024-06-29T13:33:33.992122",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2152",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "56ab9413-6168-4c64-98dd-2785072a7a9f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379749"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002944",
        "update_ts": "2024-06-30T10:04:35.593898",
        "uuid": "67ef5a25-bd55-4b4f-9cf6-0777065e3bbd",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:34:22.365945",
        "date": "2024-06-29T13:34:21.855016",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2153",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7803beb7-1138-4bfb-8cd0-6c194aaa9b0f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380390"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002945",
        "update_ts": "2024-06-30T10:04:35.595494",
        "uuid": "c817e688-9546-4c2f-9981-498280f9e4b3",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:34:45.471994",
        "date": "2024-06-29T13:34:44.943784",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2155",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b87ccddd-f259-4589-aceb-fd3fea1a70f4",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373864"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002922",
        "update_ts": "2024-06-30T10:46:10.128174",
        "uuid": "59d455db-45e7-4485-9260-bec0dec2cd6e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:36:20.161676",
        "date": "2024-06-29T13:36:19.666123",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2159",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6398b20d-5915-4008-a97b-459d80e71eed",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137374358"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002947",
        "update_ts": "2024-06-30T10:04:35.597878",
        "uuid": "40a7689d-660b-45bc-82d5-922c204b6d33",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:46:39.056989",
        "date": "2024-06-29T13:46:38.579803",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2182",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "57dcf6dd-d125-4097-9d3c-d2b031286284",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379733"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002956",
        "update_ts": "2024-06-30T10:04:35.611858",
        "uuid": "b3f8dc79-2f63-49c6-a530-ded0aaa4ffce",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:46:58.454343",
        "date": "2024-06-29T13:46:57.965285",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2183",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5e36c5d8-0c94-484c-872e-abea401bfcd1",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "14023675"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002930",
        "update_ts": "2024-06-30T10:46:10.132821",
        "uuid": "df6083cc-16d3-4bc4-82af-8b1ff241104f",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:48:28.698223",
        "date": "2024-06-29T13:48:28.167410",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2185",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d5475440-d4c4-48da-adb9-3386665410e7",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379736"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002957",
        "update_ts": "2024-06-30T10:04:35.613075",
        "uuid": "0135af6c-cb5b-458c-8edd-9af7cf38a7b2",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:53:26.191226",
        "date": "2024-06-29T14:53:25.697316",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2250",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "21a6dde8-d298-4805-a42d-10a8c2e3043e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383073"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003093",
        "update_ts": "2024-06-30T10:46:10.141257",
        "uuid": "a36c65d5-dac3-466d-887b-721deb78204b",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:31:16.129615",
        "date": "2024-06-29T16:31:15.588094",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2360",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4befc617-6408-4dd1-8dcc-fcddf03540fe",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379121"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003248",
        "update_ts": "2024-06-30T10:04:35.660850",
        "uuid": "7276ccaf-d74a-4082-9963-7f628fbd7a30",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:40:50.702812",
        "date": "2024-06-29T16:40:50.183156",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2373",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "cc3bf1f7-6947-432e-ba25-e0d885821b43",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379714"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003034",
        "update_ts": "2024-06-30T10:46:10.169648",
        "uuid": "aae2698b-0dad-475b-b7f0-7b0a3638a551",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:48:16.916889",
        "date": "2024-06-29T16:48:16.398388",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2380",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8932db4b-6837-45c0-bcbf-f766d35863c3",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379679"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003409",
        "update_ts": "2024-06-30T10:46:10.170803",
        "uuid": "ee90a91f-d6b2-4844-bb6d-4dd48fe041b0",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:49:21.615890",
        "date": "2024-06-29T16:49:21.089510",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2382",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6c64e18a-69b9-49a9-8483-15f39ce25124",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379744"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003408",
        "update_ts": "2024-06-30T10:46:10.171243",
        "uuid": "abc87b8d-33ff-4158-9107-e0b7243245c2",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:59:01.201729",
        "date": "2024-06-29T16:59:00.595524",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2391",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "68c4b692-8aa5-40af-bbd7-0034fe9a4b2f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379225"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003243",
        "update_ts": "2024-06-30T10:04:35.669691",
        "uuid": "b6011602-6646-4673-a57c-a38b5e251233",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:59:59.212782",
        "date": "2024-06-29T16:59:58.661552",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2393",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1da15514-954f-4d1f-b49c-e4949ffe67fe",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356538"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003401",
        "update_ts": "2024-06-30T10:46:10.174786",
        "uuid": "b0a81a48-d278-4cec-aa26-1d67559a2e38",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:14:15.455243",
        "date": "2024-06-29T18:14:14.709699",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2470",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "304ba4c8-ba87-49a7-ae4b-80ae42f8c37f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379739"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003559",
        "update_ts": "2024-07-01T10:58:24.605680",
        "uuid": "6f46de7d-ae45-4a95-8ae0-5f75065bf432",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:27:42.001473",
        "date": "2024-06-29T19:27:41.285265",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2604",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3c3259aa-1132-4ea5-918d-b9e10f9b373b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381487"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003169",
        "update_ts": "2024-06-30T10:04:35.734472",
        "uuid": "55e66675-b516-43a0-b736-69a4cedbe269",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:27:50.135471",
        "date": "2024-06-29T19:27:49.453807",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2605",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0e079ad9-a344-4495-a7a8-844a4f0911df",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382123"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003345",
        "update_ts": "2024-06-30T10:46:10.196484",
        "uuid": "ce411659-f25c-4a45-b94e-5e655d20df64",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:29:48.916215",
        "date": "2024-06-29T19:29:48.180162",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2612",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4e84834c-da3f-49f4-a2c5-b71e0965617c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356318"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003517",
        "update_ts": "2024-07-01T10:58:24.634130",
        "uuid": "535546bc-c1fb-4a4e-b51f-d6057b3be58c",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:35:42.859647",
        "date": "2024-06-29T19:35:42.208034",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2625",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "cb4226eb-7a7b-4092-be0a-0602ff7e1772",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383048"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003330",
        "update_ts": "2024-06-30T10:46:10.199704",
        "uuid": "c47d6c16-3a60-4487-a472-00133e58ad0e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:37:47.824334",
        "date": "2024-06-29T19:37:47.155950",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2628",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c8faf684-804f-4568-a1ba-13617ae396b1",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381160"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003514",
        "update_ts": "2024-07-01T10:58:24.635559",
        "uuid": "5a9d7236-ef40-47fc-a83c-a4992bd6a2ea",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:38:30.026763",
        "date": "2024-06-29T19:38:29.382903",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2630",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b2a01ba0-a1bb-453d-83df-5a10f09db904",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379129"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003331",
        "update_ts": "2024-06-30T10:46:10.201991",
        "uuid": "f1214885-8633-4def-98ac-910d93ba53c0",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:41:07.146453",
        "date": "2024-06-29T19:41:06.515135",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2635",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c3a4c99b-d587-4b9d-bca8-a7b378d03e15",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379693"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003328",
        "update_ts": "2024-06-30T10:46:10.202740",
        "uuid": "b9f3e981-01d6-4cad-9de2-0d31975a42b8",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:31:03.699037",
        "date": "2024-06-29T13:31:03.238099",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2145",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "10a637bd-45b1-4ba1-8133-ee1f35078a17",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373840"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002920",
        "update_ts": "2024-06-30T10:46:10.126869",
        "uuid": "22395428-9c48-4408-80e9-c5c663de6d8e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:35:27.361114",
        "date": "2024-06-29T13:35:25.895700",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2156",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "365eb350-fb4f-42e9-a8dd-722f3e26c030",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137374353"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002946",
        "update_ts": "2024-06-30T10:04:35.596716",
        "uuid": "72663466-7ba2-414c-84d2-6fdeeacfe5ae",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:40:20.304491",
        "date": "2024-06-29T13:40:19.847737",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2168",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "51228db2-b5a3-406b-9119-005c81021b71",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379730"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002952",
        "update_ts": "2024-06-30T10:04:35.605315",
        "uuid": "5da25e39-3f31-4e84-8b61-f94bec46aa45",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:41:08.454859",
        "date": "2024-06-29T13:41:07.983171",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2170",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7e715265-07ce-492f-b701-6e34cc8f6e9a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137374352"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002953",
        "update_ts": "2024-06-30T10:04:35.606989",
        "uuid": "afe726bd-019c-42a0-b16d-b720a71a03b8",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:42:45.065432",
        "date": "2024-06-29T13:42:44.568015",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2173",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "cbfce597-cc82-4c93-bb86-b5d4a6735697",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381526"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002954",
        "update_ts": "2024-06-30T10:04:35.608227",
        "uuid": "abe2eda4-b778-48d6-acd7-34f4d13c06bb",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:29:44.706958",
        "date": "2024-06-29T16:29:44.175301",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2356",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "bc31b474-246f-415f-80ae-176048cbbd27",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352664"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003049",
        "update_ts": "2024-06-30T10:46:10.164474",
        "uuid": "8931340e-3f61-4647-81d7-41bda697c4db",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:31:51.470116",
        "date": "2024-06-29T16:31:50.531250",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2361",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "596c542c-1d58-4111-8a59-d3f461191ef8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382130"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003238",
        "update_ts": "2024-06-30T10:04:35.661874",
        "uuid": "33637a20-bb78-4257-8673-4d64c35aa53f",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:32:03.554487",
        "date": "2024-06-29T16:32:03.010574",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2362",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "a0ab6482-810e-466c-9ee4-20d089d9ef31",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379740"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003047",
        "update_ts": "2024-06-30T10:46:10.165427",
        "uuid": "92602ed5-d8c7-4574-b0df-df7f5d8626ed",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:32:29.188710",
        "date": "2024-06-29T16:32:28.404142",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2364",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "98210939-debb-424f-9679-904bb06145d0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382135"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003046",
        "update_ts": "2024-06-30T10:46:10.166158",
        "uuid": "d5a9b2f6-1ba3-4f2d-882b-48291ed932f5",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:36:07.617469",
        "date": "2024-06-29T16:36:06.442901",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2370",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "16075852-ea7a-4bd0-86b9-a2741eadeaa0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382132"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003239",
        "update_ts": "2024-06-30T10:04:35.663296",
        "uuid": "da2c16e5-362d-495d-9b65-f96308f35af5",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:29:17.309212",
        "date": "2024-06-29T19:29:16.638918",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2610",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3646295f-57a5-4cdf-a7bc-83fe553f2800",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381488"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003168",
        "update_ts": "2024-06-30T10:04:35.735235",
        "uuid": "a87cf3a7-0b9d-4ec9-a17b-640367aa0fe4",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:37:51.452142",
        "date": "2024-06-29T13:37:50.681191",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2162",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9023c053-4bf0-4afd-8776-cb0db5c4f565",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379731"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002949",
        "update_ts": "2024-06-30T10:04:35.600840",
        "uuid": "ec366d62-0f86-4b6a-865f-db857684d8d7",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:45:22.934615",
        "date": "2024-06-29T13:45:21.948216",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2180",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "18b7ba9a-02a1-40fe-ab56-abce001e88d1",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381525"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002955",
        "update_ts": "2024-06-30T10:04:35.609938",
        "uuid": "571d0a2f-751a-4368-a9d0-0df2014ce27c",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:51:55.110427",
        "date": "2024-06-29T13:51:54.422979",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2190",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d7127170-874c-4a8d-949c-0024a805112b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379729"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002841",
        "update_ts": "2024-06-30T10:46:10.134237",
        "uuid": "b6f1b0e9-8c04-468e-92e2-26cfec849c84",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:51:56.168964",
        "date": "2024-06-29T13:51:55.481131",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2191",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4502e29c-50d1-4d5f-8fb7-b19e30dd57fb",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "14023117"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002958",
        "update_ts": "2024-06-30T10:04:35.614243",
        "uuid": "0ce9c98d-b745-4d4e-91f6-2eed3b1719a4",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T14:55:09.071532",
        "date": "2024-06-29T14:55:08.067472",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2253",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "54e491bf-54aa-4f80-afc3-7307f9f44ba0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379773"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003092",
        "update_ts": "2024-06-30T10:46:10.141860",
        "uuid": "4da426bd-76d9-46b0-8dae-9848ad566c8d",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:24:29.655835",
        "date": "2024-06-29T16:24:29.007237",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2350",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0e104173-019a-47b8-9797-cfe69bd9a3f1",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382149"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003052",
        "update_ts": "2024-06-30T10:46:10.163204",
        "uuid": "9d016e5b-0b27-4a45-82c1-7d9d460cafbd",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:30:06.723714",
        "date": "2024-06-29T16:30:06.106790",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2357",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "17964aae-39a5-4121-8822-d6da8569d57f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382129"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003247",
        "update_ts": "2024-06-30T10:04:35.659599",
        "uuid": "6c6ccf7a-17d8-4a9c-bdae-61251e8b85e8",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:34:19.001549",
        "date": "2024-06-29T16:34:18.490224",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2366",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7d6b098d-de3b-4927-9b55-6a7583205716",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382125"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003044",
        "update_ts": "2024-06-30T10:46:10.167691",
        "uuid": "f862d9b5-23a0-4ec5-bc5e-6ae2ec1f27ae",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:35:31.389698",
        "date": "2024-06-29T16:35:30.218721",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2368",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6fc9eff9-f637-406d-8e05-bf4545ea0abb",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352670"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003043",
        "update_ts": "2024-06-30T10:46:10.168211",
        "uuid": "719f0f0a-92b3-4d32-a766-d2fb6c72f3c1",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:36:03.089263",
        "date": "2024-06-29T16:36:02.312001",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2369",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7bd683ab-d4ce-4570-9179-34bf307d9d6f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352671"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003042",
        "update_ts": "2024-06-30T10:46:10.168647",
        "uuid": "02a37e5c-f93f-4988-80ed-749558b32103",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:38:42.048042",
        "date": "2024-06-29T16:38:41.459405",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2372",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9c9bbd4d-0a33-4037-9ed8-9b9f5dfec806",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379741"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003035",
        "update_ts": "2024-06-30T10:46:10.169146",
        "uuid": "4b2ee5ef-4a9f-4ace-8650-d38015e5876f",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:15:20.429876",
        "date": "2024-06-29T18:15:19.796865",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2472",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8637eca6-98f6-429d-a164-317c94887a84",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "14023118"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003558",
        "update_ts": "2024-07-01T10:58:24.606152",
        "uuid": "e68810f4-bc98-472f-a74c-8a0b837ab123",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:28:40.144718",
        "date": "2024-06-29T19:28:39.406675",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2607",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "d597d1f9-961c-4c0a-8b1a-c1a28b02d6bd",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356524"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003518",
        "update_ts": "2024-07-01T10:58:24.633079",
        "uuid": "4a1e3885-061e-4117-8d67-49adb81f50d3",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:37:54.207476",
        "date": "2024-06-29T19:37:53.562056",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2629",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "808565fe-6647-4028-bc7d-b55c3efb406e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383052"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003329",
        "update_ts": "2024-06-30T10:46:10.201593",
        "uuid": "3101eed0-5755-432d-b5df-986e478673f0",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:39:11.168258",
        "date": "2024-06-29T19:39:10.514915",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2631",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "539e831b-a39f-4220-8f87-4dd5e30c318a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379127"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003332",
        "update_ts": "2024-06-30T10:46:10.202379",
        "uuid": "26eb4804-b44d-4b75-9b02-d8d4285c0164",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:39:23.116450",
        "date": "2024-06-29T19:39:22.489892",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2632",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1a1a3699-568d-454a-9a97-e6aa78447acf",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356319"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003513",
        "update_ts": "2024-07-01T10:58:24.635944",
        "uuid": "6a8ccf6d-60ee-465c-8d31-cb9bf0e1af49",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:35:56.941835",
        "date": "2024-06-29T13:35:55.905693",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2158",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5bc963f6-1fe5-4fda-b2c0-b4b92383f95e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379769"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002923",
        "update_ts": "2024-06-30T10:46:10.129078",
        "uuid": "84efc061-c547-44b9-831f-4b89ca049dec",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:37:11.381082",
        "date": "2024-06-29T13:37:10.924901",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2161",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "58d94085-32e8-4813-91aa-e8e7e89630e9",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380447"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002948",
        "update_ts": "2024-06-30T10:04:35.599285",
        "uuid": "a610fbc8-ebb5-4b96-84e4-6208a62596b7",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:38:30.241512",
        "date": "2024-06-29T13:38:29.705494",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2164",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9adc22b8-616e-40e5-84a2-1072973b380b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381517"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002950",
        "update_ts": "2024-06-30T10:04:35.601943",
        "uuid": "bd5c1612-3773-49b2-a470-129ad0e8d99e",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:39:37.846495",
        "date": "2024-06-29T13:39:37.343212",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2167",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6badcfb1-7770-454e-aed1-9571c14d2e00",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "14023673"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002926",
        "update_ts": "2024-06-30T10:46:10.130602",
        "uuid": "7a0e6b40-5883-46bd-bd8e-24da250147b1",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:41:39.276953",
        "date": "2024-06-29T13:41:38.700281",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2171",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "15a628ef-954b-4bd6-b03d-f9b438f0381b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "14023674"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002927",
        "update_ts": "2024-06-30T10:46:10.131129",
        "uuid": "2150f054-555b-45d3-8734-c8de07d68c4d",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:26:16.487857",
        "date": "2024-06-29T16:26:15.843713",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2351",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "dfca189c-b58c-4134-b128-4caeb216494f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380483"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003250",
        "update_ts": "2024-06-30T10:04:35.657168",
        "uuid": "4f88d2a5-0c37-41e1-9219-29a9b6791ba1",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:17:28.542305",
        "date": "2024-06-29T18:17:27.905580",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2476",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "90ed75dd-7f30-4124-b1e0-91abbcda4722",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381520"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003195",
        "update_ts": "2024-06-30T10:04:35.713101",
        "uuid": "740d4d6b-9ac0-4b81-9b3c-fcf176cd477d",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:29:39.853128",
        "date": "2024-06-29T18:29:39.168704",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2483",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b13a929a-75a7-4b83-ac37-783da28e0039",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379137"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003210",
        "update_ts": "2024-06-30T10:04:35.714891",
        "uuid": "2e832d07-1081-476d-b5d3-58920adb1663",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:30:33.827815",
        "date": "2024-06-29T18:30:33.269673",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2485",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b69b1bdb-2e6a-4e97-9da0-36fe47849cc8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137352675"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003549",
        "update_ts": "2024-07-01T10:58:24.614108",
        "uuid": "b72e79bf-80e3-4c9d-8964-cde42ee4b87a",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:30:51.476786",
        "date": "2024-06-29T18:30:50.701330",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2486",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "082710c1-d765-4a8c-ba72-a441f38f719b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379156"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003211",
        "update_ts": "2024-06-30T10:04:35.715807",
        "uuid": "fca466ea-4ec5-4ac1-81fd-606e07f5bfa8",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:28:57.895846",
        "date": "2024-06-29T19:28:57.233506",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2608",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "563180d2-e81a-40eb-a51c-6a617b5abad6",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382480"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003349",
        "update_ts": "2024-06-30T10:46:10.197169",
        "uuid": "65a35f35-0d39-4659-95c7-ea472ea23439",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:30:47.724902",
        "date": "2024-06-29T19:30:47.038001",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2615",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e309eb1f-5d27-4f71-ac85-7d536ed80e19",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381489"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003166",
        "update_ts": "2024-06-30T10:04:35.736216",
        "uuid": "c7a0ce8c-925e-4419-9de4-878efa1985c0",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:34:49.384463",
        "date": "2024-06-29T19:34:48.381347",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2624",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "2ee30dfa-4c33-4762-846a-3575025b5b47",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380398"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003309",
        "update_ts": "2024-06-30T10:46:10.199334",
        "uuid": "d6b93257-fb79-4347-90fc-12d7c9ae04ef",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:41:59.844406",
        "date": "2024-06-29T19:41:59.073935",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2637",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "67952178-057a-4196-994a-fe95961880b7",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379158"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003321",
        "update_ts": "2024-06-30T10:46:10.203091",
        "uuid": "1c040bfa-1a32-4cfe-accc-00138dc37dac",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:39:33.250764",
        "date": "2024-06-29T13:39:32.714503",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2166",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "dae2b4f6-edaf-4f7a-8759-4ed7b0f01c5b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137374355"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002951",
        "update_ts": "2024-06-30T10:04:35.603629",
        "uuid": "7feeaf39-2800-4dcf-88da-41e1dfe48ece",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:43:00.814762",
        "date": "2024-06-29T13:43:00.264060",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2174",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b3140360-9d96-420f-a707-0fc3d42c1ada",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "14023672"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002928",
        "update_ts": "2024-06-30T10:46:10.131823",
        "uuid": "4fd909fd-b0a5-4103-b359-38eb0371afba",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:26:46.316978",
        "date": "2024-06-29T16:26:45.816182",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2352",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4b819113-be35-4dee-b313-f28a82fc638a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382138"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003051",
        "update_ts": "2024-06-30T10:46:10.163636",
        "uuid": "25704ab1-fa66-4855-b60e-c5775a23aec2",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:28:01.154778",
        "date": "2024-06-29T16:28:00.660091",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2353",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "93d1a438-c840-4ca6-affe-a2b110802794",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379117"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003050",
        "update_ts": "2024-06-30T10:46:10.163988",
        "uuid": "45f6f8a1-0b52-4f65-a83d-522531ea47e4",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:31:00.660613",
        "date": "2024-06-29T16:31:00.147825",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2359",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "392a2857-2285-4e69-9b4b-2b7571f6d23b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379706"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003048",
        "update_ts": "2024-06-30T10:46:10.164981",
        "uuid": "d44c092a-0de7-4f3e-b7ab-e815497d8037",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:37:08.283027",
        "date": "2024-06-29T16:37:06.939174",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2371",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8b37f480-688c-4f40-8285-53f49e94c5ff",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379684"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003240",
        "update_ts": "2024-06-30T10:04:35.664751",
        "uuid": "981bf92a-a6bb-4340-b2e1-57669bc6bb4e",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:32:57.229245",
        "date": "2024-06-29T18:32:56.577853",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2489",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "0e2dc959-4e7e-4885-9eb8-eb159d627223",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380449"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003548",
        "update_ts": "2024-07-01T10:58:24.614993",
        "uuid": "aaa73a8a-1275-4291-9bd1-93a219caf5bc",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:33:41.560703",
        "date": "2024-06-29T18:33:40.909567",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2491",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6e9c195f-cfcb-48dd-8e62-56fdb7fd9a30",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380391"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003547",
        "update_ts": "2024-07-01T10:58:24.615403",
        "uuid": "270a4568-45b9-43cd-922a-d8a2729bf08a",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:33:18.030488",
        "date": "2024-06-29T19:33:17.427042",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2620",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "08ecee42-afdc-4ae3-8f5d-253c82604112",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379124"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003340",
        "update_ts": "2024-06-30T10:46:10.198494",
        "uuid": "3e109ca7-f07e-4d1e-8ad4-e0627603a37c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:33:57.966377",
        "date": "2024-06-29T19:33:57.345171",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2621",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7c65f9b4-208f-468c-86b6-784fc96344f1",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383058"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003339",
        "update_ts": "2024-06-30T10:46:10.198856",
        "uuid": "0dc16d21-e1bd-4328-afda-06764b3fcc8b",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:36:48.160606",
        "date": "2024-06-29T19:36:47.501087",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2626",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "30f9563c-2595-403e-8690-8680b3b74a14",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383047"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003161",
        "update_ts": "2024-06-30T10:46:10.200130",
        "uuid": "4bbf5f24-2e97-4d0a-8bc6-eb0be39f0cab",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:45:10.964008",
        "date": "2024-06-29T19:45:09.940370",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2642",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "100b33c5-d655-441b-bc1a-4bf0e86137aa",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379771"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003324",
        "update_ts": "2024-06-30T10:46:10.204653",
        "uuid": "b3759dc1-416b-4c4a-a948-19a13d5b9612",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:44:46.542551",
        "date": "2024-06-29T13:44:44.717082",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2178",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9ced1daa-0036-4336-a67b-5d77cfd72cc7",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373858"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002929",
        "update_ts": "2024-06-30T10:46:10.132424",
        "uuid": "2733c61c-34ff-4fc9-aea3-f630b87181a1",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:17:47.190602",
        "date": "2024-06-29T15:17:46.464450",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2257",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "100aee35-c470-4563-acfa-414606cf8092",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381470"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003091",
        "update_ts": "2024-06-30T10:46:10.142374",
        "uuid": "42dddb65-8d15-4147-b8e1-2f1e70a82594",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:28:39.843578",
        "date": "2024-06-29T16:28:39.264230",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2355",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e7839ef2-3a50-41aa-99b7-ad8a878e187a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379688"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003249",
        "update_ts": "2024-06-30T10:04:35.658210",
        "uuid": "ce4fc7b7-d8a1-45cd-a214-78f7a27249ff",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:33:41.665026",
        "date": "2024-06-29T16:33:41.144663",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2365",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5004b16d-50f3-468a-9580-ffe8b57bc963",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382126"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003045",
        "update_ts": "2024-06-30T10:46:10.166910",
        "uuid": "f0af67f6-2039-443c-a2c4-d2ef1bc05953",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:35:13.702356",
        "date": "2024-06-29T18:35:13.085467",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2494",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "45796f8a-a347-4ce3-9fd1-ac06a8669e73",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379160"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003209",
        "update_ts": "2024-06-30T10:04:35.718640",
        "uuid": "b4a70fe3-6124-4365-a8a9-fb563bde7877",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:42:25.438982",
        "date": "2024-06-29T19:42:24.670290",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2638",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1dd06022-fe4f-4c40-a986-e024399419c0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381462"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003512",
        "update_ts": "2024-07-01T10:58:24.636511",
        "uuid": "c3ab6b5f-9ef0-4802-af62-2777089b29c1",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:48:58.276402",
        "date": "2024-06-29T13:48:57.745107",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2186",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3ca4863c-b5c9-4d54-bd1a-3510c0b510b2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379767"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002931",
        "update_ts": "2024-06-30T10:46:10.133561",
        "uuid": "b69cf231-1a98-4856-bd22-d9d883cca163",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:19:11.272746",
        "date": "2024-06-29T15:19:10.511440",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2259",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "27e11ab9-84e7-4de9-b1c6-969930f2a4b6",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383085"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003096",
        "update_ts": "2024-06-30T10:04:35.615329",
        "uuid": "ce8814ab-6e8e-4b69-8b99-232a93722f16",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:40:12.810845",
        "date": "2024-06-29T15:40:12.313487",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2292",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5260f405-6040-4844-a75c-0ce7f3d70f9c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379748"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002866",
        "update_ts": "2024-06-30T10:04:35.629244",
        "uuid": "8c018b65-32d1-44c8-ba70-af77411d7c78",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:56:52.915124",
        "date": "2024-06-29T16:56:52.063082",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2386",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f4df6acb-d1f1-47b9-bf47-b6b520144f74",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379226"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003405",
        "update_ts": "2024-06-30T10:46:10.172772",
        "uuid": "07fe7f46-146b-4a01-97f3-b58c2bb5f981",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:57:32.733572",
        "date": "2024-06-29T16:57:32.215810",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2387",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "98f2be8c-1d31-4e5a-9aeb-1214d1009868",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356540"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003404",
        "update_ts": "2024-06-30T10:46:10.173355",
        "uuid": "16067cee-5a19-4fe3-9fb1-4a3829a02453",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:01:01.625785",
        "date": "2024-06-29T17:01:01.080407",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2397",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c495969a-d644-4df2-b872-1286881c8f33",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137367664"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003400",
        "update_ts": "2024-06-30T10:46:10.175315",
        "uuid": "7abf03c7-b95b-4f16-90be-0b9651059b79",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:38:31.324257",
        "date": "2024-06-29T18:38:30.576973",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2500",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "864eaeb3-1040-43d1-bb98-3c944b706d10",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380459"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003542",
        "update_ts": "2024-07-01T10:58:24.618206",
        "uuid": "7566849e-44ce-4637-b07b-43c1ccc0ef59",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:42:21.257352",
        "date": "2024-06-29T18:42:20.604392",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2508",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "350d2118-92da-41ea-985c-28c4d8dea05b",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382142"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003380",
        "update_ts": "2024-06-30T10:46:10.184108",
        "uuid": "35c36708-b930-4d59-acf8-51e1f4b87462",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:48:44.765000",
        "date": "2024-06-29T18:48:44.121043",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2523",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "68ea4b9a-dd27-442f-9602-e6d8e7e97dd5",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382485"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003375",
        "update_ts": "2024-06-30T10:46:10.186028",
        "uuid": "c6b53e38-d626-4937-91f4-5589312e88f5",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:58:52.802738",
        "date": "2024-06-29T18:58:52.173829",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2541",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9f51b1b4-1efc-4849-98a5-9b3a5d06b9ac",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137364894"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003531",
        "update_ts": "2024-07-01T10:58:24.624831",
        "uuid": "27e1447b-de5f-4f4d-8fe0-b694df16384d",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T12:21:36.414228",
        "date": "2024-06-29T12:21:35.757941",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2086",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "57f50bc2-8857-4dcc-b6d5-c1234eb33fbe",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137373843"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002834",
        "update_ts": "2024-06-30T10:46:10.105960",
        "uuid": "904d2df1-ff7b-4e19-b518-ac8f1687947c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:19:56.470038",
        "date": "2024-06-29T15:19:55.801558",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2260",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "bc349761-8101-4e0d-b883-80755ca2b4ee",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383049"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003090",
        "update_ts": "2024-06-30T10:46:10.142991",
        "uuid": "df4bef88-5033-4217-8ea4-622a60255ce7",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:20:51.755843",
        "date": "2024-06-29T15:20:50.877576",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2262",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ad6c5a83-fa86-40e0-9dab-5aeed432f156",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383078"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003089",
        "update_ts": "2024-06-30T10:46:10.143922",
        "uuid": "46cf82a8-90fb-4b76-b9cf-36a5ad6a2cef",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:23:54.981422",
        "date": "2024-06-29T15:23:54.302836",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2268",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "62dd7d53-c387-4971-8ed7-4a05f2c7e6ea",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383050"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003085",
        "update_ts": "2024-06-30T10:46:10.147945",
        "uuid": "f4a84037-1b06-471f-a4ce-55af8228aa38",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:26:50.284202",
        "date": "2024-06-29T15:26:49.605202",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2273",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "221cc06b-128d-424e-8e66-26a0f7aa0948",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "14023671"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003082",
        "update_ts": "2024-06-30T10:46:10.149024",
        "uuid": "7b8aec68-02f6-45b7-be53-ac27fbb45a4f",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:31:35.243237",
        "date": "2024-06-29T15:31:34.781236",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2278",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9f76538b-6350-4c4a-b6cc-ff99be8889ac",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137377759"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003080",
        "update_ts": "2024-06-30T10:46:10.149771",
        "uuid": "d69d0c1a-5ba4-4d95-b7e3-7dfc081f2ca2",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:47:07.477205",
        "date": "2024-06-29T16:47:06.889464",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2379",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "ceb3356e-c6af-479d-92e3-716594f71814",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379743"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003410",
        "update_ts": "2024-06-30T10:46:10.170266",
        "uuid": "fdf7a1c2-f710-4398-a5fc-862735e2684c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:50:24.628436",
        "date": "2024-06-29T16:50:24.018683",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2383",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "db30fda3-73d0-4372-b843-dad43b9f0847",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379747"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003407",
        "update_ts": "2024-06-30T10:46:10.171669",
        "uuid": "bbb42625-bece-481f-8b1d-e9139c4f1a6f",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:58:00.331863",
        "date": "2024-06-29T16:57:59.595461",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2388",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3981d862-08d4-49ae-b39a-c6577b4b5dd6",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379745"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003241",
        "update_ts": "2024-06-30T10:04:35.666693",
        "uuid": "97ec0d7b-934d-400a-a8d5-7561e94107b3",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:38:38.346685",
        "date": "2024-06-29T18:38:37.741284",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2501",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "983a9d2c-cc6e-4e34-84b3-6f7a8041e77f",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380472"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003217",
        "update_ts": "2024-06-30T10:04:35.721255",
        "uuid": "e49ad16a-4f28-4726-a8d2-0095a1521e0e",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:43:41.815390",
        "date": "2024-06-29T19:43:41.110889",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2640",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "699919c8-0ad4-4e38-9fe2-9aa7ac52599e",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379126"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003322",
        "update_ts": "2024-06-30T10:46:10.203459",
        "uuid": "1cfdef7f-502c-44b0-82cf-ee1bea01e0fc",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T13:53:51.916728",
        "date": "2024-06-29T13:53:51.312480",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2195",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "a01cfc79-5ed7-49e9-aaec-942a1cb32959",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379732"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002840",
        "update_ts": "2024-06-30T10:46:10.134864",
        "uuid": "828aab54-9df3-43e1-9627-f4dc9172a692",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:21:48.993861",
        "date": "2024-06-29T15:21:48.247959",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2263",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8868dd15-2a08-4890-99e8-4334adc79747",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383070"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003088",
        "update_ts": "2024-06-30T10:46:10.144859",
        "uuid": "efd69a54-cfea-488c-b05b-0b5ae1ec8cce",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:22:41.902983",
        "date": "2024-06-29T15:22:41.392759",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2265",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "1b4f4d08-51fb-4f0b-b280-657527b8c123",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137377751"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003086",
        "update_ts": "2024-06-30T10:46:10.146296",
        "uuid": "a6b0b932-766e-4bc4-9594-86be3cf2cbc9",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:25:13.590007",
        "date": "2024-06-29T15:25:13.013712",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2270",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e9e9403f-01f0-446d-847e-4551076107ee",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382458"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002875",
        "update_ts": "2024-06-30T10:04:35.617869",
        "uuid": "1e1b5458-b2ec-4021-9ba1-aa9ac213ce1f",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:26:11.287854",
        "date": "2024-06-29T15:26:10.264561",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2272",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f7611dbc-dedd-4bb6-8dc5-c549b2af26be",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381522"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002874",
        "update_ts": "2024-06-30T10:04:35.619593",
        "uuid": "a2f514a5-1b27-44a5-a524-1997cf266580",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:36:57.806607",
        "date": "2024-06-29T15:36:56.861048",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2287",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e7ce80d8-e2c2-46d5-868f-b4cbba698268",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137364901"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003077",
        "update_ts": "2024-06-30T10:46:10.150946",
        "uuid": "92208e07-0c7d-42ac-ae9c-4a16cc9d0194",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:38:15.656527",
        "date": "2024-06-29T15:38:15.028879",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2288",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "46fca55d-fcee-4045-91b1-7ffdbf370549",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137364900"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002868",
        "update_ts": "2024-06-30T10:04:35.627050",
        "uuid": "12065a54-8007-4c70-aaf9-4a9148b3cb78",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:39:18.193207",
        "date": "2024-06-29T15:39:17.227303",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2290",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "59dd5368-f379-48d4-b2ed-897222ad82c0",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379758"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003076",
        "update_ts": "2024-06-30T10:46:10.151271",
        "uuid": "c268deef-f0bd-4dd3-827e-c67441acc088",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:40:29.070652",
        "date": "2024-06-29T15:40:28.475878",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2293",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "148eea56-8c71-4743-a742-11de8e474916",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380491"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003075",
        "update_ts": "2024-06-30T10:46:10.151726",
        "uuid": "d0a4075a-884c-41f1-bcaa-f690380a9cd3",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:55:22.012131",
        "date": "2024-06-29T16:55:21.498950",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2385",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "03823ddf-fffe-4ba1-866a-ee796a8c09d5",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379227"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003406",
        "update_ts": "2024-06-30T10:46:10.172170",
        "uuid": "356deff6-8f60-4014-abfb-770ce670cd6b",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T17:00:05.742047",
        "date": "2024-06-29T17:00:05.190247",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2394",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "615e838b-87e1-44e2-95f6-ed0f2eefbffb",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356536"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003244",
        "update_ts": "2024-06-30T10:04:35.671275",
        "uuid": "a82062c5-6c49-4160-a057-beb5121f478f",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:39:02.108707",
        "date": "2024-06-29T18:39:01.262343",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2502",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "5141275a-163e-4951-9918-b6ce9c4e3864",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382141"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003216",
        "update_ts": "2024-06-30T10:04:35.722032",
        "uuid": "bc4ec003-3539-4db4-8ef3-c3acdb028763",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:45:36.742595",
        "date": "2024-06-29T18:45:36.008660",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2516",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6b280234-80ab-435b-a801-1fc9c66b61b4",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380485"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003378",
        "update_ts": "2024-06-30T10:46:10.184856",
        "uuid": "3b795d28-d8d6-4973-aca2-be6e43ab9d76",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:55:33.412002",
        "date": "2024-06-29T18:55:32.784811",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2535",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "7b756ba8-2a32-4191-8221-2fc57f7c77f8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382136"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003371",
        "update_ts": "2024-06-30T10:46:10.187545",
        "uuid": "ac2e5186-61e0-4e64-8999-cf271e9fe49b",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:00:23.816941",
        "date": "2024-06-29T19:00:23.111325",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2545",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "946d776c-c2c2-4508-8205-29f9f085c8e2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382133"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003368",
        "update_ts": "2024-06-30T10:46:10.188714",
        "uuid": "3669ce9b-7657-47f3-8ec5-e35243b2484c",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:01:03.574758",
        "date": "2024-06-29T19:01:02.985757",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2546",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f2923525-0aa5-4af3-956b-040c6c42cccc",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382131"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003202",
        "update_ts": "2024-06-30T10:04:35.725185",
        "uuid": "c8a84996-5315-4616-823e-c7fc2510ba48",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:44:37.123900",
        "date": "2024-06-29T19:44:36.131651",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2641",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "48fcae80-f7cd-4bbe-ab46-2cf3b7ca7661",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383053"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003323",
        "update_ts": "2024-06-30T10:46:10.203816",
        "uuid": "8289d196-0b6a-4660-9f82-8cc22322584b",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:22:43.582589",
        "date": "2024-06-29T15:22:43.039239",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2266",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4a5ffcf8-132d-47e8-978b-9d0086ffc2a4",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382119"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002876",
        "update_ts": "2024-06-30T10:04:35.616818",
        "uuid": "3e48283c-c8c0-43c2-ae45-9ae5ff3a5965",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:23:18.831548",
        "date": "2024-06-29T15:23:18.159004",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2267",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "b3e631ee-6191-430a-9801-f5bb91d0d776",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383063"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003087",
        "update_ts": "2024-06-30T10:46:10.147541",
        "uuid": "853d8f66-ab39-4e3a-a24c-c0827f5c11f4",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:25:03.004616",
        "date": "2024-06-29T15:25:02.454568",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2269",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "99cdc306-fe6f-451e-9352-0e78824997f2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383051"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003084",
        "update_ts": "2024-06-30T10:46:10.148293",
        "uuid": "971d663c-e8e5-4e43-b602-1e778f23355e",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:33:39.628112",
        "date": "2024-06-29T15:33:37.646717",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2282",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "747e0a42-edd4-4093-9b77-27abc608a9f8",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "14023670"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003078",
        "update_ts": "2024-06-30T10:46:10.150580",
        "uuid": "cd980f40-99ed-4854-91c1-dcb63de260b2",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:38:59.239591",
        "date": "2024-06-29T15:38:58.752870",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2289",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "6a4ee3f5-9799-4c95-878a-320461d4c3d2",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137381507"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002867",
        "update_ts": "2024-06-30T10:04:35.627988",
        "uuid": "085884a2-ec2b-4aa0-874b-839607f1fe1a",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:41:11.647010",
        "date": "2024-06-29T15:41:11.115159",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2294",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "594904e4-e896-4b62-8eab-05aca5ccc979",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137364902"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0002865",
        "update_ts": "2024-06-30T10:04:35.630253",
        "uuid": "4b157193-bdbf-4c1a-8d12-984b930000fa",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T15:42:07.742360",
        "date": "2024-06-29T15:42:07.218532",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2295",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "4ece1800-6cdc-4fae-b813-22c51e1fe007",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380489"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003074",
        "update_ts": "2024-06-30T10:46:10.152047",
        "uuid": "82bb82c0-c21d-41d5-9a2c-a3a6602c6143",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:58:02.703230",
        "date": "2024-06-29T16:58:01.959078",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2389",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "c8dbd2c2-88e7-4280-b125-99ddfcc5ecdc",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356311"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003403",
        "update_ts": "2024-06-30T10:46:10.173776",
        "uuid": "4eebd078-8816-4c9b-bf3e-e9f598b8df91",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:58:33.164818",
        "date": "2024-06-29T16:58:32.506507",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2390",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "8fc9c220-82ba-4396-8133-67af5baa4684",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379223"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003242",
        "update_ts": "2024-06-30T10:04:35.668304",
        "uuid": "7fc47e87-0760-4b4a-ab78-f32a06a3a0db",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T16:59:22.562182",
        "date": "2024-06-29T16:59:21.952975",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2392",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "9a14affe-971e-4f07-be23-3ba09bd4f336",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137356539"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003402",
        "update_ts": "2024-06-30T10:46:10.174308",
        "uuid": "4a0c15de-7514-43fc-bd5a-fdb9663439c6",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:40:39.105355",
        "date": "2024-06-29T18:40:38.498706",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2504",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "205b0999-3088-473b-8dd0-4dd5bd181c97",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137376088"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003541",
        "update_ts": "2024-07-01T10:58:24.618868",
        "uuid": "584ac601-3471-4527-bf34-463bd1abd69f",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:46:31.865417",
        "date": "2024-06-29T18:46:31.049909",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2519",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "33ef3a28-c68d-40a0-80ce-69ca0e346a75",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380498"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003377",
        "update_ts": "2024-06-30T10:46:10.185262",
        "uuid": "8f6a471f-9cc7-4aee-aca2-b4de7564941b",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:47:19.011858",
        "date": "2024-06-29T18:47:18.387509",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2520",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f4068b77-7b5a-4d3d-acc7-3534c37e7f9a",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137380394"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003537",
        "update_ts": "2024-07-01T10:58:24.621042",
        "uuid": "4dd8e878-b4bd-4f63-b358-09ae0569d65a",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:51:04.433229",
        "date": "2024-06-29T18:51:03.773282",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2526",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "79b50099-f481-42cd-98bf-f37884136e8d",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137364893"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003535",
        "update_ts": "2024-07-01T10:58:24.622667",
        "uuid": "3e09e7aa-91ae-4472-a904-5c3b221a683a",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:57:07.552121",
        "date": "2024-06-29T18:57:06.930021",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2537",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "e33e59b9-42d9-4b2c-8f43-af1142498a9c",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379155"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003205",
        "update_ts": "2024-06-30T10:04:35.724074",
        "uuid": "f570fdcb-7975-4fbe-aa92-6455a5d2b948",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:57:25.207643",
        "date": "2024-06-29T18:57:24.567037",
        "executor": {
          "enabled": true,
          "id": "3fb377eb-909f-46d1-b60d-a89ea3d69bf2",
          "login": "6552",
          "name": "Д",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2539",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "49cd8307-a5c7-4046-8a9b-4b119d694cac",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137376089"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003532",
        "update_ts": "2024-07-01T10:58:24.624226",
        "uuid": "460bfca8-0208-424a-a4f5-ebf011b6b4a0",
        "workspace": {
          "barcode": "-",
          "condition": "GOOD",
          "id": "a19a2adb-9a16-4535-94bf-411bddbce65d",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "-"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:58:36.773087",
        "date": "2024-06-29T18:58:36.172416",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2540",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "f6fbdb72-5dbc-42ec-9dfe-663da8d79357",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137379716"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003204",
        "update_ts": "2024-06-30T10:04:35.724464",
        "uuid": "85838988-09cd-455f-b63c-3a3313d7c356",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:59:17.916212",
        "date": "2024-06-29T18:59:17.271077",
        "executor": {
          "enabled": true,
          "id": "335f7f36-d70c-4967-876d-8e87d9286a58",
          "login": "1643",
          "name": "Евгений",
          "position": "SHIFT_SUPERVISOR",
          "roles": [
            "c4064095-e79a-4045-9d62-73216ac58ca8",
            "0b1f507f-f789-4570-9812-8b5d47c6c2f3"
          ],
          "surname": "Махов"
        },
        "id": "RT-0Q-0000-2542",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "3c2e75a3-94bb-41a0-9e97-84a0f75f10fc",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382134"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003369",
        "update_ts": "2024-06-30T10:46:10.188372",
        "uuid": "3649b965-614c-4dda-bd4f-3c52b34227de",
        "workspace": {
          "barcode": "VO-18",
          "condition": "GOOD",
          "id": "974c60ad-aabb-4b30-93c4-8d6f39f8a36b",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-18",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T18:59:54.587973",
        "date": "2024-06-29T18:59:53.938860",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2543",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "45ab4ad1-4782-47c4-b2bd-b21e9e448120",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137382140"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003203",
        "update_ts": "2024-06-30T10:04:35.724828",
        "uuid": "37796ddb-0440-4d2f-b298-8b593294e9e3",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      },
      {
        "activated": true,
        "condition": "GOOD",
        "create_ts": "2024-06-29T19:05:34.569548",
        "date": "2024-06-29T19:05:33.891309",
        "executor": {
          "enabled": true,
          "id": "ce7a51c6-8951-4391-9468-b660d09ec2d9",
          "login": "5200",
          "name": "А",
          "position": "OPERATOR",
          "roles": [
            "459a9682-cc26-4ba5-a278-db938c15be71"
          ],
          "surname": "Казанцева"
        },
        "id": "RT-0Q-0000-2550",
        "items": [
          {
            "acceptance_type": "BOX",
            "id": "164fc02c-d050-4fcd-894f-d0581c10dfa9",
            "multiplicity": 1,
            "quantity": 1,
            "serial_numbers": [],
            "trust_acceptance_box_barcode": "137383056"
          }
        ],
        "merchant": {
          "cam_id": "71156",
          "contract_date": "2023-10-16",
          "contract_num": "КО-ОП- 16/10/2023-293",
          "enabled": true,
          "id": "75d776a1-1038-4837-b62f-8de5b76de458",
          "inn": "7709505477",
          "kpp": "774950001",
          "login": "e.generalova@taber.ru",
          "ogrn": "1037739861851",
          "service_group_set_id": "2086eb6b-a256-4831-8575-ae8a4fe788d1",
          "title": "Табер Трейд \"Подружка\"",
          "trade_title": "Подружка"
        },
        "status": "DONE",
        "tare_barcode": "TARA0003206",
        "update_ts": "2024-06-30T10:04:35.725582",
        "uuid": "055bf6e0-6043-43ac-ae13-571c92854864",
        "workspace": {
          "barcode": "VO-19",
          "condition": "GOOD",
          "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
          "printer_settings": [
            {
              "name": "PRINTER_A4",
              "type": "A4"
            },
            {
              "name": "STICKER_6x6",
              "type": "STICKER_6x6"
            },
            {
              "name": "STICKER_10X15",
              "type": "STICKER_10x15"
            }
          ],
          "title": "VO-19",
          "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
        }
      }
    ],
    "status": "PROCESSING",
    "supplier": {
      "id": "b6a22d5b-462e-4c13-936b-f56a4c514af8",
      "inn": "7709505477",
      "kpp": "774950001",
      "title": "Табер Трейд ООО"
    },
    "supply_form": [],
    "supply_type": "DEFAULT",
    "trust_acceptance_boxes": [
      {
        "barcode": "137377759",
        "items": [
          {
            "quantity": 3,
            "sku_id": "0Q-26713"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-226970"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-229201"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-227041"
          }
        ]
      },
      {
        "barcode": "137380458",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-204700"
          }
        ]
      },
      {
        "barcode": "137379745",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-233412"
          },
          {
            "quantity": 48,
            "sku_id": "0Q-84361"
          }
        ]
      },
      {
        "barcode": "137380485",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-229163"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-228318"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-143094"
          },
          {
            "quantity": 9,
            "sku_id": "0Q-210421"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-215105"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-154970"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-228321"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-196098"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-227041"
          },
          {
            "quantity": 33,
            "sku_id": "0Q-93944"
          }
        ]
      },
      {
        "barcode": "137352677",
        "items": [
          {
            "quantity": 42,
            "sku_id": "0Q-152661"
          }
        ]
      },
      {
        "barcode": "137356522",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-219429"
          }
        ]
      },
      {
        "barcode": "137356316",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-219415"
          }
        ]
      },
      {
        "barcode": "137382139",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-209761"
          }
        ]
      },
      {
        "barcode": "137382136",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-209761"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-233412"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-227050"
          }
        ]
      },
      {
        "barcode": "137381515",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-68478"
          }
        ]
      },
      {
        "barcode": "137367662",
        "items": [
          {
            "quantity": 9,
            "sku_id": "0Q-121441"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-226973"
          }
        ]
      },
      {
        "barcode": "137373854",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137383059",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-226970"
          }
        ]
      },
      {
        "barcode": "137383070",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-173306"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-190460"
          },
          {
            "quantity": 16,
            "sku_id": "0Q-222991"
          }
        ]
      },
      {
        "barcode": "137379225",
        "items": [
          {
            "quantity": 23,
            "sku_id": "0Q-242850"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-234151"
          },
          {
            "quantity": 17,
            "sku_id": "0Q-242244"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242854"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-241002"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-206216"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-241005"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-241000"
          },
          {
            "quantity": 9,
            "sku_id": "0Q-220578"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242853"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-196420"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242849"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-241001"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-124408"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242848"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-241003"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-201419"
          }
        ]
      },
      {
        "barcode": "137380392",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-166470"
          }
        ]
      },
      {
        "barcode": "137381472",
        "items": [
          {
            "quantity": 50,
            "sku_id": "0Q-118454"
          }
        ]
      },
      {
        "barcode": "137379693",
        "items": [
          {
            "quantity": 30,
            "sku_id": "0Q-121441"
          }
        ]
      },
      {
        "barcode": "137352670",
        "items": [
          {
            "quantity": 18,
            "sku_id": "0Q-55953"
          },
          {
            "quantity": 36,
            "sku_id": "0Q-170638"
          },
          {
            "quantity": 27,
            "sku_id": "0Q-196441"
          }
        ]
      },
      {
        "barcode": "137383075",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-69312"
          }
        ]
      },
      {
        "barcode": "137356540",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-206286"
          }
        ]
      },
      {
        "barcode": "137364892",
        "items": [
          {
            "quantity": 54,
            "sku_id": "0Q-147680"
          }
        ]
      },
      {
        "barcode": "137381471",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-149358"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-196076"
          }
        ]
      },
      {
        "barcode": "137374354",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-206849"
          },
          {
            "quantity": 36,
            "sku_id": "0Q-166529"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245850"
          },
          {
            "quantity": 19,
            "sku_id": "0Q-213381"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-185422"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245852"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-215265"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-237877"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-166520"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245848"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-240992"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245853"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-201704"
          }
        ]
      },
      {
        "barcode": "137379736",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-229199"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-227025"
          }
        ]
      },
      {
        "barcode": "137356533",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-88592"
          }
        ]
      },
      {
        "barcode": "137356319",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-219429"
          }
        ]
      },
      {
        "barcode": "137373839",
        "items": [
          {
            "quantity": 17,
            "sku_id": "0Q-242248"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-226968"
          }
        ]
      },
      {
        "barcode": "137380479",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-141296"
          }
        ]
      },
      {
        "barcode": "137377751",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227045"
          },
          {
            "quantity": 32,
            "sku_id": "0Q-242246"
          }
        ]
      },
      {
        "barcode": "137382150",
        "items": [
          {
            "quantity": 20,
            "sku_id": "0Q-159466"
          }
        ]
      },
      {
        "barcode": "137381479",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-155187"
          }
        ]
      },
      {
        "barcode": "137367671",
        "items": [
          {
            "quantity": 72,
            "sku_id": "0Q-38924"
          }
        ]
      },
      {
        "barcode": "137381485",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-155187"
          }
        ]
      },
      {
        "barcode": "137352685",
        "items": [
          {
            "quantity": 35,
            "sku_id": "0Q-43003"
          }
        ]
      },
      {
        "barcode": "137380491",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-220805"
          }
        ]
      },
      {
        "barcode": "137383061",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-227044"
          }
        ]
      },
      {
        "barcode": "137381526",
        "items": [
          {
            "quantity": 22,
            "sku_id": "0Q-223133"
          }
        ]
      },
      {
        "barcode": "137381481",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-139578"
          }
        ]
      },
      {
        "barcode": "137383066",
        "items": [
          {
            "quantity": 11,
            "sku_id": "0Q-227065"
          }
        ]
      },
      {
        "barcode": "137367657",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-123904"
          }
        ]
      },
      {
        "barcode": "137379155",
        "items": [
          {
            "quantity": 15,
            "sku_id": "0Q-219471"
          },
          {
            "quantity": 35,
            "sku_id": "0Q-231822"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-170639"
          }
        ]
      },
      {
        "barcode": "137373867",
        "items": [
          {
            "quantity": 11,
            "sku_id": "0Q-103996"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-206154"
          }
        ]
      },
      {
        "barcode": "137352676",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-133898"
          },
          {
            "quantity": 36,
            "sku_id": "0Q-213822"
          }
        ]
      },
      {
        "barcode": "137381518",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-224424"
          }
        ]
      },
      {
        "barcode": "137383071",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-222991"
          }
        ]
      },
      {
        "barcode": "137356536",
        "items": [
          {
            "quantity": 36,
            "sku_id": "0Q-223180"
          }
        ]
      },
      {
        "barcode": "137380493",
        "items": [
          {
            "quantity": 4,
            "sku_id": "0Q-220805"
          }
        ]
      },
      {
        "barcode": "137364894",
        "items": [
          {
            "quantity": 36,
            "sku_id": "0Q-159332"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-159466"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-227042"
          },
          {
            "quantity": 26,
            "sku_id": "0Q-196364"
          }
        ]
      },
      {
        "barcode": "137382127",
        "items": [
          {
            "quantity": 36,
            "sku_id": "0Q-220694"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-160434"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-202999"
          },
          {
            "quantity": 21,
            "sku_id": "0Q-191901"
          },
          {
            "quantity": 13,
            "sku_id": "0Q-154669"
          },
          {
            "quantity": 9,
            "sku_id": "0Q-191904"
          }
        ]
      },
      {
        "barcode": "137352688",
        "items": [
          {
            "quantity": 28,
            "sku_id": "0Q-227141"
          }
        ]
      },
      {
        "barcode": "137382123",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-121443"
          }
        ]
      },
      {
        "barcode": "137379138",
        "items": [
          {
            "quantity": 18,
            "sku_id": "0Q-241961"
          },
          {
            "quantity": 22,
            "sku_id": "0Q-241922"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-223520"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-225609"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-129329"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-200216"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-219471"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-125227"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-167080"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-227045"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-203428"
          }
        ]
      },
      {
        "barcode": "137374353",
        "items": [
          {
            "quantity": 30,
            "sku_id": "0Q-228357"
          },
          {
            "quantity": 80,
            "sku_id": "0Q-206936"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245842"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-213385"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245843"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-232744"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245838"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245845"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245840"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245846"
          }
        ]
      },
      {
        "barcode": "137379227",
        "items": [
          {
            "quantity": 23,
            "sku_id": "0Q-242856"
          },
          {
            "quantity": 22,
            "sku_id": "0Q-191919"
          },
          {
            "quantity": 16,
            "sku_id": "0Q-196064"
          },
          {
            "quantity": 135,
            "sku_id": "0Q-169249"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-217684"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-222598"
          }
        ]
      },
      {
        "barcode": "137380396",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-111462"
          }
        ]
      },
      {
        "barcode": "137373855",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137383054",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-229201"
          }
        ]
      },
      {
        "barcode": "137379751",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-241950"
          }
        ]
      },
      {
        "barcode": "137379130",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-57905"
          },
          {
            "quantity": 25,
            "sku_id": "0Q-210216"
          }
        ]
      },
      {
        "barcode": "137367674",
        "items": [
          {
            "quantity": 72,
            "sku_id": "0Q-38924"
          }
        ]
      },
      {
        "barcode": "14023118",
        "items": [
          {
            "quantity": 52,
            "sku_id": "0Q-211873"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-243718"
          },
          {
            "quantity": 37,
            "sku_id": "0Q-211872"
          },
          {
            "quantity": 41,
            "sku_id": "0Q-211853"
          }
        ]
      },
      {
        "barcode": "137379117",
        "items": [
          {
            "quantity": 3,
            "sku_id": "0Q-85825"
          },
          {
            "quantity": 17,
            "sku_id": "0Q-245258"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-159329"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-204870"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-227044"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-55953"
          }
        ]
      },
      {
        "barcode": "137352673",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-232577"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-228318"
          }
        ]
      },
      {
        "barcode": "137380489",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-220805"
          }
        ]
      },
      {
        "barcode": "137379731",
        "items": [
          {
            "quantity": 21,
            "sku_id": "0Q-123881"
          }
        ]
      },
      {
        "barcode": "137380459",
        "items": [
          {
            "quantity": 4,
            "sku_id": "0Q-124609"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-228318"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-206442"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-210955"
          },
          {
            "quantity": 9,
            "sku_id": "0Q-234987"
          },
          {
            "quantity": 22,
            "sku_id": "0Q-154453"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-150473"
          }
        ]
      },
      {
        "barcode": "137380469",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-228614"
          },
          {
            "quantity": 42,
            "sku_id": "0Q-196058"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-227065"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-227142"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-93945"
          }
        ]
      },
      {
        "barcode": "137383085",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-192039"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-227723"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-227043"
          }
        ]
      },
      {
        "barcode": "137379706",
        "items": [
          {
            "quantity": 98,
            "sku_id": "0Q-141324"
          }
        ]
      },
      {
        "barcode": "137382143",
        "items": [
          {
            "quantity": 20,
            "sku_id": "0Q-129331"
          }
        ]
      },
      {
        "barcode": "137383079",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-228188"
          }
        ]
      },
      {
        "barcode": "137379153",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-227045"
          }
        ]
      },
      {
        "barcode": "137381487",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-155187"
          }
        ]
      },
      {
        "barcode": "137381507",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-210317"
          }
        ]
      },
      {
        "barcode": "137352684",
        "items": [
          {
            "quantity": 14,
            "sku_id": "0Q-227128"
          }
        ]
      },
      {
        "barcode": "137380483",
        "items": [
          {
            "quantity": 20,
            "sku_id": "0Q-228318"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-227048"
          }
        ]
      },
      {
        "barcode": "137381482",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-155187"
          }
        ]
      },
      {
        "barcode": "137379743",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-233412"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-227066"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-216053"
          }
        ]
      },
      {
        "barcode": "137379763",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-234988"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-226296"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-223004"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-231673"
          }
        ]
      },
      {
        "barcode": "137381461",
        "items": [
          {
            "quantity": 32,
            "sku_id": "0Q-237947"
          }
        ]
      },
      {
        "barcode": "137379135",
        "items": [
          {
            "quantity": 85,
            "sku_id": "0Q-188401"
          }
        ]
      },
      {
        "barcode": "137379755",
        "items": [
          {
            "quantity": 10,
            "sku_id": "0Q-220805"
          }
        ]
      },
      {
        "barcode": "137381511",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-233411"
          }
        ]
      },
      {
        "barcode": "137364887",
        "items": [
          {
            "quantity": 72,
            "sku_id": "0Q-140136"
          }
        ]
      },
      {
        "barcode": "137379226",
        "items": [
          {
            "quantity": 37,
            "sku_id": "0Q-173592"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-191920"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242337"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242338"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-189932"
          },
          {
            "quantity": 17,
            "sku_id": "0Q-206082"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-223239"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242855"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242333"
          }
        ]
      },
      {
        "barcode": "14023675",
        "items": [
          {
            "quantity": 21,
            "sku_id": "0Q-229202"
          }
        ]
      },
      {
        "barcode": "137356313",
        "items": [
          {
            "quantity": 10,
            "sku_id": "0Q-219415"
          }
        ]
      },
      {
        "barcode": "137379737",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227025"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137373860",
        "items": [
          {
            "quantity": 23,
            "sku_id": "0Q-206154"
          }
        ]
      },
      {
        "barcode": "137352663",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-213309"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-227035"
          }
        ]
      },
      {
        "barcode": "14023670",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-226966"
          },
          {
            "quantity": 42,
            "sku_id": "0Q-227140"
          }
        ]
      },
      {
        "barcode": "137356310",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-230121"
          }
        ]
      },
      {
        "barcode": "137367678",
        "items": [
          {
            "quantity": 26,
            "sku_id": "0Q-219440"
          }
        ]
      },
      {
        "barcode": "137383046",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-190989"
          }
        ]
      },
      {
        "barcode": "137367660",
        "items": [
          {
            "quantity": 1,
            "sku_id": "0Q-210902"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-226966"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-138846"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-234403"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-228050"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-138825"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-227070"
          }
        ]
      },
      {
        "barcode": "137380490",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-220805"
          }
        ]
      },
      {
        "barcode": "137379730",
        "items": [
          {
            "quantity": 28,
            "sku_id": "0Q-121450"
          }
        ]
      },
      {
        "barcode": "137367649",
        "items": [
          {
            "quantity": 20,
            "sku_id": "0Q-204542"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-203420"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-210417"
          },
          {
            "quantity": 26,
            "sku_id": "0Q-185834"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-150473"
          }
        ]
      },
      {
        "barcode": "137382486",
        "items": [
          {
            "quantity": 1,
            "sku_id": "0Q-229189"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-242199"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-229191"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-191894"
          },
          {
            "quantity": 30,
            "sku_id": "0Q-221407"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-226776"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-204544"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-49935"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-162908"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-204823"
          }
        ]
      },
      {
        "barcode": "137383077",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-187325"
          }
        ]
      },
      {
        "barcode": "137382134",
        "items": [
          {
            "quantity": 27,
            "sku_id": "0Q-93944"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-56151"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-226964"
          },
          {
            "quantity": 9,
            "sku_id": "0Q-166807"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-164237"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-177583"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-243522"
          }
        ]
      },
      {
        "barcode": "137381160",
        "items": [
          {
            "quantity": 32,
            "sku_id": "0Q-237947"
          }
        ]
      },
      {
        "barcode": "137379139",
        "items": [
          {
            "quantity": 1,
            "sku_id": "0Q-189932"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-73714"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-177581"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-228308"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-242170"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-159330"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-111111"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-234403"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-241931"
          }
        ]
      },
      {
        "barcode": "137356525",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-84360"
          }
        ]
      },
      {
        "barcode": "14023673",
        "items": [
          {
            "quantity": 9,
            "sku_id": "0Q-229202"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-13650"
          }
        ]
      },
      {
        "barcode": "137356521",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-219429"
          }
        ]
      },
      {
        "barcode": "137381476",
        "items": [
          {
            "quantity": 4,
            "sku_id": "0Q-227071"
          }
        ]
      },
      {
        "barcode": "137382126",
        "items": [
          {
            "quantity": 23,
            "sku_id": "0Q-238358"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-74105"
          },
          {
            "quantity": 36,
            "sku_id": "0Q-153263"
          }
        ]
      },
      {
        "barcode": "137379769",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-146602"
          }
        ]
      },
      {
        "barcode": "137380397",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-111462"
          }
        ]
      },
      {
        "barcode": "137352674",
        "items": [
          {
            "quantity": 10,
            "sku_id": "0Q-228318"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-77693"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-227042"
          }
        ]
      },
      {
        "barcode": "137373856",
        "items": [
          {
            "quantity": 5,
            "sku_id": "0Q-227043"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-230081"
          }
        ]
      },
      {
        "barcode": "137367653",
        "items": [
          {
            "quantity": 4,
            "sku_id": "0Q-219441"
          },
          {
            "quantity": 19,
            "sku_id": "0Q-221429"
          }
        ]
      },
      {
        "barcode": "137379741",
        "items": [
          {
            "quantity": 25,
            "sku_id": "0Q-242247"
          }
        ]
      },
      {
        "barcode": "137367661",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-226973"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-189711"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-125477"
          }
        ]
      },
      {
        "barcode": "137380496",
        "items": [
          {
            "quantity": 14,
            "sku_id": "0Q-226961"
          }
        ]
      },
      {
        "barcode": "137379124",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-121443"
          }
        ]
      },
      {
        "barcode": "137356543",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-219118"
          }
        ]
      },
      {
        "barcode": "137356315",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-219415"
          }
        ]
      },
      {
        "barcode": "137383052",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-229201"
          }
        ]
      },
      {
        "barcode": "137381489",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-155187"
          }
        ]
      },
      {
        "barcode": "137380447",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-120533"
          },
          {
            "quantity": 19,
            "sku_id": "0Q-38924"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-219440"
          }
        ]
      },
      {
        "barcode": "137380487",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-220805"
          }
        ]
      },
      {
        "barcode": "137379748",
        "items": [
          {
            "quantity": 3,
            "sku_id": "0Q-227066"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-242247"
          }
        ]
      },
      {
        "barcode": "137356314",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-219415"
          }
        ]
      },
      {
        "barcode": "137364902",
        "items": [
          {
            "quantity": 13,
            "sku_id": "0Q-103996"
          }
        ]
      },
      {
        "barcode": "137381462",
        "items": [
          {
            "quantity": 32,
            "sku_id": "0Q-237947"
          }
        ]
      },
      {
        "barcode": "137383082",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-228309"
          }
        ]
      },
      {
        "barcode": "137373857",
        "items": [
          {
            "quantity": 15,
            "sku_id": "0Q-12730"
          }
        ]
      },
      {
        "barcode": "137364901",
        "items": [
          {
            "quantity": 50,
            "sku_id": "0Q-138838"
          }
        ]
      },
      {
        "barcode": "137379747",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227066"
          }
        ]
      },
      {
        "barcode": "137356320",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-219429"
          }
        ]
      },
      {
        "barcode": "137373845",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137367667",
        "items": [
          {
            "quantity": 47,
            "sku_id": "0Q-138251"
          }
        ]
      },
      {
        "barcode": "137352678",
        "items": [
          {
            "quantity": 42,
            "sku_id": "0Q-152661"
          }
        ]
      },
      {
        "barcode": "137380445",
        "items": [
          {
            "quantity": 72,
            "sku_id": "0Q-38924"
          }
        ]
      },
      {
        "barcode": "137380393",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-211040"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-166470"
          }
        ]
      },
      {
        "barcode": "137352659",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-170637"
          }
        ]
      },
      {
        "barcode": "14023674",
        "items": [
          {
            "quantity": 22,
            "sku_id": "0Q-229202"
          }
        ]
      },
      {
        "barcode": "137383063",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-204699"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-227065"
          }
        ]
      },
      {
        "barcode": "137382457",
        "items": [
          {
            "quantity": 72,
            "sku_id": "0Q-12407"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-55953"
          }
        ]
      },
      {
        "barcode": "137367655",
        "items": [
          {
            "quantity": 36,
            "sku_id": "0Q-38924"
          },
          {
            "quantity": 36,
            "sku_id": "0Q-93946"
          }
        ]
      },
      {
        "barcode": "137379746",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-227066"
          }
        ]
      },
      {
        "barcode": "137383047",
        "items": [
          {
            "quantity": 14,
            "sku_id": "0Q-221427"
          }
        ]
      },
      {
        "barcode": "137381524",
        "items": [
          {
            "quantity": 22,
            "sku_id": "0Q-223133"
          }
        ]
      },
      {
        "barcode": "137379127",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-121443"
          }
        ]
      },
      {
        "barcode": "137379129",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-121443"
          }
        ]
      },
      {
        "barcode": "137379716",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-219439"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-219440"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-213549"
          }
        ]
      },
      {
        "barcode": "137373846",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137356544",
        "items": [
          {
            "quantity": 16,
            "sku_id": "0Q-191885"
          },
          {
            "quantity": 9,
            "sku_id": "0Q-210316"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-208956"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-173572"
          }
        ]
      },
      {
        "barcode": "137379761",
        "items": [
          {
            "quantity": 19,
            "sku_id": "0Q-238352"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-170282"
          }
        ]
      },
      {
        "barcode": "137379750",
        "items": [
          {
            "quantity": 45,
            "sku_id": "0Q-141324"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-241950"
          }
        ]
      },
      {
        "barcode": "137382465",
        "items": [
          {
            "quantity": 13,
            "sku_id": "0Q-201031"
          },
          {
            "quantity": 18,
            "sku_id": "0Q-12407"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-213843"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-188401"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-243696"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-55953"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-206459"
          },
          {
            "quantity": 18,
            "sku_id": "0Q-237684"
          }
        ]
      },
      {
        "barcode": "137380453",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-221429"
          }
        ]
      },
      {
        "barcode": "137382119",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-160221"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-196440"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-55953"
          }
        ]
      },
      {
        "barcode": "137383056",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-229201"
          }
        ]
      },
      {
        "barcode": "137380494",
        "items": [
          {
            "quantity": 50,
            "sku_id": "0Q-206442"
          }
        ]
      },
      {
        "barcode": "137379744",
        "items": [
          {
            "quantity": 15,
            "sku_id": "0Q-233412"
          }
        ]
      },
      {
        "barcode": "137379766",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-227066"
          }
        ]
      },
      {
        "barcode": "137382144",
        "items": [
          {
            "quantity": 20,
            "sku_id": "0Q-129331"
          }
        ]
      },
      {
        "barcode": "137376092",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-158598"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-227041"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-227044"
          }
        ]
      },
      {
        "barcode": "137367676",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-125042"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-00437"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-245259"
          },
          {
            "quantity": 13,
            "sku_id": "0Q-239216"
          },
          {
            "quantity": 18,
            "sku_id": "0Q-191902"
          }
        ]
      },
      {
        "barcode": "137352671",
        "items": [
          {
            "quantity": 16,
            "sku_id": "0Q-196065"
          },
          {
            "quantity": 25,
            "sku_id": "0Q-70351"
          },
          {
            "quantity": 27,
            "sku_id": "0Q-200216"
          }
        ]
      },
      {
        "barcode": "137381520",
        "items": [
          {
            "quantity": 50,
            "sku_id": "0Q-223737"
          }
        ]
      },
      {
        "barcode": "137356531",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-12793"
          }
        ]
      },
      {
        "barcode": "137379125",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-121443"
          }
        ]
      },
      {
        "barcode": "137373849",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137379692",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-210418"
          }
        ]
      },
      {
        "barcode": "137379765",
        "items": [
          {
            "quantity": 20,
            "sku_id": "0Q-147373"
          }
        ]
      },
      {
        "barcode": "137367654",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-219441"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-28175"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-109334"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-227033"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-93946"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-227070"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-228306"
          }
        ]
      },
      {
        "barcode": "137383058",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-226970"
          }
        ]
      },
      {
        "barcode": "137364899",
        "items": [
          {
            "quantity": 50,
            "sku_id": "0Q-138838"
          }
        ]
      },
      {
        "barcode": "137380451",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-210317"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-212882"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-204703"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-228050"
          }
        ]
      },
      {
        "barcode": "137364898",
        "items": [
          {
            "quantity": 60,
            "sku_id": "0Q-156587"
          }
        ]
      },
      {
        "barcode": "14023672",
        "items": [
          {
            "quantity": 13,
            "sku_id": "0Q-13650"
          },
          {
            "quantity": 18,
            "sku_id": "0Q-225777"
          }
        ]
      },
      {
        "barcode": "137356542",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-229949"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-167086"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-206286"
          }
        ]
      },
      {
        "barcode": "137367663",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-213825"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-209598"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-210217"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-00526"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-228306"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-121441"
          }
        ]
      },
      {
        "barcode": "137379696",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-133905"
          }
        ]
      },
      {
        "barcode": "137383073",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-226293"
          }
        ]
      },
      {
        "barcode": "137380466",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-210430"
          }
        ]
      },
      {
        "barcode": "137356535",
        "items": [
          {
            "quantity": 39,
            "sku_id": "0Q-223180"
          }
        ]
      },
      {
        "barcode": "137376089",
        "items": [
          {
            "quantity": 60,
            "sku_id": "0Q-231679"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-231460"
          },
          {
            "quantity": 13,
            "sku_id": "0Q-233388"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-227720"
          }
        ]
      },
      {
        "barcode": "137382122",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-121443"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-212301"
          }
        ]
      },
      {
        "barcode": "137379767",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-226296"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-204703"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-208958"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-205371"
          }
        ]
      },
      {
        "barcode": "137356317",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-219415"
          }
        ]
      },
      {
        "barcode": "137373847",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137376088",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-217211"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-231460"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-227022"
          }
        ]
      },
      {
        "barcode": "137381475",
        "items": [
          {
            "quantity": 4,
            "sku_id": "0Q-227071"
          }
        ]
      },
      {
        "barcode": "137379158",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-125475"
          }
        ]
      },
      {
        "barcode": "137381517",
        "items": [
          {
            "quantity": 1,
            "sku_id": "0Q-134916"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-224424"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-210317"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-233411"
          }
        ]
      },
      {
        "barcode": "137382479",
        "items": [
          {
            "quantity": 87,
            "sku_id": "0Q-201031"
          }
        ]
      },
      {
        "barcode": "137367664",
        "items": [
          {
            "quantity": 96,
            "sku_id": "0Q-209598"
          }
        ]
      },
      {
        "barcode": "137383048",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-08730"
          }
        ]
      },
      {
        "barcode": "137352680",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-139077"
          }
        ]
      },
      {
        "barcode": "137382125",
        "items": [
          {
            "quantity": 145,
            "sku_id": "0Q-213310"
          }
        ]
      },
      {
        "barcode": "137356539",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-206286"
          }
        ]
      },
      {
        "barcode": "137356541",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-206286"
          }
        ]
      },
      {
        "barcode": "137356537",
        "items": [
          {
            "quantity": 36,
            "sku_id": "0Q-223180"
          }
        ]
      },
      {
        "barcode": "137380477",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-111460"
          }
        ]
      },
      {
        "barcode": "137379738",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137379728",
        "items": [
          {
            "quantity": 25,
            "sku_id": "0Q-229199"
          }
        ]
      },
      {
        "barcode": "137381522",
        "items": [
          {
            "quantity": 50,
            "sku_id": "0Q-137070"
          },
          {
            "quantity": 16,
            "sku_id": "0Q-220773"
          }
        ]
      },
      {
        "barcode": "137382481",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-213183"
          }
        ]
      },
      {
        "barcode": "137382146",
        "items": [
          {
            "quantity": 20,
            "sku_id": "0Q-227048"
          }
        ]
      },
      {
        "barcode": "14023117",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-243718"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-34426"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-200064"
          },
          {
            "quantity": 46,
            "sku_id": "0Q-211855"
          },
          {
            "quantity": 18,
            "sku_id": "0Q-211872"
          }
        ]
      },
      {
        "barcode": "137379770",
        "items": [
          {
            "quantity": 25,
            "sku_id": "0Q-133194"
          },
          {
            "quantity": 22,
            "sku_id": "0Q-228311"
          }
        ]
      },
      {
        "barcode": "137380478",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-111460"
          }
        ]
      },
      {
        "barcode": "137383067",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-204699"
          }
        ]
      },
      {
        "barcode": "137379121",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-00640"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-210901"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245259"
          },
          {
            "quantity": 27,
            "sku_id": "0Q-135765"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-235832"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-213029"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-241930"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-121443"
          }
        ]
      },
      {
        "barcode": "137383069",
        "items": [
          {
            "quantity": 1,
            "sku_id": "0Q-125248"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-227070"
          },
          {
            "quantity": 42,
            "sku_id": "0Q-226643"
          }
        ]
      },
      {
        "barcode": "14023671",
        "items": [
          {
            "quantity": 45,
            "sku_id": "0Q-54747"
          }
        ]
      },
      {
        "barcode": "137383050",
        "items": [
          {
            "quantity": 32,
            "sku_id": "0Q-242246"
          }
        ]
      },
      {
        "barcode": "137352683",
        "items": [
          {
            "quantity": 35,
            "sku_id": "0Q-150468"
          }
        ]
      },
      {
        "barcode": "137380474",
        "items": [
          {
            "quantity": 39,
            "sku_id": "0Q-109334"
          },
          {
            "quantity": 47,
            "sku_id": "0Q-12413"
          }
        ]
      },
      {
        "barcode": "137381525",
        "items": [
          {
            "quantity": 22,
            "sku_id": "0Q-223133"
          }
        ]
      },
      {
        "barcode": "137381480",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-155187"
          }
        ]
      },
      {
        "barcode": "137382487",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-231681"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-191896"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-136394"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-188403"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-227017"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-133905"
          }
        ]
      },
      {
        "barcode": "137379739",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137373851",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137379679",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-226965"
          }
        ]
      },
      {
        "barcode": "137379137",
        "items": [
          {
            "quantity": 3,
            "sku_id": "0Q-235832"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-125227"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-170375"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-133295"
          },
          {
            "quantity": 40,
            "sku_id": "0Q-141324"
          }
        ]
      },
      {
        "barcode": "137382124",
        "items": [
          {
            "quantity": 11,
            "sku_id": "0Q-74105"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-121443"
          },
          {
            "quantity": 9,
            "sku_id": "0Q-225611"
          }
        ]
      },
      {
        "barcode": "137380465",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-210430"
          }
        ]
      },
      {
        "barcode": "137352667",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-72343"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-170637"
          }
        ]
      },
      {
        "barcode": "137380472",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-12413"
          },
          {
            "quantity": 40,
            "sku_id": "0Q-109334"
          }
        ]
      },
      {
        "barcode": "137383053",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-229201"
          }
        ]
      },
      {
        "barcode": "137379740",
        "items": [
          {
            "quantity": 7,
            "sku_id": "0Q-242247"
          },
          {
            "quantity": 54,
            "sku_id": "0Q-192033"
          }
        ]
      },
      {
        "barcode": "137381478",
        "items": [
          {
            "quantity": 4,
            "sku_id": "0Q-227071"
          }
        ]
      },
      {
        "barcode": "137381509",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-233411"
          }
        ]
      },
      {
        "barcode": "137383065",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-93947"
          }
        ]
      },
      {
        "barcode": "137379688",
        "items": [
          {
            "quantity": 5,
            "sku_id": "0Q-242199"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-205358"
          },
          {
            "quantity": 18,
            "sku_id": "0Q-219440"
          }
        ]
      },
      {
        "barcode": "137382140",
        "items": [
          {
            "quantity": 14,
            "sku_id": "0Q-33489"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-232579"
          }
        ]
      },
      {
        "barcode": "137379733",
        "items": [
          {
            "quantity": 45,
            "sku_id": "0Q-233413"
          }
        ]
      },
      {
        "barcode": "137374355",
        "items": [
          {
            "quantity": 33,
            "sku_id": "0Q-237870"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-240994"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245851"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-240995"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-240993"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-166520"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-213381"
          },
          {
            "quantity": 48,
            "sku_id": "0Q-211740"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-204584"
          }
        ]
      },
      {
        "barcode": "137380446",
        "items": [
          {
            "quantity": 72,
            "sku_id": "0Q-38924"
          }
        ]
      },
      {
        "barcode": "137373843",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-187306"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-181708"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-173301"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-136041"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-226381"
          }
        ]
      },
      {
        "barcode": "137382120",
        "items": [
          {
            "quantity": 7,
            "sku_id": "0Q-228045"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-219427"
          },
          {
            "quantity": 28,
            "sku_id": "0Q-191905"
          },
          {
            "quantity": 17,
            "sku_id": "0Q-134600"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-226777"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-196016"
          }
        ]
      },
      {
        "barcode": "137367672",
        "items": [
          {
            "quantity": 72,
            "sku_id": "0Q-38924"
          }
        ]
      },
      {
        "barcode": "137382121",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-212301"
          },
          {
            "quantity": 48,
            "sku_id": "0Q-138845"
          },
          {
            "quantity": 52,
            "sku_id": "0Q-209452"
          },
          {
            "quantity": 19,
            "sku_id": "0Q-138852"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-227963"
          }
        ]
      },
      {
        "barcode": "137382130",
        "items": [
          {
            "quantity": 33,
            "sku_id": "0Q-133290"
          }
        ]
      },
      {
        "barcode": "137382148",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-191924"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-237937"
          },
          {
            "quantity": 13,
            "sku_id": "0Q-65094"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-226968"
          }
        ]
      },
      {
        "barcode": "137379704",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-231591"
          }
        ]
      },
      {
        "barcode": "137379771",
        "items": [
          {
            "quantity": 7,
            "sku_id": "0Q-227075"
          }
        ]
      },
      {
        "barcode": "137356524",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-219429"
          }
        ]
      },
      {
        "barcode": "137382458",
        "items": [
          {
            "quantity": 18,
            "sku_id": "0Q-55953"
          },
          {
            "quantity": 48,
            "sku_id": "0Q-12407"
          }
        ]
      },
      {
        "barcode": "137382149",
        "items": [
          {
            "quantity": 36,
            "sku_id": "0Q-65094"
          },
          {
            "quantity": 53,
            "sku_id": "0Q-231663"
          }
        ]
      },
      {
        "barcode": "137383068",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-93947"
          }
        ]
      },
      {
        "barcode": "137383083",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-228309"
          }
        ]
      },
      {
        "barcode": "137382138",
        "items": [
          {
            "quantity": 5,
            "sku_id": "0Q-209761"
          },
          {
            "quantity": 35,
            "sku_id": "0Q-33489"
          }
        ]
      },
      {
        "barcode": "137380492",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-134442"
          }
        ]
      },
      {
        "barcode": "137381527",
        "items": [
          {
            "quantity": 13,
            "sku_id": "0Q-167082"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-231654"
          },
          {
            "quantity": 13,
            "sku_id": "0Q-170270"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-223794"
          },
          {
            "quantity": 13,
            "sku_id": "0Q-223133"
          }
        ]
      },
      {
        "barcode": "137379729",
        "items": [
          {
            "quantity": 15,
            "sku_id": "0Q-121450"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-93943"
          }
        ]
      },
      {
        "barcode": "137364900",
        "items": [
          {
            "quantity": 50,
            "sku_id": "0Q-138838"
          }
        ]
      },
      {
        "barcode": "137381523",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-223133"
          },
          {
            "quantity": 46,
            "sku_id": "0Q-221405"
          },
          {
            "quantity": 19,
            "sku_id": "0Q-171693"
          }
        ]
      },
      {
        "barcode": "137373852",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137367665",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-226964"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-221428"
          }
        ]
      },
      {
        "barcode": "137373842",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-179674"
          }
        ]
      },
      {
        "barcode": "137352668",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-170637"
          },
          {
            "quantity": 30,
            "sku_id": "0Q-245258"
          }
        ]
      },
      {
        "barcode": "137383084",
        "items": [
          {
            "quantity": 36,
            "sku_id": "0Q-209749"
          }
        ]
      },
      {
        "barcode": "137379762",
        "items": [
          {
            "quantity": 13,
            "sku_id": "0Q-238352"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-227066"
          }
        ]
      },
      {
        "barcode": "137381486",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-155187"
          }
        ]
      },
      {
        "barcode": "137383078",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-226293"
          }
        ]
      },
      {
        "barcode": "137380486",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-154970"
          }
        ]
      },
      {
        "barcode": "137356532",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-12793"
          }
        ]
      },
      {
        "barcode": "137379122",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-164362"
          }
        ]
      },
      {
        "barcode": "137380390",
        "items": [
          {
            "quantity": 28,
            "sku_id": "0Q-166995"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-138852"
          },
          {
            "quantity": 38,
            "sku_id": "0Q-141131"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-234988"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-160556"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-152834"
          },
          {
            "quantity": 33,
            "sku_id": "0Q-210414"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-185861"
          }
        ]
      },
      {
        "barcode": "137373840",
        "items": [
          {
            "quantity": 15,
            "sku_id": "0Q-226963"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-227043"
          }
        ]
      },
      {
        "barcode": "137383062",
        "items": [
          {
            "quantity": 23,
            "sku_id": "0Q-125242"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-246388"
          }
        ]
      },
      {
        "barcode": "137383044",
        "items": [
          {
            "quantity": 1,
            "sku_id": "0Q-08730"
          },
          {
            "quantity": 18,
            "sku_id": "0Q-208954"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-196076"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-12730"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-204699"
          }
        ]
      },
      {
        "barcode": "137373862",
        "items": [
          {
            "quantity": 14,
            "sku_id": "0Q-206154"
          }
        ]
      },
      {
        "barcode": "137383072",
        "items": [
          {
            "quantity": 5,
            "sku_id": "0Q-206345"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-226293"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-222991"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-69312"
          }
        ]
      },
      {
        "barcode": "137381510",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-233411"
          }
        ]
      },
      {
        "barcode": "137367650",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-210417"
          }
        ]
      },
      {
        "barcode": "137379732",
        "items": [
          {
            "quantity": 15,
            "sku_id": "0Q-123881"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-233413"
          }
        ]
      },
      {
        "barcode": "137381470",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-149358"
          },
          {
            "quantity": 48,
            "sku_id": "0Q-164239"
          }
        ]
      },
      {
        "barcode": "137379756",
        "items": [
          {
            "quantity": 9,
            "sku_id": "0Q-220805"
          }
        ]
      },
      {
        "barcode": "137383043",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-204699"
          }
        ]
      },
      {
        "barcode": "137382132",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-161198"
          },
          {
            "quantity": 30,
            "sku_id": "0Q-234974"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-232016"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-202998"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-111463"
          }
        ]
      },
      {
        "barcode": "137380463",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-210430"
          }
        ]
      },
      {
        "barcode": "137379141",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-146712"
          },
          {
            "quantity": 50,
            "sku_id": "0Q-106193"
          }
        ]
      },
      {
        "barcode": "137380454",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-221429"
          }
        ]
      },
      {
        "barcode": "137356545",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-196509"
          }
        ]
      },
      {
        "barcode": "137380464",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-210430"
          }
        ]
      },
      {
        "barcode": "137379714",
        "items": [
          {
            "quantity": 46,
            "sku_id": "0Q-202994"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-135766"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-196439"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-243037"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-228045"
          }
        ]
      },
      {
        "barcode": "137380452",
        "items": [
          {
            "quantity": 60,
            "sku_id": "0Q-206439"
          }
        ]
      },
      {
        "barcode": "137379749",
        "items": [
          {
            "quantity": 5,
            "sku_id": "0Q-226970"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-241950"
          }
        ]
      },
      {
        "barcode": "137379686",
        "items": [
          {
            "quantity": 18,
            "sku_id": "0Q-219440"
          }
        ]
      },
      {
        "barcode": "137381514",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-84216"
          }
        ]
      },
      {
        "barcode": "137364893",
        "items": [
          {
            "quantity": 60,
            "sku_id": "0Q-159322"
          }
        ]
      },
      {
        "barcode": "137379160",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-170639"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-146712"
          }
        ]
      },
      {
        "barcode": "137356523",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-219429"
          }
        ]
      },
      {
        "barcode": "137381474",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-123897"
          }
        ]
      },
      {
        "barcode": "137380476",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-111460"
          }
        ]
      },
      {
        "barcode": "137376090",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-226964"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-204700"
          }
        ]
      },
      {
        "barcode": "137373844",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137379768",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-72292"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-103996"
          }
        ]
      },
      {
        "barcode": "137356546",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-196509"
          }
        ]
      },
      {
        "barcode": "137380448",
        "items": [
          {
            "quantity": 30,
            "sku_id": "0Q-143399"
          }
        ]
      },
      {
        "barcode": "137383080",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-196512"
          }
        ]
      },
      {
        "barcode": "137356538",
        "items": [
          {
            "quantity": 18,
            "sku_id": "0Q-206286"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-223180"
          }
        ]
      },
      {
        "barcode": "137379128",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-121443"
          }
        ]
      },
      {
        "barcode": "137383057",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-241949"
          }
        ]
      },
      {
        "barcode": "137381508",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-233411"
          }
        ]
      },
      {
        "barcode": "137379126",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-121443"
          }
        ]
      },
      {
        "barcode": "137381463",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-223167"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-227035"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-237947"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-147374"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-147368"
          }
        ]
      },
      {
        "barcode": "137381473",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-123897"
          }
        ]
      },
      {
        "barcode": "137377752",
        "items": [
          {
            "quantity": 1,
            "sku_id": "0Q-243029"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-147375"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-223271"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-227071"
          }
        ]
      },
      {
        "barcode": "137380395",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-111462"
          }
        ]
      },
      {
        "barcode": "137382141",
        "items": [
          {
            "quantity": 23,
            "sku_id": "0Q-242270"
          },
          {
            "quantity": 42,
            "sku_id": "0Q-246390"
          },
          {
            "quantity": 36,
            "sku_id": "0Q-191903"
          },
          {
            "quantity": 18,
            "sku_id": "0Q-234977"
          }
        ]
      },
      {
        "barcode": "137382142",
        "items": [
          {
            "quantity": 14,
            "sku_id": "0Q-159466"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-227048"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-00526"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-209008"
          }
        ]
      },
      {
        "barcode": "137373858",
        "items": [
          {
            "quantity": 15,
            "sku_id": "0Q-12730"
          }
        ]
      },
      {
        "barcode": "137356526",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-84360"
          }
        ]
      },
      {
        "barcode": "137380471",
        "items": [
          {
            "quantity": 10,
            "sku_id": "0Q-213840"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-159328"
          },
          {
            "quantity": 25,
            "sku_id": "0Q-125477"
          },
          {
            "quantity": 31,
            "sku_id": "0Q-210421"
          },
          {
            "quantity": 43,
            "sku_id": "0Q-200534"
          }
        ]
      },
      {
        "barcode": "137381488",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-155187"
          }
        ]
      },
      {
        "barcode": "137383064",
        "items": [
          {
            "quantity": 1,
            "sku_id": "0Q-238978"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-227065"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-93947"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-230110"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-227132"
          }
        ]
      },
      {
        "barcode": "137380457",
        "items": [
          {
            "quantity": 18,
            "sku_id": "0Q-195961"
          },
          {
            "quantity": 47,
            "sku_id": "0Q-27830"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-204700"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-221429"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-204547"
          }
        ]
      },
      {
        "barcode": "137367659",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-226964"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-227035"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-226965"
          }
        ]
      },
      {
        "barcode": "137379154",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-227045"
          }
        ]
      },
      {
        "barcode": "137352686",
        "items": [
          {
            "quantity": 22,
            "sku_id": "0Q-121934"
          }
        ]
      },
      {
        "barcode": "137379156",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-146712"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-227050"
          },
          {
            "quantity": 18,
            "sku_id": "0Q-243704"
          }
        ]
      },
      {
        "barcode": "137382131",
        "items": [
          {
            "quantity": 19,
            "sku_id": "0Q-111463"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-133290"
          }
        ]
      },
      {
        "barcode": "137382461",
        "items": [
          {
            "quantity": 52,
            "sku_id": "0Q-188127"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-55953"
          }
        ]
      },
      {
        "barcode": "137373853",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137373864",
        "items": [
          {
            "quantity": 14,
            "sku_id": "0Q-12730"
          }
        ]
      },
      {
        "barcode": "137382485",
        "items": [
          {
            "quantity": 45,
            "sku_id": "0Q-205358"
          }
        ]
      },
      {
        "barcode": "137356527",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-225736"
          }
        ]
      },
      {
        "barcode": "137352665",
        "items": [
          {
            "quantity": 30,
            "sku_id": "0Q-213549"
          }
        ]
      },
      {
        "barcode": "137367666",
        "items": [
          {
            "quantity": 17,
            "sku_id": "0Q-38924"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-229190"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-150472"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-225587"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-201489"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-227022"
          },
          {
            "quantity": 22,
            "sku_id": "0Q-185834"
          },
          {
            "quantity": 60,
            "sku_id": "0Q-216050"
          },
          {
            "quantity": 5,
            "sku_id": "0Q-227017"
          }
        ]
      },
      {
        "barcode": "137380498",
        "items": [
          {
            "quantity": 7,
            "sku_id": "0Q-227720"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-206440"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-243780"
          },
          {
            "quantity": 16,
            "sku_id": "0Q-150476"
          },
          {
            "quantity": 11,
            "sku_id": "0Q-134563"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-226347"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-226959"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-179605"
          }
        ]
      },
      {
        "barcode": "137373861",
        "items": [
          {
            "quantity": 11,
            "sku_id": "0Q-12730"
          },
          {
            "quantity": 42,
            "sku_id": "0Q-190459"
          }
        ]
      },
      {
        "barcode": "137382133",
        "items": [
          {
            "quantity": 9,
            "sku_id": "0Q-166807"
          },
          {
            "quantity": 47,
            "sku_id": "0Q-231661"
          },
          {
            "quantity": 30,
            "sku_id": "0Q-203669"
          }
        ]
      },
      {
        "barcode": "137356530",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-12793"
          }
        ]
      },
      {
        "barcode": "137380488",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-220805"
          }
        ]
      },
      {
        "barcode": "137380449",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-147369"
          },
          {
            "quantity": 48,
            "sku_id": "0Q-138866"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-171695"
          }
        ]
      },
      {
        "barcode": "137380450",
        "items": [
          {
            "quantity": 13,
            "sku_id": "0Q-204703"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-228307"
          }
        ]
      },
      {
        "barcode": "137356534",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-88592"
          }
        ]
      },
      {
        "barcode": "137367651",
        "items": [
          {
            "quantity": 5,
            "sku_id": "0Q-146031"
          },
          {
            "quantity": 53,
            "sku_id": "0Q-125477"
          }
        ]
      },
      {
        "barcode": "137373848",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137382137",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-232578"
          }
        ]
      },
      {
        "barcode": "137379684",
        "items": [
          {
            "quantity": 3,
            "sku_id": "0Q-213549"
          },
          {
            "quantity": 15,
            "sku_id": "0Q-70351"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-141285"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-202994"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-100967"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-133905"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-213850"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-211768"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-121441"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-00640"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-196686"
          }
        ]
      },
      {
        "barcode": "137379718",
        "items": [
          {
            "quantity": 34,
            "sku_id": "0Q-53514"
          },
          {
            "quantity": 70,
            "sku_id": "0Q-225734"
          }
        ]
      },
      {
        "barcode": "137379735",
        "items": [
          {
            "quantity": 27,
            "sku_id": "0Q-229199"
          }
        ]
      },
      {
        "barcode": "137379759",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-220805"
          }
        ]
      },
      {
        "barcode": "137379223",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-222579"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-66002"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-241004"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-182312"
          },
          {
            "quantity": 9,
            "sku_id": "0Q-242239"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242851"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-232849"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242847"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-233227"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-177915"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-240999"
          },
          {
            "quantity": 19,
            "sku_id": "0Q-169248"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-189932"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-149325"
          },
          {
            "quantity": 36,
            "sku_id": "0Q-201909"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-196375"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-242852"
          },
          {
            "quantity": 9,
            "sku_id": "0Q-242241"
          }
        ]
      },
      {
        "barcode": "137379764",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-108506"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-237754"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-228305"
          }
        ]
      },
      {
        "barcode": "137379705",
        "items": [
          {
            "quantity": 29,
            "sku_id": "0Q-205364"
          }
        ]
      },
      {
        "barcode": "137367677",
        "items": [
          {
            "quantity": 3,
            "sku_id": "0Q-231827"
          },
          {
            "quantity": 21,
            "sku_id": "0Q-228014"
          },
          {
            "quantity": 9,
            "sku_id": "0Q-227048"
          },
          {
            "quantity": 1,
            "sku_id": "0Q-147369"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-213312"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-238017"
          }
        ]
      },
      {
        "barcode": "137379773",
        "items": [
          {
            "quantity": 42,
            "sku_id": "0Q-226645"
          },
          {
            "quantity": 13,
            "sku_id": "0Q-243027"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-230115"
          }
        ]
      },
      {
        "barcode": "137380394",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-111462"
          }
        ]
      },
      {
        "barcode": "137373850",
        "items": [
          {
            "quantity": 2,
            "sku_id": "0Q-227072"
          }
        ]
      },
      {
        "barcode": "137382135",
        "items": [
          {
            "quantity": 10,
            "sku_id": "0Q-227050"
          },
          {
            "quantity": 7,
            "sku_id": "0Q-202935"
          },
          {
            "quantity": 30,
            "sku_id": "0Q-125478"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-233412"
          }
        ]
      },
      {
        "barcode": "137379132",
        "items": [
          {
            "quantity": 30,
            "sku_id": "0Q-228045"
          }
        ]
      },
      {
        "barcode": "137352666",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-72343"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-235832"
          },
          {
            "quantity": 18,
            "sku_id": "0Q-196441"
          }
        ]
      },
      {
        "barcode": "137383060",
        "items": [
          {
            "quantity": 4,
            "sku_id": "0Q-227045"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-125250"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-227044"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-29803"
          }
        ]
      },
      {
        "barcode": "137380467",
        "items": [
          {
            "quantity": 72,
            "sku_id": "0Q-93945"
          }
        ]
      },
      {
        "barcode": "137374358",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-237872"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245839"
          },
          {
            "quantity": 63,
            "sku_id": "0Q-201922"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-215125"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245841"
          },
          {
            "quantity": 3,
            "sku_id": "0Q-211741"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-185861"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245844"
          }
        ]
      },
      {
        "barcode": "137381477",
        "items": [
          {
            "quantity": 4,
            "sku_id": "0Q-227071"
          }
        ]
      },
      {
        "barcode": "137381506",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-210317"
          }
        ]
      },
      {
        "barcode": "137380455",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-221429"
          }
        ]
      },
      {
        "barcode": "137380456",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-221429"
          }
        ]
      },
      {
        "barcode": "137380398",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-111462"
          }
        ]
      },
      {
        "barcode": "137383055",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-229201"
          }
        ]
      },
      {
        "barcode": "137356312",
        "items": [
          {
            "quantity": 20,
            "sku_id": "0Q-212901"
          }
        ]
      },
      {
        "barcode": "137382480",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-213183"
          }
        ]
      },
      {
        "barcode": "137380468",
        "items": [
          {
            "quantity": 72,
            "sku_id": "0Q-93945"
          }
        ]
      },
      {
        "barcode": "137383076",
        "items": [
          {
            "quantity": 10,
            "sku_id": "0Q-215016"
          }
        ]
      },
      {
        "barcode": "137383081",
        "items": [
          {
            "quantity": 24,
            "sku_id": "0Q-196512"
          }
        ]
      },
      {
        "barcode": "137356318",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-219429"
          }
        ]
      },
      {
        "barcode": "137367652",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-221429"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-226959"
          }
        ]
      },
      {
        "barcode": "137356311",
        "items": [
          {
            "quantity": 75,
            "sku_id": "0Q-174388"
          }
        ]
      },
      {
        "barcode": "137367658",
        "items": [
          {
            "quantity": 55,
            "sku_id": "0Q-12406"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-146598"
          },
          {
            "quantity": 14,
            "sku_id": "0Q-226960"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-210056"
          }
        ]
      },
      {
        "barcode": "137381516",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-68478"
          }
        ]
      },
      {
        "barcode": "137352675",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-232577"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-227042"
          }
        ]
      },
      {
        "barcode": "137352664",
        "items": [
          {
            "quantity": 15,
            "sku_id": "0Q-227033"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-226968"
          }
        ]
      },
      {
        "barcode": "137383049",
        "items": [
          {
            "quantity": 22,
            "sku_id": "0Q-04213"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-242246"
          }
        ]
      },
      {
        "barcode": "137382129",
        "items": [
          {
            "quantity": 21,
            "sku_id": "0Q-227050"
          },
          {
            "quantity": 6,
            "sku_id": "0Q-210329"
          }
        ]
      },
      {
        "barcode": "137382118",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-160221"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-55953"
          },
          {
            "quantity": 24,
            "sku_id": "0Q-189709"
          }
        ]
      },
      {
        "barcode": "137381465",
        "items": [
          {
            "quantity": 48,
            "sku_id": "0Q-150466"
          }
        ]
      },
      {
        "barcode": "137381521",
        "items": [
          {
            "quantity": 3,
            "sku_id": "0Q-223181"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-223737"
          },
          {
            "quantity": 27,
            "sku_id": "0Q-166994"
          }
        ]
      },
      {
        "barcode": "137380391",
        "items": [
          {
            "quantity": 22,
            "sku_id": "0Q-234973"
          },
          {
            "quantity": 10,
            "sku_id": "0Q-166470"
          }
        ]
      },
      {
        "barcode": "137374352",
        "items": [
          {
            "quantity": 16,
            "sku_id": "0Q-206223"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245854"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-240997"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245849"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-245847"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-192318"
          },
          {
            "quantity": 96,
            "sku_id": "0Q-214342"
          },
          {
            "quantity": 23,
            "sku_id": "0Q-240996"
          },
          {
            "quantity": 12,
            "sku_id": "0Q-220845"
          }
        ]
      },
      {
        "barcode": "137383051",
        "items": [
          {
            "quantity": 9,
            "sku_id": "0Q-222797"
          },
          {
            "quantity": 8,
            "sku_id": "0Q-228310"
          },
          {
            "quantity": 4,
            "sku_id": "0Q-125253"
          }
        ]
      },
      {
        "barcode": "137367656",
        "items": [
          {
            "quantity": 40,
            "sku_id": "0Q-123904"
          }
        ]
      },
      {
        "barcode": "137381513",
        "items": [
          {
            "quantity": 8,
            "sku_id": "0Q-233411"
          }
        ]
      },
      {
        "barcode": "137381519",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-215839"
          },
          {
            "quantity": 2,
            "sku_id": "0Q-227132"
          },
          {
            "quantity": 20,
            "sku_id": "0Q-227283"
          }
        ]
      },
      {
        "barcode": "137379758",
        "items": [
          {
            "quantity": 6,
            "sku_id": "0Q-220805"
          }
        ]
      },
      {
        "barcode": "137356529",
        "items": [
          {
            "quantity": 12,
            "sku_id": "0Q-12793"
          }
        ]
      }
    ],
    "update_ts": "2024-06-30T10:04:35.577574",
    "uuid": "a5d46770-db5f-449e-b337-40ea399f12dd",
    "workspace": {
      "barcode": "VO-19",
      "condition": "GOOD",
      "id": "e0f69561-5549-42ce-8aea-877f2c56add0",
      "printer_settings": [
        {
          "name": "PRINTER_A4",
          "type": "A4"
        },
        {
          "name": "STICKER_6x6",
          "type": "STICKER_6x6"
        },
        {
          "name": "STICKER_10X15",
          "type": "STICKER_10x15"
        }
      ],
      "title": "VO-19",
      "zone_id": "02bb0f33-d9fe-40e1-80ee-57158b0273b2"
    }
  }
}
import { IPrintOptions, usePrint } from './print'
import { useFetchShipmentDocsForPrint, usePreviewBase64Pdf } from 'src/hooks'


/** Печать транспортной накладной */

interface printResult {
  printShipmentDocs: (shipmentId: string, isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}

export const usePrintShipmentDocs = (): printResult => {
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const { printSticker } = usePrint()
  const { fetch: getShipmentDocsForPrint } = useFetchShipmentDocsForPrint()

  const printShipmentDocs = async (shipmentId: string, isPreview?: boolean, options: IPrintOptions = {}) => {
    return await getShipmentDocsForPrint({ shipmentId })
      .then((result) => {
        if (!result) { return }
        const { content, printer } = result

        if (isPreview) {
          previewBase64Pdf(content, 'Документы отгрузки')
          return
        }

        printSticker(printer, content, {
          copies: 1,
          ...options
        })
      })
      .catch(() => {

      })
  }

  return {
    printShipmentDocs,
  }
}
import { useMutation } from 'react-query'
import api from 'src/api'
import { ApiReqResult } from 'src/interfaces'

interface IParams {
  orderId: string
  data: IUpdateAdjustingDocument
}
interface IUpdateAdjustingDocument {
  export_to_dlv?: boolean
  items: IItems[]
  remove_from_batch?: boolean
}
interface IItems {
  cell_barcode?: string
  change: number
  id: string
  place_barcode?: string
  sku_batch_id?: string
}
interface Result extends ApiReqResult {
  id: string
}
// http://wms-test.kak2c.ru/warehouse-api/order/{order_id}/edit-with-related-docs
const useUpdateAdjustingDocument = () => {
  return useMutation(async ({ orderId, data }: IParams) => {
    const path = `/order/${orderId}/edit-with-related-docs`
    const result = await api.post(path, data)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}
export default useUpdateAdjustingDocument

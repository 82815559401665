import styles from './tsd-main-wrapper.module.scss'
import React from 'react'

import cx from 'classnames'

interface ITSDMainWrapperProps {
  children: React.ReactNode
  overlayClassName?: string
  wrapperClassName?: string
}
const TSDMainWrapper = ({
  children,
  overlayClassName,
  wrapperClassName,
}: ITSDMainWrapperProps) => {
  return (
    <div className={cx(styles.overlay, overlayClassName)}>
      <div className={cx(styles.contentWrapper, wrapperClassName)}>
        {children}
      </div>
    </div>
  )
}

export default TSDMainWrapper

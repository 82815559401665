import { useMutation } from 'react-query'

import api from 'src/api'

const useAdvancedMode = () =>
  useMutation(async (data: { advanced_auth_key: string }) =>
    api.post('/auth/advanced_mode', data),
  )

export default useAdvancedMode

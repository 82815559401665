import { useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult, IContainer, IPhysicalSetOrderDto } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { provideMock } from '../../mocks'
import {
  MOCK_CREATE_EDIT_PHYSICAL_SET,
  MOCK_DISASSEMBLING_CREATE_EDIT_PHYSICAL_SET,
  MOCK_GET_PHYSICAL_SET,
  MOCK_START_PHYSICAL_SET,
} from '../../mocks/physicalSet'


interface IParams {
  order_id: string
}

interface IResult extends ApiReqResult {
  order: IPhysicalSetOrderDto,
  containers: IContainer[]
}

const useGetPhysicalSet = (
  params: IParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IResult>(
    [`getPhysicalSet`, params],
    provideMock<IParams, IResult>(
    async () => {
      const result = await api.get(`/physical-set-order/${params.order_id}`)
      if (!result?.data?.success) {
        throw new Error('not success')
      }
      return result.data
    },
       params,
      [
        {
        key: 'order_id',
        value: 'mock',
        result: MOCK_GET_PHYSICAL_SET,
        logData: 'Получение задания',
      },
        {
        key: 'order_id',
        value: 'mockStarted',
        result: MOCK_START_PHYSICAL_SET,
        logData: 'Получение задания (начатое)',
      },
        {
        key: 'order_id',
        value: 'mockEdit',
        result: MOCK_CREATE_EDIT_PHYSICAL_SET,
        logData: 'Получение задания (с комплектом)',
      },
        {
        key: 'order_id',
        value: 'mockEditDis',
        result: MOCK_DISASSEMBLING_CREATE_EDIT_PHYSICAL_SET,
        logData: 'Получение задания (с комплектом)',
      },
    ]),
    {
      keepPreviousData: true,
      ...options,
    },
  )
}

export default useGetPhysicalSet

import api from 'src/api'
import { ApiReqResult, ISticker } from 'src/interfaces'
import { convertObjectToURLParams } from '../../helpers'

interface IParams {
  order_id: string
  barcode?: string
}

interface IResult extends ISticker, ApiReqResult {
}

const useGetOrderPlacePackingList = () => ({
  fetch: async ({ order_id, barcode } : IParams): Promise<IResult> => {
    const params = convertObjectToURLParams({ barcode })
    const result = await api.get(`/order/${order_id}/packing-list${params}`)
    if (!result.data?.success) {
      throw result.data
    }
    return result.data
  }
})

export default useGetOrderPlacePackingList

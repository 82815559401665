import styles from './DeliveryServiceCell.module.scss'
import React from 'react'

type Props = {
  deliveryContractTitle: string
  deliveryServiceTitle: string
}
export const DeliveryServiceCell = ({ deliveryContractTitle, deliveryServiceTitle }: Props) => {
  return (
    <div className={styles.delivery}>
        <div className={styles.deliveryService}>{deliveryServiceTitle}</div>
        <div className={styles.deliveryContractTitle}>{deliveryContractTitle}</div>
      </div>
  )
}

import { InputWrapper, Modal } from 'src/components'
import styles from './CellManualModal.module.scss'
import { Button } from '@consta/uikit/Button'
import React, { useEffect, useState } from 'react'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { Loader } from '@consta/uikit/Loader'
import { IError } from 'src/interfaces'


interface IManualInputModalProps {
  onSubmit: (cellBarcode: string) => void
  onClose: () => void
  isOpen: boolean
  error?: IError
  isCellLoading: boolean
}

type inputsCellsType = {
  key: string
  label: string
}
const ManualInputModal = ({
  isCellLoading,
  error,
  onSubmit,
  onClose,
  isOpen,
}: IManualInputModalProps) => {
  const inputCells: inputsCellsType[] = [
    { key: 'section', label: 'Секция' },
    { key: 'line', label: 'Линия' },
    { key: 'shelving', label: 'Стелаж' },
    { key: 'cell', label: 'Ячейка' },
  ]

  const [acceptDisabled, setAcceptDisabled] = useState<boolean>(true)
  const [errorTxt, setErrorTxt] = useState<string>('')
  const [inputCellsState, setInputCellsState] = useState<{
    [key: string]: TextFieldPropValue
  }>({})

  useEffect(() => {
    // сбрасываем ошибку при открытии
    if (isOpen) {
      setErrorTxt('')
    }
  }, [isOpen])

  const barcodeInputValues = Object.values(inputCellsState)
  useEffect(() => {
    const valuesError =
      Object.values(inputCellsState).filter((item) => item).length <
      inputCells.length
    if (valuesError !== acceptDisabled) {
      setAcceptDisabled(valuesError)
    }
  }, [barcodeInputValues])

  const onChangeInput = (
    value: TextFieldPropValue,
    key: string,
    index: number,
  ) => {
    if (errorTxt) {
      setErrorTxt('')
    }
    setInputCellsState((prev) => {
      prev[key] = value
      return prev
    })
  }

  useEffect(() => {
    if (error) {
      setErrorTxt(error.message)
    }
  }, [error])

  const onClickSubmit = async () => {
    const valuesError: boolean =
      Object.values(inputCellsState).length < inputCells.length

    if (valuesError) {
      return console.log('Ошибка значения')
    }
    const resultBarcode = inputCells.reduce(
      (accum, item) => accum + inputCellsState[item.key],
      '',
    )
    onSubmit(resultBarcode)
  }
  const onClickClose = () => {
    setErrorTxt('')
    setInputCellsState({})
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      onClose={onClose}
      title={'Ручной ввод адреса ячейки'}
      subtitle={'Введите адрес ячейки'}
      className={styles.modal}
    >
      <div className={styles.inputCellsWrapper}>
        <div className={styles.inputCells}>
          {inputCells.map((input, i) => (
            <ManualInputCell
              id={input.key}
              index={i}
              key={input.key}
              label={input.label}
              value={inputCellsState[input.key]}
              onChange={(value) => onChangeInput(value, input.key, i)}
            />
          ))}
        </div>
        <p className={styles.errorText}>{errorTxt}</p>
      </div>

      {isCellLoading ? <Loader size="s" className={styles.loader} /> : null}

      <div className={styles.btnsWrapper}>
        <Button
          width="full"
          label="Отмена"
          view="ghost"
          className={styles.btn}
          onClick={onClickClose}
        />
        <Button
          width="full"
          label="Перейти"
          className={styles.btn}
          onClick={onClickSubmit}
          disabled={acceptDisabled || isCellLoading}
        />
      </div>
    </Modal>
  )
}


interface IManualInputCell {
  id: string
  label: string
  value: TextFieldPropValue | undefined
  onChange: (value: TextFieldPropValue) => any
  index: number
}
const ManualInputCell = ({
  id,
  label,
  value,
  index,
  onChange,
}: IManualInputCell) => {
  return (
    <div className={styles.inputCell}>
      <p>{label}</p>
      <InputWrapper
        id={id}
        size="m"
        autoFocus={!index}
        value={value}
        handleChange={onChange}
        className={styles.inputBarcode}
        placeholder={''}
      />
    </div>
  )
}

export default ManualInputModal

import { useQuery } from 'react-query'
import api from 'src/api'
import { ApiReqResult, IDeliveryCompanyProduct, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from 'src/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'

// http://wms-test.kak2c.ru/warehouse-api/delivery-company-product

export interface IGetDeliveryCompanyProductParams {
  delivery_company?: string
}
export interface IGetDeliveryCompanyProductResult
  extends ApiReqResult,
    PaginationResult {
  products: IDeliveryCompanyProduct[]
}
const useGetDeliveryCompanyProduct = (
  paramsObj: IGetDeliveryCompanyProductParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IGetDeliveryCompanyProductResult>(
    [`getDiliveryCompanyProduct`, paramsObj],
    async () => {
      const searchParams = convertObjectToURLParams(paramsObj)
      const result = await api.get(`/delivery-company-product${searchParams}`)

      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}
export default useGetDeliveryCompanyProduct

import { useMutation } from 'react-query'

import api from 'src/api'

import { ApiReqResult, ICarrierCompanyCrateData } from 'src/interfaces'

/*
 * Создание/редактирование перевозчика
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Perevozchiki/operation/putCarrier
 */

interface Result extends ApiReqResult {
  id: string
}
interface CreateUpdateCarrierCompanyParams {
  companyId?: string,
  data: ICarrierCompanyCrateData
}

const useCreateUpdateCarrierCompany = () => {
  return useMutation(async ({ companyId, data }: CreateUpdateCarrierCompanyParams) => {
    const path = `/carrier-company${companyId ? `/${companyId}` : ''}`
    const result = await api.post(path, data)

    if (!result?.data?.success) {
      throw result
    }
    return result.data as Result
  })
}

export default useCreateUpdateCarrierCompany

import { useQuery } from 'react-query'

import { ApiReqResult, IShipmentPlan } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import api from '../../api'


interface IParams {
  docId: string
}

interface IShipmentPlanResult extends ApiReqResult {
  shipment_plan: IShipmentPlan
}

const getShipmentPlan = async (params: IParams) => {
  const result = await api.get(`/shipment-plan/${params.docId}`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

export const useGetShipmentPlan = (
  params: IParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IShipmentPlanResult>(
    [`getShipmentPlan`, params],
    () => getShipmentPlan(params),
    {
      keepPreviousData: true,
      ...options
    },
  )
}

import React, { useEffect, useState } from 'react'

import styles from './advance-mode-modal.module.scss'

import { Button } from '@consta/uikit/Button'

import { InputWrapper, Modal } from 'src/components'

import { TextFieldPropValue } from '@consta/uikit/TextField'

import { useAdvancedMode } from 'src/hooks'

import { useAppContext } from 'src/context'

import { ModalProps } from 'src/interfaces'

interface AdvanceModeModalProps extends ModalProps {
  deferredAction: (() => void) | null
  setAdvanceMode: (value: boolean) => void
}

const AdvanceModeModal = (props: AdvanceModeModalProps) => {
  const { deferredAction, isOpen, onClose } = props

  const advancedModeMutation = useAdvancedMode()

  const { setAdvanceMode } = useAppContext()

  const [advanceBarcode, setAdvanceBarcode] = useState<TextFieldPropValue>('')
  const [error, setError] = useState<string>('')

  const handleSubmit = async () => {
    setAdvanceMode(true)
    if (deferredAction) {
      await deferredAction()
      setAdvanceMode(false)
    }
    onClose()
  }

  useEffect(() => {
    ;(async function () {
      if (advanceBarcode) {
        if (process.env.NODE_ENV !== 'production') {
          //проверка dev-пароля
          if (advanceBarcode === 'mock') {
            await handleSubmit()
            return
          }
        }

        await advancedModeMutation
          .mutateAsync({ advanced_auth_key: String(advanceBarcode) })
          .then(async ({ data }) => {
            if (data?.success && data?.privileges === 'ADMIN') {
              await handleSubmit()
            }
          })
      }
    })()
  }, [advanceBarcode])

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.advance}
      headerTitle="Активация расширенного режима"
      size="s"
      rootClassName={styles.rootadvance}
    >
      <InputWrapper
        label={'Отсканируйте или введите специальный штрихкод'}
        type={'text'}
        value={advanceBarcode}
        id={'advanceBarcode'}
        handleChange={setAdvanceBarcode}
        withDelay
        autoFocus
        className={styles.itemInput}
        placeholder={'Штрих-код'}
        error={error}
        size="l"
        autoComplete={'off'}
      />

      <Button
        label="Закрыть"
        view="ghost"
        className={styles.btn}
        onClick={onClose}
        loading={advancedModeMutation.isLoading}
      />
    </Modal>
  )
}

export default AdvanceModeModal

import styles from './verified-info.module.scss'
import React from 'react'
import cx from 'classnames'

interface IVerifiedInfoProps {
  title?: string
  className?: string
  checked: number
  allItemsCount?: number
}
const VerifiedInfo = (props: IVerifiedInfoProps) => {
  const { title = 'Проверено', checked, className, allItemsCount } = props
  return (
    <div className={cx(styles.verifiedInfo, className)}>
      <h5 className={styles.title}>{title}</h5>
      <p className={styles.amount}>
        <span className={styles.verifiedGreen}>{checked}</span>
        {allItemsCount !== undefined ? <> из {allItemsCount}</> : null}
      </p>
    </div>
  )
}
export default VerifiedInfo

import { ICommonDocument, IPhysicalSetOrderDto, IWorkspace } from 'src/interfaces'


export enum PhysicalSetOrderBatchStatusEnum {
  NEW = "NEW",
  PICKING = "PICKING",
  PICKED = "PICKED",
  PACKING = "PACKING",
  PACKED = "PACKED",
  PLACING = "PLACING",
  PLACED = "PLACED",
}

export interface PhysicalSetOrderBatchDto extends ICommonDocument {
  orders: IPhysicalSetOrderDto[]
  pallet_id: string
  status: PhysicalSetOrderBatchStatusEnum
  workspace: IWorkspace
}

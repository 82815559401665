

export const DATE_MONTH_FORMAT = `YYYY-MM`
export const DATE_FORMAT = `YYYY-MM-DD`
export const TIME_FORMAT = `HH:mm:ss`
export const DATE_FORMAT_VIEW = `DD.MM.YYYY`
export const MONTH_FORMAT_VIEW = `MM.YYYY`
export const DATE_TIME_VIEW = `${DATE_FORMAT_VIEW} ${TIME_FORMAT}`
export const DATETIME_REQUEST_FORMAT = `${DATE_FORMAT}T${TIME_FORMAT}`

export const COMMON_BARCODE_TYPE = 'COMMON'

// код серийного номера Честный знак
export const CHZ_CODE = 'chestniy_znak'
import React from 'react'

import { useAppContext } from 'src/context'

interface AppVersionProps {
  className?: string
}

const AppVersion = (props: AppVersionProps) => {
  const { className } = props

  const { appVersion } = useAppContext()

  return <div className={className}>{`WMS v${appVersion}`}</div>
}

export default AppVersion

import { useQuery } from 'react-query'
import api from 'src/api'

import { ApiReqResult, IFBSOrderBatch } from 'src/interfaces'
import { provideMock } from '../../mocks'
import { MOCK_ORDER_BATCH, MOCK_ORDER_BATCH_2, MOCK_ORDER_BATCH_BAD } from '../../mocks/batch/getOrderBatch'

// todo подумать как сделать без этой путаницы с orderId/batchId
interface useGetFBSOrderBatchParamsType {
  orderId: string // это batchId в действительности
  enabled?: boolean
}

const getMethod = async ({
  orderId: batchId,
}: useGetFBSOrderBatchParamsType) => {
  let result = await api.get(`/packing/order-batch/${batchId}`)

  const { success, ...order_batch } = result.data
  return { success, order_batch }
}

interface GetOrderBatchResult extends ApiReqResult {
  order_batch: IFBSOrderBatch
}
// todo rename to useGetOrderBatch
const useGetFBSOrderBatch = (
  params: useGetFBSOrderBatchParamsType,
  callback?: (order_batch: GetOrderBatchResult) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<GetOrderBatchResult>(
    [`getOrderBatch`, params],
     provideMock<any, any>(
    () => getMethod(params),
       params,
      [
        {
        key: 'orderId',
        value: 'mock',
        result: MOCK_ORDER_BATCH,
        logData: 'Получение батча'
      },
        {
        key: 'orderId',
        value: 'mock2',
        result: MOCK_ORDER_BATCH_2,
        logData: 'Получение батча 2'
      },
        {
        key: 'orderId',
        value: 'mockBad',
        result: MOCK_ORDER_BATCH_BAD,
        logData: 'Получение батча не wb'
      },
    ]),
    {
      enabled: params.enabled ?? true,
      cacheTime: 0,
      // keepPreviousData: true,
      onSuccess: ({ success, order_batch }) => {
        if (success) {
          if (callback) {
            callback({ success, order_batch })
          }
        } else {
          if (error) {
            error()
          }
        }
      },
      onError: () => {
        if (error) {
          error()
        }
      },
      onSettled: () => {
        if (settled) {
          settled()
        }
      },
    },
  ) as {
    isFetching: boolean;
    refetch: any;
    data: GetOrderBatchResult }
}

export default useGetFBSOrderBatch

import { IReportTemplate } from '../../../interfaces/report'
import { ICellPlace, ISku } from '../../../interfaces'

/** ОДИНОЧНЫЕ ЭКШЕНЫ */

export const SET_TEMPLATES = 'SET_TEMPLATES'
export const setTemplates = (templates: IReportTemplate[]) => ({
  type: SET_TEMPLATES,
  payload: templates,
})

export const SET_CURRENT_TEMPLATE = 'SET_CURRENT_TEMPLATE'
export const setCurrentTemplate = (template: IReportTemplate) => ({
  type: SET_CURRENT_TEMPLATE,
  payload: template,
})

export const SET_MODAL_SKUS = 'SET_MODAL_SKUS'
export const setModalSkus = (skus: ISku[]) => ({
  type: SET_MODAL_SKUS,
  payload: skus
})

export const SET_MODAL_CELLS = 'SET_MODAL_CELLS'
export const setModalCells = (cells: ICellPlace[]) => ({
  type: SET_MODAL_CELLS,
  payload: cells
})

import React, { useMemo, useState } from 'react'
import { Button, ChoiceTableModal, PrintPreviewCheckbox, WhiteIconPrinter } from '../../index'
import { generateFilesColumns, generateFilesRows } from './utils'
import { Checkbox } from '@consta/uikit/Checkbox'
import styles from './BatchDocsPrint.module.scss'
import { PrintTypeEnum } from 'src/interfaces'
import cx from 'classnames'

interface BatchDocsPrintProps {
  fullCompleteActivation: boolean
  setFullCompleteActivation: (active: boolean) => void
  handlePrint: (isPreview: boolean, ...args: any[]) => void
  size?: "m" | "xs" | "s" | "l"
}

const BatchDocsPrint = (props: BatchDocsPrintProps) => {
  const {
    fullCompleteActivation,
    setFullCompleteActivation,
    handlePrint,
    size='s'
  } = props

  const [filesModalVisible, setFilesModalVisible] = useState<boolean>(false)

  const [printType, setPrintType] = useState<PrintTypeEnum>(PrintTypeEnum.print)

  const filesRows = useMemo(generateFilesRows, [])
  const filesColumns = useMemo(generateFilesColumns, [])
  return (
    <>
      <div className={styles.btns}>
        <div className={styles.checkboxes}>
          <Checkbox
            label={'Сборка без ТСД'}
            checked={fullCompleteActivation}
            onChange={({ checked }) => setFullCompleteActivation(checked)}
            className={cx(styles.checkboxPrintType)}
            size={size}
          />
          <PrintPreviewCheckbox
            onChange={setPrintType}
            className={styles.checkboxPrintType}
            size={size}
          />
        </div>
        <Button
          iconLeft={WhiteIconPrinter}
          label={'Печать документов'}
          view={'primary'}
          size={size}
          onClick={() => setFilesModalVisible(true)}
        />
      </div>
      {
        filesModalVisible ? (
          <ChoiceTableModal
            isOpen={true}
            onClose={() => setFilesModalVisible(false)}
            onSubmit={(selectedRows) => {
              setFilesModalVisible(false)
              const selectedRowsIds = selectedRows.map(row => row.id)
              handlePrint(printType === PrintTypeEnum.preview, selectedRowsIds)
            }}
            title={'Печать документов батча'}
            submitBtnLabel={'Напечатать'}
            submitBtnIcon={WhiteIconPrinter}
            rows={filesRows}
            columns={filesColumns}
          />
        ) : null
      }
    </>
  )
}
export default BatchDocsPrint
import React, { useState } from 'react'

import styles from './choice-manual-package-modal.module.scss'

import { Button } from '@consta/uikit/Button'

import { EnterHandler, InputWrapper, Modal, SelectList } from 'src/components'

import { IDimensions, IOrderPackagingOption, ISelectListOption, ModalProps } from 'src/interfaces'


interface ChoiceManualPackageProps extends ModalProps {
  packagingOptions: IOrderPackagingOption[]
  selected: IOrderPackagingOption | null
  onBack: () => void
  onSelect: (option: IOrderPackagingOption | null, usedPackagingBarcode?: string) => void
  showConfirm?: (visible: boolean) => void
  onSubmit: (packageId: string, options: { dimensions?: IDimensions }) => void
  withoutSelectList?: boolean
}

const ChoiceManualPackageModal = (props: ChoiceManualPackageProps) => {
  const { isOpen, withoutSelectList, showConfirm, packagingOptions, selected, onBack, onSelect, onSubmit } =
    props

  const [error, setError] = useState<string>('')

  const handleSelect = (option: ISelectListOption) => {
    onSelect(
      packagingOptions.find(
        (o: IOrderPackagingOption) => o.id === option.value,
      ) || null,
    )
  }

  const handleScan = (barcode: string) => {
    const packagingOption = packagingOptions.find(
        (o: IOrderPackagingOption) => o.barcodes.includes(barcode)) || null
    if (packagingOption) {
      onSelect(packagingOption, barcode)
      showConfirm?.(true)
      setError('')
    } else {
      setError('Упаковка не найдена')
    }
  }

  const handleSubmit = () => {
    if (withoutSelectList) {
      // Если убрать эту проверку, то возникает плохая ситуация со сканером
      // Вызывает баг, который дублирует submit на ENTER
      return
    }
    if (selected) {
      onSubmit(selected.id, {})
    }
  }

  return (
    <EnterHandler onEnter={isOpen ? handleSubmit : undefined}>
      <Modal
        isOpen={isOpen}
        hasOverlay
        onOverlayClick={(): boolean => false}
        className={styles.manualPackage}
        title="Выберите упаковку"
        size="s"
      >
        <div className={styles.selectWrap}>
          {
            !withoutSelectList ? (
              <SelectList
                value={selected ? { label: selected.title, value: selected.id } : null}
                options={packagingOptions.map((o: IOrderPackagingOption) => ({
                  label: o.title,
                  value: o.id,
                }))}
                onChange={handleSelect}
                className={styles.wrap}
              />
            ) : (
              <InputWrapper
                className={styles.input}
                withDelay
                label={'Отсканируйте шк упаковки'}
                id={'packaging_barcode'}
                handleChange={(barcode) => handleScan(String(barcode))}
                autoFocus
                error={error}
                size="l"
              />
            )
          }
        </div>
        <div className={styles.btns}>
          <Button
            label="Назад"
            view="ghost"
            className={styles.btn}
            onClick={onBack}
          />
          {
            !withoutSelectList ? (
              <Button
                label="Выбрать"
                disabled={!selected}
                className={styles.btn}
                onClick={handleSubmit}
              />
            ) : null
          }
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ChoiceManualPackageModal

import { useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult, ICarrierCompany, PaginationResult } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { convertObjectToURLParams } from '../../helpers'

/*
 * Получить список компаний-перевозчиков
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Kompanii-perevozchiki/operation/getCarrierCompanies
 */


interface IParams {
  search: string
}
interface GetCarriersCompaniesListResult extends ApiReqResult, PaginationResult {
  companies: ICarrierCompany[]
}

const useGetCarriersCompaniesList = (params: IParams, options?: UseQueryOptions<any>) => {
  return useQuery<GetCarriersCompaniesListResult>(
    [`getCarriersCompaniesList`, params],
    async () => {
      const paramsStr = convertObjectToURLParams(params)
      const result = await api.get(`/carrier-company${paramsStr}`)
      return result.data as GetCarriersCompaniesListResult
    },
    options,
  )
}

export default useGetCarriersCompaniesList

import { useMutation } from 'react-query'
import api from 'src/api'
import { IPagination, ISku } from '../../interfaces'

// request types
export interface IProps {
  /* ШК тары */
  place_ids: string[]
}

// response types
export interface Error {
  code: string
  message: string
}

export interface Place {
  barcode: string
  condition: string
  id: string
  title: string
  zone_id: string
}

export interface Sku extends ISku {}

export interface Stock {
  condition: string
  place_id: string
  quantity: number
  reserved: number
  sku_batch_id: string
  sku_id: string
}

export interface GetProductRemainsResponseTypes {
  errors: Error[]
  pagination: IPagination
  places: Place[]
  skus: Sku[]
  stock: Stock[]
  success: boolean
}

/** дубль метода useGetCellStockByCellId todo оставить один из */

const useGetProductRemains = () => {
  const getProductRemains = async ({ place_ids }: IProps) => {
    const result = await api.post('/items/stock', {
      // Андрей С. сказал, что не надо указывать не надо
      // condition: 'GOOD',
      place_ids,
      type: 'FULL_BY_PLACES',
    })

    if (result.status === 200) {
      return result.data as GetProductRemainsResponseTypes
    }
  }

  return useMutation(getProductRemains)
}

export default useGetProductRemains

import IconUnload from '../assets/images/unloadBg.svg'
import IconLoad from '../assets/images/loadBg.svg'
import IconWarehouse from '../assets/images/warehouseBg.svg'
import IconDocs from '../assets/images/docsBg.svg'
import { PATHS } from './routing'
import { ISelectListOption } from 'src/interfaces'

export enum OperationType {
  unload = 'unload',
  warehouse = 'warehouse',
  acceptance = 'acceptance',
  reports = 'reports',
  billing = 'billing',
  admin = 'admin',
}

export interface IOperationOption extends ISelectListOption {
  category?: string
  categoryId?: string
  params?: any
  path: string
  isTSD?: boolean
}

/** Основные блоки операций армов */
export const operations: {
  [key in OperationType]: { title: string; icon: string }
} = {
  [OperationType.unload]: {
    title: 'Отгрузка',
    icon: IconUnload,
  },
  [OperationType.acceptance]: {
    title: 'Приемка',
    icon: IconLoad,
  },
  [OperationType.warehouse]: {
    title: 'Операции склада',
    icon: IconWarehouse,
  },
  [OperationType.reports]: {
    title: 'Отчеты',
    icon: IconDocs,
  },
  [OperationType.admin]: {
    title: 'Администратор',
    icon: IconWarehouse,
  },

  [OperationType.billing]: {
    title: 'Биллинг',
    icon: IconWarehouse,
  },
}

/** Выделенные армы */
export const operationOptions: IOperationOption[] = [
  {
    label: 'Упаковка товара',
    value: 'packaging',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.ORDER_SCAN,
  },
  {
    label: 'Приемка товара',
    value: 'acceptance',
    category: operations.acceptance.title,
    categoryId: OperationType.acceptance,
    path: PATHS.ACCEPTANCE_SCAN,
  },
  {
    label: 'Приемка товара ТСД',
    value: 'acceptance',
    category: operations.acceptance.title,
    categoryId: OperationType.acceptance,
    path: PATHS.ACCEPTANCE_FORM_TSD,
    isTSD: true,
  },

  {
    label: 'Сборка комплектов',
    value: 'assembling',
    category: operations.acceptance.title,
    categoryId: OperationType.acceptance,
    path: PATHS.ASSEMBLING_PHYSICAL_SET,
  },
  {
    label: 'Сверка реестра',
    value: 'RegistryReconciliation',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.REGISTRY_SCAN,
    isTSD: true,
  },

  {
    label: 'Инвентаризация ТСД',
    value: 'InventoryTSD',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.INVENTORY_TSD,
    isTSD: true,
  },

  {
    label: 'Частичная инвентаризация ТСД',
    value: 'PartOfInventoryTSD',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.PARTIAL_INVENTORY_TSD,
    isTSD: true,
  },

  {
    label: 'Контроль ячейки и номенклатуры',
    value: 'cell-control',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.WAREHOUSE_CONTROL_SCAN,
    isTSD: true,
  },
  {
    label: 'Формирование транспортных мест',
    value: 'formationTransportPlace',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.F_OF_TRANSPORT_PLACES_LIST,
    params: { page: 'uncompletedPlaces' },
  },
  {
    label: 'Формирование транспортных мест WB FBS',
    value: 'formationPlace',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.F_OF_PLACES_LIST,
    params: { page: 'placesList' },
    isTSD: true,
  },
  {
    label: 'Маркировка транспортных мест',
    value: 'markingTransportPlace',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.MARKING_TRANSPORT_PLACE,
  },
  {
    label: 'Генерация батчей',
    value: 'batchGeneration',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.BATCH_GENERATION,
    params: { page: 'batchList' },
  },
  {
    label: 'Отгрузка со склада',
    value: 'shipmentFromWarehouse',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.SHIPMENT_FROM_WAREHOUSE_SCAN,
  },
  {
    label: 'Заказ на отгрузку',
    value: 'shipmentOrder',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.SHIPMENT_ORDER_LIST,
  },
  {
    label: 'Генерация батчей комплектации',
    value: 'batchGeneration',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.BATCH_GENERATION_PHYSICAL_SET,
    params: { page: 'batchList' },
  },
  {
    label: 'Батчи',
    value: 'batch',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.BATCH,
    params: { page: 'batchList' },
  },
  {
    label: 'Компании перевозчиков',
    value: 'carrierCompanies',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.CARRIER_COMPANIES,
  },
  {
    label: 'Размещение товара',
    value: 'productPlacement',
    category: operations.acceptance.title,
    categoryId: OperationType.acceptance,
    path: PATHS.PRODUCT_PLACEMENT,
    params: { page: 'docList' },
    isTSD: true,
  },
  {
    label: 'Документы приемки',
    value: 'acceptanceDocuments',
    category: operations.acceptance.title,
    categoryId: OperationType.acceptance,
    path: PATHS.SUPPLY_PLAN,
  },
  {
    label: 'Приходные ордера',
    value: 'incomingOrders',
    category: operations.acceptance.title,
    categoryId: OperationType.acceptance,
    path: PATHS.RECEIPT__LIST,
  },
  {
    label: 'Отчеты',
    value: 'reports',
    category: operations.reports.title,
    categoryId: OperationType.reports,
    path: PATHS.REPORTS,
    params: { page: 'reports' },
  },
  {
    label: 'Операции и документы',
    value: 'operationsAndDocuments',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.OPERATIONS_AND_DOCUMENTS,
  },
  {
    label: 'Перемещение',
    value: 'goodsRelocation',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.GOODS_RELOCATION,
    isTSD: true,
  },

  {
    label: 'Настройки склада',
    value: 'warehouseSettings',
    path: PATHS.DIRECTORIES,
    params: { page: 'settings' },
  },

  {
    label: 'Печать стикеров',
    value: 'armPrinting',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.ARM_PRINTING,
  },
  {
    label: 'Справочники',
    value: 'guides',
    path: PATHS.DIRECTORIES,
    params: { page: 'guides' },
  },
  {
    label: 'Приемка возвратов',
    value: 'refundAcceptance',
    category: operations.acceptance.title,
    categoryId: OperationType.acceptance,
    path: PATHS.REFUND_ACCEPTANCE,
    params: { page: 'invoiceScanPage' },
  },
  {
    label: 'Сортировка батча',
    value: 'batchSorting',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.BATCH_SORTING,
    isTSD: true,
  },
  {
    label: 'Сортировка кроссдокингом',
    value: 'crossdockingSorting',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.CROSSDOCKING_SORTING,
  },
  {
    label: 'Сверка реестра',
    value: 'RegistryReconciliation',
    category: operations.unload.title,
    categoryId: OperationType.unload,
    path: PATHS.REGISTRY_SCAN,
    isTSD: true,
  },
  {
    label: 'Биллинг',
    value: 'Billing',
    category: operations.billing.title,
    categoryId: OperationType.billing,
    path: PATHS.BILLING,
  },
  {
    label: 'Дополнительные операции',
    value: 'additionalOperations',
    category: operations.billing.title,
    categoryId: OperationType.billing,
    path: PATHS.SERVICES_RENDERED,
  },
  {
    label: 'Сборка товаров ТСД',
    value: 'GoodsPickingTSD',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.GOODS_PICKING_TSD,
    isTSD: true,
  },
  {
    label: 'Сборка товаров на РМ',
    value: 'GoodsPicking',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.GOODS_PICKING,
  },
  {
    label: 'Перестикеровка товаров',
    value: 'GoodsRelabelArm',
    category: operations.warehouse.title,
    categoryId: OperationType.warehouse,
    path: PATHS.GOODS_RELABEL_SCAN,
  },
  {
    label: 'Управление инструкциями упаковки',
    value: 'PackingInstructions',
    category: operations.admin.title,
    categoryId: OperationType.admin,
    path: PATHS.PACKING_INSTRUCTIONS,
  },
]

import { useEffect, useState } from 'react'

type Props = {
  defaultState?: boolean
}
export const useToggle = (props?: Props) => {

  const [value, setValue] = useState<boolean>(props?.defaultState || false)

  const toggle = () => {
    setValue(prev => !prev)
  }

  return {value, toggle, setValue}
}

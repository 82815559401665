import { useMutation } from 'react-query'

import api from 'src/api'

type ResultType =  {
  data?: { success: boolean },
  code?: string,
  message?: string
}

const useCloseTare = () => {
  return useMutation(
    async (barcode: string) => {
      //Без пустого тела получаем ошибку с бэка
      const result: ResultType = await api.post(`/warehouse/container/${barcode}/close`, {})
      if (!result?.data?.success) {
        throw new Error(result.code)
      }
      return result.data
    },
  )
}

export default useCloseTare

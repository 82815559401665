import { useMutation } from 'react-query'
import api from 'src/api'

import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { provideMock } from '../../mocks'


interface Params {
  barcode: string
}

interface Result extends ApiReqResult {
  id: string
}

const getOrderID = async (params: Params) => {
  const result = await provideMock<Params, { data: Result }>(
    async () =>
      await api.get(`/packing-v2/order/id?search=${params.barcode}`),
    params,
    [
      {
        key: 'barcode',
        value: 'mock',
        result: {
          data: {
            id: 'mock',
            success: true
          }},
        logData: 'Получение id товара'
      },
      ],
  )()

  if (!result?.data?.success) {
    throw result
  }
  return result.data
}


const useGetOrderID = (options?: UseMutationOptions<Result, any, any>) => {
  return useMutation(getOrderID, options)
}

export default useGetOrderID

import { ICommonDocument, IDimensionsObj, CommonSerialNumberFilled, ISku, IWorkspace } from 'src/interfaces'
import { RequiredField } from '../../helpers'


export enum PhysicalSetOrderStatusEnum {
  NEW = "NEW",
  PICKING = "PICKING",
  PICKED = "PICKED",
  PACKING = "PACKING",
  PACKED = "PACKED",
  DONE = "DONE",
  CANCELED = "CANCELED",
}

export enum PhysicalSetOrderOperationEnum {
  ASSEMBLING = "ASSEMBLING",
  DISASSEMBLING = "DISASSEMBLING",
}


export const OperationDescription = {
  [PhysicalSetOrderOperationEnum.ASSEMBLING]: 'Сборка',
  [PhysicalSetOrderOperationEnum.DISASSEMBLING]: 'Разборка',
}


export interface IPhysicalSetOrderDto extends ICommonDocument {
  items: IPhysicalSetOrderItemDto[]
  places: IPhysicalSetOrderPlaceDto[]
  min_days_before_expiration_date: number
  operation: PhysicalSetOrderOperationEnum
  quantity: number
  sku: ISku
  sku_batch_id: string
  sku_condition: string
  status: PhysicalSetOrderStatusEnum
  workspace: IWorkspace
}

export interface IPhysicalSetOrderItemDto {
  quantity: number
  sku: ISku
  sku_batch_id: string
}

export enum PlaceStatusEnum {
  PACKING = "PACKING",
  PACKED = "PACKED",
}


export interface IPhysicalSetOrderPlaceDto extends ICommonDocument {
  items: IPhysicalSetOrderPlaceItemDto[]
  packaging_id: string
  tare_barcode?: string
  dimensions: IDimensionsObj
  status: PlaceStatusEnum
  update_ts: string
  uuid: string
  warehouse_comment: string
  weight: number
  workspace: IWorkspace
  sku_id: string
}

export interface IPhysicalSetOrderPlaceItemDto {
  barcode_used: string
  id: string
  packed_at: string
  quantity: number
  serial_numbers: CommonSerialNumberFilled[]
  sku_batch_id?: string
  tare_barcode?: string
}

/* Интерфейс создания / обновления комплекта */
export interface IPutPhysicalSetOrderPlaceInnerDto {
  items: IPutPhysicalSetOrderPlaceInnerItemDto[]
  tare_barcode?: string
  dimensions?: IDimensionsObj
  disassembled_place_id?: string
  packaging_id?: string
  status?: PlaceStatusEnum
  serial_numbers?: CommonSerialNumberFilled[]
  weight?: number
  id?: string
}

/* Интерфейс товара создания / обновления комплекта */
export interface IPutPhysicalSetOrderPlaceInnerItemDto {
  barcode_used: string,
  sku_id: string
  packed_at: string,
  quantity: number,
  packaging_id?: string,
  serial_numbers?: CommonSerialNumberFilled[],
  sku_batch_id?: string,
  tare_barcode?: string, // ШК используемой тары, требуется, если динаковые номенклатуры идут в разные тары
}



/* Интерфейс текущего комплекта */
export interface IDraftPhysicalSetPlace extends IPutPhysicalSetOrderPlaceInnerDto {
  workspace?: RequiredField<Partial<IWorkspace>, 'barcode'>
}

/* Интерфейс доавляемого товара в кмоплект */
export interface IDraftPhysicalSetPlaceItem extends IPhysicalSetOrderItemDto {
  barcode_used: string
}
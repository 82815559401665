import api from 'src/api'
import { ApiReqResult } from '../../interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { useQuery } from 'react-query'
import { DocumentsType } from 'src/updatedDesignVersion/shared/ui/NavigationMenu/routes'


interface IResult extends ApiReqResult {
  values: DocumentsType[]
}

const getReportsTypeList = async (): Promise<IResult> => {
  const result = await api.get('/report')
    if (!result?.data?.success) {
      throw result
    }
    return result.data
}
export const useGetReportsTypeList = (options?: UseQueryOptions<any>) => {
  return useQuery<IResult>(
    [`getReportsTypeList`],
    getReportsTypeList,
    { ...options },
  )
}

export const useFetchReportsTypeList = () => ({
  fetch: getReportsTypeList,
})

import api from 'src/api'
import { useMutation } from 'react-query'


const useDeleteReturn = () => {
  return useMutation(async (data: {returnId: string}) => {
    const result = await api.delete(`/return/${data.returnId}`)
    if (!result.data.success) {
      throw new Error('Delete return error')
    }
    return result.data
  })
}

export default useDeleteReturn

import { BrowserRouter, generatePath, Link } from 'react-router-dom'
import { PATHS } from '../../../config'
import { renderToStaticMarkup } from 'react-dom/server'
import React from 'react'

export function replaceTextInAngleBrackets(
  str: string,
  getPath: (splitUrl: string[]) => string = (splitUrl: string[]) =>
    generatePath(PATHS.OPERATIONS_AND_DOCUMENTS, {
      page: `${splitUrl[0]}`,
      id: `${splitUrl[1]}`,
    })
): string {
  /* генерируем ссылку в строке по специальному обозначению */
  const pattern = /<(.*?)>/g
  const matches = str.match(pattern)

  if (!matches) {
    return str
  }

  return str.replace(pattern, match => {
    const fullUrl = match.slice(1, -1) // Удаляем угловые скобки
    const splitUrl = fullUrl.split('#')
    const urlPathname = getPath(splitUrl)
    const component = (
      <BrowserRouter>
        <Link target={'_blank'} to={urlPathname}>{splitUrl[1]}</Link>
      </BrowserRouter>
    )
    return renderToStaticMarkup(component)
  })
}
import { TemplateFieldType } from '../types/tableGenerator/generatorTypes'
import { templateChecker } from './templateChecker'
import { DocumentTypeEnum } from '../../../interfaces'

export interface IInnerFieldValue {
  title: string
  value: string
}

export interface IField {
  id: number
  title: string
  document_type?: DocumentTypeEnum
  type: TemplateFieldType
  read_only?: boolean
  required: boolean
  values?: IInnerFieldValue[]
  fields?: IField[]
}

export interface IFieldValue {
  id: number
  value: string
  meta?: any
}

const generateFieldsRow = (arr) => {
  let result = []
  let i = 0
  while (i < arr.length) {
    result.push({ fields: arr.slice(i, i + 4) })
    i += 4
  }
  return result
}


export const generateFields = (fields: IField[], fieldValues: IFieldValue[], editState: boolean) =>
  generateFieldsRow(
    templateChecker(fields, fieldValues, editState).filter((item) => item !== null),
  )

import { useMutation } from 'react-query'
import api from 'src/api'
import { ApiReqResult, StickerBase } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'


type Params = {
  batch_id: string
  invoice?: boolean
  order_documents?: boolean
  picking_list?: boolean
  order_ids?: string
}

interface IApiResult extends StickerBase, ApiReqResult {
}

const useGetBatchDocsForPrint = (
  options?: UseMutationOptions<any, IApiResult, any>,
) => {
  return useMutation(
    async (params: Params[]) => {
      const res = await api.post(`/order-batch/docs-for-print`, params)
      if (!res?.data?.success) {
        throw res
      }
      return res.data
    },
    options
  )
}

export default useGetBatchDocsForPrint

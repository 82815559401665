import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import api from 'src/api'
import { convertObjectToURLParams } from 'src/helpers'
import { ApiReqResult, ICellPlace, IPaginationFilter, PaginationResult } from 'src/interfaces'

export interface IGetCellsListParams extends IPaginationFilter {
  search?: string
  barcode?: string[]
  title?: string[]
}
export interface GetCellsResult extends ApiReqResult, PaginationResult {
  cells: ICellPlace[]
}

export const useGetPlacesList = (
  params: IGetCellsListParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<GetCellsResult>(
    ['getPlacesList', params],
    async () => {
      const searchParams = convertObjectToURLParams(params)
      const result = await api.get(`/warehouse/place${searchParams}`)

      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}

import qz from 'qz-tray'
import { hextorstr, KEYUTIL, KJUR, stob64 } from 'jsrsasign'

export default () => {
  const privateKey = `-----BEGIN PRIVATE KEY-----
MIIEvgIBADANBgkqhkiG9w0BAQEFAASCBKgwggSkAgEAAoIBAQDodtuFKsTuiIB+
0qNRmHYKGTB7xp62XLdFlhGqdzGQZbJL+8wQv5pnCgbx2fm8QmdM3kcFyqzKQShJ
p5HsHe0dzfTv1qx2ptSJkGTMwx+yfeTnP1Fyyc+KQTQmbUiMmjjd6pQSt1MKeTnh
dWUIY5oQybfTWIa9v+D7PrJA7QbqJgkqHsuv1T3uIfPh+siZVI5jtzcozfVVPRzy
FM6ysriDqZlyBbKmt0Mg2u6oj4M2kYUrdvnXSZQTL+KdKhY9t4IpFDUrjpe0Nx/7
pCzyoRHK8JfAkWXRv7zcCOUoDBU2BZuNHjYLDq9gYAiOvJuX0F32GhLlxnasJd8g
A+gPI3EXAgMBAAECggEAK2blnXtawV5i/0q8i2N3xnLlhnaS82FkJm3aZoVSj68t
FtqwQwBDB7HrkgQsiGWte8CUahRpJmn756SNrhP4TXRCovTlxlFnpTd8Wm6j4GN4
9WPxBQmA4kNCRVpqDTZOH8DIXKY1ruLrV5jfgC14emHvHFqO7aaBjucpyM14WQbd
0+7R4llxZmAKgeDZGKmDFvIT41jP0Zvx54wCmLRZDfGAao+vqEcC6mriKB/dFbig
wdBRtjN2r7djjqk9c72Gh9Sv8xa6WkFtwuAk8ahmTDegkSurIbDTSO1ObOx91z6f
VUYBdXI+m8HF8CU9At125IhB6/24uIaBQoExsF6QAQKBgQD7qFHGsGOJRTzYd5YN
+EQe/kbGZGXWNvg2OgLTwmbgzF4AoYrmo+s963Dlajr3T4yBpYaMfGZWsZxSXVns
59PKE5Gxb0DpIk/wm4D3Qm+ZAf9Gmw8IlZ5K/EA/Kd1FnpbDFDXfk8pVV1Y3Fl61
nGph2kUU/ubSKEAR3GTeXCDTFwKBgQDsecDYyU/8IfXo6XHOQ51JTxH7UulvqJwH
s9iGmR2xrIeimsALMhu1LGwIV/rkU7Gb82E/cQpNojkyOa/wfb8ENhSjNOy93Tnz
ncZ83lxSG8DDB0sKWxiNtJazVhPOQg/j2yDv4GJ6YWeDRBRQGHhGjH49f8cw5VN6
j06cNA0SAQKBgA0MTyEkKCUH5/25RZh31OBegsDql7UhqID3RQpBvB/PuNgDiDYp
LZtKR9I9Tc1g3IIkWPOTx6KbFuXWXUFBS+PgW999SzpLQyXQikFHuo2OYvQAOilR
yKFOru4Xd5eSf3Kh66EqAoWmP5wfls2HzKRCMu7BwKoI2DmU2c4FrNYFAoGBALUR
IPWdZ44yXr3Bie+a3CNW1Pjwameh3h8TuTaOpSr3ZT+/aE7qRkb6RZo565TK9NLp
j0vpfS8miM8JVss5LXrHAhRZoPBcJVSBOYtBI8WHFJkAy5eZWvqeDUoyHaYv9UTN
87FYyW7ViFH9XeRaaDX0u3DRly68WagddN6sCFoBAoGBANtXPDUW/6+cSS0FAT3M
4aonL2DJpjJPnk+qZMYfbnKjWFo7GunXfGl47rQdq+P5uME5s++IB+mXo8cdmkf1
wO/Trtn3cJ/feUYf8edZ0+cSzBzICVCWOV7YISqIVZNvUBWEcjmucnslvSX3L/Hv
qNJlK+E9h8ejBV9NE/jWTchK
-----END PRIVATE KEY-----
`

  qz.security.setCertificatePromise(function (resolve: any, reject: any) {
    fetch('/cert.pem', {
      cache: 'no-store',
      headers: { 'Content-Type': 'text/plain' },
    }).then(function (data) {
      data.ok ? resolve(data.text()) : reject(data.text())
    })
  })

  qz.security.setSignaturePromise(function (toSign: any) {
    return function (resolve: (res: string) => void, reject: any) {
      try {
        var pk = KEYUTIL.getKey(privateKey)
        var sig = new KJUR.crypto.Signature({ alg: 'SHA1withRSA' }) // Use "SHA1withRSA" for QZ Tray 2.0 and older
        sig.init(pk)
        sig.updateString(toSign)
        var hex = sig.sign()
        //console.log('DEBUG: \n\n' + stob64(hextorstr(hex)))
        resolve(stob64(hextorstr(hex)))
      } catch (err) {
        console.error(err)
        reject(err)
      }
    }
  })
}

import React, { CSSProperties, memo } from 'react'
import styles from './TSDNomenclatureCountingTableRow.module.scss'

import cx from 'classnames'
import { IBarcode } from 'src/interfaces'
import { TSDTableCountingCell, TSDTableNomenclatureCell } from 'src/components/TSD'

export interface TSDNomenclatureCountingTableRowProps {
  sku_id?: string
  sku_batch_id?: string
  batchExpDate?: string
  className?: string,
  name: string,
  barcodes?: IBarcode[],
  quantity: number,
  maxQuantity: number,
  id?: string,
  itemId?: string,
  withEditQuantity?: boolean
  onClickEdit?: (sku_id: string, sku_batch_id?: string) => void
  withDeleteItem?: boolean
  onClickDelete?: (itemId: string, sku_id: string, sku_batch_id?: string) => void
  itemRef?: React.RefObject<HTMLDivElement>
  rowStyles?: CSSProperties
}

export const TSDNomenclatureCountingTableRow = memo((props: TSDNomenclatureCountingTableRowProps) => {
  const {
    id,
    itemId,
    name,
    barcodes,
    quantity,
    maxQuantity,
    sku_id,
    sku_batch_id,
    batchExpDate,
    className,
    withEditQuantity,
    onClickEdit,
    withDeleteItem,
    onClickDelete,
    itemRef,
    rowStyles
  } = props
  return (
    <div
      id={id}
      ref={itemRef}
      className={cx(styles.tsdTableRow, { [styles.complete]: quantity === maxQuantity }, className)}
      style={rowStyles}
    >
      <TSDTableNomenclatureCell
        name={name}
        batchExpDate={batchExpDate}
        barcodes={barcodes}
      />
      <TSDTableCountingCell
        quantity={quantity}
        maxQuantity={maxQuantity}
        withEditQuantity={withEditQuantity}
        onClickEdit={() => onClickEdit(sku_id, sku_batch_id)}
        withDeleteItem={withDeleteItem}
        onClickDelete={() => onClickDelete(itemId, sku_id, sku_batch_id)}
      />
    </div>
  )
})

import React from 'react'
import styles from './input-with-buton.module.scss'
import { InputWrapper } from '../index'
import { Button } from '@consta/uikit/Button'
import { IconEdit } from '@consta/icons/IconEdit'
import { InputWrapperProps } from '../InputWrapper/InputWrapper'
import { IconComponent } from '@consta/icons/Icon'

interface InputWithButtonProps {
  btnIcon?: IconComponent
  onClickBtn: () => void
  inputProps: InputWrapperProps
}
const InputWithButton = ({
  btnIcon = IconEdit,
  onClickBtn,
  inputProps,
}: InputWithButtonProps) => {
  return (
    <div className={styles.inputWithButtonWrapper}>
      <InputWrapper {...inputProps} />
      <Button
        onlyIcon
        view="ghost"
        className={styles.btn}
        iconLeft={btnIcon}
        size="m"
        onClick={onClickBtn}
      />
    </div>
  )
}
export default InputWithButton

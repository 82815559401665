import { IPrintOptions, usePrint } from './print'
import { usePreviewBase64Pdf } from 'src/hooks/index'
import useGetDefectSticker from '../../items/useGetDefectSticker'


/** Печать стикера места */

interface printResult {
  printItemDefectSticker: (params: IParams, isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}
interface IParams {
  sku_id: string,
  defect_id: string,
  doc_id: string,
  disable_billing?: boolean
}
export const usePrintItemDefectSticker = (): printResult => {
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const { printSticker } = usePrint()
  const { fetch: getDefectSticker } = useGetDefectSticker()

  const printItemDefectSticker = async (params: IParams, isPreview?: boolean, options: IPrintOptions = {}) => {
    return await getDefectSticker(params)
      .then((result) => {
        if (!result) { return }
        const { sticker: {printer, content}, stickerExists } = result

        if (isPreview) {
          previewBase64Pdf(content, 'Стикер брака')
          return
        }

        printSticker(printer, content, {
          copies: 1,
          stickerExists,
          ...options
        })
      })
      .catch(() => {

      })
  }

  return {
    printItemDefectSticker,
  }
}
import { useMutation } from 'react-query'
import api from 'src/api'

import { ApiReqResult, IError, IOrderFilter } from 'src/interfaces'

export interface IBatchResult {
  success: boolean
  errors?: IError[]
  id: string
  n: number
}

interface IApiResult extends ApiReqResult {
  results: IBatchResult[]
}

export interface IBatchGenerateGroupBy {
  dimensions_and_weight?: {
    dimensions_sum?: number
    weight?: number
  }

  merchant?: boolean
  warehouse_zone?: boolean
  delivery_company?: boolean
  order_items?: boolean
}

export interface IBatchGenerateData {
  filter?: IOrderFilter
  group_by?: IBatchGenerateGroupBy
  groups?: number[]
  orders?: string[]
  orders_in_batch?: number
}

const useBatchGenerate = (
  callback?: (results: IBatchResult[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useMutation(
    async (data: IBatchGenerateData) => api.post(`/order-batch/generate`, data),
    {
      onSuccess: ({ data }: { data: IApiResult }) => {
        if (data.success) {
          callback?.(data.results)
        } else {
          error?.()
        }
      },
      onError: () => error?.(),
      onSettled: () => settled?.(),
    },
  )
}

export default useBatchGenerate

import { useQuery } from 'react-query'
import api from 'src/api'

import {
  MOCK_GET_B2B_FORMATION,
  MOCK_GET_B2B_PACKING_BY_ID,
  MOCK_GET_B2B_PACKING_BY_ID_2,
  MOCK_GET_B2B_RELABELLING_BY_ID_2,
  MOCK_GET_PACKING_BY_ID,
  MOCK_GET_PACKING_LAMODA_FBS_B2C,
  MOCK_PROBLEM_PACKING_BARCODE,
  MOCK_START_PACKING_FAIL,
  provideMock,
} from 'src/mocks'

import {
  ApiReqResult,
  IOrder,
  IOrderItem,
  IOrderPackagingOption,
  IOrderPackagingPlace,
  IShippingFormItem,
} from 'src/interfaces'

interface UsePackingByIdParamsType {
  orderId: string
  type?: string
  enabled?: boolean
}

const getMethod = async (orderId: string, type?: string) => {
  const pathOrderBase = `/packing/order/${orderId}`
  if (type === 'relabel' || type === 'docs') {
    return await api.get(pathOrderBase)
  }
  return await api.post(`${pathOrderBase}/${type || 'start'}`)
}
const orderDataToFrontFormat = (order: IOrder) => {
  /** Конвертируем данные заказа в нужный фронтовой формат */
  return {
    ...order,
  }
}
export const getPackingById = async (params: UsePackingByIdParamsType) => {
  const result = await getMethod(params.orderId, params.type)
  if (!result.data?.success) throw new Error(result.data.message)
  return {
    ...result.data,
    order: orderDataToFrontFormat(result.data.order),
  }
}

export interface GetPackingByIdResult extends ApiReqResult {
  order: IOrder
  packagings: IOrderPackagingOption[]
}

const useGetPackingById = (
  params: UsePackingByIdParamsType,
  callback?: (
    items: IOrderItem[],
    places: IOrderPackagingPlace[],
    shippingForm?: IShippingFormItem[] | [],
    order?: IOrder
  ) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<GetPackingByIdResult>(
    [`getPackingById`, params],
    provideMock<UsePackingByIdParamsType, GetPackingByIdResult>(
      getPackingById,
      params,
      [
        {
          key: 'orderId',
          value: 'mock',
          result: MOCK_GET_PACKING_BY_ID,
          logData: 'Старт упаковки заказа b2c'
        },
        {
          key: 'orderId',
          value: 'mockLamodaFbs',
          result: MOCK_GET_PACKING_LAMODA_FBS_B2C,
          logData: 'Старт упаковки заказа Lamoda fbs (b2c)'
        },
        {
          key: 'orderId',
          value: 'failMock',
          result: MOCK_START_PACKING_FAIL as any,
          logData: 'Старт упаковки заказа (с ошибкой)'
        },
        {
          key: 'orderId',
          value: 'mockBarcodeproblem',
          result: MOCK_PROBLEM_PACKING_BARCODE,
          logData: 'Старт упаковки заказа'
        },
        {
          key: 'orderId',
          value: 'mockb2b',
          result: MOCK_GET_B2B_PACKING_BY_ID,
          logData: 'Старт упаковки заказа b2b'
        },
        {
          key: 'orderId',
          value: 'mockb2b_2',
          result: MOCK_GET_B2B_PACKING_BY_ID_2,
          logData: 'Старт упаковки заказа b2b'
        },
        {
          key: 'orderId',
          value: 'mockrelabel',
          result: MOCK_GET_B2B_RELABELLING_BY_ID_2,
          logData: 'Старт упаковки заказа'
        },
        {
          key: 'orderId',
          value: 'mock_formation',
          result: MOCK_GET_B2B_FORMATION,
          logData: 'Старт упаковки заказа fbo с формированием'
        },
      ],
    ),
    {
      enabled: params.enabled ?? !!params,
      keepPreviousData: true,
      onSuccess: ({ success, order }) => {
        const { items, places, shipping_form: shippingForm } = order
        if (success) {
          if (callback) {
            callback(items, places, shippingForm, order)
          }
        } else {
          if (error) {
            error()
          }
        }
      },
      onError: () => {
        if (error) {
          error()
        }
      },
      onSettled: () => {
        if (settled) {
          settled()
        }
      },
    },
  ) as { refetch: any; data: GetPackingByIdResult; isFetching: boolean}
}

export default useGetPackingById

import api from 'src/api'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'

import { ApiReqResult, IAcceptanceSku, ICellPlace, PaginationResult } from 'src/interfaces'

import { IReplaceDoc } from 'src/interfaces/replace'

export interface GetReplaceByIdResult extends ApiReqResult, PaginationResult {
  document: IReplaceDoc
  skus: IAcceptanceSku[]
  cells: ICellPlace[]
}

export const useGetReplaceById = (
  replaceId: string,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<GetReplaceByIdResult>(
    ['getReplaceList', replaceId],
    async () => {
      if (replaceId) {
        const result = await api.get(`/replace/${replaceId}`)
        return result.data
      }
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}

import { IPrintOptions, usePrint } from './print'
import { IItemStickerParams } from '../../items/useNewGetItemSticker'
import { usePreviewBase64Pdf } from '../usePreviewBase64Pdf'
import { useItemGetSticker } from 'src/hooks'
import { useCallback } from 'react'


/** Печать стикера товара */
interface printResult {
  printItemStickerById: (data: IItemStickerParams, isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}

export const usePrintItemSticker = (): printResult => {
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const { printSticker } = usePrint()

  const getItemSticker = useItemGetSticker()

  const printItemStickerById = useCallback(async (data: IItemStickerParams, isPreview?: boolean, options: IPrintOptions = {}) => {
    await getItemSticker
      .fetch(data)
      .then((result) => {
        if (!result.success) {
          return
        }
        const { sticker, stickerExists } = result
        if (!sticker) {
          console.error('Ошибка получения стикера')
          return
        }

        if (isPreview) {
          previewBase64Pdf(sticker.content, 'Стикер номенклатуры')
          return
        }
        printSticker(sticker.printer, sticker.content, {
          copies: options.copies || 1,
          stickerExists,
          ...options,
        })
      })
      .catch(() => {

      })
  }, [getItemSticker, previewBase64Pdf, printSticker])

  return {
    printItemStickerById,
  }
}
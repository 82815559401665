import React from 'react'
import cx from 'classnames'

import styles from './nomenclature-badge.module.scss'
import { Badge, BadgePropForm, BadgePropSize, BadgePropStatus } from '@consta/uikit/Badge'
import { BadgePropView } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import fragileImg from 'src/assets/images/fragile.svg'
import { IconComponent } from '@consta/icons/Icon'
import { BadgeTypeEnum } from 'src/interfaces'

interface IProps {
  type: BadgeTypeEnum
  form?: BadgePropForm
  view?: BadgePropView
  size?: BadgePropSize
  className?: string
}

interface BadgeItemProps {
  label: string
  view?: BadgePropView
  status?: BadgePropStatus
  icon?: IconComponent
}
export const NomenclatureBadge = (props: IProps) => {
  const { type, className, view, form = 'default', size = 'xs' } = props

  const badgesTypes: { [key: string]: BadgeItemProps } = {
    partOfSet: {
      view: 'stroked',
      label: 'Часть набора',
    },
    documents: {
      view: 'stroked',
      label: 'Документы',
    },
    surplus: {
      view: 'filled',
      label: 'Излишки',
    },
    shortages: {
      view: 'stroked',
      status: 'warning',
      label: 'Недостача',
    },
    defect: {
      view: 'filled',
      status: 'error',
      label: 'Брак',
    },
    fragile: {
      view: 'filled',
      status: 'error',
      label: 'Хрупкое',
      icon: () => <img src={fragileImg} alt="" />,
    },
  }

  return (
    <Badge
      className={cx(styles.badge, className)}
      {...badgesTypes[type]}
      form={form}
      size={size}
      view={view}
    />
  )
}

export interface IBadgeItem {
  key: BadgeTypeEnum
  visible: boolean
  view?: BadgePropView
}
export const NomenclatureBadgesList = ({
  badgesList = [], className
}: {
  badgesList: IBadgeItem[]
  className?: string
}) => {
  return (
    <div className={cx(styles.badgeList, className)}>
      {badgesList.map(({ visible, key, ...badgeProps }) => (
        <React.Fragment key={key}>
          {visible ? <NomenclatureBadge type={key} {...badgeProps} /> : null}
        </React.Fragment>
      ))}
    </div>
  )
}

import { ApiReqResult, IAcceptanceSku, ICellPlace, ICellSkuStock, ConditionEnum } from 'src/interfaces'
import { useQuery } from 'react-query'
import api from 'src/api'
import { UseQueryOptions } from 'react-query/types/react/types'
import { MOCK_FULL_BY_PLACES, provideMock } from '../../../mocks'

interface IGetCellStockByCellIdParams {
  /** Тип запроса остатков */
  type?: 'STORAGE_TOTAL' | 'FULL_BY_PLACES'
  /** Состояние товара */
  condition?: ConditionEnum
  /** Время, на которое нужно получить остатки */
  date?: string
  /** Места на складе */
  place_ids?: string[]
  /** Товары и партии */
  skus?: {
    sku_batch_id?: string
    sku_id: string
  }[]
}

export interface IGetCellStockByCellIdResult extends ApiReqResult {
  stock: ICellSkuStock[]
  skus: IAcceptanceSku[]
  places: ICellPlace[]
}


export const fetchItemsStock = async (params: IGetCellStockByCellIdParams): Promise<IGetCellStockByCellIdResult> => {
  const result = await api.post('/items/stock', {
    ...params,
    type: params.type || 'FULL_BY_PLACES',
  })
  if (!result.data?.success) {
    throw result || 'Ошибка получения стоков'
  }
  return result.data
}
export const useGetItemsStock = (
  params: IGetCellStockByCellIdParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<Promise<IGetCellStockByCellIdResult>, any, IGetCellStockByCellIdResult, [string, IGetCellStockByCellIdParams]>(
    [`getCellStockByCellId`, params],
    // provideMock<IGetCellStockByCellIdParams, IGetCellStockByCellIdResult>(
    async () => await fetchItemsStock(params),
    //   params,
    //   {
    //       key: 'place_ids',
    //       value: 'mock',
    //       result: MOCK_FULL_BY_PLACES,
    //     },
    // ),
    {
      enabled: true,
      cacheTime: 0,
      ...options,
    },
  )
}
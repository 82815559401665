import { Button, InputWithDateMask, InputWrapper, ItemPreview, Modal, TSDTableWrapper } from 'src/components'
import styles from './TSDSelectBatchModalV2.module.scss'
import React, { useState } from 'react'
import { IBatch, ISku, useCreateBatchResult } from 'src/interfaces'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { IconCalendar } from '@consta/icons/IconCalendar'
import dayjs from 'dayjs'
import { DATE_FORMAT, DATE_FORMAT_VIEW } from 'src/helpers'
import { useCreateBatch } from 'src/hooks'

interface ISelectBatchModal {
  onSubmit: (selectedBatchId: string, batch: IBatch) => void
  onClose: () => void
  sku: ISku
  isOpen: boolean
}

const TSDSelectBatchModalV2 = ({
  onSubmit,
  sku,
  onClose,
  isOpen,
}: ISelectBatchModal) => {
  const [batchId, setBatchId] = useState('')
  const [changeModal, setChangeModal] = useState(false)
  const [batchNum, setBatchNum] = useState<TextFieldPropValue>(null)
  const [batchDate, setBatchDate] = useState<TextFieldPropValue>(null)

  const createBatchMutation = useCreateBatch({ itemId: sku.id ?? '' })

  const headerTitle = !changeModal
    ? 'Проверка партии товара'
    : 'Создание новой партии'

  const headerArticle = !changeModal
    ? 'Выберите партию из списка или создайте новую'
    : 'Введите название партии и срок годности'

  const handleAddNewBatch = async () => {
    const newBatch = {
      num: batchNum ? String(batchNum) : undefined,
      exp_date: batchDate
        ? dayjs(batchDate, DATE_FORMAT_VIEW).format(DATE_FORMAT)
        : undefined,
    }

    createBatchMutation
      .mutateAsync({
        batch: newBatch,
      })
      .then((result: useCreateBatchResult) => {
        onSubmit(result.id, {id: result.id, ...newBatch})
      })
  }

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      withClose
      onClose={() => {
        if (changeModal) {
          setChangeModal(false)
          return
        }
        onClose()
      }}
      subtitle={''}
      className={styles.modal}
    >
      <div className={styles.header}>
        <h2>{headerTitle}</h2>
        <h4>{headerArticle}</h4>
      </div>
      <div className={styles.top}>
        <ItemPreview
          image={sku.image}
          title={sku.title}
          article={sku.article}
          imgClassName={styles.img}
        />
      </div>
      {!changeModal ? (
        <div className={styles.selectBatch}>
          <TSDTableWrapper
            header={<></>}
            tableClassName={styles.tableWrapper}
            maxTableHeight={180}
          >
            {sku.batches.map((batch, i) => (
              <div
                key={batch.id}
                className={styles.batchItem}
                style={{
                  backgroundColor: batchId === batch.id
                    ? 'rgba(0, 32, 51, 0.3)'
                    : 'white',
                }}
                onClick={() => setBatchId(batch.id)}
              >
                <div className={styles.batch}>
                  <div className={styles.batchtitle}>Срок годности, до</div>
                  <div className={styles.batchvalue}>
                    {dayjs(batch.exp_date).format(DATE_FORMAT_VIEW) || '-'}
                  </div>
                </div>
              </div>
            ))}
          </TSDTableWrapper>
          <div className={styles.footer}>
            <Button
              label={'Подтвердить партию'}
              size={'s'}
              disabled={!batchId}
              onClick={() => {
                const batch = sku.batches.find(batch => batch.id === batchId)
                if (batch) {
                  onSubmit(batchId, batch)
                }
              }}
            />
            <Button
              view="secondary"
              size={'s'}
              label={'Создать новую партию'}
              onClick={() => setChangeModal(true)}
            />
          </div>
        </div>
      ) : (
        <div className={styles.addbatch}>
          <InputWrapper
            autoFocus
            value={batchNum}
            id={'batchNum'}
            handleChange={(value: TextFieldPropValue) =>
              setBatchNum(String(value))
            }
            className={styles.itemInput}
            placeholder={'№ партии'}
            size="s"
          />
          <InputWithDateMask
            value={batchDate}
            id={'dateTo'}
            handleChange={(value: TextFieldPropValue) => setBatchDate(value)}
            className={styles.itemInput}
            size="s"
            placeholder={DATE_FORMAT_VIEW}
            rightSide={IconCalendar}
          />
          <div className={styles.footer}>
            <Button
              label="Создать партию"
              size={'s'}
              className={styles.btn}
              disabled={changeModal ? !batchNum || !batchDate : !batchNum}
              onClick={handleAddNewBatch}
            />
          </div>
        </div>
      )}
    </Modal>
  )
}
export default TSDSelectBatchModalV2

import api from 'src/api'

import { MOCK_SAVE_REGISTER, provideMock } from 'src/mocks'

import { ApiReqResult, IPlaceItem } from 'src/interfaces'

interface useSaveInterruptedRegisterParamsType {
  number: string
  items: IPlaceItem[]
}

const saveInterruptedRegister = async (
  params: useSaveInterruptedRegisterParamsType,
) => {
  const result = await api.post(
    `/getregistry/registry/${params.number}/savedata`,
    {
      number: params.number,
      items: params.items,
    },
  )

  if (!result.data?.success) {
    throw result.data
  }
  return result.data
}

const useSaveInterruptedRegister = () => ({
  fetch: (params: useSaveInterruptedRegisterParamsType) =>
    provideMock<useSaveInterruptedRegisterParamsType, ApiReqResult>(
      saveInterruptedRegister,
      params,
      {
        key: 'number',
        value: 'mock',
        result: MOCK_SAVE_REGISTER,
      },
    )(),
})

export default useSaveInterruptedRegister

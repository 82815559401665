import React, { useEffect } from 'react'
import cx from 'classnames'
import { useNotifications } from 'src/notification'
import styles from './template-wrapper.module.scss'

import { AppVersion } from 'src/components'

import { TemplatesEnum } from 'src/config'
import { PrinterProvider } from '../../printer'

interface TemplateWrapperProps {
  template?: TemplatesEnum
}

const TemplateWrapper = (
  props: React.PropsWithChildren<TemplateWrapperProps>,
) => {
  const { children, template } = props

  const isSingleTemplate = template === TemplatesEnum.single
  const isTSDTemplate = template === TemplatesEnum.TSD

  const notification = useNotifications()

  useEffect(() => {
    /**
     * todo better
     * Скрывает оповещение если в TSD режиме
     * */
    if (notification?.state?.qzStartError && isTSDTemplate) {
      notification?.clear('qzStartError')
    }
  }, [notification?.state?.qzStartError])

  return (
    <div className={cx(styles.wrapper, styles.tsd)}>
      {!isTSDTemplate ? (
        // для ТСД мы не используется принтер
        <PrinterProvider>{children}</PrinterProvider>
      ) : (
        // todo возможно, стоит сюда вынести варппер tsd (подумать)
        children
      )}
      {!isSingleTemplate ? <AppVersion className={styles.version} /> : null}
    </div>
  )
}

export default TemplateWrapper

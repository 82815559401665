import { ITableColumn } from 'src/interfaces'
import { BatchCell, ImageCell, NomenclatureNameCell } from '../cells'
import React from 'react'
import { IconAdd } from '@consta/icons/IconAdd'
import { Button } from '@consta/uikit/Button'

type ExtraArgsType = {
  extraColumns?: ITableColumn[]
}
export const nomenclatureColumns = (args?: ExtraArgsType): ITableColumn[] => {
  /** Генерация столбцов номенклатуры */
  return [
    {
      key: 'img',
      title: 'Фото',
      title_txt: 'Фото',
      gridCellWidth: '90px',
      renderCell: ({ row }: any) => {
        return <ImageCell image={row.image} />
      },
    },
    {
      key: 'name',
      title: 'Номенклатура',
      title_txt: 'Номенклатура',
      gridCellWidth: '5fr',
      renderCell: ({row}) => {
        return (
          <NomenclatureNameCell
            title={row.skuTitle as string || '-'}
            article={row.skuArticle as string || '-'}
          />
        )
      }
    },
    {
      key: 'quantity',
      title: 'Кол-во',
      title_txt: 'Кол-во',
      gridCellWidth: '150px',
      align: 'right',
    },
    {
      key: 'measure',
      title: 'Едн.',
      title_txt: 'Едн.',
      gridCellWidth: '90px',
    },
    {
      key: 'party',
      title: 'Партия',
      title_txt: 'Партия',
      gridCellWidth: '190px',
      renderCell: ({ row, index }) => {
        return (
          <BatchCell
            num={row.batchNum as string || ''}
            date={row.batchDate as string || ''}
          />
        )
      },
    },
    ...(args?.extraColumns || []),
  ]
}

type ColumnsWithAddBtnType = {
  handleClickAdd?: (id: string) => void
} & ExtraArgsType
export const nomenclatureColumnsWithAddBtn = (args: ColumnsWithAddBtnType): ITableColumn[] => {
  return nomenclatureColumns({
    extraColumns: [
      {
        key: 'action__add',
        title: (
          <Button
            onlyIcon
            iconLeft={IconAdd}
            view={'clear'}
            size={'s'}
            disabled={true}
            style={{opacity: 0, height: '0px'}}
          />
        ),
        title_txt: 'action__add',
        isAction: true,
        align: 'right',
        renderCell: ({ row }) => {
          return (
            <Button
              onlyIcon
              iconLeft={IconAdd}
              view={'clear'}
              size={'s'}
              onClick={(e) => args.handleClickAdd?.(row.id)}
            />
          )
        },
      },
      ...(args.extraColumns || []),
    ],
  })
}
import { useState } from 'react'
import { ITableRow } from 'src/interfaces'
import { SortEnum } from '../../../updatedDesignVersion/shared/types/sort'

type PropsType = {
  rows: ITableRow[]
}
const useSort = (props: PropsType) => {
  const {
    rows,
  } = props

  const [sort, setSort] = useState<null | boolean>(null)

  const handleSortRows = (key: string) => {
    setSort(prev => {
      if (sort === null) return true
      if (sort) return false
      return null
    })

    const comparison = sort ? 1 : -1

    return rows.sort((a: any, b: any) => {
      if (a[key] > b[key]) {
        return comparison
      }
      if (a[key] < b[key]) {
        return -comparison
      }

      return 0
    })
  }

  return {
    sort: sort ? SortEnum.DESC : SortEnum.ASC,
    handleSortRows
  }
}
export default useSort
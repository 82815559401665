import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ICombobox } from 'src/interfaces'
import { generateStatusesOptions } from 'src/helpers'
import { PhysicalSetOrderStatusEnum } from '../physicalSet'


export const PHYSICAL_SET_STATUSES: Record<PhysicalSetOrderStatusEnum | string, string> = {
  [PhysicalSetOrderStatusEnum.NEW]: "Новый",
  [PhysicalSetOrderStatusEnum.PICKING]: "Подбор",
  [PhysicalSetOrderStatusEnum.PICKED]: "Подобран",
  [PhysicalSetOrderStatusEnum.PACKING]: "На упаковке",
  [PhysicalSetOrderStatusEnum.PACKED]: "Упакован",
  [PhysicalSetOrderStatusEnum.DONE]: "Выполнен",
  [PhysicalSetOrderStatusEnum.CANCELED]: "Отменен",
}

export const PHYSICAL_SET_STATUSES_STYLES: Record<PhysicalSetOrderStatusEnum | string, BadgePropStatus> = {
  [PhysicalSetOrderStatusEnum.NEW]: 'system',
  [PhysicalSetOrderStatusEnum.PICKING]: 'normal',
  [PhysicalSetOrderStatusEnum.PICKED]: 'warning',
  [PhysicalSetOrderStatusEnum.PACKING]: 'normal',
  [PhysicalSetOrderStatusEnum.PACKED]: 'success',
  [PhysicalSetOrderStatusEnum.DONE]: 'warning',
  [PhysicalSetOrderStatusEnum.CANCELED]: 'error',
}

export const physicalSetSelectOptions: ICombobox[] = generateStatusesOptions(PHYSICAL_SET_STATUSES)

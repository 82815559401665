import { ApiReqResult } from './api'
import { ISku } from './items'


export enum InventorySoundStatusEnum {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  FINISH = "FINISH",
}
export type InventoryType = {
  cell_barcode: string
  id: string
  items: InventoryItemType[]
}
export type InventoryItemType = {
  quantity: number
  quantity_plan: number
  sku_batch_id: string
  sku_id: string
  id: string
}

export type PartialInventoryRequestResult = {
  inventory: InventoryType
  skus: ISku[]
  sound_alert: InventorySoundStatusEnum
} & ApiReqResult
import api from 'src/api'
import { useMutation } from 'react-query'
import { provideMock } from 'src/mocks'
import { ApiReqResult } from 'src/interfaces'

interface useCloseSupplyPartParamsType {
  supplyId: string
}

const useReopenSupplyPart = (params: useCloseSupplyPartParamsType) => {
  return useMutation(async (data: { receiptId: string }) => {
    try {
      const result = await provideMock<
        useCloseSupplyPartParamsType,
        { data: ApiReqResult }
      >(
        async () =>
          await api.post(
            `/receiving/supply/${params.supplyId}/part/${data.receiptId}/open`,
          ),
        params,
        {
          key: 'supplyId',
          value: 'mock',
          result: {
            data: {
              success: true,
            },
          },
        },
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useReopenSupplyPart

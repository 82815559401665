import { useQuery } from 'react-query'
import api from 'src/api'

import { MOCK_GET_SUPPLY_BY_ID, provideMock, BIG_ACCEPTANCE } from 'src/mocks'

import { ApiReqResult, IAcceptanceSku, ISupply } from 'src/interfaces'

interface UseSupplyByIdParamsType {
  supplyId: string
}

const getSupplyById = async (params: UseSupplyByIdParamsType) => {
  const result = await api.get(`/receiving/supply/${params.supplyId}`)
  if (!result.data?.success) throw new Error(result.data.message)
  return result.data
}

interface GetSupplyByIdResult extends ApiReqResult {
  supply: ISupply
  skus: IAcceptanceSku[]
}

const useGetSupplyById = (
  params: UseSupplyByIdParamsType,
  callback?: (supply: ISupply, originalItems: IAcceptanceSku[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<GetSupplyByIdResult>(
    [`getSupplyById`, params],
    provideMock<UseSupplyByIdParamsType, GetSupplyByIdResult>(
      getSupplyById,
      params,
      [
        {
        key: 'supplyId',
        value: 'mock2',
        result: MOCK_GET_SUPPLY_BY_ID,
      },
        {
        key: 'supplyId',
        value: 'mock',
        result: BIG_ACCEPTANCE,
      },
      ]
    ),
    {
      enabled: !!params,
      keepPreviousData: true,
      onSuccess: (data) => {
        if (data?.success) {
          callback?.(data.supply, data.skus)
        } else {
          error?.()
        }
      },
      onError: error,
      onSettled: settled,
    },
  )
}

export default useGetSupplyById

import { useMutation } from 'react-query'

import api from 'src/api'


type DataType = {
  order_id?:string
  place_barcode?:string
  tare_barcode: string
}
const useAcceptPlaceByOrder = () => {
  return useMutation(
    async (data: { data: DataType; returnId: string }) => {
      const result = await api.post(`/return/${data.returnId}/place-by-order`, data.data)
      if (!result?.data?.success) {
        throw result
      }
      return result.data
    },
  )
}

export default useAcceptPlaceByOrder

import React, { useState } from 'react'

import styles from './ErrorModal.module.scss'

import img from './assets/ConnectionError.svg'

import { Button } from '@consta/uikit/Button'
import { IconRestart } from '@consta/uikit/IconRestart'

import { Modal } from 'src/components'

import { IError, ModalProps } from 'src/interfaces'

interface ErrorModalProps extends ModalProps {
  title: string,
  extraSlotErrorText?: React.ReactNode
  errorData: IError
  onRetry: () => Promise<void>
}

const ErrorModal = (props: ErrorModalProps) => {
  const {
    title,
    isOpen,
    onClose,
    onRetry,
    errorData,
    extraSlotErrorText = null
  } = props

  const [loading, setLoading] = useState<boolean>(false)

  const handleRetry = () => {
    setLoading(true)

    onRetry()
      .finally(() => setLoading(false))
  }

  return (
    <Modal
      withClose
      hasOverlay
      isOpen={isOpen}
      onOverlayClick={onClose}
      className={styles.error}
      title={title}
      size="s"
      img={img}
      imgClass={styles.img}
      closeClass={styles.close}
      onClose={onClose}
    >
      <div key={`error`} className={styles.errorMes}>
        {extraSlotErrorText}
        <p>
          Код ошибки: {errorData.code}. {errorData.message}
        </p>
      </div>

      <div className={styles.btns}>
        <Button
          label="Повторить"
          view="primary"
          loading={loading}
          className={styles.repeat}
          onClick={handleRetry}
          size="l"
          iconLeft={IconRestart}
        />
      </div>
    </Modal>
  )
}

export default ErrorModal

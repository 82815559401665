import { useMutation } from 'react-query'
import api from 'src/api'
import { IReplaceItem } from 'src/interfaces/replace'

export interface ICreateReplaceParams {
  items: IReplaceItem[]
}

export const useCreateReplace = () => {
  const createReplace = async (params: ICreateReplaceParams) => {
    const result = await api.post(`/replace`, params)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation('createReplace', createReplace)
}
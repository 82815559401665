import React from 'react'

const IconPDF = (props: any) => (
  <svg
    width='30' height='40' viewBox='0 0 30 40' fill='none' xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M28 0H2C0.89543 0 0 0.89543 0 2V38C0 39.1046 0.89543 40 2 40H28C29.1046 40 30 39.1046 30 38V2C30 0.89543 29.1046 0 28 0Z'
      fill='#FB6A6A' />
    <path opacity='0.6'
          d='M15.0423 17.1833C15.4017 17.3492 15.327 17.5642 15.2165 18.5799C15.1036 19.6276 14.7251 21.5415 13.996 23.4241C13.2661 25.3037 12.1832 27.1506 11.2394 28.5176C10.2964 29.8854 9.48937 30.7742 8.87396 31.2875C8.25617 31.8008 7.8292 31.9379 7.55012 31.9831C7.27343 32.0283 7.14781 31.9831 7.07307 31.8452C6.99833 31.7081 6.97209 31.4822 7.03649 31.2065C7.0993 30.9339 7.24958 30.6145 7.67575 30.2056C8.1059 29.7951 8.80956 29.2926 9.79151 28.8261C10.7735 28.3587 12.0313 27.9256 13.0641 27.6288C14.097 27.3336 14.9016 27.1732 15.6061 27.0478C16.3121 26.9216 16.9156 26.832 17.5071 26.7853C18.0987 26.7416 18.6791 26.7416 19.2452 26.7853C19.8113 26.832 20.3647 26.9216 20.8442 27.0369C21.3212 27.1506 21.7251 27.2869 22.0774 27.505C22.4288 27.72 22.7317 28.0168 22.882 28.3486C23.0347 28.6781 23.0347 29.0442 22.9082 29.3051C22.7826 29.5676 22.5298 29.7265 22.2411 29.8192C21.9517 29.9088 21.6249 29.9314 21.2338 29.8192C20.8442 29.7039 20.3894 29.4531 19.9123 29.1228C19.4345 28.7918 18.9304 28.3813 18.2768 27.7433C17.6216 27.1054 16.8154 26.24 16.1364 25.3964C15.4574 24.5528 14.9016 23.7326 14.5239 23.037C14.1478 22.3414 13.9459 21.772 13.7821 21.2026C13.6183 20.6317 13.4927 20.0623 13.4426 19.5497C13.3925 19.0372 13.4156 18.5799 13.4664 18.2146C13.5165 17.8516 13.5937 17.5759 13.7185 17.3928C13.8441 17.2137 14.0214 17.1202 14.1471 17.075C14.2727 17.0299 14.349 17.0299 14.4237 17.0182C14.4985 17.0065 14.5732 16.9839 14.6885 17.0182C14.7982 17.0509 14.9437 17.1381 15.0892 17.2269'
          stroke='white' strokeMiterlimit='10' strokeLinejoin='round' />
    <path fillRule='evenodd' clipRule='evenodd'
          d='M20.35 6C20.2433 6 20.1567 6.03 20.09 6.09C20.03 6.14333 20 6.21333 20 6.3V7H21V8H20V11H21V12H18V11H19V8H18V7H19V6.1C19 5.76 19.0967 5.49333 19.29 5.3C19.4833 5.1 19.7333 5 20.04 5H21.5V6L20.35 6ZM8.8 7V7.24C9.00667 7.08 9.27333 7 9.6 7H10.3C10.8867 7 11.3167 7.12667 11.59 7.38C11.8633 7.62667 12 8.03333 12 8.6V10.4C12 10.96 11.86 11.3667 11.58 11.62C11.3067 11.8733 10.88 12 10.3 12H9.01V14H8V7H8.8ZM9.65 8C9.43 8 9.26667 8.06 9.16 8.18C9.05333 8.29333 9 8.41667 9 8.55L9.01 11H10.25C10.4967 11 10.6833 10.9433 10.81 10.83C10.9367 10.7167 11 10.5567 11 10.35V8.65C11 8.44333 10.9367 8.28333 10.81 8.17C10.6833 8.05667 10.4967 8 10.25 8H9.65ZM15.2 12C15.5133 12 15.78 11.9233 16 11.77V12H17V5H16V7H14.76C14.18 7 13.69 7.12667 13.41 7.38C13.1367 7.63333 13 8.04 13 8.6V10.4C13 10.9667 13.1367 11.3767 13.41 11.63C13.6833 11.8767 14.1133 12 14.7 12H15.2ZM15.84 10.83C15.7333 10.9433 15.57 11 15.35 11H14.75C14.5033 11 14.3167 10.9433 14.19 10.83C14.0633 10.7167 14 10.5567 14 10.35V8.65C14 8.44333 14.0633 8.28333 14.19 8.17C14.3167 8.05667 14.5033 8 14.75 8H16V10.45C16 10.5833 15.9467 10.71 15.84 10.83Z'
          fill='white' />
  </svg>
)

export default IconPDF

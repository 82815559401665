import { IReducerAction, IReducerState } from './storeInterfaces'
import { SET_CURRENT_TEMPLATE, SET_MODAL_CELLS, SET_MODAL_SKUS, SET_TEMPLATES } from './actions'

export const initialState: IReducerState = {
  templates: [],
  currentTemplate: null,
  modalSkus: [],
  modalCells: [],
}

export const reducer = (
  state: IReducerState,
  action: IReducerAction,
): IReducerState => {
  const { type, payload } = action

  console.log(type, payload)
  switch (type) {
     case SET_TEMPLATES:
      return {
        ...state,
        templates: payload,
        currentTemplate: payload.length ? payload[0] : null
      }

    case SET_CURRENT_TEMPLATE:
      return {
        ...state,
        currentTemplate: payload
      }
      
    case SET_MODAL_SKUS:
      return {
        ...state,
        modalSkus: payload,
      }

    case SET_MODAL_CELLS:
      return {
        ...state,
        modalCells: payload,
      }


    default:
      return state
  }
}

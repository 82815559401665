import React, { useMemo, useState } from 'react'

import { SelectModal } from 'src/components'

import { ISelectListOption, ItemPreviewType, IZone, ModalProps } from 'src/interfaces'

interface ISelectZoneModal extends ModalProps {
  zones: IZone[]
  onSubmit: (zoneId: string) => void
}

const SelectZoneModal = (props: ISelectZoneModal) => {
  const { isOpen, onSubmit, zones, onClose } = props

  const [selectedItem, setSelectedItem] = useState<ISelectListOption | null>(null)

  const zonesOptions = useMemo(() => {
    return zones.map((zone) => ({
      id: zone.id,
      value: zone.id,
      label: zone.title,
    }))
  }, [zones]);

  const handleClickSubmit = () => {
    if (!selectedItem) return
    onSubmit(selectedItem.id)
  }

  return (
    <SelectModal
      isOpen={isOpen}
      title={'Выберите зону из списка'}
      selectLabel={''}
      options={zonesOptions}
      selectedItem={selectedItem}
      setSelectedItem={setSelectedItem}
      onSubmit={handleClickSubmit}
      onClose={onClose}
    />
  )
}

export default SelectZoneModal

import React from 'react'
import { ComboboxWrapperWithApi } from 'src/components'
import { useGetCellsList } from 'src/hooks/warehouse/cell/useGetCellsList'
import { ICellPlace } from 'src/interfaces'
import { ComboboxItem, CommonFieldProps } from '../../types/props'

interface IProps extends CommonFieldProps<ComboboxCellItem> {
  convertHookData?: (data: { cells: ICellPlace[] }) => any
}

export type ComboboxCellItem = ComboboxItem<ICellPlace>

const ComboboxCells = (props: IProps) => {
  const {
    label = 'Ячейка',
    placeholder = 'Выберите ячейку',
    convertHookData = (data) => {
      return data.cells.map((el) => ({ label: el.title, id: el.id, dto: el }))
    },
    convertHookParams = (params) => ({
      search: params.search
    }),
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      label={label}
      placeholder={placeholder}
      useGetHookSearch={useGetCellsList}
      convertHookParams={convertHookParams}
      convertHookData={convertHookData}
      {...restProps}
    />
  )
}
export default ComboboxCells

import { ConditionEnum } from '../'
import { BadgePropStatus } from '@consta/uikit/Badge'

export const WORKSPACES_STATUSES: Record<
  ConditionEnum | string,
  string
> = {
  [ConditionEnum.GOOD]: 'Годный',
  [ConditionEnum.DEFECT]: 'Брак',
}
export const WORKSPACE_STATUS_BADGE_TYPE: Record<
  ConditionEnum | string,
  BadgePropStatus
> = {
  [ConditionEnum.GOOD]: 'system',
  [ConditionEnum.DEFECT]: 'error',
}

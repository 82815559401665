/* с партиями */
export const GET_PICKING_BY_ID: any = {
  success: true,
  'cells': [
    {
      'access_level': 0,
      'barcode': 'K010101',
      'condition': 'GOOD',
      'id': '372d3f87-7cc3-4177-8431-bfcfe337c3ac',
      'is_blocked': false,
      'title': 'A-01-01-01',
      'topology': {
        'bay': 1,
        'row': 1,
        'tier': '1',
      },
      'type': 'CELL',
      'zone_id': 'c6322eed-03e9-4cf3-8f16-8828bac6b4fc',
    },
    {
      'access_level': 0,
      'barcode': 'A010102',
      'condition': 'GOOD',
      'id': '372d3f87-7cc3-4177-8431-bfcfe337c3a2',
      'is_blocked': false,
      'title': 'A-01-01-02',
      'topology': {
        'bay': 1,
        'row': 1,
        'tier': '1',
      },
      'type': 'CELL',
      'zone_id': 'c6322eed-03e9-4cf3-8f16-8828bad6b4fc',
    },
  ],
  'picking': {
    'activated': true,
    'create_ts': '2023-04-03T11:21:08.355064',
    'date': '2023-04-03T11:21:08.344454',
    'executor': {
      'enabled': true,
      'id': '69e2c608-88c9-4a32-87a9-1401736228b0',
      'login': '123',
      'name': '123',
      'patronymic': '123',
      'position': 'OPERATOR',
      'roles': [
        'e545d9ad-c079-4c11-9005-a775438549a2',
      ],
      'surname': '123',
    },
    'id': 'PIK-0000-0053',
    'items': [
      {
        'cell_barcode': 'K010101',
        'errors': [],
        'position': 0,
        'quantity': 0,
        'quantity_plan': 1,
        'sku_id': 'TM-1040',
        'sku_batch_id': `some-batch-num0`,
      },
      {
        'cell_barcode': 'K010101',
        'errors': [],
        'position': 0,
        'quantity': 0,
        'quantity_plan': 2,
        'sku_id': 'TM-1040',
        'sku_batch_id': `some-batch-num1`,
      },
      {
        'cell_barcode': 'A010102',
        'errors': [],
        'position': 0,
        'quantity': 0,
        'quantity_plan': 2,
        'sku_id': 'TM-1040',
        'sku_batch_id': `some-batch-num1`,
      },
      {
        'cell_barcode': 'A010102',
        'errors': [],
        'position': 0,
        'quantity': 1,
        'quantity_plan': 2,
        'sku_id': 'TM-1040',
        'sku_batch_id': `some-batch-num2`,
      },
      {
        'cell_barcode': 'A010102',
        'errors': [],
        'position': 0,
        'quantity': 1,
        'quantity_plan': 2,
        'sku_id': 'TM-test',
        'sku_batch_id': `some-batch-num1`,
      },
    ],
    'status': 'PROCESSING',
    'update_ts': '2023-08-22T14:23:59.488541',
    'uuid': '98a0785f-d7dd-44b6-bb9a-9a46fe296712',
  },
  'skus': [
    {
      'article': 'KTS',
      'barcodes': [
        {
          'active': true,
          'barcode': '100020003000',
          'type': 'COMMON',
          'used_in_last_supply': true,
        },
        {
          'active': true,
          'barcode': '110020003000',
          'type': 'WILDBERRIES',
          'used_in_last_supply': false,
        },
      ],
      'batch_accounting': false,
      'batch_expdate_mandatory': false,
      'batches': [
        {
          id: 'some-batch-id0',
          num: 'some-batch-num0',
          exp_date: '2022-10-01',
        },
        {
          id: 'some-batch-id1',
          num: 'some-batch-num1',
          exp_date: '2022-11-01',
        },
      ],
      'dimensions_fact': {
        'depth': 10,
        'height': 30,
        'width': 20,
      },
      'dimensions_plan': {
        'depth': 100,
        'height': 100,
        'width': 100,
      },
      'external_id': '1040',
      'fragile': false,
      'id': 'TM-1040',
      'image': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b.png',
      'image_preview': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b-100.png',
      'images': [
        {
          'position': 0,
          'preview_url': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b-100.png',
          'url': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b.png',
        },
      ],
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'parts': [],
      'require_dimensions': false,
      'require_weight': false,
      'scan_serial_numbers': [],
      'title': 'Кактус',
      'type': 'SKU',
      'weight_fact': 500,
      'weight_plan': 1000,
    },
    {
      'article': 'KTS',
      'barcodes': [
        {
          'active': true,
          'barcode': '100020003000',
          'type': 'COMMON',
          'used_in_last_supply': true,
        },
        {
          'active': true,
          'barcode': '110020003000',
          'type': 'WILDBERRIES',
          'used_in_last_supply': false,
        },
      ],
      'batch_accounting': false,
      'batch_expdate_mandatory': false,
      'batches': [
        {
          id: 'some-batch-id0',
          num: 'some-batch-num0',
          exp_date: '2022-10-01',
        },
        {
          id: 'some-batch-id1',
          num: 'some-batch-num1',
          exp_date: '2022-11-01',
        },
      ],
      'dimensions_fact': {
        'depth': 10,
        'height': 30,
        'width': 20,
      },
      'dimensions_plan': {
        'depth': 100,
        'height': 100,
        'width': 100,
      },
      'external_id': '1040',
      'fragile': false,
      'id': 'TM-test',
      'image': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b.png',
      'image_preview': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b-100.png',
      'images': [
        {
          'position': 0,
          'preview_url': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b-100.png',
          'url': 'https://storage.yandexcloud.net/images-k2c/a0817438-1093-47ba-9733-ffa38166a40b.png',
        },
      ],
      'merchant': {
        'cam_id': '101',
        'contract_date': '2022-09-25',
        'contract_num': '1001',
        'enabled': true,
        'id': '2b78266b-0405-48f6-a263-3dba1869209f',
        'login': 'test@new-wms.ru',
        'service_group_set_id': '2086eb6b-a256-4831-8575-ae8a4fe788d1',
        'title': 'Test new WMS merchant',
        'trade_title': 'Test new WMS merchant',
      },
      'parts': [],
      'require_dimensions': false,
      'require_weight': false,
      'scan_serial_numbers': [],
      'title': 'Машина',
      'type': 'SKU',
      'weight_fact': 500,
      'weight_plan': 1000,
    },
  ],
}
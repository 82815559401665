import { useMutation } from 'react-query'

import api from 'src/api'

import { MOCK_UPDATE_ITEM_WEIGHT_AND_DIMENSIONS, provideMock } from 'src/mocks'

import { IItemWeightAndDimensions, updateWeightAndDimensionsRes } from 'src/interfaces'

interface useUpdateWeightAndDimensionsParamsType {
  itemId: string
}

const useUpdateWeightAndDimensions = (
  params: useUpdateWeightAndDimensionsParamsType,
) => {
  return useMutation(async (data: { item: IItemWeightAndDimensions }) => {
    try {
      const result = await provideMock<
        useUpdateWeightAndDimensionsParamsType,
        { data: updateWeightAndDimensionsRes }
      >(
        async () =>
          await api.post(`/items/weight_and_dimensions`, {
            dimensions: data.item.dimensions,
            weight: data.item.weight,
            sku_id: params.itemId
          }),
        params,
        [
          {
            key: 'itemId',
            value: 'mock',
            result: MOCK_UPDATE_ITEM_WEIGHT_AND_DIMENSIONS,
          },
          {
            key: 'itemId',
            value: 'KK1043',
            result: MOCK_UPDATE_ITEM_WEIGHT_AND_DIMENSIONS,
          },
        ],
      )()

      if (!result?.data?.success) {
        throw result
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useUpdateWeightAndDimensions

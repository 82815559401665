import { useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult, IError, IMerchant } from 'src/interfaces'

interface IGetMerchantsResult extends ApiReqResult {
  errors?: IError[]
  merchants: IMerchant[]
}

const useGetMerchants = (
  handleSuccess?: (merchants: IMerchant[]) => void,
  handleSettled?: () => void,
  handleError?: () => void,
) => {
  return useQuery(
    [`getMerchants`],
    async () => {
      const result = await api.get(`merchant?size=1000`)
      return result.data
    },
    {
      onSuccess: (data) => {
        if (data.success) {
          return handleSuccess?.(data.merchants)
        }
        handleError?.()
      },
      onSettled: handleSettled,
      onError: handleError,
    },
  ) as {
    refetch: any
    isLoading: boolean
    isError: boolean
    data: IGetMerchantsResult
  }
}

export default useGetMerchants

import React from 'react'
import { ConditionEnum } from 'src/interfaces'
import { Badge } from '@consta/uikit/Badge'

interface CellProps {
 condition: ConditionEnum
}

export const ConditionCell = (props: CellProps) => {
  const {
    condition
  } = props

  if (condition === 'GOOD') {
    return <Badge status="success" label='Годный' />
  }
  if (condition === 'DEFECT') {
    return <Badge status="error" label="Брак" />
  }
  return null
}

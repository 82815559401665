import React from 'react'

import { BadgePropStatus } from '@consta/uikit/__internal__/src/components/Badge/Badge'
import { ISelectOption, TypesEnum } from 'src/interfaces'

export const API_BASE_URL = 'https://api-wms-test.tempoline.ru'
export const LOCAL_STORAGE_NAME = '____TEMPOLINE___WMS____'
export const SETTINGS_LOCAL_STORAGE_NAME = '____SETTINGS___WMS____'
export const QZ_HOST = 'localhost'

export const CLOSE_PACKAGING_BARCODE = '3PL_CLPL'
export const PRINT_ITEM_STICKER_BARCODE = 'PR_ST'
export const CLOSE_PALLET_BARCODE = '3PL_CLPLT'
export const COMPLETE_REGISTER = 'CMP_RGS'
export const PRINT_PALLET_LISTS = 'PR_PLT_LSTS'
export const POST_PALLETS_COUNT = 'PST_PLTCNT'
export const PACK_DOCS = '3PL_PCDC'
export const UNIQUE_CODE_EMPTY = '3PL_UNIQUE_EMPTY'
export const ADVANCED_BARCODE = 'aEdu76Gfc76gvD'

//fixme:
export type STEP = {
  label: string
  value: string
  completed?: boolean
}

export enum TemplatesEnum {
  single = 'single',
  TSD = 'TSD',
  scan = 'scan',
}

export type TAB_ITEM = {
  value: string
  id: string
}

export const TABS_ITEMS: TAB_ITEM[] = [
  {
    value: 'Основная',
    id: 'main',
  },
  {
    value: 'Дополнительно',
    id: 'secondary',
  },
  {
    value: 'Настройки',
    id: 'settings',
  },
]

export type PACKED_ORDERS_OPTION = {
  id: string
  label: string
  rightSide: React.ReactNode
}

export type STATUSES_PACKED_ORDERS = {
  [key: string]: [string, BadgePropStatus]
}

export type ORDER_PLACE_ITEM = {
  id: string
  img: string
  title: string
  barcode: string
  count: string
}

export type ORDER_PLACE = {
  id: string
  count: string
  status: string
  barcode: string | null
  dimensions: string | null
  weigth: string | null
  items: ORDER_PLACE_ITEM[]
}


export const MARGIN_TRANSLATE: { [key: string]: string } = {
  top: 'Сверху',
  right: 'Справа',
  bottom: 'Снизу',
  left: 'Слева',
}

export const SIZE_TRANSLATE: { [key: string]: string } = {
  width: 'Ширина',
  height: 'Высота',
}

export const NOTIFICATIONS_TIMEOUT = 3000

export const MONTHS = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
]

export enum PackingScanOptionsEnum {
  simple = 'simple',
  packingV2 = 'packingV2',
  resume = 'resume',
  wb_fbs = 'wb_fbs',
  wb_fbs_pallets = 'wb_fbs_pallets',
  batch = 'batch',
  relabel = 'relabel',
  docs_print = 'docs_print',
}

export const SCAN_OPTIONS: ({newPacking?: boolean} & ISelectOption)[] = [
  {
    label: 'Упаковка заказа 2',
    value: PackingScanOptionsEnum.packingV2,
    newPacking: true
  },
  // {
  //   label: 'Упаковка заказа',
  //   value: PackingScanOptionsEnum.simple,
  // },
  {
    label: 'Упаковка WB FBS',
    value: PackingScanOptionsEnum.wb_fbs,
    newPacking: true
  },
  {
    label: 'Упаковка + формирование WB FBS',
    value: PackingScanOptionsEnum.wb_fbs_pallets,
    newPacking: true
  },
  // {
  //   label: 'Упаковка батча',
  //   value: PackingScanOptionsEnum.batch,
  // },
  // {
  //   label: 'Перемаркировка',
  //   value: PackingScanOptionsEnum.relabel,
  // },
  // {
  //   label: 'Печать документов',
  //   value: PackingScanOptionsEnum.docs_print,
  // },
]

export const dateTypes: { label: string; value: TypesEnum }[] = [
  {
    label: 'дата изготовления',
    value: TypesEnum.dateOfManufacture,
  },
  {
    label: 'дата - годен до',
    value: TypesEnum.expirationDate,
  },
]

/** Регулярное выражение для честного знака */
export const DefaultChzRegExp: RegExp =
  /^01(\d{14})21(.{6}|.{13}|.{20})(\x1d|)(91|93)(.{4})(\x1d|)((92(.{88}|.{44}))|(\s*))(\x1d|)$/g

/** ЧЗ - заглушки, которые не проверяются на уникальность */
export const ChzExcludeCodes = [
  "01000000000000002100000000000009100009200000000000000000000000000000000000000000000"
]

export const selfPickupDeliveryServices = ['SELF_PICKUP'] //ID курьерок которые в модуле формирования транспортного места считаются самовывозом
export const palletCloseBarcode = '3PL_CLPL' //ШК закрытия транспортного места


export const ShipmentTypes = {
  DELIVERY_SERVICE: 'Курьерская служба',
  FBO: 'ФБО',
  SELF_PICKUP: 'Самовывоз',
  FBS: 'МП ФБС',
}


export const GOOD_CONDITION_BARCODE = '3PL_PLGOOD'
export const DEFECT_CONDITION_BARCODE = '3PL_PLBAD'
import styles from './BatchCell.module.scss'

export const BatchCell = ({ num, date }: { num: string; date?: string; }) => {

  return (
    <div className={styles.batch}>
      {date ? <p className={styles.date}>{date}</p> : null}
      <span className={styles.num}>{num}</span>
    </div>
  )
}



const IconScanBarcode = (props: any) => (
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
    <path d='M2 9V4C2 2.89543 2.89543 2 4 2H9V4H4V9H2Z' fill='#002033' fillOpacity='0.35' />
    <path d='M2 20V15H4V20H9V22H4C2.89543 22 2 21.1046 2 20Z' fill='#002033' fillOpacity='0.35' />
    <path d='M22 20V15H20V20H15V22H20C21.1046 22 22 21.1046 22 20Z' fill='#002033' fillOpacity='0.35' />
    <path d='M22 4V9H20V4H15V2H20C21.1046 2 22 2.89543 22 4Z' fill='#002033' fillOpacity='0.35' />
    <path d='M8 6H6V18H8V6Z' fill='#002033' fillOpacity='0.35' />
    <path d='M9 6H10V18H9V6Z' fill='#002033' fillOpacity='0.35' />
    <path d='M16 6H13V18H16V6Z' fill='#002033' fillOpacity='0.35' />
    <path d='M11 6H12V18H11V6Z' fill='#002033' fillOpacity='0.35' />
    <path d='M18 6H17V18H18V6Z' fill='#002033' fillOpacity='0.35' />
  </svg>
)

export default IconScanBarcode

import { DependencyList, useEffect } from 'react'


type Props = {
  isActive?: boolean
  copyActive?: boolean
  selectAllActive?: boolean
  copyHandler: () => void
  selectAllHandler: () => void
}
export const useHotkeyCopy = (props: Props, deps: DependencyList) => {
  const {
    isActive=true,
    copyActive,
    selectAllActive,
    copyHandler,
    selectAllHandler,
  } = props
  const keyDownEventHandler = (event: KeyboardEvent) => {
    const code = event.code
    if (event.ctrlKey || event.metaKey) {
      if (code === 'KeyC' && copyActive) {
        event.preventDefault()
        // CTRL+C Pressed
        copyHandler()
      }
      if (code === 'KeyA') {
        if (selectAllActive) {
          event.preventDefault()
          // CTRL+A Pressed
          selectAllHandler()
        }
      }
    }
  }
  useEffect(() => {
    if (!isActive) {
      return () => {
        document.removeEventListener('keydown',keyDownEventHandler)
      }
    }
    document.addEventListener('keydown',keyDownEventHandler)
    return () => {
      document.removeEventListener('keydown',keyDownEventHandler)
    }
  }, [
    isActive,
    copyActive,
    selectAllActive,
    ...deps
  ])
}
import { useMutation } from 'react-query'

import api from 'src/api'
import { IRefundPlaceItem } from '../../interfaces/refund/refund'

export interface IUpdateRefundPlace {
  barcode?: string
  items?: IRefundPlaceItem[]
  order_id?: string
}

const useUpdateReturnPlace = () => {
  return useMutation(
    async (data: { refundPlace: IUpdateRefundPlace; returnId: string;  placeId?: string}) => {
      const result = await api.post(`/return/${data.returnId}/place${data.placeId ? '/' + data.placeId : ''}`, data.refundPlace)
      if (!result?.data?.success) {
        throw result
      }
      return result.data
    },
  )
}

export default useUpdateReturnPlace

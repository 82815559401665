import { ITableColumn, ITableRow } from 'src/interfaces'


export const generateFilesColumns = (): ITableColumn[] => {
  return [
    {
      key: 'title',
      title: 'Имя документа',
      title_txt: 'Имя документа',
    },
  ]
}

export const generateFilesRows = (): ITableRow[] => {
  return [
    { id: 'pickingList', title: 'Лист подбора' },
    { id: 'invoice', title: 'Инвойсы заказов' },
  ]
}

import api from 'src/api'

import { ApiReqResult } from 'src/interfaces'
import { IRefundPlace, IRefundPlanItem, IRefundSku } from '../../interfaces/refund/refund'


export interface IGetReturnPlaceResult extends ApiReqResult {
  place: IRefundPlace
  plan_items?: IRefundPlanItem[]
  skus?: IRefundSku[]
}

const useGetReturnPlace = () => ({
  fetch: async (returnId: string, placeId: string) => {
    const result = await api.get(`/return/${returnId}/place/${placeId}`)
    if (!result.data.success) {
      throw new Error(result.data.message)
    }
    return result.data as IGetReturnPlaceResult
  }
})

export default useGetReturnPlace

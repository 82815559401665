import { Modal } from 'src/components'
import React, { useEffect, useState } from 'react'
import styles from './item-sticker-print-2-modal.module.scss'
import StickStickerSvg from './assets/sticker.svg'
import { Timer } from '@consta/uikit/Timer'

interface IItemStickerPrint2Props {
  isOpen: boolean
  handleAcceptAndNext: () => void
  timerValue?: number
  title?: string
  subtitle?: string
  btnText?: string
}
const ItemStickerPrint2Modal = (props: IItemStickerPrint2Props) => {
  const {
    isOpen,
    handleAcceptAndNext,
    timerValue = 5,
    title = 'Стикер отправлен на печать',
    subtitle = 'Приклейте стикер на товар',
    btnText = 'Продолжить упаковку'
  } = props
  const [timeCount, setTimeCount] = useState<number>(timerValue)

  useEffect(() => {
    let timerId:any;
    if (isOpen) {
      timerId = setInterval(() => {
        setTimeCount((state) => state - 1)
      }, 1000)
    }
    return () => {
      if (!timerId) {
        return
      }
      clearInterval(timerId);
      setTimeCount(timerValue);
    }
  }, [isOpen])

  useEffect(() => {
    if (timeCount === 0) {
      handleAcceptAndNext()
    }
  }, [timeCount])

  return (
    <Modal
      isOpen={isOpen}
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size="min"
    >
      <div className={styles.contentWrapper}>
        <img src={StickStickerSvg} />
        <div className={styles.titleWrapper}>
          <h3 className={styles.title}>{title}</h3>
          <p className={styles.grayText}>{subtitle}</p>
        </div>

        <div>
          <Timer size="m" seconds={timeCount} progress={timeCount * 20} placeholder='' />

          <div className={styles.btns}>
            <p className={styles.continueBtn} onClick={handleAcceptAndNext}>
              {btnText}
            </p>
          </div>
        </div>
      </div>
    </Modal>
  )
}
export default ItemStickerPrint2Modal

import React, { createContext, useCallback, useContext, useReducer } from 'react'

import { ConfirmType, IReducerAction, StickerWithCopies } from 'src/interfaces'
import {
  setConfirmModalAction,
  setErrorDataAction,
  setInputRefAction,
  setJoinCodeModalVisibleAction,
  setReprintStickersForPrintAction,
  setStickStickerModalVisibleAction,
} from './store/actions'
import { getInitialState, reducer } from './store/reducer'
import { IReducer } from './types/storeInterfaces'
import { useInputFocus } from '../../hooks/actionHooks/focus/useInputFocus'
import {
  ChoiceTablePrintModal,
  ConfirmActionModal,
  ConfirmCodeModal,
  ErrorModal,
  ItemStickerPrintModal,
  WhiteIconPrinter,
} from '../../components'
import { ErrorModaType } from './types/error'

/*
  Идея данного контекста:
    Внести все общие модалки/действия для армов в один общий контекст
*/


type Props = React.PropsWithChildren<{
  joinSettings?: {
    isLoading?: boolean,
    onClose?: () => void,
    onSubmit: (joinCode: string, closeCallback: () => void) => Promise<void>,
    joinModalTitle?: string,
    joinModalSubtitle?: string,
  },
  confirmModalSettings?: {
    isLoading?: boolean,
    onClose?: () => void,
    onSubmit?: (closeCallback: () => void) => void,
  },
  printFilesModalSettings?: {
    onClose?: () => void,
    onSubmit: (files: StickerWithCopies[], isPreview: boolean, closeCallback: () => void) => void,
  },
  stickStickerSettings?: {
    backTo?: string
    onClose?: () => void,
  },
  errorDataSettings?: {
    onClose?: () => void,
  },
}>

export const ArmCommonContext = createContext({
  state: getInitialState(),

  // Модлаки
  setConfirmModal: (confirmData: ConfirmType | null) => void confirmData as void,
  setJoinCodeModalVisible: (visible: boolean) => void visible as void,
  setReprintStickersForPrint: (files: StickerWithCopies[]) => void files as void,
  setStickStickerModalVisible: (visible: boolean) => void visible as void,
  setErrorDataModal: (errorData: ErrorModaType | null) => void errorData as void,

  // Работа с фокусом поля главного поля ввода
  setInputRef: (ref: React.RefObject<HTMLInputElement> | null) => void ref as void,
  blurOrFocus: (type: 'focus' | 'blur') => void type as void,
  focus: () => void false as void,
  blur: () => void false as void,
})


export const ArmCommonContextProvider = (props: Props) => {
  const {
    children,
    joinSettings,
    confirmModalSettings,
    printFilesModalSettings,
    stickStickerSettings,
    errorDataSettings,
  } = props


  const [state, dispatch] = useReducer<React.Reducer<IReducer, IReducerAction>>(reducer, getInitialState())
  const {
    joinCodeModalVisible,
    confirmModal,
    reprintFiles,
    stickStickerModalVisible,
    errorDataModal,
  } = state

  const setInputRef = (ref: React.RefObject<HTMLInputElement> | null) => dispatch(setInputRefAction(ref))

  const focusData = useInputFocus({ inputBarcodeRef: state.inputRef })

  const setConfirmModal = useCallback(
    (confirmData: ConfirmType | null) => dispatch(setConfirmModalAction(confirmData)),
    [dispatch]
  )
  const setJoinCodeModalVisible = useCallback(
    (visible: boolean) => dispatch(setJoinCodeModalVisibleAction(visible)),
    [dispatch]
  )
  const setReprintStickersForPrint = useCallback(
    (files: StickerWithCopies[]) => dispatch(setReprintStickersForPrintAction(files)),
    [dispatch]
  )
  const setStickStickerModalVisible = useCallback(
    (visible: boolean) => dispatch(setStickStickerModalVisibleAction(visible)),
    [dispatch]
  )
  const setErrorDataModal = useCallback(
    (errorData: ErrorModaType | null) => dispatch(setErrorDataAction(errorData)),
    [dispatch]
  )


  return (
    <ArmCommonContext.Provider
      value={{
        state,

        setInputRef,

        setConfirmModal,
        setJoinCodeModalVisible,
        setReprintStickersForPrint,
        setStickStickerModalVisible,
        setErrorDataModal,

        ...focusData,
      }}
    >
      {children}

      {/** Модалка подтверждения c действием */}
      {
        confirmModal ? (
          <ConfirmActionModal
            isOpen={true}
            isInteger={true}
            withPreview={false}
            confirmWithInput={confirmModal.confirmWithInput}
            confirmWithScan={false}
            confirmLoading={confirmModalSettings?.isLoading}
            size={'min'}
            type={confirmModal.type || 'warning'}
            title={confirmModal.title}
            subtitle={confirmModal.subtitle}
            inputLabel={'Введите кол-во'}
            onMount={() => {
              focusData.blur()
            }}
            onClose={async () => {
              await confirmModal?.actionClose?.()
              setConfirmModal(null)
              focusData.focus()
              confirmModalSettings?.onClose?.()
            }}
            onSubmit={async (...args) => {
              await confirmModal?.actionSubmit(...args)
              confirmModalSettings?.onSubmit?.(() => {
                setConfirmModal(null)
                focusData.focus()
              })
            }}
            validateCallback={confirmModal.validateCallback}
          />
        ) : null
      }

      {/** Модалка подтверждения входа */}
      {
        joinCodeModalVisible ? (
          <ConfirmCodeModal
            isOpen
            isInteger
            confirmLoading={joinSettings?.isLoading}
            title={joinSettings?.joinModalTitle}
            subtitle={joinSettings?.joinModalSubtitle}
            onClose={() => {
              setJoinCodeModalVisible(false)
              joinSettings?.onClose?.()
            }}
            onSubmit={async (codeValue) => {
              if (!codeValue) return
              await joinSettings?.onSubmit(codeValue, () => {
                setJoinCodeModalVisible(false)
                focusData.focus()
              })
            }}
          />
        ) : null
      }

      {/** Выбор стикеров из списка */}
      {
        reprintFiles.length ? (
          <ChoiceTablePrintModal
            isOpen={true}
            onClose={() => {
              setReprintStickersForPrint([])
              printFilesModalSettings?.onClose?.()
            }}
            onSubmit={async (rowsFiles, isPreview) => {
              const filesForPrint: StickerWithCopies[] = rowsFiles.map((rowFile) => ({
                content: rowFile.content,
                printer: rowFile.printer,
                quantity: rowFile.quantity,
                title: rowFile.title,
              }))
              printFilesModalSettings?.onSubmit(filesForPrint, isPreview, () => {
                setReprintStickersForPrint([])
                focusData.focus()
              })
            }}
            title={'Печать стикеров'}
            submitBtnLabel={'Напечатать'}
            submitBtnIcon={WhiteIconPrinter}
            rows={
              reprintFiles.map((file) => ({
                id: file.title,
                ...file,
              }))
            }
            columns={[
              {
                key: 'title',
                title: 'Название',
                title_txt: 'Название',
              },
            ]}
          />
        ) : null
      }

      {
        stickStickerModalVisible ? (
          <ItemStickerPrintModal
            backTo={stickStickerSettings?.backTo}
            isOpen={true}
            onClose={() => {
              setStickStickerModalVisible(false)
              focusData.focus()
              stickStickerSettings?.onClose?.()
            }}
          />
        ) : null
      }

      {errorDataModal ? (
        <ErrorModal
          title={errorDataModal.modalTitle}
          isOpen={true}
          onClose={() => {
            setErrorDataModal(null)
            focusData?.focus()
            errorDataSettings?.onClose?.()
          }}
          errorData={errorDataModal.error}
          onRetry={errorDataModal.retryAction}
        />
      ) : null}
    </ArmCommonContext.Provider>
  )
}

export const useArmCommonContext = () => useContext(ArmCommonContext)

import React from 'react'
import cx from 'classnames'
import styles from './Flex.module.scss'

interface FlexProps {
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around'
  gap?: 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl'
  children: React.ReactNode
}

export const Flex = (props: FlexProps) => {
  const {
    gap,
    justifyContent,
    direction
  } = props
	return (
		<div className={cx(
      styles.Flex,
      { [styles[`gap${gap}`]]: Boolean(gap) },
      { [styles[`justifyContent${justifyContent}`]]: Boolean(justifyContent) },
      { [styles[`direction${direction}`]]: Boolean(direction) },
    )}>
      {props.children}
		</div>
	);
};

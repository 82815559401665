import React from 'react'
import styles from './ErrorBoundary.module.scss'

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    // @ts-ignore
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className={styles.errorComp}>
          <h3>Что-то пошло не так</h3>
        </div>
      )
    }

    // @ts-ignore
    return this.props.children;
  }
}
export default ErrorBoundary

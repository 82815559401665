import api from '../../api'
import { ApiReqResult, ICountryItem } from '../../interfaces'
import { useQuery } from 'react-query'

const getCountriesList = async () => {
  const result = await api.get(`/enum/delivery-code`)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

interface IResult extends ApiReqResult {
  values: ICountryItem[]
}

const useGetCountriesList = (
  callback?: (data: ICountryItem[]) => void,
  error?: () => void,
  settled?: () => void,
) => {
  return useQuery<IResult>([`getCountriesList`], getCountriesList, {
    keepPreviousData: false,
    onSuccess: ({ success, values }) => {
      if (success) {
        callback?.(values)
      } else {
        error?.()
      }
    },
    onError: () => error?.(),
    onSettled: () => settled?.(),
  })
}

export default useGetCountriesList

import React, { JSX } from 'react'

import styles from './filter-block.module.scss'
import cx from 'classnames'
import {
  FieldType,
  FilterFieldType,
  FilterRow,
  isBool,
  isCalendar,
  isCell,
  isCombobox,
  isContractors,
  isDocument,
  isGroup,
  isInput,
  isJson,
  isMerchants,
  isSku,
  isSkuBatch,
  isTable,
  isUrl,
  isWarehouseContainer,
  isWarehousePlace,
} from './types'
import { CalendarInput, ComboboxWrapper, InputWrapper, SwitchWrapper } from '../index'
import FilterView from '../FilterView/FilterView'
import { convertFilterStateToView } from './utils'
import ComboboxMerchants from 'src/updatedDesignVersion/widgets/fields/ui/ComboboxMerchants/ComboboxMerchants'
import ComboboxCells from 'src/updatedDesignVersion/widgets/fields/ui/ComboboxCells/ComboboxCells'
import ComboboxSkus from 'src/updatedDesignVersion/widgets/fields/ui/ComboboxSkus/ComboboxSkus'
import ComboboxSkuBatch from 'src/updatedDesignVersion/widgets/fields/ui/ComboboxSkuBatch/ComboboxSkuBatch'
import { GroupField } from '../../updatedDesignVersion/widgets/fields/ui/GroupField/GroupFields'
import { TableField } from 'src/updatedDesignVersion/widgets/fields/ui/TableField/TableField'
import { DefaultItem } from '@consta/uikit/Combobox'
import { JsonField } from 'src/updatedDesignVersion/widgets/fields/ui/JsonField/JsonField'
import ComboboxDocumentsSearchApi
  from '../../updatedDesignVersion/widgets/fields/ui/ComboboxDocumentsSearchApi/ComboboxDocumentsSearchApi'
import ComboboxContractors from '../../updatedDesignVersion/widgets/fields/ui/ComboboxContractors/ComboboxContractors'
import ComboboxWarehousePlace
  from 'src/updatedDesignVersion/widgets/fields/ui/ComboboxWarehousePlace/ComboboxWarehousePlace'
import ComboboxWarehouseContainer
  from 'src/updatedDesignVersion/widgets/fields/ui/ComboboxWarehouseContainer/ComboboxWarehouseContainer'


/* Todo переделать это в генератор полей, а не генератор фильтра */
type ComboboxFieldType = {
  Component: (props: any) => JSX.Element
  extraProps?: Record<string, any>
  conditionFn: (data: FilterFieldType) => boolean
}

type valueType = any // todo добавить тип для поля value
export type ChangeFilterDataType = {
  fieldId: string
  value?: valueType
  filedType?: FieldType
}
export type onChangeType = { onChange: (data: ChangeFilterDataType) => void }
export type FilterStateType = Record<string, valueType>

interface IFilterProps {
  visible: boolean
  disabled?: boolean
  /** Поля фильтров */
  filterRowsFields: FilterRow[]
  /** Стейт фильтров */
  filterState: FilterStateType
  /**
   * Стейт черновика фильтров
   * Если требуется отображать превью фильтров (теги) не сразу,
   * а по какому-то действию (например после нажатия кнопки Поиск), то
   * filterStateDraft является стейтом для начальных данных, а filterState
   * для конечных данных
   */
  filterStateDraft?: FilterStateType
  /** Обновить основной стейт */
  onChangeFilter: (fieldId: string, value?: valueType) => void
  /** Обновить стейт черновика */
  onChangeFilterDraft?: (fieldId: string, value: any, type: FieldType) => void
  wrapperClassName?: string
  excludeFilterFields?: string[]
  visibleFilterView?: boolean
}

const FilterBlock = (props: IFilterProps) => {
  const {
    visible,
    disabled = false,
    filterRowsFields,
    wrapperClassName,
    filterState,
    filterStateDraft,
    excludeFilterFields,
    onChangeFilter,
    onChangeFilterDraft,
    visibleFilterView = true,
  } = props

  const handleChange = (data: ChangeFilterDataType) => {
    if (onChangeFilterDraft) {
      onChangeFilterDraft(data.fieldId, data.value, data.filedType)
      return
    }
    onChangeFilter(data.fieldId, data.value)
  }

  const filtersView = convertFilterStateToView(
    filterState,
    filterRowsFields,
    excludeFilterFields,
  )

  return (
    <div className={cx(styles.filtersBlockWrapper, wrapperClassName)}>
      {visible
        ? filterRowsFields
          .filter((field) => !field.hidden)
          .map((filterRow, i) => (
            <RowFields
              key={i}
              {...filterRow}
              filterState={
                filterStateDraft !== undefined
                  ? filterStateDraft
                  : filterState
              }
              onChange={handleChange}
              disabled={disabled}
            />
          ))
        : null}

      {visibleFilterView ? (
        <FilterView
          isClearAllFilter={false}
          clearFilter={(fieldId) => onChangeFilter(fieldId, undefined)}
          filtersView={filtersView}
        />
      ) : null}
    </div>
  )
}
type RowFieldsType = { filterState: FilterStateType; disabled: boolean } & onChangeType & FilterRow
const RowFields = (data: RowFieldsType) => (
  /** Строка полей */
  <div
    className={cx(
      styles.filterRow,
      data.flexSpaceBetween ? styles.spaceBetween : '',
      data.extraClassName,
    )}
  >
    {data.fields.map(
      ({ fieldWrapperClassName, jointFields, ...fieldProps }, i) => (
        <React.Fragment key={i}>
          {jointFields ? (
            /** Слитые поля вместе, без раздедений */
            <RowFields
              filterState={data.filterState}
              fields={jointFields}
              extraClassName={styles.jointFilterRow}
              onChange={data.onChange}
              disabled={data.disabled}
            />
          ) : (
            /** Выбранное поле */
            <div className={cx(styles.fieldWrapper, data.extraClassName)}>
              <CorrectComponentField
                {...fieldProps}
                filterState={data.filterState}
                onChange={data.onChange}
                disabled={data.disabled}
              />
            </div>
          )}
        </React.Fragment>
      ),
    )}
  </div>
)

type CorrectComponentFieldType = FilterFieldType & {
  filterState: FilterStateType
  disabled?: boolean
} & onChangeType

const CorrectComponentField = (data: CorrectComponentFieldType) => {
  /** Выбор нужного компонента по типу */

  if (!data.fieldProps || !data.type) {
    return null
  }
  const value = data.filterState[data.fieldProps.id]
  const commonProps = {
    value,
    disabled: data.disabled || data.fieldProps.readOnly,
    readOnly: data.disabled || data.fieldProps.readOnly,
    isRequired: data.fieldProps.isRequired,
    className: cx(styles.fieldClassName, data.fieldProps.className),
  }

  const onChangeCombobox = (valueObj: DefaultItem | null) => {
    if (!data.fieldProps) {
      console.error('Ошибка в поле изменения, нет пропсов поля')
      return
    }
    data.fieldProps.onChange?.(valueObj)
    data.onChange({
      value: valueObj,
      filedType: data.type,
      fieldId: data.fieldProps.id,
    })
  }

  /* Комбобоксы с api search */
  const comboboxFields: ComboboxFieldType[] = [
    { Component: ComboboxWrapper, extraProps: {onChange: ({value}) => onChangeCombobox(value)}, conditionFn: isCombobox },
    { Component: ComboboxSkuBatch, conditionFn: isSkuBatch },
    // Api search
    { Component: ComboboxContractors, conditionFn: isContractors },
    { Component: ComboboxMerchants, conditionFn: isMerchants },
    { Component: ComboboxSkus, conditionFn: isSku },
    { Component: ComboboxCells, conditionFn: isCell },
    { Component: ComboboxWarehousePlace, conditionFn: isWarehousePlace },
    { Component: ComboboxWarehouseContainer, conditionFn: isWarehouseContainer },
    { Component: ComboboxDocumentsSearchApi, conditionFn: isDocument },
  ]
  const comboboxField = comboboxFields.find(comboboxEl => comboboxEl.conditionFn(data))
  if (comboboxField) {
    return (
      <comboboxField.Component
        {...data.fieldProps}
        {...commonProps}
        className={''} // костыль, чтобы обойти дефолтный пропс
        wrapperClassName={cx(styles.fieldClassName, data.fieldProps.className)}
        onChange={onChangeCombobox}
        {...comboboxField.extraProps}
      />
    )
  }
  if (isInput(data)) {
    return (
      <InputWrapper
        {...data.fieldProps}
        {...commonProps}
        handleChange={(value, type) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(value, type)
          data.onChange({
            value,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isUrl(data)) {
    return (
      <InputWrapper
        {...data.fieldProps}
        {...commonProps}
        link={commonProps.value}
        handleChange={(value, type) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(value, type)
          data.onChange({
            value,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isCalendar(data)) {
    return (
      <CalendarInput
        {...data.fieldProps}
        {...commonProps}
        value={value ? new Date(value) : null}
        handleChange={(value) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(value)
          data.onChange({
            value,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isBool(data)) {
    return (
      <SwitchWrapper
        {...data.fieldProps}
        {...commonProps}
        onChange={(boolValue) => {
          if (!data.fieldProps) {
            console.error('Ошибка в поле изменения, нет пропсов поля')
            return
          }
          data.fieldProps.onChange?.(boolValue)
          data.onChange({
            value: boolValue,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isGroup(data)) {
    return (
      <GroupField
        {...data.fieldProps}
        {...commonProps}
        onSubmit={(valeArr) => {
          data.fieldProps.onChange?.(valeArr)
          data.onChange({
            value: valeArr,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isTable(data)) {
    return (
      <TableField
        {...data.fieldProps}
        {...commonProps}
        onSubmit={(vale) => {
          data.fieldProps.onChange?.(vale)
          data.onChange({
            value: vale,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  if (isJson(data)) {
    return (
      <JsonField
        {...data.fieldProps}
        {...commonProps}
        onSubmit={(vale) => {
          data.fieldProps.onChange?.(vale)
          data.onChange({
            value: vale,
            filedType: data.type,
            fieldId: data.fieldProps.id,
          })
        }}
      />
    )
  }
  return null
}

export { FilterBlock, CorrectComponentField }

import { useMutation } from 'react-query'
import api from 'src/api'

import { ApiReqResult } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { provideMock } from '../../mocks'
import { RequestData } from '../../interfaces/supplySortingDto'


interface Params {
  supplyId: string
}

interface Result extends RequestData, ApiReqResult {
}

const completeCrossdockingSorting = async (params: Params) => {
  const result = await provideMock<Params, { data: Result }>(
    async () =>
      await api.post(`/crossdocking-sorting/supply/${params.supplyId}/complete-sorting`),
    params,
    [
      // {
      //   key: 'supplyId',
      //   value: 'mock',
      //   result: { data:  },
      //   logData: 'Формирование паллет до перемаркировки'
      // }
      ],
  )()

  if (!result.data?.success) {
    throw result
  }
  return result.data
}


const useCompleteCrossdockingSorting = (
  options?: UseMutationOptions<Result, any, Params>,
) => {
  return useMutation<Result, any, Params>(
    completeCrossdockingSorting,
    options
  )
}

export default useCompleteCrossdockingSorting

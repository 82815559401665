import {
  ApiReqResult,
  DocumentTypeEnum,
  ICommonDocument,
  IDimensions,
  IDimensionsObj,
  IMerchant,
  IOrderItem,
  CommonSerialNumberFilled,
  OperationTypes,
  palletStatuses,
  RelabelingTypes,
  ShipmentTypes,
  ShippingOptionsType,
} from 'src/interfaces/index'

export enum OrderStatusEnum {
  new = 'NEW',
  picking = 'PICKING',
  picked = 'PICKED',
  packing = 'PACKING',
  packing_error = 'PACKING_ERROR',
  packedAwaitingDocs = 'PACKED_AWAITING_DOCS',
  packedAwaitingRelabel = 'PACKED_AWAITING_RELABEL',
  packed = 'PACKED',
  shipped = 'SHIPPED',
  canceled = 'CANCELED',
  return_partially = 'RETURNED_PARTIALLY',
  return = 'RETURNED',
  done = 'DONE',
}

// order
export enum RelabelingOptionsEnum {
  scanWithUnique = 'scanWithUnique', // Сканирование c проверкой уникальности нового ШК (ШК клиента) (Стандартное)
  scanWithoutUnique = 'scanWithoutUnique', // Сканирование без проверки уникальности нового ШК
  withoutScan = 'withoutScan', // Без сканирования нового ШК
}

export interface IOrder extends ICommonDocument {
  operationType?: OperationTypes
  shipmentType?: ShipmentTypes
  global_shipping_guidelines: string
  order_shipping_comments: string
  operativeComment?: string
  cancel_items_confirmed?: boolean
  require_place_weight: boolean
  maxBoxesWithoutPallet?: number
  relabelingType?: RelabelingTypes
  join_code?: string
  packing_state?: string
  sorting_number?: string
  ExtraSeat?: boolean
  // Задача 11748 - Параметр, показывающий потребность в сборе состава пустых паллет
  with_pallet_composition?: boolean
  relabelingOptions?: {
    scanNewBarcodeByClient: RelabelingOptionsEnum
  }
  shippingOptions?: {
    packingType: ShippingOptionsType
  }
  preferred_barcode_type?: string
  shipping_form?: IShippingFormItem[]
  merchant?: IMerchant
  delivery: IDelivery
  items: IOrderItem[]
  files?: { id: string, name: string, url: string }[]
  files_count?: number
  places: IOrderPackagingPlace[]
  pallets?: IOrderPackedPallet[]
  ad_attachments: IAdAttachments[]
  status?: OrderStatusEnum | string
  paused_at?: string
  batch_uuid?: string

  /** Убрать стикеровку и доп упаковку товара при ФБО */
  DisableStickerAndPackagingFBO?: boolean
  /** Запрошена отмена заказа */
  cancel_requested?: boolean
  customer?: IOrderCustomer
  payment_type?: string
  sku_condition?: string
  disable_billing?: boolean
  address?: IAddress
}

export interface IOrderCustomer {
  name: string
  surname: string
  patronymic: string
  phone: string
  document_date?: string
  document_number?: string
  document_series?: string
}

export interface IOrderFilter {
  search?: string
  ids?: string[]
  sku_ids?: string[]
  external_ids?: string[]
  address?: {
    search: string
  }
  date_from?: string
  date_to?: string
  delivery_companies?: string[]
  delivery_company_products?: string[]
  executor_id?: string
  in_batch?: boolean
  merchant_ids?: string[]
  shipping_date_from?: string
  shipping_date_to?: string
  sku_condition?: string
  status?: string
  without_reserve?: boolean
  total_quantity_from?: string
  total_quantity_to?: string
  warehouse_zone_ids?: string[]
}

export interface IAddress {
  area?: string
  area_fias?: string
  block?: string
  building?: string
  city?: string
  city_fias?: string
  country?: string
  flat?: string
  floor?: number
  full_address?: string
  house?: string
  region?: string
  region_fias?: string
  settlement?: string
  settlement_fias?: string
  street?: string
  zip?: string
}

export interface IDelivery {
  logo: string
  title: string
  contract_title: string
  delivery_service?: string //Код курьерки. ToDo 1C Пока возвращает только новый API. Если будет сделан в старом - убрать все костыли сравнения title.
  sorting_center_acceptance_date: string
  maximum_place_weight?: number
  maximum_places_count?: number
  maximum_pallet_weight?: number
  maximum_place_dimensions?: IDimensionsObj
  maximum_pallet_dimensions?: IDimensionsObj
  address?: IAddress
  shipping_date_plan?: string
  delivery_contract_id?: string
  delivery_contract_title?: string
  delivery_date_plan?: string
  delivery_time_plan_from?: string
  delivery_time_plan_to?: string
  postamat_num?: string
}
export interface IDeliveryContract {
  code: string
  delivery_company: {
    code: string
    is_fbo: boolean
    is_fbs: boolean
    logo: string
    preferred_barcode_type: string
    title: string
  }
  delivery_company_product: {
    code: string
    title: string
  }
  id?: string
  first_mile_transfer_type: string
  dlv_id?: string
  min_days_before_expiration_date: number
  title: string
}

export interface IPackedFullOrder {
  id: string
  date: string
  global_shipping_guidelines: string
  order_shipping_comments: string
  operativeComment?: string
  shipping_form: IShippingFormItem[]
  delivery: IDelivery
  places: IOrderPackagingPlace[]
  items: IOrderItem[]
}

export interface IAdAttachments {
  id: string
  name: string
  quantity: number
  note: string
  barcode: string
  img: string
}

// places

export interface IPackedOrderPlace {
  packaging_id: string | null
  sequence_num: number
  weight?: number
  items: ICommonInfoPlaceItem[]
}

export interface ICommonInfoPlaceItem {
  id: string
  packed_at?: string
  barcode_used: string
  quantity: number
  expirationDate?: string
  dateOfManufacture?: string
  packagings?: IAddPack[]
  serial_numbers: CommonSerialNumberFilled[]
  article?: string
  fragile?: boolean
  marketplace_sku_id?: string
}

export interface IOrderPackagingPlaceItem extends ICommonInfoPlaceItem {
  weight?: number
  title?: string
  kit_id?: number
  kit_title?: string
  batchOrderDate?: string
  batchOrderId?: string
  manualRequireWeight?: boolean
  sku_batch_id?: string
}

interface ICommonInfoOrderPackagingPlace {
  id?: string
  packaging_id: string | null
  sequence_num: number | string
  is_final?: boolean
  weight?: number
}

export interface IOrderPackagingPlace extends ICommonInfoOrderPackagingPlace {
  dimensions?: IDimensions
  temp_id?: string
  items: IOrderPackagingPlaceItem[]
}

export interface IOrderPackagingMarkedPlace extends IOrderPackagingPlace {
  newBarcode?: string
}

export interface IPlacePacked extends ICommonInfoOrderPackagingPlace {
  items: ICommonInfoPlaceItem[]
  order_id?: string
}

export interface IUpdatePackingPlaceResult extends ApiReqResult {
  place_id: string
  sticker: {
    content: string
    printer: string
  }
}

// update weight and dimensions

export interface updateWeightAndDimensionsRes extends ApiReqResult {
  item_id: string
}

export interface IItemWeightAndDimensions {
  weight?: number
  dimensions?: IDimensionsObj
}

export interface IPlaceErrorData {
  show: boolean
  data: IOrderPackagingPlace
  errors: any
}

// sticker

export interface StickerBase {
  content: string
  printer: string
}

export interface StickerData {
  stickerExists?: boolean
  sticker: StickerBase
}
export type StickerResult = StickerData & ApiReqResult

export interface PrintResult extends StickerBase, ApiReqResult {}
export interface StickerWithCopies extends StickerBase {
  quantity: number
  title: string
}

export interface applyPrintItemStickerParams {
  itemId: string
  barcode: string
  quantity: number
  dateOfManufacture?: string
  expiryMonths?: string
  advanced?: boolean
  needFocusAfterPrint?: boolean
  disable_billing?: boolean
  document_type?: DocumentTypeEnum
  /* id документа например в заказе OrderId */
  document_id: string
  marketplace_sku_id?: string
}

// pallets
// todo объединить с IPallet
export interface IOrderCommonPallet {
  id?: string
  pallet_id?: string
  barcode?: string
  pallet_num: number
  weight?: number
  temp_id?: string
  dimensions?: IDimensionsObj
  status?: palletStatuses | string
  num?: number
}

export interface IOrderPallet extends IOrderCommonPallet {
  places: IOrderPackagingPlace[]
}

export interface IOrderPalletMarked extends IOrderPallet {
  places: IOrderPackagingMarkedPlace[]
}

export interface IOrderPackedPallet extends IOrderCommonPallet {
  places: string[]
}

export interface IOrderPackedMarkedPallet extends IOrderPackedPallet {
  newBarcode?: string
}

export interface IUpdatePackingPalletResult extends StickerResult {
  pallet_id: string
  pallet_num: number
  stickers?: {
    content: string
    printer: string
  }[]
}

export interface IGetFragileResult extends StickerResult {}

export type IOrderReqPlaceOfPallet = { placeId?: string; orderId?: string }
export interface IOrderReqPallet {
  pallet_id?: string
  places: IOrderReqPlaceOfPallet[]
  pallet_num: number
  weight?: number
  barcode?: string
  dimensions?: { width: number; height: number; depth: number }
  packaging_id?: string
}

export interface IPalletErrorData {
  show: boolean
  data: IOrderReqPallet
  errors: any
}

// add files

export interface IAddFile {
  id: string
  name: string
  url: string
  printed?: boolean
  copies: number
}

export interface addFileResult extends ApiReqResult {
  id: string
  content: string
  printer: string
}

export interface addFilesResult extends ApiReqResult {
  files: IAddFile[]
}

// packages

export interface ICachedPackage {
  [key: string]: string
}

export interface IAddPack {
  packaging_id: string | null
  quantity: number
}

export enum PackagingPurposes {
  orderPlace = 'ORDER_PLACE',
  transportPlace = 'TRANSPORT_PLACE',
  physicalSet = 'PHYSICAL_SET',
  orderPlaceWB = 'ORDER_PLACE_WILDBERRIES_FBS',
  receiptItem = 'RECEIPT_ITEM',
  returnPlaceItem = 'RETURN_PLACE_ITEM',
  monoPalletOversize = 'MONO_PALLET_OVERSIZE',
}
export interface IPackageDto {
  id: string
  title: string
  barcodes: string[]
  require_dimensions?: boolean
  purpose?: PackagingPurposes[]
  /** Изображение ШК для подтверждения */
  img?: string
}
export interface IOrderPackagingOption extends IPackageDto {}

// shipping form
export interface IBaseInstruction {
  id: string
  title: string
  value: string
  soundURL?: string
}
export interface IShippingFormItem extends IBaseInstruction {
  inputType?: string //TODO: возможно, стоить завести enum
  mandatory?: boolean
  tooltip?: string
  name?: string
}

import React from 'react'
import { ComboboxWrapperWithApi } from 'src/components'
import { ICellPlace, IContainer } from 'src/interfaces'
import { CommonFieldProps } from '../../types/props'
import { useGetTaresList } from 'src/hooks'

interface IProps extends CommonFieldProps<ComboboxCellItem> {
  convertHookData?: (data: { containers: IContainer[] }) => any
}
export type ComboboxCellItem = {
  label: string
  id: string
  dto: IContainer
}

const ComboboxWarehouseContainer = (props: IProps) => {
  const {
    label = 'Контейнер',
    placeholder = 'Выберите контейнер',
    convertHookData = (data) => {
      return data.containers.map((el) => ({ label: el.title, id: el.id, dto: el }))
    },
    convertHookParams = (params) => params,
    ...restProps
  } = props

  return (
    <ComboboxWrapperWithApi
      label={label}
      placeholder={placeholder}
      useGetHookSearch={useGetTaresList}
      convertHookParams={convertHookParams}
      convertHookData={convertHookData}
      {...restProps}
    />
  )
}
export default ComboboxWarehouseContainer

import { useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult, PaginationResult, Service, ServiceGroup } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { convertObjectToURLParams } from 'src/helpers'

/*
 * Получение списка услуг
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Skladskie-uslugi/operation/getServices
 */

interface GetServicesListParams {
  params?: {
    search?: string
    group_id?: string
    is_material?: boolean
    size?: number
  }
  options?: UseQueryOptions<any>
}

interface GetServicesListResult extends ApiReqResult, PaginationResult {
  groups: ServiceGroup[]
  services: Service[]
}

const useGetServicesList = ({ params, options }: GetServicesListParams) => {
  return useQuery<GetServicesListResult>(
    [`getServicesList`, params],
    async () => {
      const paramsStr = convertObjectToURLParams(params)
      const result = await api.get(`/billing/service${paramsStr}`)
      return result.data as GetServicesListResult
    },
    options,
  )
}

export default useGetServicesList

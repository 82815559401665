import { useState } from 'react'
import api from 'src/api'
import { convertObjectToURLParams } from '../../helpers'
import { PrintResult } from '../../interfaces'

type Params = {
  barcode_chz: string,
  item_id: string
  order_id?: string
}

const getChzSticker = async (params: Params) => {
  const urlParams = convertObjectToURLParams(params)
  const result = await api.get(`/packing/order/sticker_chz${urlParams}`)

  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data as PrintResult
}

const useGetChzSticker = () => {
  const [loading, setLoading] = useState<boolean>(false)

  return ({
    isLoading: loading,
    fetch: async (params: Params): Promise<PrintResult> => {
      setLoading(true)
      try {
        const result = await getChzSticker(params)
        return result
      } finally {
        setLoading(false)
      }
    },
  })
}

export default useGetChzSticker

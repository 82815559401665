import { ISideTab } from './types'

export const SETTINGS = {
  warehouse: { label: 'Склад', value: 'warehouse' },
  users: { label: 'Пользователи', value: 'users' },
  gates: { label: 'Ворота', value: 'gates' },
  workspaces: { label: 'Рабочие места', value: 'workspaces' },
  zones: { label: 'Зоны', value: 'zones' },
}
export const GUIDES = {
  nomenclature: { label: 'Номенклатура', value: 'nomenclature' },
  roles: { label: 'Роли', value: 'roles' },
  merchants: { label: 'Организации', value: 'merchants' },
  delivery: { label: 'Договоры доставки', value: 'delivery' },
  instructions: { label: 'Инструкции', value: 'instructions' },
  paymentMethods: { label: 'Способы оплаты', value: 'paymentMethods' },
  documentStatuses: { label: 'Статусы документа', value: 'documentStatuses' },
  categories: { label: 'Категории', value: 'categories' },
  vehicles: { label: 'Транспортные средства', value: 'vehicles' },
  carriers: { label: 'Перевозчики', value: 'carriers' },
  carriersCompany: { label: 'Компании перевозчиков', value: 'carriersCompany' },
  serialNumbers: { label: 'Уникальные номера', value: 'serialNumbers' },
}

export const SECTIONS: Record<string, ISideTab> = { ...SETTINGS, ...GUIDES }

export const tabs = {
  settings: {
    label: 'Настройки',
    value: 'settings',
    sideTabs: SETTINGS,
  },
  guides: {
    label: 'Справочники',
    value: 'guides',
    sideTabs: GUIDES,
  },
}

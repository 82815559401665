import { useMutation } from 'react-query'
import api from 'src/api'


const useLoadReturnPlaceFiles = () => {
  return useMutation(
    async (data: {returnId: string; placeId: string; fileId: string}) => {
      const result = await api.delete(`/return/${data.returnId}/place/${data.placeId}/file/${data.fileId}`)
      return result.data
    },
  )
}

export default useLoadReturnPlaceFiles

import React, { createContext, useContext, useEffect, useState } from 'react'
import { useSound } from 'use-sound'

export enum soundNameEnum {
  PACKING_ITEM_ADDED_TO_PLACE = 'PACKING_ITEM_ADDED_TO_PLACE',
  PACKING_WRONG_ITEM = 'PACKING_WRONG_ITEM',
  PACKING_SURPLUS_ITEM = 'PACKING_SURPLUS_ITEM',
  PACKING_PLACE_POSTED = 'PACKING_PLACE_POSTED',
  PACKING_COMPLETED = 'PACKING_COMPLETED',
  PACKING_KIT_ASSEMBLE = 'PACKING_KIT_ASSEMBLE',
  PACKING_WEIGHT_ITEM = 'PACKING_WEIGHT_ITEM',
  RECEIVING_SURPLUS_ITEM = 'RECEIVING_SURPLUS_ITEM',
  RECEIVING_WEIGHT_ITEM = 'RECEIVING_WEIGHT_ITEM',
  PACKING_PLACE_WEIGHT = 'PACKING_PLACE_WEIGHT',
  OTHER_ERROR = 'OTHER_ERROR',
}
const SOUND_NAMES = Object.values(soundNameEnum)

interface ISoundContext {
  play: (id: string) => void
}

export const SoundContext = createContext<ISoundContext | undefined>(undefined)

export const useSoundHook = () => {
  const context = useContext(SoundContext)
  return context
}

export type IAudioRecord =  Record<string, { audio: HTMLAudioElement }>
export const useDynamicSoundsHook = () => {
  const [soundsObj, setSoundsObj] = useState<IAudioRecord>({})

  const setMultipleAudios = (soundsUrls: string[]) => {
    setSoundsObj(soundsUrls.reduce((acc, url) => (
      { ...acc, [url]: { audio: new Audio(url) } }
    ), {}))
  }

  const playAudio = (url: string) => {
    try {
      const audio = new Audio(url)
      audio.addEventListener('canplaythrough', audio.play)
    } catch (e) {
      console.error(e)
    }
  }

  const clearSounds = () => setSoundsObj({})

  return {
    sounds: soundsObj,
    clearSounds,
    setMultipleAudios,
    playAudio
  }
}


export const SoundProvider = (props: React.PropsWithChildren<{}>) => {
  const { children } = props

  useEffect(() => {
    window.soundPlay = play //TODO better
  }, [])

  const soundObj: { [key: string]: { play: () => void } } = {}

  for (let i = 0; i < SOUND_NAMES.length; i++) {
    const name = SOUND_NAMES[i]
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [play] = useSound(`/sounds/${name}.mp3`)
    soundObj[name] = { play }
  }

  const play = (name: string) => soundObj[name].play()

  return (
    <SoundContext.Provider value={{ play }}>{children}</SoundContext.Provider>
  )
}

import { IPallet, IPalletOrder, IPalletPlace } from './pallet'
import { IGate } from './warehouse'
import { IFile } from './other'
import { IMerchant, ISupplier } from './acceptance'
import { ICommonDocument } from './documents'
import { ShipmentPlanStatusesEnum } from './statuses'
import { OrderStatusEnum } from './order'
import { ICarrier, IVehicle } from './carrier'

export enum ShipmentStatusesEnum {
  NEW = 'NEW',
  READY_FOR_LOADING = 'READY_FOR_LOADING',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  DONE = 'DONE',
}

export interface IUploadFile {
  data: string
  title: string
  type: string
}


export interface IShipmentPlan extends ICommonDocument {
  /** Способ доставки */
  delivery_service: string
  /** Способ доставки */
  carrier: ICarrier
  /** Статус */
  status: ShipmentPlanStatusesEnum
  /** Заказы */
  items: IShipmentPlanOrder[]
  /** Время начала приёмки */
  receipt_started_at: string
  /** Ошибка сверки */
  verification_error: string
  /** Поставщик */
  supplier?: ISupplier
}

export interface IShipmentPlanOrder extends ICommonDocument {
  status: OrderStatusEnum
}

export interface IShipmentPlanTable {
  id: string
  merchant?: IMerchant,
  deliveryService: string
}

export interface IShipmentOrder extends IPalletOrder {}
export interface IShipmentOrderPlace extends IPalletPlace {}
export interface IShipmentTransportPlace extends IPallet {
  orders?: string[]
  merchant?: IMerchant
}

export interface IShipment extends ICommonDocument {
  delivery_service: string
  carrier: ICarrier
  vehicle: IVehicle
  gate: IGate
  files: IFile[]
  status: ShipmentStatusesEnum
  items: IShipmentTransportPlace[]
  shipment_plans: IShipmentPlan[]
}

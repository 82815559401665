import React from 'react'
import cx from 'classnames'

import styles from './select-list.module.scss'

import { ISelectListOption } from 'src/interfaces'
import LinkContextMenu from '../Table/LinkContextMenu/LinkContextMenu'


interface SelectListProps {
  options: ISelectListOption[]
  value?: ISelectListOption | null
  onChange: (o: ISelectListOption) => void
  className?: string
  height?: number
  onLabelClick?: boolean
  withContextLink?: boolean
  generatePath?: (option: ISelectListOption) => string
}

const SelectList = (props: SelectListProps) => {
  const {
    options,
    value,
    onChange,
    className,
    height = 310,
    onLabelClick = false,
    withContextLink = false,
    generatePath,
  } = props
  return (
    <div className={cx(styles.wrap, className)}>
      <ul className={styles.main}
          style={{
            height: `${height}px`,
          }}
      >
        {options.length ? (
          <>
            {options.map((o, i) => {
              const className = cx(
                value &&
                (value.value === o.value
                  ? styles.active
                  : styles.notActive),
                styles.li,
              )
              if (withContextLink) {
                return (
                  <LinkContextMenu
                    as={'li'}
                    key={`${o.value}-${i}`}
                    defaultOpenType={'open'}
                    className={className}
                    href={generatePath?.(o)}
                  >
                    <span onClick={onLabelClick ? () => onChange(o) : undefined}>
                      {o.label}
                    </span>
                    {o.rightSide && <>{o.rightSide}</>}
                  </LinkContextMenu>
                )
              }
              return (
                <li
                  className={className}
                  key={`${o.value}-${i}`}
                  onClick={!onLabelClick ? () => onChange(o) : undefined}
                >
                  <span onClick={onLabelClick ? () => onChange(o) : undefined}>
                    {o.label}
                  </span>
                  {o.rightSide && <>{o.rightSide}</>}
                </li>
              )
            })}
          </>
        ) : null}
      </ul>
    </div>
  )
}

export default SelectList

import { IMerchant } from './acceptance'
import { IUserData } from './user'
import { DocumentTypeEnum, IWorkspace } from 'src/interfaces'

export const DocumentsDescription = {
  [DocumentTypeEnum.BILLING]: 'Биллинг',
  [DocumentTypeEnum.SKU_REPLACE]: 'Заявка на коррекцию стока',
  [DocumentTypeEnum.RELABEL]: 'Коррекция стока',
  [DocumentTypeEnum.MOVING]: 'Перемещение товара внутри зоны',
  [DocumentTypeEnum.EXTENDED_MOVING]: 'Перемещение товара между зонами',
  [DocumentTypeEnum.ORDER]: 'Заказ на отгрузку',
  [DocumentTypeEnum.ORDER_BATCH]: 'Батч',
  [DocumentTypeEnum.ORDER_PLACE]: 'Грузовое место',
  [DocumentTypeEnum.TRANSPORT_PLACE]: 'Транспортное место',
  [DocumentTypeEnum.PICKING]: 'Подбор',
  [DocumentTypeEnum.PLACING]: 'Размещение',
  [DocumentTypeEnum.RECEIPT]: 'Приемка',
  [DocumentTypeEnum.SERVICE]: 'Дополнительные складские услуги (биллинг)',
  [DocumentTypeEnum.SUPPLY_PLAN]: 'План поставки',
  [DocumentTypeEnum.SHIPMENT]: 'Отгрузка',
  [DocumentTypeEnum.SHIPMENT_PLAN]: 'План на отгрузку',
  [DocumentTypeEnum.UNLOADING]: 'Предварительная приемка товара на складе',
  [DocumentTypeEnum.PHYSICAL_SET_ORDER_DOC]:
    'Заявка на комплектацию/разукомплектацию набора',
  [DocumentTypeEnum.PHYSICAL_SET_ORDER_PLACE_DOC]: 'Комплектация/разукомплектация',
  [DocumentTypeEnum.CHANGE_SKU_STOCK]: 'Корректировка остатка',
  [DocumentTypeEnum.RETURN]: 'Возврат заказов',
  [DocumentTypeEnum.RETURN_PLACE]: 'Возвращенное грузовое место',
  [DocumentTypeEnum.INVENTORY]: 'Инвентаризация',
  [DocumentTypeEnum.TRANSPORTATION]: 'Перевозки',
  [DocumentTypeEnum.PHYSICAL_SET_ORDER]: 'Задание на комплектацию наборов',
  [DocumentTypeEnum.ORDER_CANCEL]: 'Отмененная часть заказа',
  [DocumentTypeEnum.PHYSICAL_SET_ORDER_PLACE]: 'Обработанный комплект',
  [DocumentTypeEnum.PHYSICAL_SET_ORDER_BATCH]: 'Батч заданий на комплектацию',
} as const

export const NewDocumentsDescription = {
  ...DocumentsDescription,
  [DocumentTypeEnum.ORDER_BATCH]: 'Батчи заказов',
  [DocumentTypeEnum.PICKING]: 'Отборы из адресов',
  [DocumentTypeEnum.RECEIPT]: 'Приходные ордера',
  [DocumentTypeEnum.SERVICE]: 'Оказанные услуги',
  [DocumentTypeEnum.SUPPLY_PLAN]: 'Документы приёмки',
  [DocumentTypeEnum.SHIPMENT]: 'Отгрузка со склада',
  [DocumentTypeEnum.INVENTORY]: 'Приказы на инвентаризацию',
  [DocumentTypeEnum.INVENTORY_CELL]: 'Приказы на частичную инвентаризацию',
  [DocumentTypeEnum.TRANSPORTATION]: 'Перевозки QUBY',
} as const

export interface IDocumentLight {
  activated: boolean
  create_ts: string
  date: string
  id: string
  merchant: IMerchant
  title: string
}

export interface ICommonDocument {
  /** Идентификатор UUID документа */
  uuid?: string
  /** Идентификатор */
  id: string
  /** Флаг активности (false для удаленных документов) */
  activated?: boolean
  /** Комментарий */
  comment?: string
  /** Время создания */
  create_ts?: string
  /** Время документа */
  date: string
  /** Идентификатор из внешней системы */
  external_id?: string
  /** Время последнего обновления */
  update_ts?: string
  /** Комментарий склада */
  warehouse_comment?: string
  /** Организация */
  merchant?: IMerchant
  /** Исполлнитель */
  executor?: IUserData

  facts?: IDocumentsFacts[]

  plans?: IDocumentsFacts[]

  scheduled_from?: string

  scheduled_to?: string

  sku_condition?: string

  status?: string

  workspace?: IWorkspace

  pallet_id?: string
}
export interface IDocumentsFacts {
  activated?: boolean
  cell_barcode: string
  comment?: string
  create_ts?: string
  date: string
  executor?: IUserData
  external_id?: string
  id: string
  items: IFactsItems[]
  merchant?: IMerchant
  pallet_id: string
  status: string
  update_ts?: string
  uuid?: string
  warehouse_comment?: string
  workspace: IWorkspace
}

export interface IFactsItems {
  quantity: number
  sku_batch_id: string
  sku_id: string
}
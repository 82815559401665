/* Тип компонента с бека */
import { TemplateFieldType } from '../types/tableGenerator/generatorTypes'
import { DatePickerPropType } from '@consta/uikit/DatePicker'
import { DATE_FORMAT, DATE_MONTH_FORMAT, DATETIME_REQUEST_FORMAT, TIME_FORMAT } from '../../../helpers'
import days from 'dayjs'

export const getCalendarInputType = (templateType: TemplateFieldType): DatePickerPropType => {
  switch (templateType) {
    case TemplateFieldType.DATETIME:
      return 'date-time'
    case TemplateFieldType.DATE:
      return 'date'
    case TemplateFieldType.YEARMONTH:
      return "month"
    case TemplateFieldType.TIME:
      return "time"
    default:
      return "date"
  }
}
/* Формат данных для запросов */
export const getCalendarValueFormat = (templateType: TemplateFieldType, value: Date): string => {
  console.log(value)
  let format = DATETIME_REQUEST_FORMAT
  switch (templateType) {
    case TemplateFieldType.DATETIME:
      format = DATETIME_REQUEST_FORMAT
      break;
    case TemplateFieldType.DATE:
      format = DATE_FORMAT
      break;
    case TemplateFieldType.YEARMONTH:
      format = DATE_MONTH_FORMAT
      break;
    case TemplateFieldType.TIME:
      format = TIME_FORMAT
      break;
  }
 return days(value).format(format)
}
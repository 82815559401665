export const MOCK_ORDER_BATCH: any = {
  'success': true,
  "order_batch": {
    'id': 'f60a8522-4568-11ee-f590-ac1f6b90eae2',
    'status': 'selected',
    'orders': [
      // MOCK_PROBLEM_PACKING_BARCODE,
      {
        'success': true,
        'order': {
          'id': 'orderId_1',
          // 'id': 'O-TM-5669-1212',
          'operationType': 'PACKAGING_B2B',
          'maxBoxesWithoutPallet': 100000,
          'relabelingType': 'BOXES_AND_PALLETS',
          'with_pallet_composition': false,
          'cancelReason': '',
          'date': '2023-08-27T21:06:29',
          'packing_state': 'packed',
          'DisableStickerAndPackagingFBO': false,
          'global_shipping_guidelines': '',
          'ExtraSeat': false,
          'status': 'CANCELED',
          'dlvId': 'c32e4e07-f508-448b-b470-c6eadcca2f0f',
          'order_shipping_comments': '',
          'operativeComment': '',
          'sorting_number': 1,
          'require_place_weight': true,
          'preferred_barcode_type': 'WILDBERRIES',
          'shippingOptions': {
            'packingType': 'MIX_PACKINGS',
          },
          'shipping_form': [],
          'merchant': {
            'id': '2',
            'title': 'test 2',
            'trade_title': 'test 2',
            'dlv_login': 'test 2',
          },
          'receiver': {
            'contractor_id': '',
            'phone': '70000000000',
            'patronymic': 'Wildberries',
            'name': 'На',
            'surname': 'Покупатель',
          },
          'delivery': {
            'logo': '',
            'title': 'WB',
            'contract_title': '',
            'contract': 'R7WB',
            'address': {
              'country': 'RU',
              'region': '',
              'region_fias': '',
              'area': 'Московская',
              'area_fias': '',
              'city': 'Подольск',
              'city_fias': '',
              'settlement': 'Коледино',
              'settlement_fias': '',
              'street': 'ул Троицкая',
              'house': '20',
              'block': '',
              'building': '',
              'floor': '',
              'flat': '',
              'zip': '',
              'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
            },
            'sorting_center_acceptance_date': '',
            'maximum_place_weight': '',
            'maximum_places_count': '',
            'maximum_pallet_weight': 1000000,
            'maximum_place_dimensions': {},
            'maximum_pallet_dimensions': {
              'width': 100000,
              'depth': 10000000,
              'height': 100000,
            },
          },
          'relabelingOptions': {
            'scanNewBarcodeByClient': 'scanWithUnique',
          },
          'items': [
            {
              'id': 'R72060',
              'title': 'Футболка оверсайз с кармашком для мальчика (140, 146, 158, 164), Цвет: морской Размер: 140',
              'article': '33405-140морской',
              'quantity': 1,
              'weight': 120,
              'image': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc.jpg',
              'image_preview': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc-100.jpg',
              'fragile': '',
              'price_data': {
                'price': 1201,
                'vat': 'NO_VAT',
                'currency': 'RUB',
              },
              'require_weight': false,
              'require_dimensions': false,
              'dimensions': {
                'width': 26,
                'height': 1,
                'depth': 15.5,
              },
              'barcodes': [
                {
                  'barcode': '2',
                  'type': 'COMMON',
                },
                {
                  'barcode': '2123123',
                  'type': 'WILDBERRIES',
                },
              ],
              'packagings': [],
              'scan_serial_numbers': [],
            },
          ],
          'places': [
            {
            id: "203000000630",
            items: [
              {
                id: 'R72060',
                barcode_used: "194135439924",
                quantity: 1,
                serial_numbers: []
              }
            ],
            order_id: "O-TM-5669-1212",
            packaging_id: "d009463c-936b-11ee-0687-ac1f6b90eae2",
            sequence_num: 1,
            weight: 640000,
          }
          ],
          'pallets': [],
          'ad_attachments': [],
        },
        'packagings': [
          {
            'id': 'R7vp_150*150',
            'title': 'ВП пакет 15х15см',
            'require_dimensions': true,
            'barcodes': [
              'vp_150*150',
            ],
          },
          {
            'id': 'R7vp_200*200',
            'title': 'ВП пакет 20х20см',
            'require_dimensions': true,
            'barcodes': [
              'vp_200*200',
            ],
          },
          {
            'id': 'R7vp_300*300',
            'title': 'ВП пакет 30х30см',
            'require_dimensions': true,
            'barcodes': [
              'vp_300*300',
            ],
          },
          {
            'id': 'R7vp_30*40',
            'title': 'ВП пленка 30*40 см',
            'require_dimensions': false,
            'barcodes': [
              'vp_30*40',
            ],
          },
          {
            'id': 'R7200*100*100',
            'title': 'Короб 20-10-10 см',
            'require_dimensions': false,
            'barcodes': [
              '200*100*100',
            ],
          },
          {
            'id': 'R7250*180*100',
            'title': 'Короб 25-18-10 см',
            'require_dimensions': false,
            'barcodes': [
              '250*180*100',
            ],
          },
          {
            'id': 'R7253*205*238',
            'title': 'Короб 25,3-20,5-23,8 см',
            'require_dimensions': false,
            'barcodes': [
              '253*205*238',
            ],
          },
          {
            'id': 'R7270*205*130',
            'title': 'Короб 27-20-13 см',
            'require_dimensions': false,
            'barcodes': [
              '270*205*130',
            ],
          },
          {
            'id': 'R7300*150*150',
            'title': 'Короб 30-15-15 см',
            'require_dimensions': false,
            'barcodes': [
              '300*150*150',
            ],
          },
          {
            'id': 'R7330x250x80',
            'title': 'Короб 33-25-8 см',
            'require_dimensions': false,
            'barcodes': [
              '330*250*80',
            ],
          },
          {
            'id': 'R7400*300*100',
            'title': 'Короб 40-30-10 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*100',
            ],
          },
          {
            'id': 'R7400*300*190',
            'title': 'Короб 40-30-19 см ',
            'require_dimensions': false,
            'barcodes': [
              '400*300*190',
            ],
          },
          {
            'id': 'R7400*300*250',
            'title': 'Короб 40-30-25 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*250',
            ],
          },
          {
            'id': 'R7YY600*400*250',
            'title': 'Короб 60-40-25 см - Т-24',
            'require_dimensions': false,
            'barcodes': [
              '600*400*250',
            ],
          },
          {
            'id': 'R7600*400*400',
            'title': 'Короб 60-40-40 см',
            'require_dimensions': false,
            'barcodes': [
              '600*400*400',
            ],
          },
          {
            'id': 'R7240*320',
            'title': 'Курьер-пакет 24-32 см',
            'require_dimensions': true,
            'barcodes': [
              '240*320',
            ],
          },
          {
            'id': 'R7300*400',
            'title': 'Курьер-пакет 30-40 см',
            'require_dimensions': true,
            'barcodes': [
              '300*400',
            ],
          },
          {
            'id': 'R7400*600',
            'title': 'Курьер-пакет 40-60 см',
            'require_dimensions': true,
            'barcodes': [
              '400*600',
            ],
          },
          {
            'id': 'R7430*500',
            'title': 'Курьер-пакет 43-50 см',
            'require_dimensions': true,
            'barcodes': [
              '430*500',
            ],
          },
          {
            'id': 'R7600*800',
            'title': 'Курьер-пакет 60-80 см',
            'require_dimensions': true,
            'barcodes': [
              '600*800',
            ],
          },
          {
            'id': 'R7no_packaging',
            'title': 'Нет Упаковки',
            'require_dimensions': false,
            'barcodes': [
              'no_packaging',
            ],
          },
          {
            'id': 'R7300*500',
            'title': 'Пакет ПВД 300мм*500мм 75 мкм',
            'require_dimensions': true,
            'barcodes': [
              '300*500',
            ],
          },
          {
            'id': 'R7200*80',
            'title': 'Пленка ПВД (п/рукав) 200 мм*80мм',
            'require_dimensions': true,
            'barcodes': [
              '200*80',
            ],
          },
        ],
      },
      {
        'success': true,
        'order': {
          'id': 'orderId_2',
          'operationType': 'PACKAGING_B2B',
          'maxBoxesWithoutPallet': 100000,
          'relabelingType': 'BOXES_AND_PALLETS',
          'with_pallet_composition': false,
          'cancelReason': '',
          'date': '2023-08-27T21:06:29',
          'packing_state': 'awaiting_packing',
          'DisableStickerAndPackagingFBO': false,
          'global_shipping_guidelines': '',
          'ExtraSeat': false,
          'dlvId': 'c32e4e07-f508-448b-b470-c6eadcca2f0f',
          'order_shipping_comments': '',
          'operativeComment': '',
          'sorting_number': 1,
          'require_place_weight': true,
          'preferred_barcode_type': 'WILDBERRIES',
          'shippingOptions': {
            'packingType': 'MIX_PACKINGS',
          },
          'shipping_form': [],
          'merchant': {
            'id': '2',
            'title': 'test 2',
            'trade_title': 'test 2',
            'dlv_login': 'test 2',
          },
          'receiver': {
            'contractor_id': '',
            'phone': '70000000000',
            'patronymic': 'Wildberries',
            'name': 'На',
            'surname': 'Покупатель',
          },
          'delivery': {
            'logo': '',
            'title': 'WB',
            'contract_title': '',
            'contract': 'R7WB',
            'address': {
              'country': 'RU',
              'region': '',
              'region_fias': '',
              'area': 'Московская',
              'area_fias': '',
              'city': 'Подольск',
              'city_fias': '',
              'settlement': 'Коледино',
              'settlement_fias': '',
              'street': 'ул Троицкая',
              'house': '20',
              'block': '',
              'building': '',
              'floor': '',
              'flat': '',
              'zip': '',
              'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
            },
            'sorting_center_acceptance_date': '',
            'maximum_place_weight': '',
            'maximum_places_count': '',
            'maximum_pallet_weight': 1000000,
            'maximum_place_dimensions': {},
            'maximum_pallet_dimensions': {
              'width': 100000,
              'depth': 10000000,
              'height': 100000,
            },
          },
          'relabelingOptions': {
            'scanNewBarcodeByClient': 'scanWithUnique',
          },
          'items': [
            {
              'id': 'R72060',
              'title': 'Футболка оверсайз с кармашком для мальчика (140, 146, 158, 164), Цвет: морской Размер: 140',
              'article': '33405-140морской',
              'quantity': 1,
              'weight': 120,
              'image': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc.jpg',
              'image_preview': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc-100.jpg',
              'fragile': '',
              'price_data': {
                'price': 1201,
                'vat': 'NO_VAT',
                'currency': 'RUB',
              },
              'require_weight': false,
              'require_dimensions': false,
              'dimensions': {
                'width': 26,
                'height': 1,
                'depth': 15.5,
              },
              'barcodes': [
                {
                  'barcode': '2',
                  'type': 'COMMON',
                },
                {
                  'barcode': '2123123',
                  'type': 'WILDBERRIES',
                },
              ],
              'packagings': [],
              'scan_serial_numbers': [],
            },
          ],
          'places': [],
          'pallets': [],
          'ad_attachments': [],
        },
        'packagings': [
          {
            'id': 'R7vp_150*150',
            'title': 'ВП пакет 15х15см',
            'require_dimensions': true,
            'barcodes': [
              'vp_150*150',
            ],
          },
          {
            'id': 'R7vp_200*200',
            'title': 'ВП пакет 20х20см',
            'require_dimensions': true,
            'barcodes': [
              'vp_200*200',
            ],
          },
          {
            'id': 'R7vp_300*300',
            'title': 'ВП пакет 30х30см',
            'require_dimensions': true,
            'barcodes': [
              'vp_300*300',
            ],
          },
          {
            'id': 'R7vp_30*40',
            'title': 'ВП пленка 30*40 см',
            'require_dimensions': false,
            'barcodes': [
              'vp_30*40',
            ],
          },
          {
            'id': 'R7200*100*100',
            'title': 'Короб 20-10-10 см',
            'require_dimensions': false,
            'barcodes': [
              '200*100*100',
            ],
          },
          {
            'id': 'R7250*180*100',
            'title': 'Короб 25-18-10 см',
            'require_dimensions': false,
            'barcodes': [
              '250*180*100',
            ],
          },
          {
            'id': 'R7253*205*238',
            'title': 'Короб 25,3-20,5-23,8 см',
            'require_dimensions': false,
            'barcodes': [
              '253*205*238',
            ],
          },
          {
            'id': 'R7270*205*130',
            'title': 'Короб 27-20-13 см',
            'require_dimensions': false,
            'barcodes': [
              '270*205*130',
            ],
          },
          {
            'id': 'R7300*150*150',
            'title': 'Короб 30-15-15 см',
            'require_dimensions': false,
            'barcodes': [
              '300*150*150',
            ],
          },
          {
            'id': 'R7330x250x80',
            'title': 'Короб 33-25-8 см',
            'require_dimensions': false,
            'barcodes': [
              '330*250*80',
            ],
          },
          {
            'id': 'R7400*300*100',
            'title': 'Короб 40-30-10 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*100',
            ],
          },
          {
            'id': 'R7400*300*190',
            'title': 'Короб 40-30-19 см ',
            'require_dimensions': false,
            'barcodes': [
              '400*300*190',
            ],
          },
          {
            'id': 'R7400*300*250',
            'title': 'Короб 40-30-25 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*250',
            ],
          },
          {
            'id': 'R7YY600*400*250',
            'title': 'Короб 60-40-25 см - Т-24',
            'require_dimensions': false,
            'barcodes': [
              '600*400*250',
            ],
          },
          {
            'id': 'R7600*400*400',
            'title': 'Короб 60-40-40 см',
            'require_dimensions': false,
            'barcodes': [
              '600*400*400',
            ],
          },
          {
            'id': 'R7240*320',
            'title': 'Курьер-пакет 24-32 см',
            'require_dimensions': true,
            'barcodes': [
              '240*320',
            ],
          },
          {
            'id': 'R7300*400',
            'title': 'Курьер-пакет 30-40 см',
            'require_dimensions': true,
            'barcodes': [
              '300*400',
            ],
          },
          {
            'id': 'R7400*600',
            'title': 'Курьер-пакет 40-60 см',
            'require_dimensions': true,
            'barcodes': [
              '400*600',
            ],
          },
          {
            'id': 'R7430*500',
            'title': 'Курьер-пакет 43-50 см',
            'require_dimensions': true,
            'barcodes': [
              '430*500',
            ],
          },
          {
            'id': 'R7600*800',
            'title': 'Курьер-пакет 60-80 см',
            'require_dimensions': true,
            'barcodes': [
              '600*800',
            ],
          },
          {
            'id': 'R7no_packaging',
            'title': 'Нет Упаковки',
            'require_dimensions': false,
            'barcodes': [
              'no_packaging',
            ],
          },
          {
            'id': 'R7300*500',
            'title': 'Пакет ПВД 300мм*500мм 75 мкм',
            'require_dimensions': true,
            'barcodes': [
              '300*500',
            ],
          },
          {
            'id': 'R7200*80',
            'title': 'Пленка ПВД (п/рукав) 200 мм*80мм',
            'require_dimensions': true,
            'barcodes': [
              '200*80',
            ],
          },
        ],
      },
      {
        'success': true,
        'order': {
          'id': 'orderId_3',
          'operationType': 'PACKAGING_B2B',
          'maxBoxesWithoutPallet': 100000,
          'relabelingType': 'BOXES_AND_PALLETS',
          'with_pallet_composition': false,
          'cancelReason': '',
          'date': '2023-08-27T21:06:29',
          'packing_state': 'awaiting_packing',
          'DisableStickerAndPackagingFBO': false,
          'global_shipping_guidelines': '',
          'ExtraSeat': false,
          'dlvId': 'c32e4e07-f508-448b-b470-c6eadcca2f0f',
          'order_shipping_comments': '',
          'operativeComment': '',
          'sorting_number': 1,
          'require_place_weight': true,
          'preferred_barcode_type': 'WILDBERRIES',
          'shippingOptions': {
            'packingType': 'MIX_PACKINGS',
          },
          'shipping_form': [],
          'merchant': {
            'id': '2',
            'title': 'test 2',
            'trade_title': 'test 2',
            'dlv_login': 'test 2',
          },
          'receiver': {
            'contractor_id': '',
            'phone': '70000000000',
            'patronymic': 'Wildberries',
            'name': 'На',
            'surname': 'Покупатель',
          },
          'delivery': {
            'logo': '',
            'title': 'WB',
            'contract_title': '',
            'contract': 'R7WB',
            'address': {
              'country': 'RU',
              'region': '',
              'region_fias': '',
              'area': 'Московская',
              'area_fias': '',
              'city': 'Подольск',
              'city_fias': '',
              'settlement': 'Коледино',
              'settlement_fias': '',
              'street': 'ул Троицкая',
              'house': '20',
              'block': '',
              'building': '',
              'floor': '',
              'flat': '',
              'zip': '',
              'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
            },
            'sorting_center_acceptance_date': '',
            'maximum_place_weight': '',
            'maximum_places_count': '',
            'maximum_pallet_weight': 1000000,
            'maximum_place_dimensions': {},
            'maximum_pallet_dimensions': {
              'width': 100000,
              'depth': 10000000,
              'height': 100000,
            },
          },
          'relabelingOptions': {
            'scanNewBarcodeByClient': 'scanWithUnique',
          },
          'items': [
            {
              'id': 'R72060',
              'title': 'Футболка оверсайз с кармашком для мальчика (140, 146, 158, 164), Цвет: морской Размер: 140',
              'article': '33405-140морской',
              'quantity': 1,
              'weight': 120,
              'image': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc.jpg',
              'image_preview': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc-100.jpg',
              'fragile': '',
              'price_data': {
                'price': 1201,
                'vat': 'NO_VAT',
                'currency': 'RUB',
              },
              'require_weight': false,
              'require_dimensions': false,
              'dimensions': {
                'width': 26,
                'height': 1,
                'depth': 15.5,
              },
              'barcodes': [
                {
                  'barcode': '2',
                  'type': 'COMMON',
                },
                {
                  'barcode': '2123123',
                  'type': 'WILDBERRIES',
                },
              ],
              'packagings': [],
              'scan_serial_numbers': [],
            },
          ],
          'places': [],
          'pallets': [],
          'ad_attachments': [],
        },
        'packagings': [
          {
            'id': 'R7vp_150*150',
            'title': 'ВП пакет 15х15см',
            'require_dimensions': true,
            'barcodes': [
              'vp_150*150',
            ],
          },
          {
            'id': 'R7vp_200*200',
            'title': 'ВП пакет 20х20см',
            'require_dimensions': true,
            'barcodes': [
              'vp_200*200',
            ],
          },
          {
            'id': 'R7vp_300*300',
            'title': 'ВП пакет 30х30см',
            'require_dimensions': true,
            'barcodes': [
              'vp_300*300',
            ],
          },
          {
            'id': 'R7vp_30*40',
            'title': 'ВП пленка 30*40 см',
            'require_dimensions': false,
            'barcodes': [
              'vp_30*40',
            ],
          },
          {
            'id': 'R7200*100*100',
            'title': 'Короб 20-10-10 см',
            'require_dimensions': false,
            'barcodes': [
              '200*100*100',
            ],
          },
          {
            'id': 'R7250*180*100',
            'title': 'Короб 25-18-10 см',
            'require_dimensions': false,
            'barcodes': [
              '250*180*100',
            ],
          },
          {
            'id': 'R7253*205*238',
            'title': 'Короб 25,3-20,5-23,8 см',
            'require_dimensions': false,
            'barcodes': [
              '253*205*238',
            ],
          },
          {
            'id': 'R7270*205*130',
            'title': 'Короб 27-20-13 см',
            'require_dimensions': false,
            'barcodes': [
              '270*205*130',
            ],
          },
          {
            'id': 'R7300*150*150',
            'title': 'Короб 30-15-15 см',
            'require_dimensions': false,
            'barcodes': [
              '300*150*150',
            ],
          },
          {
            'id': 'R7330x250x80',
            'title': 'Короб 33-25-8 см',
            'require_dimensions': false,
            'barcodes': [
              '330*250*80',
            ],
          },
          {
            'id': 'R7400*300*100',
            'title': 'Короб 40-30-10 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*100',
            ],
          },
          {
            'id': 'R7400*300*190',
            'title': 'Короб 40-30-19 см ',
            'require_dimensions': false,
            'barcodes': [
              '400*300*190',
            ],
          },
          {
            'id': 'R7400*300*250',
            'title': 'Короб 40-30-25 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*250',
            ],
          },
          {
            'id': 'R7YY600*400*250',
            'title': 'Короб 60-40-25 см - Т-24',
            'require_dimensions': false,
            'barcodes': [
              '600*400*250',
            ],
          },
          {
            'id': 'R7600*400*400',
            'title': 'Короб 60-40-40 см',
            'require_dimensions': false,
            'barcodes': [
              '600*400*400',
            ],
          },
          {
            'id': 'R7240*320',
            'title': 'Курьер-пакет 24-32 см',
            'require_dimensions': true,
            'barcodes': [
              '240*320',
            ],
          },
          {
            'id': 'R7300*400',
            'title': 'Курьер-пакет 30-40 см',
            'require_dimensions': true,
            'barcodes': [
              '300*400',
            ],
          },
          {
            'id': 'R7400*600',
            'title': 'Курьер-пакет 40-60 см',
            'require_dimensions': true,
            'barcodes': [
              '400*600',
            ],
          },
          {
            'id': 'R7430*500',
            'title': 'Курьер-пакет 43-50 см',
            'require_dimensions': true,
            'barcodes': [
              '430*500',
            ],
          },
          {
            'id': 'R7600*800',
            'title': 'Курьер-пакет 60-80 см',
            'require_dimensions': true,
            'barcodes': [
              '600*800',
            ],
          },
          {
            'id': 'R7no_packaging',
            'title': 'Нет Упаковки',
            'require_dimensions': false,
            'barcodes': [
              'no_packaging',
            ],
          },
          {
            'id': 'R7300*500',
            'title': 'Пакет ПВД 300мм*500мм 75 мкм',
            'require_dimensions': true,
            'barcodes': [
              '300*500',
            ],
          },
          {
            'id': 'R7200*80',
            'title': 'Пленка ПВД (п/рукав) 200 мм*80мм',
            'require_dimensions': true,
            'barcodes': [
              '200*80',
            ],
          },
        ],
      },
      {
        'success': true,
        'order': {
          'id': 'orderId_4',
          'operationType': 'PACKAGING_B2B',
          'maxBoxesWithoutPallet': 100000,
          'relabelingType': 'BOXES_AND_PALLETS',
          'with_pallet_composition': false,
          'cancelReason': '',
          'date': '2023-08-27T21:06:29',
          'packing_state': 'awaiting_packing',
          'DisableStickerAndPackagingFBO': false,
          'global_shipping_guidelines': '',
          'ExtraSeat': false,
          'dlvId': 'c32e4e07-f508-448b-b470-c6eadcca2f0f',
          'order_shipping_comments': '',
          'operativeComment': '',
          'sorting_number': 1,
          'require_place_weight': true,
          'preferred_barcode_type': 'WILDBERRIES',
          'shippingOptions': {
            'packingType': 'MIX_PACKINGS',
          },
          'shipping_form': [],
          'merchant': {
            'id': '2',
            'title': 'test 2',
            'trade_title': 'test 2',
            'dlv_login': 'test 2',
          },
          'receiver': {
            'contractor_id': '',
            'phone': '70000000000',
            'patronymic': 'Wildberries',
            'name': 'На',
            'surname': 'Покупатель',
          },
          'delivery': {
            'logo': '',
            'title': 'WB',
            'contract_title': '',
            'contract': 'R7WB',
            'address': {
              'country': 'RU',
              'region': '',
              'region_fias': '',
              'area': 'Московская',
              'area_fias': '',
              'city': 'Подольск',
              'city_fias': '',
              'settlement': 'Коледино',
              'settlement_fias': '',
              'street': 'ул Троицкая',
              'house': '20',
              'block': '',
              'building': '',
              'floor': '',
              'flat': '',
              'zip': '',
              'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
            },
            'sorting_center_acceptance_date': '',
            'maximum_place_weight': '',
            'maximum_places_count': '',
            'maximum_pallet_weight': 1000000,
            'maximum_place_dimensions': {},
            'maximum_pallet_dimensions': {
              'width': 100000,
              'depth': 10000000,
              'height': 100000,
            },
          },
          'relabelingOptions': {
            'scanNewBarcodeByClient': 'scanWithUnique',
          },
          'items': [
            {
              'id': 'R72060',
              'title': 'Футболка оверсайз с кармашком для мальчика (140, 146, 158, 164), Цвет: морской Размер: 140',
              'article': '33405-140морской',
              'quantity': 1,
              'weight': 120,
              'image': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc.jpg',
              'image_preview': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc-100.jpg',
              'fragile': '',
              'price_data': {
                'price': 1201,
                'vat': 'NO_VAT',
                'currency': 'RUB',
              },
              'require_weight': false,
              'require_dimensions': false,
              'dimensions': {
                'width': 26,
                'height': 1,
                'depth': 15.5,
              },
              'barcodes': [
                {
                  'barcode': '2',
                  'type': 'COMMON',
                },
                {
                  'barcode': '2123123',
                  'type': 'WILDBERRIES',
                },
              ],
              'packagings': [],
              'scan_serial_numbers': [],
            },
          ],
          'places': [],
          'pallets': [],
          'ad_attachments': [],
        },
        'packagings': [
          {
            'id': 'R7vp_150*150',
            'title': 'ВП пакет 15х15см',
            'require_dimensions': true,
            'barcodes': [
              'vp_150*150',
            ],
          },
          {
            'id': 'R7vp_200*200',
            'title': 'ВП пакет 20х20см',
            'require_dimensions': true,
            'barcodes': [
              'vp_200*200',
            ],
          },
          {
            'id': 'R7vp_300*300',
            'title': 'ВП пакет 30х30см',
            'require_dimensions': true,
            'barcodes': [
              'vp_300*300',
            ],
          },
          {
            'id': 'R7vp_30*40',
            'title': 'ВП пленка 30*40 см',
            'require_dimensions': false,
            'barcodes': [
              'vp_30*40',
            ],
          },
          {
            'id': 'R7200*100*100',
            'title': 'Короб 20-10-10 см',
            'require_dimensions': false,
            'barcodes': [
              '200*100*100',
            ],
          },
          {
            'id': 'R7250*180*100',
            'title': 'Короб 25-18-10 см',
            'require_dimensions': false,
            'barcodes': [
              '250*180*100',
            ],
          },
          {
            'id': 'R7253*205*238',
            'title': 'Короб 25,3-20,5-23,8 см',
            'require_dimensions': false,
            'barcodes': [
              '253*205*238',
            ],
          },
          {
            'id': 'R7270*205*130',
            'title': 'Короб 27-20-13 см',
            'require_dimensions': false,
            'barcodes': [
              '270*205*130',
            ],
          },
          {
            'id': 'R7300*150*150',
            'title': 'Короб 30-15-15 см',
            'require_dimensions': false,
            'barcodes': [
              '300*150*150',
            ],
          },
          {
            'id': 'R7330x250x80',
            'title': 'Короб 33-25-8 см',
            'require_dimensions': false,
            'barcodes': [
              '330*250*80',
            ],
          },
          {
            'id': 'R7400*300*100',
            'title': 'Короб 40-30-10 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*100',
            ],
          },
          {
            'id': 'R7400*300*190',
            'title': 'Короб 40-30-19 см ',
            'require_dimensions': false,
            'barcodes': [
              '400*300*190',
            ],
          },
          {
            'id': 'R7400*300*250',
            'title': 'Короб 40-30-25 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*250',
            ],
          },
          {
            'id': 'R7YY600*400*250',
            'title': 'Короб 60-40-25 см - Т-24',
            'require_dimensions': false,
            'barcodes': [
              '600*400*250',
            ],
          },
          {
            'id': 'R7600*400*400',
            'title': 'Короб 60-40-40 см',
            'require_dimensions': false,
            'barcodes': [
              '600*400*400',
            ],
          },
          {
            'id': 'R7240*320',
            'title': 'Курьер-пакет 24-32 см',
            'require_dimensions': true,
            'barcodes': [
              '240*320',
            ],
          },
          {
            'id': 'R7300*400',
            'title': 'Курьер-пакет 30-40 см',
            'require_dimensions': true,
            'barcodes': [
              '300*400',
            ],
          },
          {
            'id': 'R7400*600',
            'title': 'Курьер-пакет 40-60 см',
            'require_dimensions': true,
            'barcodes': [
              '400*600',
            ],
          },
          {
            'id': 'R7430*500',
            'title': 'Курьер-пакет 43-50 см',
            'require_dimensions': true,
            'barcodes': [
              '430*500',
            ],
          },
          {
            'id': 'R7600*800',
            'title': 'Курьер-пакет 60-80 см',
            'require_dimensions': true,
            'barcodes': [
              '600*800',
            ],
          },
          {
            'id': 'R7no_packaging',
            'title': 'Нет Упаковки',
            'require_dimensions': false,
            'barcodes': [
              'no_packaging',
            ],
          },
          {
            'id': 'R7300*500',
            'title': 'Пакет ПВД 300мм*500мм 75 мкм',
            'require_dimensions': true,
            'barcodes': [
              '300*500',
            ],
          },
          {
            'id': 'R7200*80',
            'title': 'Пленка ПВД (п/рукав) 200 мм*80мм',
            'require_dimensions': true,
            'barcodes': [
              '200*80',
            ],
          },
        ],
      },
      {
        'success': true,
        'order': {
          'id': 'orderId_5',
          'operationType': 'PACKAGING_B2B',
          'maxBoxesWithoutPallet': 100000,
          'relabelingType': 'BOXES_AND_PALLETS',
          'with_pallet_composition': false,
          'cancelReason': '',
          'date': '2023-08-27T21:06:29',
          'packing_state': 'awaiting_packing',
          'DisableStickerAndPackagingFBO': false,
          'global_shipping_guidelines': '',
          'ExtraSeat': false,
          'dlvId': 'c32e4e07-f508-448b-b470-c6eadcca2f0f',
          'order_shipping_comments': '',
          'operativeComment': '',
          'sorting_number': 1,
          'require_place_weight': true,
          'preferred_barcode_type': 'WILDBERRIES',
          'shippingOptions': {
            'packingType': 'MIX_PACKINGS',
          },
          'shipping_form': [],
          'merchant': {
            'id': '2',
            'title': 'test 2',
            'trade_title': 'test 2',
            'dlv_login': 'test 2',
          },
          'receiver': {
            'contractor_id': '',
            'phone': '70000000000',
            'patronymic': 'Wildberries',
            'name': 'На',
            'surname': 'Покупатель',
          },
          'delivery': {
            'logo': '',
            'title': 'WB',
            'contract_title': '',
            'contract': 'R7WB',
            'address': {
              'country': 'RU',
              'region': '',
              'region_fias': '',
              'area': 'Московская',
              'area_fias': '',
              'city': 'Подольск',
              'city_fias': '',
              'settlement': 'Коледино',
              'settlement_fias': '',
              'street': 'ул Троицкая',
              'house': '20',
              'block': '',
              'building': '',
              'floor': '',
              'flat': '',
              'zip': '',
              'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
            },
            'sorting_center_acceptance_date': '',
            'maximum_place_weight': '',
            'maximum_places_count': '',
            'maximum_pallet_weight': 1000000,
            'maximum_place_dimensions': {},
            'maximum_pallet_dimensions': {
              'width': 100000,
              'depth': 10000000,
              'height': 100000,
            },
          },
          'relabelingOptions': {
            'scanNewBarcodeByClient': 'scanWithUnique',
          },
          'items': [
            {
              'id': 'R72060',
              'title': 'Футболка оверсайз с кармашком для мальчика (140, 146, 158, 164), Цвет: морской Размер: 140',
              'article': '33405-140морской',
              'quantity': 1,
              'weight': 120,
              'image': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc.jpg',
              'image_preview': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc-100.jpg',
              'fragile': '',
              'price_data': {
                'price': 1201,
                'vat': 'NO_VAT',
                'currency': 'RUB',
              },
              'require_weight': false,
              'require_dimensions': false,
              'dimensions': {
                'width': 26,
                'height': 1,
                'depth': 15.5,
              },
              'barcodes': [
                {
                  'barcode': '2',
                  'type': 'COMMON',
                },
                {
                  'barcode': '2123123',
                  'type': 'WILDBERRIES',
                },
              ],
              'packagings': [],
              'scan_serial_numbers': [],
            },
          ],
          'places': [],
          'pallets': [],
          'ad_attachments': [],
        },
        'packagings': [
          {
            'id': 'R7vp_150*150',
            'title': 'ВП пакет 15х15см',
            'require_dimensions': true,
            'barcodes': [
              'vp_150*150',
            ],
          },
          {
            'id': 'R7vp_200*200',
            'title': 'ВП пакет 20х20см',
            'require_dimensions': true,
            'barcodes': [
              'vp_200*200',
            ],
          },
          {
            'id': 'R7vp_300*300',
            'title': 'ВП пакет 30х30см',
            'require_dimensions': true,
            'barcodes': [
              'vp_300*300',
            ],
          },
          {
            'id': 'R7vp_30*40',
            'title': 'ВП пленка 30*40 см',
            'require_dimensions': false,
            'barcodes': [
              'vp_30*40',
            ],
          },
          {
            'id': 'R7200*100*100',
            'title': 'Короб 20-10-10 см',
            'require_dimensions': false,
            'barcodes': [
              '200*100*100',
            ],
          },
          {
            'id': 'R7250*180*100',
            'title': 'Короб 25-18-10 см',
            'require_dimensions': false,
            'barcodes': [
              '250*180*100',
            ],
          },
          {
            'id': 'R7253*205*238',
            'title': 'Короб 25,3-20,5-23,8 см',
            'require_dimensions': false,
            'barcodes': [
              '253*205*238',
            ],
          },
          {
            'id': 'R7270*205*130',
            'title': 'Короб 27-20-13 см',
            'require_dimensions': false,
            'barcodes': [
              '270*205*130',
            ],
          },
          {
            'id': 'R7300*150*150',
            'title': 'Короб 30-15-15 см',
            'require_dimensions': false,
            'barcodes': [
              '300*150*150',
            ],
          },
          {
            'id': 'R7330x250x80',
            'title': 'Короб 33-25-8 см',
            'require_dimensions': false,
            'barcodes': [
              '330*250*80',
            ],
          },
          {
            'id': 'R7400*300*100',
            'title': 'Короб 40-30-10 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*100',
            ],
          },
          {
            'id': 'R7400*300*190',
            'title': 'Короб 40-30-19 см ',
            'require_dimensions': false,
            'barcodes': [
              '400*300*190',
            ],
          },
          {
            'id': 'R7400*300*250',
            'title': 'Короб 40-30-25 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*250',
            ],
          },
          {
            'id': 'R7YY600*400*250',
            'title': 'Короб 60-40-25 см - Т-24',
            'require_dimensions': false,
            'barcodes': [
              '600*400*250',
            ],
          },
          {
            'id': 'R7600*400*400',
            'title': 'Короб 60-40-40 см',
            'require_dimensions': false,
            'barcodes': [
              '600*400*400',
            ],
          },
          {
            'id': 'R7240*320',
            'title': 'Курьер-пакет 24-32 см',
            'require_dimensions': true,
            'barcodes': [
              '240*320',
            ],
          },
          {
            'id': 'R7300*400',
            'title': 'Курьер-пакет 30-40 см',
            'require_dimensions': true,
            'barcodes': [
              '300*400',
            ],
          },
          {
            'id': 'R7400*600',
            'title': 'Курьер-пакет 40-60 см',
            'require_dimensions': true,
            'barcodes': [
              '400*600',
            ],
          },
          {
            'id': 'R7430*500',
            'title': 'Курьер-пакет 43-50 см',
            'require_dimensions': true,
            'barcodes': [
              '430*500',
            ],
          },
          {
            'id': 'R7600*800',
            'title': 'Курьер-пакет 60-80 см',
            'require_dimensions': true,
            'barcodes': [
              '600*800',
            ],
          },
          {
            'id': 'R7no_packaging',
            'title': 'Нет Упаковки',
            'require_dimensions': false,
            'barcodes': [
              'no_packaging',
            ],
          },
          {
            'id': 'R7300*500',
            'title': 'Пакет ПВД 300мм*500мм 75 мкм',
            'require_dimensions': true,
            'barcodes': [
              '300*500',
            ],
          },
          {
            'id': 'R7200*80',
            'title': 'Пленка ПВД (п/рукав) 200 мм*80мм',
            'require_dimensions': true,
            'barcodes': [
              '200*80',
            ],
          },
        ],
      },
      {
        'success': true,
        'order': {
          'id': 'orderId_6',
          'operationType': 'PACKAGING_B2B',
          'maxBoxesWithoutPallet': 100000,
          'relabelingType': 'BOXES_AND_PALLETS',
          'with_pallet_composition': false,
          'cancelReason': '',
          'date': '2023-08-27T21:06:29',
          'packing_state': 'awaiting_packing',
          'DisableStickerAndPackagingFBO': false,
          'global_shipping_guidelines': '',
          'ExtraSeat': false,
          'dlvId': 'c32e4e07-f508-448b-b470-c6eadcca2f0f',
          'order_shipping_comments': '',
          'operativeComment': '',
          'sorting_number': 1,
          'require_place_weight': true,
          'preferred_barcode_type': 'WILDBERRIES',
          'shippingOptions': {
            'packingType': 'MIX_PACKINGS',
          },
          'shipping_form': [],
          'merchant': {
            'id': '2',
            'title': 'test 2',
            'trade_title': 'test 2',
            'dlv_login': 'test 2',
          },
          'receiver': {
            'contractor_id': '',
            'phone': '70000000000',
            'patronymic': 'Wildberries',
            'name': 'На',
            'surname': 'Покупатель',
          },
          'delivery': {
            'logo': '',
            'title': 'WB',
            'contract_title': '',
            'contract': 'R7WB',
            'address': {
              'country': 'RU',
              'region': '',
              'region_fias': '',
              'area': 'Московская',
              'area_fias': '',
              'city': 'Подольск',
              'city_fias': '',
              'settlement': 'Коледино',
              'settlement_fias': '',
              'street': 'ул Троицкая',
              'house': '20',
              'block': '',
              'building': '',
              'floor': '',
              'flat': '',
              'zip': '',
              'fullAddress': '142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20',
            },
            'sorting_center_acceptance_date': '',
            'maximum_place_weight': '',
            'maximum_places_count': '',
            'maximum_pallet_weight': 1000000,
            'maximum_place_dimensions': {},
            'maximum_pallet_dimensions': {
              'width': 100000,
              'depth': 10000000,
              'height': 100000,
            },
          },
          'relabelingOptions': {
            'scanNewBarcodeByClient': 'scanWithUnique',
          },
          'items': [
            {
              'id': 'R72060',
              'title': 'Футболка оверсайз с кармашком для мальчика (140, 146, 158, 164), Цвет: морской Размер: 140',
              'article': '33405-140морской',
              'quantity': 1,
              'weight': 120,
              'image': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc.jpg',
              'image_preview': 'https://storage.yandexcloud.net/images-k2c/876d3ebe-9ea7-43c3-a498-78c09b3d7ddc-100.jpg',
              'fragile': '',
              'price_data': {
                'price': 1201,
                'vat': 'NO_VAT',
                'currency': 'RUB',
              },
              'require_weight': false,
              'require_dimensions': false,
              'dimensions': {
                'width': 26,
                'height': 1,
                'depth': 15.5,
              },
              'barcodes': [
                {
                  'barcode': '2',
                  'type': 'COMMON',
                },
                {
                  'barcode': '2123123',
                  'type': 'WILDBERRIES',
                },
              ],
              'packagings': [],
              'scan_serial_numbers': [],
            },
          ],
          'places': [],
          'pallets': [],
          'ad_attachments': [],
        },
        'packagings': [
          {
            'id': 'R7vp_150*150',
            'title': 'ВП пакет 15х15см',
            'require_dimensions': true,
            'barcodes': [
              'vp_150*150',
            ],
          },
          {
            'id': 'R7vp_200*200',
            'title': 'ВП пакет 20х20см',
            'require_dimensions': true,
            'barcodes': [
              'vp_200*200',
            ],
          },
          {
            'id': 'R7vp_300*300',
            'title': 'ВП пакет 30х30см',
            'require_dimensions': true,
            'barcodes': [
              'vp_300*300',
            ],
          },
          {
            'id': 'R7vp_30*40',
            'title': 'ВП пленка 30*40 см',
            'require_dimensions': false,
            'barcodes': [
              'vp_30*40',
            ],
          },
          {
            'id': 'R7200*100*100',
            'title': 'Короб 20-10-10 см',
            'require_dimensions': false,
            'barcodes': [
              '200*100*100',
            ],
          },
          {
            'id': 'R7250*180*100',
            'title': 'Короб 25-18-10 см',
            'require_dimensions': false,
            'barcodes': [
              '250*180*100',
            ],
          },
          {
            'id': 'R7253*205*238',
            'title': 'Короб 25,3-20,5-23,8 см',
            'require_dimensions': false,
            'barcodes': [
              '253*205*238',
            ],
          },
          {
            'id': 'R7270*205*130',
            'title': 'Короб 27-20-13 см',
            'require_dimensions': false,
            'barcodes': [
              '270*205*130',
            ],
          },
          {
            'id': 'R7300*150*150',
            'title': 'Короб 30-15-15 см',
            'require_dimensions': false,
            'barcodes': [
              '300*150*150',
            ],
          },
          {
            'id': 'R7330x250x80',
            'title': 'Короб 33-25-8 см',
            'require_dimensions': false,
            'barcodes': [
              '330*250*80',
            ],
          },
          {
            'id': 'R7400*300*100',
            'title': 'Короб 40-30-10 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*100',
            ],
          },
          {
            'id': 'R7400*300*190',
            'title': 'Короб 40-30-19 см ',
            'require_dimensions': false,
            'barcodes': [
              '400*300*190',
            ],
          },
          {
            'id': 'R7400*300*250',
            'title': 'Короб 40-30-25 см',
            'require_dimensions': false,
            'barcodes': [
              '400*300*250',
            ],
          },
          {
            'id': 'R7YY600*400*250',
            'title': 'Короб 60-40-25 см - Т-24',
            'require_dimensions': false,
            'barcodes': [
              '600*400*250',
            ],
          },
          {
            'id': 'R7600*400*400',
            'title': 'Короб 60-40-40 см',
            'require_dimensions': false,
            'barcodes': [
              '600*400*400',
            ],
          },
          {
            'id': 'R7240*320',
            'title': 'Курьер-пакет 24-32 см',
            'require_dimensions': true,
            'barcodes': [
              '240*320',
            ],
          },
          {
            'id': 'R7300*400',
            'title': 'Курьер-пакет 30-40 см',
            'require_dimensions': true,
            'barcodes': [
              '300*400',
            ],
          },
          {
            'id': 'R7400*600',
            'title': 'Курьер-пакет 40-60 см',
            'require_dimensions': true,
            'barcodes': [
              '400*600',
            ],
          },
          {
            'id': 'R7430*500',
            'title': 'Курьер-пакет 43-50 см',
            'require_dimensions': true,
            'barcodes': [
              '430*500',
            ],
          },
          {
            'id': 'R7600*800',
            'title': 'Курьер-пакет 60-80 см',
            'require_dimensions': true,
            'barcodes': [
              '600*800',
            ],
          },
          {
            'id': 'R7no_packaging',
            'title': 'Нет Упаковки',
            'require_dimensions': false,
            'barcodes': [
              'no_packaging',
            ],
          },
          {
            'id': 'R7300*500',
            'title': 'Пакет ПВД 300мм*500мм 75 мкм',
            'require_dimensions': true,
            'barcodes': [
              '300*500',
            ],
          },
          {
            'id': 'R7200*80',
            'title': 'Пленка ПВД (п/рукав) 200 мм*80мм',
            'require_dimensions': true,
            'barcodes': [
              '200*80',
            ],
          },
        ],
      },
    ],
  }
}
export const MOCK_ORDER_BATCH_2: any = {
  'success': true,
  "order_batch": {
    "id": "51298e2c-4635-11ee-338c-ac1f6b90eae2",
    "status": "selected",
    "orders": [
        {
            "success": true,
            "order": {
                "id": "XH1019661952",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-28T22:12:06",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "5fe095d5-8aff-4a56-8bc0-b95c4b8c9270",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 7,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [
                    {
                        "id": "instruction",
                        "title": "Заголовок инструкции",
                        "value": "<h4>Памперсы - упакуй в ПВД пакет</h4>",
                        "soundURL": ""
                    }
                ],
                "merchant": {
                    "id": "4f5a1d9e-67c7-11ec-2996-002590357b68",
                    "title": "Иглмосс Эдишинз",
                    "trade_title": "Иглмосс Эдишинз",
                    "dlv_login": "collections@eaglemoss.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "XHWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "XH2924",
                        "title": "Коллекционная фигурка DC \"Охотница (Хелена Бертинелли)\"",
                        "article": "CDCUK053",
                        "quantity": 1,
                        "weight": 120,
                        "image": "",
                        "image_preview": "",
                        "fragile": "",
                        "price_data": {
                            "price": 330,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 20,
                            "height": 7,
                            "depth": 16
                        },
                        "barcodes": [
                            {
                                "barcode": "977175482750253",
                                "type": "COMMON"
                            },
                            {
                                "barcode": "1",
                                // "type": "WILDBERRIES"
                                "type": "COMMON"
                            },
                        ],
                        "packagings": [],
                        "scan_serial_numbers": [
                          // {s
                          {
                            code: 'chestniy_znak',
                            title: 'Честный знак',
                            template: '*',
                            // required: true
                          },
                        ]
                    }
                ],
                "places": [
                    // {
                    //     "id": "14163232565",
                    //     "temp_id": "*0wxxzVp",
                    //     "packaging_id": "XH250*180*100",
                    //     "sequence_num": "1",
                    //     "weight": 272,
                    //     "items": [
                    //         {
                    //             "id": "XH2924",
                    //             "barcode_used": "977175482750253",
                    //             "quantity": 1,
                    //             "serial_numbers": [],
                    //             "packagings": []
                    //         }
                    //     ]
                    // }
                ],
                "pallets": [
                    // {
                    //     "pallet_id": "WB0000014279",
                    //     "status": "PACKED",
                    //     "pallet_num": 1,
                    //     "weight": 819,
                    //     "dimensions": {
                    //         "depth": 300,
                    //         "width": 190,
                    //         "height": 400
                    //     },
                    //     "places": [
                    //         "14163232565"
                    //     ]
                    // }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                  "barcodes": [
                    "300*150*150"
                  ],
                  "id": "300*150*150",
                  "title": "ТЛ Короб 30-15-15 см",
                  "require_dimensions": false,
                },
                {
                    "id": "XHvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "XHvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "XHvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "XHvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "XH200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "XH250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "XH253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "XH270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "XH300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "XH330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80"
                    ]
                },
                {
                    "id": "XH400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "XH400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "XH400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "XHYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "XH600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "XH240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "XH300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "XH400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "XH430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "XH600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "XHno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "XH300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "XH200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "XH1019718286",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-28T22:44:55",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "50385866-7401-4e79-ae94-04be5b0ad877",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 1,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "4f5a1d9e-67c7-11ec-2996-002590357b68",
                    "title": "Иглмосс Эдишинз",
                    "trade_title": "Иглмосс Эдишинз",
                    "dlv_login": "collections@eaglemoss.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "XHWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "XH2325",
                        "title": "Чужой и Хищник. Ноланд",
                        "article": "ALNUK017",
                        "quantity": 1,
                        "weight": 410,
                        "image": "",
                        "image_preview": "",
                        "fragile": "",
                        "price_data": {
                            "price": 416,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 20,
                            "height": 28,
                            "depth": 16
                        },
                        "barcodes": [
                            {
                                "barcode": "641945982756",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    // {
                    //     "id": "14163317776",
                    //     "temp_id": "*0wzFBBk",
                    //     "packaging_id": "XH400*300*190",
                    //     "sequence_num": "1",
                    //     "weight": 726,
                    //     "items": [
                    //         {
                    //             "id": "XH2325",
                    //             "barcode_used": "7u",
                    //             "quantity": 1,
                    //             "serial_numbers": [],
                    //             "packagings": []
                    //         }
                    //     ]
                    // }
                ],
                "pallets": [
                    // {
                    //     "pallet_id": "WB0000014030",
                    //     "status": "PACKED",
                    //     "pallet_num": 1,
                    //     "weight": 3428,
                    //     "dimensions": {
                    //         "depth": "",
                    //         "width": "",
                    //         "height": ""
                    //     },
                    //     "places": [
                    //         "14163317776"
                    //     ]
                    // }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "XHvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "XHvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "XHvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "XHvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "XH200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "XH250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "XH253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "XH270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "XH300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "XH330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80"
                    ]
                },
                {
                    "id": "XH400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "XH400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "XH400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "XHYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "XH600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "XH240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "XH300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "XH400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "XH430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "XH600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "XHno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "XH300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "XH200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "XH1019791682",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-28T23:39:29",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "7f00eb35-3ec1-4f5d-9500-b079ecafd4a8",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 2,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "4f5a1d9e-67c7-11ec-2996-002590357b68",
                    "title": "Иглмосс Эдишинз",
                    "trade_title": "Иглмосс Эдишинз",
                    "dlv_login": "collections@eaglemoss.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "XHWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "XH5278",
                        "title": "Модель грузового судна \"Аксанар\". Стартрек",
                        "article": "SSSUK167",
                        "quantity": 1,
                        "weight": 309,
                        "image": "",
                        "image_preview": "",
                        "fragile": "",
                        "price_data": {
                            "price": 439,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 28,
                            "height": 5,
                            "depth": 22
                        },
                        "barcodes": [
                            {
                                "barcode": "2861061713418",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    // {
                    //     "id": "14163463705",
                    //     "temp_id": "*0w1Thlh",
                    //     "packaging_id": "XH330x250x80",
                    //     "sequence_num": "1",
                    //     "weight": 455,
                    //     "items": [
                    //         {
                    //             "id": "XH5278",
                    //             "barcode_used": "2861061713418",
                    //             "quantity": 1,
                    //             "serial_numbers": [],
                    //             "packagings": []
                    //         }
                    //     ]
                    // }
                ],
                "pallets": [
                    // {
                    //     "pallet_id": "WB0000014030",
                    //     "status": "PACKED",
                    //     "pallet_num": 1,
                    //     "weight": 3428,
                    //     "dimensions": {
                    //         "depth": "",
                    //         "width": "",
                    //         "height": ""
                    //     },
                    //     "places": [
                    //         "14163463705"
                    //     ]
                    // }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "XHvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "XHvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "XHvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "XHvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "XH200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "XH250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "XH253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "XH270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "XH300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "XH330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80"
                    ]
                },
                {
                    "id": "XH400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "XH400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "XH400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "XHYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "XH600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "XH240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "XH300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "XH400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "XH430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "XH600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "XHno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "XH300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "XH200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "XH1019791681",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-28T23:39:34",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "0890ffaa-6ff4-432c-b232-5078383b0c77",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 3,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "4f5a1d9e-67c7-11ec-2996-002590357b68",
                    "title": "Иглмосс Эдишинз",
                    "trade_title": "Иглмосс Эдишинз",
                    "dlv_login": "collections@eaglemoss.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "XHWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "XH3164",
                        "title": "Модель военного корабля клингонов \"Хищная птица\" (XXII век). Стартрек",
                        "article": "SSSUK035",
                        "quantity": 1,
                        "weight": 271,
                        "image": "",
                        "image_preview": "",
                        "fragile": "",
                        "price_data": {
                            "price": 439,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 29,
                            "height": 5,
                            "depth": 22
                        },
                        "barcodes": [
                            {
                                "barcode": "977205001510535",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    // {
                    //     "id": "14163463704",
                    //     "temp_id": "*0w1Thhm",
                    //     "packaging_id": "XH330x250x80",
                    //     "sequence_num": "1",
                    //     "weight": 427,
                    //     "items": [
                    //         {
                    //             "id": "XH3164",
                    //             "barcode_used": "977205001510535",
                    //             "quantity": 1,
                    //             "serial_numbers": [],
                    //             "packagings": []
                    //         }
                    //     ]
                    // }
                ],
                "pallets": [
                    // {
                    //     "pallet_id": "WB0000014030",
                    //     "status": "PACKED",
                    //     "pallet_num": 1,
                    //     "weight": 3428,
                    //     "dimensions": {
                    //         "depth": "",
                    //         "width": "",
                    //         "height": ""
                    //     },
                    //     "places": [
                    //         "14163463704"
                    //     ]
                    // }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "XHvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "XHvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "XHvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "XHvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "XH200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "XH250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "XH253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "XH270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "XH300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "XH330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80"
                    ]
                },
                {
                    "id": "XH400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "XH400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "XH400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "XHYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "XH600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "XH240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "XH300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "XH400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "XH430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "XH600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "XHno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "XH300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "XH200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "XH1020074061",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-29T08:05:41",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "df7818f1-3688-413d-b4be-b78042fe0c4a",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 4,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "merchant": {
                    "id": "4f5a1d9e-67c7-11ec-2996-002590357b68",
                    "title": "Иглмосс Эдишинз",
                    "trade_title": "Иглмосс Эдишинз",
                    "dlv_login": "collections@eaglemoss.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "XHWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "shipping_form": [
                    {
                        "id": "instruction",
                        "title": "Заголовок инструкции",
                        "value": "<h4>Памперсы - упакуй в ПВД пакет</h4>",
                        "soundURL": ""
                    }
                ],
                "items": [
                    {
                        "id": "XH2420",
                        "title": "Коллекционная фигурка DC \"Брейниак\"",
                        "article": "CDCUK055",
                        "quantity": 1,
                        "weight": 167,
                        "image": "",
                        "image_preview": "",
                        "fragile": "",
                        "price_data": {
                            "price": 328.96,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 20,
                            "height": 7,
                            "depth": 15
                        },
                        "barcodes": [
                            {
                                "barcode": "977175482750255",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": [],
                        "instruction_id": "1"
                    }
                ],
                "places": [
                    // {
                    //     "id": "14164051811",
                    //     "temp_id": "*0w+R2M4",
                    //     "packaging_id": "XH250*180*100",
                    //     "sequence_num": "1",
                    //     "weight": 260,
                    //     "items": [
                    //         {
                    //             "id": "XH2420",
                    //             "barcode_used": "977175482750255",
                    //             "quantity": 1,
                    //             "serial_numbers": [],
                    //             "packagings": []
                    //         }
                    //     ]
                    // }
                ],
                "pallets": [
                    // {
                    //     "pallet_id": "WB0000014030",
                    //     "status": "PACKED",
                    //     "pallet_num": 1,
                    //     "weight": 3428,
                    //     "dimensions": {
                    //         "depth": "",
                    //         "width": "",
                    //         "height": ""
                    //     },
                    //     "places": [
                    //         "14164051811"
                    //     ]
                    // }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "XHvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "XHvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "XHvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "XHvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "XH200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "XH250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "XH253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "XH270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "XH300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "XH330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80"
                    ]
                },
                {
                    "id": "XH400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "XH400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "XH400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "XHYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "XH600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "XH240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "XH300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "XH400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "XH430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "XH600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "XHno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "XH300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "XH200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "XH1020075414",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-29T08:05:51",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "c87e933e-3aba-4fee-9e80-f0975c7e711d",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 5,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "4f5a1d9e-67c7-11ec-2996-002590357b68",
                    "title": "Иглмосс Эдишинз",
                    "trade_title": "Иглмосс Эдишинз",
                    "dlv_login": "collections@eaglemoss.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "XHWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "XH3338",
                        "title": "Фигурка DC Киборг",
                        "article": "CDCUK050",
                        "quantity": 1,
                        "weight": 174,
                        "image": "",
                        "image_preview": "",
                        "fragile": "",
                        "price_data": {
                            "price": 652.98,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 7,
                            "height": 20,
                            "depth": 16
                        },
                        "barcodes": [
                            {
                                "barcode": "977175482750250",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    // {
                    //     "id": "14164054318",
                    //     "temp_id": "*0w+US40",
                    //     "packaging_id": "XH250*180*100",
                    //     "sequence_num": "1",
                    //     "weight": 272,
                    //     "items": [
                    //         {
                    //             "id": "XH3338",
                    //             "barcode_used": "977175482750250",
                    //             "quantity": 1,
                    //             "serial_numbers": [],
                    //             "packagings": []
                    //         }
                    //     ]
                    // }
                ],
                "pallets": [
                    // {
                    //     "pallet_id": "WB0000014030",
                    //     "status": "PACKED",
                    //     "pallet_num": 1,
                    //     "weight": 3428,
                    //     "dimensions": {
                    //         "depth": "",
                    //         "width": "",
                    //         "height": ""
                    //     },
                    //     "places": [
                    //         "14164054318"
                    //     ]
                    // }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "XHvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "XHvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "XHvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "XHvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "XH200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "XH250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "XH253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "XH270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "XH300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "XH330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80"
                    ]
                },
                {
                    "id": "XH400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "XH400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "XH400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "XHYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "XH600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "XH240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "XH300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "XH400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "XH430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "XH600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "XHno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "XH300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "XH200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "XH1020112607",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-29T08:32:19",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "ad68eece-7030-4578-9961-ba6b557a0509",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 6,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "4f5a1d9e-67c7-11ec-2996-002590357b68",
                    "title": "Иглмосс Эдишинз",
                    "trade_title": "Иглмосс Эдишинз",
                    "dlv_login": "collections@eaglemoss.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "XHWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    // {
                    //     "id": "XH3290",
                    //     "title": "Фигурка DC Женщина-Кошка",
                    //     "article": "CDCUK007",
                    //     "quantity": 1,
                    //     "weight": 148,
                    //     "image": "",
                    //     "image_preview": "",
                    //     "fragile": "",
                    //     "price_data": {
                    //         "price": 328.96,
                    //         "vat": "VAT_20",
                    //         "currency": "RUB"
                    //     },
                    //     "require_weight": false,
                    //     "require_dimensions": false,
                    //     "dimensions": {
                    //         "width": 7,
                    //         "height": 20,
                    //         "depth": 16
                    //     },
                    //     "barcodes": [
                    //         {
                    //             "barcode": "977175482750207",
                    //             "type": "COMMON"
                    //         }
                    //     ],
                    //     "packagings": [],
                    //     "scan_serial_numbers": []
                    // }
                  {
                        "id": "XH2420",
                        "title": "Коллекционная фигурка DC \"Брейниак\"",
                        "article": "CDCUK055",
                        "quantity": 1,
                        "weight": 167,
                        "image": "",
                        "image_preview": "",
                        "fragile": "",
                        "price_data": {
                            "price": 328.96,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 20,
                            "height": 7,
                            "depth": 15
                        },
                        "barcodes": [
                            {
                                "barcode": "977175482750255",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": [],
                        "instruction_id": "1"
                    }
                ],
                "places": [
                    // {
                    //     "id": "14164107040",
                    //     "temp_id": "*0w/HyAz",
                    //     "packaging_id": "XH250*180*100",
                    //     "sequence_num": "1",
                    //     "weight": 239,
                    //     "items": [
                    //         {
                    //             "id": "XH3290",
                    //             "barcode_used": "977175482750207",
                    //             "quantity": 1,
                    //             "serial_numbers": [],
                    //             "packagings": []
                    //         }
                    //     ]
                    // }
                ],
                "pallets": [
                    // {
                    //     "pallet_id": "WB0000014030",
                    //     "status": "PACKED",
                    //     "pallet_num": 1,
                    //     "weight": 3428,
                    //     "dimensions": {
                    //         "depth": "",
                    //         "width": "",
                    //         "height": ""
                    //     },
                    //     "places": [
                    //         "14164107040"
                    //     ]
                    // }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "XHvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "XHvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "XHvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "XHvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "XH200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "XH250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "XH253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "XH270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "XH300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "XH330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80"
                    ]
                },
                {
                    "id": "XH400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "XH400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "XH400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "XHYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "XH600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "XH240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "XH300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "XH400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "XH430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "XH600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "XHno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "XH300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "XH200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        }
    ]
}
}

export const MOCK_ORDER_BATCH_BAD: any = {
  'success': true,
  "order_batch": {
    "id": "e95b0962-47c7-11ee-1785-ac1f6b90eae2",
    "status": "selected",
    "orders": [
        {
            "success": true,
            "order": {
                "id": "BR1024053046",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-30T23:59:38",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "487cf2f2-d346-49aa-958f-791c8341ab9e",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 1,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "36442fe8-2f0b-11eb-5f95-002590357b68",
                    "title": "Бочарова ИП \"Мпарфюм\"",
                    "trade_title": "Бочарова ИП \"Мпарфюм\"",
                    "dlv_login": "dir@mparfum.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "BRWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "BR4006",
                        "title": "Туалетная вода мужская D&G Pour Homme, 75 мл",
                        "article": "75-00030711",
                        "quantity": 1,
                        "weight": 275,
                        "image": "https://storage.yandexcloud.net/images-k2c/11e3d9ad-7077-471d-812f-e4dbc2514306.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/11e3d9ad-7077-471d-812f-e4dbc2514306-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 5414,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 8,
                            "height": 12,
                            "depth": 5
                        },
                        "barcodes": [
                            {
                                "barcode": "3423473020783",
                                "type": "COMMON"
                            },
                            {
                                "barcode": "737052074443",
                                "type": "COMMON"
                            },
                            {
                                "barcode": "2037918120085",
                                "type": "WILDBERRIES"
                            },
                            {
                                "barcode": "8011003072095",
                                "type": "WILDBERRIES"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": [
                            {
                                "code": "chestniy_znak",
                                "title": "Честный знак",
                                "template": "{УИТ}"
                            }
                        ]
                    }
                ],
                "places": [
                    {
                        "id": "14208265484",
                        "temp_id": "*07g7Qwl",
                        "packaging_id": "BR200*100*100",
                        "sequence_num": "1",
                        "weight": 317,
                        "items": [
                            {
                                "id": "BR4006",
                                "barcode_used": "3423473020783",
                                "quantity": 1,
                                "serial_numbers": [
                                    {
                                        "code": "chestniy_znak",
                                        "value": "0103423473020783212bj&Jo%DPUsDb91EE0692jdQf1Kc3cS3dLd2Vk46XH1Jsq3R0lWOTJBJsl6BODTM="
                                    }
                                ],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014363",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 3763,
                        "dimensions": {
                            "depth": 400,
                            "width": 250,
                            "height": 600
                        },
                        "places": [
                            "14208265484"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "BRvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "BRvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "BRvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "BRvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "BR200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "BR250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "BR253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "BR270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "BR300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "BR330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "BR400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "BR400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "BR400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "BRYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "BR600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "BR240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "BR300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "BR400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "BR430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "BR600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "BRno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "BR300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "BR200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "LG1024076096",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T00:26:48",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "d88c9fb0-7bba-48fb-82c9-aa065ad0e224",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 2,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "d666f5fa-54c2-11eb-189a-002590357b68",
                    "title": "ЛБ ГРУПП \"Арго Классик\"",
                    "trade_title": "ЛБ ГРУПП \"Арго Классик\"",
                    "dlv_login": "lbgrouprus.mp@yandex.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "LGWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "LG2899",
                        "title": "Толстовка женская J 42, Размер: 44 Цвет: красный",
                        "article": "J 42-красный-44",
                        "quantity": 1,
                        "weight": 293,
                        "image": "https://storage.yandexcloud.net/images-k2c/fa9f694f-9180-404a-a83f-c500c5cb69e0.JPG",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/fa9f694f-9180-404a-a83f-c500c5cb69e0-100.JPG",
                        "fragile": "",
                        "price_data": {
                            "price": 2001,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 32,
                            "height": 4,
                            "depth": 24
                        },
                        "barcodes": [
                            {
                                "barcode": "4657779822379",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14211311619",
                        "temp_id": "*08PaANF",
                        "packaging_id": "LG270*205*130",
                        "sequence_num": "1",
                        "weight": 399,
                        "items": [
                            {
                                "id": "LG2899",
                                "barcode_used": "4657779822379",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014357",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 567,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14211311619"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "LGvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "LGvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "LGvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "LGvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "LG200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "LG250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "LG253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "LG270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "LG300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "LG330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "LG400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "LG400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "LG400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "LGYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "LG600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "LG240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "LG300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "LG400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "LG430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "LG600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "LGno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "LG300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "LG200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "YT1024083091",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T00:37:08",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "f4189a92-3103-447c-b561-aec971795dfc",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 3,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "1fb8a03a-7f4e-11ec-5c9e-002590357b68",
                    "title": "АВИАКОМПОЗИТ \"стармаркет\"",
                    "trade_title": "АВИАКОМПОЗИТ \"стармаркет\"",
                    "dlv_login": "3290792@gmail.com"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "YTWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "YT4545",
                        "title": "Мяч баскетбольный Gold Cup резина 8 панелей",
                        "article": "GoldCupG706",
                        "quantity": 1,
                        "weight": 580,
                        "image": "",
                        "image_preview": "",
                        "fragile": "",
                        "price_data": {
                            "price": 811.96,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 29,
                            "height": 10,
                            "depth": 10
                        },
                        "barcodes": [
                            {
                                "barcode": "OZN911975353",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14211329377",
                        "temp_id": "*08PrWFD",
                        "packaging_id": "YT270*205*130",
                        "sequence_num": "1",
                        "weight": 710,
                        "items": [
                            {
                                "id": "YT4545",
                                "barcode_used": "OZN911975353",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014376",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 29872,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14211329377"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "YTvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "YTvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "YTvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "YTvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "YT200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "YT250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "YT253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "YT270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "YT300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "YT330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80"
                    ]
                },
                {
                    "id": "YT400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "YT400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "YT400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "YTYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "YT600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "YT240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "YT300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "YT400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "YT430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "YT600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "YTno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "YT300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "YT200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "JE1024118710",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T01:55:53",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "248ff107-1f1c-4f63-b8a6-5dcaeb35e709",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 4,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "e19d3050-ee15-11eb-3f97-002590357b68",
                    "title": "Гербст ИП \"Паблик стор\"",
                    "trade_title": "Гербст ИП \"Паблик стор\"",
                    "dlv_login": "a.koch@wh-p.co"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "JEWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "JE110867",
                        "title": "7642-10 Пуловер CATNOIR, Размер: 42 Цвет: Розовый",
                        "article": "7642-10/55",
                        "quantity": 2,
                        "weight": 547,
                        "image": "https://storage.yandexcloud.net/images-k2c/0f833610-3983-45b5-a9fe-848fe1388689.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/0f833610-3983-45b5-a9fe-848fe1388689-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 4144,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 43,
                            "height": 6,
                            "depth": 33
                        },
                        "barcodes": [
                            {
                                "barcode": "4251956447388",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14219514238",
                        "temp_id": "*0+MkX6H",
                        "packaging_id": "JE400*300*100",
                        "sequence_num": "1",
                        "weight": 761,
                        "items": [
                            {
                                "id": "JE110867",
                                "barcode_used": "4251956447388",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014361",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 9534,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14219514238"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "JEvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "JEvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "JEvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "JEvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "JE200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "JE250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "JE253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "JE270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "JE300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "JE330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "JE400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "JE400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "JE400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "JEYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "JE600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "JE240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "JE300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "JE400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "JE430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "JE600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "JEno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "JE300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "JE200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "HR1024180556",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T05:16:07",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "2fc766ed-b4f2-40cf-96b2-6f4e91542d59",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 5,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "946d5b5a-8c92-11eb-0895-002590357b68",
                    "title": "ХАЙПЕР",
                    "trade_title": "ХАЙПЕР",
                    "dlv_login": "mp@hiper-power.com"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "HRWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "HR2377",
                        "title": "Беспроводная мышь OMW-5500, 1000/1600 DPI, 3 кнопки+колесо, цвет черный, USB, софт-тач",
                        "article": "OMW-5500",
                        "quantity": 1,
                        "weight": 85,
                        "image": "https://storage.yandexcloud.net/images-k2c/7c9e8b8d-aa34-4e5c-9752-39070a9d7cc1.jpeg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/7c9e8b8d-aa34-4e5c-9752-39070a9d7cc1-100.jpeg",
                        "fragile": "",
                        "price_data": {
                            "price": 427,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 17,
                            "height": 7,
                            "depth": 12
                        },
                        "barcodes": [
                            {
                                "barcode": "7930114973637",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14220851695",
                        "temp_id": "*0+g+e8C",
                        "packaging_id": "HR200*100*100",
                        "sequence_num": "1",
                        "weight": 130,
                        "items": [
                            {
                                "id": "HR2377",
                                "barcode_used": "7930114973637",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014386",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 67675,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14220851695"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "HRvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "HRvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "HRvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "HRvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "HR200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "HR250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "HR253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "HR270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "HR300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "HR330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "HR400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "HR400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "HR400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "HRYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "HR600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "HR240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "HR300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "HR400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "HR430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "HR600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "HRno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "HR300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "HR200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "LG1024215224",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T06:16:46",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "3b45ec00-400b-4fe0-b349-8fa892382231",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 6,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "d666f5fa-54c2-11eb-189a-002590357b68",
                    "title": "ЛБ ГРУПП \"Арго Классик\"",
                    "trade_title": "ЛБ ГРУПП \"Арго Классик\"",
                    "dlv_login": "lbgrouprus.mp@yandex.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "LGWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "LG1011",
                        "title": "Трусы Tr 03, Размер: 46 Цвет: василек",
                        "article": "Tr 03-василек-46",
                        "quantity": 1,
                        "weight": 21,
                        "image": "https://storage.yandexcloud.net/images-k2c/563bab17-877c-44e3-984b-a00e2c165ce6.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/563bab17-877c-44e3-984b-a00e2c165ce6-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 560,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 12,
                            "height": 1,
                            "depth": 12
                        },
                        "barcodes": [
                            {
                                "barcode": "4627193452391",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14220919079",
                        "temp_id": "*0+iAScE",
                        "packaging_id": "LG200*100*100",
                        "sequence_num": "1",
                        "weight": 68,
                        "items": [
                            {
                                "id": "LG1011",
                                "barcode_used": "4627193452391",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014364",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 4928,
                        "dimensions": {
                            "depth": 400,
                            "width": 400,
                            "height": 600
                        },
                        "places": [
                            "14220919079"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "LGvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "LGvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "LGvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "LGvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "LG200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "LG250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "LG253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "LG270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "LG300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "LG330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "LG400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "LG400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "LG400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "LGYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "LG600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "LG240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "LG300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "LG400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "LG430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "LG600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "LGno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "LG300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "LG200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "L31024218832",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T06:22:37",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "f3462d65-b8d8-48e1-af71-b284aa31c4d4",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 7,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "435c7e52-f61e-11ec-d589-002590357b68",
                    "title": "ПРО ОБУВЬ \"Тамарис Офишл\"",
                    "trade_title": "ПРО ОБУВЬ \"Тамарис Офишл\"",
                    "dlv_login": "online_sales@wortmann-wostok.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "L3WB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "L31-1-25056-28-003#39",
                        "title": "Ботинки женские, Размер: 39",
                        "article": "1-1-25056-28-003",
                        "quantity": 1,
                        "weight": 1034,
                        "image": "https://storage.yandexcloud.net/images-k2c/3de6760f-ba76-4c5f-a4e6-ee08a498aec9.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/3de6760f-ba76-4c5f-a4e6-ee08a498aec9-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 5765,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 31,
                            "height": 12,
                            "depth": 26
                        },
                        "barcodes": [
                            {
                                "barcode": "4064194943433",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": [
                            {
                                "code": "chestniy_znak",
                                "title": "Честный знак",
                                "template": "{УИТ}"
                            }
                        ]
                    }
                ],
                "places": [
                    {
                        "id": "14220924619",
                        "temp_id": "*0+iFssF",
                        "packaging_id": "L3430*500",
                        "sequence_num": "1",
                        "weight": 1063,
                        "items": [
                            {
                                "id": "L31-1-25056-28-003#39",
                                "barcode_used": "4064194943433",
                                "quantity": 1,
                                "serial_numbers": [
                                    {
                                        "code": "chestniy_znak",
                                        "value": "01040641949434332155DmKilmyX1+;91009892nHAEIhv4JUGBJTbs3ZDixCKRtgbhQJM5lu7nKZWX0Ya7pcs0ezhgbXtCEHp7R4ihRzjeHZF4CaiX91mlYjYtIQ=="
                                    }
                                ],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014382",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 18019,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14220924619"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "L3vp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "L3vp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "L3vp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "L3vp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "L3200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "L3250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "L3253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "L3270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "L3300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "L3330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80"
                    ]
                },
                {
                    "id": "L3400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "L3400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "L3400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "L3YY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "L3600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "L3240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "L3300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "L3400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "L3430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "L3600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "L3no_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "L3300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "L3200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "HR1024225721",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T06:31:12",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "c5135967-7c16-44fc-bd22-4be7e088dab2",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 8,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "946d5b5a-8c92-11eb-0895-002590357b68",
                    "title": "ХАЙПЕР",
                    "trade_title": "ХАЙПЕР",
                    "dlv_login": "mp@hiper-power.com"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "HRWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "HR2613",
                        "title": "Прожектор уличный светодиодный HIPER HF-009 / 50W / 6500K / IP65",
                        "article": "HF-009",
                        "quantity": 1,
                        "weight": 352,
                        "image": "https://storage.yandexcloud.net/images-k2c/3167439f-f5e0-48f7-abce-9de1cfca89da.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/3167439f-f5e0-48f7-abce-9de1cfca89da-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 439,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 14,
                            "height": 4,
                            "depth": 15
                        },
                        "barcodes": [
                            {
                                "barcode": "7930092038229",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14220938208",
                        "temp_id": "*0+iS+AG",
                        "packaging_id": "HR250*180*100",
                        "sequence_num": "1",
                        "weight": 363,
                        "items": [
                            {
                                "id": "HR2613",
                                "barcode_used": "7930092038229",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014386",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 67675,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14220938208"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "HRvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "HRvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "HRvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "HRvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "HR200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "HR250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "HR253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "HR270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "HR300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "HR330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "HR400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "HR400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "HR400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "HRYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "HR600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "HR240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "HR300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "HR400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "HR430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "HR600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "HRno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "HR300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "HR200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "LG1024232481",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T06:40:50",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "fff0c71e-528a-4434-a401-7589f1e0e75d",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 9,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "d666f5fa-54c2-11eb-189a-002590357b68",
                    "title": "ЛБ ГРУПП \"Арго Классик\"",
                    "trade_title": "ЛБ ГРУПП \"Арго Классик\"",
                    "dlv_login": "lbgrouprus.mp@yandex.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "LGWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "LG3012",
                        "title": "Спортивная куртка женская J 404, Размер: 42 Цвет: лимон",
                        "article": "J 404-лимон-42",
                        "quantity": 1,
                        "weight": 252,
                        "image": "",
                        "image_preview": "",
                        "fragile": "",
                        "price_data": {
                            "price": 2298,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 27,
                            "height": 5,
                            "depth": 22
                        },
                        "barcodes": [
                            {
                                "barcode": "4657765620064",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14220953480",
                        "temp_id": "*0+ih4gA",
                        "packaging_id": "LG270*205*130",
                        "sequence_num": "1",
                        "weight": 358,
                        "items": [
                            {
                                "id": "LG3012",
                                "barcode_used": "4657765620064",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014364",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 4928,
                        "dimensions": {
                            "depth": 400,
                            "width": 400,
                            "height": 600
                        },
                        "places": [
                            "14220953480"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "LGvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "LGvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "LGvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "LGvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "LG200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "LG250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "LG253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "LG270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "LG300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "LG330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "LG400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "LG400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "LG400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "LGYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "LG600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "LG240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "LG300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "LG400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "LG430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "LG600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "LGno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "LG300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "LG200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "HR1024251728",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T07:01:17",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "f73ede9f-ba3b-40bd-ad38-8a2fb6dae54a",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 10,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "946d5b5a-8c92-11eb-0895-002590357b68",
                    "title": "ХАЙПЕР",
                    "trade_title": "ХАЙПЕР",
                    "dlv_login": "mp@hiper-power.com"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "HRWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "HR1812",
                        "title": "Подвесной светильник-бра настенный HIPER H144-1 / E27 / 60Вт",
                        "article": "H144-1",
                        "quantity": 1,
                        "weight": 1065,
                        "image": "https://storage.yandexcloud.net/images-k2c/d98a8fdd-3411-460c-93bd-8cd4d443b1e7.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/d98a8fdd-3411-460c-93bd-8cd4d443b1e7-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 1617,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 26,
                            "height": 27,
                            "depth": 27
                        },
                        "barcodes": [
                            {
                                "barcode": "4660064392709",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14221005383",
                        "temp_id": "*0+jUkdh",
                        "packaging_id": "HRvp_30*40",
                        "sequence_num": "1",
                        "weight": 1101,
                        "items": [
                            {
                                "id": "HR1812",
                                "barcode_used": "4660064392709",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014386",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 67675,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14221005383"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "HRvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "HRvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "HRvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "HRvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "HR200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "HR250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "HR253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "HR270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "HR300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "HR330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "HR400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "HR400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "HR400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "HRYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "HR600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "HR240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "HR300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "HR400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "HR430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "HR600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "HRno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "HR300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "HR200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "JE1024251785",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T07:01:17",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "35c03273-65d9-4087-8d7e-fffe54a587dc",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 11,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "e19d3050-ee15-11eb-3f97-002590357b68",
                    "title": "Гербст ИП \"Паблик стор\"",
                    "trade_title": "Гербст ИП \"Паблик стор\"",
                    "dlv_login": "a.koch@wh-p.co"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "JEWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "JE115304",
                        "title": "SuperBreak One Russet Red Рюкзак Jansport, Размер: ONE SIZE Цвет: бордовый",
                        "article": "EK0A5BAGN621",
                        "quantity": 1,
                        "weight": 344,
                        "image": "https://storage.yandexcloud.net/images-k2c/6db79506-15f5-427c-b97a-9a13e2ecb506.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/6db79506-15f5-427c-b97a-9a13e2ecb506-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 3180,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 43,
                            "height": 3,
                            "depth": 37
                        },
                        "barcodes": [
                            {
                                "barcode": "196011458043",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14221005440",
                        "temp_id": "*0+jUoBl",
                        "packaging_id": "JE400*300*100",
                        "sequence_num": "1",
                        "weight": 552,
                        "items": [
                            {
                                "id": "JE115304",
                                "barcode_used": "196011458043",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014361",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 9534,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14221005440"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "JEvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "JEvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "JEvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "JEvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "JE200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "JE250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "JE253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "JE270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "JE300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "JE330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "JE400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "JE400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "JE400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "JEYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "JE600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "JE240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "JE300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "JE400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "JE430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "JE600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "JEno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "JE300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "JE200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "HR1024251729",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T07:01:23",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "dd1dd835-e368-487d-99b3-da79a1dce162",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 12,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "946d5b5a-8c92-11eb-0895-002590357b68",
                    "title": "ХАЙПЕР",
                    "trade_title": "ХАЙПЕР",
                    "dlv_login": "mp@hiper-power.com"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "HRWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "HR1812",
                        "title": "Подвесной светильник-бра настенный HIPER H144-1 / E27 / 60Вт",
                        "article": "H144-1",
                        "quantity": 1,
                        "weight": 1065,
                        "image": "https://storage.yandexcloud.net/images-k2c/d98a8fdd-3411-460c-93bd-8cd4d443b1e7.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/d98a8fdd-3411-460c-93bd-8cd4d443b1e7-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 1617,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 26,
                            "height": 27,
                            "depth": 27
                        },
                        "barcodes": [
                            {
                                "barcode": "4660064392709",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14221005384",
                        "temp_id": "*0+jUkhp",
                        "packaging_id": "HRvp_30*40",
                        "sequence_num": "1",
                        "weight": 1070,
                        "items": [
                            {
                                "id": "HR1812",
                                "barcode_used": "4660064392709",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014386",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 67675,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14221005384"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "HRvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "HRvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "HRvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "HRvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "HR200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "HR250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "HR253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "HR270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "HR300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "HR330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "HR400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "HR400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "HR400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "HRYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "HR600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "HR240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "HR300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "HR400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "HR430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "HR600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "HRno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "HR300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "HR200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "JE1024260860",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T07:09:33",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "f8823bfc-bf75-46e2-b521-0856e1726615",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 13,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "e19d3050-ee15-11eb-3f97-002590357b68",
                    "title": "Гербст ИП \"Паблик стор\"",
                    "trade_title": "Гербст ИП \"Паблик стор\"",
                    "dlv_login": "a.koch@wh-p.co"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "JEWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "JE115094",
                        "title": "2324256 лонгслив LERROS, Размер: M Цвет: темно-синий",
                        "article": "2324256/485",
                        "quantity": 1,
                        "weight": 239,
                        "image": "https://storage.yandexcloud.net/images-k2c/adbaaff2-9e0f-4892-bd94-f5a7994bb9c2.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/adbaaff2-9e0f-4892-bd94-f5a7994bb9c2-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 4674,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 35,
                            "height": 1,
                            "depth": 23
                        },
                        "barcodes": [
                            {
                                "barcode": "4066718114404",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14221027745",
                        "temp_id": "*0+jqaFp",
                        "packaging_id": "JE270*205*130",
                        "sequence_num": "1",
                        "weight": 351,
                        "items": [
                            {
                                "id": "JE115094",
                                "barcode_used": "4066718114404",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014361",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 9534,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14221027745"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "JEvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "JEvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "JEvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "JEvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "JE200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "JE250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "JE253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "JE270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "JE300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "JE330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "JE400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "JE400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "JE400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "JEYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "JE600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "JE240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "JE300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "JE400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "JE430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "JE600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "JEno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "JE300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "JE200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "JE1024260861",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T07:09:38",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "73f2234b-b64d-43ec-9918-a6b07cd072f3",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 14,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "e19d3050-ee15-11eb-3f97-002590357b68",
                    "title": "Гербст ИП \"Паблик стор\"",
                    "trade_title": "Гербст ИП \"Паблик стор\"",
                    "dlv_login": "a.koch@wh-p.co"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "JEWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "JE5486",
                        "title": "2273000 Футболка LERROS, Размер: L Цвет: Бордовый",
                        "article": "2273000",
                        "quantity": 1,
                        "weight": 185,
                        "image": "https://storage.yandexcloud.net/images-k2c/ddc1703e-fbdd-4e9c-8c96-d7c64482d888.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/ddc1703e-fbdd-4e9c-8c96-d7c64482d888-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 1261,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 36,
                            "height": 1,
                            "depth": 23
                        },
                        "barcodes": [
                            {
                                "barcode": "4066718034900",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14221027746",
                        "temp_id": "*0+jqaJj",
                        "packaging_id": "JE270*205*130",
                        "sequence_num": "1",
                        "weight": 294,
                        "items": [
                            {
                                "id": "JE5486",
                                "barcode_used": "4066718034900",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014361",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 9534,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14221027746"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "JEvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "JEvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "JEvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "JEvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "JE200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "JE250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "JE253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "JE270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "JE300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "JE330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "JE400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "JE400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "JE400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "JEYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "JE600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "JE240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "JE300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "JE400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "JE430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "JE600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "JEno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "JE300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "JE200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "LG1024312940",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-31T07:54:47",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "42930103-6850-4c37-a627-2af3f0e51a3b",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 15,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "d666f5fa-54c2-11eb-189a-002590357b68",
                    "title": "ЛБ ГРУПП \"Арго Классик\"",
                    "trade_title": "ЛБ ГРУПП \"Арго Классик\"",
                    "dlv_login": "lbgrouprus.mp@yandex.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "LGWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "LG1896",
                        "title": "Худи женский J 240+, Цвет: черный Размер: 54",
                        "article": "J 240+-черный-54",
                        "quantity": 1,
                        "weight": 570,
                        "image": "https://storage.yandexcloud.net/images-k2c/0e459313-be05-42f9-930f-d824fad986ee.JPG",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/0e459313-be05-42f9-930f-d824fad986ee-100.JPG",
                        "fragile": "",
                        "price_data": {
                            "price": 1615,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 34,
                            "height": 6,
                            "depth": 31
                        },
                        "barcodes": [
                            {
                                "barcode": "4627200187469",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14221114733",
                        "temp_id": "*0+k/W1o",
                        "packaging_id": "LG330x250x80",
                        "sequence_num": "1",
                        "weight": 770,
                        "items": [
                            {
                                "id": "LG1896",
                                "barcode_used": "4627200187469",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014364",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 4928,
                        "dimensions": {
                            "depth": 400,
                            "width": 400,
                            "height": 600
                        },
                        "places": [
                            "14221114733"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "LGvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "LGvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "LGvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "LGvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "LG200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "LG250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "LG253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "LG270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "LG300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "LG330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "LG400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "LG400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "LG400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "LGYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "LG600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "LG240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "LG300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "LG400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "LG430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "LG600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "LGno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "LG300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "LG200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "BR1023783511",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-30T21:19:25",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "0ee5d9cc-49f9-41b9-abd2-649ab65dd391",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 16,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "36442fe8-2f0b-11eb-5f95-002590357b68",
                    "title": "Бочарова ИП \"Мпарфюм\"",
                    "trade_title": "Бочарова ИП \"Мпарфюм\"",
                    "dlv_login": "dir@mparfum.ru"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "BRWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "BR7058",
                        "title": "RENI Home Аромадиффузор парфюмерная вода №07 Pomelo&Cedar 100мл",
                        "article": "100-00050938",
                        "quantity": 1,
                        "weight": 334,
                        "image": "https://storage.yandexcloud.net/images-k2c/ce295a5d-e8db-4379-8ac6-2ba5971a299a.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/ce295a5d-e8db-4379-8ac6-2ba5971a299a-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 766,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 24,
                            "height": 24,
                            "depth": 7
                        },
                        "barcodes": [
                            {
                                "barcode": "4630199520100",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14205156359",
                        "temp_id": "*06xfAc4",
                        "packaging_id": "BR250*180*100",
                        "sequence_num": "1",
                        "weight": 425,
                        "items": [
                            {
                                "id": "BR7058",
                                "barcode_used": "4630199520100",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014363",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 3763,
                        "dimensions": {
                            "depth": 400,
                            "width": 250,
                            "height": 600
                        },
                        "places": [
                            "14205156359"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "BRvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "BRvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "BRvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "BRvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "BR200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "BR250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "BR253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "BR270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "BR300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "BR330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "BR400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "BR400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "BR400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "BRYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "BR600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "BR240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "BR300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "BR400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "BR430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "BR600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "BRno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "BR300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "BR200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "YT1023794745",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-30T21:24:13",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "7c778330-9e32-4f5b-88af-a563d267f9ec",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 17,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "1fb8a03a-7f4e-11ec-5c9e-002590357b68",
                    "title": "АВИАКОМПОЗИТ \"стармаркет\"",
                    "trade_title": "АВИАКОМПОЗИТ \"стармаркет\"",
                    "dlv_login": "3290792@gmail.com"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "YTWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "YT4684",
                        "title": "Набор для настольного тенниса Atemi DUET (2ракетки+чехол+3 мяча*) Original",
                        "article": "AtemiDUET",
                        "quantity": 1,
                        "weight": 419,
                        "image": "",
                        "image_preview": "",
                        "fragile": "",
                        "price_data": {
                            "price": 1323,
                            "vat": "VAT_20",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 27,
                            "height": 4,
                            "depth": 19
                        },
                        "barcodes": [
                            {
                                "barcode": "4740152200212",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14205174173",
                        "temp_id": "*06xwZ0x",
                        "packaging_id": "YT270*205*130",
                        "sequence_num": "1",
                        "weight": 522,
                        "items": [
                            {
                                "id": "YT4684",
                                "barcode_used": "4740152200212",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014376",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 29872,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14205174173"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "YTvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "YTvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "YTvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "YTvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "YT200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "YT250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "YT253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "YT270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "YT300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "YT330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80"
                    ]
                },
                {
                    "id": "YT400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "YT400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "YT400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "YTYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "YT600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "YT240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "YT300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "YT400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "YT430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "YT600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "YTno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "YT300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "YT200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        },
        {
            "success": true,
            "order": {
                "id": "JE1023809909",
                'operationType': 'PACKAGING_B2B',
                "maxBoxesWithoutPallet": 100000,
                "relabelingType": "BOXES_AND_PALLETS",
                "with_pallet_composition": false,
                "cancelReason": "",
                "date": "2023-08-30T21:31:08",
                'packing_state': 'awaiting_packing',
                "DisableStickerAndPackagingFBO": false,
                "global_shipping_guidelines": "",
                "ExtraSeat": false,
                "dlvId": "744ecf99-9869-46ed-95de-ebe43d2c6784",
                "order_shipping_comments": "",
                "operativeComment": "",
                "sorting_number": 18,
                "require_place_weight": true,
                "preferred_barcode_type": "WILDBERRIES",
                "shippingOptions": {
                    "packingType": "MIX_PACKINGS"
                },
                "shipping_form": [],
                "merchant": {
                    "id": "e19d3050-ee15-11eb-3f97-002590357b68",
                    "title": "Гербст ИП \"Паблик стор\"",
                    "trade_title": "Гербст ИП \"Паблик стор\"",
                    "dlv_login": "a.koch@wh-p.co"
                },
                "receiver": {
                    "contractor_id": "",
                    "phone": "70000000000",
                    "patronymic": "Wildberries",
                    "name": "На",
                    "surname": "Покупатель"
                },
                "delivery": {
                    "logo": "",
                    "title": "WB",
                    "contract_title": "",
                    "contract": "JEWB",
                    "address": {
                        "country": "RU",
                        "region": "",
                        "region_fias": "",
                        "area": "Московская",
                        "area_fias": "",
                        "city": "Подольск",
                        "city_fias": "",
                        "settlement": "Коледино",
                        "settlement_fias": "",
                        "street": "ул Троицкая",
                        "house": "20",
                        "block": "",
                        "building": "",
                        "floor": "",
                        "flat": "",
                        "zip": "",
                        "fullAddress": "142117, Россия, Московская обл, г Подольск, деревня Коледино, ул Троицкая, стр 20"
                    },
                    "sorting_center_acceptance_date": "",
                    "maximum_place_weight": "",
                    "maximum_places_count": "",
                    "maximum_pallet_weight": 1000000,
                    "maximum_place_dimensions": {},
                    "maximum_pallet_dimensions": {
                        "width": 100000,
                        "depth": 10000000,
                        "height": 100000
                    }
                },
                "relabelingOptions": {
                    "scanNewBarcodeByClient": "scanWithUnique"
                },
                "items": [
                    {
                        "id": "JE115304",
                        "title": "SuperBreak One Russet Red Рюкзак Jansport, Размер: ONE SIZE Цвет: бордовый",
                        "article": "EK0A5BAGN621",
                        "quantity": 1,
                        "weight": 344,
                        "image": "https://storage.yandexcloud.net/images-k2c/6db79506-15f5-427c-b97a-9a13e2ecb506.jpg",
                        "image_preview": "https://storage.yandexcloud.net/images-k2c/6db79506-15f5-427c-b97a-9a13e2ecb506-100.jpg",
                        "fragile": "",
                        "price_data": {
                            "price": 3180,
                            "vat": "NO_VAT",
                            "currency": "RUB"
                        },
                        "require_weight": false,
                        "require_dimensions": false,
                        "dimensions": {
                            "width": 43,
                            "height": 3,
                            "depth": 37
                        },
                        "barcodes": [
                            {
                                "barcode": "196011458043",
                                "type": "COMMON"
                            }
                        ],
                        "packagings": [],
                        "scan_serial_numbers": []
                    }
                ],
                "places": [
                    {
                        "id": "14205197632",
                        "temp_id": "*06yHUA2",
                        "packaging_id": "JE400*300*100",
                        "sequence_num": "1",
                        "weight": 554,
                        "items": [
                            {
                                "id": "JE115304",
                                "barcode_used": "3",
                                "quantity": 1,
                                "serial_numbers": [],
                                "packagings": []
                            }
                        ]
                    }
                ],
                "pallets": [
                    {
                        "pallet_id": "WB0000014361",
                        "status": "PACKED",
                        "pallet_num": 1,
                        "weight": 9534,
                        "dimensions": {
                            "depth": "",
                            "width": "",
                            "height": ""
                        },
                        "places": [
                            "14205197632"
                        ]
                    }
                ],
                "ad_attachments": []
            },
            "packagings": [
                {
                    "id": "JEvp_150*150",
                    "title": "ВП пакет 15х15см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_150*150"
                    ]
                },
                {
                    "id": "JEvp_200*200",
                    "title": "ВП пакет 20х20см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_200*200"
                    ]
                },
                {
                    "id": "JEvp_300*300",
                    "title": "ВП пакет 30х30см",
                    "require_dimensions": true,
                    "barcodes": [
                        "vp_300*300"
                    ]
                },
                {
                    "id": "JEvp_30*40",
                    "title": "ВП пленка",
                    "require_dimensions": false,
                    "barcodes": [
                        "vp_30*40"
                    ]
                },
                {
                    "id": "JE200*100*100",
                    "title": "Короб 20-10-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "200*100*100"
                    ]
                },
                {
                    "id": "JE250*180*100",
                    "title": "Короб 25-18-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "250*180*100"
                    ]
                },
                {
                    "id": "JE253*205*238",
                    "title": "Короб 25,3-20,5-23,8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "253*205*238"
                    ]
                },
                {
                    "id": "JE270*205*130",
                    "title": "Короб 27-20-13 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "270*205*130"
                    ]
                },
                {
                    "id": "JE300*150*150",
                    "title": "Короб 30-15-15 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "300*150*150"
                    ]
                },
                {
                    "id": "JE330x250x80",
                    "title": "Короб 33-25-8 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "330*250*80",
                        "330x250x80"
                    ]
                },
                {
                    "id": "JE400*300*100",
                    "title": "Короб 40-30-10 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*100"
                    ]
                },
                {
                    "id": "JE400*300*190",
                    "title": "Короб 40-30-19 см ",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*190"
                    ]
                },
                {
                    "id": "JE400*300*250",
                    "title": "Короб 40-30-25 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "400*300*250"
                    ]
                },
                {
                    "id": "JEYY600*400*250",
                    "title": "Короб 60-40-25 см - Т-24",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*250"
                    ]
                },
                {
                    "id": "JE600*400*400",
                    "title": "Короб 60-40-40 см",
                    "require_dimensions": false,
                    "barcodes": [
                        "600*400*400"
                    ]
                },
                {
                    "id": "JE240*320",
                    "title": "Курьер-пакет 24-32 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "240*320"
                    ]
                },
                {
                    "id": "JE300*400",
                    "title": "Курьер-пакет 30-40 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*400"
                    ]
                },
                {
                    "id": "JE400*600",
                    "title": "Курьер-пакет 40-60 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "400*600"
                    ]
                },
                {
                    "id": "JE430*500",
                    "title": "Курьер-пакет 43-50 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "430*500"
                    ]
                },
                {
                    "id": "JE600*800",
                    "title": "Курьер-пакет 60-80 см",
                    "require_dimensions": true,
                    "barcodes": [
                        "600*800"
                    ]
                },
                {
                    "id": "JEno_packaging",
                    "title": "Нет Упаковки",
                    "require_dimensions": false,
                    "barcodes": [
                        "no_packaging"
                    ]
                },
                {
                    "id": "JE300*500",
                    "title": "Пакет ПВД 300мм*500мм 75 мкм",
                    "require_dimensions": true,
                    "barcodes": [
                        "300*500"
                    ]
                },
                {
                    "id": "JE200*80",
                    "title": "Пленка ПВД (п/рукав) 200 мм*80мм",
                    "require_dimensions": true,
                    "barcodes": [
                        "200*80"
                    ]
                }
            ]
        }
    ]
}
}

import { useMutation } from 'react-query'

import api from 'src/api'

/*
 * Удаление документа
 * http://wms-test.kak2c.ru/warehouse-api/billing/service-doc/{doc_id}
 */

interface DeleteBilingParams {
  docId: string
}

const useDeleteBilling = () => {
  return useMutation(async ({ docId }: DeleteBilingParams) => {
    const result = await api.delete(`/billing/service-doc/${docId}`)

    if (!result?.data?.success) {
      throw result
    }
    return result.data
  })
}

export default useDeleteBilling

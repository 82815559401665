import api from 'src/api'
import { convertObjectToURLParams } from 'src/helpers'
import {
  ApiReqResult,
  IPaginationFilter,
  IPicking,
  ISku,
  IWarehouseCell,
  PaginationResult,
  PickingStatusEnum,
} from 'src/interfaces'
import { useQuery } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'


interface IResult extends ApiReqResult, PaginationResult {
  skus: ISku[]
  pickings: IPicking[]
  cells: IWarehouseCell[]
}
interface IParam extends IPaginationFilter {
  statuses?: PickingStatusEnum[]
  parent_id?: string
}

export const useGetPickings = () => ({
  fetch: async (params: IParam) => {
    const urlParams = convertObjectToURLParams(params)
    const result = await api.get(`/picking${urlParams}`)

    if (!result.data?.success) {
      throw result.data
    }
    return result.data?.pickings || []
  }
})


export const useGetQueryPickings = (
  params?: IParam,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IResult>(
    [`getPickings`],
    async () => {
      const urlParams = convertObjectToURLParams(params)
      const result = await api.get(`/picking${urlParams}`)
      return result.data
    },
    {
      keepPreviousData: true,
      ...options
    },
  )
}

export const MOCK_GET_CROSSDOCKING_SUPPLY: any = {
  "errors": [
    {
      "code": "ACCESS_DENIED",
      "message": "string"
    }
  ],
  "files_for_print": [],
  "places": [
    {
      "barcode": "string",
      "condition": "GOOD",
      "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
      "title": "string",
      "zone_id": "4495020a-236f-46e0-9453-e3f9cc6476f4"
    }
  ],
  "skus": [
    {
      "article": "string",
      "barcodes": [
        {
          "active": true,
          "barcode": "string",
          "type": "COMMON",
          "used_in_last_supply": true
        }
      ],
      "batch_accounting": true,
      "batch_expdate_mandatory": true,
      "batches": [
        {
          "exp_date": "2019-08-24",
          "id": "54ef78bb9a-ffc8-481e-a980-397a2cbcae",
          "num": "string"
        }
      ],
      "category": {
        "code": "string",
        "title": "string"
      },
      "composition": "string",
      "country_of_origin": "RU",
      "dimensions_fact": {
        "depth": 0,
        "height": 0,
        "width": 0
      },
      "dimensions_plan": {
        "depth": 0,
        "height": 0,
        "width": 0
      },
      "expiration_months": 0,
      "external_id": "string",
      "fragile": true,
      "id": "string",
      "image": "string",
      "image_preview": "string",
      "images": [
        {
          "position": 0,
          "preview_url": "string",
          "url": "string"
        }
      ],
      "manufacturer": "string",
      "merchant": {
        "cam_id": "string",
        "contract_date": "2019-08-24",
        "contract_num": "string",
        "dlv_login": "string",
        "enabled": true,
        "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
        "inn": "string",
        "kpp": "string",
        "login": "string",
        "ogrn": "string",
        "service_group_set_id": "6d9f165c-b8f2-4d34-aee3-e7fd52e7fc07",
        "sorting_number": "string",
        "title": "string",
        "trade_title": "string"
      },
      "parts": [
        {
          "optional": true,
          "quantity": 0,
          "sku_id": "string"
        }
      ],
      "require_dimensions": true,
      "require_weight": true,
      "scan_serial_numbers": [
        {
          "code": "chestniy_znak",
          "required": true,
          "template": "string",
          "title": "string"
        }
      ],
      "title": "string",
      "title_en": "string",
      "tnved_code": "string",
      "type": "SKU",
      "weight_fact": 0,
      "weight_plan": 0
    }
  ],
  "success": true,
  "supply": {
    "activated": true,
    "comment": "string",
    "create_ts": "2019-08-24T14:15:22Z",
    "date": "2019-08-24T14:15:22Z",
    "executor": {
      "enabled": true,
      "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
      "login": "string",
      "name": "string",
      "password": "string",
      "patronymic": "string",
      "position": "GENERAL_DIRECTOR",
      "roles": [
        "497f6eca-6276-4993-bfeb-53cbbbba6f08"
      ],
      "surname": "string"
    },
    "external_id": "string",
    "id": "string",
    "join_code": "string",
    "merchant": {
      "cam_id": "string",
      "contract_date": "2019-08-24",
      "contract_num": "string",
      "dlv_login": "string",
      "enabled": true,
      "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
      "inn": "string",
      "kpp": "string",
      "login": "string",
      "ogrn": "string",
      "service_group_set_id": "6d9f165c-b8f2-4d34-aee3-e7fd52e7fc07",
      "sorting_number": "string",
      "title": "string",
      "trade_title": "string"
    },
    "sorted_items": [
      {
        "barcode_used": "string",
        "cell_barcode": "string",
        "condition": "GOOD",
        "defect_comment": "string",
        "defect_type_id": "42209305-768c-4edf-ab82-c71fe42238a5",
        "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
        "order_id": "string",
        "quantity": 0,
        "serial_numbers": [
          {
            "code": "chestniy_znak",
            "title": "string",
            "value": "string"
          }
        ],
        "sku_batch_id": "54ef78bb9a-ffc8-481e-a980-397a2cbcae",
        "sku_id": "string"
      }
    ],
    "sorting_status": "SORTING",
    "status": "ENTERED",
    "update_ts": "2019-08-24T14:15:22Z",
    "uuid": "095be615-a8ad-4c33-8e9c-c7612fbf6c9f",
    "warehouse_comment": "string",
    "workspace": {
      "barcode": "string",
      "condition": "GOOD",
      "id": "497f6eca-6276-4993-bfeb-53cbbbba6f08",
      "printer_settings": [
        {
          "name": "string",
          "type": "A4"
        }
      ],
      "title": "string",
      "zone_id": "4495020a-236f-46e0-9453-e3f9cc6476f4"
    }
  }
}

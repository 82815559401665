import { checkBase64MIMEType } from 'src/helpers'
import { ISticker, StickerWithCopies } from 'src/interfaces'
import { useNotifications } from 'src/notification'
import { usePrinter } from 'src/printer'
import { useCallback } from 'react'
import { usePreviewBase64Pdf } from '../usePreviewBase64Pdf'

export enum PrintErrorCodes {
  contentError = 'contentError',
  contentNotPdf = 'contentNotPdf',
  printerNameError = 'printerNameError',
  printError = 'printError',
}

export interface IPrintOptions {
  copies?: number
  onStickerSuccess?: () => void
  onStickerError?: (sticker: Partial<ISticker>, code?: PrintErrorCodes) => void
  onFinally?: () => void
  successNotifText?: string
  errorNotifText?: string
  needShowPrintStickerInfo?: boolean
  stickerExists?: boolean,
}

interface IUsePrintProps {
}

export const usePrint = (data?: IUsePrintProps) => {
  const notification = useNotifications()
  const printer = usePrinter()
  const { previewBase64Pdf } = usePreviewBase64Pdf()

  const handleStickerError = useCallback((
    message: string,
    errorCode: PrintErrorCodes,
    sticker: Partial<ISticker>,
    onStickerError?: (sticker: Partial<ISticker>, code?: PrintErrorCodes) => void,
  ) => {
    onStickerError?.(sticker, errorCode)
    notification?.show('alert', message)
  }, [notification])

  const printSticker = useCallback(async (
    printerName?: string,
    content?: string,
    options?: IPrintOptions,
  ) => {
    const sticker: Partial<ISticker> = {
      printer: printerName,
      content: content,
    }

    /* Если стикер не должен выполняться, но при этом это не ошибка */
    if (!options?.stickerExists && options?.stickerExists !== undefined) {
      options?.onStickerSuccess?.()
      return
    }

    /* Валидация */
    if (!content) {
      const err = 'Ошибка формирования стикера, контент отсутствует'
      handleStickerError(
        err,
        PrintErrorCodes.contentError,
        sticker,
        options?.onStickerError,
      )
      return Promise.reject(err)
    }
    if (checkBase64MIMEType(content) !== 'application/pdf') {
      const err = 'Ошибка формирования стикера - данные получены не в PDF'
      handleStickerError(
        err,
        PrintErrorCodes.contentNotPdf,
        sticker,
        options?.onStickerError,
      )
      return Promise.reject(err)
    }
    if (!printerName) {
      const err = 'Ошибка печати стикера - Не было передано имя принтера для печати стикера'
      handleStickerError(
        err,
        PrintErrorCodes.printerNameError,
        sticker,
        options?.onStickerError,
      )
      return Promise.reject(err)
    }


    try {
      await printer?.setPrinter(printerName)
      await printer?.print(content, {
        onSuccess: () => {
          notification?.show('success', options?.successNotifText || 'Стикер успешно отправлен на печать')
          options?.onStickerSuccess?.()
        },
        onError: (err) => {
          handleStickerError(
            options?.errorNotifText || `Ошибка печати стикера - ${err}`,
            PrintErrorCodes.printError,
            sticker,
            options?.onStickerError,
          )
        },
        onFinally: options?.onFinally,
        qzOptions: {
          copies: options?.copies ?? 1,
          printer: printerName,
        },
      })
      return Promise.resolve()
    } catch (err) {
      handleStickerError(
        `Ошибка печати стикера - ${err}`,
        PrintErrorCodes.printError,
        sticker,
        options?.onStickerError,
      )
    }
  },[handleStickerError, notification, printer])

  const handlePrintStickersList = (
    filesForPrint?: StickerWithCopies[],
    isPreview: boolean = false
  ) => {
    if (!filesForPrint) return
    return Promise.all(filesForPrint.map(async (file) => {
      if (isPreview) {
        previewBase64Pdf(file.content, file.title)
      } else {
        await printSticker(file.printer, file.content, { copies: file.quantity })
      }
    }))
  }


  return { printSticker, handlePrintStickersList }
}

import React, { useEffect, useRef, useState } from 'react'
import { AdvanceModeLock, Button, ErrorBoundary, InputWrapper, UserBlock } from '../../../../components'
import { IconArrowDown } from '@consta/icons/IconArrowDown'
import { Link, useHistory } from 'react-router-dom'
import cactusLogo from 'src/updatedDesignVersion/shared/icons/IconCactusLogo.svg'
import styles from './Header.module.scss'
import { IconSearchStroked } from '@consta/icons/IconSearchStroked'
import { useDebounce } from '../../../../hooks'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import { NavigationMenu } from '../NavigationMenu/NavigationMenu'
import cx from 'classnames'
import { Badge } from '@consta/uikit/Badge'
import { IconSettings } from '@consta/icons/IconSettings'
import { useAppContext } from '../../../../context'
import { PATHS } from '../../../../config'
import { ContextMenu, ContextMenuStatus } from '@consta/uikit/ContextMenu'
import { IconComponent } from '@consta/icons/Icon'
import { IconCalculator } from '@consta/icons/IconCalculator'
import { IconDocFilled } from '@consta/icons/IconDocFilled'
import { IconCards } from '@consta/icons/IconCards'
import { HEADER_ID } from '../../helpers/consts'

const IconTitle = {
  Операции: IconCards,
  Документы: IconDocFilled,
  Отчеты: IconCalculator,
}

export type ContextMenuItemDefault = {
  label: string | number
  key?: string
  rightSide?: React.ReactNode
  rightIcon?: IconComponent
  leftSide?: React.ReactNode
  leftIcon?: IconComponent
  subMenu?: ContextMenuItemDefault[]
  status?: ContextMenuStatus
  disabled?: boolean
  groupId?: number | string
  onClick?: React.MouseEventHandler
}
type Group = {
  name: string
  key: string
}
type Item = {
  label: string
  group: string
}

export const Header = () => {
  const [searchTxtDebounced, searchTxt, setSearchTxt] =
    useDebounce<TextFieldPropValue>(null)
  const ref = useRef(null)
  const { mainMenuList, advanceMode } = useAppContext()
  const history = useHistory()
  const [list, setList] = useState<ContextMenuItemDefault[]>([])
  const [groupList, setGroupList] = useState<Group[]>([])
  const [filteredList, setFilteredList] = useState<ContextMenuItemDefault[]>([])
  const [visible, setVisible] = useState(false)


  useEffect(() => {
    setGroupList(mainMenuList.map((el) => ({ name: el.label, key: el.label })))
    const list = mainMenuList.map((menu) =>
      menu.items.map((el) => ({
        label: el.name,
        key: el.route,
        groupId: menu.label,
        rightIcon: IconTitle[el.headerType],
      })),
    ).flat()
    setList(list)
    setFilteredList(list)
  }, [])

  const handleSearch = (value: string) => {
    if (!value) {
      setFilteredList(list)
      return
    }

    const results = list.filter((item) =>
      String(item.label).toLowerCase().includes(value.toLowerCase()),
    )

    setFilteredList(results)
    setVisible(true)
  }
  useEffect(() => {
    handleSearch(searchTxtDebounced)
  }, [searchTxtDebounced])

  const openSettings = () => {
    history.push(PATHS.SETTINGS)
  }

  return (
    <section id={HEADER_ID} className={styles.headerSection}>
      <Link to="/" className={styles.logo}>
        <img src={cactusLogo} alt="Кактус" />
      </Link>
      <div className={styles.searchContainer}>
        <InputWrapper
          id="search"
          autoFocus
          placeholder="Я ищу"
          handleChange={(value) => {
            setVisible(false)
            setSearchTxt(value)
          }}
          inputRef={ref}
          value={searchTxt}
          className={styles.searchInput}
          leftSide={IconSearchStroked}
        />
        {filteredList.length ? (
          <ContextMenu
            items={filteredList}
            className={styles.contextSearch}
            isOpen={visible}
            size="m"
            anchorRef={ref}
            groups={groupList}
            getGroupLabel={(group) => group.name}
            onClickOutside={() => setVisible(false)}
            getGroupId={(group) => group.key}
            onItemClick={({ item }) => {
              history.push(item.key)
            }}
          />
        ) : null}
      </div>

      <div className={styles.menuWrapper}>
        <ErrorBoundary>
          <NavigationMenu
            mainMenuList={mainMenuList}
          />
        </ErrorBoundary>
      </div>

      <div className={styles.buttonWrapper}>
        {advanceMode ? (
          <>
            <Badge
              form="round"
              status="normal"
              label="Расширенный"
              size={'s'}
              className={styles.badge}
            />
            <Button
              onlyIcon
              iconLeft={IconSettings}
              view="clear"
              size="s"
              iconSize="m"
              onClick={openSettings}
            />
          </>
        ) : null}
        <div className={cx(styles.userCard, { [styles.lightCard]: true })}>
          <AdvanceModeLock className={styles.lock} />
          <UserBlock
            withMenu
            iconRight={IconArrowDown}
            size="l"
            defaultMenuSettings={{
              withoutGoToMainMenu: false,
            }}
          />
        </div>
      </div>
    </section>
  )
}

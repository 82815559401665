import { useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { PhysicalSetOrderBatchDto } from '../../interfaces/physicalSetOrderBatch'


interface IParams {
  docId: string
}

interface IResult extends ApiReqResult {
  batch: PhysicalSetOrderBatchDto
}

const getPhysicalSetOrderBatch = async (params: { docId: string }): Promise<IResult> => {
  const result = await api.get(`/physical-set-order-batch/${params.docId}`)
  if (!result?.data?.success) {
    throw new Error('not success')
  }
  return result.data
}

export const useFetchPhysicalSetOrderBatch = () => ({
  fetch: (docId: string) => getPhysicalSetOrderBatch({ docId }),
})


export const useGetPhysicalSetOrderBatch = (
  params: IParams,
  options?: UseQueryOptions<any>
) => {
  return useQuery<IResult>(
    [`getPhysicalSetOrderBatch`, params],
    async () => getPhysicalSetOrderBatch(params),
    {
      keepPreviousData: true,
      ...options,
    },
  )
}


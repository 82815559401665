import { useQuery } from 'react-query'

import api from 'src/api'
import { ApiReqResult } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { DocumentsType } from 'src/updatedDesignVersion/shared/ui/NavigationMenu/routes'


interface IResult extends ApiReqResult {
  values: DocumentsType[]
}

const getDetDocumentTypeList = async (): Promise<IResult> => {
  const result = await api.get(`/document/type`)
  if (!result?.data?.success) {
    throw result
  }
  return result.data
}

export const useGetDocumentTypeList = (options?: UseQueryOptions<any>) => {
  return useQuery<IResult>(
    [`getDocumentTypeList`],
    getDetDocumentTypeList,
    { ...options },
  )
}
export const useFetchDocumentTypes = () => ({
  fetch: getDetDocumentTypeList,
})

import { IDimensions, IDimensionsObj } from '../interfaces'
import { convertLength, lengthMeasure } from './converters'

export const isDimensionsCorrect = (dimensions: IDimensionsObj | IDimensions): boolean =>
  Boolean(Number(dimensions.width) && Number(dimensions.height) && Number(dimensions.depth))


/** Отображение габаритов */
export const getDimensionStr = (
  dimensions?: IDimensionsObj | IDimensions,
  measure?: {from: lengthMeasure, to: lengthMeasure},
  keysList: ('width' | 'height' | 'depth')[] = ['width', 'height', 'depth']): string => {
  if (!dimensions) {
    return ''
  }
  return keysList.reduce((acc, key) => {
    let value = Number(dimensions[key])
    if (measure) {
      value = convertLength(measure.from, measure.to, value)
    }
    return acc + `${acc ? 'x' : ''}${value}`
  }, '')
}

/** Габариты из строки в число */
export const convertDimensionStrToNum = (dimensions: IDimensions): IDimensionsObj => ({
  width: Number(dimensions.width),
  depth: Number(dimensions.depth),
  height: Number(dimensions.height),
})
export const convertDimensionsNumToStr = (dimensions: IDimensionsObj): IDimensions => ({
  width: String(dimensions.width),
  depth: String(dimensions.depth),
  height: String(dimensions.height),
})
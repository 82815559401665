import { useMutation } from 'react-query'
import api from 'src/api'

import { ApiReqResult, IDimensionsObj } from 'src/interfaces'
import { UseMutationOptions } from 'react-query/types/react/types'
import { provideMock } from '../../mocks'

interface IResult extends ApiReqResult {
}

interface IParams {
  orderId: string,
  places: string[]
  packaging_id?: string,
  dimensions?: IDimensionsObj,
}

const useChangePhysicalSetPackage = (
  options?: UseMutationOptions<any, any, any>,
) => {
  return useMutation(
    async ({ orderId, ...data }: IParams) => {
      try {
        const result = await provideMock<{ orderId: string }, { data: IResult }>(
          async () => {
            const path = `/physical-set-order/${orderId}/change-packaging`
            return await api.post(path, data)
          },
          { orderId },
          [
            {
              key: 'orderId',
              value: 'mock',
              result: {
                data: {
                  success: true
                },
              },
              logData: 'Изменяем упаковку',
            },
          ],
        )()

        if (!result?.data?.success) {
          throw new Error('Ошибка редактирования места')
        }
        return result.data
      } catch (e) {
        throw e
      }
    },
    options,
  )
}

export default useChangePhysicalSetPackage

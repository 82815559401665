import React, { useCallback, useEffect, useRef, useState } from 'react'

import styles from './webcam-component.module.scss'

import { Button } from 'src/components/index'
import Webcam from 'react-webcam'
import { Loader } from '@consta/uikit/Loader'
import { Text } from '@consta/uikit/Text'

// Пакет и доки https://www.npmjs.com/package/react-webcam

interface IWebcamModal {
  withPreview?: boolean // включает превью при фотографии
  screenshotFormat?: 'image/webp' | 'image/png' | 'image/jpeg'
  onScreenshot: (imgBase64Src: string) => void
}

const WebcamComponent = (props: IWebcamModal) => {
  const { withPreview, onScreenshot, screenshotFormat = 'image/png' } = props

  const webcamRef = useRef<Webcam | null>(null)
  const [imgBase64Src, setImgBase64Src] = useState<string | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')

  useEffect(() => {
    if (!imgBase64Src) {
      return
    }
    onScreenshot(imgBase64Src)
  }, [imgBase64Src])

  const handleCapture = useCallback(() => {
    if (!webcamRef?.current) {
      return
    }
    const imageSrc = webcamRef.current.getScreenshot()
    setImgBase64Src(imageSrc)
  }, [webcamRef, setImgBase64Src])

  return (
    <div className={styles.wrapper}>
      {withPreview && imgBase64Src ? (
        <img src={imgBase64Src} width={200} height={200} />
      ) : null}
      {loading ? <Loader className={styles.loader} /> : null}
      {error ? (
        <Text view={'alert'} className={styles.error}>
          {error}
        </Text>
      ) : null}
      <Webcam
        audio={false}
        minScreenshotHeight={100}
        minScreenshotWidth={100}
        ref={webcamRef}
        screenshotFormat={screenshotFormat}
        onUserMediaError={(onUserMediaError) => {
          const errorTxt =
            typeof onUserMediaError === 'string'
              ? onUserMediaError
              : onUserMediaError.message
          setError(errorTxt)
          setLoading(false)
        }}
      />
      <div className={styles.btns}>
        <Button
          label="Сфотографировать"
          className={styles.btn}
          onClick={handleCapture}
        />
      </div>
    </div>
  )
}

export default WebcamComponent

import { useQuery } from 'react-query'

import api from 'src/api'
import { ApiReqResult, IHistoryChanges } from 'src/interfaces'
import { UseQueryOptions } from 'react-query/types/react/types'
import { MOCK_GET_LOG_CHANGES, provideMock } from '../../mocks'


/*
 * Получить список изменений
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Istoriya-operacij/operation/getChanges
 */

interface IServiceDocByParentResult extends ApiReqResult {
  /** Изменения */
  changes: IHistoryChanges[]
}

interface IServiceDocByParentParams {
  /** Идентификатор сущности */
  entity_id: string
}

const useGetLogChange = (paramsData: {
  params: IServiceDocByParentParams,
  options?: UseQueryOptions<any>
}) => {
  const { params, options } = paramsData

  return useQuery<IServiceDocByParentResult>(
    [`getLogChange`, params],
    provideMock<IServiceDocByParentParams, IServiceDocByParentResult>(
      async () => {
        const result = await api.get(`/log/change?entity_id=${params.entity_id}`)
        return result.data
      },
      params,
      [
        {
        key: 'entity_id',
        value: 'mock',
        result: MOCK_GET_LOG_CHANGES as IServiceDocByParentResult,
      },
    ]),
    options
  )
}

export default useGetLogChange

import { IFilterView } from '../FilterView/FilterView'
import { FilterStateType } from './FilterBlock'
import { FilterFieldType, FilterRow, isBool, isCalendar, isCombobox, isInput, isMerchants, isSku } from './types'
import days from 'dayjs'
import { DATE_FORMAT_VIEW, MONTH_FORMAT_VIEW, TIME_FORMAT } from '../../helpers'

type ResultType = Record<string, FilterFieldType>
const getInfoFields = (fieldsList: FilterFieldType[]): ResultType => {
  /** Рекурсивно вытаскиваем все поля в объект, с доступом по ключу */
  return fieldsList.reduce((acc, field): ResultType => {
    if (field.jointFields) {
      const fields = getInfoFields(field.jointFields)
      return { ...acc, ...fields }
    }
    if (field.fieldProps) {
      return { ...acc, [field.fieldProps.id]: field }
    }
    return acc
  }, {} as ResultType)
}

const getHashTableFields = (rowsFields: FilterRow[]): ResultType => {
  /** Приводим все поля к объекту с доступом по ключу */
  return rowsFields.reduce((acc, row) => {
    const infoObj = getInfoFields(row.fields)
    return { ...acc, ...infoObj }
  }, {} as ResultType)
}
const getDateValue = (args: {
  value: string
  hasTime?: boolean
  isMonth?: boolean
  customFormat?: string
}) => {
  const { value, hasTime, customFormat, isMonth } = args
  let format = `${DATE_FORMAT_VIEW} ${hasTime ? TIME_FORMAT : ''}`
  if (isMonth) {
    format = MONTH_FORMAT_VIEW
  }
  if (customFormat) {
    format = customFormat
  }
  return days(value).format(format)
}

const convertValueToCorrectFormat = (
  value: any,
  field: FilterFieldType,
): string => {
  /**  Конвертируем значения фильтров в строку в нужный формат */
  if (isBool(field)) {
    return value ? 'Включен' : ''
  }
  if (isInput(field)) {
    return value
  }
  if (isCalendar(field)) {
    const fieldType = field.fieldProps?.type || ''
    const hasTime = fieldType.includes('time')
    const isRange = fieldType.includes('range')
    const isMonth = fieldType.includes('month')
    if (isMonth) {
      return getDateValue({ value, isMonth })
    }
    if (isRange) {
      return value
        .map((v: string) => getDateValue({ value: v, hasTime }))
        .join(' - ')
    }
    return getDateValue({ value, hasTime })
  }
  const isComboboxFnsArr = [isCombobox, isMerchants, isSku]
  const isComboboxField = isComboboxFnsArr.some(fn => fn(field))
  if (isComboboxField) {
    if (Array.isArray(value)) {
      return value.map((v) => v.label).join(', ')
    }
    return value.label
  }

  return '-'
}

export const convertFilterStateToView = (
  filterState: FilterStateType,
  filterRowsFields: FilterRow[],
  excludeFilterFields: string[] = [],
): IFilterView[] => {
  const fieldInfo = getHashTableFields(filterRowsFields)
  return Object.keys(filterState)
    .filter(
      (filterKey) =>
        filterState[filterKey as keyof typeof filterState] &&
        !excludeFilterFields.includes(filterKey),
    )
    .map((filterKey) => {
      const key = filterKey as keyof typeof filterState
      const field = fieldInfo[key]
      const label =
        field?.fieldProps?.viewDescription || field?.fieldProps?.label || '-'
      const value = convertValueToCorrectFormat(filterState[key], field)

      return {
        key: filterKey,
        label: label,
        value: value,
      }
    })
}

import { useMutation } from 'react-query'
import api from 'src/api'

export interface IGetCellStickersResponse {
  errors: []
  stickers: ICellSticker[]
  success: boolean
}

interface ICellSticker {
  /** Код ячейки */
  barcode: string
  /** Картинка стикера в pdf */
  content: string
  errors: []
  /** Название принтера для отправки на печать */
  printer: string
  success: boolean
}

export const useGetCellStickers = () => {
  const getCellStickers = async (barcodes: string[]) => {
    const result = await api.post('/warehouse/cell/sticker', barcodes)

    if (result?.status === 200) {
      return result.data
    }
  }

  return useMutation(getCellStickers)
}

import React from 'react'
import { ConfirmType, StickerWithCopies } from '../../../interfaces'
import { ErrorModaType } from '../types/error'


export const SET_INPUT_REF: string = 'SET_INPUT_REF'
export const setInputRefAction = (ref: React.RefObject<HTMLInputElement> | null) => ({
  type: SET_INPUT_REF,
  payload: ref,
})

export const SET_CONFIRM_MODAL: string = 'SET_CONFIRM_MODAL'
export const setConfirmModalAction = (confirmData: ConfirmType | null) => ({
  type: SET_CONFIRM_MODAL,
  payload: confirmData,
})

export const SET_JOIN_CODE_MODAL_VISIBLE: string = 'SET_JOIN_CODE_MODAL_VISIBLE'
export const setJoinCodeModalVisibleAction = (visible: boolean) => ({
  type: SET_JOIN_CODE_MODAL_VISIBLE,
  payload: visible,
})


export const SET_REPRINT_STICKERS_FOR_PRINT: string = 'SET_REPRINT_STICKERS_FOR_PRINT'
export const setReprintStickersForPrintAction = (files: StickerWithCopies[]) => ({
  type: SET_REPRINT_STICKERS_FOR_PRINT,
  payload: files,
})

export const SET_STICK_STICKER_MODAL_VISIBLE: string = 'SET_STICK_STICKER_MODAL_VISIBLE'
export const setStickStickerModalVisibleAction = (visible: boolean) => ({
  type: SET_STICK_STICKER_MODAL_VISIBLE,
  payload: visible,
})


export const SET_ERROR_DATA_MODAL: string = 'SET_ERROR_DATA_MODAL'
export const setErrorDataAction = (errorData: ErrorModaType | null) => ({
  type: SET_ERROR_DATA_MODAL,
  payload: errorData,
})

import { IPrintOptions, usePrint } from './print'
import { useGetOrderPlacePackingList, usePreviewBase64Pdf } from 'src/hooks/index'

/** Печать упаковочного листа для грузовых мест заказа */

interface printResult {
  printPlacePackingList: (orderId: string, placeBarcode?: string, isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}

export const usePrintPlacePackingList = (): printResult => {
  const { printSticker } = usePrint()
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const getOrderPlacePackingList = useGetOrderPlacePackingList()

  const printPlacePackingList = async (orderId: string, placeBarcode?: string, isPreview?: boolean, options?: IPrintOptions) => {
    await getOrderPlacePackingList
      .fetch({ order_id: orderId, barcode: placeBarcode })
      .then(({ success, printer, content }) => {
        if (success) {
          const sticker = { printer, content }

          if (isPreview) {
            previewBase64Pdf(sticker.content, 'Стикер места')
            return
          }

          printSticker(sticker.printer, sticker.content, { copies: 1, ...(options || {})})
        }
      })
      .catch(() => {

      })
  }

  return {
    printPlacePackingList,
  }
}
import { CopyTupleType } from './useTableCopy'
import { useMemo } from 'react'
import { START_INDEX } from '../const'


type PropsType = {
  startForCopy: CopyTupleType,
  endForCopy: CopyTupleType,
}
/* Хелпер для полсчетов выделенных строк и прочего при выделении для копирования */
const useTableSelectHelper = ({startForCopy, endForCopy}: PropsType) => {
  const getMaxIndex = (startIndex: string, endIndex: string) => {
    return +(startIndex) > +(endIndex) ? startIndex : endIndex
  }
  const getMinIndex = (startIndex: string, endIndex: string) => {
    return +(startIndex) > +(endIndex) ? endIndex : startIndex
  }

  const getCorrectIndexes = (startForCopy: CopyTupleType, endForCopy: CopyTupleType) => {
    const [columnStartIndexRaw, rowStartIndexRaw] = startForCopy
    const [columnEndIndexRaw, rowEndIndexRaw] = endForCopy

    return {
      columnStartIndex: getMinIndex(columnStartIndexRaw, columnEndIndexRaw),
      columnEndIndex: getMaxIndex(columnStartIndexRaw, columnEndIndexRaw),
      rowStartIndex: getMinIndex(rowStartIndexRaw, rowEndIndexRaw),
      rowEndIndex: getMaxIndex(rowStartIndexRaw, rowEndIndexRaw),
    }
  }

  const {
    columnStartIndex,
    columnEndIndex,
    rowStartIndex,
    rowEndIndex,
  } = useMemo(
    () => getCorrectIndexes(startForCopy, endForCopy),
    [startForCopy, endForCopy]
  )

  const getRowIndex = (prevIndex: string, newIndex: number): string => {
    return `${prevIndex}${prevIndex.includes('.') || !prevIndex ? '' : '.'}${newIndex+START_INDEX}`
  }
  const checkOccurrenceIndex = (index: string, startIndex: string, endIndex: string) => {
    if (!startIndex || !endIndex) return false
    return (
      +index >= +startIndex && +index <= +endIndex
    ) || (
      +index >= +endIndex && +index <= +startIndex
    )
  }
  const checkColumnsSelected = (index: string, startIndex: string, endIndex: string) => {
    const isOccurrence = checkOccurrenceIndex(index, startIndex, endIndex)
    const isFirstRowSelected = rowStartIndex[0] === '0' || rowEndIndex[0] === '0'
    return isOccurrence && isFirstRowSelected
  }
  const checkRowSelected = (rowIndex: string, startIndex: string, endIndex: string) => {
    if (!startIndex || !endIndex) return false
    return(
      (+rowIndex >= +rowStartIndex && +rowIndex <= +rowEndIndex)
      || (startIndex === '0' && endIndex === '0')
    )
  }


  return {
    getRowIndex,
    checkOccurrenceIndex,
    checkColumnsSelected,
    checkRowSelected,
    getCorrectIndexes,

    columnStartIndex,
    columnEndIndex,
    rowStartIndex,
    rowEndIndex,
  }
}

export default useTableSelectHelper
import { useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult, IPaginationFilter, PaginationResult } from 'src/interfaces'
import { IDocumentStatuses } from 'src/interfaces/documentStatuses'
import { convertObjectToURLParams } from 'src/helpers'

/*
 * http://wms-test.kak2c.ru/warehouse-api/enum/document-type
 */

interface DocumentTypesResult extends ApiReqResult, PaginationResult {
  values: IDocumentStatuses[]
}
interface getDocumentTypesParams extends IPaginationFilter {
  search?: string
}

const useGetDocumentTypes = (paramsData: {
  params?: getDocumentTypesParams
  handleSuccess?: (data: DocumentTypesResult) => void
  handleSettled?: () => void
  handleError?: () => void
}) => {
  const { params, handleSuccess, handleSettled, handleError } = paramsData

  return useQuery(
    [`getDocumentTypes`],
    async () => {
      const searchParams = convertObjectToURLParams({ size: 300, ...params })
      const result = await api.get(`/enum/document-type${searchParams}`)
      return result.data
    },
    {
      onSuccess: (data: DocumentTypesResult) => {
        if (data?.success) {
          return handleSuccess?.(data)
        }
        handleError?.()
      },
      onSettled: handleSettled,
      onError: handleError,
    },
  )
}

export default useGetDocumentTypes

export const MOCK_GET_TRANSPORT_PALLET = {
  success: true,
  pallet: {
    id: '12345',
    status: 'PACKING',
    places: ['placeId1'],
    weight: 567,
    pallet_num: 1,
    dimensions: {
      width: 10,
      height: 10,
      depth: 10,
    },
  },
  places: [
    {
      id: 'placeId1',
      order_id: 'LT123456',
      packaging_id: '4445566633211',
      sequence_num: 1,
      weight: 567,
      items: [
        {
          id: 'some-item-id',
          barcode_used: '40010030040',
          quantity: 1,
          serial_numbers: [
            {
              code: 'imei',
              value: '821821291291929',
            },
            {
              code: 'chestniy_znak',
              value: '010460370285702421',
            },
          ],
        },
      ],
    },
  ],
  orders: [
    {
      id: 'LT123456',
      merchant: {
        id: 'some-id',
        title: 'ООО Зингер',
        trade_title: 'Zinger.com',
      },
    },
  ],
}

import React, { useState } from 'react'

import { ChoiceTableModal, PrintPreviewCheckbox, WhiteIconPrinter } from 'src/components'

import { ITableColumn, ITableRow, ModalProps, PrintTypeEnum } from 'src/interfaces'

import styles from './ChoiceTablePrintModal.module.scss'
import { IconComponent } from '@consta/icons/Icon'

/**
 * Модальное окно с таблицей для печати (с чекбоксами)
 * */

interface ChoiceTablePrintModalProps extends ModalProps {
  onSubmit: (selections: ITableRow[], isPreview: boolean) => void
  /** Остальные строки и столбцы (все кроме чекбокса) */
  rows: ITableRow[]
  columns: ITableColumn[]

  tableFullHeight?: boolean
  title?: string
  subtitle?: string
  submitBtnLabel: string
  cancelBtnIcon?: IconComponent
  submitBtnIcon?: IconComponent
}

const ChoiceTablePrintModal = (props: ChoiceTablePrintModalProps) => {
  const {
    isOpen,
    rows,
    columns,
    onClose,
    onSubmit,
    tableFullHeight,
    title = 'Печать документов',
    subtitle,
    submitBtnLabel = 'Напечатать',
    cancelBtnIcon,
    submitBtnIcon = WhiteIconPrinter,
  } = props

  const [printType, setPrintType] = useState<PrintTypeEnum>(PrintTypeEnum.print)

  return (
    <ChoiceTableModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={(selections) => onSubmit(selections, printType === PrintTypeEnum.preview)}
      subtitle={subtitle}
      title={title}
      tableFullHeight={tableFullHeight}
      submitBtnLabel={submitBtnLabel}
      submitBtnIcon={submitBtnIcon}
      cancelBtnIcon={cancelBtnIcon}
      rows={rows}
      columns={columns}
      extraSlotFooter={
        <PrintPreviewCheckbox
          className={styles.PrintPreviewCheckbox}
          onChange={setPrintType}
        />
      }
    />
  )
}

export default ChoiceTablePrintModal

import { LOCAL_STORAGE_NAME, SETTINGS_LOCAL_STORAGE_NAME } from 'src/config'

import { ICurrentUser } from 'src/interfaces'

export class LocalStorage {
  readonly key: string

  constructor(keyword: string) {
    this.key = keyword
  }

  get state() {
    return localStorage.getItem(this.key) || '{}'
  }

  getData(key: string) {
    return this.state ? JSON.parse(this.state)?.[key] ?? undefined : undefined
  }

  setData(key: string, data: any) {
    localStorage.setItem(
      this.key,
      JSON.stringify({ ...JSON.parse(this.state), [key]: data }),
    )
  }

  clearData(key: string) {
    const state = JSON.parse(this.state)
    if (state.hasOwnProperty(key)) {
      delete state[key]
      localStorage.setItem(this.key, JSON.stringify(state))
    }
  }

  clear = () => localStorage.removeItem(this.key)
}

/* LS который отчищается при выходе из системы */
class AppLocalStorage extends LocalStorage {
  get token() {
    return this.getData('token')
  }
  set token(token: string) {
    this.setData('token', token)
  }
  get token_warehouse() {
    return this.getData('token_warehouse')
  }
  set token_warehouse(token_warehouse: string) {
    this.setData('token_warehouse', token_warehouse)
  }
  get currentUser() {
    return this.getData('currentUser')
  }
  set currentUser(u: ICurrentUser) {
    this.setData('currentUser', u)
  }
  get currentBase() {
    return this.getData('currentBase')
  }
  set currentBase(b: string) {
    this.setData('currentBase', b)
  }
}

/* LS который сохраняется и после выхода из системы */
class DefaultSettingsLS extends LocalStorage {
  get defaultBase() {
    return this.getData('defaultBase')
  }

  set defaultBase(b: string) {
    this.setData('defaultBase', b)
  }

  get defaultWorkplace() {
    return this.getData('defaultWorkPlace')
  }

  set defaultWorkplace(v: string) {
    this.setData('defaultWorkPlace', v)
  }

  get defaultArm() {
    return this.getData('defaultArm')
  }

  set defaultArm(v: string) {
    this.setData('defaultArm', v)
  }

  set newDesign(newDesign: boolean) {
    this.setData('newDesign', Number(newDesign) || '')
  }
  get newDesign() {
    return this.getData('newDesign')
  }
}


export const appLocalStorage = new AppLocalStorage(LOCAL_STORAGE_NAME)
export const defaultSettingsLS = new DefaultSettingsLS(SETTINGS_LOCAL_STORAGE_NAME)

import React, { useState } from 'react'

import { ChoiceManualPackageModal, ChoiceOversizedPackageModal, ChoicePackageModal } from 'src/components/index'

import { IDimensions, IOrderPackagingOption, ModalProps } from 'src/interfaces'
import { isDimensionsCorrect } from 'src/helpers'

interface ChoicePackageProps extends ModalProps {
  packagingOptions: IOrderPackagingOption[]
  onBack: () => void
  onSubmit: (packageId: string, options: { dimensions?: IDimensions }) => void
}

enum ModalEnum {
  barcode = 'barcode',
  manual = 'manual',
  oversized = 'oversized',
}

const ChoicePackage = (props: ChoicePackageProps) => {
  const {
    isOpen,
    packagingOptions,
    onBack,
    onSubmit,
    onClose
  } = props

  const [mode, setMode] = useState<ModalEnum>(ModalEnum.barcode)
  const [selected, setSelected] = useState<IOrderPackagingOption | null>(null)

  const handleSubmit = (
    packageId: string,
    options: { dimensions?: IDimensions },
  ) => {
    const currentPackage = packagingOptions.find((pack) => pack.id === packageId)
    setSelected(currentPackage || null)

    const checkDimensions = options.dimensions
      ? isDimensionsCorrect(options.dimensions)
      : null

    if (packageId && currentPackage?.require_dimensions && !checkDimensions) {
      setMode(ModalEnum.oversized)
      return
    }

    onSubmit(packageId, options)
  }

  if (!isOpen) return null
  return (
    <>
      <ChoicePackageModal
        isOpen={mode === ModalEnum.barcode}
        packagingOptions={packagingOptions}
        showManual={() => setMode(ModalEnum.manual)}
        showOversized={() => setMode(ModalEnum.oversized)}
        onBack={onBack}
        onClose={onClose}
        onSubmit={handleSubmit}
      />

      <ChoiceManualPackageModal
        isOpen={mode === ModalEnum.manual}
        packagingOptions={packagingOptions}
        selected={selected}
        onSelect={setSelected}
        onBack={() => setMode(ModalEnum.barcode)}
        onClose={onClose}
        onSubmit={handleSubmit}
      />

      <ChoiceOversizedPackageModal
        selectedPackId={selected?.id}
        packagingOptions={packagingOptions}
        isOpen={mode === ModalEnum.oversized}
        onBack={() => setMode(ModalEnum.barcode)}
        onClose={onClose}
        onSubmit={handleSubmit}
      />
    </>
  )
}

export default ChoicePackage

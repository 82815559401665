import styles from './BarcodesCell.module.scss'
import { IBarcode } from '../../../../interfaces'

export const BarcodesCell = ({ barcodes = [] }: { barcodes: IBarcode[] }) => {

  return (
    <div className={styles.wrapper}>
      {
        barcodes.map(barcodeObj => {
          return <p key={barcodeObj.barcode}>{barcodeObj.barcode}</p>
        })
      }
    </div>
  )
}

import { useQuery } from 'react-query'
import api from 'src/api'
import { ApiReqResult, IServices, IServicesDocuments, PaginationResult } from 'src/interfaces'
import { convertObjectToURLParams } from 'src/helpers'
import { UseQueryOptions } from 'react-query/types/react/types'

export interface IGetServicesParams {
  merchant_ids?: string | null
  ids?: string[]
  size?: number
  is_material?: boolean
}
export interface IGetServicesResult extends ApiReqResult, PaginationResult {
  services: IServices[]
  documents: IServicesDocuments[]
}
const useGetServices = (
  paramsObj: IGetServicesParams,
  options?: UseQueryOptions<any>,
) => {
  return useQuery<IGetServicesResult>(
    [`getServices`, paramsObj],
    async () => {
      const searchParams = convertObjectToURLParams(paramsObj)
      const result = await api.get(`/billing/service-doc${searchParams}`)

      return result.data
    },
    {
      keepPreviousData: true,
      ...options,
    },
  )
}
export default useGetServices

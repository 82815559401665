import { useMutation } from 'react-query'

import api from 'src/api'

/*
 * Удаление компании-перевозчика
 * https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Kompanii-perevozchiki/operation/deleteCarrierCompany
 */

interface DeleteCarrierCompanyParams {
  companyId: string
}

const useDeleteCarrierCompany = () => {
  return useMutation(async ({ companyId }: DeleteCarrierCompanyParams) => {
    const result = await api.delete(`/carrier-company/${companyId}`)

    if (!result?.data?.success) {
      throw result
    }
    return result.data
  })
}

export default useDeleteCarrierCompany

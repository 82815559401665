import { IPrintOptions, usePrint } from './print'
import { usePreviewBase64Pdf } from 'src/hooks/index'
import useShipmentPlanDocsSticker from '../../shipment/useShipmentPlanDocsSticker'

/** Печать стикеров планов к отгрузки */

interface printResult {
  printShipmentPlanSticker: (shipmentPlans: string[], isPreview?: boolean, options?: IPrintOptions) => Promise<void>
}

export const usePrintShipmentPlanDocsSticker = (): printResult => {
  const { printSticker } = usePrint()
  const { previewBase64Pdf } = usePreviewBase64Pdf()
  const getSticker = useShipmentPlanDocsSticker()

  const printShipmentPlanSticker = async (shipmentPlans: string[], isPreview?: boolean, options: IPrintOptions = {}) => {
    await getSticker
      .mutateAsync(shipmentPlans)
      .then(({ data }) => {
        if (data.success) {
          const content = data.content
          const printer = data.printer

          if (isPreview) {
            previewBase64Pdf(content, 'Планы на отгрузку')
            return
          }
          printSticker(printer, content, { copies: 1, stickerExists: true, ...options})
        }
      })
      .catch(() => {

      })
  }

  return {
    printShipmentPlanSticker,
  }
}
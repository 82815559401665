import api from 'src/api'
import { useMutation } from 'react-query'
import { UseMutationOptions } from 'react-query/types/react/types'
import { ApiReqResult, IError, PickingSkuErrorEnum } from 'src/interfaces'


interface IData {
  cell_barcode: string
  quantity: number
  sku_id: string

  barcode_used?: string
  error?: PickingSkuErrorEnum
  sku_batch_id?: string
}

interface IResult extends ApiReqResult {
  results: {
    errors?: IError[]
    id: string
    n: number
    success: boolean
  }[]
}

interface IParams {
  doc_id: string
  data: IData[]
}

const addPickedGoods = async ({ doc_id, data }: IParams) => {
  const result = await api.post(`/picking/${doc_id}/pick`, data)
  if (!result.data?.success) {
    throw new Error(result.data.message)
  }
  return result.data
}

const useAddPickedGoods = (options?: UseMutationOptions<any, IResult, any>) => {
  return useMutation(addPickedGoods, options)
}

export default useAddPickedGoods

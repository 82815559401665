import { useMutation } from 'react-query'

import api from 'src/api'
import { provideMock } from '../../mocks'
import { ApiReqResult } from '../../interfaces'

interface UseResetPackedOrderByIdParams {
  orderId: string
}

const useResetPackedOrderById = (params: UseResetPackedOrderByIdParams) => {
  return useMutation(async () => {
    try {
      const result = await provideMock<UseResetPackedOrderByIdParams, { data: ApiReqResult }>(
        async () =>
          await api.post(`/packing/order/${params.orderId}/reset`, {}),
        params,
        {
          key: 'orderId',
          value: 'mock',
          result: { data: { success: true } },
          logData: '--- !!! Сброс упаковки заказа !!! ---'
        },
      )()
      if (!result?.data?.success) {
        throw result?.data
      }
      return result.data
    } catch (e) {
      throw e
    }
  })
}

export default useResetPackedOrderById

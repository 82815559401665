import React, { useEffect, useState } from 'react'

import styles from './create-vehicle-modal.module.scss'
import { InputWrapper, Modal } from 'src/components/index'
import { IVehicle, IVehicleCreateData, ModalProps } from 'src/interfaces'
import { Button } from '@consta/uikit/Button'
import { TextFieldPropValue } from '@consta/uikit/TextField'
import cx from 'classnames'
import { useCreateUpdateVehicle } from 'src/hooks'

interface IProps extends ModalProps {
  onSubmit: (newVehicle: IVehicle) => void
  vehicleEditId?: string | null
  vehiclesList: IVehicle[]
}

interface IVehicleForm extends IVehicleCreateData {
}

const CreateVehicleModal = (props: IProps) => {
  const {
    isOpen,
    onClose,
    onSubmit,
    vehicleEditId,
    vehiclesList,
  } = props

  const createUpdateVehicle = useCreateUpdateVehicle({
    vehicleId: vehicleEditId ? String(vehicleEditId) : '',
  })

  const [formState, setFormState] = useState<Partial<IVehicleForm> | null>(null)

  const onCreate = () => {
    if (!formState) {
      return
    }
    const reqData: IVehicleCreateData = {
      model: formState.model || '',
      plate_number: formState.plate_number || '',
    }

    createUpdateVehicle
      .mutateAsync(reqData)
      .then((data) => {
        onSubmit({ ...reqData, id: data.id })
      })
      .catch(() => {
      })
  }

  const onChange = (id: string, value: TextFieldPropValue) => {
    setFormState((prev) => ({ ...prev, [id]: value }))
  }

  useEffect(() => {
    if (!vehicleEditId) {
      return
    }
    const vehicle = vehiclesList.find(v => v.id === vehicleEditId)
    setFormState({ model: vehicle?.model || '', plate_number: vehicle?.plate_number || '' })
  }, [vehicleEditId])

  return (
    <Modal
      isOpen={isOpen}
      hasOverlay
      onOverlayClick={(): boolean => false}
      className={styles.modal}
      size='m'
      headerTitle={'Создание нового перевозчика'}
    >
      <div className={styles.formWrapper}>
        <div className={styles.carAndGateInfo}>
          <div className={styles.carInfo}>
            <label>Номер и марка авто</label>
            <div>
              <InputWrapper
                id={'plate_number'}
                form='defaultBrick'
                placeholder={'С065ЕМ179'}
                value={formState?.plate_number}
                handleChange={(value: TextFieldPropValue) => {
                  onChange('plate_number', value)
                }}
                className={cx(styles.input, styles.carNumber)}
              />
              <InputWrapper
                id={'car_model'}
                form={'clearDefault'}
                placeholder={'Ford Transit'}
                value={formState?.model}
                handleChange={(value: TextFieldPropValue) => {
                  onChange('model', value)
                }}
                className={cx(styles.input, styles.carBrand)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footer}>
        <Button label='Отмена' view={'ghost'} onClick={onClose} />
        <Button
          label='Сохранить'
          view={'primary'}
          onClick={onCreate}
          loading={createUpdateVehicle.isLoading}
        />
      </div>
    </Modal>
  )
}

export default CreateVehicleModal

/**
 *  Открывает документ Base64 pdf в отдельном окне для просмотра
 */
import React, { useCallback } from 'react'

interface previewBase64PdfResult {
  previewBase64Pdf: (content: string, title?: string) => void
  generatePreviewIframe: (content: string) => React.ReactNode
}

export const usePreviewBase64Pdf = (): previewBase64PdfResult => {

  const generatePreviewIframe = (content: string): React.ReactNode => (
    <iframe
      style={{
        position: "absolute", left: 0, top: 0, border: 0
      }}
      width='100%'
      height='100%'
      src={`data:application/pdf;base64,${encodeURI(content)}`}
    />
  )

  const openPageWithBase64Pdf = useCallback((content: string, title?: string) => {
    let pdfWindow = window.open('', '_blank')
    if (pdfWindow) {
      pdfWindow.document.write
      (`<html>
          <head>
            <title>${title || ''}</title>
          </head>
          <body style='margin: 0; padding: 0'>
            <iframe 
              style='position: absolute; left: 0; top: 0; border: 0;'
              width='100%'
              height='100%'
              src='data:application/pdf;base64,${encodeURI(content)}'
             />
          </body>
        </html>`)
    }
  }, [])

  const previewBase64Pdf = useCallback((content: string, title?: string) => {
    setTimeout(() => openPageWithBase64Pdf(content, title), 0)
  }, [openPageWithBase64Pdf])

  return {
    previewBase64Pdf,
    generatePreviewIframe
  }
}
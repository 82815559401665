import styles from './confirm-exit-modal.module.scss'
import { Button } from '@consta/uikit/Button'
import React from 'react'
import { EnterHandler, Modal } from '../../../index'
import { ModalProps } from '../../../../interfaces'
import warningImg from '../../../../assets/images/orangeTiangle.svg'


interface IProps extends ModalProps {
  onSubmit: () => void
}

const ConfirmExitModal = ({
  isOpen,
  onClose,
  onSubmit
}: IProps) => {
  return (
    <EnterHandler onEnter={onClose}>
      <Modal
        isOpen={isOpen}
        onOverlayClick={(): boolean => false}
        className={styles.modal}
        img={warningImg}
        imgClass={styles.img}
        title={'Прекращение операции'}
        subtitle={'Вы уверены, что хотите прервать операцию'}
        size='min'
      >

        <div className={styles.btns}>
          <Button
            label={'Да'}
            onClick={onSubmit}
            view={'ghost'}
          />
          <Button
            label={'Отмена'}
            onClick={onClose}
          />
        </div>
      </Modal>
    </EnterHandler>
  )
}

export default ConfirmExitModal

import { useMutation, useQuery } from 'react-query'

import api from 'src/api'

import { ApiReqResult, IAcceptanceSku, IError, IPagination, IPaginationFilter } from 'src/interfaces'
import { convertObjectToURLParams } from '../../helpers'
import { UseQueryOptions } from 'react-query/types/react/types'


/** https://wms-test.kak2c.ru/doc/warehouse-api/index.html#tag/Tovary/operation/getSkus */


interface IGetItemsResult extends ApiReqResult {
  errors?: IError[]
  skus: IAcceptanceSku[]
  pagination: IPagination
}

export interface IGetSkuItemsParams extends IPaginationFilter {
  id?: string
  barcode?: string
  title?: string
  search?: string
  article?: string
  merchant_ids?: string[]

  options?: UseQueryOptions<any,any,any,any>
  notShowErrors?: boolean
}

const getItems = async (params: IGetSkuItemsParams) => {
  const urlParams = convertObjectToURLParams(params)
  const result = await api.get(`/items${urlParams}`)
  if (!result?.data?.success) {
    if (params.notShowErrors) {
      return
    }
    throw new Error(result.data.message)
  }
  return result.data
}

export const useGetItems = (
  { options, ...params }: IGetSkuItemsParams,
  handleSuccess?: (result: IAcceptanceSku[]) => void,
  handleSettled?: () => void,
  handleError?: (err?: any) => void,
) => {
  return useQuery(
    [`getSkus`, params],
    () => getItems(params),
    {
      onSuccess: (data) => {
        if (data?.success) {
          return handleSuccess?.(data.skus)
        }
        handleError?.()
      },
      onSettled: handleSettled,
      onError: handleError,
      ...options
    },
  ) as {
    refetch: any
    isLoading: boolean
    isFetching: boolean
    isError: boolean
    data: IGetItemsResult
  }
}

export const useFetchItems = () => {
  return { fetch: getItems }
}

export const useGetItemsM = () => {
  const getItemsM = async (params: IGetSkuItemsParams) => {
    const urlParams = convertObjectToURLParams(params)
    const result = await api.get(`/items${urlParams}`)

    if (result.data) {
      return result.data as IGetItemsResult
    }
  }

  return useMutation('getItemsM', getItemsM)
}

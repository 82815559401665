import api from 'src/api'

import { ApiReqResult, StickerData, StickerFormatType } from 'src/interfaces'
import { convertObjectToURLParams } from '../../helpers'


export interface IParams extends StickerFormatType {
  order_id: string
  place_id: string
}

export interface IResult extends ApiReqResult, StickerData {
}

const useGetPhysicalSetSticker = () => ({
  fetch: async (params: IParams) => {
    const paramsStr = convertObjectToURLParams({format: params.format})
    const result = await api.get(`/physical-set-order/${params.order_id}/place/${params.place_id}/sticker${paramsStr}`)
    if (!result?.data?.success) {
      throw new Error('not success')
    }
    return result.data as IResult
  },
})

export default useGetPhysicalSetSticker

import { TemplateFieldType } from '../../../shared/types/tableGenerator/generatorTypes'

/**
 * Сравниваем типы для типизации пропросв
 */
export const isUrl = (type: TemplateFieldType) => TemplateFieldType.URL === type
export const isInput = (type: TemplateFieldType) => [
  TemplateFieldType.TEXT,
  TemplateFieldType.TEXT_AREA,
  TemplateFieldType.NUMBER
].includes(type)
export const isBoolean = (type: TemplateFieldType) => TemplateFieldType.BOOLEAN === type
export const isSelect = (type: TemplateFieldType) => [TemplateFieldType.SELECT].includes(type)
export const isSkuBatch = (type: TemplateFieldType) => type === TemplateFieldType.SKU_BATCH
export const isDocument = (type: TemplateFieldType) => type === TemplateFieldType.DOCUMENT
export const isSku = (type: TemplateFieldType) => type === TemplateFieldType.SKU
export const isMerchant = (type: TemplateFieldType) => type === TemplateFieldType.MERCHANT
export const isContractor = (type: TemplateFieldType) => type === TemplateFieldType.CONTRACTOR
export const isCell = (type: TemplateFieldType) => type === TemplateFieldType.CELL
export const isContainer = (type: TemplateFieldType) => type === TemplateFieldType.CONTAINER
export const isPlace = (type: TemplateFieldType) => type === TemplateFieldType.PLACE
export const isComboboxSearchApi = (type: TemplateFieldType) => (
  isDocument(type)
  || isSku(type)
  || isMerchant(type)
  || isContractor(type)
  || isCell(type)
  || isContainer(type)
  || isPlace(type)
)

export const isImage = (type: TemplateFieldType) => type === TemplateFieldType.IMAGE
export const isArticle = (type: TemplateFieldType) => type === TemplateFieldType.ARTICLE
export const isDateTime = (type: TemplateFieldType) => type === TemplateFieldType.DATETIME
export const isDate = (type: TemplateFieldType) => type === TemplateFieldType.DATE
export const isTime = (type: TemplateFieldType) => type === TemplateFieldType.TIME
export const isYearMonth = (type: TemplateFieldType) => type === TemplateFieldType.YEARMONTH
export const isCalendar = (type: TemplateFieldType) => (
  isDateTime(type)
  || isDate(type)
  || isTime(type)
  || isYearMonth(type)
)
export const isTable = (type: TemplateFieldType) => type === TemplateFieldType.TABLE
export const isGroup = (type: TemplateFieldType) => type === TemplateFieldType.GROUP
export const isJSON = (type: TemplateFieldType) => type === TemplateFieldType.JSON

import { useMutation } from 'react-query'
import api from 'src/api'

export interface IProps {
  title: string
  type: string
}

const useCreateZone = () => {
  const createZone = async (params: IProps) => {
    const result = await api.post('/warehouse/zone', params)

    if (result.status === 200) {
      return result
    }
  }

  return useMutation(createZone)
}

export default useCreateZone

import { useMutation } from 'react-query'

import api from 'src/api'

interface Params {
  vehicleId: string
}

const useDeleteCarrier = () => {
  return useMutation(async (params: Params) => {
    const result = await api.delete(`/vehicle/${params.vehicleId}`)

    if (!result?.data?.success) {
      throw result
    }
    return result.data
  })
}

export default useDeleteCarrier

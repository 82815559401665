import { BadgePropStatus } from '@consta/uikit/Badge'
import { IconComponent } from '@consta/icons/Icon'
import { IconWatchStroked } from '@consta/icons/IconWatchStroked'
import { IconAllDone } from '@consta/icons/IconAllDone'

export enum PickingCellStatusEnum {
  await_picking = 'await_picking',
  processing = 'processing',
  picked = 'picked'
}

export const PICKING_CELL_STATUSES_DESCRIPTION: Record<PickingCellStatusEnum | string, string> = {
  [PickingCellStatusEnum.await_picking]: 'Ожидает отбора',
  [PickingCellStatusEnum.processing]: 'На отборе',
  [PickingCellStatusEnum.picked]: 'Отобрана',
}

export const PICKING_CELL_STATUS_BADGE_TYPE: Record<PickingCellStatusEnum | string, BadgePropStatus> = {
  [PickingCellStatusEnum.await_picking]: 'system',
  [PickingCellStatusEnum.processing]: 'warning',
  [PickingCellStatusEnum.picked]: 'success',
}

export const PICKING_CELL_STATUS_BADGE_ICON: Record<PickingCellStatusEnum | string, IconComponent> = {
  [PickingCellStatusEnum.await_picking]: IconWatchStroked,
  [PickingCellStatusEnum.processing]: IconWatchStroked,
  [PickingCellStatusEnum.picked]: IconAllDone,
}

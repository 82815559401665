import { useMutation } from 'react-query'

import api from 'src/api'
import { ReturnStatusEnum } from '../../interfaces'

type RequestItemData = {
  id: string,
  status: ReturnStatusEnum
}

const useUpdateReturnStatus = () => {
  return useMutation(
    async (data: RequestItemData[]) => {
      const result = await api.post(`/return/status`, data)
      return result.data
    },
  )
}

export default useUpdateReturnStatus
